import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import commonStyle from "../../../style/commonStyle.module.css";
import purchaseStyle from "../purchaseStyle.module.css";
import {
  DownloadPDFButton,
  EditButtonLink,
  EmailButton,
  ScreenTitle,
  SimpleBlueButtonLink,
} from "../../../components/editComponents";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  Modal,
  ErrorMessage,
  Loader,
  ViewText,
  ViewDateWithTitle,
  SubTitle,
  Tag,
  FromTag,
  PriceText,
  ViewLabel,
  VariantSkuTag,
} from "../../../components/viewComponents";
import { purchaseOrderDetailsRequest } from "../../../actions/purchaseOrderActions";
import useDocumentTitle from "../../../useDocumentTitle";
import { downloadPurchaseOrderPdf } from "./downloadPurchaseOrderPdf";
import PoEmailForm from "./PoEmailForm";
import XeroLink from "../../../components/XeroLink";
import { ProductTypeIcon } from "../../../components/icons";
import { convtPrice, convtQuantity } from "../../../functions/functions";
import { generatePurchaseOrderRequest } from "../../../api/generatePDFServices";

export default function PreviewPurchaseOrderScreen() {
  useDocumentTitle("Preview Purchase Order");
  const dispatch = useDispatch();
  const { id: poid } = useParams();

  const { userInfo } = useSelector((state) => state.userLogin);
  
  const config = {
    responseType: "arraybuffer",
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const { userDetails, success: detailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const [emailModalOpen, setEmaiModalOpen] = useState(false);

  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const { loading, orderInfo, error, success } = useSelector(
    (state) => state.purchaseOrderDetails
  );

  /////////////////////////////////////////////////////////////////////

  useEffect(() => {
    dispatch(purchaseOrderDetailsRequest(poid));
  }, [dispatch, poid]);

  /////////////////////////////////////////////////////////////////////////////
  const downloadPDFAction = async () => {
    try {
      setReqStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await generatePurchaseOrderRequest(poid, config);
      if (responseData.status === 200) {
        // Create a Blob from the response data
        const blob = new Blob([responseData.data]);
        saveAs(blob, `StockUnify-purchase-order-${orderInfo.basicInfo.orderNumber}.pdf`);

        setReqStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
          error: "",
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setReqStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };


  const sendEmailAction = () => {
    setEmaiModalOpen(true);
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Modal
        title="Send Email "
        isModalOpen={emailModalOpen}
        closeModal={() => setEmaiModalOpen(false)}
        content={
          <PoEmailForm
            supplierEmail={success ? orderInfo.basicInfo.email : ""}
            orderInfo={orderInfo}
            closeModal={() => setEmaiModalOpen(false)}
          />
        }
      />

      <Breadcrumbs screenName="Preview Purchase Order" />
      <ScreenTitle title="Preview Purchase Order" />
      <div className={commonStyle.pageContentContainer}>
        {loading ? (
          <Loader mess="Requesting purchase order details, please wait a moment..." />
        ) : success ? (
          <div>
            <div className={purchaseStyle.orderNumberRow}>
              <div className={purchaseStyle.orderNumberCon}>
                <div>
                  <ViewText title="Order No." />
                  <p> {orderInfo.basicInfo.orderNumber}</p>
                </div>
                {orderInfo.basicInfo.status === "Approved" ? (
                  <Tag name={orderInfo.basicInfo.status} color="green" />
                ) : orderInfo.basicInfo.status === "Draft" ? (
                  <Tag name={orderInfo.basicInfo.status} color="yellow" />
                ) : orderInfo.basicInfo.status === "Completed" ? (
                  <Tag name={orderInfo.basicInfo.status} color="blue" />
                ) : orderInfo.basicInfo.status === "Deleted" ? (
                  <Tag name={orderInfo.basicInfo.status} color="gray" />
                ) : (
                  <Tag name={orderInfo.basicInfo.status} color="gray" />
                )}
              </div>
              <div>
                {orderInfo.basicInfo.status !== "Completed" && (
                  <div>
                    {detailsSuccess &&
                    userDetails.data.permissions.includes(
                      "new_purchase_order"
                    ) ? (
                      <EditButtonLink
                        path={`/purchase/order/edit/${orderInfo.basicInfo.id}`}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </div>
            </div>

            {/* purchase order infomation */}
            <SubTitle name="Purchase Order Information" />
            <div className={purchaseStyle.orderInfoWrapper}>
              <div className={purchaseStyle.basicInfoWrapper}>
                <div className={commonStyle.col1}>
                  <ViewText
                    title="Supplier"
                    value={orderInfo.basicInfo.supplier}
                  />
                  <ViewLabel title="Billing Address" />
                  {orderInfo.basicInfo.billingAddress.country ? (
                    <p>{orderInfo.basicInfo.billingAddress.country.value}</p>
                  ) : (
                    <></>
                  )}
                  {orderInfo.basicInfo.billingAddress.region ? (
                    <p>{orderInfo.basicInfo.billingAddress.region}</p>
                  ) : (
                    <></>
                  )}
                  {orderInfo.basicInfo.billingAddress.city ? (
                    <p>{orderInfo.basicInfo.billingAddress.city}</p>
                  ) : (
                    <></>
                  )}
                  {orderInfo.basicInfo.billingAddress.addressLine ? (
                    <p>{orderInfo.basicInfo.billingAddress.addressLine}</p>
                  ) : (
                    <></>
                  )}
                  {orderInfo.basicInfo.billingAddress.postalCode ? (
                    <p>{orderInfo.basicInfo.billingAddress.postalCode}</p>
                  ) : (
                    <></>
                  )}
                  <ViewText
                    title="Location"
                    value={orderInfo.basicInfo.location}
                  />
                  {orderInfo.basicInfo.expectedDate > 0 ? (
                    <ViewDateWithTitle
                      title="Expected Delivery Date"
                      value={orderInfo.basicInfo.expectedDate}
                    />
                  ) : (
                    <></>
                  )}

                  {orderInfo.basicInfo.deliveryAddress && (
                    <ViewText
                      title="Address"
                      value={orderInfo.basicInfo.deliveryAddress}
                    />
                  )}
                  {orderInfo.basicInfo.receiveDate > 0 ? (
                    <ViewDateWithTitle
                      title="Receive Date"
                      value={orderInfo.basicInfo.receiveDate}
                    />
                  ) : (
                    <></>
                  )}
                  {orderInfo.basicInfo.projectName && (
                    <ViewText
                      title="Project"
                      value={orderInfo.basicInfo.projectName}
                    />
                  )}

                  {orderInfo.basicInfo.notes && (
                    <ViewText title="Notes" value={orderInfo.basicInfo.notes} />
                  )}
                </div>
                <div className={commonStyle.col2}>
                  <ViewDateWithTitle
                    title="Create Date"
                    value={orderInfo.basicInfo.createDate}
                  />
                  {orderInfo.basicInfo.approveDate > 0 ? (
                    <ViewDateWithTitle
                      title="Approve Date"
                      value={orderInfo.basicInfo.approveDate}
                    />
                  ) : (
                    <></>
                  )}

                  <ViewText
                    title="Tax Rate"
                    value={orderInfo.basicInfo.taxrateName}
                  />
                  <ViewText
                    title="Currecy"
                    value={orderInfo.basicInfo.currency}
                  />
                  <ViewText
                    title="Invoice"
                    value={
                      orderInfo.basicInfo.invoiceNo
                        ? orderInfo.basicInfo.invoiceNo
                        : "Not received"
                    }
                  />
                  <ViewDateWithTitle
                    title="Invoice Date"
                    value={orderInfo.basicInfo.invoiceDate}
                  />

                  <ViewDateWithTitle
                    title="Invoice Due Date"
                    value={orderInfo.basicInfo.invoiceDue}
                  />

                  {orderInfo.basicInfo.bill_status === "ISSUED" ? (
                    <>
                      <ViewLabel title="Payable Bill" />
                      <XeroLink
                        invoiceId={orderInfo.basicInfo.bill_id}
                        type="purchase_order_bill"
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className={purchaseStyle.invoiceConPreview}>
                {orderInfo.basicInfo.status !== "Draft" ? (
                  <>
                    <DownloadPDFButton
                      name="Download PDF"
                      action={downloadPDFAction}
                    />
                    <EmailButton name="Send Email" action={sendEmailAction} />
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {/* invoice section */}

            <SubTitle name="Order Products" />
            <p className={purchaseStyle.taxInclText}>
              {orderInfo.basicInfo.taxIncluded
                ? "* Amounts are tax inclusive"
                : "* Amounts are tax exclusive"}
            </p>

            <div className={commonStyle.longDataTable}>
              <table className={commonStyle.pageTable}>
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Product Name</th>
                    <th>Unit</th>
                    <th>Unit Cost</th>
                    <th>Discount</th>
                    <th>Net Cost</th>
                    <th>Quantity</th>
                    <th>Received Quantity</th>
                    <th>Total Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {orderInfo.orderItems.map((item, index1) => {
                    return (
                      <tr key={index1}>
                        <td>
                          {item.variantSku && (
                            <VariantSkuTag name={item.variantSku} />
                          )}
                        </td>
                        <td>
                          {" "}
                          {item.productName}{" "}
                          {item.variant ? (
                            <span className={purchaseStyle.variantTag}>
                              {item.variant}
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>{item.unitName}</td>

                        <td
                          style={{
                            width: "10%",
                          }}
                        >
                          <PriceText num={item.unitCost} />
                        </td>
                        <td
                          style={{
                            width: "10%",
                          }}
                        >
                          {item.discount}% <FromTag name="OFF" />
                        </td>
                        <td>
                          {item.discount ? (
                            <PriceText
                              num={convtPrice(
                                (1 - Number(item.discount) / 100) *
                                  Number(item.unitCost)
                              )}
                            />
                          ) : (
                            <PriceText num={item.unitCost} />
                          )}
                        </td>
                        <td>{convtQuantity(item.quantity)}</td>
                        <td>{convtQuantity(item.receivedQuantity)}</td>
                        <td
                          style={{
                            width: "10%",
                            maxWidth: "15%",
                          }}
                        >
                          <PriceText num={item.totalCost} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="8" className={commonStyle.tableFootName}>
                      Total
                    </td>

                    <td>
                      <PriceText num={orderInfo.orderSummary.productsTotal} />
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div className={purchaseStyle.summaryWrapper}>
              <div className={purchaseStyle.costCon}>
                {/* additional cost */}
                <SubTitle name="Additional Cost" />
                <table className={commonStyle.pageTable}>
                  <thead>
                    <tr>
                      <th>Cost Name</th>
                      <th>Cost</th>
                    </tr>
                  </thead>

                  <tbody>
                    {orderInfo.orderCosts.map((c, index2) => {
                      return (
                        <tr key={index2}>
                          <td
                            className={purchaseStyle.productNameCol}
                            style={{
                              width: "60%",
                            }}
                          >
                            {c.costName}
                          </td>

                          <td
                            style={{
                              width: "36%",
                            }}
                          >
                            <PriceText num={c.cost} />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className={commonStyle.tableFootName}>Total</td>

                      <td>
                        <PriceText
                          num={orderInfo.orderSummary.additionalCostTotal}
                        />
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div className={purchaseStyle.summaryCon}>
                {/* summary total price */}
                <SubTitle name="Order Summary" />
                <table
                  className={commonStyle.summaryTable}
                  style={{ marginTop: "12px" }}
                >
                  {orderInfo.basicInfo.taxIncluded ? (
                    <tbody>
                      <tr>
                        <td className={commonStyle.tableFootName}>
                          Products Total
                        </td>

                        <td style={{ width: "30%" }}>
                          <PriceText
                            num={orderInfo.orderSummary.productsTotal}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className={commonStyle.tableFootName}>
                          Additional Cost Total
                        </td>
                        <td style={{ width: "30%" }}>
                          <PriceText
                            num={orderInfo.orderSummary.additionalCostTotal}
                          />
                        </td>
                      </tr>

                      <tr className={commonStyle.summaryTableTotalRow}>
                        <td className={commonStyle.summaryTableTotalName}>
                          Order Total{" "}
                          <span className={commonStyle.taxText}>Tax Incl.</span>
                        </td>

                        <td className={commonStyle.summaryTableTotalValue}>
                          <PriceText
                            num={orderInfo.basicInfo.totalCostInclTax}
                          />
                          {orderInfo.basicInfo.currency}
                        </td>
                      </tr>

                      <tr className={commonStyle.summaryTableTotalRow}>
                        <td className={commonStyle.tableFootName}>
                          Total{" "}
                          <span className={commonStyle.taxText}>Tax Excl.</span>
                        </td>

                        <td style={{ width: "30%" }}>
                          <PriceText
                            num={orderInfo.basicInfo.totalCostExclTax}
                          />
                        </td>
                      </tr>

                      <tr className={commonStyle.summaryTableTotalRow}>
                        <td className={commonStyle.tableFootName}>
                          Tax
                          <span className={commonStyle.taxText}>
                            {orderInfo.taxRate
                              ? "-" + orderInfo.taxRate.value
                              : ""}
                          </span>
                        </td>

                        <td style={{ width: "30%" }}>
                          <PriceText num={orderInfo.basicInfo.totalTax} />
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className={commonStyle.tableFootName}>
                          Products Total
                        </td>

                        <td style={{ width: "30%" }}>
                          <PriceText
                            num={orderInfo.orderSummary.productsTotal}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className={commonStyle.tableFootName}>
                          Additional Cost Total
                        </td>
                        <td style={{ width: "30%" }}>
                          <PriceText
                            num={orderInfo.orderSummary.additionalCostTotal}
                          />
                        </td>
                      </tr>

                      <tr className={commonStyle.summaryTableTotalRow}>
                        <td className={commonStyle.tableFootName}>
                          Total{" "}
                          <span className={commonStyle.taxText}>Tax Excl.</span>
                        </td>

                        <td style={{ width: "30%" }}>
                          <PriceText
                            num={orderInfo.basicInfo.totalCostExclTax}
                          />
                        </td>
                      </tr>

                      <tr className={commonStyle.summaryTableTotalRow}>
                        <td className={commonStyle.tableFootName}>
                          Tax
                          <span className={commonStyle.taxText}>
                            {orderInfo.taxRate
                              ? "-" + orderInfo.basicInfo.taxRate.value
                              : ""}
                          </span>
                        </td>

                        <td style={{ width: "30%" }}>
                          <PriceText num={orderInfo.basicInfo.totalTax} />
                        </td>
                      </tr>

                      <tr className={commonStyle.summaryTableTotalRow}>
                        <td className={commonStyle.summaryTableTotalName}>
                          Order Total{" "}
                          <span className={commonStyle.taxText}>Tax Incl.</span>
                        </td>

                        <td className={commonStyle.summaryTableTotalValue}>
                          <PriceText
                            num={orderInfo.basicInfo.totalCostInclTax}
                          />
                          {orderInfo.basicInfo.currency}
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
            {orderInfo.basicInfo.status === "Approved" && (
              <div>
                <SimpleBlueButtonLink
                  path={`/purchase/order/receive-stock/${orderInfo.basicInfo.id}`}
                  name="Receive Stock"
                />
              </div>
            )}
          </div>
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
    </div>
  );
}
