import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import commonStyle from "../../../style/commonStyle.module.css";
import Breadcrumbs from "../../../components/Breadcrumbs";
import settingsStyle from "../settingsStyle.module.css";
import CompanySettingsTab from "./CompanySettingsTab";
import {
  EditButton,
  AddButton,
  DeleteButton,
  DefaultLabel,
} from "../../../components/editComponents";
import {
  Modal,
  ErrorMessage,
  Loader,
  SimpleDialog,
  DeleteDialog,
} from "../../../components/viewComponents";
import { K_CURRENCIES } from "../../../constantsData/currencies";
import CurrencyForm from "./CurrencyForm";
import {
  newCurrencyRequest,
  updateCurrencyRequest,
  currencyListRequest,
  deleteCurrencyRequest,
} from "../../../actions/orgSettingsActions";
import useDocumentTitle from "../../../useDocumentTitle";

export default function CompanySettingsCurrencies() {
  useDocumentTitle("Currency");
  const dispatch = useDispatch();
  const initialValues = {
    id: "",
    code: "",
    description: "",
    rate: "",
  };
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  useEffect(() => {
    dispatch(currencyListRequest());
  }, [dispatch]);

  // get currency list
  const { loading, currencies, error, success } = useSelector(
    (state) => state.currencyList
  );

  // update currency
  const {
    loading: upadteLoading,
    error: updateError,
    success: updateSuccess,
  } = useSelector((state) => state.updateCurrency);
  // update currency
  const {
    loading: newLoading,
    error: newError,
    success: newSuccess,
  } = useSelector((state) => state.newCurrency);

  const [editedCurrency, setEditCurrency] = useState("");

  // new and edit Modal
  const [newModal, setNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  // mange dialog content, new and edit
  const [newDialog, setNewDialog] = useState(false);
  const [updateDialog, setUpdateDialog] = useState(false);

  const getCurrencyValue = (values) => {
    let currencyValue = {
      id: values.id,
      code: K_CURRENCIES.filter((x) => x.value === values.code)[0],
      description: values.description,
      rate: values.rate,
      isBaseCurrency: values.is_base_currency,
    };

    return currencyValue;
  };

  const openEditModal = (item) => {
    setEditCurrency(item);
    setEditModal(true);
  };

  const addNewCurrency = (values) => {
    let submitedValues = {
      code: values.code.value,
      description: values.description,
      rate: values.rate,
    };

    dispatch(newCurrencyRequest(submitedValues));
    setNewModal(false);
    setNewDialog(true);
  };

  const editCurrency = (values) => {
    let submitedValues = {
      code: values.code.value,
      description: values.description,
      rate: values.rate,
    };

    dispatch(updateCurrencyRequest(submitedValues, values.id));
    setEditModal(false);
    setUpdateDialog(true);
  };

  // delete
  const [deleteId, setDeleteId] = useState();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const {
    loading: deleteLoading,
    error: deleteError,
    success: deleteSuccess,
  } = useSelector((state) => state.deleteDepartment);

  const deleteAction = (id) => {
    setDeleteId(id);
    setDeleteDialog(true);
  };

  const sendDeleteRequest = () => {
    dispatch(deleteCurrencyRequest(deleteId));
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Modal
        title="New Currency"
        isModalOpen={newModal}
        closeModal={() => setNewModal(false)}
        content={
          <CurrencyForm
            values={initialValues}
            onSubmit={addNewCurrency}
            btnName="Save"
            type="new"
          />
        }
      />
      <Modal
        title="Update Currency"
        isModalOpen={editModal}
        closeModal={() => setEditModal(false)}
        content={
          <CurrencyForm
            values={() => getCurrencyValue(editedCurrency)}
            onSubmit={editCurrency}
            btnName="Update"
            type="edit"
          />
        }
      />

      <SimpleDialog
        title="Add New Currency"
        successMessage="New currency added successfully."
        isDialogOpen={newDialog}
        closeDialog={() => setNewDialog(false)}
        loading={newLoading}
        loadingMessage="Processing request..."
        success={newSuccess}
        error={newError}
        btnValue="Confirm"
        confirmAction={() => setNewDialog(false)}
      />

      <SimpleDialog
        title="Update Currency"
        successMessage="Currency updated successfully."
        isDialogOpen={updateDialog}
        closeDialog={() => setUpdateDialog(false)}
        loading={upadteLoading}
        loadingMessage="Processing request..."
        success={updateSuccess}
        error={updateError}
        btnValue="Confirm"
        confirmAction={() => setUpdateDialog(false)}
      />

      <DeleteDialog
        title="Delete Currency"
        preDeleteMess="Please note that this action can not be undone. Deleting this currency will remove it from any active customer or supplier accounts and set their currency fields to empty. However, any previous orders made with this currency will remain in the system. Are you sure you want to proceed with the deletion?"
        successMessage="Currency deleted successfully."
        isDialogOpen={deleteDialog}
        closeDialog={() => setDeleteDialog(false)}
        loading={deleteLoading}
        success={deleteSuccess}
        error={deleteError}
        loadingMessage="Processing request..."
        deleteAction={sendDeleteRequest}
      />
      <Breadcrumbs screenName="Currency" />
      <div className={commonStyle.tabsContainer}>
        <CompanySettingsTab tabName="currency" />

        <div className={commonStyle.settingsContent}>
          <div className={settingsStyle.subTitleContainer}>
            <h2>Currency</h2>
          </div>
          {loading ? (
            <Loader mess="Requesting currencies, please wait a moment..." />
          ) : success ? (
            <div className={settingsStyle.contentCon}>
              <div className={commonStyle.shortDataTable}>
                {userDetailsSuccess ? (
                  userDetails.data.permissions.includes(
                    "add_delete_edit_company_info"
                  ) ? (
                    userDetails.data.orgInfo.plan_name !== "start_up" ? (
                      <AddButton
                        name="New Currency"
                        action={() => setNewModal(true)}
                      />
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}

                {/* hold the tax rates table */}
                <table className={commonStyle.pageTable}>
                  <thead>
                    <tr>
                      {/* <th></th> */}
                      <th>Currency Code</th>
                      <th>Description</th>
                      <th>Exchange rate</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currencies.length > 0 ? (
                      currencies.map((item) => (
                        <tr key={item.id}>
                          <td>
                            {item.code}{" "}
                            {item.isBaseCurrency === 1 ? (
                              <DefaultLabel name="Base Currency" />
                            ) : (
                              <></>
                            )}
                          </td>
                          <td>{item.description}</td>
                          <td>{item.rate}</td>
                          <td>
                            {item.isBaseCurrency === 1 ? (
                              <></>
                            ) : (
                              <div className={commonStyle.actionsGrop}>
                                {userDetailsSuccess ? (
                                  userDetails.data.permissions.includes(
                                    "add_delete_edit_company_info"
                                  ) ? (
                                    <>
                                      <EditButton
                                        action={() => openEditModal(item)}
                                      />
                                      <DeleteButton
                                        action={() => deleteAction(item.id)}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )
                                ) : (
                                  <></>
                                )}
                              </div>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          {" "}
                          <p>
                            There are no existing custom currencies. Please add
                            a new currency now.
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <ErrorMessage mess={error} />
          )}
        </div>
      </div>
    </div>
  );
}
