import React from "react";
import { NavLink } from "react-router-dom";
import commonStyle from "../../style/commonStyle.module.css";

export default function IOTab({ tabName, type }) {

  let className1;
  let className2;

  if (tabName === "import") {
    className1 = commonStyle.activeItem;
  } else {
    className1 = commonStyle.tabItem;
  }
  if (tabName === "export") {
    className2 = commonStyle.activeItem;
  } else {
    className2 = commonStyle.tabItem;
  }


  return type === "product" ? (
    <div className={commonStyle.tabsCon}>
      <NavLink
        to="/inventory/products/import"
        exact="true"
        className={className1}
      >
        Import
      </NavLink>
      <NavLink
        to="/inventory/products/export"
        exact="true"
        className={className2}
      >
        Export
      </NavLink>
    </div>
  ) : type === "supplier" ? (
    <div className={commonStyle.tabsCon}>
      <NavLink
        to="/supplier/import"
        exact="true"
        className={className1}
      >
        Import
      </NavLink>
      <NavLink
        to="/supplier/export"
        exact="true"
        className={className2}
      >
        Export
      </NavLink>
    </div>
  ) : type === "customer" ? (
    <div className={commonStyle.tabsCon}>
      <NavLink
        to="/customer/import"
        exact="true"
        className={className1}
      >
        Import
      </NavLink>
      <NavLink
        to="/customer/export"
        exact="true"
        className={className2}
      >
        Export
      </NavLink>
    </div>
  ) : (
    <></>
  );
}
