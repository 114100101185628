import React from "react";
import { NavLink } from "react-router-dom";
import commonStyle from "../../../style/commonStyle.module.css";

export default function CompanySettingsTab({ tabName }) {
  let className1;
  let className2;
  let className3;
  let className4;

  if (tabName === "general") {
    className1 = commonStyle.activeItem;
  } else {
    className1 = commonStyle.tabItem;
  }
  if (tabName === "department") {
    className2 = commonStyle.activeItem;
  } else {
    className2 = commonStyle.tabItem;
  }

  if (tabName === "location") {
    className3 = commonStyle.activeItem;
  } else {
    className3 = commonStyle.tabItem;
  }
  if (tabName === "currency") {
    className4 = commonStyle.activeItem;
  } else {
    className4 = commonStyle.tabItem;
  }

  return (
    <div className={commonStyle.tabsCon}>
      <NavLink to="/company-settings" exact="true" className={className1}>
        General
      </NavLink>
      <NavLink
        to="/company-settings/department"
        exact="true"
        className={className2}
      >
        Department
      </NavLink>
      <NavLink
        to="/company-settings/location"
        exact="true"
        className={className3}
      >
        Location
      </NavLink>
      <NavLink
        to="/company-settings/currency"
        exact="true"
        className={className4}
      >
        Currency
      </NavLink>
     
    </div>
  );
}
