import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { subscriptionDetailsRequest } from "../../actions/billingActions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";

export default function PaymentHistoryScreen() {
  useDocumentTitle("Payment History");
  
  // const { loading, subscription, error, success } = useSelector(
  //   (state) => state.subscriptionDetails
  // );



  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(subscriptionDetailsRequest());
  }, [dispatch]);


  return (
    <div className={commonStyle.pageContainer}>
    
    </div>
  );
}
