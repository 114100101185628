import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import commonStyle from "../../../style/commonStyle.module.css";
import {
  ScreenTitle,
  CloneButtonLink,
  EditButtonLink,
  ReceiveStockButton,
  ViewButtonLink,
  DeleteButton
} from "../../../components/editComponents";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Pagination from "../../../components/Pagination";
import {
  ErrorMessage,
  Loader,
  Tag,
  ViewDateText,
  DollarTag,
  DeleteDialog,
} from "../../../components/viewComponents";
import { purchaseOrderListRequest } from "../../../actions/purchaseOrderActions";
import useDocumentTitle from "../../../useDocumentTitle";
import { Search } from "../../../components/inputFields";
import { deletePurchaseOrderRequest } from "../../../api/purchaseOrderServices";

export default function PurchaseOrderListScreen() {
  useDocumentTitle("Manage Purchase Orders");
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  const { loading, purchaseOrders, error, success } = useSelector(
    (state) => state.purchaseOrderList
  );

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);

  // search
  const [keyWords, setKeywords] = useState("");

  const [deleteOrderId, setDeleteOrderId] = useState("");
  const [deleteDialog, setDeleteDialog] = useState(false);

  const [deleteStatus, setDeleteStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });


  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  useEffect(() => {
    if (
      userDetailsSuccess &&
      userDetails.data.permissions.includes("purchase_order_list")
    ) {
      dispatch(purchaseOrderListRequest(keyWords, pageNum, pageSize, "all"));
    } else {
      dispatch(
        purchaseOrderListRequest(keyWords, pageNum, pageSize, "creator")
      );
    }
  }, [dispatch, pageNum, keyWords, deleteStatus.success]);

  const handleSearchChange = (value) => {
    setKeywords(value);
    setPageNum(0);
  };

    ////////////////////////////////////////////////////////////////////////////////////
    const openDeleteDialog = (orderId) => {
      setDeleteOrderId(orderId);
      setDeleteDialog(true);
    };
    const sendDeleteRequest = async () => {
      try {
        setDeleteStatus({ ...deleteStatus, loading: true, success: false, error: "" });
        const responseData = await deletePurchaseOrderRequest(deleteOrderId, config);
  
        if (responseData.success) {
          setDeleteStatus({
            ...deleteStatus,
            loading: false,
            success: true,
          });
        } else {
          throw "Delete purchase order failed.";
        }
      } catch (error) {
        setDeleteStatus({
          ...deleteStatus,
          loading: false,
          error: error,
        });
      }
    };
  
    ////////////////////////////////////////////////////////////////////////////////////

  /////////////////////////////  /////////////////////////////  /////////////////////////////
  /////////////////////////////  /////////////////////////////  /////////////////////////////

  /////////////////////////////  /////////////////////////////  /////////////////////////////
  /////////////////////////////  /////////////////////////////  /////////////////////////////

  const EditLink = ({ itemId }) => {
    return userDetailsSuccess ? (
      userDetails.data.permissions.includes("new_purchase_order") ? (
        <EditButtonLink path={`/purchase/order/edit/${itemId}`} />
      ) : (
        <></>
      )
    ) : (
      <></>
    );
  };

  const CloneLink = ({ itemId }) => {
    return userDetailsSuccess ? (
      userDetails.data.permissions.includes("new_purchase_order") ? (
        <CloneButtonLink
          path={`/purchase/order/new`}
          data={{ orderId: itemId }}
        />
      ) : (
        <></>
      )
    ) : (
      <></>
    );
  };

  const PreviewLink = ({ itemId }) => {
    return userDetailsSuccess ? (
      userDetails.data.permissions.includes("new_purchase_order") ? (
        <ViewButtonLink path={`/purchase/order/preview/${itemId}`} />
      ) : (
        <></>
      )
    ) : (
      <></>
    );
  };

  const ReceiveStockLink = ({ itemId }) => {
    return userDetailsSuccess ? (
      userDetails.data.permissions.includes("receive_stock") ? (
        <NavLink to={`/purchase/order/receive-stock/${itemId}`}>
          <ReceiveStockButton />
        </NavLink>
      ) : (
        <></>
      )
    ) : (
      <></>
    );
  };

  const DeleteOrderButton = ({ itemId }) => {
    return userDetailsSuccess ? (
      userDetails.data.permissions.includes("new_purchase_order") ? (
        <DeleteButton action={() => openDeleteDialog(itemId)} />
      ) : (
        <></>
      )
    ) : (
      <></>
    );
  };

  return (
    <div className={commonStyle.pageContainer}>
      <DeleteDialog
        title="Delete Purchase Order"
        preDeleteMess={`Please note that the delete action can not be undone. Are you sure you want to proceed?`}
        successMessage="Purchase order deleted sucessfully."
        isDialogOpen={deleteDialog}
        closeDialog={() => setDeleteDialog(false)}
        loading={deleteStatus.loading}
        success={deleteStatus.success}
        error={deleteStatus.error}
        loadingMessage="Processing request..."
        deleteAction={sendDeleteRequest}
      />

      <Breadcrumbs screenName="All Purchase Orders" />
      <ScreenTitle title="All Purchase Orders" />
      <div className={commonStyle.pageContentContainer}>
      <div style={{ width: "60%" }}>
              <Search
                placeholdertext="Type order number / supplier name to search..."
                initialQuery={keyWords}
                onChange={handleSearchChange}
              />
            </div>

        {loading ? (
          <Loader mess="Requesting purchase order list, please wait a moment..." />
        ) : success ? (
          <div className={commonStyle.longDataTable}>
            

            {/* hold the product list table */}
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  
                  <th>Order Number</th>
                  <th>Create Date</th>
                  <th>Supplier</th>
                  {purchaseOrders.hasProjects ? <th>Project</th> : <></>}
                  <th>Location</th>
                  <th>Total Cost</th>
                  <th>Creator</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {purchaseOrders.orders.length > 0 ? (
                  purchaseOrders.orders.map((item) => (
                    <tr key={item.id}>
                      <td>{item.orderNumber}</td>
                      <td>
                        <ViewDateText value={item.createDate} />
                      </td>
                      <td>{item.supplier}</td>
                      {purchaseOrders.hasProjects ? <td className="max-w-[120px] truncate">
                        {
                          item.projectCode ? <span>{item.projectCode} - {item.projectName}</span> : <></>
                        }
                        
                      </td> : <></>}
                      <td>
                        <Tag name={item.location} color="gray" />
                      </td>
                      <td>
                        <DollarTag />
                        {item.totalCostInclTax}
                      </td>
                      <td>
                        {item.creatorFirstName} {item.creatorLastName ? item.creatorLastName : ""}
                      </td>
                      <td>
                        {item.status === "Approved" ? (
                          <Tag name={item.status} color="green" />
                        ) : item.status === "Draft" ? (
                          <Tag name={item.status} color="yellow" />
                        ) : item.status === "Placed" ? (
                          <Tag name={item.status} color="yellow" />
                        ) : item.status === "Completed" ? (
                          <Tag name={item.status} color="blue" />
                        ) : (
                          <></>
                        )}
                      </td>

                      {/* according to different status show different actions */}
                      <td>
                        {item.status === "Draft" ? (
                          <div className={commonStyle.actionsGrop}>
                            <EditLink itemId={item.id} />
                            <DeleteOrderButton itemId={item.id} />
                          </div>
                        ) : item.status === "Placed" ? (
                          <div className={commonStyle.actionsGrop}>
                            <PreviewLink itemId={item.id} />
                            <CloneLink itemId={item.id} />
                            <DeleteOrderButton itemId={item.id} />
                            {userDetailsSuccess ? (
                              userDetails.data.permissions.includes(
                                "approve_purchase_order"
                              ) ? (
                                <EditLink itemId={item.id} />
                              ) : (
                                <></>
                              )
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : item.status === "Approved" ? (
                          <div className={commonStyle.actionsGrop}>
                            <PreviewLink itemId={item.id} />
                            <ReceiveStockLink itemId={item.id} />
                            <CloneLink itemId={item.id} />
                            <DeleteOrderButton itemId={item.id} />
                          </div>
                        ) : item.status === "Completed" ? (
                          <div className={commonStyle.actionsGrop}>
                            <PreviewLink itemId={item.id} />
                            <CloneLink itemId={item.id} />
                          </div>
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      {" "}
                      <p>
                        There are no purchase order available, create a new
                        purchase order now.
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {purchaseOrders.orders.length > 0 ? (
              <Pagination
                totalPageNum={purchaseOrders.totalPages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
    </div>
  );
}
