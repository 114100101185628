import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import settingsStyle from "../settingsStyle.module.css";
import {
  EditButton,
  SimpleBlueButton,
} from "../../../components/editComponents";
import { StyledPureInput, StyledSelect } from "../../../components/inputFields";
import { K_COUNTRIES } from "../../../constantsData/countries";
import { K_INDUSTRYS } from "../../../constantsData/industries";
import { K_DATEFORMAT } from "../../../constantsData/dateFormats";
import { K_TIMEZONES } from "../../../constantsData/timeZones";
import { Loader, TipsText } from "../../../components/viewComponents";
import { BsFillHouseFill } from "react-icons/bs";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function OrganizationForm({ orgInfo, onSubmit }) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const [isEdit, setEdit] = useState(false);
  const [newValues, setNewValues] = useState(orgInfo);
  const [formErrors, setFormErrors] = useState({});

  const [preImage, setPreImage] = useState();
  const [uploadLoading, setUploadLoading] = useState(false);

  // handle change when input
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewValues({ ...newValues, [name]: value });
  };

  // handle change when input
  const handleImageChange = (e) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("image", file);

      try {
        setUploadLoading(true);
        axios
          .post(SERVER_URL + `/org/logo/update`, formData, config)
          .then(async (response) => {
            setUploadLoading(false);
            if (response.data.success) {
              setPreImage(response.data.data.url);
            } else {
              setFormErrors({
                logo: "Update company logo failed, please try again later",
              });
            }
          })
          .catch((err) => {
            setUploadLoading(false);
            setFormErrors({
              logo: "Update company logo failed, please try again later",
            });
          });
      } catch (error) {
        setUploadLoading(false);
        setFormErrors({ logo: "Connection error, please try again later" });
      }
    }
  };

  const checkValidation = () => {
    const hasErrors = validate(newValues);

    if (Object.values(hasErrors).length === 0) {
      setEdit(false);
      onSubmit(newValues);
    } else {
      setFormErrors(hasErrors);
    }
  };

  // check input validation
  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = "Company name is required!";
    }
    if (!values.country) {
      errors.country = "Country is required!";
    }
    if (!values.industry) {
      errors.industry = "Industry is required!";
    }
    if (!values.timeZone) {
      errors.timeZone = "Time zone is required!";
    }
    if (!values.dateFormat) {
      errors.dateFormat = "Date format is required!";
    }

    return errors;
  };

  const editCompanyInfo = () => {
    setEdit(true);
  };

  return (
    <div className={settingsStyle.companySettingsCon}>
      <div className={settingsStyle.subTitleContainer}>
        <h2>General Information</h2>

        {isEdit ? (
          <></>
        ) : (
          <>
            {userDetailsSuccess ? (
              userDetails.data.permissions.includes(
                "add_delete_edit_company_info"
              ) ? (
                <EditButton action={editCompanyInfo} />
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </>
        )}
      </div>

      <div className={settingsStyle.contentCon}>
        <div className={settingsStyle.logoContainer}>
          <div className={settingsStyle.logo}>
            {preImage ? (
              <img alt="company logo" width="120px" src={preImage} />
            ) : newValues.logo ? (
              <img alt="company logo" width="120px" src={newValues.logo} />
            ) : (
              <BsFillHouseFill className={settingsStyle.defaultLogo} />
            )}
          </div>

          {isEdit && !uploadLoading ? (
            <label className={settingsStyle.uploadBtn}>
              Upload Logo
              <input type="file" name="logo" onChange={handleImageChange} />
            </label>
          ) : (
            <></>
          )}

          {isEdit && uploadLoading ? <Loader mess="Uploading..." /> : <></>}
        </div>

        <div className={settingsStyle.tableCon}>
          {isEdit ? (
            <div>
              <table className={settingsStyle.textTable}>
                <tbody>
                  <tr>
                    <td className={settingsStyle.label}>Company Name</td>
                    <td className={settingsStyle.valueField}>
                      <StyledPureInput
                        type="text"
                        name="name"
                        value={newValues.name}
                        onChange={(name) =>
                          handleChange({
                            target: { value: name, name: "name" },
                          })
                        }
                        error={formErrors.name}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className={settingsStyle.label}>Country</td>
                    <td className={settingsStyle.value}>
                      <StyledSelect
                        placeHolder="Select country"
                        selectOptions={K_COUNTRIES}
                        value={newValues.country}
                        onChange={(country) =>
                          handleChange({
                            target: { value: country, name: "country" },
                          })
                        }
                        error={formErrors.country}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className={settingsStyle.label}>Base Currency</td>
                    <td className={settingsStyle.value}>
                      <StyledPureInput
                        type="text"
                        name="baseCurrency"
                        value={newValues.baseCurrency}
                        readOnly
                        error={formErrors.baseCurrency}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className={settingsStyle.label}>Industry</td>
                    <td className={settingsStyle.value}>
                      <StyledSelect
                        placeHolder="Select industry"
                        selectOptions={K_INDUSTRYS}
                        value={newValues.industry}
                        onChange={(industry) =>
                          handleChange({
                            target: { value: industry, name: "industry" },
                          })
                        }
                        error={formErrors.industry}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className={settingsStyle.label}>Date Format</td>
                    <td className={settingsStyle.value}>
                      <StyledSelect
                        value={newValues.dateFormat}
                        placeHolder="Select date format"
                        selectOptions={K_DATEFORMAT}
                        onChange={(dateFormat) =>
                          handleChange({
                            target: { value: dateFormat, name: "dateFormat" },
                          })
                        }
                        error={formErrors.dateFormat}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className={settingsStyle.label}>Time Zone</td>
                    <td className={settingsStyle.value}>
                      <StyledSelect
                        placeHolder="Select time zone"
                        value={newValues.timeZone}
                        selectOptions={K_TIMEZONES}
                        onChange={(timeZone) =>
                          handleChange({
                            target: { value: timeZone, name: "timeZone" },
                          })
                        }
                        error={formErrors.timeZone}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="w-full mt-6">
              <SimpleBlueButton name="Update" action={checkValidation} />
              </div>
              <TipsText text="To apply the new company logo, time zone and date format, please log out and log in again." />
            </div>
          ) : (
            <table className={settingsStyle.textTable}>
              <tbody>
                <tr>
                  <td className={settingsStyle.label}>Company Name</td>
                  <td className={settingsStyle.value}>{newValues.name}</td>
                </tr>
                <tr>
                  <td className={settingsStyle.label}>Country</td>
                  <td className={settingsStyle.value}>
                    {newValues.country.label}
                  </td>
                </tr>
                <tr>
                  <td className={settingsStyle.label}>Base Currency</td>
                  <td className={settingsStyle.value}>
                    {newValues.baseCurrency}
                  </td>
                </tr>
                <tr>
                  <td className={settingsStyle.label}>Industry</td>
                  <td className={settingsStyle.value}>
                    {newValues.industry.label}
                  </td>
                </tr>
                <tr>
                  <td className={settingsStyle.label}>Date Format</td>
                  <td className={settingsStyle.value}>
                    {newValues.dateFormat.label}
                  </td>
                </tr>
                <tr>
                  <td className={settingsStyle.label}>Time Zone</td>
                  <td className={settingsStyle.value}>
                    {newValues.timeZone.label}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
}
