import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import commonStyle from "../../../style/commonStyle.module.css";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  AddLinkButton,
  EditButtonLink,
  DeleteButton,
  ViewButtonLink,
  ScreenTitle
} from "../../../components/editComponents";
import {
  ErrorMessage,
  Loader,
  DeleteDialog,
} from "../../../components/viewComponents";

import {
  roleNameListRequest,
  deleteRoleRequest,
} from "../../../actions/userRoleActions";
import useDocumentTitle from "../../../useDocumentTitle";

export default function RolesSettings() {
  useDocumentTitle("Roles")
  // access check for the actions
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const dispatch = useDispatch();

  // get roles list
  const { loading, roleNames, error, success } = useSelector(
    (state) => state.roleNamesList
  );

  // delete role
  const [deleteId, setDeleteId] = useState();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const {
    loading: deleteLoading,
    error: deleteError,
    success: deleteSuccess,
  } = useSelector((state) => state.deleteRole);

  const deleteRoleAction = (id) => {
    setDeleteId(id);
    setDeleteDialog(true);
  };

  const sendDeleteRequest = () => {
    dispatch(deleteRoleRequest(deleteId));
  };

  useEffect(() => {
    dispatch(roleNameListRequest());
  }, [dispatch]);

  return (
    <div className={commonStyle.pageContainer}>
      <DeleteDialog
        title="Delete Role"
        preDeleteMess="Deleting this role can not be undone, and any users assigned to this role will permanently lose their associated permissions. Are you sure you want to proceed with deleting this role?"
        successMessage="Role deleted successfully."
        isDialogOpen={deleteDialog}
        closeDialog={() => setDeleteDialog(false)}
        loading={deleteLoading}
        success={deleteSuccess}
        error={deleteError}
        loadingMessage="Processing request..."
        deleteAction={sendDeleteRequest}
      />
      <Breadcrumbs screenName="Roles" />
      <ScreenTitle title="Roles" />
          <div className={commonStyle.pageContentContainer}>
            {loading ? (
              <Loader mess="Requesting role list, please wait a moment.." />
            ) : success ? (
              <>
                <div className={commonStyle.shortDataTable}>
     
                    {userDetailsSuccess ? (
                      userDetails.data.permissions.includes(
                        "add_delete_edit_roles"
                      ) ? (
                        <AddLinkButton
                          name="New Role"
                          path="/settings/roles/new"
                        />
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}

                  {/* hold the role list table */}
                  <table className={commonStyle.pageTable}>
                    <thead>
                      <tr>
                        <th>Role</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {roleNames.length > 0 ? (
                        roleNames.map((role) => (
                          <tr key={role.id}>
                            <td>{role.label}</td>
                            <td>
                              <div className={commonStyle.actionsGrop}>
                                <ViewButtonLink
                                  path={`/settings/roles/preview/${role.id}`}
                                  data={role}
                                />

                                {/* check if role is super admin */}
                                {role.id === 1 ? (
                                  <></>
                                ) : userDetailsSuccess ? (
                                  userDetails.data.permissions.includes(
                                    "add_delete_edit_roles"
                                  ) ? (
                                    <>
                                      <EditButtonLink
                                        path={`/settings/roles/edit/${role.id}`}
                                        data={role}
                                      />
                                      <DeleteButton
                                        action={() => deleteRoleAction(role.id)}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )
                                ) : (
                                  <></>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <>
                          <tr>
                            <td>There are no roles available. Please add a new role now.</td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <ErrorMessage mess={error} />
            )}
          </div>
        </div>

  );
}
