import React from "react";
import { ReactComponent as DashboardIcon } from '../icons/dashboard-icon-black.svg';
import { ReactComponent as InventoryIcon } from '../icons/inventory-icon-black.svg';
import { ReactComponent as SaleIcon } from '../icons/sale-icon-black.svg';
import { ReactComponent as PurchaseIcon } from '../icons/purchase-icon-black.svg';
import { ReactComponent as CustomerIcon } from '../icons/customer-icon-black.svg';
import { ReactComponent as SupplierIcon } from '../icons/supplier-icon-black.svg';
import { ReactComponent as SettingsIcon } from '../icons/settings-icon-black.svg';
import { ReactComponent as IntegrationIcon } from '../icons/integration-icon-black.svg';
import { ReactComponent as RentalIcon } from '../icons/rental-icon-black.svg';
import { ReactComponent as ReportingIcon } from '../icons/reporting-icon-black.svg';
import commonStyle from "../style/commonStyle.module.css";
import menuStyle from "../style/menuStyle.module.css";

function ProductTypeIcon({ name }) {
  return (
    <div className={commonStyle.productTypeIconCon}>
      <div
        className={
          name === "product"
            ? commonStyle.productTypeIcon
            : "rentalProduct"
            ? commonStyle.rentalTypeIcon
            : "service"
            ? commonStyle.serviceTypeIcon
            : commonStyle.productTypeIcon
        }
      ></div>
      <p className={commonStyle.prodyctTypeTag}>{
          name === "product"
            ? "Product"
            : name === "rentalProduct"
            ? "Rental"
            : name === "service"
            ? "Service"
            : "Product"
        }</p>
    </div>
  );
}

function SideMenuIcon({ name }) {

  if (name==="dashboard" ){
    return <DashboardIcon className={menuStyle.sidemenuIcon} />
  }else if (name==="inventory") {
    return <InventoryIcon className={menuStyle.sidemenuIcon} />
  }else if (name==="sale") {
    return <SaleIcon className={menuStyle.sidemenuIcon} />
  }else if (name==="purchase") {
    return <PurchaseIcon className={menuStyle.sidemenuIcon} />
  }else if (name==="customer") {
    return <CustomerIcon className={menuStyle.sidemenuIcon} />
  }else if (name==="supplier") {
    return <SupplierIcon className={menuStyle.sidemenuIcon} />
  }else if (name==="settings") {
    return <SettingsIcon className={menuStyle.sidemenuIcon} />
  }else if (name==="integration") {
    return <IntegrationIcon className={menuStyle.sidemenuIcon} />
  }else if (name==="rental") {
    return <RentalIcon className={menuStyle.sidemenuIcon} />
  }else if (name==="reporting") {
    return <ReportingIcon className={menuStyle.sidemenuIcon} />
  }else {
    return <></>
  }
 
}


export { ProductTypeIcon, SideMenuIcon };
