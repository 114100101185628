import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  allPermissionsRequest,
  newRoleRequest,
} from "../../../actions/userRoleActions";
import Breadcrumbs from "../../../components/Breadcrumbs";
import commonStyle from "../../../style/commonStyle.module.css";
import RoleDetailForm from "./RoleDetailForm";
import {
  ErrorMessage,
  Loader,
  SimpleDialog,
} from "../../../components/viewComponents";
import useDocumentTitle from "../../../useDocumentTitle";
import { ScreenTitle } from "../../../components/editComponents";

export default function NewRole() {
  useDocumentTitle("New Role");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    loading: newRoleLoading,
    success: newRoleSuccess,
    error: newRoleError,
  } = useSelector((state) => state.newRole);

  const {
    loading,
    permissions: permissionList,
    error,
    success,
  } = useSelector((state) => state.allPermissions);


  useEffect(() => {
    dispatch(allPermissionsRequest());
  }, [dispatch, navigate]);

  // when submit add new role request, open the add new role dialog, display the request results, success or error.
  const [addNewRoleDialog, setNewRoleDialog] = useState(false);

  const addNewRoleRequest = (values) => {
    setNewRoleDialog(true);
    // get selected permissions id
    let selectedPemissons = [];

    values.permissions.forEach((val) => {
        if (val.permission) {
          selectedPemissons.push(val.id);
        }
    });

    let newRoleValues = {
      roleName: values.roleName,
      rolePermissions: selectedPemissons,
    };

    dispatch(newRoleRequest(newRoleValues));
  };

  const closeDialogWithSuccess = () => {
    navigate("/settings/roles", { replace: true, from: location });
  }

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Add New Role"
        successMessage="The new role was added successfully. You can now assign this role to users."
        isDialogOpen={addNewRoleDialog}
        closeDialog={() => setNewRoleDialog(false)}
        loading={newRoleLoading}
        loadingMessage="Processing request..."
        success={newRoleSuccess}
        error={newRoleError}
        btnValue="Confirm"
        confirmAction = {closeDialogWithSuccess}
      />

      <Breadcrumbs screenName="New Role" />
      <ScreenTitle title="New Role" />
          <div className={commonStyle.pageContentContainer}>
            {loading ? (
              <Loader mess="Requesting permissions, please wait a moment..." />
            ) : success ? (
              <RoleDetailForm
                onSubmit={addNewRoleRequest}
                roleDetails={{ roleName: "", permissions: permissionList.data }}
                btnName="Create New Role"
                type="new"
              />
            ) : (
              <ErrorMessage mess={error} />
            )}
          </div>
        </div>

  );
}
