import axios from "axios";


const SERVER_URL = process.env.REACT_APP_SERVER_URL;


 export const getCustomerDetailsApiRequest = async (customerId, config) => {

   try {
    
     const response = await axios.get(SERVER_URL + `/customers/${customerId}`, config);
     return response;
   } catch (error) {
    throw error;
   }
 
 };


