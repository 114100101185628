import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { systemSettingsDetailsRequest, updateSystemSettingsRequest } from "../../../actions/systemSettingsActions";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  EditButton,
  SimpleBlueButton,
} from "../../../components/editComponents";
import {
  ReadOnlyField,
  StyledInput,
  StyledSelect
} from "../../../components/inputFields";
import {
  ErrorMessage,
  Loader,
  OrderNumberPatternDescriptionDialog,
  SimpleDialog,
  TipsText
} from "../../../components/viewComponents";
import { generateOrderNumber } from "../../../functions/functions";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";
import SystemSettingsTab from "./SystemSettingsTab";

export default function SystemSettingsSO() {
  useDocumentTitle("System Settings(SO)");
  const dispatch = useDispatch();

  const TAX_TYPES = [
    { id: 1, label: "Tax Inclusive", value: "inclusive" },
    { id: 2, label: "Tax Exclusive", value: "exclusive" },
  ];

  const { loading, settingDetails, error, success } = useSelector(
    (state) => state.systemSettingDetails
  );

  const { loading: updateLoading, error: updateError, success: updateSuccess } = useSelector(
    (state) => state.updateSystemSettings
  );


  const [formState, setFormState] = useState({
    taxRule: {},
    orderNumberRule: "",
    invoiceNumberRule: "",
    // fileNameRule: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [updateDialog, setUpdateDialog] = useState(false);
  const [exampleOrderNo, setExampleOrderNo] = useState("");
  const [exampleInvoiceNo, setExampleInvoiceNo] = useState("");
  const [descriptionDialog, setDescriptionDialog] = useState(false);

  useEffect(() => {
    dispatch(systemSettingsDetailsRequest("sale_order"));
  }, [dispatch, updateSuccess]);

  useEffect(() => {
    if (success) {
      let findTaxRule = settingDetails.find(
        (x) => x.settingName === "sale_order_default_tax_rule"
      );
      let tax_rule_value = "";
      if (findTaxRule) {
        tax_rule_value = TAX_TYPES.find(
          (x) => x.value === findTaxRule.settingValue
        );
      }
      let findOrderNumberRule = settingDetails.find(
        (x) => x.settingName === "sale_order_number_rule"
      );
      let findInvNumberRule = settingDetails.find(
        (x) => x.settingName === "sale_invoice_number_rule"
      );
      setFormState({
        taxRule: tax_rule_value ? tax_rule_value : "",
        orderNumberRule: findOrderNumberRule
          ? findOrderNumberRule.settingValue
          : "",
        invoiceNumberRule: findInvNumberRule ? findInvNumberRule.settingValue : ""
      });
    }
  }, [success]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const checkValidation = () => {
    const hasErrors = validate(formState);

    if (Object.values(hasErrors).length === 0) {
      // setEdit(false);
      let submitValues = {
        taxRule: formState.taxRule ? formState.taxRule.value : "inclusive",
        orderNumberRule: formState.orderNumberRule,
        invoiceNumberRule: formState.invoiceNumberRule
      }
      saveChanges(submitValues);
    } else {
      setFormErrors(hasErrors);
    }
  };

  // check input validation
  const validate = (values) => {
    const errors = {};

    if (!values.taxRule) {
      errors.taxRule = "Tax rule name is required!";
    }
    if (!values.orderNumberRule) {
      errors.orderNumberRule = "Sale order number generation pattern is required!";
    }
    if (!values.invoiceNumberRule) {
      errors.invoiceNumberRule = "Sale invoice number generation pattern is required!";
    }

    return errors;
  };

  const generateExampleNumber = (e) => {
    e.preventDefault();
    let orderNumber = generateOrderNumber(formState.orderNumberRule);
    setExampleOrderNo(orderNumber);
  };

  const generateExampleInvoiceNumber = (e) => {
    e.preventDefault();
    let orderNumber = generateOrderNumber(formState.invoiceNumberRule);
    setExampleInvoiceNo(orderNumber);
  };

  const openDescriptionDialog = (e) => {
    e.preventDefault();
    setDescriptionDialog(true);
  };

  const saveChanges = (values) => {
    dispatch(updateSystemSettingsRequest("sale_order", values));
    setUpdateDialog(true);
    setIsEdit(false);
  }

  return (
    <div className={commonStyle.pageContainer}>
      <OrderNumberPatternDescriptionDialog
        title="Order Number Pattern Guide"
        isDialogOpen={descriptionDialog}
        closeDialog={() => setDescriptionDialog(false)}
      />
      <SimpleDialog
        title="Update Sale Order Settings"
        successMessage="Updated successfully."
        isDialogOpen={updateDialog}
        closeDialog={() => setUpdateDialog(false)}
        loading={updateLoading}
        loadingMessage="Processing request..."
        success={updateSuccess}
        error={updateError}
        btnValue="OK"
        confirmAction={() => setUpdateDialog(false)}
      />

      <Breadcrumbs screenName="System Settings" />
      <div className={commonStyle.tabsContainer}>
        <SystemSettingsTab tabName={"sale"} />
        <div className={commonStyle.settingsContent}>
          {loading ? (
            <Loader mess="Requesting  details, please wait a moment..." />
          ) : success ? (
            <div className="w-1/2 mt-8 p-4">
              <div className="w-full flex flex-row justify-between">
                <p className="text-brandColor">Sale Order Settings</p>
                {!isEdit && <EditButton action={() => setIsEdit(true)} />}
              </div>

              {isEdit ? (
                <StyledSelect
                  label={"Default Tax Rule for Sale Orders"}
                  clearable={false}
                  placeHolder="Select tax rule"
                  selectOptions={TAX_TYPES}
                  value={formState.taxRule}
                  onChange={(taxRule) =>
                    handleChange({
                      target: { value: taxRule, name: "taxRule" },
                    })
                  }
                  error={formErrors.taxRule}
                />
              ) : (
                <ReadOnlyField
                  label={"Default Tax Rule for Sale Orders"}
                  value={formState.taxRule.label}
                />
              )}

              {isEdit ? (
                <StyledInput
                  label={"Sale Order Number Generation Pattern"}
                  placeHolder={
                    "Pattern for generating sale order numbers (e.g., PO%d%d%d%d)"
                  }
                  type="text"
                  name="orderNumberRule"
                  value={formState.orderNumberRule}
                  onChange={(orderNumberRule) =>
                    handleChange({
                      target: {
                        value: orderNumberRule,
                        name: "orderNumberRule",
                      },
                    })
                  }
                  error={formErrors.orderNumberRule}
                />
              ) : (
                <ReadOnlyField
                  label={"Sale Order Number Generation Pattern"}
                  value={formState.orderNumberRule}
                />
              )}
                <div className="flex flex-row items-start mt-2 justify-between">
                <div className="flex flex-col">
                  <button
                    className="text-green-500 hober:cursor-pointer hover:underline"
                    onClick={generateExampleNumber}
                  >
                    Click here to see an example order number
                  </button>
                  {exampleOrderNo && <p className="mt-1">{exampleOrderNo}</p>}
                </div>
             
              </div>
               {isEdit ? (
                <StyledInput
                  label={"Sale Invoice Number Generation Pattern"}
                  placeHolder={
                    "Pattern for generating sale invoice numbers (e.g., PO%d%d%d%d)"
                  }
                  type="text"
                  name="invoiceNumberRule"
                  value={formState.invoiceNumberRule}
                  onChange={(invoiceNumberRule) =>
                    handleChange({
                      target: {
                        value: invoiceNumberRule,
                        name: "invoiceNumberRule",
                      },
                    })
                  }
                  error={formErrors.invoiceNumberRule}
                />
              ) : (
                <ReadOnlyField
                  label={"Sale Invoice Number Generation Pattern"}
                  value={formState.invoiceNumberRule}
                />
              )}
                <div className="flex flex-row items-start mt-2 justify-between">
                <div className="flex flex-col">
                  <button
                    className="text-green-500 hober:cursor-pointer hover:underline"
                    onClick={generateExampleInvoiceNumber}
                  >
                    Click here to see an example invoice number
                  </button>
                  {exampleInvoiceNo && <p className="mt-1">{exampleInvoiceNo}</p>}
                </div>
                <button
                    className="text-green-500 hober:cursor-pointer hover:underline"
                    onClick={openDescriptionDialog}
                  >
                    How to define my order number pattern?
                  </button>
          
              </div>
            
              {isEdit && (
                <div className="w-full mt-6">
                  <SimpleBlueButton
                    name="Save Changes"
                    action={checkValidation}
                  />
                </div>
              )}
            </div>
          ) : error ? (
            <ErrorMessage mess={error} />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
