function formatDate(
  date = new Date(),
  format = "DD/MM/YYYY",
  timeZone = "Pacific/Auckland",
  withTime = false
) {
  let formattedDate = "";

  const year = date.toLocaleString("en-US", {
    timeZone: timeZone,
    year: "numeric",
  });
  const month = date.toLocaleString("default", {
    month: "2-digit",
    timeZone: timeZone,
  });
  const day = date.toLocaleString("en-US", {
    timeZone: timeZone,
    day: "2-digit",
  });

  const time = date.toLocaleTimeString("default", { timeZone: timeZone });

  if (withTime) {
    if (format === "MM/DD/YYYY") {
      formattedDate = [month, day, year].join("/") + "  " + time;
    } else if (format === "DD/MM/YYYY") {
      formattedDate = [day, month, year].join("/") + "  " + time;
    } else if (format === "YYYY/MM/DD") {
      formattedDate = [year, month, day].join("/") + "  " + time;
    } else {
      formattedDate = [day, month, year].join("/") + "  " + time;
    }
  } else {
    if (format === "MM/DD/YYYY") {
      formattedDate = [month, day, year].join("/");
    } else if (format === "DD/MM/YYYY") {
      formattedDate = [day, month, year].join("/");
    } else if (format === "YYYY/MM/DD") {
      formattedDate = [year, month, day].join("/");
    } else {
      formattedDate = [day, month, year].join("/");
    }
  }

  return formattedDate;
}

function getTimeStamp(day, timeZone) {
  //new Date() is current time (whatever the zone - time object)
  let date = new Date();

  //displays the current date (no time, so midnight) in the specified zone (string of time with zone ref)
  let userTimeZoneDate = date.toLocaleDateString("en-US", {
    timeZone: timeZone,
  });
  //new Date(<above midnight time in zone>) parse the midnight time in zone and display corresponding current timezone time (time object)
  let newDate = new Date(userTimeZoneDate);
  // convert to timeStamp
  let timeStamp = Math.floor(newDate.getTime() / 1000);
  return timeStamp;
}

function getTimeStamps(day, timeZone) {
  let now = new Date();
  let todayMidnight = new Date(
    now.toLocaleDateString("en-US", { timeZone: timeZone })
  );

  let todayStart = Math.floor(todayMidnight.getTime() / 1000);
  let todayEnd = Math.floor(
    (todayMidnight.setDate(todayMidnight.getDate() + 1) - 1) / 1000
  );

  let startTimestamp = todayStart;
  let endTimestamp = todayEnd;

  let startDate;
  let endDate;
  switch (day) {
    case "today":
      return [startTimestamp, endTimestamp];
    case "7days":
      startDate = new Date(todayMidnight.setDate(todayMidnight.getDate() - 8));
      startTimestamp = Math.floor(startDate.getTime() / 1000);

      endDate = new Date(startDate.setDate(startDate.getDate() + 6));
      endTimestamp = Math.floor(endDate.setHours(23, 59, 59, 999) / 1000);

      return [startTimestamp, endTimestamp];
    case "15days":
      startDate = new Date(todayMidnight.setDate(todayMidnight.getDate() - 16));
      startTimestamp = Math.floor(startDate.getTime() / 1000);

      endDate = new Date(startDate.setDate(startDate.getDate() + 14));
      endTimestamp = Math.floor(endDate.setHours(23, 59, 59, 999) / 1000);

      return [startTimestamp, endTimestamp];

    case "30days":
      startDate = new Date(todayMidnight.setDate(todayMidnight.getDate() - 31));
      startTimestamp = Math.floor(startDate.getTime() / 1000);

      endDate = new Date(startDate.setDate(startDate.getDate() + 29));
      endTimestamp = Math.floor(endDate.setHours(23, 59, 59, 999) / 1000);

      return [startTimestamp, endTimestamp];
    default:
      return [startTimestamp, endTimestamp];
  }
}

function convToNZDate(date) {
  //new Date() is current time (whatever the zone - time object)
  //displays the current date (no time, so midnight) in the specified zone (string of time with zone ref)
  let nzTimeZoneDate = date.toLocaleDateString("en-US", {
    timeZone: "Pacific/Auckland",
  });
  //new Date(<above midnight time in zone>) parse the midnight time in zone and display corresponding current timezone time (time object)
  let nzdate = new Date(nzTimeZoneDate);

  // convert to timeStamp
  let timeStamp = Math.floor(nzdate.getTime() / 1000);
  return timeStamp;
}

function convtPrice(num) {
  let org_num = Number(num);
  let new_num = (Math.round(org_num * 100) / 100).toFixed(2);
  return new_num;
}

function convtDisplayPrice(num) {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  } else {
    return num.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
}

function convtQuantity(num) {
  const org_num = Number(num);
  const roundedNumber = Number(org_num.toFixed(3)); // Round to three decimal places
  return roundedNumber.toString();
}

function calculateTrialLeftDays(expireTime) {
  let diffInDays = -1;
  let today = new Date();
  let expireDate = new Date(expireTime * 1000);
  const diffInMs = expireDate - today;

  if (diffInMs > 0) {
    diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
  }

  return diffInDays;
}

function formatReportSummaryDate(
  dateString = new Date().toISOString(),
  format = "DD/MM/YYYY",
  timeZone = "Pacific/Auckland",
  withTime = false
) {
  let date = new Date(dateString); // Parse the input string into a Date object
  let formattedDate = "";

  const year = date.toLocaleString("en-US", {
    timeZone: timeZone,
    year: "numeric",
  });
  const month = date.toLocaleString("en-US", {
    // Ensure consistent locale for month
    timeZone: timeZone,
    month: "2-digit",
  });
  const day = date.toLocaleString("en-US", {
    // Ensure consistent locale for day
    timeZone: timeZone,
    day: "2-digit",
  });

  // Only include time if requested
  const time = withTime
    ? " " +
      date.toLocaleTimeString("en-US", {
        timeZone: timeZone,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false, // Adjusted to use 24-hour format if time is included
      })
    : "";

  switch (format) {
    case "MM/DD/YYYY":
      formattedDate = `${month}/${day}/${year}${time}`;
      break;
    case "DD/MM/YYYY":
      formattedDate = `${day}/${month}/${year}${time}`;
      break;
    case "YYYY/MM/DD":
      formattedDate = `${year}/${month}/${day}${time}`;
      break;
    default:
      // Defaults to "DD/MM/YYYY" if format is unrecognized
      formattedDate = `${day}/${month}/${year}${time}`;
  }

  return formattedDate;
}

function highlightText(text, keyword) {
  keyword = String(keyword).trim();
  if (!keyword.length) return text;
  // keywords sorted by length (long => short)
  const keywords = String(keyword)
    .trim()
    .toLowerCase()
    .split(/\s+/)
    .sort((a, b) => b.length - a.length);
  const text_lowered = String(text).toLowerCase();
  let highlights = [];
  for (const k of keywords) {
    let idx = null;
    let len = k.length;
    let start_idx = 0;
    while (true) {
      idx = text_lowered.indexOf(k, start_idx);
      if (idx >= 0) {
        highlights.push([idx, len]);
        start_idx += len;
      } else {
        break;
      }
    }
  }
  highlights = highlights.sort((a, b) => a[0] - b[0]);

  let parts = [];
  let idx_text = 0;
  let idx_highlights = 0;
  while (true) {
    if (idx_highlights >= highlights.length) {
      if (idx_text < text.length) {
        parts.push([text.substr(idx_text), 0]);
      }
      break;
    }
    if (idx_text < highlights[idx_highlights][0]) {
      parts.push([text.substring(idx_text, highlights[idx_highlights][0]), 0]);
      idx_text = highlights[idx_highlights][0];
      continue;
    }
    if (idx_text == highlights[idx_highlights][0]) {
      parts.push([text.substr(idx_text, highlights[idx_highlights][1]), 1]);
      idx_text += highlights[idx_highlights][1];
    }
    idx_highlights += 1;
  }
  return (
    <>
      {parts.map((p, idx) =>
        p[1] ? (
          <span key={idx} className="text-brandColor font-medium">
            {p[0]}
          </span>
        ) : (
          p[0]
        )
      )}
    </>
  );
}

function autoGenerateSaleOrderNumber() {
  let orderNumber;
  let min = 10;
  let max = 10999000;
  let rand = (min + Math.random() * (max - min)).toFixed(0);

  let dateObj = new Date();
  let month = dateObj.getUTCMonth() + 1; //months from 1-12
  let year = dateObj.getUTCFullYear();
  let hours = dateObj.getHours();
  orderNumber = "SO" + hours + year + month + rand;
  return orderNumber;
};


const autoGeneratePurchaseOrderNumber = () => {
  let orderNumber;
  let min = 10;
  let max = 10999000;
  let rand = (min + Math.random() * (max - min)).toFixed(0);

  let dateObj = new Date();
  let month = dateObj.getUTCMonth() + 1; //months from 1-12
  let year = dateObj.getUTCFullYear();
  let hours = dateObj.getHours();
  orderNumber = "PO" + hours + year + month + rand;
  return orderNumber;
};


function getCurrentDate() {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}${month}${day}`;
}

function generateOrderNumber(format) {
  // Function to get a random number 0-9
  const getRandomDigit = () => Math.floor(Math.random() * 10);
  // Function to get a random uppercase letter A-Z
  const getRandomUppercase = () => String.fromCharCode(65 + Math.floor(Math.random() * 26));
  // Function to get a random lowercase letter a-z
  const getRandomLowercase = () => String.fromCharCode(97 + Math.floor(Math.random() * 26));
  // Replace placeholders in the format string
  let orderNumber = format.replace(/%d|%W|%w/g, (match) => {
      switch (match) {
          case '%d': return getRandomDigit();
          case '%W': return getRandomUppercase();
          case '%w': return getRandomLowercase();
          default: return match;
      }
  });
  return orderNumber;
}

export {
  formatDate,
  getTimeStamp,
  getTimeStamps,
  convToNZDate,
  convtPrice,
  convtQuantity,
  calculateTrialLeftDays,
  formatReportSummaryDate,
  highlightText,
  autoGenerateSaleOrderNumber,
  autoGeneratePurchaseOrderNumber,
  convtDisplayPrice,
  getCurrentDate,
  generateOrderNumber
};
