import React, { useState } from "react";

import {
  SimpleBlueButton,
} from "../../../components/editComponents";
import {
  StyledInput,
} from "../../../components/inputFields";

export default function DepartmentsForm({ btnName, values, onSubmit }) {
  const [newValues, setNewValues] = useState(values);
  const [formErrors, setFormErrors] = useState({});

  const checkValidation = () => {
    const hasErrors = validate(newValues);

    if (Object.keys(hasErrors).length === 0) {
        onSubmit(newValues);  
    } else {
      setFormErrors(hasErrors);
    }
  };
  // handle change when input
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewValues({ ...newValues, [name]: value });
  };

  // check input validation
  const validate = (values) => {

    const errors = {};
    if (!values.name) {
      errors.name = "Department name is required!";
    }

    return errors;
  };

  return (
    <form>
      <StyledInput
        label="Department Name*"
        type="text"
        name="name"
        autofocus="autofocus"
        value={newValues.name}
        onChange={(name) =>
          handleChange({
            target: { value: name, name: "name" },
          })
        }
        error={formErrors.name}
      />
      <StyledInput
        label="Description"
        type="text"
        name="description"
        value={newValues.description}
        onChange={(description) =>
          handleChange({
            target: { value: description, name: "description" },
          })
        }
        error={formErrors.description}
      />
                 <div className="w-full mt-6"><SimpleBlueButton name={btnName} action={checkValidation} /></div>
      
    </form>
  );
}
