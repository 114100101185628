import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetPasswordRequest,
} from "../../actions/authAction";
import {
  LoadingButton,
  SimpleBlueButton
} from "../../components/editComponents";
import {
  StyledEmailInput
} from "../../components/inputFields";
import {
  ErrorMessage,
  SuccessText
} from "../../components/viewComponents";
import loginStyle from "./loginStyle.module.css";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function ForgotPassword({quitResetPwd}) {
  const dispatch = useDispatch();

  const {
    loading: resetPwdLoading,
    success: resetPwdSuccess,
    error: resetPwdError,
  } = useSelector((state) => state.resetPassword);

  // when logged push to orginal page

  const [userResetEmail, setResetEmail] = useState("");
  //define the error message

  const [formErrors, setFormErrors] = useState({});

  //////////////////////////////////

  // reset email

  const handleResetEmailChange = (value) => {
    const errors = {};
    let re = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (re.test(value)) {
      // this is a valid email address
      setFormErrors({});
      setResetEmail(value);
    } else {
      // invalid email, maybe show an error to the user.
      setResetEmail(value);
      errors.userResetEmail = "Please enter a valid email address";
      setFormErrors(errors);
    }
  };

  // reset password request submit
  const ResetPasswordRequest = () => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!userResetEmail) {
      setFormErrors({ userResetEmail: "Please enter your email address" });
    } else if (!regex.test(userResetEmail)) {
      setFormErrors({ userResetEmail: "Email address tEmaiis invalid" });
    } else {
      dispatch(resetPasswordRequest({ userEmail: userResetEmail }));
    }
  };
  ///////////////////////////////////////////////////////////////////////////////////

  return (
    <div>
      <h1 className={loginStyle.signin}>Reset Password</h1>
      {resetPwdSuccess ? (
        <SuccessText mess="Reset password email sent successfully!" />
      ) : (
        <div>
          <p>
            Please enter your email address, A reset password link will be sent
            to you.
          </p>

          {resetPwdError ? <ErrorMessage mess={resetPwdError} /> : <></>}

          <StyledEmailInput
            type="email"
            name="userResetEmail"
            autofocus="autofocus"
            value={userResetEmail}
            onChange={(userResetEmail) =>
              handleResetEmailChange(userResetEmail)
            }
            error={formErrors.userResetEmail}
          />
          <div className="w-full mt-4">
          {resetPwdLoading ? (
            <LoadingButton name="Sending email..." />
          ) : (
            <SimpleBlueButton
              name="Reset Password"
              action={ResetPasswordRequest}
            />
          )}
           </div>
          <p className={loginStyle.signinText} onClick={quitResetPwd}>
            Sign in
          </p>
        </div>
      )}
    </div>
  );
}
