import React, { useState } from "react";
import {
  SimpleBlueButton
} from "../../../components/editComponents";
import { StyledInput } from "../../../components/inputFields";

export default function SaleOrderShippingForm({ btnName, values, onSubmit }) {
  const [shippingValues, setShippingValues] = useState(values);
  const [formErrors, setFormErrors] = useState({});

  // handle change when input
  const handleChange = (e) => {
    const { name, value } = e.target;
    setShippingValues({ ...shippingValues, [name]: value });
  };


  // check input validation
  const checkValidation = () => {
    const hasErrors = validate(shippingValues);

    if (Object.keys(hasErrors).length === 0) {
      onSubmit(shippingValues);
    } else {
      setFormErrors(hasErrors);
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.shippingCompany) {
      errors.shippingCompany = "Shipping company is required!";
    }
    if (!values.trackingNo) {
      errors.trackingNo = "Tracking number is required!";
    }

    return errors;
  };

  return (
    <form>
      <StyledInput
        label="Shipping Company"
        type="text"
        name="shippingCompany"
        autofocus="autofocus"
        value={shippingValues.shippingCompany}
        onChange={(shippingCompany) =>
          handleChange({
            target: { value: shippingCompany, name: "shippingCompany" },
          })
        }
        error={formErrors.shippingCompany}
      />
      <StyledInput
        label="Tracking Number"
        type="text"
        name="trackingNo"
        value={shippingValues.trackingNo}
        onChange={(trackingNo) =>
          handleChange({
            target: { value: trackingNo, name: "trackingNo" },
          })
        }
        error={formErrors.trackingNo}
      />
 <div className="w-full mt-6">
      <SimpleBlueButton name={btnName} action={checkValidation} />
      </div>
    </form>
  );
}
