

export function serviceMiddleware(response) {
 
    if (!response.data.success) {
        if(response.data.error === -3){
            localStorage.removeItem("userInfo");
            throw Object.assign(
                new Error("Your session has expired. Please refresh the page and log in again."),
                { code: 1001 }
             );
        }
        if(response.data.error === -2){
            throw Object.assign(
                new Error("You do not have permission do this action"),
                { code: 1002 }
             );
        }  
    }   
    return response;
}