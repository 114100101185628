export const IMPORT_CSV_FILE_REQUEST = "IMPORT_CSV_FILE_REQUEST";
export const IMPORT_CSV_FILE_SUCCESS = "IMPORT_CSV_FILE_SUCCESS";
export const IMPORT_CSV_FILE_FAIL = "IMPORT_CSV_FILE_FAIL";

export const EXPORT_CSV_FILE_REQUEST = "EXPORT_CSV_FILE_REQUEST";
export const EXPORT_CSV_FILE_SUCCESS = "EXPORT_CSV_FILE_SUCCESS";
export const EXPORT_CSV_FILE_FAIL = "EXPORT_CSV_FILE_FAIL";


export const IO_LOGS_REQUEST = "IO_LOGS_REQUEST";
export const IO_LOGS_SUCCESS = "IO_LOGS_SUCCESS";
export const IO_LOGS_FAIL = "IO_LOGS_FAIL";