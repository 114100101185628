import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ScreenTitle } from "../../../components/editComponents";
import {
  SimpleDialog,
  ErrorMessage,
  Loader,
  MappingXeroAccountDialog,
} from "../../../components/viewComponents";
import inventoryStyle from "../inventoryStyle.module.css";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  productOptionsRequest,
  editProductRequest,
  productDetailsRequest,
} from "../../../actions/productActions";
import ProductDetailsForm from "./ProductDetailsForm";
import { productTypeOptions } from "../../../constantsData/productType";

export default function EditProductScreen() {
  useDocumentTitle("Edit Product");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  //////////////////////////////////////////////////////////////////////////////
  const productStatus = location.state;
  const { id: productId } = useParams();
  //////////////////////////////////////////////////////////////////////////////
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const { loading, options, error, success } = useSelector(
    (state) => state.productOptions
  );
  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  // edit product dialog
  const [dialog, setDialog] = useState(false);

  const [xeroDialog, setXeroDialog] = useState(false);

  const [productData, setProductData] = useState("");
  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    dispatch(productOptionsRequest());
    dispatch(productDetailsRequest(productId, "edit"));
  }, [dispatch, productId]);
  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  const {
    loading: detailsLoading,
    productInfo,
    error: detailsError,
    success: detailsSuccess,
  } = useSelector((state) => state.productDetails);

  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
  } = useSelector((state) => state.updateProduct);
  // get edit product details

  const editProductValues = () => {
    let data = {
      productName: productInfo.basicInfo.productName,
      type: productTypeOptions.filter(
        (x) => x.value === productInfo.basicInfo.type
      )[0],
      sku: productInfo.basicInfo.sku,
      unit: options.units.filter(
        (x) => Number(x.id) === Number(productInfo.basicInfo.unitId)
      )[0],
      category: options.category.filter(
        (x) => Number(x.id) === Number(productInfo.basicInfo.categoryId)
      )[0],
      reference: productInfo.basicInfo.reference,
      description: productInfo.basicInfo.description,
      image: productInfo.basicInfo.image,
      originalManaged: productInfo.basicInfo.managed,
      managed: productInfo.basicInfo.managed,
      serialized: productInfo.basicInfo.serialized,
      batchTracked: productInfo.basicInfo.batchTracked,
      supplier: options.suppliers.filter(
        (x) => Number(x.id) === Number(productInfo.basicInfo.supplierId)
      )[0],
      hasAttrs: productInfo.basicInfo.hasAttrs,
      attrs: productInfo.basicInfo.attrs,
      hasStockOnHand: productInfo.basicInfo.hasStockOnHand,
      hasBatches: productInfo.basicInfo.hasBatches,
    };

    return data;
  };

  const autoGenerateSku = () => {
    let skuValue = "";
    let min = 1;
    let max = 10000000;
    let rand = (min + Math.random() * (max - min)).toFixed(0);

    let dateObj = new Date();
    let month = dateObj.getUTCMonth() + 1; //months from 1-12
    let year = dateObj.getUTCFullYear();
    skuValue = "RA" + year + month + rand;
    return skuValue;
  };
  //////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////
  const autoGenerateVariantSku = () => {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let uniqueCode = "";
    let timestamp = new Date().getTime(); // Current timestamp in milliseconds

    // Convert timestamp to a base that matches the number of characters we have (36) to ensure uniqueness
    let base36Timestamp = timestamp.toString(36).toUpperCase();
    let randIndex;

    // Extract the last 4 characters from the base36Timestamp to use as part of the unique code
    uniqueCode += base36Timestamp.slice(-4);

    // Generate two additional random characters from chars to complete the six-character code
    for (let i = 0; i < 2; i++) {
      randIndex = Math.floor(Math.random() * chars.length);
      uniqueCode += chars[randIndex];
    }

    return uniqueCode;
  };
  ///////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////

  const saveProductInfo = (values, isDraft) => {
    let attributes = [];
    if (values.basicInfo.hasAttrs) {
      for (let attr of values.basicInfo.attrs) {
        let opts = [];
        for (let opt of attr.attrOptions) {
          opts.push(opt.optionName);
        }
        attributes.push({ name: attr.attrName, opts: opts });
      }
    }

    let locationIds = [];

    // auto generate variant sku code
    values.variants.forEach((element) => {
      if (!element.variantSku) {
        element.variantSku = autoGenerateVariantSku();
      }
    });

    let productValues = {
      draft: isDraft,
      syncXero: false,
      basicInfo: {
        productName: values.basicInfo.productName,
        type: values.basicInfo.type.value,
        sku: values.basicInfo.sku ? values.basicInfo.sku : autoGenerateSku(),
        categoryId: values.basicInfo.category
          ? values.basicInfo.category.id
          : "",
        description: values.basicInfo.description,
        attributes: attributes,
        reference: values.basicInfo.reference,
        image: values.basicInfo.image,
        managed: values.basicInfo.managed,
        serialized: values.basicInfo.serialized,
        batchTracked: values.basicInfo.batchTracked,
        supplierId: values.basicInfo.supplier
          ? values.basicInfo.supplier.id
          : "",
        unitId: values.basicInfo.unit.id,
        locationId: locationIds,
      },

      attrsStock: values.variants,
    };

    setProductData(productValues);

    if (
      userDetailsSuccess &&
      userDetails.data.orgInfo.xeroConnected &&
      !userDetails.data.orgInfo.xeroConfigured
    ) {
      setXeroDialog(true);
    } else {
      setDialog(true);
      dispatch(editProductRequest(productValues, productId));
    }
  };

  const closeDialogWithSuccess = () => {
    navigate("/inventory/product-list", { replace: true, from: location });
  };

  const proceedWithoutUpdateToXero = () => {
    setXeroDialog(false);
    setDialog(true);
    dispatch(editProductRequest(productData, productId));
  };

  return (
    <div className={commonStyle.pageContainer}>
      <MappingXeroAccountDialog
        title="Update Product"
        isDialogOpen={xeroDialog}
        closeDialog={() => setXeroDialog(false)}
        proceedAction={proceedWithoutUpdateToXero}
      />
      <SimpleDialog
        title="Update Product"
        successMessage="Product updated successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={updateLoading}
        loadingMessage="Processing request..."
        success={updateSuccess}
        error={updateError}
        confirmAction={closeDialogWithSuccess}
      />

      <Breadcrumbs screenName="Edit Product" />
      <ScreenTitle title="Edit Product" status="Edit" />

      {loading ? (
        <Loader mess="Requesting data, please wait a moment..." />
      ) : success ? (
        detailsLoading ? (
          <Loader mess="Requesting product details, please wait a moment..." />
        ) : detailsSuccess ? (
          productStatus === "Draft" ? (
            <div className={inventoryStyle.formCon}>
              <ProductDetailsForm
                type="editDraft"
                initialBasicInfo={editProductValues}
                initialVariants={productInfo.attrsDetails}
                onSave={saveProductInfo}
                btnName="Create New Product"
              />
            </div>
          ) : (
            <div className={inventoryStyle.formCon}>
              <ProductDetailsForm
                type="editActive"
                initialBasicInfo={editProductValues}
                initialVariants={productInfo.attrsDetails}
                onSave={saveProductInfo}
                btnName="Create New Product"
              />
            </div>
          )
        ) : (
          <ErrorMessage mess={detailsError} />
        )
      ) : (
        <ErrorMessage mess={error} />
      )}
    </div>
  );
}
