import React from "react";
import loginStyle from "./loginStyle.module.css";
import {
  AiOutlineLock,
  AiOutlineIdcard,
  AiOutlineFileDone,
} from "react-icons/ai";

export default function RegisterSteps({ currentStep, action }) {
  let step1ClassName;
  let step2ClassName;
  let step3ClassName;
  let stepLine1;
  let stepLine2;

  if (currentStep === "step1") {
    step1ClassName = loginStyle.stepColored;
    stepLine1 = loginStyle.stepLine;
    stepLine2 = loginStyle.stepLine;
  } else {
    step1ClassName = loginStyle.step;
    
  }
  if (currentStep === "step2") {
    step1ClassName = loginStyle.stepColored;
    step2ClassName = loginStyle.stepColored;
    stepLine1 = loginStyle.stepLineColored;
    stepLine2 = loginStyle.stepLine;
  } else {
    step2ClassName = loginStyle.step;
  }
  if (currentStep === "step3") {
    step1ClassName = loginStyle.stepColored;
    step2ClassName = loginStyle.stepColored;
    step3ClassName = loginStyle.stepColored;
    stepLine1 = loginStyle.stepLineColored;
    stepLine2 = loginStyle.stepLineColored;
  } else {
    step3ClassName = loginStyle.step;
  }

  return (
    <div className={loginStyle.stepsContainer}>
      <div className={step1ClassName}>
        <AiOutlineLock className={loginStyle.stepIcon} />
        <p onClick={() =>action("step1")} className={loginStyle.stepText}>Login Details</p>
      </div>
      <hr className={stepLine1} />
      <div className={step2ClassName}>
        <AiOutlineIdcard className={loginStyle.stepIcon} />
        <p onClick={() =>action("step2")} className={loginStyle.stepText}> Organization Details</p>
      </div>
      <hr className={stepLine2} />
      <div className={step3ClassName}>
        <AiOutlineFileDone className={loginStyle.stepIcon} />
        <p className={loginStyle.stepText}>Finish</p>
      </div>
    </div>
  );
}
