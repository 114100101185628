import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  ScreenTitle,
  DownloadButton,
  RefreshButton,
} from "../../../components/editComponents";
import ProductIOTabs from "./ProductIOTabs";
import commonStyle from "../../../style/commonStyle.module.css";
import ioStyle from "../../io/ioStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";
import {
  ErrorMessage,
  Loader,
  Tag,
  ViewDateWithTime,
  SimpleDialog,
} from "../../../components/viewComponents";
import { FaFileCsv } from "react-icons/fa";
import {
  importExportLogsRequest,
  exportCsvRequest,
} from "../../../actions/IOActions";

export default function ExportProductScreen() {
  useDocumentTitle("Export Products");
  const dispatch = useDispatch();
  ////////////////////////////////////////////////////////////////////////////////////////////

  const [exportDialog, setExportDialog] = useState(false);

  const {
    loading: logsLoading,
    logsList,
    error: logsError,
    success: logsSuccess,
  } = useSelector((state) => state.IOLogs);

  const { loading, success, error } = useSelector((state) => state.exportCsv);

  ////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    dispatch(importExportLogsRequest("product", "EXPORT"));
  }, [dispatch, success]);
  ////////////////////////////////////////////////////////////////////////////////////////////
  const createExportCsvTask = () => {
    setExportDialog(true);
    dispatch(exportCsvRequest("product"));
  };

  const refreshData = () => {
    dispatch(importExportLogsRequest("product", "EXPORT"));
  };

  return (
    <div className={commonStyle.pageContainer}>
       <Breadcrumbs screenName={"Export Products"} />
       <ScreenTitle title={"Export Products"} />
      

      <SimpleDialog
        title="Export"
        successMessage="Export task added successfully."
        isDialogOpen={exportDialog}
        closeDialog={() => setExportDialog(false)}
        loading={loading}
        loadingMessage="Processing request..."
        success={success}
        error={error}
        confirmAction={() => setExportDialog(false)}
      />

      <div className={commonStyle.tabsContainer}>
        <ProductIOTabs tabName="exportProducts" />

        <div className={commonStyle.settingsContent}>
          <div className={ioStyle.contentWrapper}>
            <div className={ioStyle.importTips}>
              <p>
                Click below button to create a new export products
                task, once the task completed, you can download csv file in
                below table. .
              </p>
            </div>
            <DownloadButton
              name="Create New Export Task"
              action={createExportCsvTask}
            />
            <div style={{ marginTop: "40px",display: "flex", justifyContent: "space-between" }}>
              <h2>Export Tasks</h2>
              <RefreshButton name="Refresh Task Status" action={refreshData} />
            </div>

            {logsLoading ? (
              <Loader mess="Requesting data, please wait a moment..." />
            ) : logsSuccess ? (
              <table className={commonStyle.pageTable}>
                <thead>
                  <tr>
                    {/* <th></th> */}
                    <th>Date</th>
                    <th>Operator</th>
                    <th>Status</th>
                    <th>Result</th>
                  </tr>
                </thead>
                <tbody>
                  {logsList.length > 0 ? (
                    logsList.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <ViewDateWithTime value={item.atime} />
                        </td>

                        <td>
                          {item.operatorFirstName} {item.operatorLastName}{" "}
                        </td>
                        <td>
                          {item.status === "QUEUED" ? (
                            <Tag name={item.status} color="yellow" />
                          ) : item.status === "PROCESSING" ? (
                            <Tag name={item.status} color="green" />
                          ) : item.status === "SUCCESS" ? (
                            <Tag name={item.status} color="blue" />
                          ) : item.status === "FAILED" ? (
                            <Tag name={item.status} color="red" />
                          ) : (
                            <></>
                          )}
                        </td>
                        <td>
                          {item.status === "SUCCESS" ? (
                            <a
                              href={item.outputFileUrl}
                              download={item.outputFileUrl}
                              rel="noreferrer"
                              className={ioStyle.downloadBtnCon}
                            >
                              <FaFileCsv className={ioStyle.downloadBtnLogo} />
                              <span>Download Csv</span>
                            </a>
                          ) : item.status === "FAILED" ? (
                            item.errors
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>No export records.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            ) : logsError ? (
              <ErrorMessage mess={logsError} />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
