
import axios from "axios";


const SERVER_URL = process.env.REACT_APP_SERVER_URL;


 export const generatePacklingSlipRequest = async (orderId, config) => {

   try {
     const response = await axios.get(SERVER_URL + `/settings/pdf-templates/generate/packing-slip/${orderId}`, config);
     return response;
   } catch (error) {
    throw error;
   }
 };

 export const generateInvoiceRequest = async (orderId, config) => {

  try {
    const response = await axios.get(SERVER_URL + `/settings/pdf-templates/generate/invoice/${orderId}`, config);
    return response;
  } catch (error) {
   throw error;
  }
};

export const generatePurchaseOrderRequest = async (orderId, config) => {

  try {
    const response = await axios.get(SERVER_URL + `/settings/pdf-templates/generate/purchase-order/${orderId}`, config);
    return response;
  } catch (error) {
   throw error;
  }
};

  