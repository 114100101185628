import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  RefreshButton,
  ScreenTitle,
  SimpleBlueButton,
  TextLink,
} from "../../components/editComponents";
import Breadcrumbs from "../../components/Breadcrumbs";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import XeroIntegrationTab from "./XeroIntegrationTab";
import VerticalTabs from "../../components/VerticalTabs";
import {
  Loader,
  ViewDateWithTime,
  Tag,
  ErrorMessage,
} from "../../components/viewComponents";
import XeroLink from "../../components/XeroLink";
import { xeroTaskListRequest } from "../../actions/xeroActions";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function XeroSyncDataScreen() {
  useDocumentTitle("Xero Integration");
  const dispatch = useDispatch();
  ////////////////////////////////////////////////////////////////////////////////////////
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

 ////////////////////////////////////////////////////////////////////////////////////////
  const { loading, xeroTasks, error, success } = useSelector(
    (state) => state.xeroTaskList
  );
 

  const [restartTaskStatus, setRestartTaskStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  ////////////////////////////////////////////////////////////////////////////////////////
  const [activeTab, setActiveTab] = useState("sale_order_invoice");

  const tabs = [
    { id: 1, tabName: "Sale order", value: "sale_order_invoice" },
   // { id: 2, tabName: "Sale order journal", value: "sale_order_journal" },
    { id: 3, tabName: "Purchase order", value: "purchase_order_bill" },
    { id: 4, tabName: "Stock Adjustment", value: "on_hand_stock_journal" },
    { id: 4, tabName: "Products", value: "sync_product" },
  ];

  const changeTabAction = (tabName) => {
    setActiveTab(tabName);
  };
  ////////////////////////////////////////////////////////////////////////////////////////


  useEffect(() => {
    dispatch(xeroTaskListRequest());
    // get xero tasks
    
  }, [dispatch]);

  ////////////////////////////////////////////////////////////////////////////////////////
  const restartXeroTask = (taskId) => {
    // get xero connection status
    try {
      setRestartTaskStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      axios
        .get(SERVER_URL + `/integration/xero/restart_task/${taskId}`, config)
        .then(async (response) => {
          if (response.data.success) {
            setRestartTaskStatus((preState) => ({
              ...preState,
              loading: false,
              success: true,
              error: "",
            }));
            dispatch(xeroTaskListRequest());
          } else {
            setRestartTaskStatus((preState) => ({
              ...preState,
              loading: false,
              error:
                "Request restart Xero task falied, please try again later.",
            }));
          }
        })
        .catch((err) => {
          setRestartTaskStatus((preState) => ({
            ...preState,
            loading: false,
            error: "Request restart Xero task falied, please try again later.",
          }));
        });
    } catch (error) {
      setRestartTaskStatus((preState) => ({
        ...preState,
        loading: false,
        error: "Connection Error, please try again later.",
      }));
    }
  };

  const refreshData = () => {
    dispatch(xeroTaskListRequest());
  }

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Xero Integration" />
      <ScreenTitle title="Xero Integration" />
      <div className={commonStyle.tabsContainer}>
        <XeroIntegrationTab tabName="data" />
        <div className={commonStyle.settingsContent}>
          <div style={{display:"flex", justifyContent:"space-between",marginTop:"20px", marginBottom:"-10px"}}>
            <p>*This page displays the historical data synchronized from StockUnify to your Xero system.</p>
            <RefreshButton name="Refresh Task Status" action={refreshData} />
          </div>
          <div className={commonStyle.pageContentContainerTwoColumn}>
            <VerticalTabs
              tabsData={tabs}
              changeTab={changeTabAction}
              activeTab={activeTab}
            />
            <div className={commonStyle.templatesContentWrapper}>
              {loading ? (
                <Loader mess="Requesting Xero tasks data..." />
              ) : success ? (
                <table className={commonStyle.pageTable}>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Type</th>
                      <th>Xero Link</th>
                      <th>Status</th>
                      <th>Original Data</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {xeroTasks.length > 0 ? (
                      xeroTasks
                        .filter((x) => x.type === activeTab)
                        .map((item) => (
                          <tr key={item.id}>
                            <td>
                              <ViewDateWithTime value={item.atime} />
                            </td>
                            <td>
                              {" "}
                              {item.type === "on_hand_stock_journal" ? (
                                <p>Stock adjustment</p>
                              ) : item.type === "sale_order_invoice" ? (
                                <p>New sale order - Invoice</p>
                              ) : item.type === "sale_order_journal" ? (
                                <p>New sale order - COGS</p>
                              ) : item.type === "purchase_order_bill" ? (
                                <p>New purchase order</p>
                              ) : item.type === "sync_product" ? (
                                <p>Update product</p>
                              ) : (
                                <></>
                              )}
                            </td>

                            <td>
                              {item.status === "SUCCEED" ? (
                                <>
                                  {item.type === "on_hand_stock_journal" && item.reference.adjust_type === "RECEIVESTOCK" ? (
                                    <XeroLink
                                      invoiceId={item.invoice_id}
                                      type="stock_adjust_receive_stock"
                                    />
                                  ) : item.type === "on_hand_stock_journal" && item.reference.adjust_type === "STOCKDEDUCTION" ? (
                                    <XeroLink
                                      invoiceId={item.invoice_id}
                                      type="stock_adjust_deduct_stock"
                                    />
                                  ) : item.type === "sale_order_invoice" ? (
                                    <XeroLink
                                      invoiceId={item.invoice_id}
                                      type="sale_order_invoice"
                                    />
                                  ) : item.type === "sale_order_journal" ? (
                                    <XeroLink
                                      invoiceId={item.invoice_id}
                                      type="sale_order_journal"
                                    />
                                  ) : item.type === "purchase_order_bill" ? (
                                    <XeroLink
                                      invoiceId={item.invoice_id}
                                      type="purchase_order_bill"
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </td>

                            <td>
                              {item.status === "QUEUED" ? (
                                <Tag name={item.status} color="yellow" />
                              ) : item.status === "SUCCEED" ? (
                                <Tag name={item.status} color="green" />
                              ) : item.status === "PROCESSING" ? (
                                <Tag name={item.status} color="green" />
                              ) : item.status === "FAILED" ? (
                                <Tag name={item.status} color="red" />
                              ) : (
                                <></>
                              )}
                              {
                                item.status === "FAILED" && <p style={{marginTop:"4px", color:"var(--delete-btn)", fontSize:"12px"}}>{item.error}</p>
                              }
                            </td>

                            <td>
                              {"sale_order_id" in item.reference ? (
                                <TextLink
                                  path={`/sale/order/preview/${item.reference.sale_order_id}`}
                                  name="Go to sale order"
                                />
                              ) : "purchase_order_id" in item.reference ? (
                                <TextLink
                                  path={`/purchase/order/preview/${item.reference.purchase_order_id}`}
                                  name="Go to purchase order"
                                />
                              ) : "product_family_id" in item.reference ? (
                                <TextLink
                                  path={`/inventory/preview/${item.reference.product_family_id}`}
                                  name="Go to product"
                                />
                              ) : "adjust_id" in item.reference ? (
                                <TextLink
                                  path={`/inventory/bulk-stock-adjustment/preview/${item.reference.adjust_id}`}
                                  name="Go to stock adjustment"
                                />
                              ) : "family_id" in item.reference ? (
                                <TextLink
                                  path={`/inventory/preview/${item.reference.family_id}`}
                                  name="Go to product details"
                                />
                              ) : (
                                <></>
                              )}
                            </td>
                            <td>
                              {item.status === "FAILED" ? (
                                <>
                                  {restartTaskStatus.loading ? (
                                    <Loader />
                                  ) : (
                                    <SimpleBlueButton
                                      name="Retry"
                                      action={() => restartXeroTask(item.id)}
                                    />
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td>There are no records.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              ) : error ? (
                <ErrorMessage mess={error} />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
