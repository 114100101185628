import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import commonStyle from "../../../style/commonStyle.module.css";
import { EditButtonLink, ScreenTitle } from "../../../components/editComponents";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  ErrorMessage,
  Loader,
  ViewText,
  ViewDateWithTitle,
  SubTitle,
  PriceText,
  VariantTag,
  VariantSkuTag,
} from "../../../components/viewComponents";
import {
  stockTransferDetailsRequest,
} from "../../../actions/productActions";
import useDocumentTitle from "../../../useDocumentTitle";
import { convtPrice, convtQuantity } from "../../../functions/functions";

export default function PreviewStockTransferScreen() {
  useDocumentTitle("Preview Stock Transfer");
  const dispatch = useDispatch();
  const { id: transferid } = useParams();

  const { loading, transferDetails, error, success } = useSelector(
    (state) => state.stockTransferDetails
  );

  /////////////////////////////////////////////////////////////////////

  useEffect(() => {
    dispatch(stockTransferDetailsRequest(transferid));
  }, [dispatch, transferid]);

  /////////////////////////////////////////////////////////////////////////////

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Preview Stock Transfer" />
      <ScreenTitle title="Preview Stock Transfer" />
      <div className={commonStyle.pageContentContainer}>
        {loading ? (
          <Loader mess="Requesting details, please wait a moment..." />
        ) : success ? (
          <div>
            {/* purchase order infomation */}
            <SubTitle name="Order Basic Information" />

            <div className={commonStyle.previewContentContainer}>

            {transferDetails.basicInfo.status === "Approved" && (
              <div className="flex justify-end">
                   <EditButtonLink
                   path={`/inventory/bulk-stock-transfer/edit/${transferDetails.basicInfo.id}`}
                 />
                 </div>
            )}

              <div className={commonStyle.previewWrapper}>
                <div className={commonStyle.col1}>
                  <ViewText
                    title="Transfer Out Location"
                    value={transferDetails.basicInfo.outLocation}
                  />

                  <ViewText
                    title="Transfer in Location"
                    value={transferDetails.basicInfo.inLocation}
                  />
                  {transferDetails.basicInfo.projectName && 
                  <ViewText
                    title="Project"
                    value={transferDetails.basicInfo.projectCode + "-" + transferDetails.basicInfo.projectName}
                  />
                  }
                  <ViewText
                    title="Notes"
                    value={transferDetails.basicInfo.notes}
                  />
                </div>
                <div className={commonStyle.col3}>
                  <ViewDateWithTitle
                    title="Create Date"
                    value={transferDetails.basicInfo.atime}
                  />
                  <ViewText
                    title="Creator"
                    value={
                      transferDetails.basicInfo.creatorFirstName +
                      " " +
                      transferDetails.basicInfo.creatorLastName
                    }
                  />
                </div>
              </div>
            </div>

            {/* invoice section */}

            <SubTitle name="Transfer Products" />
            <div className={commonStyle.longDataTable}>
              <table className={commonStyle.pageTable}>
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Product Name</th>
                    <th>Unit</th>
                    <th>Transfer Cost</th>
                    <th>Quantity</th>
                    <th>Transfer Total Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {transferDetails.orderItems.map((item, index1) => {
                    return (
                      <tr key={index1}>
                        <td>{item.variantSku && <VariantSkuTag name={item.variantSku} /> }</td>
                        <td>{item.productName}</td>
                        <td>{item.unitName}</td>

                        <td>
                          <PriceText num={convtPrice(item.unitCost)} />
                        </td>
                        <td>{convtQuantity(item.quantity)}</td>

                        <td>
                          <PriceText num={convtPrice(item.totalCost)} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
    </div>
  );
}
