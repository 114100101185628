import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import filterStyle from "../style/filterStyle.module.css";
import { MdKeyboardArrowDown, MdFilterList } from "react-icons/md";
import { SimpleBlueButton } from "./editComponents";
import { DateInput, StyledMultiSelect } from "./inputFields";
import { ErrorText, Loader, ErrorMessage } from "./viewComponents";
import { productOptionsRequest } from "../actions/productActions";

function DateFilter({ onSelect }) {
  const { userDetails } = useSelector((state) => state.userDetail);
  const userTimeZone = userDetails.data.orgInfo.time_zone;

  let localToday = new Date();
  let userTimeZoneTodayStamp = localToday.toLocaleDateString("en-US", {
    timeZone: userTimeZone,
  });

  const dateOptions = [
    {
      startDate: new Date(
        new Date(userTimeZoneTodayStamp) - 7 * 24 * 60 * 60 * 1000
      ),
      endDate: new Date(
        new Date(userTimeZoneTodayStamp) - 1 * 24 * 60 * 60 * 1000
      ),
      label: "Last 7 days",
    },
    {
      startDate: new Date(
        new Date(userTimeZoneTodayStamp) - 15 * 24 * 60 * 60 * 1000
      ),
      endDate: new Date(
        new Date(userTimeZoneTodayStamp) - 1 * 24 * 60 * 60 * 1000
      ),
      label: "Last 15 days",
    },
    {
      startDate: new Date(
        new Date(userTimeZoneTodayStamp) - 30 * 24 * 60 * 60 * 1000
      ),
      endDate: new Date(
        new Date(userTimeZoneTodayStamp) - 1 * 24 * 60 * 60 * 1000
      ),
      label: "Last 30 days",
    },
  ];

  const [filterOpen, setFilterOpen] = useState(false);
  const [optionSelected, setOptionSelected] = useState(dateOptions[0]);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const [formErrors, setFormErrors] = useState({});

  const selectOption = (index) => {
    setOptionSelected(dateOptions[index]);
    setFilterOpen(false);
    setDatePickerOpen(false);

    let startTimestamp = Math.floor(
      dateOptions[index].startDate.setHours(0, 0, 0, 0) / 1000
    );
    let endTimestamp = Math.floor(
      dateOptions[index].endDate.setHours(23, 59, 59, 999) / 1000
    );

    // onSelect({startTime: new Date(startTimestamp * 1000), endTime: new Date(endTimestamp * 1000)});
    onSelect({ startTime: startTimestamp, endTime: endTimestamp });
  };

  const openDataPicker = () => {
    setDatePickerOpen(true);
    setOptionSelected({
      startDate: "",
      endDate: "",
      label: "Custom date range",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOptionSelected({ ...optionSelected, [name]: value });
  };

  const checkValidation = () => {
    const hasErrors = validate(optionSelected);

    if (hasErrors && Object.keys(hasErrors).length === 0) {
      setFilterOpen(false);
      setDatePickerOpen(false);
      setFormErrors({});

      let startTimestamp;
      let endTimestamp;

      startTimestamp = Math.floor(
        optionSelected.startDate.setHours(0, 0, 0, 0) / 1000
      );
      endTimestamp = Math.floor(
        optionSelected.endDate.setHours(23, 59, 59, 999) / 1000
      );

      //onSelect({startTime: new Date(startTimestamp * 1000), endTime: new Date(endTimestamp * 1000)});
      onSelect({ startTime: startTimestamp, endTime: endTimestamp });
    } else {
      setFormErrors(hasErrors);
    }
  };

  // check input validation
  const validate = (values) => {
    const errors = {};

    if (!values.startDate) {
      errors.startDate = "Please select a start date.";
    }
    if (!values.endDate) {
      errors.endDate = "Please select a end date.";
    }
    if (values.endDate) {
      if (values.endDate < values.startDate) {
        errors.dateError = "End date is less than start date.";
      }
    }

    return errors;
  };

  return (
    <div>
      <div
        className={
          filterOpen
            ? `${filterStyle.dateFilterCon} ${filterStyle.open}`
            : filterStyle.dateFilterCon
        }
      >
        <div
          className={filterStyle.selectedOptionCon}
          onClick={() => setFilterOpen(!filterOpen)}
        >
          <span>{optionSelected.label}</span>
          <MdKeyboardArrowDown className={filterStyle.toggleBtn} />
        </div>

        <div className={filterStyle.filterOptionsCon}>
          {dateOptions.map((item, index) => (
            <div
              key={index}
              className={
                optionSelected.label === item.label
                  ? `${filterStyle.dateOption} ${filterStyle.selected}`
                  : filterStyle.dateOption
              }
              onClick={() => selectOption(index)}
            >
              {item.label}
            </div>
          ))}
          <div
            className={
              datePickerOpen
                ? `${filterStyle.dateOption} ${filterStyle.selected}`
                : filterStyle.dateOption
            }
            onClick={openDataPicker}
          >
            Custom date range
          </div>
          <div
            className={
              datePickerOpen
                ? `${filterStyle.datePickerCon} ${filterStyle.datePickerOpen}`
                : filterStyle.datePickerCon
            }
          >
            <DateInput
              label="Start Date"
              error={formErrors.startDate}
              value={optionSelected.startDate}
              disableFuturedate
              onChange={(startDate) =>
                handleChange({
                  target: {
                    value: startDate,
                    name: "startDate",
                  },
                })
              }
            />
            <DateInput
              label="End Date"
              error={formErrors.endDate}
              value={optionSelected.endDate}
              disableFuturedate
              onChange={(endDate) =>
                handleChange({
                  target: {
                    value: endDate,
                    name: "endDate",
                  },
                })
              }
            />
            {formErrors.dateError ? (
              <ErrorText mess={formErrors.dateError} />
            ) : (
              <></>
            )}
            <div className="w-full my-4">
              <SimpleBlueButton
                name="Apply Filter"
                action={() => checkValidation()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ProductListFilter({ onSelect, initialFilter, filterOptions, onDelete }) {
  const dispatch = useDispatch();

  const statusOptions = [
    { id: "1", label: "Draft", value: "Draft" },
    { id: "2", label: "Active", value: "Active" },
  ];

  const {
    loading: optionsLoading,
    options,
    error: optionsError,
    success: optionsSuccess,
  } = useSelector((state) => state.productOptions);

  const [filterData, setFilterData] = useState(initialFilter);
  const [filterOpen, setFilterOpen] = useState(false);

  useEffect(() => {
    dispatch(productOptionsRequest());
  }, [dispatch,onDelete]);

  useEffect(() => {
    setFilterData(initialFilter);
  }, [initialFilter]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilterData((prev) => ({ ...prev, [name]: value }));
  };

  const checkValidation = () => {
    setFilterOpen(false);
    onSelect(filterData);
  };

  return (
    <div>
      <div
        className={`relative`} >
        <div
          className="w-11 h-11 ml-3 flex items-center justify-center rounded border border-solid border-brandColor hover:bg-brandColor hover:text-white hover:cursor-pointer"
          onClick={() => setFilterOpen(!filterOpen)}
        >
          <MdFilterList />
        </div>

        <div className={`${filterOpen ? "absolute block top-14 right-0 z-10 " : "hidden" }  w-[200px] bg-white shadow-lg`} >
          {optionsLoading ? (
            <Loader mess="Requesting..." />
          ) : optionsSuccess ? (
            <div className={filterStyle.productsFilterCon}>
              {filterOptions.includes("category") ? (
                <StyledMultiSelect
                  label="Product Category"
                  selectOptions={options.category}
                  value={filterData.category}
                  onChange={(category) =>
                    handleChange({
                      target: { value: category, name: "category" },
                    })
                  }
                />
              ) : (
                <></>
              )}

              {filterOptions.includes("location") ? (
                <StyledMultiSelect
                  label="Location"
                  selectOptions={options.location}
                  value={filterData.location}
                  onChange={(location) =>
                    handleChange({
                      target: { value: location, name: "location" },
                    })
                  }
                />
              ) : (
                <></>
              )}

              {filterOptions.includes("supplier") ? (
                <StyledMultiSelect
                  label="Supplier"
                  selectOptions={options.suppliers}
                  value={filterData.supplier}
                  onChange={(supplier) =>
                    handleChange({
                      target: { value: supplier, name: "supplier" },
                    })
                  }
                />
              ) : (
                <></>
              )}

              {filterOptions.includes("status") ? (
                <StyledMultiSelect
                  label="Status"
                  selectOptions={statusOptions}
                  value={filterData.status}
                  onChange={(status) =>
                    handleChange({
                      target: { value: status, name: "status" },
                    })
                  }
                />
              ) : (
                <></>
              )}
              <div className="w-full my-4">
                <SimpleBlueButton
                  name="Apply Filter"
                  action={() => checkValidation()}
                />
              </div>
            </div>
          ) : (
            <ErrorMessage mess={optionsError} />
          )}
        </div>
      </div>
    </div>
  );
}

export { DateFilter, ProductListFilter };
