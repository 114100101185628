

// org settings

import { SUBSCRIPTION_DETAILS_FAIL, SUBSCRIPTION_DETAILS_REQUEST, SUBSCRIPTION_DETAILS_SUCCESS } from "../constants/billingConstants";


export const subscriptionDetailsReducer = (
  state = { subscription: {} },
  action
) => {
  switch (action.type) {
    case SUBSCRIPTION_DETAILS_REQUEST:
      return { loading: true, success: false };
    case SUBSCRIPTION_DETAILS_SUCCESS:
      return { loading: false, subscription: action.payload, success: true };
    case SUBSCRIPTION_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
