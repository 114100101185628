import { pdf } from "@react-pdf/renderer";
import PurchaseOrderPDF from "./PurchaseOrderPDF";

const downloadPurchaseOrderPdf = async(orderInfo, userDetails) => {

    // Generate the PDF blob
    const blob = await pdf(
      <PurchaseOrderPDF orderInfo={orderInfo} userDetails={userDetails} />
    ).toBlob();

    const url = URL.createObjectURL(blob);
    const fileName = "Purchase_order_" + orderInfo.basicInfo.orderNumber + ".pdf"
    // Create a link to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);

    // Click the link to trigger the download
    link.click();

    // Clean up the link element and URL object
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  export {
    downloadPurchaseOrderPdf
  }