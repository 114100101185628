import React from "react";
import inventoryStyle from "./inventoryStyle.module.css";
import { MdLocationPin } from "react-icons/md";

export default function LocationTab({ locationsData, changeLocation, activeId }) {
  return (
    <div className={inventoryStyle.locationtabcon}>
      {locationsData.map((loc, index) => {
        return  activeId && Number(loc.locationId) === Number(activeId) ? (
          <div
            key={index}
            className={inventoryStyle.locationItemActive}
           
          >
            <MdLocationPin />
            <span style={{marginLeft:"4px"}}>{loc.locationName}</span>
          </div>
        ) : !activeId && index === 0 ? (
          <div
            key={index}
            className={inventoryStyle.locationItemActive}
           
          >
            <MdLocationPin />
            <span style={{marginLeft:"4px"}}>{loc.locationName}</span>
          </div>
        ) : (
          <div
            key={index}
            className={inventoryStyle.locationItem}
            onClick={() => changeLocation(loc.locationId)}
          >
             <MdLocationPin />
             <span style={{marginLeft:"4px"}}>{loc.locationName}</span>
          </div>
        );
      })}
    </div>
  );
}
