import React, { useState } from "react";
import inventoryStyle from "../inventoryStyle.module.css";
import {
  XDeleteButton,
  DeleteButton,
  SimpleBlueButton,
  AddItem,
} from "../../../components/editComponents";
import { NoBorderInput } from "../../../components/inputFields";
import commonStyle from "../../../style/commonStyle.module.css";

export default function VariantForm({ values, onSubmit, editType }) {
  const [attrs, setAttrs] = useState(values);
  const [showAddAttrBtn, setAddAttrBtn] = useState(
    editType === "editActive" ? false : true
  );
  //define the error message
  const [formErrors, setFormErrors] = useState({});

  const handleAttrChange = (e) => {
    const { key, value, index } = e.target;

    let newState = attrs.map((obj, i) => {
      if (i === index) {
        return { ...obj, [key]: value };
      }
      return obj;
    });

    setAttrs(newState);
  };

  const handleAttrOptionsChange = (e) => {
    const { key, value, childIndex, parentIndex } = e.target;

    let copyAttrs = [...attrs];

    let newState = copyAttrs[parentIndex].attrOptions.map((obj, i) => {
      if (i === childIndex) {
        return { ...obj, [key]: value };
      }
      return obj;
    });

    copyAttrs[parentIndex].attrOptions = newState;
    setAttrs(copyAttrs);
  };

  const addAttr = () => {
    let attrsCopy = [...attrs];
    attrsCopy.push({
      attrName: "",
      attrOptions: [{ optionName: "" }],
    });
    if (attrsCopy.length >= 3) {
      setAddAttrBtn(false);
    }
    setAttrs(attrsCopy);
  };

  const deleteAttr = (index) => {
    let attrsCopy = [...attrs];
    attrsCopy.splice(index, 1);
    if (attrsCopy.length < 3) {
      setAddAttrBtn(true);
    }
    setAttrs(attrsCopy);
  };

  const addAttrOption = (parentIndex) => {
    let copy = [...attrs];
    copy[parentIndex].attrOptions.push({
      optionName: "",
      optionStock: 0,
    });

    setAttrs(copy);
  };

  const deleteAttrOption = (parentIndex, childIndex) => {
    let copy = [...attrs];
    copy[parentIndex].attrOptions.splice(childIndex, 1);
    setAttrs(copy);
  };
  //////////// //////////// //////////// //////////// ////////////
  const checkValidation = () => {
    const hasErrors = validate(attrs);

    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});
      onSubmit(attrs);
    } else {
      setFormErrors(hasErrors);
    }
  };

  // check input validation
  const validate = (values) => {
    const errors = {};
    let uniq_attrNames = {};
    values.every((elem) => {
      let uniq_optNames = {};

      // check if any attr name is empty
      if (!elem.attrName) {
        errors.attrs = "Please enter a attribute name";
        return false;
      }
      // check if any attr name has no options
      if (elem.attrOptions.length < 1) {
        errors.attrs = "Please add at least one option for an attribute";
        return false;
      }
      if (!elem.attrName) {
        errors.attrs = "Please enter a attribute name";
        return false;
      }
      // check if any two attr name is repeat

      if (elem.attrName in uniq_attrNames) {
        errors.attrs = "Attribute name repreat, please use another name";
        return false;
      }

      // loop attr's options
      elem.attrOptions.every((opt) => {
        // check if any attr's options name is empty
        if (!opt.optionName) {
          errors.attrs = "Please enter a option name";
          return false;
        }

        if (opt.optionName in uniq_optNames) {
          errors.attrs = "Option name repreat, please use another option name";
          return false;
        } else {
          uniq_optNames[opt.optionName] = 1;
          return true;
        }
      });

      uniq_attrNames[elem.attrName] = 1;
      return true;
    });

    return errors;
  };

  return (
    <div style={{ width: "100%" }}>
      {formErrors.attrs ? (
        <p className={commonStyle.tableError}>{formErrors.attrs}</p>
      ) : (
        <></>
      )}

      {editType === "editActive" ? (
        <p className={inventoryStyle.notAllowDeleteText}>
          Attribute can not be edited when product is approved, But you can add
          more options, the option with stock on hand can not be deleted.
        </p>
      ) : (
        <p className={inventoryStyle.attrText}>
          Set attributes to this product, e.g. size, color. You can set up to
          three attributes.
        </p>
      )}

      <table className={inventoryStyle.dynTable}>
        <thead>
          <tr>
            <th>Attribute Name</th>
            <th>Options</th>
            <th className={inventoryStyle.delLoc}></th>
          </tr>
        </thead>
        <tbody>
          {attrs.map((item, index) => {
            return (
              <tr key={index}>
                <td>
                  {editType === "editActive" ? (
                    <span>{item.attrName}</span>
                  ) : (
                    <NoBorderInput
                      type="text"
                      name="Attribute"
                      value={item.attrName}
                      onChange={(attrName) =>
                        handleAttrChange({
                          target: {
                            value: attrName,
                            key: "attrName",
                            index: index,
                          },
                        })
                      }
                    />
                  )}
                </td>
                <td>
                  <div>
                    {item.attrOptions.map((x, index1) => {
                      return (
                        <div key={index1}>
                          {editType === "editActive" ? (
                            x.optionStock ? (
                              <NoBorderInput
                                type="text"
                                name="attrOptions"
                                value={x.optionName}
                                isReadOnly
                              />
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <NoBorderInput
                                  type="text"
                                  name="attrOptions"
                                  value={x.optionName}
                                  onChange={(optionName) =>
                                    handleAttrOptionsChange({
                                      target: {
                                        value: optionName,
                                        key: "optionName",
                                        childIndex: index1,
                                        parentIndex: index,
                                      },
                                    })
                                  }
                                />
                                <XDeleteButton
                                  action={() => deleteAttrOption(index, index1)}
                                />
                              </div>
                            )
                          ) : (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <NoBorderInput
                                type="text"
                                name="attrOptions"
                                value={x.optionName}
                                onChange={(optionName) =>
                                  handleAttrOptionsChange({
                                    target: {
                                      value: optionName,
                                      key: "optionName",
                                      childIndex: index1,
                                      parentIndex: index,
                                    },
                                  })
                                }
                              />
                              <XDeleteButton
                                action={() => deleteAttrOption(index, index1)}
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                    <AddItem
                      name="Add Option"
                      action={() => addAttrOption(index)}
                    />
                  </div>
                </td>
                <td style={{ textAlign: "center" }}>
                  {editType === "editActive" ? (
                    <></>
                  ) : (
                    <DeleteButton action={() => deleteAttr(index)} />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {showAddAttrBtn ? (
        <AddItem name="Add Attribute" action={addAttr} />
      ) : (
        <></>
      )}
      <div className="w-full mt-4">
        <SimpleBlueButton action={checkValidation} name="Confirm" />
      </div>
    </div>
  );
}
