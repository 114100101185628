import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import menuStyle from "../../style/menuStyle.module.css";
import { AiOutlineUser, AiOutlineLogout } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { userlogout } from "../../actions/authAction";

function Navbar() {
  const dispatch = useDispatch();
  const isdropdownOpen = false;
  // get login user infomation
  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success } = useSelector((state) => state.userDetail);

  const logoutRequest = () => {
    dispatch(userlogout());
  };

  let navigate = useNavigate();
  useEffect(() => {
    if (!userInfo) {
      navigate("/login", { replace: true });
    }
  }, [userInfo, navigate]);

  return (
    <div className={menuStyle.dropDownMenu}>
      <div
        className={
          isdropdownOpen
            ? `${menuStyle.dropdownItem} ${menuStyle.open}`
            : `${menuStyle.dropdownItem}`
        }
      >
        {success ? <span>Hi,{userDetails.data.first_name} </span> : <></>}
        <AiOutlineUser className={menuStyle.accountIcon} />

        <div className={menuStyle.dropdownContent}>
          <NavLink to="/user-profile" exact="true" className={menuStyle.sublink}>
          My Profile
          </NavLink>

          {success ? (
            userDetails.data.roles.includes("Super Admin") ? (
              <NavLink
                to="/manage-subscription"
                exact="true"
                className={menuStyle.sublink}
              >
                Manage Subscription
              </NavLink>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          <div className={menuStyle.logout} onClick={logoutRequest}>
            <AiOutlineLogout />
            <span style={{ marginLeft: "4px" }}>Log out</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
