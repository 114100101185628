export const FAV_GROUP_UPDATE_REQUEST = "FAV_GROUP_UPDATE_REQUEST";
export const FAV_GROUP_UPDATE_SUCCESS = "FAV_GROUP_UPDATE_SUCCESS";
export const FAV_GROUP_UPDATE_FAIL = "FAV_GROUP_UPDATE_FAIL";

export const FAV_GROUP_REQUEST = "FAV_GROUP_UPDATE_REQUEST";
export const FAV_GROUP_SUCCESS = "FAV_GROUP_UPDATE_SUCCESS";
export const FAV_GROUP_FAIL = "FAV_GROUP_UPDATE_FAIL";

export const SALE_BOARD_DATA_REQUEST = "SALE_BOARD_DATA_REQUEST";
export const SALE_BOARD_DATA_SUCCESS = "SALE_BOARD_DATA_SUCCESS";
export const SALE_BOARD_DATA_FAIL = "SALE_BOARD_DATA_FAIL";

export const INV_BOARD_DATA_REQUEST = "INV_BOARD_DATA_REQUEST";
export const INV_BOARD_DATA_SUCCESS = "INV_BOARD_DATA_SUCCESS";
export const INV_BOARD_DATA_FAIL = "INV_BOARD_DATA_FAIL";

export const SALE_TRENDS_DATA_REQUEST = "SALE_TRENDS_DATA_REQUEST";
export const SALE_TRENDS_DATA_SUCCESS = "SALE_TREND_DATA_SUCCESS";
export const SALE_TRENDS_DATA_FAIL = "SALE_TREND_DATA_FAIL";

export const CREATED_PO_DATA_REQUEST = "CREATED_PO_DATA_REQUEST";
export const CREATED_PO_DATA_SUCCESS = "CREATED_PO_DATA_SUCCESS";
export const CREATED_PO_DATA_FAIL = "CREATED_PO_DATA_FAIL";

export const TOP_SALE_PRODUCT_REQUEST = "TOP_SALE_PRODUCT_REQUEST";
export const TOP_SALE_PRODUCT_SUCCESS = "TOP_SALE_PRODUCT_SUCCESS";
export const TOP_SALE_PRODUCT_FAIL = "TOP_SALE_PRODUCT_FAIL";

export const STOCK_ALERT_PRODUCT_REQUEST = "STOCK_ALERT_PRODUCT_REQUEST";
export const STOCK_ALERT_PRODUCT_SUCCESS = "STOCK_ALERT_PRODUCT_SUCCESS";
export const STOCK_ALERT_PRODUCT_FAIL = "STOCK_ALERT_PRODUCT_FAIL";

export const TRAIL_TASK_REQUEST = "TRAIL_TASK_REQUEST";
export const TRAIL_TASK_SUCCESS = "TRAIL_TASK_SUCCESS";
export const TRAIL_TASK_FAIL = "TRAIL_TASK_FAIL";

export const INITIAL_SETTINGS_REQUEST = "INITIAL_SETTINGS_REQUEST";
export const INITIAL_SETTINGS_SUCCESS = "INITIAL_SETTINGS_SUCCESS";
export const INITIAL_SETTINGS_FAIL = "INITIAL_SETTINGS_FAIL";