import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  GreenButton,
  XDeleteButton,
  SimpleBlueButton,
  AddItem,
  SingleRatioButton,
  AddLinkOutlineButton,
  BarcodeScanButton,
} from "../../../components/editComponents";
import {
  StyledSelect,
  StyledInput,
  DateInput,
  NoBorderPercentInput,
  NoBorderInput,
  StyledTextarea,
  CreateableSelectField,
} from "../../../components/inputFields";
import {
  SubTitle,
  PriceText,
  ContentModal,
  TipsText,
  VariantSkuTag,
  XeroAccountMappingTip,
  Modal,
} from "../../../components/viewComponents";
import purchaseStyle from "../purchaseStyle.module.css";
import salesStyle from "../../sale/salesStyle.module.css";
import commonStyle from "../../../style/commonStyle.module.css";
import PurchaseOrderScanForm from "./PurchaseOrderScanForm";
import { convtPrice } from "../../../functions/functions";
import ProductPicker from "../../../components/ProductPicker";
import { validatePrice, validateQuantity } from "../../../functions/validate";
import BulkImportOrderProducts from "../../../components/BulkImportOrderProducts";
import ProjectForm from "../../settings/ProjectForm";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function PurchaseOrderForm({
  initialOrderInfo,
  initialOrderItems,
  initialOrderSummary,
  initialCosts,
  submit,
  type,
  showDraftBtn
}) {

  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: detailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  // product values

  const { loading, purchaseOrderOptions, error, success } = useSelector(
    (state) => state.purchaseOrderOptions
  );

  /////////////////////////////initial states//////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  const [options, setOptions] = useState("");
  const [orderInfo, setOrderInfo] = useState(initialOrderInfo);
  const [orderItems, setOrderItems] = useState(initialOrderItems);

  const [addtionalCosts, setAddtionalCosts] = useState(initialCosts);
  // store the order items total cost and extra cost total. the tax amount and order amount calculate when display
  const [orderSummary, setOrderSummary] = useState(initialOrderSummary);

  //define the error message
  const [formErrors, setFormErrors] = useState({});
  /////////////////////////////////////////////////////////////////////////////////////////////
  const [newProjectModal, setNewProjectModal] = useState(false);

  useEffect(() => {
    if (Object.keys(purchaseOrderOptions).length) {
      setOptions(purchaseOrderOptions);
    } else {
      setOptions("");
    }
  }, [success]);

  /////////////////////////////Handle change functions////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrderInfo({ ...orderInfo, [name]: value });
  };

  const handleSupplierChange = (e) => {
    const { value } = e.target;

    if (value) {
      try {
        axios
          .get(SERVER_URL + `/suppliers/${value.id}`, config)
          .then(async (response) => {
            if (response.data.success) {
              let supplierDetails = response.data.data;
              setOrderInfo({
                ...orderInfo,
                supplier: value,
                taxRate: success
                  ? purchaseOrderOptions.taxrateOptions.filter(
                      (x) => Number(x.id) === Number(supplierDetails.taxRateId)
                    )[0]
                  : "",
                currency: supplierDetails.currency
                  ? supplierDetails.currency
                  : userDetails.data.orgInfo.base_currency,
                supplierName: supplierDetails.name,
                supplierBillingName: supplierDetails.billingName,
                firstName: supplierDetails.firstName,
                lastName: supplierDetails.lastName,
                email: supplierDetails.email,
                phone: supplierDetails.phone,
                postalAddress: supplierDetails.postalAddress,
                billingAddress: supplierDetails.billingAddress,
              });
            } else {
              setFormErrors({ supplier: "Get supplier details failed!" });
            }
          })
          .catch((err) => {
            setFormErrors({ supplier: "Get supplier details failed!" });
          });
      } catch (error) {
        setFormErrors({ supplier: "Get supplier details failed!" });
      }
    } else {
      setOrderInfo({
        ...orderInfo,
        supplier: "",
      });
    }
  };

  const handleLocationChange = (e) => {
    const { value } = e.target;

    if (value) {
      setOrderInfo({
        ...orderInfo,
        location: value,
        deliveryAddress: value.address,
      });
    } else {
      setOrderInfo({
        ...orderInfo,
        location: "",
        deliveryAddress: "",
      });
    }
  };

  const handleOrderItemChange = (e) => {
    const { key, value, index } = e.target;
    const type = e.type;

    // only allow int inout
    if (type === "quantity") {
      const validated = validateQuantity(value);
      if (!validated) return;
    }

    if (type === "price") {
      const validated = validatePrice(value);
      if (!validated) return;
    }

    let newItems = [...orderItems];

    newItems[index][key] = value;

    if (newItems[index].unitCost && newItems[index].quantity) {
      if (newItems[index].discount) {
        let discountedUnitCost = convtPrice(
          newItems[index].unitCost * (1 - newItems[index].discount / 100)
        );
        // has discount value
        newItems[index]["totalCost"] = convtPrice(
          discountedUnitCost * Number(newItems[index].quantity)
        );
      } else {
        // no discount value
        newItems[index]["totalCost"] = convtPrice(
          Number(newItems[index].unitCost) * Number(newItems[index].quantity)
        );
      }
    } else {
      newItems[index]["totalCost"] = "";
    }

    // caculate order summary
    sumProductsTotal(newItems);
    setOrderItems(newItems);
  };

  const handleCostChange = (e) => {
    const { key, value, index } = e.target;
    const type = e.type;

    if (type === "price") {
      const validated = validatePrice(value);
      if (!validated) return;
    }

    let costsCopy = [...addtionalCosts];
    costsCopy[index][key] = value;
    setAddtionalCosts(costsCopy);

    // calculate cost total
    let orderSummaryCopy = { ...orderSummary };
    let costTotal = costsCopy.reduce(
      (preTotal, item) => Number(preTotal) + Number(item.cost),
      0
    );
    orderSummaryCopy.additionalCostTotal = costTotal;
    setOrderSummary(orderSummaryCopy);
  };

  const sumProductsTotal = (items) => {
    let orderSummaryCopy = { ...orderSummary };
    let newProductTotal = items.reduce(
      (preTotal, item) => Number(preTotal) + Number(item.totalCost),
      0
    );

    orderSummaryCopy.productsTotal = newProductTotal;

    setOrderSummary(orderSummaryCopy);
  };

  ///////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////

  const addAdditionalCost = () => {
    let costCopy = [...addtionalCosts];
    costCopy.push({
      costName: "",
      cost: "",
    });

    setAddtionalCosts(costCopy);
  };

  const deleteOrderItem = (index) => {
    let orderItemsCopy = [...orderItems];
    orderItemsCopy.splice(index, 1);

    // re calculate order total

    sumProductsTotal(orderItemsCopy);
    setOrderItems(orderItemsCopy);
  };

  const deleteCostItem = (index) => {
    let costCopy = [...addtionalCosts];
    costCopy.splice(index, 1);

    // re calculate cost total
    let orderSummaryCopy = { ...orderSummary };
    let costTotal = costCopy.reduce(
      (preTotal, item) => Number(preTotal) + Number(item.cost),
      0
    );
    orderSummaryCopy.additionalCostTotal = costTotal;

    setAddtionalCosts(costCopy);
    setOrderSummary(orderSummaryCopy);
  };

  ////////////////////  ////////////////////  ////////////////////  ////////////////////
  ////////////////////  ////////////////////  ////////////////////  ////////////////////
  const getProductFromPicker = (result) => {
    if (result.productType !== "package") {
      addNewOrderItem(result.data);
    }
  };

  const addNewOrderItem = (proDetails) => {
    let orderItemsCopy = [...orderItems];
    // remove empty row in order items
    orderItemsCopy = orderItemsCopy.filter((x) => x.productName);
    // check exist products, error
    // let exists = orderItemsCopy.some(
    //   (item) =>
    //     item.famid === proDetails.familyId &&
    //     item.variantCode === proDetails.variantCode
    // );
    let exists = false;
    
    if (exists) {
      let index = orderItemsCopy.findIndex(
        (item) =>
          item.famid === proDetails.familyId &&
          item.variantCode === proDetails.variantCode
      );
      orderItemsCopy[index].quantity =
        Number(orderItemsCopy[index].quantity) + 1;

      orderItemsCopy[index].totalPrice =
        Number(orderItemsCopy[index].totalPrice) +
        1 * Number(orderItemsCopy[index].unitPrice);
    } else {
      orderItemsCopy.push({
        productType: proDetails.productType,
        famid: proDetails.familyId,
        sku: proDetails.sku,
        productName: proDetails.name,
        variant: proDetails.variant ? proDetails.variant : "",
        variantCode: proDetails.variantCode,
        variantSku: proDetails.variantSku,
        unitName: proDetails.unit,
        unitLatestCost: convtPrice(proDetails.latestCost),
        unitCost:
          orderInfo.taxIncluded && orderInfo.taxRate
            ? convtPrice(
                Number(proDetails.latestCost) *
                  (1 + orderInfo.taxRate.rate / 100)
              )
            : convtPrice(proDetails.latestCost),
        quantity: "",
        totalCost: "",
        discount: "",
      });
    }
    sumProductsTotal(orderItemsCopy);
    setOrderItems(orderItemsCopy);
  };

  /////////////////////////Scan barcode///////////////////////////////////
  ////////////////////////////////////////////////////////////////////////
  const [scanModal, setScanModal] = useState(false);
  const openScanModal = () => {
    if (!orderInfo.location) {
      setFormErrors({
        orderItemsError: "Please select a location before scan product.",
      });
      return;
    } else if (!orderInfo.taxRate) {
      setFormErrors({
        orderItemsError: "Please select a tax rate before scan product.",
      });
      return;
    } else {
      setFormErrors({ orderItemsError: "" });
      setScanModal(true);
    }
  };

  const getScanedProducts = (products) => {
    let orderItemsCopy = [...orderItems];
    // remove empty row in order items
    orderItemsCopy = orderItemsCopy.filter((x) => x.productName);

    for (let i = 0; i < products.length; i++) {
      let pro = products[i];
      let exists = orderItemsCopy.some(
        (item) =>
          item.famid === pro.familyId && item.variantCode === pro.variantCode
      );
      if (exists) {
        let index = orderItemsCopy.findIndex(
          (item) =>
            item.famid === pro.familyId && item.variantCode === pro.variantCode
        );
        orderItemsCopy[index].quantity =
          Number(orderItemsCopy[index].quantity) + Number(pro.quantity);
        orderItemsCopy[index].totalCost =
          Number(orderItemsCopy[index].totalCost) +
          Number(pro.quantity) * Number(orderItemsCopy[index].unitCost);
      } else {
        orderItemsCopy.push({
          famid: pro.familyId,
          productType: pro.productType,
          productName: pro.productName,
          variant: pro.variant,
          variantCode: pro.variantCode,
          variantSku: pro.variantSku,
          sku: pro.sku,
          discount: "",
          unitName: pro.unitName,
          unitLatestCost: pro.unitLatestCost,
          unitCost: pro.unitCost,
          quantity: pro.quantity,
          totalCost: pro.totalCost,
        });
      }
    }

    // re calculate order total
    sumProductsTotal(orderItemsCopy);
    setOrderItems(orderItemsCopy);
    setScanModal(false);
  };
  ////////////////////////////Submit and check values/////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////

  const checkValidation = (status) => {
    const sumitValues = {
      orderInfo: orderInfo,
      orderItems: orderItems,
      costs: addtionalCosts.filter((x) => x.costName && x.cost),
      orderSummary: orderSummary,
    };

    const hasErrors = validate(sumitValues);

    if (hasErrors && Object.keys(hasErrors).length === 0) {
      submit(sumitValues, status);
      setFormErrors({});
    } else {
      setFormErrors(hasErrors);
    }
  };

  // check input validation
  const validate = (values) => {
    const errors = {};
    if (!values.orderInfo.orderNumber) {
      errors.orderNumber = "Order number is required!";
    }
    if (!values.orderInfo.supplier) {
      errors.supplier = "Supplier is required!";
    }
    if (!values.orderInfo.taxRate) {
      errors.taxRate = "tax rate is required!";
    }
    if (!values.orderInfo.currency) {
      errors.currency = "Currency is required!";
    }

    if (!values.orderInfo.location) {
      errors.location = "Delivery location is required!";
    }

    // check if the order items is filled
    if (values.orderItems.length <= 0) {
      errors.orderItemsError =
        "Please add at least one product to the purchase order!";
    } else {
      values.orderItems.forEach((item) => {
        if (!item.productName) {
          errors.orderItemsError = "Product name is required.";
          return;
        }
        if (!item.unitCost) {
          errors.orderItemsError = "Product cost is required.";
          return;
        }
        if (!item.quantity) {
          errors.orderItemsError = "Product quantity is required.";
          return;
        }
      });
    }

    return errors;
  };
  
  const addNewProjectSuccess = (newPro) => {
    let optionCopy = { ...options };
    let projectCopy = optionCopy.projectsOptions;
    projectCopy.unshift(newPro);
    setOptions(optionCopy);
    setOrderInfo({ ...orderInfo, project: newPro });
    setNewProjectModal(false);
  };

  return (
    <div>
      <Modal
        title="New Project"
        isModalOpen={newProjectModal}
        closeModal={() => setNewProjectModal(false)}
        content={<ProjectForm 
          projectId=""
          type="new"
          btnName="Add New Project"
          values={{ name: "", code:"" }} 
          onSuccess={addNewProjectSuccess} />}
      />
      <ContentModal
        title="Scan Barcode"
        isModalOpen={scanModal}
        closeOnOverlayClick={false}
        closeModal={() => setScanModal(false)}
        content={
          <PurchaseOrderScanForm
            taxIncluded={orderInfo.taxIncluded}
            taxrate={orderInfo.taxRate ? orderInfo.taxRate.rate : ""}
            completeScan={getScanedProducts}
          />
        }
      />
      <div className={salesStyle.sectionCard}>
        <XeroAccountMappingTip pageName="purchaseOrder" />

        <div className="w-full grid grid-cols-2 gap-8">
          <div className="w-full">
            <StyledSelect
              label="Location"
              placeHolder="Select receive location"
              selectOptions={
                success ? purchaseOrderOptions.locationOptions : []
              }
              clearable={false}
              value={orderInfo.location}
              onChange={(location) =>
                handleLocationChange({
                  target: { value: location },
                })
              }
              error={formErrors.location}
            />
            <StyledInput
              label="Order Number"
              type="text"
              name="orderNumber"
              value={orderInfo.orderNumber}
              onChange={(orderNumber) =>
                handleChange({
                  target: { value: orderNumber, name: "orderNumber" },
                })
              }
              error={formErrors.orderNumber}
            />
          </div>
        </div>
      </div>

      <div className={salesStyle.sectionCard}>
        <div className="w-full grid grid-cols-2 gap-8">
          <div className="w-full">
            <SubTitle name="Supplier Information" />
            <div style={{ display: "flex", alignItems: "flex-end" }}>
              <StyledSelect
                label="Supplier*"
                placeHolder="Select supplier"
                value={orderInfo.supplier}
                selectOptions={options.supplierOptions}
                onChange={(supplier) =>
                  handleSupplierChange({
                    target: { value: supplier },
                  })
                }
                error={formErrors.supplier}
              />
              <AddLinkOutlineButton name="New Supplier" path="/supplier/new" />
            </div>

            {orderInfo.supplier ? (
              <div className={salesStyle.customerInfoCard}>
                <p>Contact Infomation</p>
                <TipsText
                  text={orderInfo.firstName + " " + orderInfo.lastName}
                />
                <TipsText text={orderInfo.email} />
                <TipsText text={orderInfo.phone} />
                <div style={{ display: "flex" }}>
                  <div style={{ width: "50%", marginRight: "20px" }}>
                    <p>Postal Address</p>
                    <TipsText
                      text={
                        orderInfo.postalAddress && orderInfo.postalAddress.country
                          ? orderInfo.postalAddress.country.value
                          : ""
                      }
                    />
                    <TipsText text={orderInfo.postalAddress && orderInfo.postalAddress.region} />
                    <TipsText text={orderInfo.postalAddress && orderInfo.postalAddress.city} />
                    <TipsText text={orderInfo.postalAddress && orderInfo.postalAddress.addressLine} />
                    <TipsText text={orderInfo.postalAddress && orderInfo.postalAddress.postalCode} />
                  </div>
                  <div style={{ width: "50%", marginRight: "20px" }}>
                    <p>Billing Address</p>
                    <TipsText
                      text={
                        orderInfo.billingAddress && orderInfo.billingAddress.country
                          ? orderInfo.billingAddress.country.value
                          : ""
                      }
                    />
                    <TipsText text={orderInfo.billingAddress && orderInfo.billingAddress.region} />
                    <TipsText text={orderInfo.billingAddress && orderInfo.billingAddress.city} />
                    <TipsText text={orderInfo.billingAddress && orderInfo.billingAddress.addressLine} />
                    <TipsText text={orderInfo.billingAddress && orderInfo.billingAddress.postalCode} />
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            <StyledSelect
              label="Tax Rate*"
              placeHolder="Select tax rate"
              clearable={false}
              value={orderInfo.taxRate}
              selectOptions={options.taxrateOptions}
              onChange={(taxRate) =>
                handleChange({
                  target: { value: taxRate, name: "taxRate" },
                })
              }
              error={formErrors.taxRate}
            />
            <StyledInput
              label="Currency*"
              value={orderInfo.currency}
              type="text"
              name="currency"
              isReadOnly
            />
            {options.projectsOptions && options.projectsOptions.length > 0 ? (
              <>
                {/* <StyledSelect
                label="Project"
                placeHolder="Select project"
                clearable={true}
                value={orderInfo.project}
                selectOptions={options.projectsOptions}
                onChange={(project) =>
                  handleChange({
                    target: { value: project, name: "project" },
                  })
                }
              /> */}
                <CreateableSelectField
                  label="Project"
                  extractKey="id"
                  options={options.projectsOptions}
                  name={"year"}
                  value={orderInfo.project}
                  onChange={(project) =>
                    handleChange({
                      target: { value: project, name: "project" },
                    })
                  }
                  placeholder="Select project"
                  autoFocus={false}
                  readOnly={false}
                  clearable={true}
                  noResultText="No options"
                  addBtnName="Add a new project"
                  openAddNewModal={() => setNewProjectModal(true)}
                  error={formErrors.project}
                />
              </>
            ) : (
              <></>
            )}
          </div>

          <div className="w-full">
            <SubTitle name="Shipping Infomation" />

            <StyledInput
              label="Delivery Address"
              type="text"
              name="deliveryAddress"
              value={orderInfo.deliveryAddress}
              onChange={(deliveryAddress) =>
                handleChange({
                  target: { value: deliveryAddress, name: "deliveryAddress" },
                })
              }
              error={formErrors.deliveryAddress}
            />

            <DateInput
              label="Expected Delivery Date"
              disabledPreDate
              error={formErrors.expectedDate}
              value={orderInfo.expectedDate}
              onChange={(expectedDate) =>
                handleChange({
                  target: {
                    value: expectedDate,
                    name: "expectedDate",
                  },
                })
              }
            />

            <StyledTextarea
              label="Notes"
              type="text"
              name="notes"
              value={orderInfo.notes}
              onChange={(notes) =>
                handleChange({
                  target: { value: notes, name: "notes" },
                })
              }
              error={formErrors.notes}
            />
          </div>
        </div>
      </div>

      {/************ order items ***********************/}
      <div className={salesStyle.sectionCard}>
        <div className={purchaseStyle.orderItemsCon}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <SubTitle name="Order Products" />
            <SingleRatioButton
              label="Amounts are tax inclusive"
              name="taxIncluded"
              isOn={orderInfo.taxIncluded}
              onChange={(taxIncluded) =>
                handleChange({
                  target: { value: taxIncluded, name: "taxIncluded" },
                })
              }
            />
          </div>

          {formErrors.orderItemsError ? (
            <p className={commonStyle.tableError}>
              {formErrors.orderItemsError}
            </p>
          ) : (
            <></>
          )}

          <div className={salesStyle.orderActionsCon}>
            <div style={{ width: "50%" }}>
              <ProductPicker
                locationId={orderInfo.location ? orderInfo.location.id : ""}
                priceTierId={orderInfo.priceTier ? orderInfo.priceTier.id : ""}
                onSelect={getProductFromPicker}
                requiredType="product"
              />
            </div>

            <BarcodeScanButton name="Scan Barcode" action={openScanModal} />
          </div>

          <div className={commonStyle.longDataTable}>
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Product Name</th>
                  <th>Unit</th>
                  <th>Latest Cost</th>
                  <th>Unit Cost</th>
                  <th>Discount</th>
                  <th>Net Cost</th>
                  <th>Quantity</th>
                  <th>Total</th>
                  <th
                    style={{
                      margin: "auto",
                      borderLeft: "1px solid var(--border-color)",
                    }}
                  ></th>
                </tr>
              </thead>
              <tbody>
                {orderItems.map((item, index1) => {
                  return (
                    <tr key={index1}>
                      <td>
                        {item.variantSku && (
                          <VariantSkuTag name={item.variantSku} />
                        )}
                      </td>

                      {item.productName ? (
                        <td className={purchaseStyle.productNameCol}>
                          {item.productName}{" "}
                          {item.variant ? (
                            <span className={purchaseStyle.variantTag}>
                              {item.variant}
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                      ) : (
                        <td className={purchaseStyle.productNameCol}></td>
                      )}
                      <td>{item.unitName}</td>
                      <td>
                        <PriceText num={item.unitLatestCost} />
                        <span className={commonStyle.taxText}>
                          Excl. tax
                        </span>{" "}
                      </td>
                      <td
                        style={{
                          width: "10%",
                        }}
                      >
                        <NoBorderInput
                          type="text"
                          name="cost"
                          value={item.unitCost}
                          onChange={(unitCost) =>
                            handleOrderItemChange({
                              type: "price",
                              target: {
                                value: unitCost,
                                key: "unitCost",
                                index: index1,
                              },
                            })
                          }
                        />
                      </td>
                      <td
                        style={{
                          width: "10%",
                        }}
                      >
                        <NoBorderPercentInput
                          type="text"
                          name="discount"
                          value={item.discount}
                          onChange={(discount) =>
                            handleOrderItemChange({
                              type: "price",
                              target: {
                                value: discount,
                                key: "discount",
                                index: index1,
                              },
                            })
                          }
                        />
                      </td>
                      <td>
                        {item.discount ? (
                          <PriceText
                            num={convtPrice(
                              (1 - Number(item.discount) / 100) *
                                Number(item.unitCost)
                            )}
                          />
                        ) : (
                          <PriceText num={item.unitCost} />
                        )}
                      </td>
                      <td
                        style={{
                          width: "10%",
                        }}
                      >
                        <NoBorderInput
                          type="text"
                          name="quantity"
                          value={item.quantity}
                          onChange={(quantity) =>
                            handleOrderItemChange({
                              type: "quantity",
                              target: {
                                value: quantity,
                                key: "quantity",
                                index: index1,
                              },
                            })
                          }
                        />
                      </td>
                      <td
                        style={{
                          width: "10%",
                          maxWidth: "15%",
                        }}
                      >
                        <PriceText num={item.totalCost} />
                      </td>
                      <td
                        style={{
                          borderLeft: "1px solid var(--border-color)",
                          width: "4%",
                        }}
                      >
                        {" "}
                        <XDeleteButton action={() => deleteOrderItem(index1)} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="7" className={commonStyle.tableFootName}>
                    Total
                  </td>

                  <td>
                    <PriceText num={orderSummary.productsTotal} />
                  </td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
            <BulkImportOrderProducts completeImport={getScanedProducts} />

            <div className={purchaseStyle.summaryWrapper}>
              <div className={purchaseStyle.costCon}>
                {/* additional cost */}
                <SubTitle name="Additional Cost" />
                <table className={commonStyle.pageTable}>
                  <thead>
                    <tr>
                      <th>Cost Name</th>
                      <th>Cost</th>
                      <th
                        style={{
                          borderLeft: "1px solid var(--border-color)",
                        }}
                      ></th>
                    </tr>
                  </thead>

                  <tbody>
                    {addtionalCosts.map((c, index2) => {
                      return (
                        <tr key={index2}>
                          <td
                            className={purchaseStyle.productNameCol}
                            style={{
                              width: "60%",
                            }}
                          >
                            <NoBorderInput
                              type="text"
                              name="costName"
                              value={c.costName}
                              placeHolder="Eg.shipping cost / tax "
                              onChange={(costName) =>
                                handleCostChange({
                                  target: {
                                    value: costName,
                                    key: "costName",
                                    index: index2,
                                  },
                                })
                              }
                            />
                          </td>

                          <td
                            style={{
                              width: "36%",
                            }}
                          >
                            <NoBorderInput
                              type="text"
                              name="cost"
                              value={c.cost}
                              onChange={(cost) =>
                                handleCostChange({
                                  type: "price",
                                  target: {
                                    value: cost,
                                    key: "cost",
                                    index: index2,
                                  },
                                })
                              }
                            />
                          </td>

                          <td
                            style={{
                              borderLeft: "1px solid var(--border-color)",
                              width: "4%",
                            }}
                          >
                            {" "}
                            <XDeleteButton
                              action={() => deleteCostItem(index2)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className={commonStyle.tableFootName}>Total</td>

                      <td>
                        <PriceText num={orderSummary.additionalCostTotal} />
                      </td>
                      <td></td>
                    </tr>
                  </tfoot>
                </table>
                <AddItem
                  name="Add Additional Cost"
                  action={addAdditionalCost}
                />
              </div>

              <div className={purchaseStyle.summaryCon}>
                {/* summary total price */}
                <SubTitle name="Order Summary" />
                <table
                  className={commonStyle.summaryTable}
                  style={{ marginTop: "12px" }}
                >
                  {orderInfo.taxIncluded ? (
                    <tbody>
                      <tr>
                        <td className={commonStyle.tableFootName}>
                          Products Total
                        </td>

                        <td style={{ width: "30%" }}>
                          <PriceText num={orderSummary.productsTotal} />
                        </td>
                      </tr>
                      <tr>
                        <td className={commonStyle.tableFootName}>
                          Additional Cost Total
                        </td>
                        <td style={{ width: "30%" }}>
                          <PriceText num={orderSummary.additionalCostTotal} />
                        </td>
                      </tr>

                      <tr className={commonStyle.summaryTableTotalRow}>
                        <td className={commonStyle.summaryTableTotalName}>
                          Order Total{" "}
                          <span className={commonStyle.taxText}>Tax Incl.</span>
                        </td>

                        <td className={commonStyle.summaryTableTotalValue}>
                          {orderSummary.productsTotal ? (
                            <PriceText
                              num={
                                Number(orderSummary.productsTotal) +
                                Number(orderSummary.additionalCostTotal)
                              }
                            />
                          ) : (
                            "0.00"
                          )}{" "}
                          {orderInfo.currency}
                        </td>
                      </tr>

                      <tr className={commonStyle.summaryTableTotalRow}>
                        <td className={commonStyle.tableFootName}>
                          Total{" "}
                          <span className={commonStyle.taxText}>Tax Excl.</span>
                        </td>

                        <td style={{ width: "30%" }}>
                          {orderInfo.taxRate ? (
                            <PriceText
                              num={convtPrice(
                                (Number(orderSummary.productsTotal) +
                                  Number(orderSummary.additionalCostTotal)) /
                                  (1 + orderInfo.taxRate.rate / 100)
                              )}
                            />
                          ) : (
                            "0.00"
                          )}
                        </td>
                      </tr>

                      <tr className={commonStyle.summaryTableTotalRow}>
                        <td className={commonStyle.tableFootName}>
                          Tax
                          <span className={commonStyle.taxText}>
                            {orderInfo.taxRate
                              ? "-" + orderInfo.taxRate.value
                              : ""}
                          </span>
                        </td>

                        <td style={{ width: "30%" }}>
                          {orderInfo.taxRate ? (
                            <PriceText
                              num={convtPrice(
                                Number(orderSummary.productsTotal) +
                                  Number(orderSummary.additionalCostTotal) -
                                  (Number(orderSummary.productsTotal) +
                                    Number(orderSummary.additionalCostTotal)) /
                                    (1 + Number(orderInfo.taxRate.rate) / 100)
                              )}
                            />
                          ) : (
                            "0.00"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className={commonStyle.tableFootName}>
                          Products Total
                        </td>

                        <td style={{ width: "30%" }}>
                          <PriceText num={orderSummary.productsTotal} />
                        </td>
                      </tr>
                      <tr>
                        <td className={commonStyle.tableFootName}>
                          Additional Cost Total
                        </td>
                        <td style={{ width: "30%" }}>
                          <PriceText num={orderSummary.additionalCostTotal} />
                        </td>
                      </tr>

                      <tr className={commonStyle.summaryTableTotalRow}>
                        <td className={commonStyle.tableFootName}>
                          Total{" "}
                          <span className={commonStyle.taxText}>Tax Excl.</span>
                        </td>

                        <td style={{ width: "30%" }}>
                          {orderInfo.taxRate ? (
                            <PriceText
                              num={
                                Number(orderSummary.productsTotal) +
                                Number(orderSummary.additionalCostTotal)
                              }
                            />
                          ) : (
                            "0.00"
                          )}
                        </td>
                      </tr>

                      <tr className={commonStyle.summaryTableTotalRow}>
                        <td className={commonStyle.tableFootName}>
                          Tax
                          <span className={commonStyle.taxText}>
                            {orderInfo.taxRate
                              ? "-" + orderInfo.taxRate.value
                              : ""}
                          </span>
                        </td>

                        <td style={{ width: "30%" }}>
                          {orderInfo.taxRate ? (
                            <PriceText
                              num={convtPrice(
                                (Number(orderSummary.productsTotal) +
                                  Number(orderSummary.additionalCostTotal)) *
                                  (Number(orderInfo.taxRate.rate) / 100)
                              )}
                            />
                          ) : (
                            "0.00"
                          )}
                        </td>
                      </tr>

                      <tr className={commonStyle.summaryTableTotalRow}>
                        <td className={commonStyle.summaryTableTotalName}>
                          Order Total{" "}
                          <span className={commonStyle.taxText}>Tax Incl.</span>
                        </td>

                        <td className={commonStyle.summaryTableTotalValue}>
                          {orderSummary.productsTotal && orderInfo.taxRate ? (
                            <PriceText
                              num={convtPrice(
                                Number(orderSummary.productsTotal) +
                                  Number(orderSummary.additionalCostTotal) +
                                  (Number(orderSummary.productsTotal) +
                                    Number(orderSummary.additionalCostTotal)) *
                                    (Number(orderInfo.taxRate.rate) / 100)
                              )}
                            />
                          ) : (
                            "0.00"
                          )}{" "}
                          {orderInfo.currency}
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex items-start justify-between">
        <div className="w-1/2">
        {
          showDraftBtn ? <GreenButton
          name="Save as Draft"
          action={() => checkValidation("Draft")}
          fullWidth={false}
          marginRight={false}
        /> : <></> 
        }
         
        </div>

        <div className="w-fit flex flex-col items-end">
          {detailsSuccess &&
          userDetails.data.permissions.includes("approve_purchase_order") ? (
            <div className="w-fit">
              <SimpleBlueButton
                name="Save as Approved"
                action={() => checkValidation("Approved")}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
