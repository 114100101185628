import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  updateRoleRequest,
  roleDetailRequest,
} from "../../../actions/userRoleActions";
import {
  ErrorMessage,
  Loader,
  SimpleDialog,
} from "../../../components/viewComponents";
import commonStyle from "../../../style/commonStyle.module.css";
import Breadcrumbs from "../../../components/Breadcrumbs";
import RoleDetailForm from "./RoleDetailForm";
import useDocumentTitle from "../../../useDocumentTitle";
import { ScreenTitle } from "../../../components/editComponents";

export default function EditRole() {
  useDocumentTitle("Edit Role")
  const { id:roleID } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // get state from url
  const location = useLocation();
  const { loading, roleDetail, error, success } = useSelector(
    (state) => state.roleDetail
  );

  useEffect(() => {
    dispatch(roleDetailRequest(roleID));
  }, [dispatch, roleID]);

  const {
    loading: newRoleLoading,
    success: newRoleSuccess,
    error: newRoleError,
  } = useSelector((state) => state.updateRole);

  // when submit add new role request, open the add new role dialog, display the request results, success or error.
  const [addeditRoleDialog, setEditRoleDialog] = useState(false);

  const editRoleRequest = (values) => {
    setEditRoleDialog(true);
    // get selected permissions id
    let selectedPemissons = [];

    values.permissions.forEach((val) => {
      if (val.permission) {
        selectedPemissons.push(val.id);
      }
  });

    let newRoleValues = {
      roleName: values.roleName,
      rolePermissions: selectedPemissons,
    };

    dispatch(updateRoleRequest(newRoleValues,values.id));
  };

  const closeDialogWithSuccess = () => {
    navigate("/settings/roles", { replace: true, from: location });
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Edit Role"
        successMessage="Role updated successfully."
        isDialogOpen={addeditRoleDialog}
        closeDialog={() => setEditRoleDialog(false)}
        loading={newRoleLoading}
        loadingMessage="Processing request..."
        success={newRoleSuccess}
        error={newRoleError}
        btnValue="Confirm"
        confirmAction={closeDialogWithSuccess}
      />
      <Breadcrumbs screenName="Edit Role" />
      <ScreenTitle title="Edit Role" />
      <div>

          <div className={commonStyle.pageContentContainer}>
            {loading ? (
              <Loader mess="Requesting permissions, please wait a moment..." />
            ) : success ? (
              <RoleDetailForm
                onSubmit={editRoleRequest}
                btnName="Update Role"
                title="Update Role"
                roleDetails={roleDetail}
                type="edit"
              />
            ) : (
              <ErrorMessage mess={error} />
            )}
          </div>
        </div>
      </div>

  );
}
