import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import viewStyle from "../style/styledViewComponentsStyle.module.css";
import { Loader } from "./viewComponents";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function XeroLink({ invoiceId, type }) {
  const { userInfo } = useSelector((state) => state.userLogin);

  const [xeroLinkStatus, setXeroLinkStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  // type options [sale_order_invoice, purchase_order_bill, sale_order_journal,on_hand_stock_journal ]

  ////////////////////////////////////////////////////////////////////////////////////////

  const linkToXero = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    // get xero connection status
    try {
      setXeroLinkStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      axios
        .get(
          SERVER_URL + `/integration/xero/deep_link/${invoiceId}?type=${type}`,
          config
        )
        .then(async (response) => {
          if (response.data.success) {
            setXeroLinkStatus((preState) => ({
              ...preState,
              loading: false,
              success: true,
              error: "",
            }));
            window.location.href = response.data.data.xero_url;
          } else {
            setXeroLinkStatus((preState) => ({
              ...preState,
              loading: false,
              error: "Request Xero link falied, please try again later.",
            }));
          }
        })
        .catch((err) => {
          setXeroLinkStatus((preState) => ({
            ...preState,
            loading: false,
            error: "Request Xero link falied, please try again later.",
          }));
        });
    } catch (error) {
      setXeroLinkStatus((preState) => ({
        ...preState,
        loading: false,
        error: "Connection Error, please try again later.",
      }));
    }
  };

  return xeroLinkStatus.loading ? (
    <Loader mess="Redirecting to Xero..." />
  ) : (
    <div onClick={linkToXero} className={viewStyle.xeroLinkCon}>
      <div className={viewStyle.xeroLogo}></div>
      <p> Go to Xero</p>
    </div>
  );
}
