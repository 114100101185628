import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ScreenTitle } from "../../../components/editComponents";
import inventoryStyle from "../inventoryStyle.module.css";
import {
  SimpleDialog,
  Loader,
  ErrorMessage,
  MappingXeroAccountDialog,
} from "../../../components/viewComponents";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  productOptionsRequest,
  createNewProductRequest,
  productDetailsRequest,
} from "../../../actions/productActions";
import ProductDetailsForm from "./ProductDetailsForm";
import { productTypeOptions } from "../../../constantsData/productType";

export default function NewProductScreen() {
  useDocumentTitle("Create New Product");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  //////////////////////////////////////////////////////////////////////////////
  const cloneProductId = location.state;
  //////////////////////////////////////////////////////////////////////////////
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const { loading, options, error, success } = useSelector(
    (state) => state.productOptions
  );
  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  // clone product
  const [cloneDialog, setCloneDialog] = useState(true);

  // add new product dialog
  const [dialog, setDialog] = useState(false);
  const [xeroDialog, setXeroDialog] = useState(false);

  const [productData, setProductData] = useState("");

  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    dispatch(productOptionsRequest());
    if (cloneProductId) {
      dispatch(productDetailsRequest(cloneProductId, "clone"));
    }
  }, [dispatch]);
  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  const {
    loading: detailsLoading,
    productInfo,
    error: detailsError,
    success: detailsSuccess,
  } = useSelector((state) => state.productDetails);

  const {
    loading: createLoading,
    error: createError,
    success: createSuccess,
  } = useSelector((state) => state.createProduct);

  const newProductDefaultValues = () => {
    let data = {
      productName: "",
      type: productTypeOptions.filter((x) => x.isDefault)[0],
      sku: "",
      unit: options.units.filter((x) => x.isDefault)[0],
      category: "",
      reference: "",
      description: "",
      image: "",
      managed: false,
      serialized: false,
      batchTracked: false,
      saleTax: options.salesTaxRates.filter((x) => x.isDefault)[0],
      supplier: "",
      hasAttrs: false,
      attrs: [
        { attrName: "", attrOptions: [{ optionName: "", optionStock: 0 }] },
      ],
    };
    return data;
  };

  const newProductVariants = [
    {
      variantSku: "",
      barcode: "",
      salePrice: 0,
      latestCost: 0,
      reorderLevel: 0,
      stockLevel: 0,
    },
  ];
  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  const cloneProductValues = () => {
    let data = {
      productName: productInfo.basicInfo.productName,
      type: productTypeOptions.filter(
        (x) => x.value === productInfo.basicInfo.type
      )[0],
      sku: "",
      unit: options.units.filter(
        (x) => Number(x.id) === Number(productInfo.basicInfo.unitId)
      )[0],
      category: options.category.filter(
        (x) => Number(x.id) === Number(productInfo.basicInfo.categoryId)
      )[0],
      reference: productInfo.basicInfo.reference,
      description: productInfo.basicInfo.description,
      image: productInfo.basicInfo.image,
      managed: productInfo.basicInfo.managed,
      serialized: productInfo.basicInfo.serialized,
      batchTracked: productInfo.basicInfo.batchTracked,
      supplier: options.suppliers.filter(
        (x) => Number(x.id) === Number(productInfo.basicInfo.supplierId)
      )[0],
      hasAttrs: productInfo.basicInfo.hasAttrs,
      attrs: productInfo.basicInfo.attrs,
    };
    return data;
  };

  // const autoGenerateSku = () => {
  //   let skuValue = "";
  //   let min = 1;
  //   let max = 10000000;
  //   let rand = (min + Math.random() * (max - min)).toFixed(0);

  //   let dateObj = new Date();
  //   let month = dateObj.getUTCMonth() + 1; //months from 1-12
  //   let year = dateObj.getUTCFullYear();
  //   skuValue = "RA" + year + month + rand;
  //   return skuValue;
  // };

  const autoGenerateVariantSku = () => {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let uniqueCode = "";
    let timestamp = new Date().getTime(); // Current timestamp in milliseconds

    // Convert timestamp to a base that matches the number of characters we have (36) to ensure uniqueness
    let base36Timestamp = timestamp.toString(36).toUpperCase();
    let randIndex;

    // Extract the last 4 characters from the base36Timestamp to use as part of the unique code
    uniqueCode += base36Timestamp.slice(-4);

    // Generate two additional random characters from chars to complete the six-character code
    for (let i = 0; i < 2; i++) {
      randIndex = Math.floor(Math.random() * chars.length);
      uniqueCode += chars[randIndex];
    }

    return uniqueCode;
  };
  //////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////

  const saveProductInfo = (values, isDraft) => {
    let attributes = [];
    if (values.basicInfo.hasAttrs) {
      for (let attr of values.basicInfo.attrs) {
        let opts = [];
        for (let opt of attr.attrOptions) {
          opts.push(opt.optionName);
        }
        attributes.push({ name: attr.attrName, opts: opts });
      }
    }

    let locationIds = [];

    // auto generate variant sku code
    values.variants.forEach((element) => {
      if (!element.variantSku) {
        element.variantSku = autoGenerateVariantSku();
      }
    });

    let productValues = {
      draft: isDraft,
      syncXero: false,
      basicInfo: {
        productName: values.basicInfo.productName,
        type: values.basicInfo.type.value,
        sku: values.variants[0].variantSku,
        categoryId: values.basicInfo.category
          ? values.basicInfo.category.id
          : "",
        description: values.basicInfo.description,
        attributes: attributes,
        reference: values.basicInfo.reference,
        image: values.basicInfo.image,
        supplierId: values.basicInfo.supplier
          ? values.basicInfo.supplier.id
          : "",
        managed: values.basicInfo.managed,
        serialized: values.basicInfo.serialized,
        batchTracked: values.basicInfo.batchTracked,
        unitId: values.basicInfo.unit.id,
        locationId: locationIds,
      },
      attrsStock: values.variants,
    };

    setProductData(productValues);

    if (
      userDetailsSuccess &&
      userDetails.data.orgInfo.xeroConnected &&
      !userDetails.data.orgInfo.xeroConfigured
    ) {
      setXeroDialog(true);
    } else {
      setDialog(true);
      dispatch(createNewProductRequest(productValues));
    }
  };

  const closeDialogWithSuccess = () => {
    navigate("/inventory/product-list", { replace: true, from: location });
  };

  const proceedWithoutUpdateToXero = () => {
    setXeroDialog(false);
    setDialog(true);
    dispatch(createNewProductRequest(productData));
  };

  return (
    <div className={commonStyle.pageContainer}>
      <MappingXeroAccountDialog
        title="Create New Product"
        isDialogOpen={xeroDialog}
        closeDialog={() => setXeroDialog(false)}
        proceedAction={proceedWithoutUpdateToXero}
      />

      <SimpleDialog
        title="Create New Product"
        successMessage="New product added successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={createLoading}
        loadingMessage="Processing request..."
        success={createSuccess}
        error={createError}
        confirmAction={closeDialogWithSuccess}
      />
      {cloneProductId ? (
        <SimpleDialog
          title="Clone Product"
          successMessage="The product has been cloned successfully."
          isDialogOpen={cloneDialog}
          closeDialog={() => setCloneDialog(false)}
          loading={detailsLoading}
          loadingMessage="Cloning product details..."
          success={detailsSuccess}
          error={detailsError}
          confirmAction={() => setCloneDialog(false)}
        />
      ) : (
        <></>
      )}

      <Breadcrumbs screenName="New Product" />
      <ScreenTitle title="New Product" status="New" />

      {cloneProductId ? (
        <>
          {loading ? (
            <Loader mess="Requesting data, please wait a moment..." />
          ) : success ? (
            detailsLoading ? (
              <Loader mess="Cloning product details, please wait a moment..." />
            ) : detailsSuccess ? (
              <div className={inventoryStyle.formCon}>
                <ProductDetailsForm
                  type="new"
                  initialBasicInfo={cloneProductValues}
                  initialVariants={productInfo.attrsDetails}
                  onSave={saveProductInfo}
                />
              </div>
            ) : (
              <ErrorMessage mess={detailsError} />
            )
          ) : (
            <ErrorMessage mess={error} />
          )}
        </>
      ) : (
        <>
          {loading ? (
            <Loader mess="Requesting data, please wait a moment..." />
          ) : success ? (
            <div className={inventoryStyle.formCon}>
              <ProductDetailsForm
                type="new"
                initialBasicInfo={newProductDefaultValues}
                initialVariants={newProductVariants}
                onSave={saveProductInfo}
                btnName="Create New Product"
              />
            </div>
          ) : (
            <ErrorMessage mess={error} />
          )}
        </>
      )}
    </div>
  );
}
