import React, { useState } from "react";
import {
  LoadingButton,
  SimpleBlueButton,
} from "../../components/editComponents";
import { StyledSelect, StyledInput } from "../../components/inputFields";
import { K_CURRENCIES } from "../../constantsData/currencies";
import { K_COUNTRIES } from "../../constantsData/countries";
import { K_INDUSTRYS } from "../../constantsData/industries";
import { K_DATEFORMAT } from "../../constantsData/dateFormats";
import { K_TIMEZONES } from "../../constantsData/timeZones";

export default function RegisterWithXeroForm({
  initialValues,
  onSubmit,
  registerLoading,
}) {
  const [registerValues, setRegisterValues] = useState(initialValues);

  //define the error message
  const [formErrors, setFormErrors] = useState({});

  // handle input field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setRegisterValues({ ...registerValues, [name]: value });
  };

  // form submit
  const checkValidation = () => {
    const hasErrors = validateCompanyDetail(registerValues);

    if (Object.keys(hasErrors).length === 0) {
      onSubmit(registerValues);
      return true;
    } else {
      setFormErrors(hasErrors);
      return false;
    }
  };

  // check input validation
  const validateCompanyDetail = (values) => {
    const errors = {};
    // if (!values.industry) {
    //   errors.industry = "Industry is required!";
    // }

    if (!values.country) {
      errors.country = "Country is required!";
    }
    if (!values.baseCurrency) {
      errors.baseCurrency = "Base currency is required!";
    }

    if (!values.dateFormat) {
      errors.dateFormat = "Date format is required!";
    }
    if (!values.timeZone) {
      errors.timeZone = "Time zone is required!";
    }
    return errors;
  };

  /////////////////////////////////////////////////////////////

  return (
    <div>
      {/* <StyledInput
        label="Email"
        type="text"
        name="email"
        isReadOnly={true}
        value={registerValues.userEmail}
      />

      <StyledInput
        label="Organization Name*"
        type="text"
        name="organizationName"
        value={registerValues.organizationName}
        onChange={(organizationName) =>
          handleChange({
            target: {
              value: organizationName,
              name: "organizationName",
            },
          })
        }
        error={formErrors.organizationName}
      /> */}
  
      <StyledSelect
        value={registerValues.country}
        placeHolder="Select country"
        label="Country*"
        selectOptions={K_COUNTRIES}
        onChange={(country) =>
          handleChange({
            target: { value: country, name: "country" },
          })
        }
        error={formErrors.country}
      />

      <StyledSelect
        value={registerValues.baseCurrency}
        placeHolder="Select base currency"
        label="Base Currecy*"
        selectOptions={K_CURRENCIES}
        onChange={(baseCurrency) =>
          handleChange({
            target: { value: baseCurrency, name: "baseCurrency" },
          })
        }
        error={formErrors.baseCurrency}
      />

      <StyledSelect
        value={registerValues.dateFormat}
        label="Date Format*"
        placeHolder="Select date format"
        selectOptions={K_DATEFORMAT}
        onChange={(dateFormat) =>
          handleChange({
            target: { value: dateFormat, name: "dateFormat" },
          })
        }
        error={formErrors.dateFormat}
      />
      <StyledSelect
        label="Time Zone*"
        placeHolder="Type to search time zone"
        value={registerValues.timeZone}
        selectOptions={K_TIMEZONES}
        onChange={(timeZone) =>
          handleChange({
            target: { value: timeZone, name: "timeZone" },
          })
        }
        error={formErrors.timeZone}
      />
          <StyledSelect
        value={registerValues.industry}
        placeHolder="Select industry"
        label="Industry"
        selectOptions={K_INDUSTRYS}
        onChange={(industry) =>
          handleChange({
            target: { value: industry, name: "industry" },
          })
        }
        error={formErrors.industry}
      />
     <div className="w-full mt-4">
      {registerLoading ? (
        <LoadingButton name="Saving data...." />
      ) : (
        <SimpleBlueButton
          name="Save Details"
          action={checkValidation}
        />
      )}
      </div>
    </div>
  );
}
