import { SYSTEM_SETTINGS_DETAILS_FAIL, SYSTEM_SETTINGS_DETAILS_REQUEST, SYSTEM_SETTINGS_DETAILS_SUCCESS, UPDATE_SYSTEM_SETTINGS_FAIL, UPDATE_SYSTEM_SETTINGS_REQUEST, UPDATE_SYSTEM_SETTINGS_SUCCESS } from "../constants/systemSettingsConstants";

export const systemSettingsDetailsReducer = (state = { settingDetails: [] }, action) => {
    switch (action.type) {
      case SYSTEM_SETTINGS_DETAILS_REQUEST:
        return { loading: true, success: false };
      case SYSTEM_SETTINGS_DETAILS_SUCCESS:
        return { loading: false, settingDetails: action.payload, success: true };
      case SYSTEM_SETTINGS_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
};

export const updateSystemSettingsReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_SYSTEM_SETTINGS_REQUEST:
      return { loading: true, success: false };
    case UPDATE_SYSTEM_SETTINGS_SUCCESS:
      return { loading: false, success: true };
    case UPDATE_SYSTEM_SETTINGS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
  