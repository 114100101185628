import React, { useState } from "react";
import { PriceText, Tag, ViewDateWithTime } from "../../components/viewComponents";
import commonStyle from "../../style/commonStyle.module.css";
import inventoryStyle from "./inventoryStyle.module.css";
import LocationTab from "./LocationTab";
import VariantTab from "./VariantTab";
import { convtPrice, convtQuantity } from "../../functions/functions";

export default function SerialBatchNumbers({ locations, numbers, variants }) {
  const [activeLocationId, setActiveLocationId] = useState(
    locations[0].locationId
  );
  const [activeVariantId, setActiveVariantId] = useState(
    variants[0].variantCode
  );

  const changeLocationAction = (locationId) => {
    setActiveLocationId(locationId);
  };
  const changeVariantAction = (variantId) => {
    setActiveVariantId(variantId);
  };

  return (
    <div>
      <LocationTab
        locationsData={locations}
        changeLocation={changeLocationAction}
        activeId={activeLocationId}
      />

      <div className={inventoryStyle.logsContentWrapper}>
        {variants[0].variant ? (
          <VariantTab
            variantsData={variants}
            changeVariant={changeVariantAction}
            activeCode={activeVariantId}
          />
        ) : (
          <></>
        )}

        <div className={inventoryStyle.logsContentCon}>
          {numbers.length > 0 ? (
            <table className={commonStyle.logsTable}>
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Quantity</th>
                  <th>Status</th>
                  <th>Expire Date</th>
                  <th>Added Date</th>
                 
                </tr>
              </thead>
              <tbody>
                {numbers.filter(
                  (ele) =>
                    Number(ele.locationId) === Number(activeLocationId) &&
                    Number(ele.variantCode) === Number(activeVariantId)
                ).length > 0 ? (
                  numbers
                    .filter(
                      (ele) =>
                        Number(ele.locationId) === Number(activeLocationId) &&
                        Number(ele.variantCode) === Number(activeVariantId)
                    )
                    .map((item, index3) => (
                      <tr key={index3}>
                        <td>{item.code}</td>

                        <td>{convtQuantity(item.quantity_in_stock)}</td>
                        <td>
                         
                           {item.quantity_in_stock > 0 ? (
                            <Tag name={`${item.quantity_in_stock} Stock on hand`} color="green" />
                          
                          ) : (
                            <></>
                          )}
                        </td>
                        <td>
                          {item.expire_date ? (
                            <ViewDateWithTime value={item.expire_date} />
                          ) : (
                            <></>
                          )}
                        </td>
                        <td>
                          <ViewDateWithTime value={item.atime} />
                        </td>
                       
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td>no records</td>
                  </tr>
                )}
              </tbody>
            </table>
          ) : (
            <p>No records</p>
          )}
        </div>
      </div>
    </div>
  );
}
