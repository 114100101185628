import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import loginStyle from "./loginStyle.module.css";
import { AiOutlineArrowRight } from "react-icons/ai";
import RegisterForm from "./RegisterForm";
import { K_CURRENCIES } from "../../constantsData/currencies";
import { K_COUNTRIES } from "../../constantsData/countries";
import { K_DATEFORMAT } from "../../constantsData/dateFormats";
import { K_TIMEZONES } from "../../constantsData/timeZones";
import useDocumentTitle from "../../useDocumentTitle";
import useMetaTag from "../../useMetaTag";
import LoginAdver from "./LoginAdver";
import { Loader } from "../../components/viewComponents";
import { TextLink } from "../../components/editComponents";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function RegisterScreen() {
  useDocumentTitle(
    "Free Trial Sign up - StockUnify Inventory Management Software"
  );
  useMetaTag(
    "Sign up free trial to explore all the feature in StockUnify inventory management software"
  );

  const headers = {
    "Content-type": "application/json",
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get dlocation
  const [locationStatus, setLocationStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const initialValues = {
    userEmail: "",
    password: "",
    organizationName: "",
    industry: "",
    country: "",
    baseCurrency: "",
    dateFormat: "",
    timeZone: "",
    utcOffset: "",
  };

  const [initialLocationValues, setInitialLocationValues] = useState({
    userEmail: "",
    password: "",
    organizationName: "",
    industry: "",
    country: "",
    baseCurrency: "",
    dateFormat: "",
    timeZone: "",
    utcOffset: "",
  });

  const [registerLoading, setRegisterLoading] = useState(false);
  const [registerError, setRegisterError] = useState("");
  const [registerSuccess, setRegisterSuccess] = useState(false);

  const [email, setEmail] = useState();

  useEffect(() => {
    // get user location
    try {
      setLocationStatus((prevStatus) => {
        return { ...prevStatus, loading: true };
      });

      axios
        .get("https://ipapi.co/json/")
        .then(async (response) => {
          if (response.data) {
            setLocationStatus((prevStatus) => {
              return { ...prevStatus, loading: false, success: true };
            });

            let userLocation = response.data;
            setInitialLocationValues({
              userEmail: "",
              password: "",
              organizationName: "",
              industry: {},
              country: K_COUNTRIES.filter(
                (x) => x.value === userLocation.country_name
              )[0],
              baseCurrency: K_CURRENCIES.filter(
                (x) => x.value === userLocation.currency
              )[0],
              dateFormat: K_DATEFORMAT.filter((x) => x.isDefault === 1)[0],
              timeZone: K_TIMEZONES.filter(
                (x) => x.value === userLocation.timezone
              )[0],
              utcOffset: userLocation.utc_offset,
            });
          } else {
            setLocationStatus((prevStatus) => {
              return {
                ...prevStatus,
                loading: false,
                error: "Get user location failed",
              };
            });
          }
        })
        .catch((err) => {
          setLocationStatus((prevStatus) => {
            return {
              ...prevStatus,
              loading: false,
              error: "Connection error, please try again later",
            };
          });
        });
    } catch (error) {
      setLocationStatus((prevStatus) => {
        return {
          ...prevStatus,
          loading: false,
          error: "Connection error, please try again later",
        };
      });
    }

    // register successfully
    if (registerSuccess) {
      navigate("/activation_pending", { state: email });
    }
  }, [dispatch, navigate, registerSuccess, email]);

  // register submit
  const RegisterRequest = (values) => {
    setEmail(values.userEmail);
    const registerValues = {
      userEmail: values.userEmail,
      password: values.password,
      organizationName: values.organizationName,
      industry: values.industry.value,
      country: values.country.value,
      baseCurrency: values.baseCurrency.value,
      dateFormat: values.dateFormat.value,
      timeZone: values.timeZone.value,
      utcOffset: values.utcOffset,
    };

    try {
      setRegisterLoading(true);
      axios
        .post(SERVER_URL + `/organization/register`, registerValues, headers)
        .then(async (response) => {
          setRegisterLoading(false);
          if (response.data.success) {
            setRegisterSuccess(true);
          } else {
            setRegisterError(
              "Register account failed, please try again later."
            );
          }
        })
        .catch((err) => {
          setRegisterLoading(false);
          setRegisterError("Register account failed, please try again later.");
        });
    } catch (error) {
      setRegisterLoading(false);
      setRegisterError("Connection error, please try again later.");
    }
  };

  return (
    <div className={loginStyle.container}>
      <LoginAdver />

      <div className={loginStyle.loginContainer}>
        <div className={loginStyle.brandLogoCon}>
          <a
            href="https://stockunify.com/"
            className={loginStyle.brandLogo}
          />
        </div>

        <div className={loginStyle.loginFormCon}>
          <div className={loginStyle.loginForm}>
            <h1 className={loginStyle.signin}>Create Account</h1>
            {locationStatus.loading ? (
              <Loader mess="Requesting user location..." />
            ) : locationStatus.success ? (
              <RegisterForm
                initialValues={initialLocationValues}
                onSubmit={RegisterRequest}
                registerLoading={registerLoading}
                registerError={registerError}
              />
            ) : (
              <RegisterForm
                initialValues={initialValues}
                onSubmit={RegisterRequest}
                registerLoading={registerLoading}
                registerError={registerError}
              />
            )}
            <div className={loginStyle.linkCon}>
              <TextLink path={`/login`}
                name="Have an account? Sign in now."
              />
                
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
