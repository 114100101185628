import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import menuStyle from "../../style/menuStyle.module.css";
import Navbar from "./Navbar";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { calculateTrialLeftDays } from "../../functions/functions";
import { auto } from "@popperjs/core";

function Header() {
  const { userDetails, success } = useSelector((state) => state.userDetail);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [days, setDays] = useState(0);
  const texts = [
    days < 0
      ? "Your trial account was expired."
      : `${days} days left for your trial account`,
    "You can use real business data in the trial model",
  ];
  const [displayText, setDisplayText] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 5000); // Change text every 10 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [texts.length]);

  useEffect(() => {
    if (success) {
      if (userDetails.data.orgInfo.plan_name === "trial" && userDetails.data.roles.includes("Super Admin")) {
        setDisplayText(true);
      }
      let days = calculateTrialLeftDays(userDetails.data.orgInfo.expire_time);
      setDays(days);
    }
  }, [success]);

  return (
    <div className={menuStyle.ruiInventoryHeader}>
      <NavLink to="/" className={menuStyle.brandLogo}></NavLink>
      {displayText ? (
        <div className={menuStyle.trialCon}>
          <div className={menuStyle.scrollText}>{texts[currentTextIndex]}</div>
          <NavLink
            to={"/billing"}
            style={{ textDecoration: "none" }}
            className={menuStyle.trialSubscribeBtn}
          >
            Buy Now
          </NavLink>
        </div>
      ) : (
        <></>
      )}

      <div
        style={{
          width: auto,
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <a
          href={`https://support.stockunify.com/guide/get-started`}
          className={menuStyle.userGuideIcon}
          target="_blank"
          rel="noreferrer"
        >
          <AiOutlineQuestionCircle />
          <span className={menuStyle.userGuideText}>User Guide</span>
        </a>

        <Navbar />
      </div>
    </div>
  );
}

export default Header;
