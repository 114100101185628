import axios from "axios";
import { authMiddleware } from "./authMiddleware";
import {
  ADD_SUPPLIER_FAIL,
  ADD_SUPPLIER_REQUEST,
  ADD_SUPPLIER_SUCCESS,
  CREATE_SUPPLIER_OPTIONS_FAIL,
  CREATE_SUPPLIER_OPTIONS_REQUEST,
  CREATE_SUPPLIER_OPTIONS_SUCCESS,
  SUPPLIER_DETAILS_FAIL,
  SUPPLIER_DETAILS_REQUEST,
  SUPPLIER_DETAILS_SUCCESS,
  SUPPLIER_LIST_FAIL,
  SUPPLIER_LIST_REQUEST,
  SUPPLIER_LIST_SUCCESS,
  DELETE_SUPPLIER_FAIL,
  DELETE_SUPPLIER_REQUEST,
  DELETE_SUPPLIER_SUCCESS,
  EDIT_SUPPLIER_REQUEST,
  EDIT_SUPPLIER_SUCCESS,
  EDIT_SUPPLIER_FAIL,
} from "../constants/supplierConstants";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const createSupplierOptionsRequest =
  () => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
     

      dispatch({ type: CREATE_SUPPLIER_OPTIONS_REQUEST });
      axios
        .get(SERVER_URL + `/suppliers/options`, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({
              type: CREATE_SUPPLIER_OPTIONS_SUCCESS,
              payload: response.data.data,
            });
          } else {
            throw Object.assign(
              new Error("Request supplier options failed, please try again later."),
              { code: 901 }
           );
          }
        })
        .catch((err) => {
          dispatch({
            type: CREATE_SUPPLIER_OPTIONS_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: CREATE_SUPPLIER_OPTIONS_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

export const newSupplierRequest = (values) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
   
    dispatch({ type: ADD_SUPPLIER_REQUEST });
    axios
      .post(SERVER_URL + `/suppliers/create`, values, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: ADD_SUPPLIER_SUCCESS });
        } else {
          if (response.data.error === 100) {
            throw Object.assign(
              new Error("Supplier name exists, please use a new name."),
              { code: 902 }
           );
          } else {
            throw Object.assign(
              new Error("Create new supplier failed, please try again."),
              { code: 903 }
           );
            
          }
        }
      })
      .catch((err) => {
        dispatch({
          type: ADD_SUPPLIER_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: ADD_SUPPLIER_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const editSupplierRequest = (values, id) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    

    dispatch({ type: EDIT_SUPPLIER_REQUEST });
    axios
      .put(SERVER_URL + `/suppliers/${id}`, values, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: EDIT_SUPPLIER_SUCCESS });
          // dispatch(usersListRequest());
        } else {
          if (response.data.error === 100) {
            throw Object.assign(
              new Error("Supplier name exists, please use a new name."),
              { code: 902 }
           );
          } else {
            throw Object.assign(
              new Error("Update new supplier failed, please try again."),
              { code: 904 }
           );

          }
        }
      })
      .catch((err) => {
        dispatch({
          type: EDIT_SUPPLIER_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: EDIT_SUPPLIER_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const supplierListRequest = (pageNum, pageSize, keywords, sortOrder) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  try {
    

    dispatch({ type: SUPPLIER_LIST_REQUEST });
    axios
      .get(SERVER_URL + `/suppliers/list?page_num=${pageNum}&page_size=${pageSize}&keywords=${keywords}&sortOrder=${sortOrder}`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({
            type: SUPPLIER_LIST_SUCCESS,
            payload: response.data.data,
          });
        } else {
          throw Object.assign(
            new Error("Request supplier list failed, please try again later."),
            { code: 905 }
         );
        }
      })
      .catch((err) => {
        dispatch({
          type: SUPPLIER_LIST_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: SUPPLIER_LIST_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

// get supplier details
export const supplierDetailsRequest =
  (id, type) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
     

      dispatch({ type: SUPPLIER_DETAILS_REQUEST });

      axios
        .get(SERVER_URL + `/suppliers/${id}`, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({
              type: SUPPLIER_DETAILS_SUCCESS,
              payload: response.data.data,
            });
          } else {
            throw Object.assign(
              new Error("Request supplier details failed, please try again later."),
              { code: 906 }
           );

          }
        })
        .catch((err) => {
          dispatch({
            type: SUPPLIER_DETAILS_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: SUPPLIER_DETAILS_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

  export const deleteSupplierRequest = (id) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();
  
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      
  
      dispatch({ type: DELETE_SUPPLIER_REQUEST });
      axios
        .delete(SERVER_URL + `/suppliers/${id}`, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: DELETE_SUPPLIER_SUCCESS });
          } else {
            throw Object.assign(
              new Error("Supplier deleted failed, please try again later."),
              { code: 907 }
           );
          }
        })
        .catch((err) => {
          dispatch({
            type: DELETE_SUPPLIER_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: DELETE_SUPPLIER_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };
  
