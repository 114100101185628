import React, { useState } from "react";
import billingStyle from "./billingStyle.module.css";
import BillingSteps from "./BillingSteps";
import {
  TextErrorMessage,
  PriceText,
  TipsText,
} from "../../components/viewComponents";
import {
  LoadingButton,
  SimpleBlueButton,
  SimpleOutlineButton,
  SingleRatioButton,
} from "../../components/editComponents";

export default function BillingStepTwo({
  selectedPlan,
  annually,
  checkoutAction,
  extraUsers,
  isStep1,
  isStep2,
  extraUserPrice,
  planPrice,
  subtotal,
  discountPrice,
  subtotalLessDiscount,
  totalPrice,
  gst,
  backAction,
  loading,
  error,
  userCurrency,
  userCountry,
}) {
  // components
  const [agreements, setAgreements] = useState({
    termOfUse: false,
    authorization: false,
  });
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAgreements({ ...agreements, [name]: value });
  };

  const checkValidation = (e) => {
    const hasErrors = validate(agreements);

    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});
      checkoutAction();
    } else {
      setFormErrors(hasErrors);
    }
  };

  // check input validation
  const validate = (values) => {
    const errors = {};

    if (!values.termOfUse) {
      errors.termOfUse =
        "Please read and accept the term of use before checkout.";
    }
    if (!values.authorization) {
      errors.authorization = "Please authorize repeat billing before checkout.";
    }

    return errors;
  };

  return (
    <div>
      <BillingSteps step1={isStep1} step2={isStep2} />

      <div className={billingStyle.billingSummaryWrapper}>
        <p className={billingStyle.summaryTitle}>Billing Summary</p>

        <table className={billingStyle.billingSummaryTable}>
          <tbody>
            <tr>
              <td className={billingStyle.textLabel}>Plan Name</td>
              <td className={billingStyle.summaryPriceColumn}>
                {selectedPlan}
              </td>
            </tr>
            <tr>
              <td className={billingStyle.textLabel}>Payment Method</td>
              <td className={billingStyle.summaryPriceColumn}>
                {annually ? "Paid annually" : "Paid monthly"}
              </td>
            </tr>

            <tr>
              <td className={billingStyle.textLabel}>Users</td>
              <td className={billingStyle.summaryPriceColumn}>
                {selectedPlan === "startUp"
                  ? "1 user included"
                  : selectedPlan === "basic"
                  ? "3 users included"
                  : selectedPlan === "business"
                  ? "5 users included"
                  : selectedPlan === "enterprise"
                  ? "12 users included"
                  : ""}
              </td>
            </tr>
            <tr className={billingStyle.borderTr}>
              <td className={billingStyle.textLabel}>Plan Price</td>
              <td className={billingStyle.summaryPriceColumn}>
                <PriceText num={planPrice} />
              </td>
            </tr>
            {selectedPlan === "business" || selectedPlan === "enterprise" ? (
              <tr style={{ height: "60px" }} className={billingStyle.borderTr}>
                <td>
                  <span>Extra User x {extraUsers}</span>
                </td>

                <td className={billingStyle.summaryPriceColumn}>
                  <PriceText num={extraUserPrice} />
                </td>
              </tr>
            ) : (
              <></>
            )}

            <tr>
              <td>Subtotal</td>
              <td>
                <PriceText num={subtotal} />
              </td>
            </tr>
            {discountPrice > 0 ? (
              <>
                <tr className={billingStyle.borderTr}>
                  <td>Discount</td>
                  <td>
                    <PriceText num={discountPrice} />
                  </td>
                </tr>
                <tr>
                  <td>Subtotal Less Disocunt</td>
                  <td>
                    <PriceText num={subtotalLessDiscount} />
                  </td>
                </tr>
              </>
            ) : (
              <></>
            )}

            {userCountry === "New Zealand" ? (
              <tr className={billingStyle.borderTr}>
                <td>GST(15%)</td>
                <td>
                  <PriceText num={gst} />
                </td>
              </tr>
            ) : (
              <></>
            )}

            <tr>
              <td className={billingStyle.totalNum}>Total</td>
              <td className={billingStyle.totalNum}>
                <PriceText num={totalPrice} />
                {userCurrency}
              </td>
            </tr>
          </tbody>
        </table>
        {formErrors.termOfUse ? (
          <TextErrorMessage mess={formErrors.termOfUse} />
        ) : (
          <></>
        )}
        {formErrors.authorization ? (
          <TextErrorMessage mess={formErrors.authorization} />
        ) : (
          <></>
        )}
        <div className={billingStyle.termCon}>
          <SingleRatioButton
            label="I have read and accept the"
            name="hasAttrs"
            isOn={agreements.termOfUse}
            onChange={(termOfUse) =>
              handleChange({
                target: { value: termOfUse, name: "termOfUse" },
              })
            }
          />

          <a
            href={`https://stockunify.com/legal/terms-of-use`}
            className={billingStyle.termLink}
            target="_blank"
            rel="noreferrer"
          >
            Term of use
          </a>
          <span className={billingStyle.termLinkText}> and </span>
          <a
            href={`https://stockunify.com/legal/privacy-policy`}
            className={billingStyle.termLink}
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </div>
        <SingleRatioButton
          label="By agreeing the “Terms of Use”. I hereby authorize StockUnify to deduct payment for any periodic suscription payment that fall due based on the subscription option I have chosen, from my selected credit/debit card."
          name="hasAttrs"
          isOn={agreements.authorization}
          onChange={(authorization) =>
            handleChange({
              target: { value: authorization, name: "authorization" },
            })
          }
        />

        {error ? <TextErrorMessage mess={error} /> : <></>}

        <div style={{ width:"100%", display: "flex", alignItems:"center",}}>
          <div style={{marginRight: "20px" }}>
            <SimpleOutlineButton name="Back to Plan" action={backAction} />
          </div>
          <div style={{ width: "60%" }}>
            {loading ? (
              <LoadingButton name="Requesting Stripe Checkout..." />
            ) : (
              <SimpleBlueButton name="Checkout" action={checkValidation} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
