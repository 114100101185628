import React from "react";
import loginStyle from "./loginStyle.module.css";

export default function LoginAdver() {
  return (
    <div className={loginStyle.adverCon}>
      <div className={loginStyle.adverTextCon}>
        <p className={loginStyle.adverText}>Simplify Inventory Management</p>
        <p className={loginStyle.bulletPointText}>
          Get real-time insights into your inventory with 24/7 dashboard
        </p>
        <p className={loginStyle.bulletPointText}>
          Manage product with mulitiple variations
        </p>
        <p className={loginStyle.bulletPointText}>
          Take control of each sale order's margin
        </p>
        <p className={loginStyle.bulletPointText}>
          Manage stock in multiple location
        </p>
      </div>


      <div className={loginStyle.adverImageCon}>
        <img src="https://d1o1skvu5gf7wj.cloudfront.net/rui/web_icons/login_illustration.svg" alt="Cloud-based inventory management software" className={loginStyle.adverImage}/>
      </div>
    </div>
  );
}
