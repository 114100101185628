import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import {
  productVariantDetailsRequest,
  updateProductCustomPriceRequest,
} from "../../actions/customPriceActions";
import {
  ScreenTitle,
  SimpleBlueButtonLink,
} from "../../components/editComponents";
import {
  SimpleDialog,
  ErrorMessage,
  Loader,
} from "../../components/viewComponents";
import commonStyle from "../../style/commonStyle.module.css";
import CustomPriceForm from "./CustomPriceForm";

export default function CustomPriceScreen() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { id: productId } = useParams();

  const { loading, productVariantPrice, error, success } = useSelector(
    (state) => state.productVariant
  );

  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
  } = useSelector((state) => state.updateCustomPrice);

  const [dialog, setDialog] = useState(false);

  useEffect(() => {
    dispatch(productVariantDetailsRequest(productId));
  }, [dispatch, productId]);

  const saveCustomPriceRequest = (prices, priceTier) => {
    setDialog(true);

    let priceTierType = [];
    priceTier.forEach((ele) => {
      priceTierType.push({
        priceTierId: ele.id,
        priceTierName: ele.name,
        isStaticPrice: ele.isStaticPrice,
      });
    });

    let requestValues = {
      prices: prices,
      priceTierType: priceTierType,
    };

    dispatch(updateProductCustomPriceRequest(requestValues, productId));
  };

  const closeDialogWithSuccess = () => {
    navigate("/inventory/product-list", { replace: true, from: location });
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Update Custom Price"
        successMessage="Custom price updated successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={updateLoading}
        loadingMessage="Processing request..."
        success={updateSuccess}
        error={updateError}
        btnValue="Confirm"
        confirmAction={closeDialogWithSuccess}
      />

      <Breadcrumbs screenName="Custom Price" />
      <ScreenTitle title="Custom Price" />

      <div className={commonStyle.pageContentContainer}>
        {loading ? (
          <Loader mess="Requesting product price details, please wait a moment..." />
        ) : success ? (
          productVariantPrice.priceTier.length > 0 ? (
            <CustomPriceForm
              productName={productVariantPrice.productName}
              initialPrices={productVariantPrice.variant}
              initialPriceTierList={productVariantPrice.priceTier}
              saveCustomPrice={saveCustomPriceRequest}
            />
          ) : (
            <div>
              <p>
                There are no price tier available, add a new price tier now.
              </p>
              <div className="w-full mt-6">
                <SimpleBlueButtonLink
                  name="Add New Price Tier"
                  path="/settings/price-tier"
                />
              </div>
            </div>
          )
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
    </div>
  );
}
