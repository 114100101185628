import { XERO_TASK_LIST_FAIL, XERO_TASK_LIST_REQUEST, XERO_TASK_LIST_SUCCESS } from "../constants/xderoConstants";


export const xeroTaskListReducer = (state = { xeroTasks: [] }, action) => {
  switch (action.type) {
    case XERO_TASK_LIST_REQUEST:
      return { loading: true, success: false };
    case XERO_TASK_LIST_SUCCESS:
      return { loading: false, xeroTasks: action.payload, success: true };
    case XERO_TASK_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
