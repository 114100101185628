import React, { useState } from "react";

import {
  SimpleBlueButton,
  SingleRatioButton,
} from "../../../components/editComponents";
import {
  StyledInput,
} from "../../../components/inputFields";

export default function LocationForm({ btnName, values, onSubmit }) {
  const [newValues, setNewValues] = useState(values);
  const [formErrors, setFormErrors] = useState({});

  const checkValidation = () => {
    const hasErrors = validate(newValues);

    if (Object.keys(hasErrors).length === 0) {
      onSubmit(newValues, "edit");
    } else {
      setFormErrors(hasErrors);
    }
  };
  // handle change when input
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewValues({ ...newValues, [name]: value });
  };

  // check input validation
  const validate = (values) => {
  
    const errors = {};

    if (!values.name) {
      errors.name = "Location name is required!";
    }

    return errors;
  };

  return (
    <form>
      <StyledInput
        label="Name*"
        type="text"
        name="name"
        autofocus="autofocus"
        value={newValues.name}
        onChange={(name) =>
          handleChange({
            target: { value: name, name: "name" },
          })
        }
        error={formErrors.name}
      />
      <StyledInput
        label="Address"
        type="text"
        name="address"
        value={newValues.address}
        onChange={(address) =>
          handleChange({
            target: { value: address, name: "address" },
          })
        }
        error={formErrors.address}
      />
      <StyledInput
        label="Description"
        type="text"
        name="description"
        value={newValues.description}
        onChange={(description) =>
          handleChange({
            target: { value: description, name: "description" },
          })
        }
        error={formErrors.description}
      />
      <SingleRatioButton
        label="Default location"
        name="isDefault"
        isOn={newValues.isDefault}
        onChange={(isDefault) =>
          handleChange({
            target: { value: isDefault, name: "isDefault" },
          })
        }
      />
             <div className="w-full mt-6">
      <SimpleBlueButton name={btnName} action={checkValidation} />
      </div>
    </form>
  );
}
