import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { newUserRequest } from "../../../actions/userRoleActions";
import Breadcrumbs from "../../../components/Breadcrumbs";
import commonStyle from "../../../style/commonStyle.module.css";
import UserDetailForm from "./UserDetailForm";
import { SimpleDialog } from "../../../components/viewComponents";
import useDocumentTitle from "../../../useDocumentTitle";
import { ScreenTitle } from "../../../components/editComponents";

export default function NewUser() {
  useDocumentTitle("New User")
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const newUserInitialValues = {
    firstName: "",
    lastName: "",
    userEmail: "",
    userPhone: "",
    position: "",
    location: "",
    department: "",
    roles: "",
  };

  const { loading, success, error } = useSelector(
    (state) => state.newUser
  );

  const [addNewUserDialog, setNewUserDialog] = useState(false);
  const closeDialogWithSuccess = () => {
    navigate("/settings/users", { replace: true, from: location });
  };
  
  const addNewUserRequest = (values) => {
    setNewUserDialog(true);
    let selectedRolesId = [];

    for (let i = 0; i < values.roles.length; i++) {
      selectedRolesId.push(values.roles[i].id);
    }

    let userValues = {
      email: values.userEmail,
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.userPhone,
      position: values.position,
      locationId: values.location.id,
      departmentId: values.department.id,
      roleIds: selectedRolesId,
    };

    dispatch(newUserRequest(userValues));
  };

 

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Invite New User"
        successMessage="The invitation to the new user was successful. An email has been sent to the user's email address."
        isDialogOpen={addNewUserDialog}
        closeDialog={() => setNewUserDialog(false)}
        loading={loading}
        loadingMessage="Processing request..."
        success={success}
        error={error}
        btnValue="Confirm"
        confirmAction={closeDialogWithSuccess}
      />

      <Breadcrumbs screenName="New User" />
      <ScreenTitle title="New User" />

          <div className={commonStyle.pageContentContainer}>
            <UserDetailForm
              values={newUserInitialValues}
              onSubmit={addNewUserRequest}
              btnName="Invite New User"
              title="Add New User"
            />
          </div>
        </div>

  );
}
