import axios from "axios";
import { authMiddleware } from "./authMiddleware";
import {
  EXPORT_CSV_FILE_FAIL,
  EXPORT_CSV_FILE_REQUEST,
  EXPORT_CSV_FILE_SUCCESS,
  IMPORT_CSV_FILE_FAIL,
  IMPORT_CSV_FILE_REQUEST,
  IMPORT_CSV_FILE_SUCCESS,
  IO_LOGS_FAIL,
  IO_LOGS_REQUEST,
  IO_LOGS_SUCCESS,
} from "../constants/IOConstants";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const importCsvRequest = (values, type) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {

    dispatch({ type: IMPORT_CSV_FILE_REQUEST });
    axios
      .post(SERVER_URL + `/import?category=${type}`, values, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: IMPORT_CSV_FILE_SUCCESS });
        } else {
          throw Object.assign(
            new Error("Upload csv file failed, please try again later"),
            { code: 1504 }
          );
        }
      })
      .catch((err) => {
        dispatch({
          type: IMPORT_CSV_FILE_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: IMPORT_CSV_FILE_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const exportCsvRequest = (type) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    dispatch({ type: EXPORT_CSV_FILE_REQUEST });
    axios
      .get(SERVER_URL + `/export?category=${type}`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: EXPORT_CSV_FILE_SUCCESS });
        } else {
          throw Object.assign(
            new Error("Create export csv task failed, please try again later"),
            { code: 1504 }
          );
        }
      })
      .catch((err) => {
        dispatch({
          type: EXPORT_CSV_FILE_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: EXPORT_CSV_FILE_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

// get export import logs list
export const importExportLogsRequest =
  (category = "", actionType = "") =>
  async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {

      dispatch({ type: IO_LOGS_REQUEST });

      axios
        .get(SERVER_URL + `/import_export/logs?category=${category}&action_type=${actionType}`, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({
              type: IO_LOGS_SUCCESS,
              payload: response.data.data,
            });
          } else {
            throw Object.assign(
              new Error(
                "Request import/export logs failed, please try again later"
              ),
              { code: 105 }
            );
          }
        })
        .catch((err) => {
          dispatch({
            type: IO_LOGS_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: IO_LOGS_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };
