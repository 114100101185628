import {
  ORG_LOCATION_LIST_FAIL,
  ORG_LOCATION_LIST_REQUEST,
  ORG_LOCATION_LIST_SUCCESS,
  NEW_ORG_LOCATION_FAIL,
  NEW_ORG_LOCATION_REQUEST,
  NEW_ORG_LOCATION_SUCCESS,
  UPDATE_ORG_LOCATION_FAIL,
  UPDATE_ORG_LOCATION_REQUEST,
  UPDATE_ORG_LOCATION_SUCCESS,
  DELETE_ORG_LOCATION_FAIL,
  DELETE_ORG_LOCATION_REQUEST,
  DELETE_ORG_LOCATION_SUCCESS,
  ORG_DEP_LIST_FAIL,
  ORG_DEP_LIST_REQUEST,
  ORG_DEP_LIST_SUCCESS,
  NEW_ORG_DEP_REQUEST,
  NEW_ORG_DEP_SUCCESS,
  NEW_ORG_DEP_FAIL,
  UPDATE_ORG_DEP_REQUEST,
  UPDATE_ORG_DEP_SUCCESS,
  UPDATE_ORG_DEP_FAIL,
  DELETE_ORG_DEP_REQUEST,
  DELETE_ORG_DEP_SUCCESS,
  DELETE_ORG_DEP_FAIL,
  SALE_TAX_RATE_LIST_REQUEST,
  SALE_TAX_RATE_LIST_SUCCESS,
  SALE_TAX_RATE_LIST_FAIL,
  NEW_SALE_TAX_RATE_REQUEST,
  NEW_SALE_TAX_RATE_SUCCESS,
  NEW_SALE_TAX_RATE_FAIL,
  UPDATE_SALE_TAX_RATE_REQUEST,
  UPDATE_SALE_TAX_RATE_SUCCESS,
  UPDATE_SALE_TAX_RATE_FAIL,
  ORG_DETAILS_REQUEST,
  ORG_DETAILS_SUCCESS,
  ORG_DETAILS_FAIL,
  ORG_DETAILS_UPDATE_REQUEST,
  ORG_DETAILS_UPDATE_SUCCESS,
  ORG_DETAILS_UPDATE_FAIL,
  CURRENCY_LIST_REQUEST,
  CURRENCY_LIST_SUCCESS,
  CURRENCY_LIST_FAIL,
  NEW_CURRENCY_REQUEST,
  NEW_CURRENCY_SUCCESS,
  NEW_CURRENCY_FAIL,
  UPDATE_CURRENCY_REQUEST,
  UPDATE_CURRENCY_SUCCESS,
  UPDATE_CURRENCY_FAIL,
  DELETE_CURRENCY_REQUEST,
  DELETE_CURRENCY_SUCCESS,
  DELETE_CURRENCY_FAIL,
  EMAIL_TEMPLATES_LIST_REQUEST,
  EMAIL_TEMPLATES_LIST_SUCCESS,
  EMAIL_TEMPLATES_LIST_FAIL,
  UPDATE_EMAIL_TEMPLATE_REQUEST,
  UPDATE_EMAIL_TEMPLATE_SUCCESS,
  UPDATE_EMAIL_TEMPLATE_FAIL,
  RESET_UPDATE_EMAIL_TEMPLATE_STATUS,
  EMAIL_TEMPLATES_DETAILS_REQUEST,
  EMAIL_TEMPLATES_DETAILS_SUCCESS,
  EMAIL_TEMPLATES_DETAILS_FAIL,
  CHECK_ORG_DB_STATUS_REQUEST,
  CHECK_ORG_DB_STATUS_SUCCESS,
  CHECK_ORG_DB_STATUS_FAIL,
} from "../constants/orgSettingsConstants";

// org settings

export const checkOrgStatusReducer = (
  state = { created: false },
  action
) => {
  switch (action.type) {
    case CHECK_ORG_DB_STATUS_REQUEST:
      return { loading: true, success: false };
    case CHECK_ORG_DB_STATUS_SUCCESS:
      return { loading: false, created: action.payload, success: true };
    case CHECK_ORG_DB_STATUS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};



export const organizationDetailsReducer = (
  state = { orgInfo: [] },
  action
) => {
  switch (action.type) {
    case ORG_DETAILS_REQUEST:
      return { loading: true, success: false };
    case ORG_DETAILS_SUCCESS:
      return { loading: false, orgInfo: action.payload, success: true };
    case ORG_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const organizationUpdateDetailsReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case ORG_DETAILS_UPDATE_REQUEST:
      return { loading: true, success: false };
    case ORG_DETAILS_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case ORG_DETAILS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};




///////////////////////location/////////////////////////////
////////////////////////////////////////////////////////////
export const locationListReducer = (state = { locations: [] }, action) => {
  switch (action.type) {
    case ORG_LOCATION_LIST_REQUEST:
      return { loading: true, success: false };
    case ORG_LOCATION_LIST_SUCCESS:
      return { loading: false, locations: action.payload, success: true };
    case ORG_LOCATION_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const newLocationReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_ORG_LOCATION_REQUEST:
      return { loading: true, success: false };
    case NEW_ORG_LOCATION_SUCCESS:
      return { loading: false, success: true };
    case NEW_ORG_LOCATION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateLocationReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_ORG_LOCATION_REQUEST:
      return { loading: true, success: false };
    case UPDATE_ORG_LOCATION_SUCCESS:
      return { loading: false, success: true };
    case UPDATE_ORG_LOCATION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteLocationReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_ORG_LOCATION_REQUEST:
      return { loading: true, success: false };
    case DELETE_ORG_LOCATION_SUCCESS:
      return { loading: false, success: true };
    case DELETE_ORG_LOCATION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

///////////////////////department////////////////////////////
////////////////////////////////////////////////////////////
export const departmentListReducer = (state = { departments: [] }, action) => {
  switch (action.type) {
    case ORG_DEP_LIST_REQUEST:
      return { loading: true, success: false };
    case ORG_DEP_LIST_SUCCESS:
      return { loading: false, departments: action.payload, success: true };
    case ORG_DEP_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const newDepartmentReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_ORG_DEP_REQUEST:
      return { loading: true, success: false };
    case NEW_ORG_DEP_SUCCESS:
      return { loading: false, success: true };
    case NEW_ORG_DEP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateDepartmentReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_ORG_DEP_REQUEST:
      return { loading: true, success: false };
    case UPDATE_ORG_DEP_SUCCESS:
      return { loading: false, success: true };
    case UPDATE_ORG_DEP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteDepartmentReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_ORG_DEP_REQUEST:
      return { loading: true, success: false };
    case DELETE_ORG_DEP_SUCCESS:
      return { loading: false, success: true };
    case DELETE_ORG_DEP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

///////////////////////sale tax rate/////////////////////////////
////////////////////////////////////////////////////////////
export const saleTaxRateListReducer = (
  state = { saleTaxRates: [] },
  action
) => {
  switch (action.type) {
    case SALE_TAX_RATE_LIST_REQUEST:
      return { loading: true, success: false };
    case SALE_TAX_RATE_LIST_SUCCESS:
      return { loading: false, saleTaxRates: action.payload, success: true };
    case SALE_TAX_RATE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const newSaleTaxRateReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_SALE_TAX_RATE_REQUEST:
      return { loading: true, success: false };
    case NEW_SALE_TAX_RATE_SUCCESS:
      return { loading: false, success: true };
    case NEW_SALE_TAX_RATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateSaleTaxRateReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_SALE_TAX_RATE_REQUEST:
      return { loading: true, success: false };
    case UPDATE_SALE_TAX_RATE_SUCCESS:
      return { loading: false, success: true };
    case UPDATE_SALE_TAX_RATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

///////////////////////currency////////////////////////////
////////////////////////////////////////////////////////////
export const currencyListReducer = (state = { currencies: [] }, action) => {
  switch (action.type) {
    case CURRENCY_LIST_REQUEST:
      return { loading: true, success: false };
    case CURRENCY_LIST_SUCCESS:
      return { loading: false, currencies: action.payload, success: true };
    case CURRENCY_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const newCurrencyReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_CURRENCY_REQUEST:
      return { loading: true, success: false };
    case NEW_CURRENCY_SUCCESS:
      return { loading: false, success: true };
    case NEW_CURRENCY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateCurrencyReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CURRENCY_REQUEST:
      return { loading: true, success: false };
    case UPDATE_CURRENCY_SUCCESS:
      return { loading: false, success: true };
    case UPDATE_CURRENCY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteCurrencyReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_CURRENCY_REQUEST:
      return { loading: true, success: false };
    case DELETE_CURRENCY_SUCCESS:
      return { loading: false, success: true };
    case DELETE_CURRENCY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// email templates
export const emailTemplatesListReducer = (state = {templates: []}, action) => {
  switch (action.type) {
    case EMAIL_TEMPLATES_LIST_REQUEST:
      return { loading: true, success: false };
    case EMAIL_TEMPLATES_LIST_SUCCESS:
      return { loading: false, templates: action.payload, success: true };
    case EMAIL_TEMPLATES_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateEmailTemplateReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_EMAIL_TEMPLATE_REQUEST:
      return { loading: true, success: false };
    case UPDATE_EMAIL_TEMPLATE_SUCCESS:
      return { loading: false, success: true };
    case UPDATE_EMAIL_TEMPLATE_FAIL:
      return { loading: false, error: action.payload };
    case RESET_UPDATE_EMAIL_TEMPLATE_STATUS:
      return { loading: false, error: "", success: false };;
    default:
      return state;
  }
};

export const emailTemplatesDetailsReducer = (state = {templateDetails: {}}, action) => {
  switch (action.type) {
    case EMAIL_TEMPLATES_DETAILS_REQUEST:
      return { loading: true, success: false };
    case EMAIL_TEMPLATES_DETAILS_SUCCESS:
      return { loading: false, templateDetails: action.payload, success: true };
    case EMAIL_TEMPLATES_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


