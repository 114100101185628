import axios from "axios";
import { SUBSCRIPTION_DETAILS_FAIL, SUBSCRIPTION_DETAILS_REQUEST, SUBSCRIPTION_DETAILS_SUCCESS } from "../constants/billingConstants";
import { authMiddleware } from "./authMiddleware";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const subscriptionDetailsRequest = () => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    dispatch({ type: SUBSCRIPTION_DETAILS_REQUEST });
    axios
      .get(SERVER_URL + `/billing/subscription_details`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({
            type: SUBSCRIPTION_DETAILS_SUCCESS,
            payload: response.data.data,
          });
        } else {
          throw Object.assign(
            new Error("Request subscription details failed, please try again later."),
            { code: 1822 }
         );
        }
      })
      .catch((err) => {
        dispatch({
          type: SUBSCRIPTION_DETAILS_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: SUBSCRIPTION_DETAILS_FAIL,
      payload: "Connection error, please refresh the page",
    });
  }
};

