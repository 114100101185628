import React, { useEffect, useState } from "react";
import axios from "axios";
import loginStyle from "./loginStyle.module.css";
import {
  TextErrorMessage,
  Loader
} from "../../components/viewComponents";
import { SimpleBlueButtonLink } from "../../components/editComponents";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function XeroRegisterPassCode({
  codeExchangeData,

}) {


  ////////////////////////////////// //////////////////////////////////
  ////////////////////////////////// //////////////////////////////////

  const [getCodeError, setGetCodeError] = useState("");
  const [showLoginBtn, setShowLoginBtn] = useState(false);
  const [showXeroLoginBtn, setShowXeroLoginBtn] = useState(false);

  const [excahngeCodeStatus, setExchangeCodeStatus] = useState({
    loading: false,
    error: "",
  });
  ////////////////////////////////// //////////////////////////////////
  ////////////////////////////////// //////////////////////////////////

  useEffect(() => {
    /////////////////////////////////////////
    const query = new URLSearchParams(window.location.search);
    let code = query.get("code");
    let error = query.get("error");
    if (error) {
      setGetCodeError(true);
    }

    if (code) {
      let authValues = {
        authCode: code,
      };

      try {
        setExchangeCodeStatus((prevStatus) => {
          return { ...prevStatus, loading: true };
        });
        axios
          .post(SERVER_URL + `/register/xero/pass_code`, authValues)
          .then(async (response) => {
            if (response.data.success) {
              codeExchangeData(response.data);
              setExchangeCodeStatus((prevStatus) => {
                return { ...prevStatus, loading: false };
              });
            } else if (response.data.error === 30) {
              setShowLoginBtn(true);
              setExchangeCodeStatus((prevStatus) => {
                return {
                  ...prevStatus,
                  loading: false,
                  error: "Xero account email has registered, please login",
                };
              });
            } else if (response.data.error === 20 || response.data.error === 10) {
              setShowXeroLoginBtn(true);
              setExchangeCodeStatus((prevStatus) => {
                return {
                  ...prevStatus,
                  loading: false,
                  error: "Xero account has connected with StockUnify, please log in.",
                };
              });
            } else {
              setExchangeCodeStatus((prevStatus) => {
                return {
                  ...prevStatus,
                  loading: false,
                  error: "Register with Xero failed, please try again later.",
                };
              });
            }
          })
          .catch((err) => {
            setExchangeCodeStatus((prevStatus) => {
              return {
                ...prevStatus,
                loading: false,
                error: "Register with Xero failed, please try again later.",
              };
            });
          });
      } catch (error) {
        setExchangeCodeStatus((prevStatus) => {
          return {
            ...prevStatus,
            loading: false,
            error: "Register with Xero failed, please try again later.",
          };
        });
      }
    }
  }, []);

  ///////////////////////////////////////////////////////////////////////////////////

  return (
    <div>
      {getCodeError ? (
           <div style={{marginTop:"16px"}}>
        <TextErrorMessage mess="Register with Xero failed, please try again later" />
        </div>
      ) : (
        <></>
      )}
      {excahngeCodeStatus.error ? (
        <TextErrorMessage mess={excahngeCodeStatus.error} />
      ) : (
        <></>
      )}

      {excahngeCodeStatus.loading ? (
        <Loader mess="Connecting with Xero..." />
      ) : (
        <></>
      )}

      {showLoginBtn ? (
        <div>
          <SimpleBlueButtonLink path="/login" name="Log in"/>
        </div>
      ) : (
        <></>
      )}
       {showXeroLoginBtn ? (
        <div>
          <SimpleBlueButtonLink path="/login?xeroLogin=1" name="Log in With Xero"/>
          
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
