import {
  ALL_PERMISSIONS_FAIL,
  ALL_PERMISSIONS_REQUEST,
  ALL_PERMISSIONS_SUCCESS,
  USERS_LIST_REQUEST,
  USERS_LIST_SUCCESS,
  USERS_LIST_FAIL,
  ROLE_NAME_LIST_REQUEST,
  ROLE_NAME_LIST_SUCCESS,
  ROLE_NAME_LIST_FAIL,
  ROLE_DETAIL_REQUEST,
  ROLE_DETAIL_SUCCESS,
  ROLE_DETAIL_FAIL,
  NEW_USER_REQUEST,
  NEW_USER_SUCCESS,
  NEW_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  CREATE_USER_OPTIONS_REQUEST,
  CREATE_USER_OPTIONS_SUCCESS,
  CREATE_USER_OPTIONS_FAIL,
  NEW_ROLE_REQUEST,
  NEW_ROLE_SUCCESS,
  NEW_ROLE_FAIL,
  UPDATE_ROLE_REQUEST,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAIL,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL
} from "../constants/userRoleConstants";

//**users and roles */

// create role and edit role
export const AllPermissionsReducer = (state = { permissions: {} }, action) => {
  switch (action.type) {
    case ALL_PERMISSIONS_REQUEST:
      return { loading: true, success: false };
    case ALL_PERMISSIONS_SUCCESS:
      return { loading: false, permissions: action.payload, success: true };
    case ALL_PERMISSIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const roleNamesListReducer = (state = { roleNames: [] }, action) => {
  switch (action.type) {
    case ROLE_NAME_LIST_REQUEST:
      return { loading: true, success: false };
    case ROLE_NAME_LIST_SUCCESS:
      return { loading: false, success: true, roleNames: action.payload };
    case ROLE_NAME_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const roleDetailReducer = (state = { roleDetail: [] }, action) => {
  switch (action.type) {
    case ROLE_DETAIL_REQUEST:
      return { loading: true, success: false };
    case ROLE_DETAIL_SUCCESS:
      return { loading: false, roleDetail: action.payload, success: true };
    case ROLE_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const newRoleReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_ROLE_REQUEST:
      return { loading: true, success: false };
    case NEW_ROLE_SUCCESS:
      return { loading: false, success: true };
    case NEW_ROLE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateRoleReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_ROLE_REQUEST:
      return { loading: true, success: false };
    case UPDATE_ROLE_SUCCESS:
      return { loading: false, success: true };
    case UPDATE_ROLE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteRoleReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_ROLE_REQUEST:
      return { loading: true, success: false };
    case DELETE_ROLE_SUCCESS:
      return { loading: false, success: true };
    case DELETE_ROLE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


// users
export const usersListReducer = (state = { usersList: {} }, action) => {
  switch (action.type) {
    case USERS_LIST_REQUEST:
      return { loading: true, success: false };
    case USERS_LIST_SUCCESS:
      return { loading: false, usersList: action.payload, success: true };
    case USERS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const newUserReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_USER_REQUEST:
      return { loading: true, success: false };
    case NEW_USER_SUCCESS:
      return { loading: false, success: true };
    case NEW_USER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateUserReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_USER_REQUEST:
      return { loading: true, success: false };
    case UPDATE_USER_SUCCESS:
      return { loading: false, success: true };
    case UPDATE_USER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createNewUserOptionsReducer = (state = {options:{}}, action) => {
  switch (action.type) {
    case CREATE_USER_OPTIONS_REQUEST:
      return { loading: true, success: false };
    case CREATE_USER_OPTIONS_SUCCESS:
      return { loading: false, options: action.payload, success: true };
    case CREATE_USER_OPTIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteUserReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_USER_REQUEST:
      return { loading: true, success: false };
    case DELETE_USER_SUCCESS:
      return { loading: false, success: true };
    case DELETE_USER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


