import React, { useState } from "react";
import SideMenuItem from "./SideMenuItem";
import CollapsedMenu from "./CollapsedMenu";
import menuStyle from "../../style/menuStyle.module.css";
import { useSelector } from "react-redux";
import { menuItemsData } from "../../menuItemsData";
import {
  BsBoxArrowInRight,
  BsBoxArrowInLeft,
  BsFillHouseFill,
} from "react-icons/bs";
import { MdHelp } from "react-icons/md";
import {Modal} from "../../components/viewComponents";
import FeedbackForm from "./FeedbackForm";

function SideMenu() {

  const { userDetails, success } = useSelector((state) => state.userDetail);

  const [isCollapsed, setCollapsed] = useState(true);
  const [isFeedbackModalOpen, setFeedbackModalOpen] = useState(false);

  let copyMenuItems = menuItemsData();
  let menuItems = menuItemsData();


  function removeNoAccessItem(nodes, deleteNodes) {
    let roles = userDetails.data.permissions;
    for (let i = nodes.length - 1; i >= 0; i--) {
   
      // check if has children
      if (nodes[i].children && nodes[i].isChildrenShow) {
        removeNoAccessItem(nodes[i].children, deleteNodes[i].children);
      }
      
      if (nodes[i].children && !nodes[i].isChildrenShow && nodes[i].permissionCheck) {
          if (!roles.includes(nodes[i].name)) {
            deleteNodes.splice(i, 1);
          }
      }

      if (!nodes[i].children && nodes[i].permissionCheck) {
        if (!roles.includes(nodes[i].name)) {
          deleteNodes.splice(i, 1);
        }
      }
    }
  }

  if (success) {
    removeNoAccessItem(copyMenuItems, menuItems);
  }


  return (
    <>
      <Modal
        title="Contact Us"
        isModalOpen={isFeedbackModalOpen}
        closeModal={() => setFeedbackModalOpen(false)}
        content={
          <FeedbackForm
          />
        }
      />

      {success ? (
        isCollapsed ? (
          <div className={menuStyle.sidemenuContainer}>
            {userDetails ? (
              <div className={menuStyle.organizationInfo}>
                <div className={menuStyle.organizationLogo}>
                  {userDetails.data.orgInfo.logo ? (
                    <img
                      src={userDetails.data.orgInfo.logo}
                      alt="logo"
                      className={menuStyle.orgLogo}
                     
                    />
                  ) : (
                    <BsFillHouseFill />
                  )}
                </div>
                <p className={menuStyle.organizationName}>
                  {userDetails.data.orgInfo.name}
                </p>
              </div>
            ) : (
              <></>
            )}

            <div className={menuStyle.sidemenu}>
              {menuItems.map((item) => (
                <SideMenuItem key={item.id} item={item} />
              ))}
            </div>

            <div className={menuStyle.helpWrapper}>
              <div
                className={menuStyle.helpCon}
                onClick={() => setFeedbackModalOpen(true)}
              >
                <MdHelp className={menuStyle.helpLogo} />
                <p style={{ marginLeft: "4px" }}>Need Help</p>
              </div>

              <div
                className={menuStyle.collapsedCon}
                onClick={() => setCollapsed(false)}
              >
                <BsBoxArrowInLeft />
                <p>Collapse Side Menu</p>
              </div>
            </div>
          </div>
        ) : (
          <div className={menuStyle.sidemenuContainerColla}>
            {userDetails ? (
              <div className={menuStyle.orgLogoCon}>
                {userDetails.data.orgInfo.logo ? (
                  <img
                    src={userDetails.data.orgInfo.logo}
                    alt="logo"
                    className={menuStyle.orgLogo}
                  />
                ) : (
                  <BsFillHouseFill />
                )}
              </div>
            ) : (
              <></>
            )}

            <div className={menuStyle.sidemenu}>
              {menuItems.map((item) => (
                <CollapsedMenu key={item.id} item={item} />
              ))}
            </div>

            <div className={menuStyle.helpWrapper}>
              <div
                className={menuStyle.helpCon}
                onClick={() => setFeedbackModalOpen(true)}
              >
                <MdHelp className={menuStyle.helpLogo} />
              </div>

              <div
                className={menuStyle.collapsedCon}
                onClick={() => setCollapsed(true)}
              >
                <BsBoxArrowInRight />
              </div>
            </div>
          </div>
        )
      ) : (
        <div style={{ margin: "20px" }}>
          Get menu faild, please refresh the page and try again later.
        </div>
      )}
    </>
  );
}

export default SideMenu;
