import axios from "axios";


const SERVER_URL = process.env.REACT_APP_SERVER_URL;


 export const getProductStockLevelByLocationRequest = async (pid, locationId, variantCode, config) => {

   try {
     const response = await axios.get(SERVER_URL + `/product/stock_level_by_location/${pid}?locationId=${locationId}&variantCode=${variantCode}`, config);
     return response.data;
   } catch (error) {
    throw error;
   }
 
 };

 export const createNewProductApiRequest = async (values, config) => {

  try {
    const response = await axios.post(SERVER_URL + `/product/create`, values, config);
    return response;
  } catch (error) {
    throw error;
  }

};


 export const findUnitRequest = async (unitName, config) => {
  let encodedVariantUnit = encodeURIComponent(unitName);
  try {
    const response = await axios.get(SERVER_URL + `/product/unit/find/${encodedVariantUnit}`, config)
    return response;
  } catch (error) {
    throw error;
  }
};


 export const checkProductByVariantSkuRequest = async (variantSku, config) => {
  let encodedVariantSku = encodeURIComponent(variantSku);

  try {
    const response = await axios.get(SERVER_URL + `/product/check/variant-sku/${encodedVariantSku}`, config)
    return response;
  } catch (error) {
    throw error;
  }
};


export const searchProductByVariantSkuRequest = async (variantSku, config) => {
  let encodedVariantSku = encodeURIComponent(variantSku);
  try {
    const response = await axios.get(SERVER_URL + `/product/search/variant-sku/${encodedVariantSku}`, config)
    return response;
  } catch (error) {
    throw error;
  }
};

export const stockAdjustOptionsRequest = async (config) => {
  try {
    const response = await axios.get(SERVER_URL + `/product/stock_adjustment/options`, config)
    return response;
  } catch (error) {
    throw error;
  }
};

export const unBatchedDataRequest = async (config) => {
  try {
    const response = await axios.get(SERVER_URL + `/product/batch-serial-numbers/import/data`, config)
    return response;
  } catch (error) {
    throw error;
  }
};

export const stockLogsLocationsRequest = async (famid, config) => {
  try {
    const response = await axios.get(SERVER_URL + `/product/stock_logs/locations/${famid}`, config)
    return response;
  } catch (error) {
    throw error;
  }
};

export const stockLogsRequest = async (famid, locationId, pageNum, pageSize, config) => {
  try {
    const response = await axios.get(SERVER_URL + `/product/stock_logs/${famid}?locationId=${locationId}&pageNum=${pageNum}&pageSize=${pageSize}`, config)
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateStockAdjustmentRequest = async (adjustId, values, config) => {
  try {
    const response = await axios.post(SERVER_URL + `/product/bulk_stock_adjustment/edit/${adjustId}`, values, config)
    return response;
  } catch (error) {
    throw error;
  }
};


export const updateStockTransferRequest = async (transferId, values, config) => {
  try {
    const response = await axios.post(SERVER_URL + `/product/bulk_stock_transfer/edit/${transferId}`, values, config)
    return response;
  } catch (error) {
    throw error;
  }
};





 


