import React from "react";
import { NavLink } from "react-router-dom";
import commonStyle from "../../style/commonStyle.module.css";

export default function XeroIntegrationTab({ tabName }) {
  let className1;
  let className2;
  let className3;


  if (tabName === "config") {
    className1 = commonStyle.activeItem;
  } else {
    className1 = commonStyle.tabItem;
  }
  if (tabName === "data") {
    className2 = commonStyle.activeItem;
  } else {
    className2 = commonStyle.tabItem;
  }
  if (tabName === "import") {
    className3 = commonStyle.activeItem;
  } else {
    className3 = commonStyle.tabItem;
  }


  return (
    <div className={commonStyle.tabsCon}>
      <NavLink to="/integration/xero" exact="true" className={className1}>
        Configuration
      </NavLink>
      <NavLink
        to="/integration/xero/data"
        exact="true"
        className={className2}
      >
        Data
      </NavLink> 
      <NavLink
        to="/integration/xero/import"
        exact="true"
        className={className3}
      >
        Import
      </NavLink> 
    </div>
  );
}
