const K_CURRENCIES = [
    { id: 1, value: "AUD", label: "AUD"},
    { id: 2, value: "CAD", label: "CAD"},
    { id: 3, value: "EUR", label: "EUR"},
    { id: 4, value: "GBP", label: "GBP"},
    { id: 5, value: "NZD", label: "NZD"},
    { id: 6, value: "INR", label: "INR"},
    { id: 8, value: "USD", label: "USD"},
    { id: 9, value: "JPY", label: "JPY"},
    { id: 10, value: "CHF", label: "CHF"},
    { id: 11, value: "CNY", label: "CNY"},
    { id: 12, value: "HKD", label: "HKD"},
    { id: 13, value: "KRW", label: "KRW"},
    { id: 14, value: "BRL", label: "BRL"},
    { id: 15, value: "ZAR", label: "ZAR"},
    { id: 16, value: "KRW", label: "KRW"},
    { id: 17, value: "RUB", label: "RUB"},
    { id: 18, value: "SGD", label: "SGD"},
    { id: 19, value: "SEK", label: "SEK"},
    { id: 20, value: "NOK", label: "NOK"},
    { id: 21, value: "TRY", label: "TRY"},
    { id: 22, value: "NOK", label: "NOK"},
];

export {
    K_CURRENCIES
  };