import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  Loader,
  TextErrorMessage,
} from "../../components/viewComponents";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function XeroLoginPassCode({codeExchangeData}) {

  ////////////////////////////////// //////////////////////////////////
  ////////////////////////////////// //////////////////////////////////

  const [getCodeError, setGetCodeError] = useState("");
  const [exchangeCodeStatus, setExchangeCodeStatus] = useState({
    loading: false,
    success: false,
    error: ""
  })
  ////////////////////////////////// //////////////////////////////////
  ////////////////////////////////// //////////////////////////////////

    useEffect(() => {
      /////////////////////////////////////////
      const query = new URLSearchParams(window.location.search);
      let code = query.get("code");
      let error = query.get("error");
      if (error) {
        setGetCodeError(true);
      }
  
      if (code) {
        let authValues = {
          authCode: code,
        };
  
        try {
          setExchangeCodeStatus((prevStatus) => {
            return { ...prevStatus, loading: true };
          });
          axios
            .post(SERVER_URL + `/login/xero/pass_code`, authValues)
            .then(async (response) => {
            
              if (response.data.success) {
                setExchangeCodeStatus((prevStatus) => {
                  return { ...prevStatus, loading: false, success: true };
                });
                codeExchangeData(response.data);
              } else if (response.data.error === 20) {
                setExchangeCodeStatus((prevStatus) => {
                  return { ...prevStatus, loading: false, error: "Xero account has not registered, please register with Xero firstly." };
                });

              } else {
                setExchangeCodeStatus((prevStatus) => {
                  return { ...prevStatus, loading: false, error: "Log in with Xero failed, please try again later." };
                });
              }
            })
            .catch((err) => {
              setExchangeCodeStatus((prevStatus) => {
                return { ...prevStatus, loading: false, error: "Log in with Xero failed, please try again later." };
              });
              
            });
        } catch (error) {
          setExchangeCodeStatus((prevStatus) => {
            return { ...prevStatus, loading: false, error: "Log in with Xero failed, please try again later." };
          });
        }
      }
    }, [SERVER_URL]);
  
    ///////////////////////////////////////////////////////////////////////////////////


  return (
    <div>
      {getCodeError ? <TextErrorMessage mess="Log in with Xero failed, please try again later" /> : <></>}
      {exchangeCodeStatus.error ? <TextErrorMessage mess={exchangeCodeStatus.error} /> : <></>}
      {exchangeCodeStatus.loading ? <Loader mess="Connecting Xero..." /> : <></>}
    </div>
  );
}
