import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { locationListRequest } from "../../../actions/orgSettingsActions";
import { bulkStockTransferRequest } from "../../../actions/productActions";
import {
  getProductStockLevelByLocationRequest,
  stockAdjustOptionsRequest,
} from "../../../api/productServices";
import Breadcrumbs from "../../../components/Breadcrumbs";
import ProductPicker from "../../../components/ProductPicker";
import {
  BarcodeScanButton,
  ScreenTitle,
  SimpleBlueButton,
  XDeleteButton,
} from "../../../components/editComponents";
import {
  CreateableSelectField,
  NoBorderInput,
  StyledInput,
  StyledSelect,
} from "../../../components/inputFields";
import {
  BatchNumberSelect,
  SerialNumberSelect,
} from "../../../components/trackingNumbersComponents";
import {
  ContentModal,
  ErrorMessage,
  FadeErrorMessage,
  Loader,
  Modal,
  SimpleDialog,
  SubTitle,
  TextErrorMessage,
  VariantSkuTag,
  VariantTag,
} from "../../../components/viewComponents";
import { convtPrice } from "../../../functions/functions";
import { validatePrice, validateQuantity } from "../../../functions/validate";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";
import inventoryStyle from "../inventoryStyle.module.css";
import ProductScanForm from "../ProductScanForm";
import ProjectForm from "../../settings/ProjectForm";

export default function NewStockBulkTransferScreen() {
  useDocumentTitle("Stock Transfer");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.userLogin);

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );
  const {
    loading: saveLoading,
    error: saveError,
    success: saveSuccess,
  } = useSelector((state) => state.bulkStockTransfer);

  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [options, setOptions] = useState();

  const [formErrors, setFormErrors] = useState({});

  const [outLocation, setOutLocation] = useState({});
  const [inLocation, setInLocation] = useState("");

  const [adjustProject, setAdjustProject] = useState("");

  const [adjustNotes, setAdjustNotes] = useState("");
  const [adjustProducts, setAdjustProducts] = useState([
    {
      famid: "",
      productName: "",
      variantCode: "",
      variantSku: "",
      variant: "",
      unitName: "",
      stockLevel: "",
      latestCost: "",
      adjustQty: "",
      adjustCost: "",
      serialized: 0,
      batchTracked: 0,
      serialNumbers: [],
      batchNumbers: [],
      consumeSerialNumbers: [],
      consumeBatchNumbers: [],
    },
  ]);

  const [newProjectModal, setNewProjectModal] = useState(false);

  const [scanModal, setScanModal] = useState(false);

  useEffect(() => {
    const getAdjustOptions = async () => {
      try {
        setRequestStatus((preState) => ({
          ...preState,
          loading: true,
        }));
        const responseData = await stockAdjustOptionsRequest(config);
        if (responseData.data.success) {
          setOptions(responseData.data.data);
          let locations = responseData.data.data.locations;
          if (userDetailsSuccess) {
            let find_user_location = locations.find(
              (x) => x.id === userDetails.data.locationId
            );
            if (find_user_location) {
              setOutLocation(find_user_location);
            }
          }
          setRequestStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
            error: "",
          }));
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };
    getAdjustOptions();
  }, []);

  //define the error message

  const openScanModal = () => {
    // check if has location selected
    if (!outLocation) {
      setFormErrors({
        adjustProducts: "Please select a location before scan product.",
      });
      return;
    }

    setFormErrors({ orderItemsError: "" });
    setScanModal(true);
  };

  const handleChange = (e) => {
    const { type, name, value, index } = e.target;
    if (type === "quantity") {
      const validated = validateQuantity(value);
      if (!validated) return;
    }

    if (type === "price") {
      const validated = validatePrice(value);
      if (!validated) return;
    }

    const variantsCopy = [...adjustProducts];
    variantsCopy[index][name] = value;
    setAdjustProducts(variantsCopy);
  };

  const handleLocationChange = (location) => {
    setOutLocation(location);
    updateStockLevel(location.id);
  };

  const updateStockLevel = async (locationId) => {
    let copy = [...adjustProducts];
    for (const pro of copy) {
      try {
        const responseData = await getProductStockLevelByLocationRequest(
          pro.famid,
          locationId,
          pro.variantCode,
          config
        );

        if (responseData.success) {
          pro.stockLevel = responseData.data;
        }
      } catch (error) {}
    }
    setAdjustProducts(copy);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////

  const deleteVariantItem = (index) => {
    let variantsCopy = [...adjustProducts];
    variantsCopy.splice(index, 1);

    setAdjustProducts(variantsCopy);
  };

  ////////////////////////////Submit and check values/////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////

  const checkValidation = () => {
    if (!outLocation) {
      setFormErrors({ outlocation: "Please select a transfer out location." });
      return;
    }
    if (!inLocation) {
      setFormErrors({ inlocation: "Please select a transfer in location." });
      return;
    }
    if (inLocation.id === outLocation.id) {
      setFormErrors({
        adjustProducts:
          "The transfer in location can not be the same with transfer out location.",
      });
      return;
    }

    if (!adjustProducts.some((item) => item.productName)) {
      setFormErrors({
        adjustProducts: "Please add at least one product to this order.",
      });
      return;
    }

    if (adjustProducts.some((item) => Number(item.adjustQty) <= 0)) {
      setFormErrors({ adjustProducts: "Transfer quantity is required." });
      return;
    }

    if (
      adjustProducts.some(
        (item) => Number(item.adjustQty) > Number(item.stockLevel)
      )
    ) {
      setFormErrors({
        adjustProducts: "Transfer quantity is greater than stock on hand.",
      });
      return;
    }

    // add stock level check if has serial numbers and batch tracking numbers
    if (
      adjustProducts.some(
        (item) =>
          item.serialized &&
          Number(item.consumeSerialNumbers.length) !== Number(item.adjustQty)
      )
    ) {
      setFormErrors({
        adjustProducts:
          "The quantity of selected serial numbers is not equal to the transfer quantity.",
      });
      return;
    }
    if (
      adjustProducts.some(
        (item) =>
          item.batchTracked &&
          Number(
            item.consumeBatchNumbers.reduce((accumulator, currentValue) => {
              return accumulator + Number(currentValue.consumeQuantity);
            }, 0)
          ) !== Number(item.adjustQty)
      )
    ) {
      setFormErrors({
        adjustProducts:
          "The quantity of selected batches is not equal to the transfer quantity.",
      });
      return;
    }

    // reset error message
    setFormErrors({
      adjustProducts: "",
    });

    saveStockAdjustment(false);
  };
  ////////////////////////////////////////////////////////////////////////////////////////////
  const getProductFromPicker = (result) => {
    if (result.productType !== "package") {
      addNewItem(result.data);
    }
  };

  const addNewItem = (proDetails) => {
    let adjustProductsCopy = [...adjustProducts];
    // remove empty row in order items
    adjustProductsCopy = adjustProductsCopy.filter((x) => x.productName);
    // check exist products, error
    let exists = adjustProductsCopy.some(
      (item) =>
        item.famid === proDetails.familyId &&
        item.variantCode === proDetails.variantCode
    );
    if (exists) {
      let index = adjustProductsCopy.findIndex(
        (item) =>
          item.famid === proDetails.familyId &&
          item.variantCode === proDetails.variantCode
      );
      adjustProductsCopy[index].adjustQty =
        Number(adjustProductsCopy[index].adjustQty) + 1;
    } else {
      adjustProductsCopy.push({
        famid: proDetails.familyId,
        productName: proDetails.name,
        variant: proDetails.variant ? proDetails.variant : "",
        variantCode: proDetails.variantCode,
        variantSku: proDetails.variantSku,
        unitName: proDetails.unit,
        stockLevel: proDetails.stockLevel,
        latestCost: proDetails.latestCost,
        adjustQty: "",
        adjustCost: proDetails.latestCost,
        serialized: proDetails.serialized,
        batchTracked: proDetails.batchTracked,
        serialNumbers: [],
        batchNumbers: [],
        consumeSerialNumbers: [],
        consumeBatchNumbers: [],
      });
    }

    setAdjustProducts(adjustProductsCopy);
  };
  const getScanedProducts = (products) => {
    let adjustProductsCopy = [...adjustProducts];
    // remove empty row in order items
    adjustProductsCopy = adjustProductsCopy.filter((x) => x.productName);

    for (let i = 0; i < products.length; i++) {
      let proDetails = products[i];
      // check exist products, error
      let exists = adjustProductsCopy.some(
        (item) =>
          item.famid === proDetails.familyId &&
          item.variantCode === proDetails.variantCode
      );
      if (exists) {
        let index = adjustProductsCopy.findIndex(
          (item) =>
            item.famid === proDetails.familyId &&
            item.variantCode === proDetails.variantCode
        );
        adjustProductsCopy[index].adjustQty =
          Number(adjustProductsCopy[index].adjustQty) + 1;
      } else {
        adjustProductsCopy.push({
          famid: proDetails.familyId,
          productName: proDetails.productName,
          variant: proDetails.variant ? proDetails.variant : "",
          variantCode: proDetails.variantCode,
          variantSku: proDetails.variantSku,
          unitName: proDetails.unitName,
          stockLevel: proDetails.stockOnHand,
          latestCost: proDetails.latestCost,
          adjustQty: "",
          adjustCost: proDetails.latestCost,
          serialized: proDetails.serialized,
          batchTracked: proDetails.batchTracked,
          serialNumbers: [],
          batchNumbers: [],
          consumeSerialNumbers: [],
          consumeBatchNumbers: [],
        });
      }
    }
    setAdjustProducts(adjustProductsCopy);
    setScanModal(false);
  };

  /////////////////////////////////Batch and Serial tracking//////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////
  const getSerialNumbersStockReduce = (serialNumbers, index) => {
    let adjustProductsCopy = [...adjustProducts];
    adjustProductsCopy[index].consumeSerialNumbers = serialNumbers;
    setAdjustProducts(adjustProductsCopy);
  };

  const getBatchNumbersStockReduce = (batchNumbers, index) => {
    let adjustProductsCopy = [...adjustProducts];
    adjustProductsCopy[index].consumeBatchNumbers = batchNumbers;
    setAdjustProducts(adjustProductsCopy);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////
  // mange dialog content, new and edit
  const [createDialog, setCreateDialog] = useState(false);

  const closeDialogWithSuccess = () => {
    navigate("/inventory/bulk-stock-transfer", {
      replace: true,
      from: location,
    });
  };

  const saveStockAdjustment = () => {
    setCreateDialog(true);

    let adjustProductsCopy = [...adjustProducts];
    adjustProductsCopy = adjustProductsCopy.filter(
      (x) => Number(x.adjustQty) > 0
    );

    let productsSubmitedValues = {};
    let adjustProductItems = [];
    adjustProductsCopy.forEach((elem) => {
      adjustProductItems.push({
        famid: Number(elem.famid),
        productName: elem.productName,
        variant: elem.variant,
        variantCode: Number(elem.variantCode),
        variantSku: elem.variantSku,
        adjustQty: Number(elem.adjustQty),
        adjustCost: Number(elem.adjustCost),
        adjustTotalCost: convtPrice(
          Number(elem.adjustQty) * Number(elem.adjustCost)
        ),
        serialized: elem.serialized,
        batchTracked: elem.batchTracked,
        consumeSerialNumbers: elem.consumeSerialNumbers,
        consumeBatchNumbers: elem.consumeBatchNumbers,
      });
    });

    productsSubmitedValues.adjustProducts = adjustProductItems;
    productsSubmitedValues.outlocationId = outLocation.id;
    productsSubmitedValues.inlocationId = inLocation.id;
    productsSubmitedValues.projectId = adjustProject ? adjustProject.id : "";
    productsSubmitedValues.notes = adjustNotes;

    dispatch(bulkStockTransferRequest(productsSubmitedValues));
  };

  const addNewProjectSuccess = (newPro) => {
    let optionCopy = { ...options };
    let projectCopy = optionCopy.projects;
    projectCopy.unshift(newPro);
    setOptions(optionCopy);
    setAdjustProject(newPro);
    setNewProjectModal(false);
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Modal
        title="New Project"
        isModalOpen={newProjectModal}
        closeModal={() => setNewProjectModal(false)}
        content={
          <ProjectForm
            projectId=""
            type="new"
            btnName="Add New Project"
            values={{ name: "", code: "" }}
            onSuccess={addNewProjectSuccess}
          />
        }
      />

      <ContentModal
        title="Scan Barcode"
        isModalOpen={scanModal}
        closeOnOverlayClick={false}
        closeModal={() => setScanModal(false)}
        content={
          <ProductScanForm
            locationId={outLocation ? outLocation.id : ""}
            completeScan={getScanedProducts}
          />
        }
      />

      <SimpleDialog
        title="Stock Transfer"
        successMessage="Stock transfered successfully."
        isDialogOpen={createDialog}
        closeDialog={() => setCreateDialog(false)}
        loading={saveLoading}
        loadingMessage="Processing request..."
        success={saveSuccess}
        error={saveError}
        btnValue="Confirm"
        confirmAction={closeDialogWithSuccess}
      />

      <Breadcrumbs screenName="New Stock Transfer" />
      <ScreenTitle title="New Stock Transfer" />

      <div className={commonStyle.pageContentContainer}>
        {requestStatus.loading ? (
          <Loader mess="Requesting locations..." />
        ) : requestStatus.success ? (
          <div className="w-full shadow-cardShadow p-4">
             <SubTitle name="Order Basic Information" />
            <div className="grid grid-cols-2 gap-x-6">
              <StyledSelect
                label="Transfer Out Location*"
                selectOptions={options.locations}
                clearable={false}
                value={outLocation}
                placeHolder="Select location"
                onChange={(location) => handleLocationChange(location)}
                error={formErrors.outlocation}
              />
              <StyledSelect
                label="Transfer In Location*"
                selectOptions={options.locations}
                clearable={false}
                value={inLocation}
                placeHolder="Select location"
                onChange={(location) => setInLocation(location)}
                error={formErrors.inlocation}
              />
            </div>

            {options.projects && options.projects.length > 0 ? (
              <div className="grid grid-cols-2 gap-x-6">
                <CreateableSelectField
                  label="Project"
                  extractKey="id"
                  options={options.projects}
                  name={"year"}
                  value={adjustProject}
                  onChange={(project) => setAdjustProject(project)}
                  placeholder="Select project"
                  autoFocus={false}
                  readOnly={false}
                  clearable={true}
                  noResultText="No options"
                  addBtnName="Add a new project"
                  openAddNewModal={() => setNewProjectModal(true)}
                  error={formErrors.project}
                />
              </div>
            ) : (
              <></>
            )}

            <div className="grid grid-cols-2 gap-x-6">
              <StyledInput
                label="Notes"
                type="text"
                name="notes"
                value={adjustNotes}
                onChange={(notes) => setAdjustNotes(notes)}
              />
            </div>
          </div>
        ) : requestStatus.error ? (
          <ErrorMessage mess={requestStatus.error} />
        ) : (
          <></>
        )}
        <div className="mt-8 shadow-cardShadow p-4">
          <SubTitle name="Transfer Products" />

          <div
            style={{
              marginTop: "20px",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "50%" }}>
              <ProductPicker
                onSelect={getProductFromPicker}
                requiredType="productAndService"
                locationRequired={true}
                locationId={outLocation ? outLocation.id : ""}
              />
            </div>
            <BarcodeScanButton name="Scan Barcode" action={openScanModal} />
          </div>

          <div className={commonStyle.longDataTable}>
            {formErrors.repeatProduct ? (
              <div style={{ marginTop: "20px" }}>
                <FadeErrorMessage mess={formErrors.repeatProduct} />
              </div>
            ) : (
              <></>
            )}
            {formErrors.adjustProducts ? (
              <div style={{ marginTop: "20px" }}>
                <TextErrorMessage mess={formErrors.adjustProducts} />
              </div>
            ) : (
              <></>
            )}

            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Product Name</th>
                  <th>Variant</th>
                  <th>Unit</th>
                  <th>Stock on Hand</th>
                  <th>Transfer Quantity</th>
                  <th>Transfer Cost</th>
                  <th></th>
                  <th
                    style={{
                      margin: "auto",
                      borderLeft: "1px solid var(--border-color)",
                    }}
                  ></th>
                </tr>
              </thead>
              <tbody>
                {adjustProducts.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {item.variantSku && (
                        <VariantSkuTag name={item.variantSku} />
                      )}
                    </td>
                    <td>{item.productName}</td>
                    <td>
                      {item.variant ? (
                        <VariantTag name={item.variant} />
                      ) : (
                        <></>
                      )}
                    </td>
                    <td>{item.unitName}</td>
                    <td>{item.stockLevel}</td>
                    <td style={{ width: "15%" }}>
                      <div className={inventoryStyle.adjustQtyCon}>
                        <NoBorderInput
                          type="text"
                          name="adjustQty"
                          value={item.adjustQty}
                          onChange={(adjustQty) =>
                            handleChange({
                              target: {
                                type: "quantity",
                                value: adjustQty,
                                name: "adjustQty",
                                index: index,
                              },
                            })
                          }
                        />
                      </div>
                    </td>
                    <td style={{ width: "10%" }}>
                      <NoBorderInput
                        type="text"
                        name="adjustCost"
                        value={item.adjustCost}
                        onChange={(adjustCost) =>
                          handleChange({
                            target: {
                              type: "price",
                              value: adjustCost,
                              name: "adjustCost",
                              index: index,
                            },
                          })
                        }
                      />
                    </td>
                    <td>
                      {item.adjustQty && item.serialized === 1 ? (
                        <SerialNumberSelect
                          btnName="Select Serial Number"
                          famid={item.famid}
                          variantCode={item.variantCode}
                          locationId={outLocation.id}
                          prodName={item.productName}
                          requiredQty={item.adjustQty}
                          index={index}
                          onSave={getSerialNumbersStockReduce}
                          initialNumbers={
                            item.consumeSerialNumbers
                              ? item.consumeSerialNumbers
                              : []
                          }
                        />
                      ) : (
                        <></>
                      )}

                      {item.adjustQty && item.batchTracked === 1 ? (
                        <BatchNumberSelect
                          btnName="Select Batch"
                          prodName={item.productName}
                          famid={item.famid}
                          variantCode={item.variantCode}
                          locationId={outLocation.id}
                          requiredQty={item.adjustQty}
                          index={index}
                          onSave={getBatchNumbersStockReduce}
                          initialNumbers={
                            item.consumeBatchNumbers
                              ? item.consumeBatchNumbers
                              : []
                          }
                        />
                      ) : (
                        <></>
                      )}
                    </td>
                    <td
                      style={{
                        borderLeft: "1px solid var(--border-color)",
                      }}
                    >
                      <XDeleteButton action={() => deleteVariantItem(index)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="w-full mt-4">
              <SimpleBlueButton action={checkValidation} name="Save" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
