import React from "react";
import LeaderBoard from "./LeaderBoard";
import StockAlert from "./StockAlert";

export default function StockBoard() {


  return (
    <div style={{ display: "flex" }}>
      <StockAlert />
      <LeaderBoard />
    </div>
  );
}
