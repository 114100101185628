const K_COUNTRIES = [
    {id: 2, value: "Australia", label: "Australia"},
    {id: 1, value: "New Zealand", label: "New Zealand"},
    {id: 3, value: "United Kingdom", label: "United Kingdom"},
    {id: 8, value: "South Africa", label: "South Africa"},
    {id: 18, value: "Singapore", label: "Singapore"},
    {id: 4, value: "United States", label: "United States"},
    {id: 5, value: "Canada", label: "Canada"},
    {id: 6, value: "Mexico", label: "Mexico"},
    {id: 7, value: "Brazil", label: "Brazil"},
    {id: 9, value: "Spain", label: "Spain"},
    {id: 10, value: "France", label: "France"},
    {id: 11, value: "Germany", label: "Germany"},
    {id: 12, value: "Italy", label: "Italy"},
    {id: 13, value: "China", label: "China"},
    {id: 14, value: "Argentina", label: "Argentina"},
    {id: 15, value: "Japan", label: "Japan"},
    {id: 16, value: "Argentina", label: "Argentina"},
    {id: 17, value: "Other", label: "Other"},
  ];

  export {
    K_COUNTRIES
  };