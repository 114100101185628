import axios from "axios";
import { authMiddleware } from "./authMiddleware";
import {
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  USER_DETAIL_REQUEST,
  USER_DETAIL_SUCCESS,
  USER_DETAIL_FAIL,
  ACTIVATE_ACCOUNT_REQUEST,
  ACTIVATE_ACCOUNT_SUCCESS,
  ACTIVATE_ACCOUNT_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
} from "../constants/authConstants";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const activateAccountRequest = (orgid, activateCode) => async (dispatch) => {

  const headers = {
    "Content-type": "application/json",
  };
  try {
    dispatch({ type: ACTIVATE_ACCOUNT_REQUEST });
    axios
      .get(SERVER_URL + `/organization/activate?orgid=${orgid}&code=${activateCode}`, headers)
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: ACTIVATE_ACCOUNT_SUCCESS });
        } else {
            throw Object.assign(
              new Error("Activate account failed"),
              { code: 404 }
           );
          }
      })
      .catch((err) => {
        dispatch({
          type: ACTIVATE_ACCOUNT_FAIL,
          payload: err,
        });
      });
  } catch (e) {
    dispatch({
      type: ACTIVATE_ACCOUNT_FAIL,
      payload: "Connection error, please try again later.",
    });
  }
};


export const userLoginRequest = (values) => async (dispatch) => {
 

  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };
  try {
    dispatch({ type: LOGIN_REQUEST });

    axios
      .post(SERVER_URL + `/user/login`, values, config)
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: LOGIN_SUCCESS, payload: response.data });
          localStorage.setItem("userInfo", JSON.stringify(response.data));
        } else if (response.data.error === 10){
          throw Object.assign(
            new Error("Your email and password do not match."),
            { code: 405 }
         );
        } else if (response.data.error === 5){
          throw Object.assign(
            new Error("Your email is not activated."),
            { code: 405 }
         );
        }else{
          throw Object.assign(
            new Error("Your email and password do not match."),
            { code: 405 }
         );
        }
    
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const resetPasswordRequest = (email) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };
  try {
    dispatch({ type: RESET_PASSWORD_REQUEST });

    axios
      .post(SERVER_URL + `/user/send_resetpwd_email`, email, config)
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: RESET_PASSWORD_SUCCESS });
        }else if (response.data.error === 10) {
          throw Object.assign(
            new Error("Your email is not exist"),
            { code: 405 }
         );
        }else {
          throw Object.assign(
            new Error("Sent reset password email failed"),
            { code: 405 }
         );
        }
    
      })
      .catch((err) => {
        dispatch({
          type: RESET_PASSWORD_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

//get users permissions
export const userDetailsRequest = () => async (dispatch, getState) => {

  const { userLogin: { userInfo } } = getState()

  const config = {
    headers: {
      Authorization:
      `Bearer ${userInfo.data.token}`,
    },
  };

  try {
    dispatch({ type: USER_DETAIL_REQUEST });
    axios
      .get(SERVER_URL + `/user/${userInfo.data.user_id}`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: USER_DETAIL_SUCCESS, payload: response.data });
        } else{
          throw Object.assign(
            new Error("request user details failed."),
            { code: 408 }
         );
        }
      })
      .catch((err) => {
        dispatch({
          type: USER_DETAIL_FAIL,
          payload: err,
        });
      }); 
  } catch (error) {
    dispatch({
      type: USER_DETAIL_FAIL,
      payload: "Connection error, please try again later",
    });
  }

};

// when click logout delete local storage data "userinfo"
export const userlogout = () => async (dispatch) => {
  localStorage.removeItem("userInfo");
  dispatch({ type: LOGOUT_REQUEST });
};
