import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import loginStyle from "./loginStyle.module.css";
import {
  LoadingButton,
  SimpleBlueButton,
  SingleRatioButton,
  TextLink,
  XeroButton,
} from "../../components/editComponents";
import {
  StyledPasswordInput,
  StyledEmailInput,
} from "../../components/inputFields";
import { userLoginRequest } from "../../actions/authAction";
import { LOGIN_SUCCESS } from "../../constants/authConstants";
import {
  ErrorMessage,
  TextErrorMessage,
} from "../../components/viewComponents";
import useDocumentTitle from "../../useDocumentTitle";
import useMetaTag from "../../useMetaTag";
import LoginAdver from "./LoginAdver";
import ForgotPassword from "./ForgotPassword";
import XeroLoginPassCode from "./XeroLoginPassCode";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function LoginScreen() {
  useDocumentTitle("Log in - StockUnify Inventory Management Software");
  useMetaTag(
    "Advanced cloud-based inventory management software that empowers businesses to streamline their operations and achieve optimal inventory control. "
  );
 
  const dispatch = useDispatch();

  const { error, userInfo, loading } = useSelector((state) => state.userLogin);

  // when logged push to orginal page
  const navigate = useNavigate();
  const { state } = useLocation();

  // define input value state
  const initialLoginValues = {
    userEmail: "",
    password: "",
    remember: false,
  };

  const [loginValues, setLoginValues] = useState(initialLoginValues);
  const [resetPassword, setResetPassword] = useState(false);
  //define the error message

  const [formErrors, setFormErrors] = useState({});

  ////////////////////////////////// //////////////////////////////////
  ////////////////////////////////// //////////////////////////////////

  // get input value
  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginValues({ ...loginValues, [name]: value });
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    const errors = {};
    let re = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (re.test(value)) {
      // this is a valid email address
      setFormErrors({});
      setLoginValues({ ...loginValues, [name]: value });
    } else {
      // invalid email, maybe show an error to the user.
      setLoginValues({ ...loginValues, [name]: value });
      errors.userEmail = "Please enter a valid email address";
      setFormErrors(errors);
    }
  };

  // login submit
  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.userEmail) {
      errors.userEmail = "Email is required!";
    } else if (!regex.test(values.userEmail)) {
      errors.userEmail = "Email is invalid!";
    }

    if (!values.password) {
      errors.password = "Password is required!";
    }

    return errors;
  };
  // form submit
  const checkValidation = () => {
    const hasErrors = validate(loginValues);

    if (Object.keys(hasErrors).length === 0) {
      return true;
    } else {
      setFormErrors(hasErrors);
      return false;
    }
  };

  // login submit
  const LoginRequest = () => {
    const res = checkValidation();
    if (res) {
      dispatch(userLoginRequest(loginValues));
    }
  };

  useEffect(() => {
    /////////////////////////////////////////
    const query = new URLSearchParams(window.location.search);
    let xeroLogin = query.get("xeroLogin");

    if (xeroLogin) {
      signinWithXeroRequest();
    }

    // if user is logged in , load to main page
    if (userInfo) {
      if (state) {
        navigate(state.from.pathname);
        window.location.reload(true);
      } else {
        navigate("/");
        window.location.reload(true);
      }
    }
  }, [userInfo, navigate, state]);

  const forgetPassword = () => {
    setFormErrors({});
    setResetPassword(true);
  };

  ///////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////// //////////////////////////////////
  ////////////////////Sign in with Xero// //////////////////////////////////

  const [passCode, setPassCode] = useState(true);
  const [xeroSignStatus, setXeroSigninStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const codeExchangeSuccessAction = (values) => {
    setPassCode(false);
    // save userInfo to local Storage

    if (!values.data.org_inactive) {
      dispatch({ type: LOGIN_SUCCESS, payload: values });
      localStorage.setItem("userInfo", JSON.stringify(values));

      navigate("/", {
        replace: true,
      });
    }

    if (values.data.org_inactive) {
      navigate("/register/xero", {
        replace: true,
        state: values.data,
      });
    }
  };

  const signinWithXeroRequest = () => {
    try {
      setXeroSigninStatus((prevStatus) => {
        return { ...prevStatus, loading: true };
      });

      axios
        .get(SERVER_URL + `/login/xero`)
        .then(async (response) => {
          if (response.data.success) {
            setXeroSigninStatus((prevStatus) => {
              return { ...prevStatus, loading: false, success: true };
            });
            window.location.href = response.data.data.xero_url;
          } else {
            setXeroSigninStatus((prevStatus) => {
              return {
                ...prevStatus,
                loading: false,
                error: "Request Xero OAuth2.0 failed, please try again later.",
              };
            });
          }
        })
        .catch((err) => {
          setXeroSigninStatus((prevStatus) => {
            return {
              ...prevStatus,
              loading: false,
              error: "Connection error, please try again later.",
            };
          });
        });
    } catch (error) {
      setXeroSigninStatus((prevStatus) => {
        return {
          ...prevStatus,
          loading: false,
          error: "Connection error, please try again later.",
        };
      });
    }
  };

  return (
    <div className={loginStyle.container}>
      <LoginAdver />

      <div className={loginStyle.loginContainer}>
        <div className={loginStyle.brandLogoCon}>
          <a
            href="https://stockunify.com/"
            className={loginStyle.brandLogo}
          />
        </div>

        <div className={loginStyle.loginFormCon}>
          <div className={loginStyle.loginForm}>
            {resetPassword ? (
              <ForgotPassword quitResetPwd={() => setResetPassword(false)} />
            ) : (
              <div>
                <h1 className={loginStyle.signin}>Sign in</h1>
                {error ? <ErrorMessage mess={error} /> : <></>}
                {xeroSignStatus.error ? (
                  <TextErrorMessage mess={xeroSignStatus.error} />
                ) : (
                  <></>
                )}

                {passCode ? (
                  <XeroLoginPassCode
                    codeExchangeData={codeExchangeSuccessAction}
                  />
                ) : (
                  <></>
                )}

                <form>
                  <StyledEmailInput
                    label="Email*"
                    type="email"
                    name="userEmail"
                    autofocus="autofocus"
                    value={loginValues.userEmail}
                    onChange={(userEmail) =>
                      handleEmailChange({
                        target: { value: userEmail, name: "userEmail" },
                      })
                    }
                    error={formErrors.userEmail}
                  />
                  <StyledPasswordInput
                    label="Password*"
                    name="password"
                    value={loginValues.password}
                    onChange={(password) =>
                      handleChange({
                        target: { value: password, name: "password" },
                      })
                    }
                    error={formErrors.password}
                  />
                  <div style={{ marginTop: "12px" }}>
                    <SingleRatioButton
                      label="Remember me "
                      name="remember"
                      isOn={loginValues.remember}
                      onChange={(remember) =>
                        handleChange({
                          target: { value: remember, name: "remember" },
                        })
                      }
                    />
                  </div>
                  <div className="w-full mt-4">
                  {loading ? (
                    <LoadingButton name="Logging in..." />
                  ) : (
                    <SimpleBlueButton name="Log in" action={LoginRequest} />
                  )}
                         </div>

                  {xeroSignStatus.loading ? (
                    <LoadingButton name="Logging in with Xero..." />
                  ) : (
                    <XeroButton
                      name="Sign in with Xero"
                      action={signinWithXeroRequest}
                    />
                  )}
                </form>

                <p className={loginStyle.tips} onClick={forgetPassword}>
                  Forgot password?
                </p>
                <div className={loginStyle.linkCon}>
                  <TextLink
                    path={`/register`}
                    name="Don't have an account? Create a new account now."
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
