import { useEffect } from 'react';

const useMetaTag = (content) => {
  useEffect(() => {
    const metaTag = document.querySelector(`meta[name="description"]`);

    if (metaTag) {
      metaTag.setAttribute('content', content);
    } else {
      const newMetaTag = document.createElement('meta');
      newMetaTag.setAttribute('name', "description");
      newMetaTag.setAttribute('content', content);
      document.head.appendChild(newMetaTag);
    }
  }, [content]);
};

export default useMetaTag;