import React, { useState } from "react";
import commonStyle from "../../../style/commonStyle.module.css";
import salesStyle from "../salesStyle.module.css";
import { FromTag, PriceText, VariantSkuTag } from "../../../components/viewComponents";

export default function SaleOrderMargin({ values, taxIncluded, additionalCharge, additionalChargeExcltax }) {
 
const [costExcltax] = useState(additionalChargeExcltax);
  return (
    <div style={{ width: "100%" }}>
      <p style={{ marginTop: "20px" }}>{taxIncluded ? "*Price includes tax" : "*Price does not include tax"}</p>
      <table className={commonStyle.pageTable}>
        <thead>
          <tr>
            <th>Code</th>
            <th>Product Name</th>
            <th>Unit</th>
            <th>Unit Price</th>
            <th>Discount</th>
            <th>Net Price</th>
            <th>Quantity</th>
            <th>Total Price</th>
            <th>Revenue (Excl.tax)</th>
     
            <th className={salesStyle.costbc}>COGS(Excl.tax)</th>
            <th className={salesStyle.marginbc}>Gross Profit(Excl.tax)</th>
          </tr>
        </thead>
        <tbody>
          {values.map((item, index1) => {
            return (
              <tr key={index1}>
                <td><VariantSkuTag name={item.variantSku} /></td>
                <td>{item.productName}</td>
           
                <td>{item.unitName}</td>
                <td>
                  <PriceText num={item.unitPrice} />
                </td>
                <td>
                  {item.discount ? <><span>{item.discount}%</span><FromTag name="OFF" /></> : <></>} 
                </td>

                <td>
                  <PriceText
                    num={
                      item.discount
                        ? (1 - Number(item.discount) / 100) *
                          Number(item.unitPrice)
                        : item.unitPrice
                    }
                  />{" "}
                </td>

                <td>{item.quantity}</td>
                <td>
                  <PriceText num={item.totalPrice} />
                </td>
                <td className={salesStyle.costbc}>
                  <PriceText num={item.priceExclTax} />
                </td>
                <td className={salesStyle.costbc}>
                  <PriceText num={item.unitCost} />
                </td>
                <td className={salesStyle.marginbc}>
                  <PriceText num={item.productMargin} />
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
        <tr>
            <td colSpan="7" className={commonStyle.tableFootName}>
              Additional Charge
            </td>
            <td> <PriceText
                num={additionalCharge}
              /></td>
            <td className={salesStyle.costbc}>
              <PriceText
                num={costExcltax}
              />
            </td>
  
            <td className={salesStyle.costbc}></td>
            <td className={salesStyle.marginbc}>
            </td>
          </tr>
          <tr>
            <td colSpan="7" className={commonStyle.tableFootName}>
              Total
            </td>
            <td>
              <PriceText
                num={values.reduce(
                  (preTotal, item) =>
                    Number(preTotal) + Number(item.totalPrice),
                  0
                ) + Number(additionalCharge)}
              />
            </td>
            <td className={salesStyle.costbc}>
            <PriceText
                num={values.reduce(
                  (preTotal, item) =>
                    Number(preTotal) + Number(item.priceExclTax),
                  0
                ) + Number(costExcltax)}
              />
                </td>
                <td className={salesStyle.costbc}>
                <PriceText
                num={values.reduce(
                  (preTotal, item) =>
                    Number(preTotal) + Number(item.unitCost),
                  0
                )}
              />
                </td>
            <td className={salesStyle.marginbc}>
              <PriceText
                num={values.reduce(
                  (preTotal, item) =>
                    Number(preTotal) + Number(item.productMargin),
                  0
                )+ Number(costExcltax)}
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}
