import React, { useEffect, useState } from "react";
import axios from "axios";
import useDocumentTitle from "../../useDocumentTitle";
import useMetaTag from "../../useMetaTag";
import { Loader } from "../../components/viewComponents";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function DirectXeroRegisterScreen() {
  useDocumentTitle(
    "Free Trial Sign up - StockUnify Inventory Management Software"
  );
  useMetaTag(
    "Sign up free trial to explore all the feature in StockUnify inventory management software"
  );


  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });


  useEffect(() => {
    // get user location
    try {
      setRequestStatus((preState) => {
        return { ...preState, loading: true };
      });
      axios
        .get(SERVER_URL + `/register/xero`)
        .then(async (response) => {
          if (response.data.success) {
            setRequestStatus((preState) => {
              return { ...preState, loading: false, success: true };
            });
            window.location.href = response.data.data.xero_url;
          } else {
            setRequestStatus((preState) => {
              return {
                ...preState,
                loading: false,
                error: "Request Xero OAuth2.0 failed, please try again later.",
              };
            });
          }
        })
        .catch((err) => {
          setRequestStatus((preState) => {
            return {
              ...preState,
              loading: false,
              error: "Request Xero OAuth2.0 failed, please try again later.",
            };
          });
        });
    } catch (error) {
      setRequestStatus((preState) => {
        return {
          ...preState,
          loading: false,
          error: "Connection error, please try again later.",
        };
      });
    }
  }, []);

  return (
    <div>
      {requestStatus.loading ? <Loader mess="Request..." /> : <></>}
    </div>
  );
}
