
import axios from "axios";
import { authMiddleware } from "./authMiddleware";
import { PRICE_TIER_LIST_REQUEST, PRICE_TIER_LIST_FAIL, PRICE_TIER_LIST_SUCCESS, NEW_PRICE_TIER_REQUEST, NEW_PRICE_TIER_SUCCESS, NEW_PRICE_TIER_FAIL, UPDATE_PRICE_TIER_REQUEST, UPDATE_PRICE_TIER_SUCCESS, UPDATE_PRICE_TIER_FAIL, DELETE_PRICE_TIER_REQUEST, DELETE_PRICE_TIER_SUCCESS, DELETE_PRICE_TIER_FAIL,   PRODUCT_VARIANT_DETAIL_REQUEST,
  PRODUCT_VARIANT_DETAIL_SUCCESS,
  PRODUCT_VARIANT_DETAIL_FAIL,
  UPDATE_PRODUCT_CUSTOM_PRICE_REQUEST,
  UPDATE_PRODUCT_CUSTOM_PRICE_SUCCESS,
  UPDATE_PRODUCT_CUSTOM_PRICE_FAIL, } from "../constants/customPriceConstants";

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

//////////////////////////// price Tier///////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////
export const priceTiersRequest = () => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();
  
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      dispatch({ type: PRICE_TIER_LIST_REQUEST });
      axios
        .get(SERVER_URL + `/product/price_tier/list`, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            let result = response.data.data;
            result.forEach(ele => {
                ele["isEdited"] = false;
            });
            dispatch({
              type: PRICE_TIER_LIST_SUCCESS,
              payload: result,
            });
          } else {
            throw Object.assign(
              new Error("Request price tier name list failed, please try again later."),
              { code: 601 }
           );
          }
        })
        .catch((err) => {
          dispatch({
            type: PRICE_TIER_LIST_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: PRICE_TIER_LIST_FAIL,
        payload: "Connection error, please try again later",
      });
    }
};

export const newPriceTierRequest = (values) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {

    dispatch({ type: NEW_PRICE_TIER_REQUEST });
    axios
      .post(SERVER_URL + `/product/price_tier/create`, values, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: NEW_PRICE_TIER_SUCCESS });
          dispatch(priceTiersRequest());
        } else {
          if (response.data.error === 11) {
            throw Object.assign(
              new Error("Price tier name exists, please use another name"),
              { code: 602 }
           );
          } else {
            throw Object.assign(
              new Error("Create new price tier failed, please try again later."),
              { code: 603 }
           );
          }
        }
      })
      .catch((err) => {
        dispatch({
          type: NEW_PRICE_TIER_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: NEW_PRICE_TIER_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const updatePriceTierRequest =
  (values, id) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      dispatch({ type: UPDATE_PRICE_TIER_REQUEST });
      axios
        .put(SERVER_URL + `/product/price_tier/${id}`, values, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: UPDATE_PRICE_TIER_SUCCESS });
            dispatch(priceTiersRequest());
          } else {
            if (response.data.error === 11) {
              throw Object.assign(
                new Error("Price tier name exists, please use another name"),
                { code: 602 }
             );
            } else {
              throw Object.assign(
                new Error("Update price tier failed, please try again later."),
                { code: 604 }
             );
         
            }
          }
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_PRICE_TIER_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: UPDATE_PRICE_TIER_FAIL,
        payload: "Connection error, please try again later",
      });
    }
};

export const deletePriceTierRequest = (id) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    dispatch({ type: DELETE_PRICE_TIER_REQUEST });
    axios
      .delete(SERVER_URL + `/product/price_tier/${id}`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: DELETE_PRICE_TIER_SUCCESS });
          dispatch(priceTiersRequest());
        } else {
          throw Object.assign(
            new Error("Delete price tier failed, please try again later."),
            { code: 605 }
         );
        }
      })
      .catch((err) => {
        dispatch({
          type: DELETE_PRICE_TIER_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: DELETE_PRICE_TIER_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

  // get product variant details
  export const productVariantDetailsRequest =
  (id) => async (dispatch, getState) => {
  
    const {
      userLogin: { userInfo },
    } = getState();
  
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      dispatch({ type: PRODUCT_VARIANT_DETAIL_REQUEST });
      axios
        .get(SERVER_URL + `/product/custom_price/${id}`, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            let v = response.data.data;
  
            let priceCache = {}; // variantCode + PriceTierId => price
            let tierPriceType = {}; // PriceTierId => isStaticPrice
            v.customPrices.forEach(elem => {
              let isStaticPrice = elem.discount < 0;
              tierPriceType[elem.priceTierId] = isStaticPrice;
              priceCache[`${elem.variantCode}.${elem.priceTierId}`] = {
                priceTierId: elem.priceTierId, 
                customPrice: isStaticPrice && elem.customPrice >= 0 ? elem.customPrice : "", 
                discount: !isStaticPrice && elem.discount >= 0 ? elem.discount : "", 
              };
            });

            v.variant.forEach((item) => {
              // Variant Code = item.variantCode;
              v.priceTier.forEach((ele) => {
                // Price Tier Id = ele.id
                let key = `${item.variantCode}.${ele.id}`;
                item[ele.name] = (key in priceCache) ? 
                  priceCache[key] : {priceTierId: ele.id, customPrice:"", discount:""};
              })
            })

            v.priceTier.forEach((ele) => {
              ele["isStaticPrice"] = (tierPriceType[ele.id] === false) ? false : true;
              ele["bulkPrice"] = {customPrice:"", discount:""};
            })

            delete v.customPrices;

            dispatch({
              type: PRODUCT_VARIANT_DETAIL_SUCCESS,
              payload: v,
            });
          } else {
            throw Object.assign(
              new Error("Request product variant details failed, please try again late."),
              { code: 606 }
           );
          }
        })
        .catch((err) => {
          dispatch({
            type: PRODUCT_VARIANT_DETAIL_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: PRODUCT_VARIANT_DETAIL_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

// new or edit custom price
  export const updateProductCustomPriceRequest =
  (values, id) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      dispatch({ type: UPDATE_PRODUCT_CUSTOM_PRICE_REQUEST });
      axios
        .post(SERVER_URL + `/product/custom_price/${id}`, values, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: UPDATE_PRODUCT_CUSTOM_PRICE_SUCCESS });
          } 
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_PRODUCT_CUSTOM_PRICE_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: UPDATE_PRODUCT_CUSTOM_PRICE_FAIL,
        payload: "Connection UPDATE_PRODUCT_CUSTOM_PRICE_FAIL, please try again later",
      });
    }
};