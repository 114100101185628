import axios from "axios";
import {serviceMiddleware} from "./serviceMiddleware";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;


 export const getAdjustReasonListRequest = async (pageNum, pageSize, config) => {

   try {
     const response = await axios.get(SERVER_URL + `/settings/adjust-reasons/list/?pageNum=${pageNum}&pageSize=${pageSize}`, config);

     return response;
   } catch (error) {
    throw error;
   }
 
 };

 export const createAdjustReasonRequest = async (values, config) => {

  try {
    const response = await axios.post(SERVER_URL + `/settings/adjust-reasons/create`, values, config);
    serviceMiddleware(response);
    return response;
  } catch (error) {
    throw error;
  }

};

export const updateAdjustReasonRequest = async (reasonId, values, config) => {

  try {
    const response = await axios.post(SERVER_URL + `/settings/adjust-reasons/update/${reasonId}`, values, config);
    return response;
  } catch (error) {
    throw error;
  }

};
export const deleteAdjustReasonRequest = async (reasonId, config) => {

  try {
    const response = await axios.post(SERVER_URL + `/settings/adjust-reasons/delete/${reasonId}`, {}, config);
    return response;
  } catch (error) {
    throw error;
  }

};





