import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  ScreenTitle,
  SimpleBlueButton,
  LoadingButton,
} from "../../components/editComponents";
import Breadcrumbs from "../../components/Breadcrumbs";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import integrationStyle from "./integrationStyle.module.css";
import { Loader, TextErrorMessage } from "../../components/viewComponents";
import { useLocation, useNavigate } from "react-router-dom";
import { userDetailsRequest } from "../../actions/authAction";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function XeroConnectionScreen() {
  useDocumentTitle("Xero Connection");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const reconnect = location.state ? location.state.reconnect : "";
  // access check for the actions

  const { userInfo } = useSelector((state) => state.userLogin);

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////

  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [authStatus, setAuthStatus] = useState({
    success: false,
    error: "",
  });

  const [codeStatus, setCodeStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout

    if (reconnect) {
      connectXeroRequest();
    }
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };

    const query = new URLSearchParams(window.location.search);
    let code = query.get("code");
    let state = query.get("state");
    let error = query.get("error");

    if (error) {
      setAuthStatus((preState) => ({
        ...preState,
        error: "Connect to xero failed, please try again later.",
      }));
    }

    if (code) {
      setAuthStatus((preState) => ({ ...preState, success: true }));
      let authValues = {
        authCode: code,
        authState: state,
      };
      try {
        setCodeStatus((preState) => ({ ...preState, loading: true }));
        axios
          .post(SERVER_URL + `/integration/xero/pass_code`, authValues, config)
          .then(async (response) => {
            if (response.data.success) {
              setCodeStatus((preState) => ({
                ...preState,
                loading: false,
                success: true,
              }));
              dispatch(userDetailsRequest());
              navigate("/integration/xero", { replace: true });
            } else if (response.data.error === 40) {
              setCodeStatus((preState) => ({
                ...preState,
                loading: false,
                success: false,
                error: "Xero account has conencted with StockUnify.",
              }));
            } else {
              setCodeStatus((preState) => ({
                ...preState,
                loading: false,
                success: false,
                error: "Connect Xero failed, please try again later.",
              }));
            }
          })
          .catch((err) => {
            setCodeStatus((preState) => ({
              ...preState,
              loading: false,
              error: "Connect Xero failed, please try again later.",
            }));
          });
      } catch (error) {
        setCodeStatus((preState) => ({
          ...preState,
          loading: false,
          success: false,
          error: "Connection error, please try again later.",
        }));
      }
    }
  }, [SERVER_URL, userInfo.data.token, reconnect]);

  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////

  const connectXeroRequest = () => {
    try {
      setRequestStatus({ ...requestStatus, loading: true });
      axios
        .get(SERVER_URL + `/integration/xero`, config)
        .then(async (response) => {
          if (response.data.success) {
            setRequestStatus({
              ...requestStatus,
              loading: false,
              success: true,
            });
            window.location.href = response.data.data.xero_url;
          } else {
            setRequestStatus({
              ...requestStatus,
              loading: false,
              error: "Request Xero OAuth2.0 failed, please try again later.",
            });
          }
        })
        .catch((err) => {
          setRequestStatus({
            ...requestStatus,
            loading: false,
            error: "Request Xero OAuth2.0 failed, please try again later.",
          });
        });
    } catch (error) {
      setRequestStatus({
        ...requestStatus,
        loading: false,
        error: "Connection error, please try again later.",
      });
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////

  const SubTitle = ({ text }) => {
    return <p className={integrationStyle.subTitle}>{text}</p>;
  };
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Xero Integration" />
      <ScreenTitle title="Xero Integration" />

      <div className={integrationStyle.xeroIntegrationWrapper}>
        <div className={integrationStyle.integrationInfoCon}>
          <h2 className={integrationStyle.title}>
            Speed up your business with Xero + StockUnify
          </h2>

          <p>What you can do after integrated with Xero:</p>
          <SubTitle text="Continuous stock updates." />

          <p>
            Seamless integration of accounts in real time. Any changes in
            inventory levels within StockUnify are immediately reflected in your
            stock on hand and cost of goods sold accounts within Xero.
          </p>
          <SubTitle text="Receivable updates" />
          <p>
            Real-time integration of customers and their transactions. Upon
            finalizing a sale in StockUnify, Xero is immediately notified of the
            transaction and generates an invoice in your Xero system.
          </p>

          <SubTitle text="Supplier and payable updates" />
          <p>
            Integrate all suppliers and their transactions seamlessly. Rui
            Automation sends payable transactions to Xero for payment and
            reconciliation as you receive stock from your suppliers.
          </p>
          <SubTitle text="Synchronized suppliers and customers." />
          <p>
            Effortlessly synchronize customers and suppliers in real-time. Keep
            your customers and suppliers aligned between StockUnify and Xero.
          </p>
        </div>
        <div className={integrationStyle.connectionCon}>
          <div className={integrationStyle.xeroLogoCon}>
            <div className={integrationStyle.xeroLogo}></div>
          </div>

          {authStatus.error ? (
            <TextErrorMessage mess={authStatus.error} />
          ) : (
            <></>
          )}

          {requestStatus.error ? (
            <TextErrorMessage mess={requestStatus.error} />
          ) : (
            <></>
          )}

          {codeStatus.error ? (
            <TextErrorMessage mess={codeStatus.error} />
          ) : (
            <></>
          )}

          <div className="w-full mt-4">
            {requestStatus.loading ? (
              <LoadingButton name="Redirect to Xero..." />
            ) : codeStatus.loading ? (
              <LoadingButton name="Setting Xero..." />
            ) : (
              <SimpleBlueButton
                name="Connect to Xero"
                action={connectXeroRequest}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
