import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import dashboardStyle from "./dashboardStyle.module.css";
import { HiCube } from "react-icons/hi";
import { stockAlertProductsRequest } from "../../actions/dashboardActions";
import {
  ErrorMessage,
  Loader,
  MoreLink,
  Tag,
  VariantTag,
} from "../../components/viewComponents";
import { AddLinkButton } from "../../components/editComponents";
import NodataChart from "./charts/NodataChart";
import { convtQuantity } from "../../functions/functions";
import { NavLink } from "react-router-dom";

export default function StockAlert() {
  const dispatch = useDispatch();
  const { userDetails, success: userDetailSuccess } = useSelector(
    (state) => state.userDetail
  );
  const { loading, alterProducts, success, error } = useSelector(
    (state) => state.stockAlertBoard
  );

  useEffect(() => {
    dispatch(stockAlertProductsRequest());
  }, [dispatch]);

  return (
    <div className={dashboardStyle.section_wrapper_left_half}>
      <div className={dashboardStyle.section_titleCon}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
                  <div className="flex items-center ">
            <HiCube className={dashboardStyle.section_icon} />
            <p className={dashboardStyle.section_title}>Low Stock Alert</p>
          </div>

          <MoreLink href={`/reporting/inventory/stock-alert-report`} />
        </div>
      </div>

      {loading ? (
        <Loader mess="Requesting data..." />
      ) : success ? (
        alterProducts.length > 0 ? (
          <div className={dashboardStyle.LeaderBoardWrapepr}>
            {/* <LeaderBoard title="Today's Top Seller" data={topSales} /> */}
            <table className={dashboardStyle.leadBoradTable}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th style={{ width: "100px" }}>Location</th>
                  <th style={{ width: "100px" }}></th>
                  <th>Stock on Hand</th>
                  <th>Re-order Level</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {alterProducts.map((item, index) => (
                  <tr key={index}>
                    <td className={dashboardStyle.leadBoradItemCon}>
                      <div className={dashboardStyle.productNameCon}>
                        <p>{item.name}</p>{" "}
                        {item.variant ? (
                          <VariantTag name={item.variant} />
                        ) : (
                          <></>
                        )}
                      </div>
                    </td>
                    <td>
                      <Tag name={item.locationName} color="gray" />
                    </td>
                    <td>
                      {item.stockLevel === 0 ? (
                        <span className={dashboardStyle.noStocktag}>
                          Out of Stock
                        </span>
                      ) : item.stockLevel < 3 ? (
                        <span className={dashboardStyle.lowStocktag}>
                          Low Stock
                        </span>
                      ) : (
                        <></>
                      )}
                    </td>
                    <td>{convtQuantity(item.stockLevel)} </td>
                    <td>{convtQuantity(item.reorderLevel)}</td>
                    <td>
                      {item.stockLevel === 0 ? (
                        userDetailSuccess ? (
                          userDetails.data.permissions.includes(
                            "new_purchase_order"
                          ) ? (
                            <AddLinkButton
                              name="Order"
                              path="/purchase/order/new"
                              data={{
                                famid: item.family_id,
                                variantCode: item.variantCode,
                              }}
                            />
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <NodataChart text="No low stock products" />
        )
      ) : error ? (
        <ErrorMessage mess={error} />
      ) : (
        <></>
      )}
    </div>
  );
}
