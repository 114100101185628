import React, { useState } from "react";
import {
  ViewLabel,
  VariantTag,
  TextErrorMessage,
} from "../../../components/viewComponents";
import ProductPicker from "../../../components/ProductPicker";
import { FaArrowDown } from "react-icons/fa";
import { SimpleBlueButton } from "../../../components/editComponents";

export default function ChangePackageProductForm({ currentProduct, onSubmit }) {
  const [newProduct, setNewProduct] = useState("");
  const [error, setError] = useState("");

  const getProductFromPicker = (result) => {
    if(result.productType !== "package"){
      newPackageProduct(result.data)
    }
  }

  const newPackageProduct = (proDetails) => {
    setNewProduct(proDetails);
  };

  const saveNewProduct = () => {
    if (!newProduct) {
      setError("Please select a new Product.");
    } else {
      onSubmit({ product: newProduct, index: currentProduct.index });
    }
  };

  return (
    <div style={{ width: "100%", minHeight: "600px" }}>
      <div
        style={{
          padding: "20px",
          border: "1px solid var(--border-color)",
          margin: "40px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ViewLabel title="Current Product Name" />
        <div>
          {currentProduct.product.productName}
          {currentProduct.product.variant ? (
            <VariantTag name={currentProduct.product.variant} />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "var(--main-color)",
        }}
      >
        <FaArrowDown />
      </div>

      <div
        style={{
          padding: "20px",
          border: "1px dashed var(--main-color)",
          margin: "40px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {newProduct ? (
          <>
            <ViewLabel title="New Product Name" />
            <div>
              {newProduct.name}
              {newProduct.variant ? (
                <VariantTag name={newProduct.variant} />
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <></>
        )}

        <div
          style={{
            width: "100%",
            marginTop: "20px",
          }}
        >
          <ProductPicker
            onSelect={getProductFromPicker}
            locationId={currentProduct.locationId}
            requiredType="product"
          />
        </div>
      </div>
      {error ? <TextErrorMessage mess={error} /> : <></>}
<div className="w-full mt-6">
<SimpleBlueButton name="Save Change" action={saveNewProduct} />
</div>
     
    </div>
  );
}
