import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { saleOrderDetailsRequest } from "../../../actions/saleOrderActions";
import {
  generateInvoiceRequest,
  generatePacklingSlipRequest
} from "../../../api/generatePDFServices";
import Breadcrumbs from "../../../components/Breadcrumbs";
import XeroLink from "../../../components/XeroLink";
import {
  DownloadPDFButton,
  EmailButton,
  ScreenTitle,
} from "../../../components/editComponents";
import {
  ErrorMessage,
  FromTag,
  Loader,
  Modal,
  PriceText,
  SubTitle,
  Tag,
  VariantSkuTag,
  ViewDateWithTitle,
  ViewLabel,
  ViewText,
} from "../../../components/viewComponents";
import { convtQuantity } from "../../../functions/functions";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";
import purchaseStyle from "../../purchase/purchaseStyle.module.css";
import salesStyle from "../salesStyle.module.css";
import SoEmailForm from "./SoEmailForm";
import { SaleOrderInvoice } from "./invoiceComponents";

export default function PreviewSaleOrderScreen() {
  useDocumentTitle("Preview Sale Order");
  const dispatch = useDispatch();
  const { id: soid } = useParams();

  const { userInfo } = useSelector((state) => state.userLogin);
  

  const config = {
    responseType: "arraybuffer",
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const { loading, orderInfo, error, success } = useSelector(
    (state) => state.saleOrderDetails
  );

  const [emailModalOpen, setEmaiModalOpen] = useState(false);
  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [reqInvoiceStatus, setReqInvoiceStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  /////////////////////////////////////////////////////////////////////

  useEffect(() => {
    dispatch(saleOrderDetailsRequest(soid));
  }, [dispatch, soid]);

  /////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////
  const downloadPackingSlipAction = async () => {
    try {
      setReqStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await generatePacklingSlipRequest(soid, config);
      if (responseData.status === 200) {
        // Create a Blob from the response data
        const blob = new Blob([responseData.data]);
        saveAs(blob, `Packing_slip_${orderInfo.basicInfo.invoiceNumberSu ? orderInfo.basicInfo.invoiceNumberSu : orderInfo.basicInfo.orderNumber}.pdf`);

        setReqStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
          error: "",
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setReqStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const downloadInvocieAction = async () => {
    try {
      setReqInvoiceStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await generateInvoiceRequest(soid, config);
      if (responseData.status === 200) {
        // Create a Blob from the response data
        const blob = new Blob([responseData.data]);
        saveAs(blob, `Invoice_${orderInfo.basicInfo.invoiceNumberSu ? orderInfo.basicInfo.invoiceNumberSu : orderInfo.basicInfo.orderNumber}.pdf`);

        setReqInvoiceStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
          error: "",
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setReqInvoiceStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const sendEmailAction = () => {
    setEmaiModalOpen(true);
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Modal
        title="Send Email"
        isModalOpen={emailModalOpen}
        closeModal={() => setEmaiModalOpen(false)}
        content={
          <SoEmailForm
            customerEmail={success ? orderInfo.basicInfo.email : ""}
            orderInfo={orderInfo}
            closeModal={() => setEmaiModalOpen(false)}
          />
        }
      />

      <Breadcrumbs screenName={"Preview Sale Order"} />
      <ScreenTitle title={"Preview Sale Order"} />
      <div className={commonStyle.pageContentContainer}>
        {loading ? (
          <Loader mess="Requesting sale order details, please wait a moment..." />
        ) : success ? (
          <div>
            <div className={purchaseStyle.orderNumberRow}>
              <div className={purchaseStyle.orderNumberCon}>
                <div>
                  <ViewText title="Order No." />
                  <p> {orderInfo.basicInfo.orderNumber}</p>
                </div>
                {orderInfo.basicInfo.status === "Approved" ? (
                  <Tag name={orderInfo.basicInfo.status} color="green" />
                ) : orderInfo.basicInfo.status === "Draft" ? (
                  <Tag name={orderInfo.basicInfo.status} color="yellow" />
                ) : orderInfo.basicInfo.status === "Back Order" ? (
                  <Tag name={orderInfo.basicInfo.status} color="yellow" />
                ) : orderInfo.basicInfo.status === "Completed" ? (
                  <Tag name={orderInfo.basicInfo.status} color="blue" />
                ) : orderInfo.basicInfo.status === "Deleted" ? (
                  <Tag name={orderInfo.basicInfo.status} color="gray" />
                ) : (
                  <Tag name={orderInfo.basicInfo.status} color="gray" />
                )}
              </div>
            </div>

            {/*  order infomation */}
            <SubTitle name="Order Information" />
            <div className={purchaseStyle.orderInfoWrapper}>
              <div className={purchaseStyle.basicInfoWrapper}>
                <div className={commonStyle.col1}>
                  <ViewDateWithTitle
                    title="Order Date"
                    value={orderInfo.basicInfo.createDate}
                  />
                  <ViewText
                    title="Has Customer?"
                    value={orderInfo.basicInfo.noCustomer ? "No" : "Yes"}
                  />
                  {orderInfo.basicInfo.noCustomer ? (
                    <></>
                  ) : (
                    <>
                      <ViewText
                        title="Customer"
                        value={orderInfo.basicInfo.customerName}
                      />

                      <ViewText
                        title="Customer Contact"
                        value={
                          orderInfo.basicInfo.firstName +
                          " " +
                          orderInfo.basicInfo.lastName
                        }
                      />
                      <ViewText
                        title="Email"
                        value={orderInfo.basicInfo.email}
                      />
                      <ViewText
                        title="Phone"
                        value={orderInfo.basicInfo.phone}
                      />

                      <ViewLabel title="Billing Address" />
                      {orderInfo.basicInfo.billingAddress.country ? (
                        <p>
                          {orderInfo.basicInfo.billingAddress.country.value}
                        </p>
                      ) : (
                        <></>
                      )}
                      {orderInfo.basicInfo.billingAddress.region ? (
                        <p>{orderInfo.basicInfo.billingAddress.region}</p>
                      ) : (
                        <></>
                      )}
                      {orderInfo.basicInfo.billingAddress.city ? (
                        <p>{orderInfo.basicInfo.billingAddress.city}</p>
                      ) : (
                        <></>
                      )}
                      {orderInfo.basicInfo.billingAddress.addressLine ? (
                        <p>{orderInfo.basicInfo.billingAddress.addressLine}</p>
                      ) : (
                        <></>
                      )}
                      {orderInfo.basicInfo.billingAddress.postalCode ? (
                        <p>{orderInfo.basicInfo.billingAddress.postalCode}</p>
                      ) : (
                        <></>
                      )}

                      <ViewText
                        title="Need Shipping?"
                        value={
                          orderInfo.basicInfo.isNeedShipping ? "Yes" : "No"
                        }
                      />
                      {orderInfo.basicInfo.isNeedShipping ? (
                        <>
                          <ViewLabel title="Shipping Address" />
                          {orderInfo.basicInfo.shippingAddress.country ? (
                            <p>
                              {
                                orderInfo.basicInfo.shippingAddress.country
                                  .value
                              }
                            </p>
                          ) : (
                            <></>
                          )}
                          {orderInfo.basicInfo.shippingAddress.region ? (
                            <p>{orderInfo.basicInfo.shippingAddress.region}</p>
                          ) : (
                            <></>
                          )}
                          {orderInfo.basicInfo.shippingAddress.city ? (
                            <p>{orderInfo.basicInfo.shippingAddress.city}</p>
                          ) : (
                            <></>
                          )}
                          {orderInfo.basicInfo.shippingAddress.addressLine ? (
                            <p>
                              {orderInfo.basicInfo.shippingAddress.addressLine}
                            </p>
                          ) : (
                            <></>
                          )}
                          {orderInfo.basicInfo.shippingAddress.postalCode ? (
                            <p>
                              {orderInfo.basicInfo.shippingAddress.postalCode}
                            </p>
                          ) : (
                            <></>
                          )}

                          <ViewText
                            title="Shipping Company"
                            value={
                              orderInfo.basicInfo.shippingCompany
                                ? orderInfo.basicInfo.shippingCompany
                                : "Waiting for shipping"
                            }
                          />
                          <ViewText
                            title="Tracking Number"
                            value={
                              orderInfo.basicInfo.trackingNo
                                ? orderInfo.basicInfo.trackingNo
                                : "Null"
                            }
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
                <div className={commonStyle.col2}>
                  <ViewText
                    title="Location"
                    value={orderInfo.basicInfo.locationName}
                  />
                  {orderInfo.basicInfo.priceTierName && (
                    <ViewText
                      title="Price Tier"
                      value={orderInfo.basicInfo.priceTierName}
                    />
                  )}

                  <ViewText
                    title="Tax Rate"
                    value={orderInfo.basicInfo.taxrateName}
                  />
                  <ViewText
                    title="Currecy"
                    value={orderInfo.basicInfo.currency}
                  />

                  <ViewText
                    title="Payment Term"
                    value={
                      orderInfo.basicInfo.paymentTerm +
                      " Days after invoice date"
                    }
                  />
                  <ViewLabel title="Invoice" />
                  {orderInfo.basicInfo.invoiceStatus === "ISSUED" ? (
                    <XeroLink
                      invoiceId={orderInfo.basicInfo.invoiceId}
                      type="sale_order_invoice"
                    />
                  ) : (
                    <></>
                  )}
                  {orderInfo.basicInfo.projectName && (
                    <ViewText
                      title="Project"
                      value={orderInfo.basicInfo.projectName}
                    />
                  )}
                  <ViewText title="Notes" value={orderInfo.basicInfo.notes} />
                </div>
              </div>

              <div className={purchaseStyle.invoiceConPreview}>
                {orderInfo.basicInfo.status !== "Draft" ? (
                  <>
                    <DownloadPDFButton
                      name="Download Packing Slip"
                      action={downloadPackingSlipAction}
                    />

                    {orderInfo.basicInfo.invoiceStatus === "ISSUED" ? (
                      <SaleOrderInvoice
                        name={orderInfo.basicInfo.invoiceNumber}
                        invoiceId={orderInfo.basicInfo.invoiceId}
                        detailspage={true}
                      />
                    ) : (
                     <></>
                    )}
                     <DownloadPDFButton
                        name="Download Invoice (StockUnify Generated)"
                        action={downloadInvocieAction}
                      />
                    <EmailButton name="Send Email" action={sendEmailAction} />
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <SubTitle name="Order Products" />
            <p className={purchaseStyle.taxInclText}>
              {orderInfo.basicInfo.taxIncluded
                ? "* Amounts are tax inclusive"
                : "* Amounts are tax exclusive"}
            </p>

            <div className={commonStyle.longDataTable}>
              <table className={commonStyle.pageTable}>
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Product Name</th>
                    <th>Unit</th>
                    <th>Unit Price</th>
                    <th>Discount</th>
                    <th>Net Price</th>
                    <th>Quantity</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {orderInfo.orderItems.map((item, index1) => {
                    return (
                      <tr key={index1}>
                        <td>
                          {item.productName ? (
                            <VariantSkuTag name={item.variantSku} />
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {item.type === "package" ? (
                            <div className={salesStyle.productPackageName}>
                              {item.packageName}
                            </div>
                          ) : (
                            <div style={{ display: "flex" }}>
                              {item.type === "packageProduct" ? (
                                <div className={salesStyle.packageProductTag}>
                                  Package
                                </div>
                              ) : (
                                <></>
                              )}
                              {item.productName}
                              {item.variant ? (
                                <span className={purchaseStyle.variantTag}>
                                  {item.variant}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                        </td>

                        <td>{item.productName ? item.unitName : ""}</td>

                        <td
                          style={{
                            width: "10%",
                          }}
                        >
                          {item.type !== "packageProduct" ? (
                            <PriceText num={item.unitPrice} />
                          ) : (
                            <></>
                          )}
                        </td>
                        <td
                          style={{
                            width: "10%",
                          }}
                        >
                          {item.type === "packageProduct" ? (
                            <></>
                          ) : (
                            <>
                              {item.discount}% <FromTag name="OFF" />
                            </>
                          )}
                        </td>
                        <td>
                          {item.type === "packageProduct" ? (
                            <></>
                          ) : (
                            <>
                              {item.discount ? (
                                <PriceText
                                  num={
                                    (1 - Number(item.discount) / 100) *
                                    Number(item.unitPrice)
                                  }
                                />
                              ) : (
                                <PriceText num={item.unitPrice} />
                              )}
                            </>
                          )}
                        </td>
                        <td>{convtQuantity(item.quantity)}</td>

                        <td>
                          {item.type !== "packageProduct" ? (
                            <PriceText num={item.totalPrice} />
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="7" className={commonStyle.tableFootName}>
                      Total
                    </td>

                    <td>
                      <PriceText num={orderInfo.orderSummary.productsTotal} />
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div className={purchaseStyle.summaryWrapper}>
              <div className={purchaseStyle.costCon}>
                {/* additional cost */}
                <SubTitle name="Additional Charge" />
                <table className={commonStyle.pageTable}>
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>Price</th>
                    </tr>
                  </thead>

                  <tbody>
                    {orderInfo.orderCosts.map((c, index2) => {
                      return (
                        <tr key={index2}>
                          <td
                            className={purchaseStyle.productNameCol}
                            style={{
                              width: "60%",
                            }}
                          >
                            {c.costName}
                          </td>

                          <td
                            style={{
                              width: "36%",
                            }}
                          >
                            <PriceText num={c.cost} />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className={commonStyle.tableFootName}>Total</td>

                      <td>
                        <PriceText
                          num={orderInfo.orderSummary.additionalCostTotal}
                        />
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div className={purchaseStyle.summaryCon}>
                {/* summary total price */}
                <SubTitle name="Order Summary" />
                <table
                  className={commonStyle.summaryTable}
                  style={{ marginTop: "12px" }}
                >
                  {orderInfo.basicInfo.taxIncluded ? (
                    <tbody>
                      <tr>
                        <td className={commonStyle.tableFootName}>
                          Products Total
                        </td>

                        <td style={{ width: "30%" }}>
                          <PriceText
                            num={orderInfo.orderSummary.productsTotal}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className={commonStyle.tableFootName}>
                          Additional Charge Total
                        </td>
                        <td style={{ width: "30%" }}>
                          <PriceText
                            num={orderInfo.orderSummary.additionalCostTotal}
                          />
                        </td>
                      </tr>

                      <tr className={commonStyle.summaryTableTotalRow}>
                        <td className={commonStyle.summaryTableTotalName}>
                          Order Total{" "}
                          <span className={commonStyle.taxText}>Tax Incl.</span>
                        </td>

                        <td className={commonStyle.summaryTableTotalValue}>
                          <PriceText
                            num={orderInfo.basicInfo.orderTotalInclTax}
                          />
                          {orderInfo.basicInfo.currency}
                        </td>
                      </tr>

                      <tr className={commonStyle.summaryTableTotalRow}>
                        <td className={commonStyle.tableFootName}>
                          Total{" "}
                          <span className={commonStyle.taxText}>Tax Excl.</span>
                        </td>

                        <td style={{ width: "30%" }}>
                          <PriceText
                            num={orderInfo.basicInfo.orderTotalExclTax}
                          />
                        </td>
                      </tr>

                      <tr className={commonStyle.summaryTableTotalRow}>
                        <td className={commonStyle.tableFootName}>
                          Tax
                          <span className={commonStyle.taxText}>
                            {orderInfo.taxRate
                              ? "-" + orderInfo.taxRate.value
                              : ""}
                          </span>
                        </td>

                        <td style={{ width: "30%" }}>
                          <PriceText num={orderInfo.basicInfo.orderTotalTax} />
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className={commonStyle.tableFootName}>
                          Products Total
                        </td>

                        <td style={{ width: "30%" }}>
                          <PriceText
                            num={orderInfo.orderSummary.productsTotal}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className={commonStyle.tableFootName}>
                          Additional Charge Total
                        </td>
                        <td style={{ width: "30%" }}>
                          <PriceText
                            num={orderInfo.orderSummary.additionalCostTotal}
                          />
                        </td>
                      </tr>

                      <tr className={commonStyle.summaryTableTotalRow}>
                        <td className={commonStyle.tableFootName}>
                          Total{" "}
                          <span className={commonStyle.taxText}>Tax Excl.</span>
                        </td>

                        <td style={{ width: "30%" }}>
                          <PriceText
                            num={orderInfo.basicInfo.orderTotalExclTax}
                          />
                        </td>
                      </tr>

                      <tr className={commonStyle.summaryTableTotalRow}>
                        <td className={commonStyle.tableFootName}>
                          Tax
                          <span className={commonStyle.taxText}>
                            {orderInfo.taxRate
                              ? "-" + orderInfo.basicInfo.taxRate.value
                              : ""}
                          </span>
                        </td>

                        <td style={{ width: "30%" }}>
                          <PriceText num={orderInfo.basicInfo.orderTotalTax} />
                        </td>
                      </tr>

                      <tr className={commonStyle.summaryTableTotalRow}>
                        <td className={commonStyle.summaryTableTotalName}>
                          Order Total{" "}
                          <span className={commonStyle.taxText}>Tax Incl.</span>
                        </td>

                        <td className={commonStyle.summaryTableTotalValue}>
                          <PriceText
                            num={orderInfo.basicInfo.orderTotalInclTax}
                          />
                          {orderInfo.basicInfo.currency}
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
    </div>
  );
}
