import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../components/Breadcrumbs";
import {
  priceTiersRequest,
  newPriceTierRequest,
  updatePriceTierRequest,
  deletePriceTierRequest
} from "../../actions/customPriceActions";
import {
  ScreenTitle,
  AddButton,
  EditButton,
  DeleteButton,
} from "../../components/editComponents";
import {
  Modal,
  ErrorMessage,
  Loader,
  SimpleDialog,
  DeleteDialog,
} from "../../components/viewComponents";
import useDocumentTitle from "../../useDocumentTitle";
import commonStyle from "../../style/commonStyle.module.css";
import PriceTierForm from "./PriceTierForm";


export default function PriceTierScreen() {
  useDocumentTitle("Price Tiers")
  const dispatch = useDispatch();

//define the add new category input values
const initialValues = {
  name: "",
};

// get data from store by using useSelector method
const { loading, priceTierList, error, success } = useSelector(
  (state) => state.priceTier
);

const {
  loading: upadteLoading,
  error: updateError,
  success: updateSuccess,
} = useSelector((state) => state.updatePriceTier);

const {
  loading: newLoading,
  error: newError,
  success: newSuccess,
} = useSelector((state) => state.newPriceTier);

const [editedPriceTier, setEditPriceTier] = useState("");

// new and edit Modal
const [newModal, setNewModal] = useState(false);
const [editModal, setEditModal] = useState(false);

// mange dialog content, new and edit
const [newDialog, setNewDialog] = useState(false);
const [updateDialog, setUpdateDialog] = useState(false);

const openEditModal = (item) => {
  setEditPriceTier(item);
  setEditModal(true);
};

const addNewPriceTier = (values) => {
  let newValues = {
    priceTierName: values.name,
  };

  dispatch(newPriceTierRequest(newValues));
  setNewModal(false);
  setNewDialog(true);
};

const editPriceTier = (values) => {
  let newValues = {
    priceTierName: values.name,
  };

  dispatch(updatePriceTierRequest(newValues, values.id));
  setEditModal(false);
  setUpdateDialog(true);
};

// delete
const [deleteId, setDeleteId] = useState();
const [deleteDialog, setDeleteDialog] = useState(false);
const {
  loading: deleteLoading,
  error: deleteError,
  success: deleteSuccess,
} = useSelector((state) => state.deletePriceTier);

const deleteAction = (id) => {
  setDeleteId(id);
  setDeleteDialog(true);
};

const sendDeleteRequest = () => {
  dispatch(deletePriceTierRequest(deleteId));
};


useEffect(() => {
  dispatch(priceTiersRequest());
}, [dispatch]);

return (
  <div className={commonStyle.pageContainer}>
    <Modal
      title="New Price Tier"
      isModalOpen={newModal}
      closeModal={() => setNewModal(false)}
      content={
        <PriceTierForm
          values={initialValues}
          onSubmit={addNewPriceTier}
          btnName="Save"
        />
      }
    />
    <Modal
      title="Edit Price Tier"
      isModalOpen={editModal}
      closeModal={() => setEditModal(false)}
      content={
        <PriceTierForm
          values={editedPriceTier}
          onSubmit={editPriceTier}
          btnName="Update"
        />
      }
    />

    <SimpleDialog
      title="Add New Price Tier"
      successMessage="New price tier added successfully."
      isDialogOpen={newDialog}
      closeDialog={() => setNewDialog(false)}
      loading={newLoading}
      loadingMessage="Processing request..."
      success={newSuccess}
      error={newError}
      btnValue="Confirm"
      confirmAction={() => setNewDialog(false)}
    />

    <SimpleDialog
      title="Edit Price Tier"
      successMessage="Price tier updated successfully."
      isDialogOpen={updateDialog}
      closeDialog={() => setUpdateDialog(false)}
      loading={upadteLoading}
      loadingMessage="Processing request..."
      success={updateSuccess}
      error={updateError}
      btnValue="Confirm"
      confirmAction={() => setUpdateDialog(false)}
    />

    <DeleteDialog
      title="Delete Price Tier"
      preDeleteMess="Deleting this price tier can not be undone. Once deleted, all product prices associated with this price tier will also be deleted. If you simply want to change the name, you can edit it instead. Are you sure you want to proceed with deleting this price tier?"
      successMessage="Price tier deleted successfully."
      isDialogOpen={deleteDialog}
      closeDialog={() => setDeleteDialog(false)}
      loading={deleteLoading}
      success={deleteSuccess}
      error={deleteError}
      loadingMessage="Processing request..."
      deleteAction={sendDeleteRequest}
    />

    <Breadcrumbs screenName="Price Tiers" />
    <ScreenTitle title="Price Tiers" />

    <div className={commonStyle.pageContentContainer}>
      {loading ? (
        <Loader mess="Requesting price tier list, please wait a moment..." />
      ) : success ? (
        <div className={commonStyle.shortDataTable}>
          <AddButton
            name="New Price Tier"
            action={() => setNewModal(true)}
          />

          {/* hold the tax rates table */}
          <table className={commonStyle.pageTable}>
            <thead>
              <tr>
                {/* <th></th> */}
                <th>Price Tier Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {priceTierList.length > 0 ? (
                priceTierList.map((item) => (
                  <tr key={item.id}>
                    <td>{item.name} </td>
                    <td>
                      <div className={commonStyle.actionsGrop}>
                        <EditButton action={() => openEditModal(item)} />
                        <DeleteButton
                          action={() => deleteAction(item.id)}
                        />
                      </div>
                      
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>There are no price tier available. Please add a new price tier now.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <ErrorMessage mess={error} />
      )}
    </div>
  </div>
);
}
