import React, { useState } from "react";
import {
  SimpleBlueButton,
  SingleRatioButton,
} from "../../components/editComponents";
import { DollarTag, PriceText, VariantTag } from "../../components/viewComponents";
import {
  NoBorderInput,
  NoBorderPercentInput,
} from "../../components/inputFields";
import priceStyle from "./customPriceStyle.module.css";
import commonStyle from "../../style/commonStyle.module.css";
import { MdOutlineDoneAll } from "react-icons/md";
import { validatePrice } from "../../functions/validate";

export default function CustomPriceForm({
  productName,
  initialPrices,
  initialPriceTierList,
  saveCustomPrice,
}) {

  
  const [prices, setPrices] = useState(initialPrices);

  const [priceTierList, setPriceTierList] = useState(initialPriceTierList);

  const [isBulkFill, setBulkFill] = useState(false);

  const [formErrors, setFormErrors] = useState({});

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  const handleTypeChange = (e) => {
    const { value, index } = e.target;

    let priceTierCopy = [...priceTierList];
    priceTierCopy[index]["isStaticPrice"] = value;
    setPriceTierList(priceTierCopy);
  };

  const handlePriceChange = (e) => {
    const { key, value, index, priceTier } = e.target;

    const validated = validatePrice(value);
    if (!validated) return;

    let pricesCopy = [...prices];
    pricesCopy[index][priceTier][key] = value;

    if (key === "discount") {
      let calculatedPrice = Number(
        Number(pricesCopy[index].salePrice) -
          (Number(value) / 100) * Number(pricesCopy[index].salePrice)
      );
      pricesCopy[index][priceTier]["markUpprice"] = calculatedPrice;
    }

    setPrices(pricesCopy);
  };

  ////////////////////////////////  Bulk Fill//////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////

  const handleBulkPriceChange = (e) => {
    //bulkPrice
    const { key, value, index } = e.target;
    const validated = validatePrice(value);
    if (!validated) return;

    // set bulk price
    let priceTierCopy = [...priceTierList];

    priceTierCopy[index]["bulkPrice"][key] = value;
    setPriceTierList(priceTierCopy);
  };

  const bulkApplyToAll = () => {
    let priceTierCopy = [...priceTierList];
    let pricesCopy = [...prices];

    priceTierCopy.forEach((x) => {
      pricesCopy.forEach((p) => {
        p[x.name]["customPrice"] = x.bulkPrice.customPrice;
        p[x.name]["discount"] = x.bulkPrice.discount;

        let calculatedPrice = Number(
          Number(p.salePrice) -
            (Number(x.bulkPrice.discount) / 100) * Number(p.salePrice)
        );
        p[x.name]["markUpprice"] = calculatedPrice;
      });
    });

    setPrices(pricesCopy);
  };

  ////////////////////////////////  Submit//////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  const checkValidation = () => {
    const hasErrors = validate(prices);

    if (Object.keys(hasErrors).length === 0) {
      saveCustomPrice(prices, priceTierList);
    } else {
      setFormErrors(hasErrors);
    }
  };

  // check input validation
  const validate = (values) => {
    const errors = {};

    return errors;
  };
  ////////////////////////////////  render table head//////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////

  const CustomPriceTableHeader = ({ isbulkFillTable }) => {
    return (
      <thead>
        <tr>
          {isbulkFillTable ? (
              <th></th>
          ) : (
            <>
              <th>Variant</th>
              <th>Basic Price</th>
            </>
          )}

          {priceTierList.length > 0 ? (
            priceTierList.map((x, index) => {
              return (
                <th key={index} className={priceStyle.priceTierHeader}>
                  {x.name}
                  <div className={priceStyle.priceTypeCon}>
                    <span
                      className={
                        priceTierList[index].isStaticPrice
                          ? priceStyle.priceTypeCBtnActive
                          : priceStyle.priceTypeCBtn
                      }
                      onClick={() =>
                        handleTypeChange({
                          target: {
                            value: true,
                            index: index,
                          },
                        })
                      }
                    >
                      Static Price
                    </span>

                    <span
                      style={{ marginLeft: "4px" }}
                      className={
                        !priceTierList[index].isStaticPrice
                          ? priceStyle.priceTypeCBtnActive
                          : priceStyle.priceTypeCBtn
                      }
                      onClick={() =>
                        handleTypeChange({
                          target: {
                            value: false,
                            index: index,
                          },
                        })
                      }
                    >
                      Markup Price
                    </span>
                  </div>
                </th>
              );
            })
          ) : (
            <></>
          )}
        </tr>
      </thead>
    );
  };

  return (
    <div className={commonStyle.longDataTable}>
      {formErrors.attrs ? (
        <p className={commonStyle.tableError}>{formErrors.attrs}</p>
      ) : (
        <></>
      )}
      <h3>{productName}</h3>

      <div style={{ marginTop: "10px" }}>
        <SingleRatioButton
          label="Bulk fill"
          name="bulkFill"
          isOn={isBulkFill}
          onChange={(bulkFill) => setBulkFill(bulkFill)}
        />
      </div>
      {isBulkFill ? (
        <>
          <table className={commonStyle.pageTable}>
            <CustomPriceTableHeader isbulkFillTable />
            <tbody>
              <tr>
                <td className={priceStyle.headerTitle}>
                  <div
                    onClick={bulkApplyToAll}
                    className={priceStyle.bulkFillBtn}
                  >
                    <MdOutlineDoneAll />
                    <span>Apply to All</span>
                  </div>
                </td>
                {priceTierList.length > 0 ? (
                  priceTierList.map((ele, index4) => {
                    return (
                      <td key={index4} className={priceStyle.priceTierContent}>
                        {ele.isStaticPrice ? (
                          <>
                            <NoBorderInput
                              type="text"
                              name="Attribute"
                              value={ele.bulkPrice.customPrice}
                              onChange={(price) =>
                                handleBulkPriceChange({
                                  target: {
                                    key: "customPrice",
                                    value: price,
                                    priceTier: ele.name,
                                    index: index4,
                                  },
                                })
                              }
                            />
                            <></>
                          </>
                        ) : (
                          <div className={priceStyle.markupPriceCon}>
                            <NoBorderPercentInput
                              type="text"
                              name="discount"
                              value={ele.bulkPrice.discount}
                              onChange={(discount) =>
                                handleBulkPriceChange({
                                  target: {
                                    key: "discount",
                                    value: discount,
                                    priceTier: ele.name,
                                    index: index4,
                                  },
                                })
                              }
                            />
                          </div>
                        )}
                      </td>
                    );
                  })
                ) : (
                  <></>
                )}
              </tr>
            </tbody>
          </table>
          <p className={priceStyle.bulkTips}>
          After clicking the 'Apply to all' button, the bulk price will overwrite the custom prices in the table below. 
          Once you've bulk-filled the data, you can still edit each field individually.
          </p>
        </>
      ) : (
        <></>
      )}
      {/* hold the table */}
      <table className={commonStyle.pageTable}>
        <CustomPriceTableHeader />
        <tbody>
          {prices.length > 0 ? (
            prices.map((item, index1) => (
              <tr key={item.id}>
                <td className={priceStyle.headerHalfTitle}> {item.variant ? <VariantTag name={item.variant} /> : <></>} </td>
                <td className={priceStyle.headerHalfTitle}>
                  <DollarTag />
                  {Number(item.salePrice)}
                </td>
                {priceTierList.length > 0 ? (
                  priceTierList.map((ele, index2) => {
                    return (
                      <td key={index2} className={priceStyle.priceTierContent}>
                        {ele.isStaticPrice ? (
                          <>
                            <NoBorderInput
                              type="text"
                              name="Attribute"
                              value={prices[index1][ele.name].customPrice}
                              onChange={(price) =>
                                handlePriceChange({
                                  target: {
                                    key: "customPrice",
                                    value: price,
                                    index: index1,
                                    priceTier: ele.name,
                                  },
                                })
                              }
                            />
                            <></>
                          </>
                        ) : (
                          <div className={priceStyle.markupPriceCon}>
                            <NoBorderPercentInput
                              type="text"
                              name="discount"
                              value={prices[index1][ele.name].discount}
                              onChange={(discount) =>
                                handlePriceChange({
                                  target: {
                                    key: "discount",
                                    value: discount,
                                    index: index1,
                                    priceTier: ele.name,
                                  },
                                })
                              }
                            />
                            <div className={priceStyle.markupPriceText}>
                              {prices[index1][ele.name].discount ? (
                                <>
                                  <DollarTag />
                                  {
                                    prices[index1][ele.name].markUpprice ? <PriceText num={prices[index1][ele.name].markUpprice} /> : <PriceText num={(1-(Number(prices[index1][ele.name].discount) / 100)) * Number(item.salePrice)} />
                                  }
                                </>
                              ) : (
                                <>""</>
                              )}
                            </div>
                          </div>
                        )}
                      </td>
                    );
                  })
                ) : (
                  <></>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td>There are no price available.</td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="w-full mt-6">
        <SimpleBlueButton action={checkValidation} name="Save Custom Price" />
      </div>
    </div>
  );
}
