import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  productListReducer,
  productDetailsReducer,
  productCategoryListReducer,
  newCategoryReducer,
  updateCategoryReducer,
  deleteCategoryReducer,
  productOptionsReducer,
  createNewProductReducer,
  updateProductReducer,
  productAdjustStockDetailsReducer,
  stocktakeListReducer,
  directlyReceiveStockReducer,
  stockAdjustListReducer,
  stockAdjustDetailsReducer,
  updateProductPackageReducer,
  productPackageListReducer,
  productPackageDetailsReducer,
  serialBatchNumbersReducer,
  productUnitListReducer,
  newUnitReducer,
  updateUnitReducer,
  deleteUnitReducer,
  deleteProductReducer,
  bulkStockTransferReducer,
  stockTransferListReducer,
  stockTransfeDetailsReducer
} from "./reducers/productReducers";
import {
  roleDetailReducer,
  AllPermissionsReducer,
  usersListReducer,
  newRoleReducer,
  updateRoleReducer,
  newUserReducer,
  updateUserReducer,
  roleNamesListReducer,
  createNewUserOptionsReducer,
  deleteUserReducer,
  deleteRoleReducer,
} from "./reducers/userRoleReducers";
import {
  userDetailReducer,
  userLoginReducer,
  activateAccountReducer,
  resetPasswordReducer
} from "./reducers/authReducers";
import {
  organizationDetailsReducer,
  locationListReducer,
  newLocationReducer,
  updateLocationReducer,
  newDepartmentReducer,
  updateDepartmentReducer,
  departmentListReducer,
  saleTaxRateListReducer,
  newSaleTaxRateReducer,
  updateSaleTaxRateReducer,
  deleteLocationReducer,
  deleteDepartmentReducer,
  organizationUpdateDetailsReducer,
  currencyListReducer,
  newCurrencyReducer,
  updateCurrencyReducer,
  deleteCurrencyReducer,
  emailTemplatesListReducer,
  updateEmailTemplateReducer,
  emailTemplatesDetailsReducer,
  checkOrgStatusReducer
} from "./reducers/orgSettingsReducers";
import {
  createNewSupplierReducer,
  createSupplierOptionsReducer,
  deleteSupplierReducer,
  editSupplierReducer,
  supplierDetailsReducer,
  supplierListReducer,
} from "./reducers/supplierReducers";
import {
  searchPurchaseProductReducer,
  purchaseOrderOptionsReducer,
  purchaseOrderListReducer,
  purchaseOrderDetailsReducer,
  createPurchaseOrderReducer,
  editPurchaseOrderReducer,
  receiveStockReducer,
  latestProductPurchaseDetailsReducer
} from "./reducers/purchaseOrderReducers";

import {
  priceTierListReducer,
  newPriceTierReducer,
  updatePriceTierReducer,
  deletePriceTierReducer,
  productVariantDetailsReducer,
  updateProductCustomPriceReducer,
} from "./reducers/customPriceReducers";

import {
  createCustomerOptionsReducer,
  createNewCustomerReducer,
  editCustomerReducer,
  customerDetailsReducer,
  customerListReducer,
  deleteCustomerReducer
} from "./reducers/customerReducers";

import {
  saleOrderOptionsReducer,
  saleOrderListReducer,
  saleOrderDetailsReducer,
  editSaleOrderReducer,
  createSaleOrderReducer,
  shipSaleOrderReducer,
} from "./reducers/saleOrderReducers";
import { saleOrderReportOptionReducer } from "./reducers/reportingReducers";
import { favGroupReducer, saleBoardDataReducer, saleTrendsdDataReducer, updateFavGroupReducer, topSaleProductReducer, stockAlertProductReducer, trailTaskReducer, initialSettingsReducer, createdPurchaseOrderDataReducer, invBoardDataReducer} from "./reducers/dashboardReducers";
import {importExportLogsReducer, importCsvReducer, exportCsvReducer} from "./reducers/IOReducers";
import { subscriptionDetailsReducer } from "./reducers/billingReducers";
import { xeroTaskListReducer } from "./reducers/xeroReducers";
import { systemSettingsDetailsReducer, updateSystemSettingsReducer } from "./reducers/systemSettingsReducers";



const reducer = combineReducers({
  //login and register reducers
  userLogin: userLoginReducer,
  userDetail: userDetailReducer,
  activateAccount: activateAccountReducer,
  resetPassword: resetPasswordReducer,
  //inventory reducers
  createProduct: createNewProductReducer,
  updateProduct: updateProductReducer,
  productOptions: productOptionsReducer,
  productList: productListReducer,
  productDetails: productDetailsReducer,
  productCategories: productCategoryListReducer,
  newCategory: newCategoryReducer,
  updateCategory: updateCategoryReducer,
  deleteCategory: deleteCategoryReducer,
  productAdjustDetails: productAdjustStockDetailsReducer,
  directlyReveiveStock: directlyReceiveStockReducer,
  stockAdjustList: stockAdjustListReducer,
  stockAdjustDetails: stockAdjustDetailsReducer,
  serialBatchNumbers: serialBatchNumbersReducer,
  stocktake: stocktakeListReducer,
  updateProductPackage: updateProductPackageReducer,
  productPackageList: productPackageListReducer,
  productPackageDetails: productPackageDetailsReducer,
  unitList: productUnitListReducer,
  newUnit: newUnitReducer,
  updateUnit: updateUnitReducer,
  deleteUnit: deleteUnitReducer,
  deleteProduct: deleteProductReducer,
  bulkStockTransfer: bulkStockTransferReducer,
  stockTransferList: stockTransferListReducer,
  stockTransferDetails: stockTransfeDetailsReducer,
  //settings reducers
  //users and roles
  roleDetail: roleDetailReducer,
  allPermissions: AllPermissionsReducer,
  newRole: newRoleReducer,
  updateRole: updateRoleReducer,
  usersList: usersListReducer,
  newUser: newUserReducer,
  updateUser: updateUserReducer,
  roleNamesList: roleNamesListReducer,
  createUserOptions: createNewUserOptionsReducer,
  deleteUser: deleteUserReducer,
  deleteRole: deleteRoleReducer,
  //sale tax rate
  salesTaxRateList: saleTaxRateListReducer,
  newSaleTaxRate: newSaleTaxRateReducer,
  updateSaleTaxRate: updateSaleTaxRateReducer,

  //org settings
  checkOrgStatus: checkOrgStatusReducer,
  locationList: locationListReducer,
  newLocation: newLocationReducer,
  updateLocation: updateLocationReducer,
  deleteLocation: deleteLocationReducer,
  orgDetails: organizationDetailsReducer,
  orgUpdate: organizationUpdateDetailsReducer,
  departmentList: departmentListReducer,
  updateDepartment: updateDepartmentReducer,
  newDepartment: newDepartmentReducer,
  deleteDepartment: deleteDepartmentReducer,
  currencyList: currencyListReducer,
  newCurrency: newCurrencyReducer,
  updateCurrency: updateCurrencyReducer,
  deleteCurrency: deleteCurrencyReducer,
  emailTemplateList: emailTemplatesListReducer,
  updateEmailTemplate: updateEmailTemplateReducer,
  emailTemplateDetails: emailTemplatesDetailsReducer,

  // purchase suppliers
  createSupplierOptions: createSupplierOptionsReducer,
  newSupplier: createNewSupplierReducer,
  editSupplier: editSupplierReducer,
  deleteSupplier: deleteSupplierReducer,
  supplierDetails: supplierDetailsReducer,
  supplierList: supplierListReducer,
  // purchase order
  searchPurchaseProduct: searchPurchaseProductReducer,
  purchaseOrderOptions: purchaseOrderOptionsReducer,
  purchaseOrderList: purchaseOrderListReducer,
  purchaseOrderDetails: purchaseOrderDetailsReducer,
  createPurchaseOrder: createPurchaseOrderReducer,
  editPurchaseOrder: editPurchaseOrderReducer,
  receiveStock: receiveStockReducer,
  latestPurchaseDetails: latestProductPurchaseDetailsReducer,
  // custom price
  priceTier: priceTierListReducer,
  newPriceTier: newPriceTierReducer,
  updatePriceTier: updatePriceTierReducer,
  deletePriceTier: deletePriceTierReducer,
  productVariant: productVariantDetailsReducer,
  updateCustomPrice: updateProductCustomPriceReducer,
  // customer
  createCustomerOptions: createCustomerOptionsReducer,
  newCustomer: createNewCustomerReducer,
  editCustomer: editCustomerReducer,
  deleteCustomer: deleteCustomerReducer,
  customerDetails: customerDetailsReducer,
  customerList: customerListReducer,
  // sale order
  saleOrderOptions: saleOrderOptionsReducer,
  saleOrderList: saleOrderListReducer,
  saleOrderDetails: saleOrderDetailsReducer,
  createSaleOrder: createSaleOrderReducer,
  editSaleOrder: editSaleOrderReducer,
  shipSaleOrder: shipSaleOrderReducer,

  //dashboard
  initialSettingsData: initialSettingsReducer,
  trailTask: trailTaskReducer,
  updateFavGroup: updateFavGroupReducer,
  favGroup: favGroupReducer,
  saleBoard: saleBoardDataReducer,
  saleTrendsData: saleTrendsdDataReducer,
  topSaleBoard: topSaleProductReducer,
  stockAlertBoard: stockAlertProductReducer,
  createdPo: createdPurchaseOrderDataReducer,
  invBoard: invBoardDataReducer,

  // IO
  importCsv: importCsvReducer,
  exportCsv: exportCsvReducer,
  IOLogs: importExportLogsReducer,
  // billing
  subscriptionDetails: subscriptionDetailsReducer,
  //integration
  xeroTaskList: xeroTaskListReducer,
  // reporting
  saleOrderReportOptions: saleOrderReportOptionReducer,
  // system settings
  systemSettingDetails: systemSettingsDetailsReducer,
  updateSystemSettings: updateSystemSettingsReducer
});

// get userinfo from local storage
const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;


const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middlewares = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export default store;
