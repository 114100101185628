import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userlogout } from "../../actions/authAction.js";
import {
  productsByProjectReportFilterOptionRequest,
  productsTransactionByProjectReportRequest,
} from "../../api/reportingServices.js";
import Breadcrumbs from "../../components/Breadcrumbs.js";
import Pagination from "../../components/Pagination.js";
import {
  LoadingButton,
  ScreenTitle,
  SimpleBlueButton,
  SimpleOutlineButton,
} from "../../components/editComponents.js";
import {
  DateInput,
  StyledMultiSelect,
  StyledSelect,
} from "../../components/inputFields.js";
import {
  BatchSerialNumberViewDialog,
  DollarTag,
  ErrorMessage,
  Loader,
  Tag,
  TextErrorMessage,
  VariantSkuTag,
  ViewDateWithTime,
} from "../../components/viewComponents.js";
import { formatDate } from "../../functions/functions.js";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle.js";
import { CsvWriter } from "./CsvWriter.js";

export default function ProductTransactionsByProjectScreen() {
  useDocumentTitle("Product Transactions by Project Report");
  const dispatch = useDispatch();
  const optionsRef = useRef(true);

  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: detailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  // pagination
  const [options, setOptions] = useState();

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);

  const [reportData, setReportData] = useState([]);
  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [downloadStatus, setDownloadStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  const [filterProject, setFilterProject] = useState();
  const [filterError, setFilterError] = useState("");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterLocation, setFilterLocation] = useState([]);

  useEffect(() => {
    const getOptions = async () => {
      try {
        const responseData = await productsByProjectReportFilterOptionRequest(
          config
        );
        if (responseData.success) {
          optionsRef.current = false;
          if (responseData.data.projects.length) {
            setOptions(responseData.data);
          }
        } else if (responseData.error === -3) {
          dispatch(userlogout());
        }
      } catch (error) {}
    };

    if (optionsRef) {
      getOptions();
    }
  }, [optionsRef]);

  const handleProjectChange = (value) => {
    setFilterProject(value);
    setPageNum(0);
  };

  const applyFilter = () => {
    getProducts();
  };

  /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////
  const downloadFile = async () => {
    // get full stocktake list
    let selectedFilterLocation = [];
    let startTimestamp = filterStartDate
      ? Math.floor(filterStartDate.setHours(0, 0, 0, 0) / 1000)
      : "";
    let endTimestamp = filterEndDate
      ? Math.floor(filterEndDate.setHours(23, 59, 59, 999) / 1000)
      : "";

    if (startTimestamp > endTimestamp) {
      setFilterError("The start date is greater than end date.");
      return;
    }

    if (filterLocation.length > 0) {
      filterLocation.forEach((loc) => {
        selectedFilterLocation.push(loc.id);
      });
    }

    let products = [];
    try {
      setDownloadStatus((preState) => ({
        ...preState,
        loading: true,
      }));

      const responseData = await productsTransactionByProjectReportRequest(
        0,
        99999999999,
        filterProject ? filterProject.id : "",
        selectedFilterLocation.join(),
        startTimestamp,
        endTimestamp,
        config
      );

      if (responseData.success) {
        products = responseData.data.products;
        setDownloadStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      } else if (responseData.error === -3) {
        dispatch(userlogout());
      }
    } catch (error) {
      setDownloadStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
    //get csv file
    let csvWriter = new CsvWriter([
      "Date",
      "Project",
      "Code",
      "ProductName",
      "Location",
      "Transaction Type",
      "Qty Change",
      "Value",
      "Notes",
      "OrderNumber",
      "Batch/Serial Code",
      "Adjust Reason",
      "User",
    ]);
    for (let line of products) {
      csvWriter.addLine([
        formatDate(
          new Date(line.time * 1000),
          detailsSuccess ? userDetails.data.orgInfo.date_format : "DD/MM/YYYY",
          detailsSuccess
            ? userDetails.data.orgInfo.time_zone
            : "Pacific/Auckland"
        ),
        line.projectCode + "-" + line.projectName,
        line.variantSku,
        line.productName,
        line.locationName,
        line.type === "po"
          ? "Purchase"
          : line.type === "so"
          ? "Sale"
          : line.type === "RECEIVESTOCK"
          ? "Receive Stock"
          : line.type === "STOCKDEDUCTION"
          ? "Deduct Stock"
          : line.type === "transferin"
          ? "Transfer In"
          : line.type === "transferout"
          ? "Transfer Out"
          : "",
        line.type === "so"
          ? "-" + line.qty
          : line.type === "transferout"
          ? "-" + line.qty
          : line.qty,
        line.totalValue,
        line.notes,
        line.orderNumber,
        line.batchCodes ? line.batchCodes : "",
        line.reasonName ? line.reasonName : "",
        line.creatorFirstName +
          " " +
          (line.creatorLastName ? line.creatorLastName : ""),
      ]);
    }
    const csvString = csvWriter.content;

    // create a download link
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        "product-transactions-by-project-report.csv"
      );
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  /////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getProducts();
  }, [pageNum, filterProject]);

  const getProducts = async () => {
    let selectedFilterLocation = [];

    let startTimestamp = filterStartDate
      ? Math.floor(filterStartDate.setHours(0, 0, 0, 0) / 1000)
      : "";
    let endTimestamp = filterEndDate
      ? Math.floor(filterEndDate.setHours(23, 59, 59, 999) / 1000)
      : "";

    if (startTimestamp > endTimestamp) {
      setFilterError("The start date is greater than end date.");
      return;
    }
    if (filterLocation.length > 0) {
      filterLocation.forEach((loc) => {
        selectedFilterLocation.push(loc.id);
      });
    }

    try {
      setRequestStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await productsTransactionByProjectReportRequest(
        pageNum,
        pageSize,
        filterProject ? filterProject.id : "",
        selectedFilterLocation.join(),
        startTimestamp,
        endTimestamp,
        config
      );

      if (responseData.success) {
        setReportData(responseData.data);
        setFilterError("");
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      } else if (responseData.error === -3) {
        dispatch(userlogout());
      }
    } catch (error) {
      setRequestStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const DownloadBtn = () => {
    return (
      <div style={{ width: "auto" }}>
        {downloadStatus.loading ? (
          <LoadingButton name="Downloading.." />
        ) : (
          <SimpleBlueButton name="Download" action={downloadFile} />
        )}
      </div>
    );
  };

  const BatchCell = ({ batchCodes }) => {
    // Convert batchCodes string to an array
    const batchCodesArray = batchCodes ? batchCodes.split(",") : [];
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleModalOpen = (e) => {
      e.preventDefault();
      setIsModalOpen(true);
    };

    const handleModalClose = () => {
      setIsModalOpen(false);
    };

    return (
      <div>
        <BatchSerialNumberViewDialog
          title={"Batch/Serial Numbers"}
          numbers={batchCodesArray}
          isDialogOpen={isModalOpen}
          closeDialog={handleModalClose}
          
        />

        {batchCodesArray.length > 2 ? (
          <button onClick={handleModalOpen} className="text-sm text-brandColor hover:underline">View Codes</button>
        ) : (
          <p>{batchCodes}</p>
        )}
      </div>
    );
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Product Transactions by Project Report" />

      <ScreenTitle
        title="Product Transactions by Project Report"
        buttonComponent={<DownloadBtn />}
      />
      <div className={commonStyle.pageContentContainer}>
        <div style={{ width: "100%", display: "flex", alignItems: "flex-end" }}>
          <div className="w-[80%] grid grid-cols-2 gap-4">
            <div>
              <DateInput
                label="Start Date"
                value={filterStartDate}
                disableFuturedate
                onChange={(startDate) => setFilterStartDate(startDate)}
              />
              <StyledSelect
                clearable={true}
                label="Project"
                selectOptions={
                  options && options.projects.length ? options.projects : []
                }
                value={filterProject}
                onChange={handleProjectChange}
              />
            </div>
            <div>
              <DateInput
                label="End Date"
                value={filterEndDate}
                disableFuturedate
                onChange={(endDate) => setFilterEndDate(endDate)}
              />
              <StyledMultiSelect
                label="Location"
                selectOptions={
                  options && options.locations.length ? options.locations : []
                }
                value={filterLocation}
                onChange={(location) => setFilterLocation(location)}
              />
            </div>
          </div>
          <div
            style={{
              width: "20%",
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "-20px",
            }}
          >
            {" "}
            <SimpleOutlineButton name="Apply Filter" action={applyFilter} />
          </div>
        </div>

        {requestStatus.loading ? (
          <Loader mess="Requesting report, please wait a moment..." />
        ) : requestStatus.success && detailsSuccess ? (
          <div className={commonStyle.longDataTable}>
            {filterError ? (
              <div style={{ marginTop: "20px" }}>
                <TextErrorMessage mess={filterError} />
              </div>
            ) : (
              <></>
            )}
            {/* hold the product list table */}
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Project</th>
                  <th>Code</th>
                  <th>Product Name</th>
                  <th>Location</th>
                  <th>Transaction Type</th>
                  <th>Qty Change</th>
                  <th>Unit Value</th>
                  <th>Total Value</th>
                  <th>Notes</th>
                  <th>Order Number</th>
                  <th>Batch/Serial Code</th>
                  <th>Adjust Reason</th>
                  <th>User</th>
                </tr>
              </thead>
              <tbody>
                {reportData.products.length > 0 ? (
                  reportData.products.map((item, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td>
                          <ViewDateWithTime value={item.time} />
                        </td>
                        <td className="max-w-[120px] truncate">
                          {item.projectCode + "-" + item.projectName}
                        </td>
                        <td>
                          <VariantSkuTag name={item.variantSku} />
                        </td>
                        <td>{item.productName}</td>

                        <td>
                          <Tag name={item.locationName} color="gray" />
                        </td>
                        <td>
                          {item.type === "po" ? (
                            <span className="text-sm px-2 py-1 bg-green-50 text-green-500 rounded-full">
                              Purchase
                            </span>
                          ) : item.type === "so" ? (
                            <span className="text-sm px-2 py-1 bg-blue-50 text-brandColor rounded-full">
                              Sale
                            </span>
                          ) : item.type === "RECEIVESTOCK" ? (
                            <span className="text-sm px-2 py-1 bg-green-50 text-green-500 rounded-full">
                              Receive Stock
                            </span>
                          ) : item.type === "STOCKDEDUCTION" ? (
                            <span className="text-sm px-2 py-1 bg-blue-50 text-brandColor rounded-full">
                              Deduct Stock
                            </span>
                          ) : item.type === "transferin" ? (
                            <span className="text-sm px-2 py-1 bg-green-50 text-green-500 rounded-full">
                              Transfer In
                            </span>
                          ) : item.type === "transferout" ? (
                            <span className="text-sm px-2 py-1 bg-blue-50 text-brandColor rounded-full">
                              Transfer Out
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                        <td>
                          {item.type === "po"
                            ? "+" + item.qty
                            : item.type === "so"
                            ? "-" + item.qty
                            : item.type === "RECEIVESTOCK"
                            ? "+" + item.qty
                            : item.type === "STOCKDEDUCTION"
                            ? item.qty
                            : item.type === "transferin"
                            ? "+" + item.qty
                            : item.type === "transferout"
                            ? "-" + item.qty
                            : item.qty}
                        </td>
                        <td>
                          <DollarTag />
                          {item.unit_cost_or_price}
                        </td>
                        <td>
                          <DollarTag />
                          {item.totalValue}
                        </td>
                        <td>{item.notes}</td>
                        <td>{item.orderNumber}</td>
                        <td> <BatchCell batchCodes={item.batchCodes} /></td>
                        <td>{item.reasonName ? item.reasonName : ""}</td>
                        <td>
                          {item.creatorFirstName}{" "}
                          {item.creatorLastName ? item.creatorLastName : ""}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td>
                      <p>There are no data available.</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {reportData.products.length > 0 ? (
              <Pagination
                totalPageNum={reportData.total_pages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : requestStatus.error ? (
          <ErrorMessage mess="Requesting report data failed, please try again later." />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
