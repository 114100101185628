import React, { useEffect, useState } from "react";
import { FaFileCsv } from "react-icons/fa";
import { useSelector } from "react-redux";
import { getTemplateListRequest } from "../../../api/pdfTemplatesServices";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  EditButton,
  ScreenTitle
} from "../../../components/editComponents";
import {
  ErrorMessage,
  Loader,
  Modal,
  SimpleDialog
} from "../../../components/viewComponents";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";
import UploadTemplateForm from "./UploadTemplateForm";

export default function TemplatesScreen() {
  useDocumentTitle("Templates");
  // set the Modal

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
      "Content-type": "application/json",
    },
  };

  //////////////////////////////////////////////////////////////////////////////

  const [templates, setTemplates] = useState([]);
  const [updateListCount, setUpdateListCount] = useState(0);

  const [editModal, setEditModal] = useState(false);

  const [updateDialog, setUpdateDialog] = useState(false);

  const [editedTemplate, setEditTemplate] = useState();

  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  //////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    const getList = async () => {
      try {
        setRequestStatus((preState) => ({
          ...preState,
          loading: true,
        }));
        const responseData = await getTemplateListRequest(config);

        if (responseData.data.success) {
          setTemplates(responseData.data.data);
          setRequestStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
            error: "",
          }));
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };
    getList();
  }, [updateListCount]);

  const editTemplateModal = (item) => {
    setEditTemplate(item);
    setEditModal(true);
  };


  const updateTemplateSuccess = async () => {
    setEditModal(false);
    setUpdateDialog(true);
    setEditTemplate();
    setUpdateListCount((preState) => preState + 1);
  };

  return (
    <div className={commonStyle.pageContainer}>

      <Modal
        title="Update Template"
        isModalOpen={editModal}
        closeModal={() => setEditModal(false)}
        content={
          <UploadTemplateForm
            templateId={editedTemplate ? editedTemplate.id : ""}
            type={editedTemplate? editedTemplate.name : ""}
            onSuccess={updateTemplateSuccess}
          />
        }
      />

      <SimpleDialog
        title="Update Template"
        successMessage="Template updated successfully"
        isDialogOpen={updateDialog}
        closeDialog={() => setUpdateDialog(false)}
        loading={false}
        loadingMessage="Processing request..."
        success={true}
        error={""}
        btnValue="Confirm"
        confirmAction={() => setUpdateDialog(false)}
      />

      <Breadcrumbs screenName="Export PDF Templates" />
      <ScreenTitle title="Export PDF Templates" />

      <div className={commonStyle.pageContentContainer}>
        {requestStatus.loading ? (
          <Loader mess="Requesting template list, please wait a moment.." />
        ) : requestStatus.success ? (
          <div className={commonStyle.shortDataTable}>
            {/* hold the tax rates table */}
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Template Name</th>
                  <th>Description</th>
                  <th>Template File</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {templates.length > 0 ? (
                  templates.map((item, index) => (
                    <tr key={index}>
                    
                      <td>
                        {item.name === "packingSlip"
                          ? "Packing Slip"
                          : item.name === "invoice"
                          ? "Invoice (Sale Order)"
                          : item.name === "purchaseOrder"
                          ? "Purchase Order"
                          : "Default"}
                      </td>
                      <td>{item.description}</td>
                      <td>
                        {" "}
                        <div className="flex ">
                          <FaFileCsv className="text-brandColor w-5 h-5 mr-2" />{" "}
                          <a
                            href={item.fileUrl}
                            download={item.name}
                            target="_blank"
                            rel="noreferrer"
                            className={""}
                          >
                            <p className="hover:underline hover:text-brandColor">
                            {item.name === "packingSlip"
                          ? "Packing Slip Template"
                          : item.name === "invoice"
                          ? "Invoice (Sale Order) Template"
                          : item.name === "purchaseOrder"
                          ? "Purchase Order Template"
                          : "Default"}
                            </p>
                          </a>
                        </div>
                      </td>

                      <td>
                        <div className={commonStyle.actionsGrop}>
                          <EditButton action={() => editTemplateModal(item)} />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      There are no template available.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        ) : requestStatus.error ? (
          <ErrorMessage mess={requestStatus.error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
