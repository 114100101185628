import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateEmailTemplateRequest } from "../../actions/orgSettingsActions";
import { StyledTextarea, StyledInput } from "../../components/inputFields";
import {
  EditButton,
  SimpleBlueButton,
  LoadingButton,
} from "../../components/editComponents";
import {
  ViewLabel,
  TipsText,
  SuccessText,
  ErrorText,
  SubTitle,
} from "../../components/viewComponents";
import settingsStyle from "./settingsStyle.module.css";
import { RESET_UPDATE_EMAIL_TEMPLATE_STATUS } from "../../constants/orgSettingsConstants";

export default function EmailTemplateForm({ module, content, subject }) {
  const dispatch = useDispatch();

  const [templateContent, setTemplateContent] = useState(content);
  const [templateSubject, setTemplateSubject] = useState(subject);

  const [editModel, setEditModel] = useState(false);
  // set the Modal\\
  useEffect(() => {
    setTemplateContent(content);
    setTemplateSubject(subject);
    setEditModel(false);
    dispatch({ type: RESET_UPDATE_EMAIL_TEMPLATE_STATUS });
  }, [dispatch, module, content, subject]);

  const [formErrors, setFormErrors] = useState({});

  const {
    loading: updateLoading,
    success: updateSuccess,
    error: updateError,
  } = useSelector((state) => state.updateEmailTemplate);

  const editEmailContent = () => {
    setEditModel(true);
  };

  // check input validation
  const checkValidation = () => {
    const hasErrors = validate();

    if (Object.keys(hasErrors).length === 0) {
      let submittedValues = {
        module: module,
        content: templateContent,
        subject: templateSubject,
      };
      dispatch(updateEmailTemplateRequest(submittedValues));
    } else {
      setFormErrors(hasErrors);
    }
  };

  const validate = () => {
    const errors = {};
    if (!templateContent) {
      errors.content = "Email template content is required.";
    }
    if (!templateSubject) {
      errors.subject = "Email template subject is required.";
    }
    return errors;
  };

  return (
    <div>
      <div className={settingsStyle.templatesTitleCon}>
        <h2>Email Template</h2>
        {editModel ? <></> : <EditButton action={editEmailContent} />}
      </div>
      <TipsText text="The template content will be automatically filled in when you send an email to your customers or suppliers." />
      {editModel ? (
        <div className={settingsStyle.templatesConten}>
          <StyledInput
            label="Email Subject"
            type="text"
            name="templateSubject"
            autofocus="autofocus"
            value={templateSubject}
            onChange={(templateSubject) => setTemplateSubject(templateSubject)}
            error={formErrors.subject}
          />
          <StyledTextarea
            label="Email Content"
            type="text"
            name="notes"
            presetRows="16"
            value={templateContent}
            onChange={(templateContent) => setTemplateContent(templateContent)}
            error={formErrors.content}
          />
          {updateSuccess ? (
            <SuccessText mess="Template saved successfully." />
          ) : updateError ? (
            <ErrorText mess={updateError} />
          ) : (
            <></>
          )}
          <div className="w-full mt-6">
            {updateLoading ? (
              <LoadingButton name="Processing request..." />
            ) : (
              <SimpleBlueButton name="Save" action={checkValidation} />
            )}
          </div>
        </div>
      ) : (
        <div className={settingsStyle.templatePreviewCon}>
          <StyledInput
            label="Email Subject"
            type="text"
            name="templateSubject"
            value={templateSubject}
            isReadOnly
          />

          <StyledTextarea
            label="Email Content"
            type="text"
            name="notes"
            presetRows="16"
            value={templateContent}
            isReadOnly
          />
        </div>
      )}
    </div>
  );
}
