import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import commonStyle from "../../../style/commonStyle.module.css";
import { ScreenTitle } from "../../../components/editComponents";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  ErrorMessage,
  InsufficientStockSaleOrderDialog,
  Loader,
} from "../../../components/viewComponents";
import {
  SimpleDialog,
  ProceedSaleOrderDialog,
} from "../../../components/viewComponents";
import { convtPrice, generateOrderNumber } from "../../../functions/functions";
import {
  saleOrderOptionsRequest,
  createNewSaleOrderRequest,
  saleOrderDetailsRequest,
} from "../../../actions/saleOrderActions";
import SaleOrderForm from "./SaleOrderForm";
import useDocumentTitle from "../../../useDocumentTitle";

export default function NewSaleOrderScreen() {
  useDocumentTitle("New Sale Order");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const cloneOrderId = location.state;
  useEffect(() => {
    dispatch(saleOrderOptionsRequest());
    if (cloneOrderId) {
      dispatch(saleOrderDetailsRequest(cloneOrderId, "clone", "saleOrder"));
    }
  }, [dispatch, cloneOrderId]);

  const { loading, options, error, success } = useSelector(
    (state) => state.saleOrderOptions
  );

  const {
    loading: cloneLoading,
    orderInfo,
    error: cloneError,
    success: cloneSuccess,
  } = useSelector((state) => state.saleOrderDetails);

  const {
    loading: createLoading,
    orderId,
    error: createError,
    success: createSuccess,
  } = useSelector((state) => state.createSaleOrder);

  const newOrderInfo = () => {
    let order_num = "";
    let order_pattern = options.soSettings.find((x) => x.settingName === "sale_order_number_rule");
    order_num = order_pattern ? generateOrderNumber(order_pattern.settingValue) : "";

    let tax_included = false;
    let tax_rule = options.soSettings.find((x) => x.settingName === "sale_order_default_tax_rule");
    if(tax_rule){
      tax_included = tax_rule.settingValue === "inclusive" ? true : false;
    }

    let data = {
      orderNumber: order_num,
      customer: "",
      taxRate: "",
      taxIncluded: tax_included,
      currency: "",
      customerName: "",
      customerBillingName: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      postalAddress: "",
      billingAddress: "",
      location: userDetailsSuccess
        ? options.locationOptions.filter(
            (x) => x.id === userDetails.data.locationId
          )[0]
        : "",
      paymentTerm: 0,
      priceTier: "",
      project: "",
      isNeedShipping: false,
      notes: "",
    };
    return data;
  };

  const newOrderSummary = () => {
    return {
      productsTotal: 0,
      additionalCostTotal: 0,
    };
  };

  const cloneOrderBasicInfo = () => {
    let order_num = "";
    let order_pattern = options.soSettings.find((x) => x.settingName === "sale_order_number_rule");
    order_num = order_pattern ? generateOrderNumber(order_pattern.settingValue) : "";

    let data = {
      orderNumber: order_num,
      customer: options.customerOptions.filter(
        (x) => x.id === orderInfo.basicInfo.customerId
      )[0],
      taxRate: options.taxrateOptions.filter(
        (x) => x.rate === orderInfo.basicInfo.taxrate
      )[0],
      taxIncluded: orderInfo.basicInfo.taxIncluded,
      currency: orderInfo.basicInfo.currency,
      customerName: orderInfo.basicInfo.customerName
        ? orderInfo.basicInfo.customerName
        : "",
      customerBillingName: orderInfo.basicInfo.customerBillingName
      ? orderInfo.basicInfo.customerBillingName
      : "",
      firstName: orderInfo.basicInfo.firstName
        ? orderInfo.basicInfo.firstName
        : "",
      lastName: orderInfo.basicInfo.lastName
        ? orderInfo.basicInfo.lastName
        : "",
      email: orderInfo.basicInfo.email ? orderInfo.basicInfo.email : "",
      phone: orderInfo.basicInfo.phone ? orderInfo.basicInfo.phone : "",
      postalAddress: orderInfo.basicInfo.shippingAddress
        ? orderInfo.basicInfo.shippingAddress
        : {},
      billingAddress: orderInfo.basicInfo.billingAddress
        ? orderInfo.basicInfo.billingAddress
        : {},
      location: options.locationOptions.filter(
        (x) => x.id === orderInfo.basicInfo.locationId
      )[0],
      paymentTerm: orderInfo.basicInfo.paymentTerm,
      priceTier: options.priceTierOptions.filter(
        (x) => x.id === orderInfo.basicInfo.priceTierId
      )[0],
      project: "",
      isNeedShipping: orderInfo.basicInfo.isNeedShipping,
      notes: orderInfo.basicInfo.notes ? orderInfo.basicInfo.notes : "",
    };

    return data;
  };

  const cloneOrderItems = () => {
    let orderItemsCopy = [...orderInfo.orderItems];
    orderItemsCopy.forEach((x) => {
      x.consumeBatchNumbers = [];
      x.consumeSerialNumbers = [];
    });

    return orderItemsCopy;
  };

  // new order submit values
  const [newOrderValues, setNewOrderValues] = useState({});
  // clone product
  const [cloneDialog, setCloneDialog] = useState(true);
  //


  // add new pdialog
  const [createDraftDialog, setCreateDraftDialog] = useState(false);
  const [createApprovedDialog, setCreateApprovedDialog] = useState(false);
  const [insufficientStockDialog, setInsufficientStockDialog] = useState(false);


  ////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////// submit //////////////////////////////////////////////

  const openCreateSaleOrderDialog = (values, status) => {
    let isPackageSale = false;
    let isApproved = false;
    let isInsufficientStock = false;

    if (values.orderItems.some((item) => item.type === "package")) {
      isPackageSale = true;
    }

    if (status === "Approved") {
      isApproved = true;
    }

    let orderItemValues = [];

    values.orderItems.forEach((prod) => {
      if (
        status === "Approved" &&
        prod.type !== "package" &&
        Number(prod.quantity) > Number(prod.stockOnHand)
      ) {
        isInsufficientStock = true;
      }

      let eachItem = {
        type: prod.type,
        packageId: prod.packageId,
        packageName: prod.packageName,
        productType: prod.productType,
        productName: prod.productName,
        variant: prod.variant,
        famid: prod.famid,
        variantCode: prod.variantCode,
        variantSku: prod.variantSku,
        quantity: prod.quantity,
        price: prod.unitPrice,
        discount: prod.discount,
        totalPrice: prod.totalPrice,
        serialized: prod.serialized,
        batchTracked: prod.batchTracked,
        consumeSerialNumbers: prod.consumeSerialNumbers,
        consumeBatchNumbers: prod.consumeBatchNumbers,
      };

      orderItemValues.push(eachItem);
    });

    let orderTotalExcltax;
    let orderTotalIncltax;
    let orderTax;

    if (values.orderInfo.taxIncluded) {
      orderTotalExcltax = convtPrice(
        (Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal)) /
          (1 + values.orderInfo.taxRate.rate / 100)
      );

      orderTotalIncltax = convtPrice(
        Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal)
      );

      orderTax = convtPrice(
        Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal) -
          (Number(values.orderSummary.productsTotal) +
            Number(values.orderSummary.additionalCostTotal)) /
            (1 + values.orderInfo.taxRate.rate / 100)
      );
    } else {
      orderTotalExcltax = convtPrice(
        Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal)
      );
      orderTotalIncltax = convtPrice(
        Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal) +
          (Number(values.orderSummary.productsTotal) +
            Number(values.orderSummary.additionalCostTotal)) *
            (values.orderInfo.taxRate.rate / 100)
      );
      orderTax = convtPrice(
        (Number(values.orderSummary.productsTotal) +
          Number(values.orderSummary.additionalCostTotal)) *
          (values.orderInfo.taxRate.rate / 100)
      );
    }

    if (typeof values.orderInfo.postalAddress === "object") {
      values.orderInfo.postalAddress = JSON.stringify(
        values.orderInfo.postalAddress
      );
    }
    if (typeof values.orderInfo.billingAddress === "object") {
      values.orderInfo.billingAddress = JSON.stringify(
        values.orderInfo.billingAddress
      );
    }
    let saleOrderValues = {
      isPackageSale: isPackageSale,
      approved: isApproved,
      status: status,
      orderInfo: {
        orderNumber: values.orderInfo.orderNumber,
        customerId: values.orderInfo.customer
          ? values.orderInfo.customer.id
          : "",
        taxrateId: values.orderInfo.taxRate.id,
        taxrate: values.orderInfo.taxRate.rate,
        taxIncluded: values.orderInfo.taxIncluded,
        currency: values.orderInfo.currency,
        locationId: values.orderInfo.location.id,
        customerName: values.orderInfo.customerName,
        customerBillingName: values.orderInfo.customerBillingName,
        firstName: values.orderInfo.firstName,
        lastName: values.orderInfo.lastName,
        email: values.orderInfo.email,
        phone: values.orderInfo.phone,
        postalAddress: values.orderInfo.postalAddress,
        billingAddress: values.orderInfo.billingAddress,
        paymentTerm: values.orderInfo.paymentTerm,
        priceTierId: values.orderInfo.priceTier
          ? values.orderInfo.priceTier.id
          : "",
        projectId: values.orderInfo.project
        ? values.orderInfo.project.id
        : "",
        isNeedShipping: values.orderInfo.isNeedShipping ? 1 : 0,
        notes: values.orderInfo.notes,
        // calcute order total amount
        orderTotalExcltax: orderTotalExcltax,
        orderTotalIncltax: orderTotalIncltax,
        orderTax: orderTax,
      },
      orderItems: orderItemValues,
      costs: values.costs,
    };

    setNewOrderValues(saleOrderValues);

    if (!isApproved) {
      setCreateDraftDialog(true);
      dispatch(createNewSaleOrderRequest(saleOrderValues));
    } else {
      if (isInsufficientStock) {
        setInsufficientStockDialog(true);
      } else {
        setCreateApprovedDialog(true);
      }
    }
  };

  const addNewSaleOrder = () => {
    dispatch(createNewSaleOrderRequest(newOrderValues));
  };

  const proceedInsufficiectStockOrder = (saveType) => {

    let orderValuesCopy = {...newOrderValues};
    orderValuesCopy.status = saveType;
    orderValuesCopy.approved = false;
    dispatch(createNewSaleOrderRequest(orderValuesCopy));
  };

  const closeCreateDialogWithSuccess = () => {
    navigate(`/sale/order/preview/${orderId}`, {
      replace: true,
      from: location,
    });
  };

  return (
    <div className={commonStyle.pageContainer}>
      {cloneOrderId ? (
        <SimpleDialog
          title={"Clone Sale Order"}
          successMessage="Order cloning was successful."
          isDialogOpen={cloneDialog}
          closeDialog={() => setCloneDialog(false)}
          loading={cloneLoading}
          loadingMessage="Cloning sale order details..."
          success={cloneSuccess}
          error={cloneError}
          confirmAction={() => setCloneDialog(false)}
        />
      ) : (
        <></>
      )}

      <SimpleDialog
        title={"Create New Sale Order"}
        successMessage="New order created successfully."
        isDialogOpen={createDraftDialog}
        closeDialog={() => setCreateDraftDialog(false)}
        loading={createLoading}
        loadingMessage="Processing request..."
        success={createSuccess}
        error={createError}
        confirmAction={closeCreateDialogWithSuccess}
      />

      <ProceedSaleOrderDialog
        title={"Create New Sale Order"}
        successMessage="New order added as approved successfully."
        isDialogOpen={createApprovedDialog}
        closeDialog={() => setCreateApprovedDialog(false)}
        closeDialogWithSuccess={closeCreateDialogWithSuccess}
        loading={createLoading}
        loadingMessage="Processing request..."
        success={createSuccess}
        error={createError}
        confirmAction={addNewSaleOrder}
        text1={"The stock level will be deducted."}
      />

      <InsufficientStockSaleOrderDialog
        title={"Create New Sale Order"}
        successMessage="New order created successfully."
        isDialogOpen={insufficientStockDialog}
        closeDialog={() => setInsufficientStockDialog(false)}
        closeDialogWithSuccess={closeCreateDialogWithSuccess}
        loading={createLoading}
        loadingMessage="Processing request..."
        success={createSuccess}
        error={createError}
        confirmAction={proceedInsufficiectStockOrder}
      />

      <Breadcrumbs screenName={"New Sale Order"} />
      <ScreenTitle title={"New Sale Order"} status="New" />
      <div className={commonStyle.pageContentContainer}>
        {userDetailsSuccess ? (
          loading ? (
            <Loader mess="Requesting data, please wait a moment..." />
          ) : success ? (
            cloneOrderId ? (
              cloneLoading ? (
                <Loader mess="Cloning order details, please wait a moment..." />
              ) : cloneSuccess ? (
                <SaleOrderForm
                  initialOrderInfo={cloneOrderBasicInfo}
                  initialOrderItems={cloneOrderItems}
                  initialCosts={orderInfo.orderCosts}
                  initialOrderSummary={orderInfo.orderSummary}
                  submit={openCreateSaleOrderDialog}
                  type="clone"
                />
              ) : (
                <ErrorMessage mess={cloneError} />
              )
            ) : (
              <SaleOrderForm
                initialOrderInfo={newOrderInfo}
                initialOrderItems={[]}
                initialOrderSummary={newOrderSummary}
                initialCosts={[]}
                submit={openCreateSaleOrderDialog}
                type="new"
              />
            )
          ) : (
            <ErrorMessage mess={error} />
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
