import axios from "axios";
import {serviceMiddleware} from "./serviceMiddleware";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;


 export const getProjectListRequest = async (pageNum, pageSize, config) => {

   try {
     const response = await axios.get(SERVER_URL + `/settings/project/list/?pageNum=${pageNum}&pageSize=${pageSize}`, config);

     return response;
   } catch (error) {
    throw error;
   }
 
 };

 export const createProjectRequest = async (values, config) => {

  try {
    const response = await axios.post(SERVER_URL + `/settings/project/create`, values, config);
    serviceMiddleware(response);
    return response;
  } catch (error) {
    throw error;
  }

};

export const updateProjectRequest = async (projectId, values, config) => {

  try {
    const response = await axios.post(SERVER_URL + `/settings/project/update/${projectId}`, values, config);
    return response;
  } catch (error) {
    throw error;
  }

};
export const deleteProjectRequest = async (projectId, config) => {

  try {
    const response = await axios.post(SERVER_URL + `/settings/project/delete/${projectId}`, {}, config);
    return response;
  } catch (error) {
    throw error;
  }

};





