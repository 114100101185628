import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userlogout } from "../../actions/authAction.js";
import { productOptionsRequest } from "../../actions/productActions";
import {
  salesByProductReportRequest
} from "../../api/reportingServices";
import Breadcrumbs from "../../components/Breadcrumbs";
import Pagination from "../../components/Pagination";
import {
  LoadingButton,
  ScreenTitle,
  SimpleBlueButton,
  SimpleOutlineButton,
} from "../../components/editComponents";
import {
  DateInput,
  StyledMultiSelect
} from "../../components/inputFields";
import {
  DollarTag,
  ErrorMessage,
  Loader,
  Tag,
  TextErrorMessage,
  VariantSkuTag,
  VariantTag
} from "../../components/viewComponents";
import { formatReportSummaryDate } from "../../functions/functions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import { CsvWriter } from "./CsvWriter.js";

export default function SalesSummaryByProductScreen() {
  useDocumentTitle("Sales Summary By Product Report");
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );
  const {
    loading: optionsLoading,
    options,
    error: optionsError,
    success: optionsSuccess,
  } = useSelector((state) => state.productOptions);

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  // pagination

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);

  const [reportData, setReportData] = useState([]);
  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [downloadStatus, setDownloadStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };


  const [filterLocation, setFilterLocation] = useState([]);
  const [filterCategory, setFilterCategory] = useState([]);

  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterError, setFilterError] = useState("");

  useEffect(() => {
    if (!options) {
      dispatch(productOptionsRequest());
    }
    if (options && !Object.keys(options).length > 0) {
      dispatch(productOptionsRequest());
    }
  }, [dispatch, options]);

  const applyFilter = () => {
    getProducts();
  };

  /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////
  const downloadFile = async () => {
    // get full stocktake list

    let startTimestamp = filterStartDate
      ? Math.floor(filterStartDate.setHours(0, 0, 0, 0) / 1000)
      : "";
    let endTimestamp = filterEndDate
      ? Math.floor(filterEndDate.setHours(23, 59, 59, 999) / 1000)
      : "";

    if (startTimestamp > endTimestamp) {
      setFilterError("The start date is greater than end date.");
      return;
    }

    let stockListdata = [];

    let selectedFilterCategory = [];
    let selectedFilterLocation = [];

    if (filterCategory.length > 0) {
      filterCategory.forEach((cate) => {
        selectedFilterCategory.push(cate.id);
      });
    }

    if (filterLocation.length > 0) {
      filterLocation.forEach((loc) => {
        selectedFilterLocation.push(loc.id);
      });
    }

    try {
      setDownloadStatus((preState) => ({
        ...preState,
        loading: true,
      }));

      const responseData = await salesByProductReportRequest(
        0,
        99999999999,
        selectedFilterCategory.join(),
        selectedFilterLocation.join(),
        startTimestamp,
        endTimestamp,
        config
      );

      if (responseData.success) {
        stockListdata = responseData.data.products;

        setDownloadStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      }else if(responseData.error === -3){
        dispatch(userlogout());
      }
    } catch (error) {
      setDownloadStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }

    //get csv file
    let csvWriter = new CsvWriter([
      "Date",
      "Code",
      "ProductName",
      "Variant",
      "Category",
      "Location",
      "SoldQuantity",
      "TotalAmount",
    ]);
    for (let line of stockListdata) {
      csvWriter.addLine([
        line.date
        ? formatReportSummaryDate(
          line.date,
          userDetails.data.orgInfo.date_format,
          userDetails.data.orgInfo.time_zone
        )
        : "",
        line.variantSku,
        line.productName,
        line.variant,
        line.category ? line.category : "",
        line.locationName,
        line.totalSoldCount,
        line.totalSumPriceInclTax,
       
      ]);
    }
    const csvString = csvWriter.content;

    // create a download link
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "sales-summary-by-product-report.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  /////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getProducts();
  }, [pageNum]);


  const getProducts = async () => {
    let startTimestamp = filterStartDate
      ? Math.floor(filterStartDate.setHours(0, 0, 0, 0) / 1000)
      : "";
    let endTimestamp = filterEndDate
      ? Math.floor(filterEndDate.setHours(23, 59, 59, 999) / 1000)
      : "";

    if (startTimestamp > endTimestamp) {
      setFilterError("The start date is greater than end date.");
      return;
    }

    let selectedFilterCategory = [];
    let selectedFilterLocation = [];

    if (filterCategory.length > 0) {
      filterCategory.forEach((cate) => {
        selectedFilterCategory.push(cate.id);
      });
    }

    if (filterLocation.length > 0) {
      filterLocation.forEach((loc) => {
        selectedFilterLocation.push(loc.id);
      });
    }

    try {
      setRequestStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await salesByProductReportRequest(
        pageNum,
        pageSize,
        selectedFilterCategory.join(),
        selectedFilterLocation.join(),
        startTimestamp,
        endTimestamp,
        config
      );

      if (responseData.success) {
        setReportData(responseData.data);
        setFilterError("");
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      }else if(responseData.error === -3){
        dispatch(userlogout());
      }
    } catch (error) {
      setRequestStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const DownloadBtn = () => {
    return (
      <div style={{ width: "auto" }}>
        {downloadStatus.loading ? (
          <LoadingButton name="Downloading.." />
        ) : (
          <SimpleBlueButton name="Download" action={downloadFile} />
        )}
      </div>
    );
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Sales Summary By Product Report" />
      <ScreenTitle
        title="Sales Summary By Product Report"
        buttonComponent={<DownloadBtn />}
      />
      <div className={commonStyle.pageContentContainer}>
        <div style={{ width: "100%", display: "flex", alignItems: "flex-end" }}>
          <div
            style={{
              width: "80%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "45%" }}>
              <DateInput
                label="Start Date"
                value={filterStartDate}
                disableFuturedate
                onChange={(startDate) => setFilterStartDate(startDate)}
              />
              <StyledMultiSelect
                label="Location"
                selectOptions={optionsSuccess ? options.location : []}
                value={filterLocation}
                onChange={(location) => setFilterLocation(location)}
              />
            </div>
            <div style={{ width: "45%" }}>
              <DateInput
                label="End Date"
                value={filterEndDate}
                disableFuturedate
                onChange={(endDate) => setFilterEndDate(endDate)}
              />
                <StyledMultiSelect
                label="Category"
                selectOptions={optionsSuccess ? options.category : []}
                value={filterCategory}
                onChange={(category) => setFilterCategory(category)}
              />
            </div>
          </div>
          <div
            style={{
              width: "20%",
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "-20px",
            }}
          >
            <SimpleOutlineButton name="Apply Filter" action={applyFilter} />
          </div>
        </div>

        {requestStatus.loading ? (
          <Loader mess="Requesting report, please wait a moment..." />
        ) : requestStatus.success && userDetailsSuccess ? (
          <div className={commonStyle.longDataTable}>
            {filterError ? (
              <div style={{ marginTop: "20px" }}>
                <TextErrorMessage mess={filterError} />
              </div>
            ) : (
              <></>
            )}
            {/* hold the product list table */}
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Code</th>
                  <th>Product Name</th>
                  <th>Variant</th>
                  <th>Category</th>
                  <th>Location</th>
                  {/* <th>Order Quantity</th> */}
                  <th>Sold Quantity</th>
                  <th>Total Amount</th>
                </tr>
              </thead>
              <tbody>
                {reportData.products.length > 0 ? (
                  reportData.products.map((item, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td>
                          {formatReportSummaryDate(
                            item.date,
                            userDetails.data.orgInfo.date_format,
                            userDetails.data.orgInfo.time_zone
                          )}
                        </td>
                        <td><VariantSkuTag name={item.variantSku} /></td>
                        <td>{item.productName}</td>
                        <td>{ item.variant && <VariantTag name={item.variant} /> }</td>
                        <td>{item.category}</td>
                     
                        <td>
                          <Tag name={item.locationName} color="gray" />
                        </td>
                        {/* <td>
                          {item.totalOrders}
                        </td> */}
                        <td>
                          {item.totalSoldCount}
                        </td>
                        <td>
                          <DollarTag />{item.totalSumPriceInclTax}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td>
                      <p>There are no data in selected period.</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {reportData.products.length > 0 ? (
              <Pagination
                totalPageNum={reportData.total_pages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : requestStatus.error ? (
          <ErrorMessage mess="Requesting report data failed, please try again later." />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
