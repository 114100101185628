import axios from "axios";
import { authMiddleware } from "./authMiddleware";
import {
  ORG_LOCATION_LIST_REQUEST,
  ORG_LOCATION_LIST_SUCCESS,
  ORG_LOCATION_LIST_FAIL,
  ORG_DEP_LIST_REQUEST,
  ORG_DEP_LIST_SUCCESS,
  ORG_DEP_LIST_FAIL,
  NEW_ORG_LOCATION_FAIL,
  NEW_ORG_LOCATION_REQUEST,
  NEW_ORG_LOCATION_SUCCESS,
  UPDATE_ORG_LOCATION_FAIL,
  UPDATE_ORG_LOCATION_REQUEST,
  UPDATE_ORG_LOCATION_SUCCESS,
  NEW_ORG_DEP_REQUEST,
  NEW_ORG_DEP_SUCCESS,
  NEW_ORG_DEP_FAIL,
  UPDATE_ORG_DEP_REQUEST,
  UPDATE_ORG_DEP_SUCCESS,
  UPDATE_ORG_DEP_FAIL,
  SALE_TAX_RATE_LIST_REQUEST,
  SALE_TAX_RATE_LIST_SUCCESS,
  SALE_TAX_RATE_LIST_FAIL,
  NEW_SALE_TAX_RATE_REQUEST,
  NEW_SALE_TAX_RATE_SUCCESS,
  NEW_SALE_TAX_RATE_FAIL,
  UPDATE_SALE_TAX_RATE_REQUEST,
  UPDATE_SALE_TAX_RATE_SUCCESS,
  UPDATE_SALE_TAX_RATE_FAIL,
  DELETE_ORG_LOCATION_FAIL,
  DELETE_ORG_LOCATION_REQUEST,
  DELETE_ORG_LOCATION_SUCCESS,
  DELETE_ORG_DEP_REQUEST,
  DELETE_ORG_DEP_SUCCESS,
  DELETE_ORG_DEP_FAIL,
  ORG_DETAILS_REQUEST,
  ORG_DETAILS_SUCCESS,
  ORG_DETAILS_FAIL,
  ORG_DETAILS_UPDATE_REQUEST,
  ORG_DETAILS_UPDATE_SUCCESS,
  ORG_DETAILS_UPDATE_FAIL,
  CURRENCY_LIST_REQUEST,
  CURRENCY_LIST_SUCCESS,
  CURRENCY_LIST_FAIL,
  NEW_CURRENCY_REQUEST,
  NEW_CURRENCY_SUCCESS,
  NEW_CURRENCY_FAIL,
  UPDATE_CURRENCY_REQUEST,
  UPDATE_CURRENCY_SUCCESS,
  UPDATE_CURRENCY_FAIL,
  DELETE_CURRENCY_REQUEST,
  DELETE_CURRENCY_SUCCESS,
  DELETE_CURRENCY_FAIL,
  EMAIL_TEMPLATES_LIST_REQUEST,
  EMAIL_TEMPLATES_LIST_SUCCESS,
  EMAIL_TEMPLATES_LIST_FAIL,
  UPDATE_EMAIL_TEMPLATE_REQUEST,
  UPDATE_EMAIL_TEMPLATE_SUCCESS,
  UPDATE_EMAIL_TEMPLATE_FAIL,
  EMAIL_TEMPLATES_DETAILS_REQUEST,
  EMAIL_TEMPLATES_DETAILS_SUCCESS,
  EMAIL_TEMPLATES_DETAILS_FAIL,
  CHECK_ORG_DB_STATUS_REQUEST,
  CHECK_ORG_DB_STATUS_SUCCESS,
  CHECK_ORG_DB_STATUS_FAIL,
} from "../constants/orgSettingsConstants";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

///////////////////////org details/////////////////////////////
///////////////////////////////////////////////////////////////
export const checkOrgStatusRequest = (token) => async (dispatch) => {

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    
    dispatch({ type: CHECK_ORG_DB_STATUS_REQUEST });
    axios
      .get(SERVER_URL + `/org/db_status`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({
            type: CHECK_ORG_DB_STATUS_SUCCESS,
            payload: response.data.data,
          });
        }else{
          throw response.data.message.toString();
        }
      })
      .catch((err) => {
        dispatch({
          type: CHECK_ORG_DB_STATUS_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: CHECK_ORG_DB_STATUS_FAIL,
      payload: "Connection error, please refresh the page",
    });
  }
};

export const organizationDetailsRequest = () => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    
    dispatch({ type: ORG_DETAILS_REQUEST });
    axios
      .get(SERVER_URL + `/org/details`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          let org = response.data.data;

          let orgInfo ={};
          orgInfo.id = org.id;
          orgInfo.name = org.name;
          orgInfo.country = org.country;
          orgInfo.industry = org.industry;
          orgInfo.baseCurrency = org.base_currency;
          orgInfo.dateFormat = org.date_format;
          orgInfo.timeZone = org.time_zone;
          orgInfo.logo = org.logo;
          orgInfo.adminEmail = org.adminEmail;

          dispatch({
            type: ORG_DETAILS_SUCCESS,
            payload: orgInfo,
          });
        } else {
          throw Object.assign(
            new Error("Request company details failed, please try again later."),
            { code: 822 }
         );
        }
      })
      .catch((err) => {
        dispatch({
          type: ORG_DETAILS_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: ORG_DETAILS_FAIL,
      payload: "Connection error, please refresh the page",
    });
  }
};

export const organizationUpdateDetailsRequest =
  (values) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
     

      dispatch({ type: ORG_DETAILS_UPDATE_REQUEST });
      axios
        .post(SERVER_URL + `/org/details/update`, values, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({
              type: ORG_DETAILS_UPDATE_SUCCESS,
            });
            dispatch(organizationDetailsRequest())
          } else {
            throw Object.assign(
              new Error("Update company details failed, please try again later"),
              { code: 531 }
           );
          }
        })
        .catch((err) => {
          dispatch({
            type: ORG_DETAILS_UPDATE_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: ORG_DETAILS_UPDATE_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };


///////////////////////location/////////////////////////////
////////////////////////////////////////////////////////////
// get location list
export const locationListRequest = (requestType) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {

    dispatch({ type: ORG_LOCATION_LIST_REQUEST });
    axios
      .get(SERVER_URL + `/org/location/list`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          let locations = response.data.data
          let list = [];
          if (requestType === "selectOptions"){
            for (let i = 0; i < locations.length; i++) {
              let location = {
                id: locations[i].id,
                label: locations[i].name,
                value: locations[i].name,
              };
  
              if (locations[i].is_default === 1) {
                location.isDefault = true;
              } else {
                location.isDefault = false;
              }
              list.push(location);
            }

          }else {
            for (let i = 0; i < locations.length; i++) {
              let location = {
                id: locations[i].id,
                name: locations[i].name,
                address: locations[i].address,
                description: locations[i].description,
              };
  
              if (locations[i].is_default === 1) {
                location.isDefault = true;
              } else {
                location.isDefault = false;
              }
              list.push(location);
            }

          }
          
          dispatch({
            type: ORG_LOCATION_LIST_SUCCESS,
            payload: list,
          });
        } else {
          throw Object.assign(
            new Error("Request company location list failed, please try again later."),
            { code: 802 }
         );
        }
      })
      .catch((err) => {
        dispatch({
          type: ORG_LOCATION_LIST_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: ORG_LOCATION_LIST_FAIL,
      payload: "Connection error, please refresh the page",
    });
  }
};
// add a new location
export const newLocationRequest = (values) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    

    dispatch({ type: NEW_ORG_LOCATION_REQUEST });
    axios
      .post(SERVER_URL + `/org/location/create`, values, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: NEW_ORG_LOCATION_SUCCESS });
          dispatch(locationListRequest());
        } else {
          if (response.data.error === 11) {
            throw Object.assign(
              new Error("Location name exists, please use a new name."),
              { code: 803 }
           );
          } else {
            throw Object.assign(
                new Error("create location failed, please try again later."),
                { code: 804 }
             );
          }
        }
      })
      .catch((err) => {
        dispatch({
          type: NEW_ORG_LOCATION_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: NEW_ORG_LOCATION_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const updateLocationRequest =
  (values, lid) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
     

      dispatch({ type: UPDATE_ORG_LOCATION_REQUEST });
      axios
        .put(SERVER_URL + `/org/location/${lid}`, values, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: UPDATE_ORG_LOCATION_SUCCESS });
            dispatch(locationListRequest());
          } else {
            if (response.data.error === 11) {
              throw Object.assign(
                new Error("Location name exists, please use a new name."),
                { code: 803 }
             );
            } else {
              throw Object.assign(
                new Error("Update location failed, please try again later."),
                { code: 805 }
             );
            }
          }
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_ORG_LOCATION_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: UPDATE_ORG_LOCATION_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

  export const deleteLocationRequest =
  (lid) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      

      dispatch({ type: DELETE_ORG_LOCATION_REQUEST });
      axios
        .delete(SERVER_URL + `/org/location/${lid}`, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: DELETE_ORG_LOCATION_SUCCESS });
            dispatch(locationListRequest());
          } else {
            throw Object.assign(
              new Error("Delete location failed, please try again later."),
              { code: 806 }
           );
          }
        })
        .catch((err) => {
          dispatch({
            type: DELETE_ORG_LOCATION_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: DELETE_ORG_LOCATION_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

///////////////////// sale tax rate  ///////////////////////
/////////////////////////////////////////////////////////////
export const saleTaxRateListRequest = () => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    

    dispatch({ type: SALE_TAX_RATE_LIST_REQUEST });
    axios
      .get(SERVER_URL + `/org/sale_tax_rate/list`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          let list = [];
          for (let i = 0; i < response.data.data.length; i++) {
            let rate = {
              id: response.data.data[i].id,
              taxCode: response.data.data[i].code,
              taxName: response.data.data[i].name,
              taxRate: response.data.data[i].rate,
            };

            if (response.data.data[i].is_default === 1) {
              rate.isDefault = true;
            } else {
              rate.isDefault = false;
            }

            list.push(rate);
          }

          dispatch({
            type: SALE_TAX_RATE_LIST_SUCCESS,
            payload: list,
          });
        } else {
          throw Object.assign(
            new Error("Request sale tax rate list failed, please try again later."),
            { code: 807 }
         );
        }
      })
      .catch((err) => {
        dispatch({
          type: SALE_TAX_RATE_LIST_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: SALE_TAX_RATE_LIST_FAIL,
      payload: "connection error, please try again later",
    });
  }
};

export const newSaleTaxRateRequest = (values) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    

    dispatch({ type: NEW_SALE_TAX_RATE_REQUEST });
    axios
      .post(SERVER_URL + `/org/sale_tax_rate/create`, values, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: NEW_SALE_TAX_RATE_SUCCESS });
          dispatch(saleTaxRateListRequest());
        } else {
          if (response.data.error === 11) {
            throw Object.assign(
              new Error("Tax code exists, please use a new code."),
              { code: 808 }
           );
          }else {
            throw Object.assign(
              new Error("Create new sale tax rate failed, please try again later."),
              { code: 809 }
           );
          }
        } 
      })
      .catch((err) => {
        dispatch({
          type: NEW_SALE_TAX_RATE_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: NEW_SALE_TAX_RATE_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const updateSaleTaxRateRequest =
  (values, rateid) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      

      dispatch({ type: UPDATE_SALE_TAX_RATE_REQUEST });
      axios
        .put(SERVER_URL + `/org/sale_tax_rate/${rateid}`, values, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: UPDATE_SALE_TAX_RATE_SUCCESS });
            dispatch(saleTaxRateListRequest());
          } else {
            if (response.data.error === 11){
              throw Object.assign(
                new Error("Tax code exists, please use a new code."),
                { code: 808 }
             );
            } else {
              throw Object.assign(
                new Error("Update new sale tax rate failed, please try again later."),
                { code: 810 }
             );
              
            }
          }
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_SALE_TAX_RATE_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: UPDATE_SALE_TAX_RATE_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };


///////////////////////department/////////////////////////////
////////////////////////////////////////////////////////////
// get department list
export const departmentListRequest = () => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
   

    dispatch({ type: ORG_DEP_LIST_REQUEST });
    axios
      .get(SERVER_URL + `/org/department/list`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({
            type: ORG_DEP_LIST_SUCCESS,
            payload: response.data.data,
          });
        } else {
          throw Object.assign(
            new Error("Request department list failed, please tray again later."),
            { code: 811 }
         );

        }
      })
      .catch((err) => {
        dispatch({
          type: ORG_DEP_LIST_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: ORG_DEP_LIST_FAIL,
      payload: "Connection error, please tray again later.",
    });
  }
};

export const newDepartmentRequest = (values) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
   

    dispatch({ type: NEW_ORG_DEP_REQUEST });
    axios
      .post(SERVER_URL + `/org/department/create`, values, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: NEW_ORG_DEP_SUCCESS });
          dispatch(departmentListRequest());
        } else {
          if (response.data.error === 11){
            throw Object.assign(
              new Error("Department name exists, please use a new name."),
              { code: 812 }
           );

          } else {
            throw Object.assign(
              new Error("Create new department failed, please try again later."),
              { code: 813 }
           );
          }
        } 
      })
      .catch((err) => {
        dispatch({
          type: NEW_ORG_DEP_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: NEW_ORG_DEP_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const updateDepartmentRequest =
  (values, depid) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      

      dispatch({ type: UPDATE_ORG_DEP_REQUEST });
      axios
        .put(SERVER_URL + `/org/department/${depid}`, values, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: UPDATE_ORG_DEP_SUCCESS });
            dispatch(departmentListRequest());
          } else {
            if (response.data.error === 11){
              throw Object.assign(
                new Error("Department name exists, please use a new name."),
                { code: 812 }
             );
            } else {
              throw Object.assign(
                new Error("Update department failed, please try again later."),
                { code: 814 }
             );
            }
          } 
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_ORG_DEP_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: UPDATE_ORG_DEP_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

  export const deleteDepartmentRequest =
  (depid) => async (dispatch, getState) => {
    

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
     

      dispatch({ type: DELETE_ORG_DEP_REQUEST });
      axios
        .delete(SERVER_URL + `/org/department/${depid}`, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: DELETE_ORG_DEP_SUCCESS });
            dispatch(departmentListRequest());
          } else {
            throw Object.assign(
              new Error("Delete department failed."),
              { code: 815 }
           );
          } 
        })
        .catch((err) => {
          dispatch({
            type: DELETE_ORG_DEP_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: DELETE_ORG_DEP_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };


  ///////////////////////Currency/////////////////////////////
////////////////////////////////////////////////////////////
// get currency list
export const currencyListRequest = () => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
   

    dispatch({ type: CURRENCY_LIST_REQUEST });
    axios
      .get(SERVER_URL + `/org/currency/list`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({
            type: CURRENCY_LIST_SUCCESS,
            payload: response.data.data,
          });
        } else {
          throw Object.assign(
            new Error("Request currency list failed, please tray again later."),
            { code: 817 }
         );

        }
      })
      .catch((err) => {
        dispatch({
          type: CURRENCY_LIST_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: CURRENCY_LIST_FAIL,
      payload: "Connection error, please tray again later",
    });
  }
};

export const newCurrencyRequest = (values) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
   

    dispatch({ type: NEW_CURRENCY_REQUEST });
    axios
      .post(SERVER_URL + `/org/currency/create`, values, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: NEW_CURRENCY_SUCCESS });
          dispatch(currencyListRequest());
        } else {
          if (response.data.error === 11){
            throw Object.assign(
              new Error("Currency code exists, please use a new code."),
              { code: 818 }
           );

          } else {
            throw Object.assign(
              new Error("Create new currency failed, please try again later.."),
              { code: 819 }
           );
          }
        } 
      })
      .catch((err) => {
        dispatch({
          type: NEW_CURRENCY_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: NEW_CURRENCY_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const updateCurrencyRequest =
  (values, currencyId) => async (dispatch, getState) => {

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
     

      dispatch({ type: UPDATE_CURRENCY_REQUEST });
      axios
        .put(SERVER_URL + `/org/currency/${currencyId}`, values, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: UPDATE_CURRENCY_SUCCESS });
            dispatch(currencyListRequest());
          } else {
            if (response.data.error === 11){
              throw Object.assign(
                new Error("Currency code exists, please use a new code."),
                { code: 820 }
             );
            } else {
              throw Object.assign(
                new Error("Update currency failed, please try again later."),
                { code: 821 }
             );
            }
          } 
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_CURRENCY_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: UPDATE_CURRENCY_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

  export const deleteCurrencyRequest =
  (depid) => async (dispatch, getState) => {
    

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
     

      dispatch({ type: DELETE_CURRENCY_REQUEST });
      axios
        .delete(SERVER_URL + `/org/currency/${depid}`, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: DELETE_CURRENCY_SUCCESS});
            dispatch(currencyListRequest());
          } else {
            throw Object.assign(
              new Error("Delete currency failed."),
              { code: 822 }
           );
          } 
        })
        .catch((err) => {
          dispatch({
            type: DELETE_CURRENCY_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: DELETE_CURRENCY_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };



///////////////////////Email template/////////////////////////////
////////////////////////////////////////////////////////////

export const emailTemplatesListRequest = () => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
   
    dispatch({ type: EMAIL_TEMPLATES_LIST_REQUEST });
    axios
      .get(SERVER_URL + `/settings/email_templates`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {

          dispatch({
            type: EMAIL_TEMPLATES_LIST_SUCCESS,
            payload: response.data.data,
          });
        } else {
          throw Object.assign(
            new Error("Request email templates failed, please try again later."),
            { code: 802 }
         );
        }
      })
      .catch((err) => {
        dispatch({
          type: EMAIL_TEMPLATES_LIST_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: EMAIL_TEMPLATES_LIST_FAIL,
      payload: "Connection error, please refresh the page",
    });
  }
};

export const updateEmailTemplateRequest = (values) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
   

    dispatch({ type: UPDATE_EMAIL_TEMPLATE_REQUEST });
    axios
      .post(SERVER_URL + `/settings/email_template/update`, values, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: UPDATE_EMAIL_TEMPLATE_SUCCESS });
          dispatch(emailTemplatesListRequest());
        } else {
         
            throw Object.assign(
                new Error("Update template failed, please try again later."),
                { code: 804 }
             );
        }
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_EMAIL_TEMPLATE_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: UPDATE_EMAIL_TEMPLATE_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const emailTemplatesDetailsRequest = (type) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    
    dispatch({ type: EMAIL_TEMPLATES_DETAILS_REQUEST });
    axios
      .get(SERVER_URL + `/settings/email_template/details?type=${type}`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({
            type: EMAIL_TEMPLATES_DETAILS_SUCCESS,
            payload: response.data.data,
          });
        } else {
          throw Object.assign(
            new Error("Request template details failed, please try again later."),
            { code: 802 }
         );
        }
      })
      .catch((err) => {
        dispatch({
          type: EMAIL_TEMPLATES_DETAILS_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: EMAIL_TEMPLATES_DETAILS_FAIL,
      payload: "Connection error, please refresh the page",
    });
  }
};
