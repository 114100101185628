import axios from "axios";
import {serviceMiddleware} from "./serviceMiddleware";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;


 export const getTemplateListRequest = async (config) => {

   try {
     const response = await axios.get(SERVER_URL + `/settings/pdf-templates/list`, config);
     return response;
   } catch (error) {
    throw error;
   }
 
 };


export const updateTemplateRequest = async (templateId, fileType, values, config) => {

  try {
    const response = await axios.post(SERVER_URL + `/settings/pdf-templates/update/${templateId}?fileType=${fileType}`, values, config);
    return response;
  } catch (error) {
    throw error;
  }
};







