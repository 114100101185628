import React from "react";
import commonStyle from "../../style/commonStyle.module.css";
import {AiOutlineLock} from "react-icons/ai";
import { SimpleBlueButtonLink } from "../../components/editComponents";

export default function NoAuthority() {
  return (
    <div className={commonStyle.container}>
        <div className={commonStyle.brandLogoCon}>
          <div className={commonStyle.brandLogo}></div>
        </div>
      <div className={commonStyle.nopermissonWrapper}>
        <div className={commonStyle.nopermissonCon}>
       
        <div className={commonStyle.nopermissonIconCon}>
        <AiOutlineLock className={commonStyle.nopermissonIcon}/>
      </div>
      <p className={commonStyle.textLine}>
      You do not have access to this page. 
      </p>
      <p className={commonStyle.textLine}>
      Please contact your administrator to request permission.
      </p>
      <div className="w-full mt-4">
      <SimpleBlueButtonLink name="Go to Dashboard" path="/" />
      </div>
        </div>
     
      </div>
    </div>


  );
}
