import React from "react";
import inventoryStyle from "./inventoryStyle.module.css";
import commonStyle from "../../style/commonStyle.module.css";
import { NavLink } from "react-router-dom";
import { MdLocationPin } from "react-icons/md";

export default function ProductInfoTab({ tabName, serialized, batchTracked, changeTab}) {
  let className1;
  let className2;
  let className3;
  let className4;

  if (tabName === "Basic Details") {
    className1 = commonStyle.activeItem;
  } else {
    className1 = commonStyle.tabItem;
  }
  if (tabName === "Stock Level") {
    className2 = commonStyle.activeItem;
  } else {
    className2 = commonStyle.tabItem;
  }

  if (tabName === "Serial Numbers") {
    className3 = commonStyle.activeItem;
  } else {
    className3 = commonStyle.tabItem;
  }
  if (tabName === "Batch Numbers") {
    className4 = commonStyle.activeItem;
  } else {
    className4 = commonStyle.tabItem;
  }

  return (
    <div className={commonStyle.tabsCon}>
      <div className={className1} onClick={() => changeTab("Basic Details")}>Basic Details</div>
      <div className={className2} onClick={() => changeTab("Stock Level")}>Stock Level</div>
      {serialized ?  <div className={className3} onClick={() => changeTab("Serial Numbers")}>Serial Numbers</div> : <></>}
     
      {batchTracked ? <div className={className4} onClick={() => changeTab("Batch Numbers")}>Batch Numbers</div> : <></>}
    </div>
  );
}
