const K_PRICING_FEATURES = [
  {
    name: "Users",
    usageFeature: true,
    startUp: "1 user",
    basic: "3 users",
    business: "5 users",
    enterprise: "12 users",
  },
  {
    name: "Products",
    usageFeature: true,
    startUp: "Unlimited",
    basic: "Unlimited",
    business: "Unlimited",
    enterprise: "Unlimited",
  },
  {
    name: "Customers",
    usageFeature: true,
    startUp: "Unlimited",
    basic: "Unlimited",
    business: "Unlimited",
    enterprise: "Unlimited",
  },
  {
    name: "Suppliers",
    usageFeature: true,
    startUp: "Unlimited",
    basic: "Unlimited",
    business: "Unlimited",
    enterprise: "Unlimited",
  },
  {
    name: "Transactions",
    usageFeature: true,
    startUp: "Unlimited",
    basic: "Unlimited",
    business: "Unlimited",
    enterprise: "Unlimited",
  },
  {
    name: "Bulk import/export",
    usageFeature: false,
    startUp: true,
    basic: true,
    business: true,
    enterprise: true,
  },
  {
    name: "Roles",
    usageFeature: false,
    startUp: true,
    basic: true,
    business: true,
    enterprise: true,
  },
  {
    name: "Multiple tax rates",
    usageFeature: false,
    startUp: true,
    basic: true,
    business: true,
    enterprise: true,
  },
  {
    name: "Multiple locations",
    usageFeature: false,
    startUp: false,
    basic: true,
    business: true,
    enterprise: true,
  },
  {
    name: "Multiple currencies",
    usageFeature: false,
    startUp: false,
    basic: true,
    business: true,
    enterprise: true,
  },
  {
    name: "Extra users",
    usageFeature: false,
    startUp: false,
    basic: false,
    business: true,
    enterprise: true,
  },
  {
    name: "Xero integration",
    usageFeature: false,
    startUp: true,
    basic: true,
    business: true,
    enterprise: true,
  },
  {
    name: "Standard onboarding support",
    usageFeature: false,
    startUp: true,
    basic: true,
    business: true,
    enterprise: true,
  },
  {
    name: "API integration",
    usageFeature: false,
    startUp: false,
    basic: true,
    business: true,
    enterprise: true,
  },
];

  export {
    K_PRICING_FEATURES
  };