
// create product
export const PRODUCT_OPTIONS_REQUEST = "PRODUCT_OPTIONS_REQUEST";
export const PRODUCT_OPTIONS_SUCCESS = "PRODUCT_OPTIONS_SUCCESS";
export const PRODUCT_OPTIONS_FAIL = "PRODUCT_OPTIONS_FAIL";


export const CREATE_NEW_PRODUCT_REQUEST = "CREATE_NEW_PRODUCT_REQUEST";
export const CREATE_NEW_PRODUCT_SUCCESS = "CREATE_NEW_PRODUCT_SUCCESS";
export const CREATE_NEW_PRODUCT_FAIL = "CREATE_NEW_PRODUCT_FAIL";

export const EDIT_PRODUCT_REQUEST = "EDIT_PRODUCT_REQUEST";
export const EDIT_PRODUCT_SUCCESS = "EDIT_PRODUCT_SUCCESS";
export const EDIT_PRODUCT_FAIL = "EDIT_PRODUCT_FAIL";

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL";


// product list
export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";

export const PRODUCT_DETAIL_REQUEST = "PRODUCT_DETAIL_REQUEST";
export const PRODUCT_DETAIL_SUCCESS = "PRODUCT_DETAIL_SUCCESS";
export const PRODUCT_DETAIL_FAIL = "PRODUCT_DETAIL_FAIL";

// stock adjust
export const PRODUCT_ADJUST_DETAIL_REQUEST = "PRODUCT_ADJUST_DETAIL_REQUEST";
export const PRODUCT_ADJUST_DETAIL_SUCCESS = "PRODUCT_ADJUST_DETAIL_SUCCESS";
export const PRODUCT_ADJUST_DETAIL_FAIL = "PRODUCT_ADJUST_DETAIL_FAIL";


export const DIRECTLY_RECEIVE_STOCK_REQUEST = "DIRECTLY_RECEIVE_STOCK_REQUEST";
export const DIRECTLY_RECEIVE_STOCK_SUCCESS = "DIRECTLY_RECEIVE_STOCK_SUCCESS";
export const DIRECTLY_RECEIVE_STOCK_FAIL = "DIRECTLY_RECEIVE_STOCK_FAIL";


export const BULK_STOCK_TRANSFER_REQUEST = "BULK_STOCK_TRANSFER_REQUEST";
export const BULK_STOCK_TRANSFER_SUCCESS = "BULK_STOCK_TRANSFER_SUCCESS";
export const BULK_STOCK_TRANSFER_FAIL = "BULK_STOCK_TRANSFER_FAIL";

export const STOCK_ADJUST_LIST_REQUEST = "STOCK_ADJUST_LIST_REQUEST";
export const STOCK_ADJUST_LIST_SUCCESS = "STOCK_ADJUST_LIST_SUCCESS";
export const STOCK_ADJUST_LIST_FAIL = "STOCK_ADJUST_LIST_FAIL";

export const STOCK_TRANSFER_LIST_REQUEST = "STOCK_TRANSFER_LIST_REQUEST";
export const STOCK_TRANSFER_LIST_SUCCESS = "STOCK_TRANSFER_LIST_SUCCESS";
export const STOCK_TRANSFER_LIST_FAIL = "STOCK_TRANSFER_LIST_FAIL";

export const STOCK_TRANSFER_DETAIL_REQUEST = "STOCK_TRANSFER_DETAIL_REQUEST";
export const STOCK_TRANSFER_DETAIL_SUCCESS = "STOCK_TRANSFER_DETAIL_SUCCESS";
export const STOCK_TRANSFER_DETAIL_FAIL = "STOCK_TRANSFER_DETAIL_FAIL";

export const STOCK_ADJUST_DETAILS_REQUEST = "STOCK_ADJUST_DETAILS_REQUEST";
export const STOCK_ADJUST_DETAILS_SUCCESS = "STOCK_ADJUST_DETAILS_SUCCESS";
export const STOCK_ADJUST_DETAILS_FAIL = "STOCK_ADJUST_DETAILS_FAIL";


//stocktake
export const STOCKTAKE_LIST_REQUEST = "STOCKTAKE_LIST_REQUEST";
export const STOCKTAKE_LIST_SUCCESS = "STOCKTAKE_LIST_SUCCESS";
export const STOCKTAKE_LIST_FAIL = "STOCKTAKE_LIST_FAIL";

// serial batch numebrs for all location
export const SERIAL_BATCH_LIST_REQUEST = "SERIAL_BATCH_LIST_REQUEST";
export const SERIAL_BATCH_LIST_SUCCESS = "SERIAL_BATCH_LIST_SUCCESS";
export const SERIAL_BATCH_LIST_FAIL = "SERIAL_BATCH_LIST_FAIL";





// category
export const PRODUCT_CATEGORY_LIST_REQUEST = "PRODUCT_CATEGORY_LIST_REQUEST";
export const PRODUCT_CATEGORY_LIST_SUCCESS = "PRODUCT_CATEGORY_LIST_SUCCESS";
export const PRODUCT_CATEGORY_LIST_FAIL = "PRODUCT_CATEGORY_LIST_FAIL";

export const NEW_CATEGORY_REQUEST = "NEW_CATEGORY_REQUEST";
export const NEW_CATEGORY_SUCCESS = "NEW_CATEGORY_SUCCESS";
export const NEW_CATEGORY_FAIL = "NEW_CATEGORY_FAIL";

export const UPDATE_CATEGORY_REQUEST = "UPDATE_CATEGORY_REQUEST";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAIL = "UPDATE_CATEGORY_FAIL";

export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAIL = "DELETE_CATEGORY_FAIL";

// product package
export const NEW_EDIT_PRODUCT_PACKAGE_REQUEST = "NEW_EDIT_PRODUCT_PACKAGE_REQUEST";
export const NEW_EDIT_PRODUCT_PACKAGE_SUCCESS = "NEW_EDIT_PRODUCT_PACKAGE_SUCCESS";
export const NEW_EDIT_PRODUCT_PACKAGE_FAIL = "NEW_EDIT_PRODUCT_PACKAGE_FAIL";

export const PRODUCT_PACKAGE_LIST_REQUEST = "PRODUCT_PACKAGE_LIST_REQUEST";
export const PRODUCT_PACKAGE_LIST_SUCCESS = "PRODUCT_PACKAGE_LIST_SUCCESS";
export const PRODUCT_PACKAGE_LIST_FAIL = "PRODUCT_PACKAGE_LIST_FAIL";

export const PRODUCT_PACKAGE_DETAILS_REQUEST = "PRODUCT_PACKAGE_DETAILS_REQUEST";
export const PRODUCT_PACKAGE_DETAILS_SUCCESS = "PRODUCT_PACKAGE_DETAILS_SUCCESS";
export const PRODUCT_PACKAGE_DETAILS_FAIL = "PRODUCT_PACKAGE_DETAILS_FAIL";

// category
export const PRODUCT_UNIT_LIST_REQUEST = "PRODUCT_UNIT_LIST_REQUEST";
export const PRODUCT_UNIT_LIST_SUCCESS = "PRODUCT_UNIT_LIST_SUCCESS";
export const PRODUCT_UNIT_LIST_FAIL = "PRODUCT_UNIT_LIST_FAIL";

export const NEW_UNIT_REQUEST = "NEW_UNIT_REQUEST";
export const NEW_UNIT_SUCCESS = "NEW_UNIT_SUCCESS";
export const NEW_UNIT_FAIL = "NEW_UNIT_FAIL";

export const UPDATE_UNIT_REQUEST = "UPDATE_UNIT_REQUEST";
export const UPDATE_UNIT_SUCCESS = "UPDATE_UNIT_SUCCESS";
export const UPDATE_UNIT_FAIL = "UPDATE_UNIT_FAIL";

export const DELETE_UNIT_REQUEST = "DELETE_UNIT_REQUEST";
export const DELETE_UNIT_SUCCESS = "DELETE_UNIT_SUCCESS";
export const DELETE_UNIT_FAIL = "DELETE_UNIT_FAIL";

