import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  ScreenTitle,
  SimpleBlueButtonLink,
} from "../../components/editComponents";
import Breadcrumbs from "../../components/Breadcrumbs";
import { ErrorText, Tag } from "../../components/viewComponents";
import commonStyle from "../../style/commonStyle.module.css";
import integrationStyle from "./integrationStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import DisconnectXero from "./DisconnectXero";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function IntegrationMainScreen() {
  useDocumentTitle("Integration");

  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  const [connected, setConnected] = useState(userDetailsSuccess && userDetails.data.orgInfo.xeroConnected ? true : false);
  const [lostConenctionMess, setLostConenctionMess] = useState("");

  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    // get xero connection status
    try {
      axios
        .get(SERVER_URL + `/integration/xero/connection_status`, config)
        .then(async (response) => {
          if (response.data.success) {
            if (
              response.data.data.connectionStatus === "connected" &&
              response.data.data.xeroConenctionStatus === "connected"
            ) {
              setConnected(true);
            }
            if (
              response.data.data.connectionStatus === "connected" &&
              response.data.data.xeroConenctionStatus === "disconnected"
            ) {
              setLostConenctionMess(
                "Lost connection with your Xero account, please reconnect to Xero"
              );
            }
          }
        })
        .catch((err) => {});
    } catch (error) {}
  }, [SERVER_URL, userInfo.data.token]);

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Integration" />
      <ScreenTitle title="Integration" />

      <div className={commonStyle.pageContentContainer}>
        <div className={integrationStyle.appCardCon}>
          <div className={integrationStyle.xeroLogo}></div>
          <div className={integrationStyle.xeroTitle}>
            <div>
              <span>Xero</span> |{" "}
              <span className={integrationStyle.xeroTag}>Accounting</span>{" "}
            </div>

            <div className={integrationStyle.statusCon}>
              {lostConenctionMess ? (
                <ErrorText mess={lostConenctionMess} />
              ) : (
                <></>
              )}
              {connected ? (
                <Tag name="Connected" color="green" />
              ) : (
                <Tag name="Disconnected" color="gray" />
              )}
            </div>
            <div className={integrationStyle.actionCon}>
              {connected ? (
                <div>
                  <SimpleBlueButtonLink
                    name="Configuration"
                    path="/integration/xero"
                  />
                  <DisconnectXero textBtn={true} />
                </div>
              ) : (
                <>
                  <SimpleBlueButtonLink
                    name="Connect"
                    path="/integration/xero/connect"
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
