import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";
import { formatDate } from "../../../functions/functions";

// Create styles
const styles = StyleSheet.create({
  viewer: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  page: {
    backgroundColor: "white",
    color: "#494949",
    padding: "60px 40px 40px 40px",
    fontSize: "14px",
  },
  rowWrapper: {
    display: "flex",
    flexDirection: "row",
    marginTop: "20px",
  },
  sectionLeft: {
    width: "50%",
    display: "flex",
    flexDirection: "colomn",
  },
  sectionRight: {
    width: "50%",
    display: "flex",
    flexDirection: "colomn",
    marginLeft: "20px",
  },
  orderTitle: {
    color: "#4683FF",
    fontSize: "24px",
    textAlign: "right",
    marginBottom: "14px",
  },
  rightTextRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: "4px",
  },
  textRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: "4px",
    fontSize: "12px",
  },
  addressRow: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginTop: "4px",
    marginLeft: "12px",
    fontSize: "12px",
  },
  subTitle: {
    color: "#4683FF",
    fontSize: "14px",
    textAlign: "left",
    marginBottom: "4px",
  },
  line: {
    borderBottom: "1px solid #8F8F8F",
    marginBottom: "4px",
  },
  taxHint: {
    fontSize: "12px",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  tableHeader: {
    marginTop: "30px",
  },

  row: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: "6px",
  },
  prodName: {
    width: "40%",
    fontSize: "12px",
    paddingRight: "12px",
  },
  prodVariant: {
    fontSize: "10px",
  },
  xyz: {
    width: "12%",
    fontSize: "12px",
  },
  totalColomn: {
    width: "12%",
    fontSize: "12px",
  },
  summaryCell: {
    width: "36%",
    fontWeight: "bold",
    fontSize: "12px",
    textAlign: "right",
    paddingRight: "12px",
  },
  totalCell: {
    width: "36%",
    fontWeight: "bold",
    fontSize: "12px",
    textAlign: "right",
    paddingRight: "12px",
    color: "#4683FF",
  },
  totalCellValue: {
    width: "12%",
    fontSize: "12px",
    color: "#4683FF",
  },
});

export default function PurchaseOrderPDF({ orderInfo, userDetails }) {
  const ProductsRow = () => {
    const rows = orderInfo.orderItems.map((item, index) => (
      <View style={styles.row} key={index}>
        <Text style={styles.prodName}>
          {item.productName}
          {item.variant ? (
            <Text style={styles.prodVariant}>-{item.variant}</Text>
          ) : (
            <></>
          )}
        </Text>

        <Text style={styles.xyz}>{item.unitName}</Text>
        <Text style={styles.xyz}>{item.quantity}</Text>
        <Text style={styles.xyz}>{item.unitCost}</Text>
        <Text style={styles.xyz}>
          <Text>{item.discount}%</Text>
          <Text style={styles.prodVariant}>OFF</Text>
        </Text>
        <Text style={styles.totalColomn}>{item.totalCost}</Text>
      </View>
    ));
    return <React.Fragment>{rows}</React.Fragment>;
  };

  const CostsRow = () => {
    const rows = orderInfo.orderCosts.map((item, index) => (
      <View style={styles.row} key={index}>
        <Text style={styles.prodName}>{item.costName}</Text>
        <Text style={styles.xyz}></Text>
        <Text style={styles.xyz}></Text>
        <Text style={styles.xyz}></Text>
        <Text style={styles.xyz}></Text>
        <Text style={styles.totalColomn}>{item.cost}</Text>
      </View>
    ));
    return <React.Fragment>{rows}</React.Fragment>;
  };

  const SummaryRowTaxExcl = () => {
    const rows = (
      <View>
        <View style={styles.row}>
          <Text style={styles.prodName}></Text>
          <Text style={styles.xyz}></Text>
          <Text style={styles.summaryCell}>Total Before Tax</Text>
          <Text style={styles.totalColomn}>
            {orderInfo.basicInfo.totalCostExclTax}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.prodName}></Text>
          <Text style={styles.xyz}></Text>
          <Text style={styles.summaryCell}>
            Tax-{orderInfo.basicInfo.taxrateName}
          </Text>
          <Text style={styles.totalColomn}>{orderInfo.basicInfo.totalTax}</Text>
        </View>
        <View style={styles.line}></View>
        <View style={styles.row}>
          <Text style={styles.prodName}></Text>
          <Text style={styles.xyz}></Text>
          <Text style={styles.totalCell}>Total</Text>
          <Text style={styles.totalCellValue}>
            {orderInfo.basicInfo.totalCostInclTax} {orderInfo.basicInfo.currency}
          </Text>
        </View>
      </View>
    );
    return <React.Fragment>{rows}</React.Fragment>;
  };

  const SummaryRowTaxIncl = () => {
    const rows = (
      <View>
        <View style={styles.row}>
          <Text style={styles.prodName}></Text>
          <Text style={styles.xyz}></Text>
          <Text style={styles.totalCell}>Total</Text>
          <Text style={styles.totalCellValue}>
            {orderInfo.basicInfo.totalCostInclTax} {orderInfo.basicInfo.currency}
          </Text>
        </View>
        <View style={styles.line}></View>
        <View style={styles.row}>
          <Text style={styles.prodName}></Text>
          <Text style={styles.xyz}></Text>
          <Text style={styles.summaryCell}>Total Before Tax</Text>
          <Text style={styles.totalColomn}>
            {orderInfo.basicInfo.totalCostExclTax}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.prodName}></Text>
          <Text style={styles.xyz}></Text>
          <Text style={styles.summaryCell}>
            Tax - {orderInfo.basicInfo.taxrateName}
          </Text>
          <Text style={styles.totalColomn}>{orderInfo.basicInfo.totalTax}</Text>
        </View>
      </View>
    );
    return <React.Fragment>{rows}</React.Fragment>;
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.rowWrapper}>
          <View style={styles.sectionLeft}>
            <Text> {userDetails.data.orgInfo.name}</Text>
          </View>
          <View style={styles.sectionRight}>
            <Text style={styles.orderTitle}>PURCHASE ORDER</Text>
            <View style={styles.rightTextRow}>
              <Text>Order No.:</Text>
              <Text>{orderInfo.basicInfo.orderNumber}</Text>
            </View>
            <View style={styles.rightTextRow}>
              <Text>Order Date:</Text>
              <Text>
                {formatDate(
                  new Date(orderInfo.basicInfo.createDate * 1000),
                  userDetails.data.orgInfo.date_format,
                  userDetails.data.orgInfo.time_zone
                )}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.rowWrapper}>
          <View style={styles.sectionLeft}>
            <Text style={styles.subTitle}>SUPPLIER</Text>
            <View style={styles.textRow}>
              <Text>Name: </Text><Text>{orderInfo.basicInfo.supplier}</Text>
            </View>
            <View style={styles.textRow}>
              <Text>Contact Name:</Text>
              <Text>
                {orderInfo.basicInfo.firstName} {orderInfo.basicInfo.lastName}
              </Text>
            </View>
            <View style={styles.textRow}>
              <Text>Contact Phone:</Text>
              <Text>{orderInfo.basicInfo.phone}</Text>
            </View>
            <View style={styles.textRow}>
              <Text>Contact Email:</Text>
              <Text>{orderInfo.basicInfo.email}</Text>
            </View>

            <View style={styles.textRow}>
              <Text>Billing Address: </Text>
            </View>
            <View style={styles.addressRow}>
              <Text>{orderInfo.basicInfo.billingAddress.addressLine}</Text>
              <Text>{orderInfo.basicInfo.billingAddress.city}</Text>
              <Text>{orderInfo.basicInfo.billingAddress.region}</Text>
              <Text>{(orderInfo.basicInfo.billingAddress.country && orderInfo.basicInfo.billingAddress.country.value) ? orderInfo.basicInfo.billingAddress.country.value : ""}</Text>
              <Text>{orderInfo.basicInfo.billingAddress.postalCode}</Text>
            </View>
          </View>

          <View style={styles.sectionRight}>
            <Text style={styles.subTitle}>SHIP TO</Text>
            <View style={styles.textRow}>
              <Text>Name:</Text><Text>{userDetails.data.orgInfo.name}</Text>
            </View>
            <View style={styles.textRow}>
              <Text>location:</Text>
              <Text>{orderInfo.basicInfo.location}</Text>
            </View>
            <View style={styles.textRow}>
              <Text>Address:</Text>
              <Text>{orderInfo.basicInfo.deliveryAddress}</Text>
            </View>

            <View style={styles.textRow}>
              <Text>Expected Delivery Date: </Text>
              <Text>
                {formatDate(
                  new Date(orderInfo.basicInfo.expectedDate * 1000),
                  userDetails.data.orgInfo.date_format,
                )}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.tableHeader}>
          <Text style={styles.subTitle}>ORDER PRODUCTS</Text>
          <Text style={styles.taxHint}>
            {orderInfo.basicInfo.taxIncluded
              ? "* Unit cost includes tax"
              : "* Unit cost does not include tax"}
          </Text>
          <View style={styles.row}>
            <Text style={styles.prodName}>Product</Text>
            <Text style={styles.xyz}>Unit</Text>
            <Text style={styles.xyz}>Order Qty</Text>
            <Text style={styles.xyz}>Unit Cost</Text>
            <Text style={styles.xyz}>Discount</Text>
            <Text style={styles.xyz}>Total</Text>
          </View>
          <View style={styles.line}></View>
        </View>

        <View style={styles.tableContainer}>
          <ProductsRow />
        </View>
        {orderInfo.orderCosts.length > 0 ? (
          <>
            <View style={styles.line}></View>
            <View style={styles.tableContainer}>
              <CostsRow />
            </View>
          </>
        ) : (
          <></>
        )}

        <View style={styles.line}></View>
        {orderInfo.basicInfo.taxIncluded ? (
          <View style={styles.tableContainer}>
            <SummaryRowTaxIncl />
          </View>
        ) : (
          <View style={styles.tableContainer}>
            <SummaryRowTaxExcl />
          </View>
        )}

        {orderInfo.basicInfo.notes ? (
          <View style={styles.textRow}>
            <Text>Notes: </Text><Text>{orderInfo.basicInfo.notes}</Text>
          </View>
        ) : (
          <></>
        )}

        <View style={styles.textRow}>
          <Text>
            Authorized by {orderInfo.basicInfo.creatorFirstName}
            {orderInfo.basicInfo.creatorLastName}
          </Text>
        </View>

        <View style={styles.textRow}>
          <Text>
            Date:
            {orderInfo.basicInfo.approveDate > 0
              ? formatDate(
                  new Date(orderInfo.basicInfo.approveDate * 1000),
                  userDetails.data.orgInfo.date_format,
                  userDetails.data.orgInfo.time_zone
                )
              : ""}
          </Text>
        </View>
      </Page>
    </Document>
  );
}
