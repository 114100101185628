import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import { ScreenTitle } from "../../components/editComponents";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import {
  PriceText,
  ViewDateWithTime,
  ErrorMessage,
  Loader,
} from "../../components/viewComponents";
import inventoryStyle from "./inventoryStyle.module.css";
import LocationTab from "./LocationTab";
import { convtPrice, convtQuantity } from "../../functions/functions";
import {
  stockLogsLocationsRequest,
  stockLogsRequest,
} from "../../api/productServices";

export default function StockLogsScreen() {
  useDocumentTitle("Stock Logs");
  const { id: productId } = useParams();
  const location = useLocation();
  const productName = location.state;

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [logsStatus, setLogsStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [locations, setLocations] = useState([]);

  const [activeLocationId, setActiveLocationId] = useState(0);

  // State to manage additional records loading
  const [allLogs, setAllLogs] = useState([]);
  const [loadMore, setLoadMore] = useState(true);

  // pagination
  const pageSize = 40;
  const [pageNum, setPageNum] = useState(0);

  const changeLocationAction = (locationId) => {
    setActiveLocationId(locationId);
    setPageNum(0);
    setAllLogs([]); // Clear existing logs when changing location
  };

  // get stock locations
  useEffect(() => {
    const getLogsLocations = async () => {
      try {
        setReqStatus((preState) => ({
          ...preState,
          loading: true,
        }));
        const responseData = await stockLogsLocationsRequest(productId, config);
        if (responseData.data.success) {
          let locations = responseData.data.data;
          setLocations(locations);
          if (locations.length) {
            setActiveLocationId(locations[0].locationId);
          }
          setReqStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
        }
      } catch (error) {
        setReqStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };
    getLogsLocations();
  }, []);

  useEffect(() => {
    const loadMoreLogs = async () => {
      try {
        setLogsStatus((prevState) => ({
          ...prevState,
          loading: true,
        }));
  
        // Fetch logs
        const response = await stockLogsRequest(
          productId,
          activeLocationId,
          pageNum,
          pageSize,
          config
        );
        if (response.data.success) {
          const newLogs = response.data.data;
          // Append new logs to existing logs
          const reversedNewLogs = newLogs.reverse();
          const updatedLogs = [...reversedNewLogs, ...allLogs];
          setAllLogs(updatedLogs);
          setLoadMore(newLogs.length === pageSize); // Determine if more logs are available
          setLogsStatus((prevState) => ({
            ...prevState,
            loading: false,
            success: true,
          }));
        }
      } catch (error) {
        setLogsStatus((prevState) => ({
          ...prevState,
          loading: false,
          error: error,
        }));
      }
    };

    if (Number(activeLocationId) > 0) {
      loadMoreLogs();
    }
  }, [activeLocationId, pageNum]);

  const moreLogButtonAction = () => {
    setPageNum(pageNum + 1);
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Stock Logs" />
      <ScreenTitle title="Stock Logs" />

      <div className={commonStyle.pageContentContainer}>
        {reqStatus.loading ? (
          <Loader mess="Requesting stock logs, please wait a moment..." />
        ) : reqStatus.success ? (
          <div>
            <LocationTab
              locationsData={locations}
              changeLocation={changeLocationAction}
              activeId={activeLocationId}
            />
            <p className={inventoryStyle.productName}>{productName}</p>
            <div className={inventoryStyle.logsContentWrapper}>
              {logsStatus.loading ? (
                <Loader mess={"Requesting logs..."} />
              ) : logsStatus.success ? (
                allLogs.length ? (
                  <div className={inventoryStyle.logsContentCon}>
                    <table className={commonStyle.logsTable}>
                      <thead>
                        <tr>
                          {/* <th></th> */}
                          <th>Time</th>
                          <th>Operator</th>
                          <th>Change Type</th>
                          <th>Change Quantity</th>
                          <th>Stock After Change</th>
                          <th>Unit Revenue</th>
                          <th>Unit Expense</th>
                          <th>Unit Cost</th>
                          <th>Landing Cost</th>
                          <th>Reference ID</th>
                          <th>Reason</th>
                          <th>Notes</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loadMore && (
                          <tr>
                            <td colSpan="12" style={{ textAlign: "left" }}>
                              <button
                                onClick={moreLogButtonAction}
                                className="text-brandColor hover:underline"
                              >
                                Load previous logs
                              </button>
                            </td>
                          </tr>
                        )}
                        {allLogs.map((item, index3) => (
                          <tr key={index3}>
                            <td>
                              <ViewDateWithTime value={item.atime} />
                            </td>
                            <td>
                              {item.creatorFirstName}{" "}
                              {item.creatorLastName ? item.creatorLastName : ""}
                            </td>
                            <td>{item.actionType}</td>
                            <td>{convtQuantity(item.stockChange)}</td>
                            <td>{convtQuantity(item.stockAfterChange)}</td>
                            <td>{convtPrice(item.unitIncome)}</td>
                            <td>
                              <PriceText num={item.unitExpense} />
                            </td>
                            <td>
                              <PriceText num={item.unitCost} />
                            </td>

                            <td>
                              <PriceText num={item.unitLandingCost} />
                            </td>
                            <td>
                              {" "}
                              <div className="flex flex-col">
                                <p>{item.referenceId}</p>
                                {item.project && <p>{item.project}</p>}
                              </div>
                            </td>
                            <td>{item.reason}</td>
                            <td>{item.notes}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <></>
                )
              ) : logsStatus.error ? (
                <ErrorMessage mess={logsStatus.error} />
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : reqStatus.error ? (
          <ErrorMessage mess={reqStatus.error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
