import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import loginStyle from "./loginStyle.module.css";
import RegisterWithXeroForm from "./RegisterWithXeroForm";
import { K_CURRENCIES } from "../../constantsData/currencies";
import { K_COUNTRIES } from "../../constantsData/countries";
import { K_DATEFORMAT } from "../../constantsData/dateFormats";
import { K_TIMEZONES } from "../../constantsData/timeZones";
import useDocumentTitle from "../../useDocumentTitle";
import LoginAdver from "./LoginAdver";
import {
  BlurColorBackground,
  Loader,
  TextErrorMessage,
} from "../../components/viewComponents";
import XeroRegisterPassCode from "./XeroRegisterPassCode";
import { LOGIN_SUCCESS } from "../../constants/authConstants";
import viewStyle from "../../style/styledViewComponentsStyle.module.css";
import { checkOrgStatusRequest } from "../../actions/orgSettingsActions";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function RegisterWithXeroScreen() {
  useDocumentTitle("Register");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const orgInfo = location.state;

  const [token, setToken] = useState(orgInfo ? orgInfo.token : "");

  const [userInfoData, setUserInfoData] = useState(
    orgInfo ? { data: orgInfo } : {}
  );

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const [dbCreated, setDbCreated] = useState(false);
  const [dbCreatedError, setDbCreatedError] = useState("");

  const [registerLoading, setRegisterLoading] = useState(false);
  const [registerError, setRegisterError] = useState("");

  // get dlocation
  const [locationStatus, setLocationStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [initialValues, setInitialvalues] = useState({
    userEmail: orgInfo ? orgInfo.user_email : "",
    organizationName: orgInfo ? orgInfo.org_name : "",
    industry: "",
    country: "",
    baseCurrency: "",
    dateFormat: "",
    timeZone: "",
    utcOffset: "",
  });

  const [initialLocationValues, setInitialLocationValues] = useState({
    userEmail: orgInfo ? orgInfo.user_email : "",
    organizationName: orgInfo ? orgInfo.org_name : "",
    industry: "",
    country: "",
    baseCurrency: "",
    dateFormat: "",
    timeZone: "",
    utcOffset: "",
  });

  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    // get user location
    try {
      setLocationStatus((prevStatus) => {
        return { ...prevStatus, loading: true };
      });

      axios
        .get("https://ipapi.co/json/")
        .then(async (response) => {
          if (response.data) {
            setLocationStatus((prevStatus) => {
              return { ...prevStatus, loading: false, success: true };
            });

            let userLocation = response.data;
            setInitialLocationValues((prevStatus) => {
              return {
                ...prevStatus,
                industry: {},
                country: K_COUNTRIES.filter(
                  (x) => x.value === userLocation.country_name
                )[0],
                baseCurrency: K_CURRENCIES.filter(
                  (x) => x.value === userLocation.currency
                )[0],
                dateFormat: K_DATEFORMAT.filter((x) => x.isDefault === 1)[0],
                timeZone: K_TIMEZONES.filter(
                  (x) => x.value === userLocation.timezone
                )[0],
                utcOffset: userLocation.utc_offset,
              };
            });
          } else {
            setLocationStatus((prevStatus) => {
              return {
                ...prevStatus,
                loading: false,
                error: "Get user location failed",
              };
            });
          }
        })
        .catch((err) => {
          setLocationStatus((prevStatus) => {
            return {
              ...prevStatus,
              loading: false,
              error: "Connection error, please try again later",
            };
          });
        });
    } catch (error) {
      setLocationStatus((prevStatus) => {
        return {
          ...prevStatus,
          loading: false,
          error: "Connection error, please try again later",
        };
      });
    }
  }, [token]);

  useEffect(() => {
    let timeoutId;

    const checkStatus = () => {
      if (token) {
        try {
          axios
            .get(SERVER_URL + `/org/db_status`, config)
            .then(async (response) => {
              if (response.data.success) {
                if (response.data.data) {
                  setDbCreated(true);
                }
              } else {
                throw "Get organzation database status failed.";
              }
            })
            .catch((err) => {
              setDbCreatedError(err);
            });
        } catch (error) {}

        timeoutId = setTimeout(checkStatus, 3000); // Adjust the delay as needed
      }
    };

    if (!dbCreated) {
      checkStatus();
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId); // This clears the pending timeout on re-render or unmount
      }
    };
  }, [token, dbCreated]);

  ////////////////////////////////// //////////////////////////////////
  ////////////////////Register with Xero// //////////////////////////////////

  const [passCode, setPassCode] = useState(orgInfo ? false : true);

  const codeExchangeSuccessAction = (values) => {
    setPassCode(false);
    // save userInfo to local Storage
    setInitialLocationValues({
      ...initialLocationValues,
      userEmail: values.data.user_email,
      organizationName: values.data.org_name,
    });
    setInitialvalues({
      ...initialLocationValues,
      userEmail: values.data.user_email,
      organizationName: values.data.org_name,
    });
    setToken(values.data.token);
    let user_data = {
      org_id: values.data.org_id,
      org_name: values.data.org_name,
      user_id: values.data.user_id,
      user_email: values.data.user_email,
      token: values.data.token,
    };
    setUserInfoData({ data: user_data });
  };

  // register submit
  const RegisterRequest = (values) => {
    const orgValues = {
      name: values.organizationName,
      industry: values.industry.value,
      country: values.country.value,
      baseCurrency: values.baseCurrency.value,
      dateFormat: values.dateFormat.value,
      timeZone: values.timeZone.value,
      offset: values.utcOffset,
    };

    try {
      setRegisterLoading(true);
      axios
        .post(SERVER_URL + `/org/details/initial_update`, orgValues, config)
        .then(async (response) => {
          setRegisterLoading(false);
          if (response.data.success) {
            dispatch({ type: LOGIN_SUCCESS, payload: userInfoData });
            localStorage.setItem("userInfo", JSON.stringify(userInfoData));
            //dispatch(userDetailsRequest());
            navigate("/", {
              replace: true,
              state: { showOnboardingModal: true },
            });
          } else {
            setRegisterError(
              "Update organization details failed, please try again later."
            );
          }
        })
        .catch((err) => {
          setRegisterLoading(false);
          setRegisterError(
            "Update organization details failed, please try again later."
          );
        });
    } catch (error) {
      setRegisterLoading(false);
      setRegisterError("Connection error, please try again later.");
    }
  };

  return (
    <div className={loginStyle.container}>
      <div className={viewStyle.modalColorBg}>
        <BlurColorBackground />

        <div className={viewStyle.onboardingContentContainer}>
          <div className={viewStyle.modalLogoWrapper}>
            <div className={viewStyle.modalLogoCon}></div>
          </div>

          <div className={viewStyle.modalMainContentCon}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "50%",
              }}
            >
              {passCode ? (
                <div>
                  <XeroRegisterPassCode
                    codeExchangeData={codeExchangeSuccessAction}
                  />
                </div>
              ) : (
                <div style={{ width: "100%", marginTop: "16px" }}>
                  <h1 style={{ fontWeight: "700", textAlign:"center"}}>Welcom to StockUnify!</h1>
                  {dbCreatedError ? (
                    <div style={{ marginTop: "16px" }}>
                      <TextErrorMessage mess={dbCreatedError} />
                    </div>
                  ) : (
                    <></>
                  )}
                  {!dbCreated ? (
                    <Loader mess="We are setting up your account, please wait a minute.." />
                  ) : dbCreated ? (
                    <>
                      <p style={{ marginTop: "36px" }}>
                        We'd like to offer you a personalised experience. Please
                        tell us more about your business.
                      </p>

                      {locationStatus.loading ? (
                        <Loader mess="Requesting user location..." />
                      ) : locationStatus.success ? (
                        <RegisterWithXeroForm
                          initialValues={initialLocationValues}
                          onSubmit={RegisterRequest}
                          registerLoading={registerLoading}
                          // registerError={registerError}
                          // orgInfo={orgInfo ? orgInfo : {}}
                        />
                      ) : (
                        <RegisterWithXeroForm
                          initialValues={initialValues}
                          onSubmit={RegisterRequest}
                          registerLoading={registerLoading}
                          // registerError={registerError}
                          // orgInfo={orgInfo ? orgInfo : {}}
                        />
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
