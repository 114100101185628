import React, { useEffect, useState } from "react";
import axios from "axios";
import loginStyle from "./loginStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import {
  DisableBlueButton,
  SimpleBlueButton,
} from "../../components/editComponents";
import {
  ErrorText,
  Loader,
  SuccessText,
} from "../../components/viewComponents";
import LoginAdver from "./LoginAdver";
import { useLocation } from "react-router-dom";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function PendingActivateAccountScreen() {
  useDocumentTitle("Register");

  const location = useLocation();
  const headers = {
    "Content-type": "application/json",
  };


  const email = location.state;


  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(null);

  const [resendLoading, setResendLoading] = useState(false);
  const [resendError, setResendError] = useState("");
  const [resendSuccess, setResendSuccess] = useState(false);



  useEffect(() => {
    let intervalId;
    if (secondsLeft !== null) {
      intervalId = setInterval(() => {
        setSecondsLeft((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [secondsLeft]);

  useEffect(() => {
    if (secondsLeft === 0) {
      setIsButtonDisabled(false);
      setSecondsLeft(null);
    }
  }, [secondsLeft]);



  const sendActivateEmail = () => {
    setIsButtonDisabled(true);
    setSecondsLeft(60);

    let ueserEmail = {
      userEmail: email,
    };

    try {
      setResendLoading(true);
      axios
        .post(
          SERVER_URL + `/organization/send_activate_email`,
          ueserEmail,
          headers
        )
        .then(async (response) => {
          if (response.data.success) {
            setResendLoading(false);
            setResendSuccess(true);
          } else {
            setResendLoading(false);
            setResendError(
              "Resend activate email failed, please try again later."
            );
          }
        })
        .catch((err) => {
          setResendLoading(false);
          setResendError(
            "Resend activate email failed, please try again later."
          );
        });
    } catch (error) {
      setResendLoading(false);
      setResendError("Connection error, please try again later.");
    }
  };

  return (
    <div className={loginStyle.container}>
      <LoginAdver />

      <div className={loginStyle.loginContainer}>
        <div className={loginStyle.brandLogoCon}>
          <div className={loginStyle.brandLogo}></div>
        </div>

        <div className={loginStyle.loginFormCon}>
          <div className={loginStyle.loginForm}>
            <h1 className={loginStyle.signin}>Activate Account</h1>

            <div>
              <p>
                An activate link has been sent to your email inbox. Please click
                on the link to activate your account.
              </p>

              <p className={loginStyle.resendText}>
                Have you not received the email? click the button to resend it.
              </p>

              {resendLoading ? (
                <Loader mess="sending email..." />
              ) : resendSuccess ? (
                <SuccessText mess="Email resend successfully!" />
              ) : resendError ? (
                <ErrorText mess={resendError} />
              ) : (
                <> </>
              )}

              {isButtonDisabled ? (
                <div>
                  {" "}
                  <DisableBlueButton name="Send Activate Email" /> After waiting
                  for {secondsLeft} seconds, you can resend it again.
                </div>
              ) : (
                <div className="w-full mt-4">
                <SimpleBlueButton
                  name="Send Activate Email"
                  action={sendActivateEmail}
                />
                    </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
