import React from "react";
import { useLocation } from "react-router-dom";
import settingsStyle from "../settingsStyle.module.css";
import commonStyle from "../../../style/commonStyle.module.css";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { EditButtonLink, ScreenTitle } from "../../../components/editComponents";
import {
  ViewText,
  RoleListTag,
  ViewLabel,
} from "../../../components/viewComponents";
import useDocumentTitle from "../../../useDocumentTitle";

export default function UserView() {
  useDocumentTitle("Preview user")
  // get state from path
  const location = useLocation();
  const user = location.state;

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Preview User" />
      <ScreenTitle title="Preview User" />
      <div className={commonStyle.pageContentContainer}>
            <div className={settingsStyle.halfPreviewCon}>
              <div className={settingsStyle.roleName}>
                {user.id === 1 ? (
                  <></>
                ) : (
                  <div className={settingsStyle.rightEditBtn}>
                    <EditButtonLink
                      path={`/settings/users/edit/${user.id}`}
                      data={user}
                    />
                  </div>
                )}
              </div>

              <div style={{ display: "flex" }}>
                <div className={settingsStyle.col1}>
                  <ViewText title="First Name" value={user.firstName} />
                  <ViewText title="Default Location" value={user.location.label} />
                  <ViewText title="Email" value={user.userPhone} />
                  <ViewText title="Position" value={user.position} />
                </div>

                <div className={settingsStyle.col2}>
                  <ViewText title="Last Name" value={user.lastName} />
                  <ViewText title="Phone" value={user.userPhone} />
                  <ViewText title="Department" value={user.department.label} />
                </div>
              </div>
              <ViewLabel title="Roles" />
              <RoleListTag userRoles={user.roles} />
            </div>
          </div>
    </div>
  );
}
