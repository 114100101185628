import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useSearchParams } from "react-router-dom";
import loginStyle from "./loginStyle.module.css";
import { activateAccountRequest } from "../../actions/authAction";
import {
  Loader,
  ErrorText,
  SuccessText,
} from "../../components/viewComponents";
import useDocumentTitle from "../../useDocumentTitle";
import LoginAdver from "./LoginAdver";

export default function ActivateAccountScreen() {
  useDocumentTitle("Rui Inventory");
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const orgid = searchParams.get('orgid');
  const code = searchParams.get('code');

  const { loading, success, error } = useSelector(
    (state) => state.activateAccount
  );

  // when logged push to orginal page

  useEffect(() => {
    dispatch(activateAccountRequest(orgid, code))

  }, [dispatch, orgid, code]);

  return (
    <div className={loginStyle.container}>
      <LoginAdver />

      <div className={loginStyle.loginContainer}>
        <div className={loginStyle.brandLogoCon}>
          <div className={loginStyle.brandLogo}></div>
        </div>

        <div className={loginStyle.loginFormCon}>
          <div className={loginStyle.loginForm}>
            {loading ? (
              <Loader mess="Activating account..." />
            ) : success ? (
              <div>
                <SuccessText mess="Account Activated!" />
                <p style={{marginTop: "12px"}}>Congratulations! Your account has been activated successfully. You can now log in and start exploring our product.</p>
                <div className={loginStyle.linkCon}>
                  <NavLink to="/login" exact="true" className={loginStyle.signinlink}>
                    Sign in
                  </NavLink>
                </div>
              </div>
            ) : error ? (
              <ErrorText mess={error} />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
