import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Search } from "../../components/inputFields";
import { searchPurchaseProductRequest } from "../../actions/purchaseOrderActions";
import { productDetailsRequest } from "../../actions/productActions";
import {
  HightlightKeywords,
  Loader,
  ErrorMessage,
  VariantTag,
} from "../../components/viewComponents";
import dashboardStyle from "./dashboardStyle.module.css";
import inventoryStyle from "../inventory/inventoryStyle.module.css";
import pickerStyle from "../../style/productPickerStyle.module.css";
import { MdLocationPin } from "react-icons/md";
import { AiOutlineBarcode } from "react-icons/ai";
import QuickStockScanForm from "./QuickStockScanForm";
import { convtQuantity } from "../../functions/functions";

export default function QuickStock() {
  const dispatch = useDispatch();

  const [keyWords, setKeywords] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [showProDetail, setShowProDetail] = useState(false);

  const [searchByName, setSearchByName] = useState(true);

  const { loading, products, error, success } = useSelector(
    (state) => state.searchPurchaseProduct
  );

  const {
    loading: detailLoading,
    productInfo,
    error: detailError,
    success: detailSuccess,
  } = useSelector((state) => state.productDetails);

  const handleSearchChange = (value) => {
    setKeywords(value);
    setShowProDetail(false);
    if (value) {
      // set the currect row show the search results
      setShowSearchResults(true);
      dispatch(searchPurchaseProductRequest(value, "productAndService"));
    } else {
      setShowSearchResults(false);
    }
  };

  const getProductDetails = (proId, proName) => {
    dispatch(productDetailsRequest(proId, "preview"));
    setShowSearchResults(false);
    setShowProDetail(true);
    setKeywords(proName);
  };

  return (
    <div className={dashboardStyle.quickStockCon}>
      <div className={dashboardStyle.searchTypeTabsCon}>
        <div
          className={
            searchByName
              ? `${dashboardStyle.searchTypeTab} ${dashboardStyle.tabSelected}`
              : dashboardStyle.searchTypeTab
          }
          onClick={() => setSearchByName(true)}
        >
          Search by product name
        </div>
        <div
          className={
            !searchByName
              ? `${dashboardStyle.searchTypeTab} ${dashboardStyle.tabSelected}`
              : dashboardStyle.searchTypeTab
          }
          onClick={() => setSearchByName(false)}
        >
          <AiOutlineBarcode />{" "}
          <span style={{ marginLeft: "4px" }}>Scan barcode</span>
        </div>
      </div>
      {searchByName ? (
        <Search
          searchAction={handleSearchChange}
          placeholdertext="Type product name/code to search..."
          initialQuery={keyWords}
          onChange={handleSearchChange}
        />
      ) : (
        <QuickStockScanForm type="quick_stock" proDetails={getProductDetails} />
      )}

      {showSearchResults ? (
        <ul className={dashboardStyle.qsSearchResultCon}>
          {loading ? (
            <Loader mess="Searching products, please wait a moment..." />
          ) : success ? (
            products.length > 0 ? (
              products.map((pro, index) => (
                <li
                  key={index}
                  className={pickerStyle.searchResultLine}
                  onClick={() => getProductDetails(pro.id, pro.name)}
                >
                  <HightlightKeywords
                    keywords={keyWords}
                    text={pro.name}
                    key={index}
                  />
                </li>
              ))
            ) : (
              <li className={pickerStyle.searchResultLineNR}>
                No products found
              </li>
            )
          ) : error ? (
            <ErrorMessage mess={error} />
          ) : (
            <></>
          )}
        </ul>
      ) : (
        <></>
      )}
      {searchByName ? <></> : detailSuccess ? <p>{keyWords}</p> : <></>}
      <div>
        {showProDetail ? (
          detailLoading ? (
            <Loader mess="Requesting product details, please wait a moment..." />
          ) : detailSuccess ? (
            productInfo.basicInfo.location.map((loc, index1) => (
              <div key={index1}>
                <div className={inventoryStyle.locationTitle}>
                  <MdLocationPin />
                  <span>{loc.value}</span>
                </div>
                <table className={inventoryStyle.dynTable}>
                  <thead>
                    <tr>
                      <th>Location</th>
                      {productInfo.basicInfo.attributeNames.length > 0 ? (
                        productInfo.basicInfo.attributeNames.map(
                          (item, index2) => {
                            return <th key={index2}>{item}</th>;
                          }
                        )
                      ) : (
                        <></>
                      )}
                      <th>Unit</th>
                      <th>Sale Price</th>
                      <th>Stock on Hand</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productInfo.attrsDetails.map((item, index3) =>
                      Number(item.locationId) !== Number(loc.id) ? (
                        ""
                      ) : (
                        <tr key={index3}>
                          <td>{loc.value}</td>
                          {productInfo.basicInfo.attributeNames.length > 0 ? (
                            productInfo.basicInfo.attributeNames.map(
                              (key, index3) => {
                                return (
                                  <td key={index3}>
                                    <VariantTag name={item[key]} />
                                  </td>
                                );
                              }
                            )
                          ) : (
                            <></>
                          )}
                          <td>{productInfo.basicInfo.unitName}</td>
                          <td>{item.salePrice}</td>
                          <td>{convtQuantity(item.stockLevel)}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            ))
          ) : (
            <ErrorMessage mess={detailError} />
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
