import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { TypingSearchInput } from "./inputFields";
import {
  ErrorMessage,
  Loader,
  HightlightKeywords,
  TextErrorMessage,
  VariantSkuTag,
} from "./viewComponents";
import pickerStyle from "../style/productPickerStyle.module.css";
import { searchPurchaseProductRequest } from "../actions/purchaseOrderActions";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function ProductPicker({
  index,
  locationId,
  priceTierId,
  onSelect,
  requiredType,
  locationRequired = false,
}) {
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const divRef = useRef(null);
  const isFirstRender = useRef(true);
  const shouldSearch = useRef(true);

  const useDebounce = (text, delay) => {
    const [debounce, setDebounce] = useState(text);
    useEffect(() => {
      const timer = setTimeout(() => {
        setDebounce(text);
      }, delay);
      return () => {
        clearTimeout(timer);
      };
    }, [text, text]);
    return debounce;
  };

  const {
    loading: seacrhLoading,
    products,
    error: searchError,
    success: seacrhSuccess,
  } = useSelector((state) => state.searchPurchaseProduct);

  const [formError, setFormError] = useState("");

  const [searchKeywords, setSearchKeywords] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(false);
  const decouncedText = useDebounce(searchKeywords, 500);

  const [selectedOptions, setSelectedOptions] = useState({
    productId: "",
    options: {},
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        // Clicked outside of the div, so close it
        setShowSearchResults(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFocusSearch = () => {
    if (locationRequired && !locationId) {
      setFormError("Please select a location.");
      return;
    }
    setFormError("");
    searcProductsRequest(searchKeywords);
  };

  const handleSearchChange = (e) => {
    const { value, index } = e.target;
    setFormError("");
    setSearchKeywords(value);
    shouldSearch.current = true; // Allow search on input change
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (shouldSearch.current) {
      searcProductsRequest(decouncedText);
    }
  }, [decouncedText]);

  const searcProductsRequest = (keywords) => {
    // set the currect row show the search results
    setShowSearchResults(true);
    let productType = "allProduct";
    // requiredType
    // product -> only search product type: "product, rentalProduct"
    // productAndService -> only search product type: "product, rentalProduct, service"
    //saleProduct -> only search product type: "product, service, package"
    if (requiredType) {
      productType = requiredType;
    }
    dispatch(searchPurchaseProductRequest(keywords, productType));
  };


  const getOrderItemDetails = (pro) => {
    let famid = pro.id;
    let productType = pro.type;

    try {
      // construct url
      let url = SERVER_URL + `/product/search/details/${famid}`;

      if (productType === "package") {
        if (locationId) {
          url =
            SERVER_URL +
            `/product/product-package/details/${famid}?locationId=${locationId}`;
        } else {
          url = SERVER_URL + `/product/product-package/details/${famid}`;
        }
      } else {
        if (!priceTierId && locationId) {
          url =
            SERVER_URL +
            `/product/search/details/${famid}?locationId=${locationId}`;
        } else if (priceTierId && !locationId) {
          url =
            SERVER_URL +
            `/product/search/details/${famid}?priceTierId=${priceTierId}`;
        } else if (priceTierId && locationId) {
          url =
            SERVER_URL +
            `/product/search/details/${famid}?locationId=${locationId}&priceTierId=${priceTierId}`;
        } else {
          url = SERVER_URL + `/product/search/details/${famid}`;
        }
      }

      axios
        .get(url, config)
        .then(async (response) => {
          if (response.data.success) {
            setShowSearchResults(false);
            if (Number(index) >= 0) {
              onSelect(index, { type: productType, data: response.data.data });
            } else {
              onSelect({ productType: productType, data: response.data.data });
            }
            setSelectedOptions({
              productId: "",
              options: {},
            });
            setSearchKeywords("");
            shouldSearch.current = false;
          }
        })
        .catch((err) => {});
    } catch (error) {}
  };

  return (
    <div className={pickerStyle.serchCon}>
      {formError ? <TextErrorMessage mess={formError} /> : <></>}
      <TypingSearchInput
        type="text"
        name="searchKeywords"
        placeholdertext="Type product name/code to search..."
        value={searchKeywords}
        onFocus={handleFocusSearch}
        onChange={(searchKeywords) =>
          handleSearchChange({
            target: {
              value: searchKeywords,
            },
          })
        }
      />

      {showSearchResults ? (
        <div className={pickerStyle.searchResultsCon} ref={divRef}>
          <div className={pickerStyle.searchResultsProductCon}>
            {seacrhLoading ? (
              <Loader mess="Searching products...." />
            ) : seacrhSuccess ? (
              products.length > 0 ? (
                <ul>
                  {products.map((pro, index2) => (
                    <li
                      key={index2}
                      className={pickerStyle.searchResultLine}
                      onClick={() => getOrderItemDetails(pro)}
                    >
                      {pro.type === "package" ? (
                        <div className={pickerStyle.proTypeTag}>Package</div>
                      ) : pro.sku ? (
                        <div className="mr-2">
                          <VariantSkuTag name={pro.sku} />
                        </div>
                      ) : (
                        <></>
                      )}

                      <HightlightKeywords
                        keywords={searchKeywords}
                        text={pro.name}
                        key={index2}
                      />
                    </li>
                  ))}
                </ul>
              ) : (
                <p className={pickerStyle.noResultLine}>
                  No products match, please change search words and try again
                </p>
              )
            ) : (
              <ErrorMessage mess={searchError} />
            )}{" "}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
