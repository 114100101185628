import React, { useEffect, useState } from "react";
import { MdLocationPin } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  productDetailsRequest,
  serialBatchNumbersRequest,
} from "../../actions/productActions";
import Breadcrumbs from "../../components/Breadcrumbs";
import XeroLink from "../../components/XeroLink";
import { ScreenTitle } from "../../components/editComponents";
import {
  ErrorMessage,
  Loader,
  ProductImage,
  TextErrorMessage,
  TipsText,
  VariantSkuTag,
  VariantTag,
  ViewText
} from "../../components/viewComponents";
import { productTypeOptions } from "../../constantsData/productType";
import { convtPrice, convtQuantity } from "../../functions/functions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import ProductActions from "./ProductActions";
import ProductInfoTab from "./ProductInfoTab";
import SerialBatchNumbers from "./SerialBatchNumbers";
import inventoryStyle from "./inventoryStyle.module.css";

export default function PreviewProductScreen() {
  useDocumentTitle("Preview Product");
  const { id: productId } = useParams();

  const dispatch = useDispatch();

  const [tabName, setTabName] = useState("Basic Details");

  useEffect(() => {
    dispatch(productDetailsRequest(productId, "preview"));
    dispatch(serialBatchNumbersRequest(productId));
  }, [dispatch, productId]);

  const { loading, productInfo, error, success } = useSelector(
    (state) => state.productDetails
  );

  const {
    loading: numbersLoading,
    numbers,
    error: numebrsError,
    success: numbersSuccess,
  } = useSelector((state) => state.serialBatchNumbers);

  const changeTabRequest = (tab) => {
    setTabName(tab);
  };

  /////////////////////////////// Collect serial/batch numebrs ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////
  const [variantionsModalOpen, setVariantionsModalOpen] = useState(false);

  const openModal = () => {
    setVariantionsModalOpen(true);
  };

  const updateDetails = () => {};

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Preview Product" />
      <ScreenTitle title="Preview Product" />

      {/* <ContentModal
        title="Add Serial/Batch Numbers"
        isModalOpen={variantionsModalOpen}
        closeModal={() => setVariantionsModalOpen(false)}
        content={
          <AddSerialBatchNumbersForm
            orderId={rentalReturnOrderId}
            onSubmit={closeDialogWithSuccessRentalReturn}
          />
        }
      /> */}

      {loading ? (
        <Loader mess="Requesting product details, please wait a moment..." />
      ) : success ? (
        <div>
          <div style={{ margin: "20px" }}>
            <h1 className="text-xl font-medium"><VariantSkuTag name={productInfo.basicInfo.sku} /> {productInfo.basicInfo.productName}</h1>
            <div
              style={{
                width: "100%",
                paddingTop: "32px",
                paddingBottom: "16px",
              }}
            >
              <ProductActions
                product={{
                  id: productId,
                  name: productInfo.basicInfo.productName,
                  status: productInfo.basicInfo.status,
                  locations: productInfo.basicInfo.location,
                }}
                showPreview={false}
                reloadList={updateDetails}
              />
            </div>
          </div>

          <div className={commonStyle.tabsContainer}>
            <ProductInfoTab
              tabName={tabName}
              serialized={productInfo.basicInfo.serialized}
              batchTracked={productInfo.basicInfo.batchTracked}
              changeTab={changeTabRequest}
            />
            <div className={commonStyle.settingsContent}>
              {tabName === "Basic Details" ? (
                <div>
                  <div className={inventoryStyle.stockLevelCon}>
                    <div className={commonStyle.previewWrapper}>
                      <div className={commonStyle.col1}>
                      <ViewText
                          title="Product Code"
                          value={productInfo.basicInfo.sku}
                        />
                        <ViewText
                          title="Product Name"
                          value={productInfo.basicInfo.productName}
                        />
                        <ViewText
                          title="Category"
                          value={productInfo.basicInfo.categoryName}
                        />
                       
                       
                        <ViewText
                          title="Description"
                          value={
                            productInfo.basicInfo.description
                              ? productInfo.basicInfo.description
                              : "null"
                          }
                        />
                        <ViewText
                          title="Serialized? "
                          value={
                            productInfo.basicInfo.serialized ? "Yes" : "No"
                          }
                        />
                        <ViewText
                          title="Batch Tracked? "
                          value={
                            productInfo.basicInfo.batchTracked ? "Yes" : "No"
                          }
                        />
                        {productInfo.basicInfo.syncStatus === "ISSUED" ? (
                          <XeroLink
                            invoiceId={productInfo.basicInfo.syncStockId}
                            type="on_hand_stock_journal"
                          />
                        ) : (
                          <></>
                        )}
                      </div>

                      <div className={commonStyle.col2}>
                      <ViewText
                          title="Product Type"
                          value={
                            productTypeOptions.filter(
                              (x) => x.value === productInfo.basicInfo.type
                            )[0].label
                          }
                        />
                        
                        <ViewText
                          title="Unit"
                          value={
                            productInfo.basicInfo.unitName
                              ? productInfo.basicInfo.unitName
                              : "null"
                          }
                        />
                         <ViewText
                          title="Supplier"
                          value={
                            productInfo.basicInfo.supplierName
                              ? productInfo.basicInfo.supplierName
                              : "null"
                          }
                        />

                        <ViewText
                          title="Reference"
                          value={
                            productInfo.basicInfo.reference
                              ? productInfo.basicInfo.reference
                              : "null"
                          }
                        />
                      </div>

                      <div className={commonStyle.col3}>
                        <ProductImage
                          alt="product preview"
                          src={productInfo.basicInfo.image}
                        />
                      </div>
                    </div>
                  </div>
{/* 
                  {productInfo.basicInfo.attributeNames.length > 0 ? (
                    <div className={inventoryStyle.basicPrice}>
                      <SubTitle name="Product Variants" />
                      <div style={{ width: "50%" }}>
                        <table className={inventoryStyle.dynTable}>
                          <thead>
                            <tr>
                              <th style={{ width: "20%" }}>Attribute Name</th>
                              <th>Options</th>
                            </tr>
                          </thead>
                          <tbody>
                            {productInfo.basicInfo.attrs.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{item.attrName}</td>
                                  <td>
                                    {item.attrOptions.map((x, index1) => {
                                      return (
                                        <span key={index1}>
                                          {" "}
                                          <VariantTag name={x.optionName} />
                                        </span>
                                      );
                                    })}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )} */}
                </div>
              ) : (
                <></>
              )}

              {tabName === "Stock Level" ? (
                <div className={inventoryStyle.basicPrice}>
                  {productInfo.basicInfo.location.length > 0 ? (
                    productInfo.basicInfo.location.map((loc, index1) => (
                      <div key={index1}>
                        <div className={inventoryStyle.locationTitle}>
                          <MdLocationPin />
                          <span>{loc.value}</span>
                        </div>
                        <table className={inventoryStyle.dynTable}>
                          <thead>
                            <tr>
                              {productInfo.basicInfo.attributeNames.length >
                              0 ? (
                                productInfo.basicInfo.attributeNames.map(
                                  (item, index2) => {
                                    return <th key={index2}>{item}</th>;
                                  }
                                )
                              ) : (
                                <></>
                              )}
                              <th>Code</th>
                              <th>Barcode</th>
                              <th>Sale Price</th>
                              <th>Latest Cost</th>
                              <th>Unit</th>
                              <th>Stock on Hand</th>
                              <th>Re-order Level</th>
                            </tr>
                          </thead>
                          <tbody>
                            {productInfo.attrsDetails.map((item, index3) =>
                              Number(item.locationId) !== Number(loc.id) ? (
                                ""
                              ) : (
                                <tr key={index3}>
                                  {productInfo.basicInfo.attributeNames.length >
                                  0 ? (
                                    productInfo.basicInfo.attributeNames.map(
                                      (key, index3) => {
                                        return (
                                          <td key={index3}>
                                            <VariantTag name={item[key]} />
                                          </td>
                                        );
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}
                                  <td style={{ width: "15%" }}>
                                    <VariantSkuTag name={item.variantSku} />
                                  </td>
                                  <td style={{ width: "15%" }}>
                                    {item.barcode}
                                  </td>
                                  <td style={{ width: "15%" }}>
                                    {convtPrice(item.salePrice)}
                                  </td>
                                  <td style={{ width: "15%" }}>
                                    {convtPrice(item.latestCost)}
                                  </td>
                                  <td>{productInfo.basicInfo.unitName}</td>
                                  <td style={{ width: "15%" }}>
                                    {convtQuantity(item.stockLevel)}
                                  </td>
                                  <td style={{ width: "15%" }}>
                                    {convtQuantity(item.reorderLevel)}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    ))
                  ) : (
                    <TipsText text="There are no stock on hand."></TipsText>
                  )}
                </div>
              ) : (
                <></>
              )}

              {tabName === "Serial Numbers" ? (
                <div className={inventoryStyle.basicPrice}>
                  {numbersLoading ? (
                    <Loader mess="Requesting data, please wait a moment..." />
                  ) : numbersSuccess ? (
                    <div>
                      {productInfo.attrsDetails.reduce(
                        (accumulator, currentValue) => {
                          return accumulator + Number(currentValue.stockLevel ? currentValue.stockLevel : 0);
                        },
                        0
                      ) !==
                      numbers.numbers.reduce((accumulator, currentValue) => {
                        return accumulator + Number(currentValue.quantity_in_stock);
                      }, 0) ? (
                        <div>
                          <TextErrorMessage mess="The quantity of serial numbers is not equal to the stock on hand" />
                        </div>
                      ) : (
                        <></>
                      )}
                      {numbers.numbers.length > 0 ? (
                        <SerialBatchNumbers
                          locations={numbers.locations}
                          variants={numbers.variants}
                          numbers={numbers.numbers}
                        />
                      ) : (
                        <p>There are no serial numbers for this product.</p>
                      )}
                    </div>
                  ) : (
                    <ErrorMessage mess={numebrsError} />
                  )}
                </div>
              ) : (
                <></>
              )}

              {tabName === "Batch Numbers" ? (
                <div className={inventoryStyle.basicPrice}>
                  {numbersLoading ? (
                    <Loader mess="Requesting data, please wait a moment..." />
                  ) : numbersSuccess ? (
                    <div>
                      {productInfo.attrsDetails.reduce(
                        (accumulator, currentValue) => {
                          return accumulator + Number(currentValue.stockLevel ? currentValue.stockLevel : 0);
                        },
                        0
                      ) !==
                      numbers.numbers.reduce((accumulator, currentValue) => {
                        return accumulator + Number(currentValue.quantity_in_stock);
                      }, 0) ? (
                        <div>
                          <TextErrorMessage mess="The quantity of batch numbers is not equal to the stock on hand" />
                        </div>
                      ) : (
                        <></>
                      )}

                      {numbers.numbers.length > 0 ? (
                        <SerialBatchNumbers
                          locations={numbers.locations}
                          variants={numbers.variants}
                          numbers={numbers.numbers}
                        />
                      ) : (
                        <p>There are no batch numbers for this product.</p>
                      )}
                    </div>
                  ) : (
                    <ErrorMessage mess={numebrsError} />
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      ) : (
        <ErrorMessage mess={error} />
      )}
    </div>
  );
}
