import React from "react";
import billingStyle from "./billingStyle.module.css";
import { MdOutlineDone } from "react-icons/md";
import { AiOutlineExclamationCircle } from "react-icons/ai";

export default function BillingSteps({ step1, step2, paidSuccess, paidFailed }) {
  let step1Completed;
  let step2Completed;
  if (step1 && step2) {
    step1Completed = true;
  }

  if (paidSuccess) {
    step2Completed = true;
  }

  const StepName = ({ name }) => {
    return <p className={billingStyle.stepName}>{name}</p>;
  };

  return (
    <div className={billingStyle.stepsCon}>
      {step1Completed ? (
        <div className={`${billingStyle.step} ${billingStyle.completed}`}>
          <MdOutlineDone className={billingStyle.stepNumCompleted} />
          <StepName name="Select a Plan" />
        </div>
      ) : (
        <div
          className={
            step1
              ? `${billingStyle.step} ${billingStyle.active}`
              : billingStyle.step
          }
        >
          <div
            className={
              step1 ? billingStyle.stepNumActive : billingStyle.stepNum
            }
          >
            1
          </div>
          <StepName name="Select a Plan" />
        </div>
      )}

      {step1Completed ? (
        <hr className={billingStyle.stepLineCompleted} />
      ) :  (
        <hr
          className={
            step2 ? billingStyle.stepLineColored : billingStyle.stepLine
          }
        />
      )}

      {step2Completed ? (
        <div className={`${billingStyle.step} ${billingStyle.completed}`}>
          <MdOutlineDone className={billingStyle.stepNumCompleted} />
          <StepName name="Payment" />
        </div>
      ) : paidFailed ? <div className={`${billingStyle.step} ${billingStyle.failed}`}>
      <AiOutlineExclamationCircle className={billingStyle.stepNumFailed} />
      <StepName name="Payment" />
    </div> : (
        <div
          className={
            step2
              ? `${billingStyle.step} ${billingStyle.active}`
              : billingStyle.step
          }
        >
          <div
            className={
              step2 ? billingStyle.stepNumActive : billingStyle.stepNum
            }
          >
            2
          </div>
          <StepName name="Payment" />
        </div>
      )}
    </div>
  );
}
