import React from "react";
import { useSelector } from "react-redux";
import { ScreenTitle } from "../../components/editComponents";
import {
  ErrorMessage,
  Loader,
  ViewText,
  RoleTag,
} from "../../components/viewComponents";
import navStyle from "./navStyle.module.css";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import Breadcrumbs from "../../components/Breadcrumbs";

export default function UserAccount() {
  useDocumentTitle("Profile");
  const { userDetails, loading, error, success } = useSelector(
    (state) => state.userDetail
  );

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="My Profile" />

      <ScreenTitle title="My Profile" />
      <div>
        {loading ? (
          <Loader mess="Requesting details, please wait a moment..." />
        ) : error ? (
          <ErrorMessage mess={error} />
        ) : success ? (
          <div className={navStyle.accountCon}>
            {/* user details container */}
            <div className={navStyle.detailCon}>
              <h4>Account Detail</h4>
              <div className={navStyle.infoCon}>
                <div>
                  <ViewText
                    title="First Name"
                    value={userDetails.data.first_name}
                  />

                  <ViewText title="Email" value={userDetails.data.email} />
                  <ViewText
                    title="Department"
                    value={
                      userDetails.data.department_name
                        ? userDetails.data.department_name
                        : "Default"
                    }
                  />
                </div>
                <div style={{ marginLeft: "80px" }}>
                  <ViewText
                    title="Last Name"
                    value={userDetails.data.last_name}
                  />
                  <ViewText title="Phone" value={userDetails.data.phone} />

                  <ViewText
                    title="Position"
                    value={
                      userDetails.data.position
                        ? userDetails.data.position
                        : "none"
                    }
                  />
                </div>
              </div>
            </div>
            {/* role tag container */}
            <div className={navStyle.detailCon}>
              <h4 style={{ marginBottom: "20px" }}>Roles</h4>
              <RoleTag
                userRoles={userDetails.data.roles ? userDetails.data.roles : []}
              />
            </div>

            <div className={navStyle.infoCon}></div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
