import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Header from "../../components/Header/Header";
import billingStyle from "./billingStyle.module.css";
import { K_PRICES } from "../../constantsData/planPrices";
import BillingStepOne from "./BillingStepOne";
import BillingStepTwo from "./BillingStepTwo";
import BillingSuccess from "./BillingSuccess";
import BillingFailed from "./BillingFailed";
import { convtPrice } from "../../functions/functions";
import { Loader } from "../../components/viewComponents";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function BillingScreen() {
  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  // stripe payment status
  const [afterCheckout, setAfterCheckout] = useState(false);
  const [checkoutRequestStatus, setcheckoutRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [paidFailed, setPaidFailed] = useState(false);
  const [paidSuccess, setPaidSuccess] = useState(false);

  // switch button



  const step1 = true;
  const [step2, setStep2] = useState(false);
  // order details
  const [annually, setAnnually] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("business");
  const [extraUsers, setExtraUsers] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const [discount, setDiscount] = useState({
    type: "percent",
    value: 0,
  });
 

  const [extraUserPrice, setExtraUserPrice] = useState(0);
  const [planPrice, setPlanPrice] = useState(0);
  const [subtotalPrice, setSubtotalPrice] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [subtotalLessDiscountPrice, setSubtotalLessDiscountPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [gst, setGst] = useState(0);

  const handlePlanChange = (planName) => {
    setSelectedPlan(planName);
    setExtraUsers(0);
  };

  const saveCouponCode = (code) => {
    setCouponCode(code);
  };

  const saveDiscount = (type, value) => {
    setDiscount({ type: type, value: value });
  };

  const nextAction = () => {

    let userCountry = userDetails ? userDetails.data.orgInfo.country : "New Zealand";
    let userPrice = 0;
    let planPriceCal = 0;
    let subtotalPriceCal = 0;
    let discountPriceCal = 0;
    let subtotalLessDiscountPriceCal = 0;
    let gstCal = 0;
    let totalPriceCal = 0;

    let userPlanPrices =
      userCountry === "New Zealand"
        ? K_PRICES.nz
        : userCountry === "Australia"
        ? K_PRICES.au
        : userCountry === "United Kingdom"
        ? K_PRICES.uk
        : K_PRICES.us;

    if (annually) {
      if (selectedPlan === "business") {
        userPrice = Number(extraUsers) * 49 * 12;
        planPriceCal = Number(userPlanPrices.business.annually);
      }
      if (selectedPlan === "enterprise") {
        userPrice = Number(extraUsers) * 39 * 12;
        planPriceCal = Number(userPlanPrices.enterprise.annually);
      }
      if (selectedPlan === "startUp") {
        planPriceCal = Number(userPlanPrices.startUp.annually);
      }
      if (selectedPlan === "basic") {
        planPriceCal = Number(userPlanPrices.basic.annually);
      }
    } else {
      if (selectedPlan === "business") {
        userPrice = Number(extraUsers) * 49;
        planPriceCal = Number(userPlanPrices.business.monthly);
      }
      if (selectedPlan === "enterprise") {
        userPrice = Number(extraUsers) * 39;
        planPriceCal = Number(userPlanPrices.enterprise.monthly);
      }
      if (selectedPlan === "startUp") {
        planPriceCal = Number(userPlanPrices.startUp.monthly);
      }
      if (selectedPlan === "basic") {
        planPriceCal = Number(userPlanPrices.basic.monthly);
      }
    }
    setExtraUserPrice(userPrice);

    setPlanPrice(planPriceCal);

    subtotalPriceCal = userPrice + planPriceCal;
    setSubtotalPrice(subtotalPriceCal);

    if (discount.type === "percent") {
      discountPriceCal = convtPrice(
        subtotalPriceCal * (Number(discount.value) / 100)
      );
    }
    if (discount.type === "amount") {
      discountPriceCal = convtPrice(Number(discount.value));
    }
    setDiscountPrice(discountPriceCal);

    subtotalLessDiscountPriceCal = subtotalPriceCal - discountPriceCal;
    setSubtotalLessDiscountPrice(subtotalLessDiscountPriceCal);

    if (userCountry === "New Zealand"){
      gstCal = subtotalLessDiscountPriceCal * 0.15;
    }

    setGst(gstCal);
    totalPriceCal = subtotalLessDiscountPriceCal + gstCal;
    setTotalPrice(totalPriceCal);

    setStep2(true);
  };

  const checkoutRequest = () => {
    let checkoutValues = {
      planName: selectedPlan === "startUp" ? "start_up" : selectedPlan,
      paidAnnually: annually,
      extraUserNum: extraUsers,
      couponCode: discount.value ? couponCode : "",
    };

    try {
      setcheckoutRequestStatus({ ...checkoutRequestStatus, loading: true });
      axios
        .post(SERVER_URL + `/billing/checkout`, checkoutValues, config)
        .then(async (response) => {
          if (response.data.success) {
            setAfterCheckout(true);
            setcheckoutRequestStatus({
              ...checkoutRequestStatus,
              loading: false,
              success: true,
            });
            window.location.href = response.data.data.checkout_url;
          } else {
            if(typeof response.data.message === "string"){
              throw response.data.message;
            }else{
              throw "Connection error, please try again later."
            }
          }
        })
        .catch((err) => {
          setcheckoutRequestStatus({
            ...checkoutRequestStatus,
            loading: false,
            error: typeof err === "string" ? err : "Connection error, please try again later.",
          });
        });
    } catch (error) {
      setcheckoutRequestStatus({
        ...checkoutRequestStatus,
        loading: false,
        error: "Connection error, please try again later.",
      });
    }
  };

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };

    const query = new URLSearchParams(window.location.search);
    let session_id = query.get("session_id");

    if (query.get("success")) {
      setAfterCheckout(true);
      setPaidSuccess(true);
      // call backend api pass session_id
      let sessionValues = {
        sessionId: session_id,
      };

      try {
        axios
          .post(SERVER_URL + `/billing/pass_session`, sessionValues, config)
          .then(async (response) => {
            if (response.data.success) {
              // console.log("pass session success");
            }
          })
          .catch((err) => {});
      } catch (error) {}
    }

    if (query.get("canceled")) {
      setPaidSuccess(false);
      setPaidFailed(true);
      setAfterCheckout(true);
    }
  }, [SERVER_URL, userInfo.data.token]);

  return (
    <div className={billingStyle.container}>
      <Header />
      {userDetails && userDetailsSuccess ? (
        <div>
          {afterCheckout ? (
            <>
              {paidSuccess ? (
                <BillingSuccess
                  paidSuccess={paidSuccess}
                  isStep1={step1}
                  isStep2={step2}
                />
              ) : paidFailed ? (
                <BillingFailed paidFailed={paidFailed} />
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              {step1 && !step2 ? (
                <BillingStepOne
                  userPlanPrices={
                    userDetails.data.orgInfo.country === "New Zealand"
                      ? K_PRICES.nz
                      : userDetails.data.orgInfo.country === "Australia"
                      ? K_PRICES.au
                      : userDetails.data.orgInfo.country === "United Kingdom"
                      ? K_PRICES.uk
                      : K_PRICES.us
                  }
                  selectedPlan={selectedPlan}
                  annually={annually}
                  onPlanSelect={handlePlanChange}
                  onPayMethodChange={() => setAnnually(!annually)}
                  extraUsers={extraUsers}
                  couponCode={couponCode}
                  setCouponCode={saveCouponCode}
                  discount={discount}
                  setDiscount={saveDiscount}
                  setExtraUsers={setExtraUsers}
                  nextAction={nextAction}
                  isStep1={step1}
                  isStep2={step2}
                  userCountry={userDetails.data.orgInfo.country}
                  userCurrency={
                    userDetails.data.orgInfo.country === "New Zealand"
                      ? "NZD"
                      : userDetails.data.orgInfo.country === "Australia"
                      ? "AUD"
                      : userDetails.data.orgInfo.country === "United Kingdom"
                      ? "GBP"
                      : "USD"
                  }
                  currencyLabel={
                    userDetails.data.orgInfo.country === "New Zealand"
                      ? "$"
                      : userDetails.data.orgInfo.country === "Australia"
                      ? "$"
                      : userDetails.data.orgInfo.country === "United Kingdom"
                      ? "£"
                      : "$"
                  }
                />
              ) : step1 && step2 ? (
                <BillingStepTwo
                  selectedPlan={selectedPlan}
                  annually={annually}
                  extraUsers={extraUsers}
                  planPrice={planPrice}
                  extraUserPrice={extraUserPrice}
                  subtotal={subtotalPrice}
                  discountPrice={discountPrice}
                  subtotalLessDiscount={subtotalLessDiscountPrice}
                  totalPrice={totalPrice}
                  gst={gst}
                  isStep1={step1}
                  isStep2={step2}
                  backAction={() => setStep2(false)}
                  checkoutAction={checkoutRequest}
                  loading={checkoutRequestStatus.loading}
                  error={checkoutRequestStatus.error}
                  userCountry={userDetails.data.orgInfo.country}
                  userCurrency={
                    userDetails.data.orgInfo.country === "New Zealand"
                      ? "NZD"
                      : userDetails.data.orgInfo.country === "Australia"
                      ? "AUD"
                      : userDetails.data.orgInfo.country === "United Kingdom"
                      ? "GBP"
                      : "USD"
                  }
                  currencyLabel={
                    userDetails.data.orgInfo.country === "New Zealand"
                      ? "$"
                      : userDetails.data.orgInfo.country === "Australia"
                      ? "$"
                      : userDetails.data.orgInfo.country === "United Kingdom"
                      ? "£"
                      : "$"
                  }
                />
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}
