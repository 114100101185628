export const PRICE_TIER_LIST_REQUEST = "PRICE_TIER_LIST_REQUEST";
export const PRICE_TIER_LIST_SUCCESS = "PRICE_TIER_LIST_SUCCESS";
export const PRICE_TIER_LIST_FAIL = "PRICE_TIER_LIST_FAIL";

export const NEW_PRICE_TIER_REQUEST = "NEW_PRICE_TIER_REQUEST";
export const NEW_PRICE_TIER_SUCCESS = "NEW_PRICE_TIER_SUCCESS";
export const NEW_PRICE_TIER_FAIL = "NEW_PRICE_TIER_FAIL";

export const UPDATE_PRICE_TIER_REQUEST = "UPDATE_PRICE_TIER_REQUEST";
export const UPDATE_PRICE_TIER_SUCCESS = "UPDATE_PRICE_TIER_SUCCESS";
export const UPDATE_PRICE_TIER_FAIL = "UPDATE_PRICE_TIER_FAIL";

export const DELETE_PRICE_TIER_REQUEST = "DELETE_PRICE_TIER_REQUEST";
export const DELETE_PRICE_TIER_SUCCESS = "DELETE_PRICE_TIER_SUCCESS";
export const DELETE_PRICE_TIER_FAIL = "DELETE_PRICE_TIER_FAIL";

export const PRODUCT_VARIANT_DETAIL_REQUEST = "PRODUCT_VARIANT_DETAIL_REQUEST";
export const PRODUCT_VARIANT_DETAIL_SUCCESS = "PRODUCT_VARIANT_DETAIL_SUCCESS";
export const PRODUCT_VARIANT_DETAIL_FAIL = "PRODUCT_VARIANT_DETAIL_FAIL";

export const UPDATE_PRODUCT_CUSTOM_PRICE_REQUEST = "UPDATE_PRODUCT_CUSTOM_PRICE_REQUEST";
export const UPDATE_PRODUCT_CUSTOM_PRICE_SUCCESS = "UPDATE_PRODUCT_CUSTOM_PRICE_SUCCESS";
export const UPDATE_PRODUCT_CUSTOM_PRICE_FAIL = "UPDATE_PRODUCT_CUSTOM_PRICE_FAIL";