import React, { useEffect, useState } from "react";
import uploaderStyle from "../style/fileUploaderStyle.module.css";
import {
  SimpleBlueButton,
  XDeleteButton,
} from "./editComponents";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { FaFileCsv } from "react-icons/fa";
import { TextErrorMessage } from "./viewComponents";

export default function UploadFileField({
  requiredFileType,
  onSubmit,
  showBtn,
  btnName,
  uploadFileSuccess
}) {
  // drag state
  const [dragActive, setDragActive] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [fileError, setFileError] = useState("");

  // triggers when file is dropped
  const dropHandler = (ev) => {

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
    setFileError("");

    if ( ev.dataTransfer && ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      [...ev.dataTransfer.items].forEach((item, i) => {
        setDragActive(false);
        // If dropped items aren't files, reject them

        if (item.kind === "file") {
          if(requiredFileType === "csv"){
            if (item.type === "text/csv") {
              const file = item.getAsFile();
              setSelectedFile(file);
            } else {
              setFileError("Only accept csv file");
            }
          } else if(requiredFileType === "word"){
            if (item.type === "application/msword" ||
              item.type ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
              const file = item.getAsFile();
              setSelectedFile(file);
            } else {
              setFileError("Only accept .docx file");
            }
          }

        } else {
          setFileError("Only file accepted.");
        }
      });
    } else {
      // Use DataTransfer interface to access the file(s)
      [...ev.dataTransfer.files].forEach((file, i) => {
        setDragActive(false);
        if(requiredFileType === "csv"){
          if (file.type === "text/csv") {
            setSelectedFile(file);
          } else {
            setFileError("Only accept csv file");
          }

        } else if (requiredFileType === "word"){

          if (file.type === "application/msword" ||
            file.type ===
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            setSelectedFile(file);
          } else {
            setFileError("Only accept .docx file");
          }

        }
       
      });
    }
  };

  const dragOverHandler = (ev) => {
    ev.preventDefault();
    setDragActive(true);
  };

  const handleUpload = (e) => {
    setFileError("");
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setDragActive(false);
      setSelectedFile(e.target.files[0]);
    }
  };

  const deleteFile = () => {
    setSelectedFile();
  };

  const checkValidation = () => {
    if (selectedFile) {
      onSubmit(selectedFile);
    } else {
      setFileError("Please add a file");
    }
  };

  useEffect(()=>{

    if(uploadFileSuccess){
      setSelectedFile("");
    }
  },[uploadFileSuccess])

  return (
    <div>
      {fileError ? <TextErrorMessage mess={fileError} /> : <></>}
      {selectedFile ? (
        <div className={uploaderStyle.fileCon}>

          <div className={uploaderStyle.fileRow}>
            <FaFileCsv className={uploaderStyle.csvIcon} />{" "}
            <a
              href={URL.createObjectURL(selectedFile)}
              download={selectedFile.name}
              target="_blank"
              rel="noreferrer"
              className={uploaderStyle.fileName}
            >
              <p>{selectedFile.name}</p>
            </a>
            {showBtn ? <XDeleteButton action={deleteFile} /> : <></> }
          </div>
        </div>
      ) : (
        <div
          className={uploaderStyle.uploadForm}
          onDrop={dropHandler}
          onDragOver={dragOverHandler}
        >
          <input
            type="file"
            id="input-file-upload"
            className={uploaderStyle.inputField}
            accept={`${requiredFileType === "csv" ? ".csv" : ".docx"}`}
            onChange={handleUpload}
          />
          <label
            id="label-file-upload"
            htmlFor="input-file-upload"
            className={
              dragActive
                ? `${uploaderStyle.inputFieldLabel} ${uploaderStyle.dragActive}`
                : uploaderStyle.inputFieldLabel
            }
          >
            <div className={uploaderStyle.uploadTips}>
              <AiOutlineCloudUpload className={uploaderStyle.uploadImg} />
              <div>Drag and drop, or<span className={uploaderStyle.browse}>browse</span> your files</div>
            </div>
          </label>
        </div>
      )}
      {showBtn ? (
      <div className="w-full mt-6">

        <SimpleBlueButton
          name={btnName}
          action={checkValidation}
        />
      </div>
         
      ) : (
        <></>
       
      )}

    </div>
  );
}
