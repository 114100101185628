import React from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import {
  ScreenTitle
} from "../../components/editComponents";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import XeroIntegrationTab from "./XeroIntegrationTab";
import ImportXeroCustomers from "./importXeroData/ImportXeroCustomers";
import ImportXeroSuppliers from "./importXeroData/ImportXeroSuppliers";
import ImportXeroProducts from "./importXeroData/ImportXeroProducts";

export default function XeroImportScreen() {
  useDocumentTitle("Xero Integration");

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Xero Integration" />
      <ScreenTitle title="Xero Integration" />
      <div className={commonStyle.tabsContainer}>
        <XeroIntegrationTab tabName="import" />
        <div className={commonStyle.settingsContent}>
          <div style={{display:"flex", justifyContent:"space-between",marginTop:"20px", marginBottom:"-10px"}}>
          <p>*You can import product, customers, suppliers data from your Xero system to StockUnify.</p>
          </div>
          <div className={commonStyle.pageContentContainer}>
            <div style={{width:"50%"}}>
            <ImportXeroCustomers />
            <ImportXeroSuppliers />
            <ImportXeroProducts />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
