import {
  ADD_SUPPLIER_FAIL,
  ADD_SUPPLIER_REQUEST,
  ADD_SUPPLIER_SUCCESS,
  CREATE_SUPPLIER_OPTIONS_FAIL,
  CREATE_SUPPLIER_OPTIONS_REQUEST,
  CREATE_SUPPLIER_OPTIONS_SUCCESS,
  DELETE_SUPPLIER_FAIL,
  DELETE_SUPPLIER_REQUEST,
  DELETE_SUPPLIER_SUCCESS,
  EDIT_SUPPLIER_FAIL,
  EDIT_SUPPLIER_REQUEST,
  EDIT_SUPPLIER_SUCCESS,
  SUPPLIER_DETAILS_FAIL,
  SUPPLIER_DETAILS_REQUEST,
  SUPPLIER_DETAILS_SUCCESS,
  SUPPLIER_LIST_FAIL,
  SUPPLIER_LIST_REQUEST,
  SUPPLIER_LIST_SUCCESS,
} from "../constants/supplierConstants";

export const createSupplierOptionsReducer = (
  state = { options: [] },
  action
) => {
  switch (action.type) {
    case CREATE_SUPPLIER_OPTIONS_REQUEST:
      return { loading: true };
    case CREATE_SUPPLIER_OPTIONS_SUCCESS:
      return { loading: false, options: action.payload, success: true };
    case CREATE_SUPPLIER_OPTIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createNewSupplierReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_SUPPLIER_REQUEST:
      return { loading: true };
    case ADD_SUPPLIER_SUCCESS:
      return { loading: false, success: true };
    case ADD_SUPPLIER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const editSupplierReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_SUPPLIER_REQUEST:
      return { loading: true };
    case EDIT_SUPPLIER_SUCCESS:
      return { loading: false, success: true };
    case EDIT_SUPPLIER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const supplierDetailsReducer = (
  state = { supplierInfo: [] },
  action
) => {
  switch (action.type) {
    case SUPPLIER_DETAILS_REQUEST:
      return { loading: true, success: false };
    case SUPPLIER_DETAILS_SUCCESS:
      return { loading: false, supplierInfo: action.payload, success: true };
    case SUPPLIER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const supplierListReducer = (state = { supplierList: [] }, action) => {
  switch (action.type) {
    case SUPPLIER_LIST_REQUEST:
      return { loading: true, success: false };
    case SUPPLIER_LIST_SUCCESS:
      return { loading: false, supplierList: action.payload, success: true };
    case SUPPLIER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteSupplierReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_SUPPLIER_REQUEST:
      return { loading: true, success: false };
    case DELETE_SUPPLIER_SUCCESS:
      return { loading: false, success: true };
    case DELETE_SUPPLIER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
