import React, { useState } from "react";
import { useSelector } from "react-redux";
import { LoadingButton, SimpleBlueButton } from "../../components/editComponents";
import { StyledInput } from "../../components/inputFields";
import { createProjectRequest, updateProjectRequest } from "../../api/projectServices";
import { ErrorMessage, Loader } from "../../components/viewComponents";

export default function ProjectForm({
  projectId,
  type,
  btnName,
  values,
  onSuccess,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  const [formValues, setFormValues] = useState(values);
  const [formErrors, setFormErrors] = useState({});
  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [updateStatus, setUpdateStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const checkValidation = () => {
    const hasErrors = validate(formValues);
    if (Object.keys(hasErrors).length === 0) {
      if(type === "new"){
        addNewProject();
      }else{
        editProject();
      }
      setFormErrors({});
    } else {
      setFormErrors(hasErrors);
    }
  };
  // handle change when input
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  // check input validation
  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Project name is required!";
    }
    if (!values.code) {
      errors.code = "Project code is required!";
    }
    return errors;
  };

  const addNewProject = async () => {
    try {
      setRequestStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await createProjectRequest(formValues, config);

      if (responseData.data.success) {
        let newPro = {
          id: responseData.data.data,
          label: formValues.code + "-" + formValues.name,
          value: formValues.code + "-" + formValues.name,
        };
        onSuccess(newPro);
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
          error: "",
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setRequestStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const editProject = async () => {
    try {
      setUpdateStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await updateProjectRequest(
        projectId,
        formValues,
        config
      );
      if (responseData.data.success) {
        onSuccess()
        setUpdateStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
          error: "",
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setUpdateStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  return (
    <div>
      <StyledInput
        label="Project Code"
        type="text"
        name="code"
        value={formValues.code}
        onChange={(code) =>
          handleChange({
            target: { value: code, name: "code" },
          })
        }
        error={formErrors.code}
      />

      <StyledInput
        label="Project Name"
        type="text"
        name="name"
        value={formValues.name}
        onChange={(name) =>
          handleChange({
            target: { value: name, name: "name" },
          })
        }
        error={formErrors.name}
      />

      {requestStatus.error ? (
        <div className="w-full mt-6">
          <ErrorMessage mess={requestStatus.error} />
        </div>
      ) : updateStatus.error ? (
        <div className="w-full mt-6">
          <ErrorMessage mess={updateStatus.error} />
        </div>
      ) : (
        <></>
      )}

      <div className="w-full mt-6">
        {requestStatus.loading ? (
          <LoadingButton name={"Creating project..."} />
        ) : updateStatus.loading ? (
          <LoadingButton name={"Updating project..."} />
        ) : (
          <SimpleBlueButton name={btnName} action={checkValidation} />
        )}
      </div>
    </div>
  );
}
