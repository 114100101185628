import React from "react";
import billingStyle from "./billingStyle.module.css";
import BillingSteps from "./BillingSteps";
import {
  SimpleBlueButton
} from "../../components/editComponents";
import { useLocation, useNavigate } from "react-router-dom";

export default function BillingSuccess({
  paidSuccess,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const gotoDashboard = () => {
    navigate("/", { replace: true, from: location });
  }

  const gotoManageSubscription = () => {
    navigate("/manage-subscription", { replace: true, from: location });
  }


  return (
    <div>
      <BillingSteps step1={true} step2={true} paidSuccess />
      <div className={billingStyle.billingSummaryWrapper}>
        <div className={billingStyle.payStatusCon}>
        <div className={billingStyle.paySuccessIcon}></div>
        <p className={billingStyle.paySuccessText}>Congratulations!</p>
        <p className={billingStyle.payStatusText}>Your subscription has been successfully processed. Welcome onboard!</p>
        <div className={billingStyle.actionsCon}>
          <div style={{marginRight: "20px"}}>
            <SimpleBlueButton name="Dashboard" action={gotoDashboard} />
          </div>
      
          <SimpleBlueButton name="Manage Subscription" action={gotoManageSubscription} />
        </div>
      
        </div>
       
      </div>
    </div>
  );
}
