import React from "react";
import { NavLink } from "react-router-dom";
import commonStyle from "../style/commonStyle.module.css";
import { menuItemsData } from "../menuItemsData";

export default function Breadcrumbs({ screenName }) {
  const allPath = menuItemsData();

  const getFullPath = (data) => {
    for (let index in data) {
      let n = data[index].pageName;
      if (n && n === screenName) {
        return [data[index]];
      }

      if (data[index].children) {
        let path = getFullPath(data[index].children);
        if (path.length) {
          path.unshift(data[index]);
          return path;
        }
      }
    }
    return [];
  };

  let path = getFullPath(allPath);

  return path.length > 0 ? (
    <div className={commonStyle.breadcrumbsContainer}>
      {path.map((x,index) => (
        index === 0 ? 
        <p className={commonStyle.breadcrumbsFirstItem}  key={index}> {x.pageName}</p>
        : index === path.length -1 ? 
        <p className={commonStyle.breadcrumbsLastItem}  key={index}> {x.pageName}</p> :
        <NavLink
          to={x.path}
          exact="true"
          key={index}
          className={commonStyle.breadcrumbsItem}
        >
          {x.pageName}
        </NavLink>
      ))}
    </div>
  ) : (
    <></>
  );
}
