import React, { useEffect, useState } from "react";
import {
  HiOutlineCurrencyDollar,
  HiOutlineLocationMarker,
  HiOutlineReceiptTax,
} from "react-icons/hi";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import {
  initialSettingsRequest
} from "../../actions/dashboardActions";
import {
  updateLocationRequest,
  updateSaleTaxRateRequest,
} from "../../actions/orgSettingsActions";
import { SimpleBlueButton } from "../../components/editComponents";
import {
  BlurColorBackground,
  Modal,
  SimpleDialog,
} from "../../components/viewComponents";
import viewStyle from "../../style/styledViewComponentsStyle.module.css";
import TaxForm from "../settings/TaxForm";
import LocationForm from "../settings/companySettings/LocationForm";

export default function OnboardingModal({ isModalOpen, closeModal }) {
  const dispatch = useDispatch();

  const {
    loading: upadteLoading,
    error: updateError,
    success: updateSuccess,
  } = useSelector((state) => state.updateSaleTaxRate);

  const {
    loading: upadteLocationLoading,
    error: updateLocationError,
    success: updateLocationSuccess,
  } = useSelector((state) => state.updateLocation);

  const { loading, initialSettings, success, error } = useSelector(
    (state) => state.initialSettingsData
  );

  const [editedRate, setEditRate] = useState("");
  const [editTaxModal, setEditTaxModal] = useState(false);
  const [updateTaxDialog, setUpdateTaxDialog] = useState(false);
  const [editedLocation, setEditLocation] = useState("");
  const [editLocationModal, setEditLocationModal] = useState(false);
  const [updateLocationDialog, setUpdateLocationDialog] = useState(false);

  useEffect(() => {
    dispatch(initialSettingsRequest());
  }, []);

  useEffect(() => {
    if (success) {
      setEditRate(initialSettings.taxrate);
      setEditLocation(initialSettings.location);
    }
  }, [success]);

  // update tax rate
  const updateRateRequest = (values) => {
    dispatch(updateSaleTaxRateRequest(values, values.id));
    setEditTaxModal(false);
    setUpdateTaxDialog(true);
  };

  const OpenTaxEditModal = () => {
    setEditTaxModal(true);
  };

  const closeTaxEditDialog = () => {
    setUpdateTaxDialog(false);
    dispatch(initialSettingsRequest());
  };

  // update location

  const editLocationRequest = (values) => {
    let locationNewValues = {
      locationName: values.name,
      locationAddress: values.address,
      locationDesc: values.description,
      isDefault: values.isDefault,
    };
    dispatch(updateLocationRequest(locationNewValues, values.id));
    setEditLocationModal(false);
    setUpdateLocationDialog(true);
  };

  const OpenLocationEditModal = () => {
    setEditLocationModal(true);
  };

  const closeLocationEditDialog = () => {
    setUpdateLocationDialog(false);
    dispatch(initialSettingsRequest());
  };

  return (
    <>
      <div>
        <ReactModal
          isOpen={isModalOpen}
          appElement={document.getElementById("root")}
          className={viewStyle.fullContentModal}
          overlayClassName={viewStyle.overlay}
          shouldFocusAfterRender={false}
          onRequestClose={closeModal}
          shouldCloseOnOverlayClick={true}
        >
          <div className={viewStyle.modalColorBg}>
            <BlurColorBackground />

            <div className={viewStyle.onboardingContentContainer}>
              <div className={viewStyle.modalLogoWrapper}>
                <div className={viewStyle.modalLogoCon}></div>
                <button className={viewStyle.skipButton} onClick={closeModal}>
                  Skip
                </button>
              </div>
              <div className={viewStyle.modalMainContentCon}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "50%",
                  }}
                >
                  <h1 style={{ fontWeight: "700" }}>Welcom to StockUnify!</h1>
                 
                  <p style={{ marginTop: "36px" }}>
                   We have set up the default location and tax rate for you
                  </p>
                  

                  <div className={viewStyle.taskActionItem}>
                    <div className={viewStyle.taskItemTitle}>
                      <HiOutlineReceiptTax className={viewStyle.taskIcon} />
                      <span>Your default tax rate</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        flexDirection: "column",
                      }}
                    >
                      {success ? (
                        <p>
                          {editedRate.taxCode + "(" + editedRate.taxRate + "%)"}
                        </p>
                      ) : (
                        <p></p>
                      )}

                      <button
                        className={viewStyle.taskActionBtn}
                        onClick={OpenTaxEditModal}
                      >
                        Not use this tax? update
                      </button>
                    </div>
                  </div>

                  <div className={viewStyle.taskActionItem}>
                    <div className={viewStyle.taskItemTitle}>
                      <HiOutlineLocationMarker className={viewStyle.taskIcon} />
                      <span>Your default location</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        flexDirection: "column",
                      }}
                    >
                      {success ? <p>{editedLocation.name}</p> : <p></p>}

                      <button
                        className={viewStyle.taskActionBtn}
                        onClick={OpenLocationEditModal}
                      >
                        Change location name
                      </button>
                    </div>
                  </div>
                  <div className="w-full mt-4">
                  <SimpleBlueButton
                    name="Let's Get Started"
                    action={closeModal}
                  />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>

      <Modal
        title="Edit Tax Rate"
        isModalOpen={editTaxModal}
        closeModal={() => setEditTaxModal(false)}
        content={
          <TaxForm
            values={editedRate}
            onSubmit={updateRateRequest}
            btnName="Update Tax Rate"
          />
        }
      />
      <SimpleDialog
        title="Edit Tax Rate"
        successMessage="Tax rate updated successfully"
        isDialogOpen={updateTaxDialog}
        closeDialog={() => setUpdateTaxDialog(false)}
        loading={upadteLoading}
        loadingMessage="Processing request..."
        success={updateSuccess}
        error={updateError}
        btnValue="Confirm"
        confirmAction={closeTaxEditDialog}
      />

      <Modal
        title="Update Location"
        isModalOpen={editLocationModal}
        closeModal={() => setEditLocationModal(false)}
        content={
          <LocationForm
            values={editedLocation}
            onSubmit={editLocationRequest}
            btnName="Update"
          />
        }
      />

      <SimpleDialog
        title="Update Location"
        successMessage="Location updated sucessfully."
        isDialogOpen={updateLocationDialog}
        closeDialog={() => setUpdateLocationDialog(false)}
        loading={upadteLocationLoading}
        loadingMessage="Processing request..."
        success={updateLocationSuccess}
        error={updateLocationError}
        btnValue="Confirm"
        confirmAction={closeLocationEditDialog}
      />
    </>
  );
}
