import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { LoadingButton, SimpleBlueButton } from "../editComponents";
import { StyledTextarea, StyledSelect } from "../inputFields";
import { ErrorText, SuccessText } from "../viewComponents";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function FeedbackForm() {
  const { userInfo } = useSelector((state) => state.userLogin);

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [feedbackContent, setFeedbackContent] = useState({
    module: "",
    content: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const modules = [
    {
      id: 1,
      label: "Inventory",
      value: "inventory",
    },
    {
      id: 2,
      label: "Sale",
      value: "sale",
    },
    {
      id: 3,
      label: "Purcahse",
      value: "sale",
    },
    {
      id: 4,
      label: "Custoemr",
      value: "customer",
    },
    {
      id: 5,
      label: "Supplier",
      value: "supplier",
    },
    {
      id: 6,
      label: "Settings",
      value: "settings",
    },
    {
      id: 7,
      label: "Other",
      value: "other",
    },
  ];

  // handle change when input
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeedbackContent({ ...feedbackContent, [name]: value });
  };

  // check input validation
  const checkValidation = (e) => {
    const hasErrors = validate(feedbackContent);

    if (Object.keys(hasErrors).length === 0) {
      submitFeedback();
    } else {
      setFormErrors(hasErrors);
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.module) {
      errors.module = "Module is required.";
    }
    if (!values.content) {
      errors.content = "Description is required.";
    }

    return errors;
  };

  const submitFeedback = () => {
    let submitedValues = {
      module: feedbackContent.module.label,
      content: feedbackContent.content,
    };
    try {
      setRequestStatus({ ...requestStatus, loading: true });

      axios
        .post(SERVER_URL + `/feedback`, submitedValues, config)
        .then(async (response) => {
          if (response.data.success) {
            setRequestStatus({
              ...requestStatus,
              loading: false,
              success: true,
            });
          } else {
            setRequestStatus({
              ...requestStatus,
              loading: false,
              error: "Feedback submitted failed, please try again later.",
            });
          }
        })
        .catch((err) => {
          setRequestStatus({
            ...requestStatus,
            loading: false,
            error: "Connection error, please try again later.",
          });
        });
    } catch (error) {
      setRequestStatus({
        ...requestStatus,
        loading: false,
        error: "Connection error, please try again later.",
      });
    }
  };

  return (
    <div>
      {requestStatus.success ? (
        <SuccessText mess="Feedback submitted successfully." />
      ) : (
        <div>
          {requestStatus.error ? (
            <ErrorText mess={requestStatus.error} />
          ) : (
            <></>
          )}
          <StyledSelect
            label="Module"
            placeHolder="Select module"
            selectOptions={modules}
            value={feedbackContent.module}
            onChange={(module) =>
              handleChange({
                target: { value: module, name: "module" },
              })
            }
            error={formErrors.module}
          />
          <StyledTextarea
            label="Problem Description"
            type="text"
            name="content"
            presetRows="8"
            value={feedbackContent.content}
            onChange={(content) =>
              handleChange({
                target: { value: content, name: "content" },
              })
            }
            error={formErrors.content}
          />
          <div className="w-full mt-6">
            {requestStatus.loading ? (
              <LoadingButton name="Submiting..." />
            ) : (
              <SimpleBlueButton name="Submit" action={checkValidation} />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
