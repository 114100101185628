import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  saleTaxRateListRequest,
  updateSaleTaxRateRequest,
  newSaleTaxRateRequest,
} from "../../actions/orgSettingsActions";
import {
  ScreenTitle,
  AddButton,
  EditButton,
  DeleteButton,
  DefaultLabel,
} from "../../components/editComponents";
import {
  Modal,
  SimpleDialog,
  ErrorMessage,
  Loader,
} from "../../components/viewComponents";
import TaxForm from "./TaxForm";
import commonStyle from "../../style/commonStyle.module.css";
import Breadcrumbs from "../../components/Breadcrumbs";
import useDocumentTitle from "../../useDocumentTitle";

export default function TaxSetting() {
  useDocumentTitle("Tax Setting")
  // set the Modal

  // access check for the actions
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const initialNewRateValues = {
    taxCode: "",
    taxName: "",
    taxRate: "",
    isDefault: false,
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(saleTaxRateListRequest());
  }, [dispatch]);

  // get data from store by using useSelector method
  const { loading, saleTaxRates, error, success } = useSelector(
    (state) => state.salesTaxRateList
  );

  const [newModal, setNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const [editedRate, setEditRate] = useState("");

  const editRateModal = (item) => {
    setEditRate(item);
    setEditModal(true);
  };

  // update department
  const {
    loading: upadteLoading,
    error: updateError,
    success: updateSuccess,
  } = useSelector((state) => state.updateSaleTaxRate);
  // update department
  const {
    loading: newLoading,
    error: newError,
    success: newSuccess,
  } = useSelector((state) => state.newSaleTaxRate);

  // mange dialog content, new and edit
  const [newDialog, setNewDialog] = useState(false);
  const [updateDialog, setUpdateDialog] = useState(false);

  const addNewRateRequest = (values) => {
    dispatch(newSaleTaxRateRequest(values));
    setNewModal(false);
    setNewDialog(true);
  };

  const updateRateRequest = (values) => {
    dispatch(updateSaleTaxRateRequest(values, values.id));
    setEditModal(false);
    setUpdateDialog(true);
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Modal
        title="New Tax Rate"
        isModalOpen={newModal}
        closeModal={() => setNewModal(false)}
        content={
          <TaxForm
            values={initialNewRateValues}
            onSubmit={addNewRateRequest}
            btnName="Add New Rate"
          />
        }
      />
      <Modal
        title="Edit Tax Rate"
        isModalOpen={editModal}
        closeModal={() => setEditModal(false)}
        content={
          <TaxForm
            values={editedRate}
            onSubmit={updateRateRequest}
            btnName="Update Tax Rate"
          />
        }
      />

      <SimpleDialog
        title="Add New Tax Rate"
        successMessage="New tax rate added successfully."
        isDialogOpen={newDialog}
        closeDialog={() => setNewDialog(false)}
        loading={newLoading}
        loadingMessage="Processing request..."
        success={newSuccess}
        error={newError}
        btnValue="Confirm"
        confirmAction={() => setNewDialog(false)}
      />

      <SimpleDialog
        title="Edit Tax Rate"
        successMessage="Tax rate updated successfully"
        isDialogOpen={updateDialog}
        closeDialog={() => setUpdateDialog(false)}
        loading={upadteLoading}
        loadingMessage="Processing request..."
        success={updateSuccess}
        error={updateError}
        btnValue="Confirm"
        confirmAction={() => setUpdateDialog(false)}
      />

      <Breadcrumbs screenName="Tax Rates" />
      <ScreenTitle title="Tax Rates" />

      <div className={commonStyle.pageContentContainer}>
        {loading ? (
          <Loader mess="Requesting tax rate list, please wait a moment.." />
        ) : success ? (
          <div className={commonStyle.shortDataTable}>
            {userDetailsSuccess ? (
              userDetails.data.permissions.includes(
                "add_delete_edit_tax_rates"
              ) ? (
                <AddButton
                  name="New Tax Rate"
                  action={() => setNewModal(true)}
                />
              ) : (
                <></>
              )
            ) : (
              <></>
            )}

            {/* hold the tax rates table */}
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Tax Code</th>
                  <th>Tax Name</th>
                  <th>Rate</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {saleTaxRates.length > 0 ? (
                  saleTaxRates.map((rate) => (
                    <tr key={rate.id}>
                      <td>
                        {rate.taxCode}{" "}
                        {rate.isDefault ? <DefaultLabel  name="Default"/> : <></>}
                      </td>
                      <td>{rate.taxName}</td>
                      <td>{rate.taxRate}</td>
                      <td>
                        {userDetailsSuccess ? (
                          userDetails.data.permissions.includes(
                            "add_delete_edit_tax_rates"
                          ) ? (
                            <div className={commonStyle.actionsGrop}>
                              <EditButton action={() => editRateModal(rate)} />
                              {/* <DeleteButton  /> */}
                            </div>
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                    There are no tax rate available. Please add a new tax rate now.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
    </div>
  );
}
