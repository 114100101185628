import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import SideMenu from "./components/SideMenu/SideMenu";
import Header from "./components/Header/Header";
import RegisterScreen from "./screens/login/RegisterScreen";
import LoginScreen from "./screens/login/LoginScreen";
import NoAuthority from "./screens/errorPages/NoAuthority";
import ActivateAccountScreen from "./screens/login/ActivateAccountScreen";
import { RequireAuth } from "./routesFunctions";
import { useDispatch, useSelector } from "react-redux";
import { userDetailsRequest } from "./actions/authAction";
import { menuItemsData } from "./menuItemsData";
import ResetPasswordScreen from "./screens/login/ResetPasswordScreen";
import PendingActivateAccountScreen from "./screens/login/PendingActivateAccountScreen";
import BillingScreen from "./screens/billing/BillingScreen";
import RegisterWithXeroScreen from "./screens/login/RegisterWithXeroScreen";
import NotFound from "./screens/errorPages/NotFound";
import ErrorBoundary from "./screens/errorPages/ErrorBoundary";
import DirectXeroRegisterScreen from "./screens/login/DirectXeroRegisterScreen";
import Unsubscribe from "./screens/errorPages/Unsubscribe";

function App() {
  const SidebarLayout = () => (
    <div>
      <Header />
      <div
        style={{
          display: "flex",
          height: "calc(100vh - 50px)",
          color: "var(--font-color)",
          fontSize: "var(--main-size)",
          position: "relative",
        }}
      >
        <SideMenu />
        <Outlet />
      </div>
    </div>
  );

  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);

  useEffect(() => {
    // if user is logged in , load to main page
    if (userInfo) {
      dispatch(userDetailsRequest());
    }
  }, [dispatch, userInfo]);

  // flatten routes
  const flattedRoutes = [];
  const findAllRoutes = (data) => {
    for (let index in data) {
      if (data[index].path) {
        flattedRoutes.push(data[index]);
      }
      if (data[index].children) {
        findAllRoutes(data[index].children);
      }
    }
  };

  // findAllRoutes(menuData);
  findAllRoutes(menuItemsData());

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Routes>
          <Route element={<SidebarLayout />}>
            {flattedRoutes.map((route) => (
              <Route
                exact
                path={route.path}
                element={
                  <RequireAuth
                    pageName={route.name}
                    permisson={route.permissionCheck}
                  >
                    {route.component}
                  </RequireAuth>
                }
                key={route.id}
              />
            ))}
          </Route>
          <Route
            exact
            path="/billing"
            element={
              <RequireAuth pageName="manage_subscription" permisson={true}>
                {<BillingScreen />}
              </RequireAuth>
            }
          />
          {/* <Route exact path="/billing" element={<BillingScreen />} /> */}
          <Route exact path="/login" element={<LoginScreen />} />
          <Route exact path="/register" element={<RegisterScreen />} />
          <Route
            exact
            path="/register/xero"
            element={<RegisterWithXeroScreen />}
          />
          <Route
            exact
            path="/register/xerosignup"
            element={<DirectXeroRegisterScreen />}
          />
          <Route
            exact
            path="/activation_pending"
            element={<PendingActivateAccountScreen />}
          />
          <Route
            exact
            path="/activate_account"
            element={<ActivateAccountScreen />}
          />
          <Route exact path="/set_password" element={<ResetPasswordScreen />} />
          <Route exact path="/no_permission" element={<NoAuthority />} />
          <Route exact path="/unsubscribe" element={<Unsubscribe />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
