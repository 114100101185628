const K_TIMEZONES = [
  {id: 1, value: "Etc/GMT+11", label: "(UTC-11:00) Coordinated Universal Time-11"},
  {id: 2, value: "US/Aleutian", label: "(UTC-10:00) Aleutian Islands"},
  {id: 3, value: "US/Hawaii", label: "(UTC-10:00) Hawaii"},
  {id: 4, value: "Pacific/Marquesas", label: "(UTC-09:30) Marquesas Islands"},
  {id: 5, value: "US/Alaska", label: "(UTC-09:00) Alaska"},
  {id: 6, value: "Etc/GMT+9", label: "(UTC-09:00) Coordinated Universal Time-09"},
  {id: 7, value: "America/Tijuana", label: "(UTC-08:00) Baja California"},
  {id: 8, value: "Etc/GMT+8", label: "(UTC-08:00) Coordinated Universal Time-08"},
  {id: 9, value: "US/Pacific", label: "(UTC-08:00) Pacific Time (US,Canada)"},
  {id: 10, value: "US/Arizona", label: "(UTC-07:00) Arizona"},
  {id: 11, value: "America/Mazatlan", label: "(UTC-07:00) Chihuahua, La Paz, Mazatlan"},
  {id: 12, value: "Canada/Mountain", label: "(UTC-07:00) Mountain Time (US,Canada)"},
  {id: 13, value: "America/Chicago", label: "(UTC-06:00) Central America"},
  {id: 14, value: "Canada/Central", label: "(UTC-06:00) Central Time (US, Canada)"},
  {id: 15, value: "Pacific/Easter", label: "(UTC-06:00) Easter Island"},
  {id: 16, value: "America/Monterrey", label: "(UTC-06:00) Guadalajara, Mexico City, Monterrey"},
  {id: 17, value: "Canada/Saskatchewan", label: "(UTC-06:00) Saskatchewan"},
  {id: 18, value: "America/Bogota", label: "(UTC-05:00) Bogota, Lima, Quito, Rio Branco"},
  {id: 19, value: "America/Cancun", label: "(UTC-05:00) Chetumal"},
  {id: 20, value: "Canada/Eastern", label: "(UTC-05:00) Eastern Time (US,Canada)"},
  {id: 21, value: "EST", label: "(UTC-05:00) Haiti"},
  {id: 22, value: "America/Havana", label: "(UTC-05:00) Havana"},
  {id: 23, value: "US/East-Indiana", label: "(UTC-05:00) Indiana (East)"},
  {id: 24, value: "EST", label: "(UTC-05:00) Turks and Caicos"},
  {id: 25, value: "America/Asuncion", label: "(UTC-04:00) Asuncion"},
  {id: 26, value: "Canada/Atlantic", label: "(UTC-04:00) Atlantic Time (Canada)"},
  {id: 27, value: "America/Caracas", label: "(UTC-04:00) Caracas"},
  {id: 28, value: "America/Cuiaba", label: "(UTC-04:00) Cuiaba"},
  {id: 29, value: "America/La_Paz", label: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan"},
  {id: 30, value: "America/Santiago", label: "(UTC-04:00) Santiago"},
  {id: 31, value: "Canada/Newfoundland", label: "(UTC-03:30) Newfoundland"},
  {id: 32, value: "America/Araguaina", label: "(UTC-03:00) Araguaina"},
  {id: 33, value: "Brazil/East", label: "(UTC-03:00) Brasilia"},
  {id: 34, value: "America/Cayenne", label: "(UTC-03:00) Cayenne, Fortaleza"},
  {id: 35, value: "America/Buenos_Aires", label: "(UTC-03:00) City of Buenos Aires"},
  {id: 36, value: "America/Godthab", label: "(UTC-03:00) Greenland"},
  {id: 37, value: "America/Montevideo", label: "(UTC-03:00) Montevideo"},
  {id: 38, value: "America/Punta_Arenas", label: "(UTC-03:00) Punta Arenas"},
  {id: 39, value: "America/Miquelon", label: "(UTC-03:00) Saint Pierre and Miquelon"},
  {id: 40, value: "America/Bahia", label: "(UTC-03:00) Salvador"},
  {id: 41, value: "Etc/GMT+2", label: "(UTC-02:00) Coordinated Universal Time-02"},
  {id: 42, value: "Atlantic/South_Georgia", label: "(UTC-02:00) Mid-Atlantic - Old"},
  {id: 43, value: "Atlantic/Azores", label: "(UTC-01:00) Azores"},
  {id: 44, value: "Atlantic/Cape_Verde", label: "(UTC-01:00) Cabo Verde Is."},
  {id: 45, value: "Etc/GMT", label: "(UTC) Coordinated Universal Time"},
  {id: 46, value: "Europe/London", label: "(UTC+00:00) Dublin, Edinburgh, Lisbon, London"},
  {id: 47, value: "Africa/Monrovia", label: "(UTC+00:00) Monrovia, Reykjavik"},
  {id: 48, value: "Africa/Sao_Tome", label: "(UTC+00:00) Sao Tome"},
  {id: 49, value: "Africa/Casablanca", label: "(UTC+01:00) Casablanca"},
  {id: 50, value: "Europe/Berlin", label: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"},
  {id: 51, value: "Europe/Belgrade", label: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague"},
  {id: 52, value: "Europe/Brussels", label: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris"},
  {id: 53, value: "Europe/Sarajevo", label: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb"},
  {id: 54, value: "Africa/Ceuta", label: "(UTC+01:00) West Central Africa"},
  {id: 55, value: "Asia/Amman", label: "(UTC+02:00) Amman"},
  {id: 56, value: "Europe/Athens", label: "(UTC+02:00) Athens, Bucharest"},
  {id: 57, value: "Asia/Beirut", label: "(UTC+02:00) Beirut"},
  {id: 58, value: "Africa/Cairo", label: "(UTC+02:00) Cairo"},
  {id: 59, value: "Europe/Chisinau", label: "(UTC+02:00) Chisinau"},
  {id: 60, value: "Asia/Damascus", label: "(UTC+02:00) Damascus"},
  {id: 61, value: "Asia/Gaza", label: "(UTC+02:00) Gaza, Hebron"},
  {id: 62, value: "Africa/Harare", label: "(UTC+02:00) Harare, Pretoria"},
  {id: 63, value: "Europe/Riga", label: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"},
  {id: 64, value: "Asia/Jerusalem", label: "(UTC+02:00) Jerusalem"},
  {id: 65, value: "Europe/Kaliningrad", label: "(UTC+02:00) Kaliningrad"},
  {id: 66, value: "Africa/Khartoum", label: "(UTC+02:00) Khartoum"},
  {id: 67, value: "Africa/Tripoli", label: "(UTC+02:00) Tripoli"},
  {id: 68, value: "Africa/Windhoek", label: "(UTC+02:00) Windhoek"},
  {id: 69, value: "Asia/Baghdad", label: "(UTC+03:00) Baghdad"},
  {id: 70, value: "Asia/Istanbul", label: "(UTC+03:00) Istanbul"},
  {id: 71, value: "Asia/Kuwait", label: "(UTC+03:00) Kuwait, Riyadh"},
  {id: 72, value: "Europe/Minsk", label: "(UTC+03:00) Minsk"},
  {id: 73, value: "Europe/Moscow", label: "(UTC+03:00) Moscow, St. Petersburg"},
  {id: 74, value: "Africa/Nairobi", label: "(UTC+03:00) Nairobi"},
  {id: 75, value: "Asia/Tehran", label: "(UTC+03:30) Tehran"},
  {id: 76, value: "Europe/Ulyanovsk", label: "(UTC+04:00) Astrakhan, Ulyanovsk"},
  {id: 77, value: "Europe/Samara", label: "(UTC+04:00) Izhevsk, Samara"},
  {id: 78, value: "Europe/Saratov", label: "(UTC+04:00) Saratov"},
  {id: 79, value: "Europe/Volgograd", label: "(UTC+04:00) Volgograd"},
  {id: 80, value: "Asia/Yerevan", label: "(UTC+04:00) Yerevan"},
  {id: 81, value: "Asia/Kabul", label: "(UTC+04:30) Kabul"},
  {id: 82, value: "Asia/Ashgabat", label: "(UTC+05:00) Ashgabat, Tashkent"},
  {id: 83, value: "Asia/Yekaterinburg", label: "(UTC+05:00) Yekaterinburg"},
  {id: 84, value: "Asia/Karachi", label: "(UTC+05:00) Islamabad, Karachi"},
  {id: 85, value: "Asia/Qyzylorda", label: "(UTC+05:00) Qyzylorda"},
  {id: 86, value: "Asia/Kolkata", label: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi"},
  {id: 87, value: "Asia/Colombo", label: "(UTC+05:30) Sri Jayawardenepura"},
  {id: 88, value: "Asia/Kathmandu", label: "(UTC+05:45) Kathmandu"},
  {id: 89, value: "Asia/Almaty", label: "(UTC+06:00) Astana"},
  {id: 90, value: "Asia/Dhaka", label: "(UTC+06:00) Dhaka"},
  {id: 91, value: "Asia/Omsk", label: "(UTC+06:00) Omsk"},
  {id: 92, value: "Asia/Yangon", label: "(UTC+06:30) Yangon (Rangoon)"},
  {id: 93, value: "Asia/Bangkok", label: "(UTC+07:00) Bangkok, Hanoi, Jakarta"},
  {id: 94, value: "Asia/Barnaul", label: "(UTC+07:00) Barnaul, Gorno-Altaysk"},
  {id: 95, value: "Asia/Hovd", label: "(UTC+07:00) Hovd"},
  {id: 96, value: "Asia/Krasnoyarsk", label: "(UTC+07:00) Krasnoyarsk"},
  {id: 97, value: "Asia/Novosibirsk", label: "(UTC+07:00) Novosibirsk"},
  {id: 98, value: "Asia/Tomsk", label: "(UTC+07:00) Tomsk"},
  {id: 99, value: "Asia/Shanghai", label: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi"},
  {id: 100, value: "Asia/Irkutsk", label: "(UTC+08:00) Irkutsk"},
  {id: 101, value: "Singapore", label: "(UTC+08:00) Kuala Lumpur, Singapore"},
  {id: 102, value: "Australia/Perth", label: "(UTC+08:00) Perth"},
  {id: 103, value: "Asia/Taipei", label: "(UTC+08:00) Taipei"},
  {id: 104, value: "Asia/Ulaanbaatar", label: "(UTC+08:00) Ulaanbaatar"},
  {id: 105, value: "Australia/Eucla", label: "(UTC+08:45) Eucla"},
  {id: 106, value: "Asia/Chita", label: "(UTC+09:00) Chita"},
  {id: 107, value: "Asia/Tokyo", label: "(UTC+09:00) Osaka, Sapporo, Tokyo"},
  {id: 108, value: "Asia/Pyongyang", label: "(UTC+09:00) Pyongyang"},
  {id: 109, value: "Asia/Seoul", label: "(UTC+09:00) Seoul"},
  {id: 110, value: "Asia/Yakutsk", label: "(UTC+09:00) Yakutsk"},
  {id: 111, value: "Australia/Adelaide", label: "(UTC+09:30) Adelaide"},
  {id: 112, value: "Australia/Darwin", label: "(UTC+09:30) Darwin"},
  {id: 113, value: "Australia/Brisbane", label: "(UTC+10:00) Brisbane"},
  {id: 114, value: "Australia/Canberra", label: "(UTC+10:00) Canberra, Melbourne, Sydney"},
  {id: 115, value: "Pacific/Guam", label: "(UTC+10:00) Guam, Port Moresby"},
  {id: 116, value: "Australia/Hobart", label: "(UTC+10:00) Hobart"},
  {id: 117, value: "Asia/Vladivostok", label: "(UTC+10:00) Vladivostok"},
  {id: 118, value: "Australia/Lord_Howe", label: "(UTC+10:30) Lord Howe Island"},
  {id: 119, value: "Pacific/Bougainville", label: "(UTC+11:00) Bougainville Island"},
  {id: 120, value: "Asia/Magadan", label: "(UTC+11:00) Magadan"},
  {id: 121, value: "Pacific/Norfolk", label: "(UTC+11:00) Norfolk Island"},
  {id: 122, value: "Asia/Kamchatka", label: "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky"},
  {id: 123, value: "Pacific/Auckland", label: "(UTC+12:00) Auckland, Wellington"},
  {id: 124, value: "Etc/GMT-12", label: "(UTC+12:00) Coordinated Universal Time+12"},
  {id: 125, value: "Pacific/Fiji", label: "(UTC+12:00) Fiji"},
  {id: 126, value: "Asia/Kamchatka", label: "(UTC+12:00) Petropavlovsk-Kamchatsky - Old"},
  {id: 127, value: "Pacific/Chatham", label: "(UTC+12:45) Chatham Islands"},
  {id: 128, value: "Etc/GMT-13", label: "(UTC+13:00) Coordinated Universal Time+13"},
  {id: 129, value: "Pacific/Tongatapu", label: "(UTC+13:00) Nuku'alofa"},
  {id: 130, value: "Pacific/Apia", label: "(UTC+13:00) Samoa"},
  {id: 131, value: "Pacific/Kiritimati", label: "(UTC+14:00) Kiritimati Island"},
  ];


  export {
    K_TIMEZONES
  };