import React from "react";
import inventoryStyle from "./inventoryStyle.module.css";
import { MdArrowRightAlt } from "react-icons/md";

export default function VariantTab({ variantsData, changeVariant, activeCode }) {

  return (
    <div className={inventoryStyle.variantTabcon}>
      {variantsData.map((item, index) => {
        return activeCode && Number(item.variantCode) === Number(activeCode) ? (
          <div key={index} className={inventoryStyle.variantItemActive}>
            <span style={{marginRight:"4px"}}>{item.variant}</span>
            <MdArrowRightAlt />
          </div>
        ) : !activeCode && index === 0 ? (
          <div key={index} className={inventoryStyle.variantItemActive}>
            <span style={{marginRight:"4px"}}>{item.variant}</span>
            <MdArrowRightAlt />
          </div>
        ) : (
          <div
            key={index}
            className={inventoryStyle.variantItem}
            onClick={() => changeVariant(item.variantCode)}
          >
            <span>{item.variant}</span>
          </div>
        );
      })}
    </div>
  );
}
