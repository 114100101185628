import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import menuStyle from "../../style/menuStyle.module.css";
import { SideMenuIcon } from "../icons";

function SideMenuItem({ item }) {
  const location = useLocation();
  const [isSubMenuOpen, setSubMenuOpen] = useState(false);

  const openSubMenu = () => {
    setSubMenuOpen(!isSubMenuOpen);
  }
  let showSidebar = false;
  let showChildren = false;

  if(item.children && item.children.length > 0 && item.isChildrenShow && item.isSidebarShow){
    showSidebar = true;
    showChildren = true;
  }
  if(item.children && item.children.length > 0 && !item.isChildrenShow && item.isSidebarShow){
    showSidebar = true;
  }
  if(!item.children && item.isSidebarShow){
    showSidebar = true;
  }

  return showChildren && showSidebar ? (
    // sidemen expanded, and item has childrens
    <div
     className={
      isSubMenuOpen
        ? `${menuStyle.sideMenuItemChildren} ${menuStyle.open}`
        : `${menuStyle.sideMenuItemChildren}`
    }
    >
      <div
        className={`${menuStyle.sideMenuItemTitle}`}
        onClick={openSubMenu}
      >
        <div className={menuStyle.sideMenuItemName}>
          <SideMenuIcon name={item.iconName} />
          <span className={menuStyle.sideMenuItemTitleName}>
            {item.sidemenuName}
          </span>
        </div>
        <MdKeyboardArrowDown className={menuStyle.toggleBtn} />
      </div>

      <div className={menuStyle.sideMenuContent}>
        {item.children.map((child, index) => (
          <NavLink
            to={child.path}
            exact="true"
            key={index}
            className={
              child.path === location.pathname
                ? `${menuStyle.subMenuItem} ${menuStyle.pathSelected}`
                : menuStyle.subMenuItem
            }
          >
            {child.sidemenuName}
          </NavLink>
        ))}
      </div>
    </div>
  ) : showSidebar && !showChildren ? (
    // sidemen expanded, and item has children but not show

    <NavLink
      to={item.path}
      exact="true"
      className={
        item.path === location.pathname
          ? `${menuStyle.sideMenuItemPlain} ${menuStyle.pathSelected}`
          : menuStyle.sideMenuItemPlain
      }
    >
      <SideMenuIcon name={item.iconName} />

      <div className={menuStyle.sideMenuItemNamePlain}>{item.sidemenuName}</div>
    </NavLink>
  ) : <></>;
}

export default SideMenuItem;
