import React, { useState } from "react";
import { useSelector } from "react-redux";
import { LoadingButton, SimpleBlueButton } from "../../components/editComponents";
import { StyledInput, StyledSelect } from "../../components/inputFields";
import { createProjectRequest, updateProjectRequest } from "../../api/projectServices";
import { ErrorMessage, Loader } from "../../components/viewComponents";
import { createAdjustReasonRequest, updateAdjustReasonRequest } from "../../api/adjustReasonsServices";
import { adjustTypeOptions } from "../../constantsData/adjustType";

export default function AdjustReasonForm({
  reasonId,
  type,
  btnName,
  values,
  onSuccess,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  const [formValues, setFormValues] = useState(values);
  const [formErrors, setFormErrors] = useState({});
  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [updateStatus, setUpdateStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const checkValidation = () => {
    const hasErrors = validate(formValues);
    if (Object.keys(hasErrors).length === 0) {
      if(type === "new"){
        addNewReason();
      }else{
        editReason();
      }
      setFormErrors({});
    } else {
      setFormErrors(hasErrors);
    }
  };
  // handle change when input
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  // check input validation
  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Adjust reason name is required!";
    }
    if (!values.adjustType) {
      errors.adjustType = "Adjust type is required!";
    }
    return errors;
  };

  const addNewReason = async () => {
    let savedValues = {
      name: formValues.name,
      adjustType: formValues.adjustType.value
    }
    try {
      setRequestStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await createAdjustReasonRequest(savedValues, config);

      if (responseData.data.success) {
        onSuccess();
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
          error: "",
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setRequestStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const editReason = async () => {
    let savedValues = {
      name: formValues.name,
      adjustType: formValues.adjustType.value
    }
 
    try {
      setUpdateStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await updateAdjustReasonRequest(
        reasonId,
        savedValues,
        config
      );
      if (responseData.data.success) {
        onSuccess()
        setUpdateStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
          error: "",
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setUpdateStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  return (
    <div>
        <StyledSelect
          label="Adjust Type*"
          selectOptions={adjustTypeOptions}
          value={formValues.adjustType}
          placeHolder="Select adjust type"
          clearable={false}
          onChange={(adjustType) =>
            handleChange({
              target: { value: adjustType, name: "adjustType" },
            })
          }
          error={formErrors.adjustType}
        />
      
      <StyledInput
        label="Reason Name*"
        type="text"
        name="name"
        value={formValues.name}
        onChange={(name) =>
          handleChange({
            target: { value: name, name: "name" },
          })
        }
        error={formErrors.name}
      />

      {requestStatus.error ? (
        <div className="w-full mt-6">
          <ErrorMessage mess={requestStatus.error} />
        </div>
      ) : updateStatus.error ? (
        <div className="w-full mt-6">
          <ErrorMessage mess={updateStatus.error} />
        </div>
      ) : (
        <></>
      )}

      <div className="w-full mt-6">
        {requestStatus.loading ? (
          <LoadingButton name={"Creating adjust reason..."} />
        ) : updateStatus.loading ? (
          <LoadingButton name={"Updating adjust reason..."} />
        ) : (
          <SimpleBlueButton name={btnName} action={checkValidation} />
        )}
      </div>
    </div>
  );
}
