//org settings
export const ORG_DETAILS_REQUEST = "ORG_DETAILS_REQUEST";
export const ORG_DETAILS_SUCCESS = "ORG_DETAILS_SUCCESS";
export const ORG_DETAILS_FAIL = "ORG_DETAILS_FAIL";

export const CHECK_ORG_DB_STATUS_REQUEST = "CHECK_ORG_DB_STATUS_REQUEST";
export const CHECK_ORG_DB_STATUS_SUCCESS = "CHECK_ORG_DB_STATUS_SUCCESS";
export const CHECK_ORG_DB_STATUS_FAIL = "CHECK_ORG_DB_STATUS_FAIL";


export const ORG_DETAILS_UPDATE_REQUEST = "ORG_DETAILS_UPDATE_REQUEST";
export const ORG_DETAILS_UPDATE_SUCCESS = "ORG_DETAILS_UPDATE_SUCCESS";
export const ORG_DETAILS_UPDATE_FAIL = "ORG_DETAILS_UPDATE_FAIL";

///////////////////////location/////////////////////////////
////////////////////////////////////////////////////////////
export const ORG_LOCATION_LIST_REQUEST = "ORG_LOCATION_LIST_REQUEST";
export const ORG_LOCATION_LIST_SUCCESS = "ORG_LOCATION_LIST_SUCCESS";
export const ORG_LOCATION_LIST_FAIL = "ORG_LOCATION_LIST_FAIL";

export const NEW_ORG_LOCATION_REQUEST = "NEW_ORG_LOCATION_REQUEST";
export const NEW_ORG_LOCATION_SUCCESS = "NEW_ORG_LOCATION_SUCCESS";
export const NEW_ORG_LOCATION_FAIL = "NEW_ORG_LOCATION_FAIL";

export const UPDATE_ORG_LOCATION_REQUEST = "UPDATE_ORG_LOCATION_REQUEST";
export const UPDATE_ORG_LOCATION_SUCCESS = "UPDATE_ORG_LOCATION_SUCCESS";
export const UPDATE_ORG_LOCATION_FAIL = "UPDATE_ORG_LOCATION_FAIL";

export const DELETE_ORG_LOCATION_REQUEST = "DELETE_ORG_LOCATION_REQUEST";
export const DELETE_ORG_LOCATION_SUCCESS = "DELETE_ORG_LOCATION_SUCCESS";
export const DELETE_ORG_LOCATION_FAIL = "DELETE_ORG_LOCATION_FAIL";

///////////////////////department/////////////////////////////
////////////////////////////////////////////////////////////
export const ORG_DEP_LIST_REQUEST = "ORG_DEP_LIST_REQUEST";
export const ORG_DEP_LIST_SUCCESS = "ORG_DEP_LIST_SUCCESS";
export const ORG_DEP_LIST_FAIL = "ORG_DEP_LIST_FAIL";

export const NEW_ORG_DEP_REQUEST = "NEW_ORG_DEP_REQUEST";
export const NEW_ORG_DEP_SUCCESS = "NEW_ORG_DEP_SUCCESS";
export const NEW_ORG_DEP_FAIL = "NEW_ORG_DEP_FAIL";

export const UPDATE_ORG_DEP_REQUEST = "UPDATE_ORG_DEP_REQUEST";
export const UPDATE_ORG_DEP_SUCCESS = "UPDATE_ORG_DEP_SUCCESS";
export const UPDATE_ORG_DEP_FAIL = "UPDATE_ORG_DEP_FAIL";

export const DELETE_ORG_DEP_REQUEST = "DELETE_ORG_DEP_REQUEST";
export const DELETE_ORG_DEP_SUCCESS = "DELETE_ORG_DEP_SUCCESS";
export const DELETE_ORG_DEP_FAIL = "DELETE_ORG_DEP_FAIL";

///////////////////////sale tax rate/////////////////////////////
////////////////////////////////////////////////////////////
export const SALE_TAX_RATE_LIST_REQUEST = "SALE_TAX_RATE_LIST_REQUEST";
export const SALE_TAX_RATE_LIST_SUCCESS = "SALE_TAX_RATE_LIST_SUCCESS";
export const SALE_TAX_RATE_LIST_FAIL = "SALE_TAX_RATE_LIST_FAIL";

export const NEW_SALE_TAX_RATE_REQUEST = "NEW_SALE_TAX_RATE_REQUEST";
export const NEW_SALE_TAX_RATE_SUCCESS = "NEW_SALE_TAX_RATE_SUCCESS";
export const NEW_SALE_TAX_RATE_FAIL = "NEW_SALE_TAX_RATE_FAIL";

export const UPDATE_SALE_TAX_RATE_REQUEST = "UPDATE_SALE_TAX_RATE_REQUEST";
export const UPDATE_SALE_TAX_RATE_SUCCESS = "UPDATE_SALE_TAX_RATE_SUCCESS";
export const UPDATE_SALE_TAX_RATE_FAIL = "UPDATE_SALE_TAX_RATE_FAIL";

export const DELETE_SALE_TAX_RATE_REQUEST = "DELETE_SALE_TAX_RATE_REQUEST";
export const DELETE_SALE_TAX_RATE_SUCCESS = "DELETE_SALE_TAX_RATE_SUCCESS";
export const DELETE_SALE_TAX_RATE_FAIL = "DELETE_SALE_TAX_RATE_FAIL";

///////////////////////currency/////////////////////////////
////////////////////////////////////////////////////////////
export const CURRENCY_LIST_REQUEST = "CURRENCY_LIST_REQUEST";
export const CURRENCY_LIST_SUCCESS = "CURRENCY_LIST_SUCCESS";
export const CURRENCY_LIST_FAIL = "CURRENCY_LIST_FAIL";

export const NEW_CURRENCY_REQUEST = "NEW_CURRENCY_REQUEST";
export const NEW_CURRENCY_SUCCESS = "NEW_CURRENCY_SUCCESS";
export const NEW_CURRENCY_FAIL = "NEW_CURRENCY_FAIL";

export const UPDATE_CURRENCY_REQUEST = "UPDATE_CURRENCY_REQUEST";
export const UPDATE_CURRENCY_SUCCESS = "UPDATE_CURRENCY_SUCCESS";
export const UPDATE_CURRENCY_FAIL = "UPDATE_CURRENCY_FAIL";

export const DELETE_CURRENCY_REQUEST = "DELETE_CURRENCY_REQUEST";
export const DELETE_CURRENCY_SUCCESS = "DELETE_CURRENCY_SUCCESS";
export const DELETE_CURRENCY_FAIL = "DELETE_CURRENCY_FAIL";

///////////////////////email templates/////////////////////////////
////////////////////////////////////////////////////////////
export const EMAIL_TEMPLATES_LIST_REQUEST = "EMAIL_TEMPLATES_LIST_REQUEST";
export const EMAIL_TEMPLATES_LIST_SUCCESS = "EMAIL_TEMPLATES_LIST_SUCCESS";
export const EMAIL_TEMPLATES_LIST_FAIL = "EMAIL_TEMPLATES_LIST_FAIL";

export const UPDATE_EMAIL_TEMPLATE_REQUEST = "UPDATE_EMAIL_TEMPLATE_REQUEST";
export const UPDATE_EMAIL_TEMPLATE_SUCCESS = "UPDATE_EMAIL_TEMPLATE_SUCCESS";
export const UPDATE_EMAIL_TEMPLATE_FAIL = "UPDATE_EMAIL_TEMPLATE_FAIL";
export const RESET_UPDATE_EMAIL_TEMPLATE_STATUS = "RESET_UPDATE_EMAIL_TEMPLATE_STATUS";

export const EMAIL_TEMPLATES_DETAILS_REQUEST = "EMAIL_TEMPLATES_DETAILS_REQUEST";
export const EMAIL_TEMPLATES_DETAILS_SUCCESS = "EMAIL_TEMPLATES_DETAILS_SUCCESS";
export const EMAIL_TEMPLATES_DETAILS_FAIL = "EMAIL_TEMPLATES_DETAILS_FAIL";