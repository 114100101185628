import React from "react";
import CustomPriceScreen from "./screens/customPrice/CustomPriceScreen";
import PriceTierScreen from "./screens/customPrice/PriceTierScreen";
import CustomersScreen from "./screens/customers/CustomersScreen";
import EditCustomerScreen from "./screens/customers/EditCustomerScreen";
import NewCustomerScreen from "./screens/customers/NewCustomerScreen";
import PreviewCustomerScreen from "./screens/customers/PreviewCustomerScreen";
import Dashboard from "./screens/dashboard/Dashboard";
import EditXeroConfigurationScreen from "./screens/integration/EditXeroConfigurationScreen";
import IntegrationMainScreen from "./screens/integration/IntegrationMainScreen";
import XeroConnectionScreen from "./screens/integration/XeroConnectionScreen";
import XeroIntegrationScreen from "./screens/integration/XeroIntegrationScreen";
import XeroSyncDataScreen from "./screens/integration/XeroSyncDataScreen";
import PreviewProductScreen from "./screens/inventory/PreviewProductScreen";
import ProductCategoriesScreen from "./screens/inventory/ProductCategoriesScreen";
import ProductListScreen from "./screens/inventory/ProductListScreen";
import ProductUnitsScreen from "./screens/inventory/ProductUnitsScreen";
import StockLogsScreen from "./screens/inventory/StockLogsScreen";
import EditProductScreen from "./screens/inventory/product/EditProductScreen";
import NewProductScreen from "./screens/inventory/product/NewProductScreen";
import EditProductPackageScreen from "./screens/inventory/productPackage/EditProductPackageScreen";
import NewProductPackageScreen from "./screens/inventory/productPackage/NewProductPackageScreen";
import PreviewProductPackageScreen from "./screens/inventory/productPackage/PreviewProductPackageScreen";
import ProductPackageScreen from "./screens/inventory/productPackage/ProductPackageScreen";
import NewStockBulkAdjustmentScreen from "./screens/inventory/stockAdjustment/NewStockBulkAdjustmentScreen";
import PreviewStockAdjustmentScreen from "./screens/inventory/stockAdjustment/PreviewStockAdjustmentScreen";
import StockBulkAdjustmentScreen from "./screens/inventory/stockAdjustment/StockBulkAdjustmentScreen";
import NewStockBulkTransferScreen from "./screens/inventory/stockTransfer/NewStockBulkTransferScreen";
import PreviewStockTransferScreen from "./screens/inventory/stockTransfer/PreviewStockTransferScreen";
import StockBulkTransferScreen from "./screens/inventory/stockTransfer/StockBulkTransferScreen";
import ExportScreen from "./screens/io/ExportScreen";
import ImportScreen from "./screens/io/ImportScreen";
import ManageSubscriptionScreen from "./screens/nav/ManageSubscriptionScreen";
import PaymentHistoryScreen from "./screens/nav/PaymentHistoryScreen";
import UserAccount from "./screens/nav/UserAccount";
import EditPurchaseOrderScreen from "./screens/purchase/purchaseOrder/EditPurchaseOrderScreen";
import NewPurchaseOrderScreen from "./screens/purchase/purchaseOrder/NewPurchaseOrderScreen";
import PreviewPurchaseOrderScreen from "./screens/purchase/purchaseOrder/PreviewPurchaseOrderScreen";
import PurchaseOrderListScreen from "./screens/purchase/purchaseOrder/PurchaseOrderListScreen";
import ReceivePurchaseOrderScreen from "./screens/purchase/purchaseOrder/ReceivePurchaseOrderScreen";
import ReportingScreen from "./screens/reporting/ReportingScreen";
import SaleProfitReportScreen from "./screens/reporting/SaleProfitReportScreen";
import SaleSummaryReportScreen from "./screens/reporting/SaleSummaryReportScreen";
import StocktakeReportScreen from "./screens/reporting/StocktakeReportScreen";
import EditSaleOrderScreen from "./screens/sale/saleOrder/EditSaleOrderScreen";
import NewSaleOrderScreen from "./screens/sale/saleOrder/NewSaleOrderScreen";
import PreviewSaleOrderScreen from "./screens/sale/saleOrder/PreviewSaleOrderScreen";
import SaleOrderListScreen from "./screens/sale/saleOrder/SaleOrderListScreen";
import EmailTemplatesScreen from "./screens/settings/EmailTemplatesScreen";
import TaxSetting from "./screens/settings/TaxSetting";
import CompanySettings from "./screens/settings/companySettings/CompanySettings";
import CompanySettingsCurrencies from "./screens/settings/companySettings/CompanySettingsCurrencies";
import CompanySettingsDepartments from "./screens/settings/companySettings/CompanySettingsDepartments";
import CompanySettingsLocation from "./screens/settings/companySettings/CompanySettingsLocations";
import EditRole from "./screens/settings/usersAndRoles/EditRole";
import EditUser from "./screens/settings/usersAndRoles/EditUser";
import NewRole from "./screens/settings/usersAndRoles/NewRole";
import NewUser from "./screens/settings/usersAndRoles/NewUser";
import RoleView from "./screens/settings/usersAndRoles/RoleView";
import RolesSettings from "./screens/settings/usersAndRoles/RolesSettings";
import UserView from "./screens/settings/usersAndRoles/UserView";
import UsersSettings from "./screens/settings/usersAndRoles/UsersSettings";
import EditSupplier from "./screens/supplier/EditSupplier";
import NewSupplier from "./screens/supplier/NewSupplier";
import PreviewSupplier from "./screens/supplier/PreviewSupplier";
import Suppliers from "./screens/supplier/Suppliers";
import StockOnHandReportScreen from "./screens/reporting/StockOnHandReportScreen";
import StockAlertReportScreen from "./screens/reporting/StockAlertReportScreen";
import ProductListReportScreen from "./screens/reporting/ProductListReportScreen";
import SerialBatchNumberReportScreen from "./screens/reporting/SerialBatchNumberReportScreen";
import StockAdjustReportScreen from "./screens/reporting/StockAdjustReportScreen";
import StockTransferReportScreen from "./screens/reporting/StockTransferReportScreen";
import SalesSummaryByProductScreen from "./screens/reporting/SalesSummaryByProductScreen";
import SalesProfitByProductScreen from "./screens/reporting/SalesProfitByProductScreen";
import PurchaseSummaryReportScreen from "./screens/reporting/PurchaseSummaryReportScreen";
import XeroImportScreen from "./screens/integration/XeroImportScreen";
import ProjectsScreen from "./screens/settings/ProjectsScreen";
import ProductsSummaryByProjectScreen from "./screens/reporting/ProductsSummaryByProjectScreen";
import ProductTransactionsByProjectScreen from "./screens/reporting/ProductTransactionsByProjectScreen";
import AdjustReasonsScreen from "./screens/settings/AdjustReasonsScreen";
import ImportProductScreen from "./screens/inventory/importExport/ImportProductScreen";
import ExportProductScreen from "./screens/inventory/importExport/ExportProductScreen";
import ImportBatchSerialScreen from "./screens/inventory/importExport/ImportBatchSerialScreen";
import EditStockAdjustmentScreen from "./screens/inventory/stockAdjustment/EditStockAdjustmentScreen";
import EditStockTransferScreen from "./screens/inventory/stockTransfer/EditStockTransferScreen";
import TemplatesScreen from "./screens/settings/pdfTemplates/TemplatesScreen";
import SystemSettingsPO from "./screens/settings/systemSettings/SystemSettingsPO";
import SystemSettingsSO from "./screens/settings/systemSettings/SystemSettingsSO";





// pageName : Breadcrumbs use this name
// sidemenuName: sidebar use this name
// name: for permissions check (match the name in database in permission table)

export const menuItemsData = () => {
  return [
    {
      id: "dash",
      pageName: "Dashboard",
      sidemenuName: "Dashboard",
      name: "dashboard",
      iconName: "dashboard",
      path: "/",
      component: <Dashboard />,
      isSidebarShow: true,
      permissionCheck: false,
    },
    {
      id: "inv",
      pageName: "Inventory",
      sidemenuName: "Inventory",
      name: "inventory",
      iconName: "inventory",
      isSidebarShow:true,
      path: "",
      tourClassName:"first-step",
      isChildrenShow: true,
      children: [
        {
          id: "inv1",
          pageName: "Product List",
          sidemenuName: "Manage Products",
          name: "product_list",
          path: "/inventory/product-list",
          component: <ProductListScreen />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "inv11",
              pageName: "Edit Product",
              sidebmenuName: "Edit Product",
              name: "edit_product",
              path: "/inventory/edit/:id",
              component: <EditProductScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "inv12",
              pageName: "Preview Product",
              sidebmenuName: "Preview Product",
              name: "view_product_details",
              path: "/inventory/preview/:id",
              component: <PreviewProductScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "inv13",
              pageName: "Custom Price",
              sidebmenuName: "Custom Price",
              name: "product_custom_price",
              path: "/inventory/custome-price/:id",
              component: <CustomPriceScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "inv16",
              pageName: "Stock Logs",
              sidebmenuName: "Stock Logs",
              name: "view_stock_change_logs",
              path: "/inventory/stock-logs/:id",
              component: <StockLogsScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ],
        },
        {
          id: "inv2",
          pageName: "New Product",
          sidemenuName: "Add New Product",
          name: "new_product",
          path: "/inventory/new",
          component: <NewProductScreen />,
          isSidebarShow: true,
          tourClassName:"second-step",
          permissionCheck: true,
        },
        {
          id: "inv3",
          pageName: "Product Categories",
          sidemenuName: "Product Categories",
          name: "view_product_categories",
          path: "/inventory/product-categories",
          component: <ProductCategoriesScreen />,
          isSidebarShow: true,
          permissionCheck: true,
        },
        {
          id: "inv7",
          pageName: "Product Package",
          sidemenuName: "Product Package",
          name: "manage_product_package",
          path: "/inventory/product-package",
          component: <ProductPackageScreen />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "inv61",
              pageName: "New Product Package",
              sidebmenuName: "New Product Package",
              name: "manage_product_package",
              path: "/inventory/product-package/new",
              component: <NewProductPackageScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "inv62",
              pageName: "Preview Product Package",
              sidebmenuName: "Preview Product Package",
              name: "manage_product_package",
              path: "/inventory/product-package/preview/:id",
              component: <PreviewProductPackageScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "inv63",
              pageName: "Edit Product Package",
              sidebmenuName: "Edit Product Package",
              name: "manage_product_package",
              path: "/inventory/product-package/edit/:id",
              component: <EditProductPackageScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ]
        },
        {
          id: "inv6",
          pageName: "Stock Adjustment",
          sidemenuName: "Stock Adjustment",
          name: "stock_adjustment",
          path: "/inventory/bulk-stock-adjustment",
          component: <StockBulkAdjustmentScreen />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "inv61",
              pageName: "New Stock Adjustment",
              sidebmenuName: "New Stock Adjustment",
              name: "stock_adjustment",
              path: "/inventory/bulk-stock-adjustment/new",
              component: <NewStockBulkAdjustmentScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "inv62",
              pageName: "Preview Stock Adjustment",
              sidebmenuName: "Preview Stock Adjustment",
              name: "stock_adjustment",
              path: "/inventory/bulk-stock-adjustment/preview/:id",
              component: <PreviewStockAdjustmentScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "inv63",
              pageName: "Edit Stock Adjustment",
              sidebmenuName: "Edit Stock Adjustment",
              name: "stock_adjustment",
              path: "/inventory/bulk-stock-adjustment/edit/:id",
              component: <EditStockAdjustmentScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ]
        },
        {
          id: "inv9",
          pageName: "Stock Transfer",
          sidemenuName: "Stock Transfer",
          name: "stock_transfer",
          path: "/inventory/bulk-stock-transfer",
          component: <StockBulkTransferScreen />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "inv61",
              pageName: "New Stock Transfer",
              sidebmenuName: "New Stock Transfer",
              name: "stock_transfer",
              path: "/inventory/bulk-stock-transfer/new",
              component: <NewStockBulkTransferScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "inv62",
              pageName: "Preview Stock Transfer",
              sidebmenuName: "Preview Stock Transfer",
              name: "stock_transfer",
              path: "/inventory/bulk-stock-transfer/preview/:id",
              component: <PreviewStockTransferScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "inv63",
              pageName: "Edit Stock Transfer",
              sidebmenuName: "Edit Stock Transfer",
              name: "stock_transfer",
              path: "/inventory/bulk-stock-transfer/edit/:id",
              component: <EditStockTransferScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ]
        },

        {
          id: "inv3",
          pageName: "Units of Measure",
          sidemenuName: "Units of Measure",
          name: "view",
          path: "/inventory/product-units",
          component: <ProductUnitsScreen />,
          isSidebarShow: true,
          permissionCheck: false,
        },
        {
          id: "inv5",
          pageName: "Import Products",
          sidemenuName: "Import/Export Products",
          name: "import_export_products",
          path: "/inventory/products/import",
          component: <ImportProductScreen />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "inv41",
              pageName: "Export Products",
              sidemenuName: "Export Products",
              name: "import_export_products",
              path: "/inventory/products/export",
              component: <ExportProductScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "inv42",
              pageName: "Import Batch/Serial Numbers",
              sidemenuName: "Import Batch/Serial Numbers",
              name: "import_export_products",
              path: "/inventory/products/batch-serial/import",
              component: <ImportBatchSerialScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ],
        },
      ],
    },
    {
      id: "sale",
      pageName: "Sale",
      sidemenuName: "Sale",
      name: "sale",
      iconName: "sale",
      isSidebarShow:true,
      path: "",
      isChildrenShow: true,
      children: [

        {
          id: "sale2",
          pageName: "All Sale Orders",
          sidemenuName: "All Sale Orders",
          name: "new_sale_order",
          path: "/sale/sale-order-list",
          component: <SaleOrderListScreen />,
          isSidebarShow: true,
          permissionCheck: false,
          isChildrenShow: false,
          children: [
            {
              id: "sale21",
              pageName: "Edit Sale Order",
              sidebmenuName: "Edit Sale Order",
              name: "new_sale_order",
              path: "/sale/order/edit/:id",
              component: <EditSaleOrderScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "sale23",
              pageName: "Preview Sale Order",
              sidebmenuName: "Preview Sale Order",
              name: "new_sale_order",
              path: "/sale/order/preview/:id",
              component: <PreviewSaleOrderScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ],
        },
        {
          id: "sale1",
          pageName: "New Sale Order",
          sidemenuName: "New Sale Order",
          name: "new_sale_order",
          path: "/sale/order/new",
          component: <NewSaleOrderScreen />,
          isSidebarShow: true,
          permissionCheck: true,
        },
      ],
    },

    {
      id: "pur",
      pageName: "Purchase",
      sidemenuName: "Purchase",
      name: "purchase",
      iconName: "purchase",
      path: "",
      isSidebarShow:true,
      isChildrenShow: true,
      children: [

        {
          id: "pur2",
          pageName: "All Purchase Orders",
          sidemenuName: "All Purchase Orders",
          name: "new_purchase_order",
          path: "/purchase/order-list",
          component: <PurchaseOrderListScreen />,
          isSidebarShow: true,
          permissionCheck: false,
          isChildrenShow: false,
          children: [
            {
              id: "pur21",
              pageName: "Edit Purchase Order",
              sidebmenuName: "Edit Purchase Order",
              name: "new_purchase_order",
              path: "/purchase/order/edit/:id",
              component: <EditPurchaseOrderScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "pur22",
              pageName: "Receive Stock",
              sidebmenuName: "Receive Stock",
              name: "receive_stock",
              path: "/purchase/order/receive-stock/:id",
              component: <ReceivePurchaseOrderScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "pur23",
              pageName: "Preview Purchase Order",
              sidebmenuName: "Preview Purchase Order",
              name: "new_purchase_order",
              path: "/purchase/order/preview/:id",
              component: <PreviewPurchaseOrderScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ],
        },
        {
          id: "pur1",
          pageName: "New Purchase Order",
          sidemenuName: "New Purchase Order",
          name: "new_purchase_order",
          path: "/purchase/order/new",
          component: <NewPurchaseOrderScreen />,
          isSidebarShow: true,
          permissionCheck: true,
        },
      ],
    },
    
    {
      id: "cus",
      pageName: "Customer",
      sidemenuName: "Customer",
      name: "customer",
      iconName: "customer",
      path: "",
      isSidebarShow:true,
      isChildrenShow: true,
      children: [
      
        {
          id: "cus2",
          pageName: "Manage Customers",
          sidemenuName: "Manage Customers",
          name: "view_customers",
          path: "/customers",
          component: <CustomersScreen />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "cus21",
              pageName: "Edit Customer",
              sidemenuName: "Edit Customer",
              name: "add_delete_edit_customers",
              path: "/customer/edit/:id",
              component: <EditCustomerScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "cus22",
              pageName: "Preview Customer",
              sidemenuName: "Preview Customer",
              name: "view_customers",
              path: "/customer/preview/:id",
              component: <PreviewCustomerScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ],
        },
        {
          id: "cus1",
          pageName: "New Customer",
          sidemenuName: "New Customer",
          name: "add_delete_edit_customers",
          path: "/customer/new",
          component: <NewCustomerScreen />,
          isSidebarShow: true,
          permissionCheck: true,
        },
        {
          id: "cus3",
          pageName: "Import Customers",
          sidemenuName: "Import/Export Customers",
          name: "import_export_customers",
          path: "/customer/import",
          component: <ImportScreen type="customer"/>,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "cus31",
              pageName: "Export Customers",
              sidemenuName: "Export Customers",
              name: "import_export_customers",
              path: "/customer/export",
              component: <ExportScreen type="customer"/>,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ],
        },
      ],
    },
    {
      id: "sup",
      pageName: "Supplier",
      sidemenuName: "Supplier",
      name: "supplier",
      iconName: "supplier",
      path: "",
      isSidebarShow:true,
      isChildrenShow: true,
      children: [

        {
          id: "sup2",
          pageName: "Manage Suppliers",
          sidemenuName: "Manage Suppliers",
          name: "view_suppliers",
          path: "/suppliers",
          component: <Suppliers />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "sup21",
              pageName: "Edit Supplier",
              sidemenuName: "Edit Supplier",
              name: "add_delete_edit_suppliers",
              path: "/supplier/edit/:id",
              component: <EditSupplier />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "sup22",
              pageName: "Preview Supplier",
              sidemenuName: "Preview Supplier",
              name: "view_suppliers",
              path: "/supplier/preview/:id",
              component: <PreviewSupplier />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ],
        },
        {
          id: "sup1",
          pageName: "New Supplier",
          sidemenuName: "New Supplier",
          name: "add_delete_edit_suppliers",
          path: "/supplier/new",
          component: <NewSupplier />,
          isSidebarShow: true,
          permissionCheck: true,
        },
        {
          id: "sup3",
          pageName: "Import Suppliers",
          sidemenuName: "Import/Export Suppliers",
          name: "import_export_suppliers",
          path: "/supplier/import",
          component: <ImportScreen type="supplier"/>,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "inv41",
              pageName: "Export Suppliers",
              sidemenuName: "Export Suppliers",
              name: "import_export_suppliers",
              path: "/supplier/export",
              component: <ExportScreen type="supplier"/>,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ],
        },
      ],
    },
    {
      id: "set",
      pageName: "Settings",
      sidemenuName: "Settings",
      name: "settings",
      iconName: "settings",
      path: "",
      isSidebarShow:true,
      isChildrenShow: true,
      children: [
        {
          id: "set1",
          pageName: "Users",
          sidemenuName: "Users",
          name: "view_users",
          path: "/settings/users",
          component: <UsersSettings />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "set12",
              pageName: "New User",
              sidemenuName: "New User",
              name: "add_delete_edit_users",
              path: "/settings/users/new",
              component: <NewUser />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "set13",
              pageName: "Update User",
              sidemenuName: "Update User",
              name: "add_delete_edit_users",
              path: "/settings/users/edit/:id",
              component: <EditUser />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "set14",
              pageName: "Preview User",
              sidemenuName: "Preview User",
              name: "view_users",
              path: "/settings/users/preview/:id",
              component: <UserView />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ],
        },
        {
          id: "set3",
          pageName: "Roles",
          sidemenuName: "Roles",
          name: "view_roles",
          path: "/settings/roles",
          component: <RolesSettings />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "set31",
              pageName: "New Role",
              sidemenuName: "New role",
              name: "add_delete_edit_roles",
              path: "/settings/roles/new",
              component: <NewRole />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "set32",
              pageName: "Edit Role",
              sidemenuName: "Edit Role",
              name: "add_delete_edit_roles",
              path: "/settings/roles/edit/:id",
              component: <EditRole />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "set33",
              pageName: "Preview Role",
              sidemenuName: "Preview Role",
              name: "view_roles",
              path: "/settings/roles/preview/:id",
              component: <RoleView />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ],
        },

        {
          id: "set2",
          pageName: "Tax Rates",
          sidemenuName: "Tax Rates",
          name: "add_delete_edit_tax_rates",
          path: "/settings/tax-rates",
          component: <TaxSetting />,
          isSidebarShow: true,
          permissionCheck: true,
        },
        {
          id: "set4",
          pageName: "Price Tiers",
          sidemenuName: "Price Tiers",
          name: "add_delete_edit_price_tier",
          path: "/settings/price-tier",
          component: <PriceTierScreen />,
          isSidebarShow: true,
          permissionCheck: true,
        },
        {
          id: "set7",
          pageName: "Projects",
          sidemenuName: "Projects",
          name: "add_delete_edit_project",
          path: "/settings/projects",
          component: <ProjectsScreen />,
          isSidebarShow: true,
          permissionCheck: true,
        },
        {
          id: "set8",
          pageName: "Adjust Reasons",
          sidemenuName: "Adjust Reasons",
          name: "add_delete_edit_adjust_reasons",
          path: "/settings/adjust-reasons",
          component: <AdjustReasonsScreen />,
          isSidebarShow: true,
          permissionCheck: true,
        },
        {
          id: "set5",
          pageName: "Email Templates",
          sidemenuName: "Email Templates",
          name: "manage_email_templates",
          path: "/settings/email-templates",
          component: <EmailTemplatesScreen />,
          isSidebarShow: true,
          permissionCheck: true,
        },
        {
          id: "set5",
          pageName: "Export PDF Templates",
          sidemenuName: "Export PDF Templates",
          name: "manage_export_pdf_templates",
          path: "/settings/pdf-templates",
          component: <TemplatesScreen />,
          isSidebarShow: true,
          permissionCheck: true,
        },
        {
          id: "set7",
          pageName: "System Settings",
          sidemenuName: "System Settings",
          name: "manage_system_settings",
          path: "/settings/system-settings/po",
          component: <SystemSettingsPO />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [{
            id: "set71",
            pageName: "Sale Order",
            sidemenuName: "System Settings(SO)",
            name: "manage_system_settings",
            path: "/settings/system-settings/so",
            component: <SystemSettingsSO />,
            isSidebarShow: false,
            permissionCheck: true,
          }]
        },
        {
          id: "set6",
          pageName: "Company Settings",
          sidemenuName: "Company Settings",
          name: "view_company_info",
          path: "/company-settings",
          component: <CompanySettings />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "set61",
              pageName: "Department",
              sidemenuName: "Company Department",
              name: "view_company_info",
              path: "/company-settings/department",
              component: <CompanySettingsDepartments />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "set62",
              pageName: "Location",
              sidemenuName: "Company Location",
              name: "view_company_info",
              path: "/company-settings/location",
              component: <CompanySettingsLocation />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "set63",
              pageName: "Currency",
              sidemenuName: "Company Currency",
              name: "view_company_info",
              path: "/company-settings/currency",
              component: <CompanySettingsCurrencies />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ],
        },
      ],
    },
    {
      id: "int",
      pageName: "Integration",
      sidemenuName: "Integration",
      name: "integration",
      iconName: "integration",
      path: "",
      isSidebarShow:true,
      isChildrenShow: true,
      children:[
        {
          id: "int1",
          pageName: "Manage Integration",
          sidemenuName: "Manage Integration",
          name: "manage_integration",
          path: "/integration",
          component: <IntegrationMainScreen />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: true,
          children:[
            {
              id: "int12",
              pageName: "Xero Integration",
              sidemenuName: "Xero Integration",
              name: "manage_integration",
              path: "/integration/xero",
              component: <XeroIntegrationScreen />,
              isSidebarShow: false,
              permissionCheck: false,
            },
            {
              id: "int13",
              pageName: "Xero Configuration",
              sidemenuName: "Xero Configuration",
              name: "manage_integration",
              path: "/integration/xero/edit",
              component: <EditXeroConfigurationScreen />,
              isSidebarShow: false,
              permissionCheck: false,
            },
            {
              id: "int14",
              pageName: "Xero Synchronized Logs",
              sidemenuName: "Xero Synchronized Logs",
              name: "manage_integration",
              path: "/integration/xero/data",
              component: <XeroSyncDataScreen />,
              isSidebarShow: false,
              permissionCheck: false,
            },
            {
              id: "int15",
              pageName: "Xero Connection",
              sidemenuName: "Xero Connection",
              name: "manage_integration",
              path: "/integration/xero/connect",
              component: <XeroConnectionScreen />,
              isSidebarShow: false,
              permissionCheck: false,
            },
            {
              id: "int15",
              pageName: "Xero Import",
              sidemenuName: "Xero Import",
              name: "manage_integration",
              path: "/integration/xero/import",
              component: <XeroImportScreen />,
              isSidebarShow: false,
              permissionCheck: false,
            },
          ]
        }
        
      ]
    },
    {
      id: "nav",
      pageName: "Nav",
      sidemenuName: "Nav",
      name: "navmenu",
      isSidebarShow:false,
      isChildrenShow: false,
      children: [
        {
          id: "nav1",
          pageName: "My Profile",
          sidemenuName: "My Profile",
          name: "user_account",
          path: "/user-profile",
          component: <UserAccount />,
          isSidebarShow: false,
          permissionCheck: false,
        },
        {
          id: "nav3",
          pageName: "Manage Subscription",
          sidemenuName: "Manage Subscription",
          name: "manage_subscription",
          path: "/manage-subscription",
          component: <ManageSubscriptionScreen />,
          isSidebarShow: false,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "nav1",
              pageName: "Payment History",
              sidemenuName: "Payment History",
              name: "manage_subscription",
              path: "/manage-subscription/payment-history",
              component: <PaymentHistoryScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            }
          ]
        },
      ],
    },
    {
      id: "rep",
      pageName: "Reporting",
      sidemenuName: "Reporting",
      name: "reporting",
      iconName: "reporting",
      path: "/reporting",
      component: <ReportingScreen />,
      isSidebarShow: true,
      permissionCheck: false,
      isChildrenShow: false,
      children: [
        {
          id: "rep1",
          pageName: "Inventory Reports",
          sidemenuName: "Inventory Reports",
          name: "inventory_reports",
          path: "/reporting",
          isSidebarShow: false,
          isChildrenShow: false,
          children: [

            {
              id: "repInventory1",
              pageName: "Stock On Hand Report",
              sidemenuName: "Stock On Hand Report",
              name: "inv_report_stock_on_hand",
              path: "/reporting/inventory/stock-report",
              component: <StockOnHandReportScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "repInventory1",
              pageName: "Stocktake Report",
              sidemenuName: "Stocktake Report",
              name: "inv_report_stocktake",
              path: "/reporting/inventory/stocktake-report",
              component: <StocktakeReportScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "repInventory1",
              pageName: "Stock Alert Report",
              sidemenuName: "Stock Alert Report",
              name: "inv_report_stock_alert",
              path: "/reporting/inventory/stock-alert-report",
              component: <StockAlertReportScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "repInventory1",
              pageName: "Products Report",
              sidemenuName: "Products Report",
              name: "inv_report_products",
              path: "/reporting/inventory/product-list-report",
              component: <ProductListReportScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "repInventory1",
              pageName: "Serial & Batch Numbers Report",
              sidemenuName: "Serial & Batch Numbers Report",
              name: "inv_report_serial_batch_report",
              path: "/reporting/inventory/serial-batch-report",
              component: <SerialBatchNumberReportScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "repInventory1",
              pageName: "Stock Adjustment Report",
              sidemenuName: "Stock Adjustment Report",
              name: "inv_report_stock_adjust",
              path: "/reporting/inventory/stock-adjustment-report",
              component: <StockAdjustReportScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "repInventory1",
              pageName: "Stock Transfer Report",
              sidemenuName: "Stock Transfer Report",
              name: "inv_report_stock_transfer",
              path: "/reporting/inventory/stock-transfer-report",
              component: <StockTransferReportScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            
          ],
          
        },
        {
          id: "rep2",
          pageName: "Sale Reports",
          sidemenuName: "Sale Reports",
          name: "sale_reports",
          path: "/reporting",
          isSidebarShow: false,
          isChildrenShow: false,
          children: [
            {
              id: "repSale1",
              pageName: "Sale Summary Report",
              sidemenuName: "Sale Summary Report",
              name: "sale_report_summary",
              path: "/reporting/sale/summary",
              component: <SaleSummaryReportScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "repSale2",
              pageName: "Sale Gross Profit Report",
              sidemenuName: "Sale Gross Profit Report",
              name: "sale_report_gross_profit",
              path: "/reporting/sale/gross-profit",
              component: <SaleProfitReportScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "repSale3",
              pageName: "Sales Summary By Product Report",
              sidemenuName: "Sales Summary By Product Report",
              name: "sale_report_by_product_summary",
              path: "/reporting/sale/summary-by-product",
              component: <SalesSummaryByProductScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "repSale4",
              pageName: "Sales Gross Profit By Product Report",
              sidemenuName: "Sales Gross Profit By Product Report",
              name: "sale_report_gross_profit_by_product_summary",
              path: "/reporting/sale/profit-by-product",
              component: <SalesProfitByProductScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            
            
          ],
          
        },
        {
          id: "rep3",
          pageName: "Purchase Reports",
          sidemenuName: "Purchase Reports",
          name: "purchase_reports",
          path: "/reporting",
          isSidebarShow: false,
          isChildrenShow: false,
          children: [
            {
              id: "repPur1",
              pageName: "Purchase Summary Report",
              sidemenuName: "Purchase Summary Report",
              name: "purchase_report_summary",
              path: "/reporting/purchase/summary",
              component: <PurchaseSummaryReportScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            
            
          ],
          
        },
        {
          id: "rep5",
          pageName: "Project Reports",
          sidemenuName: "Project Reports",
          name: "projects_reports",
          path: "/reporting",
          isSidebarShow: false,
          isChildrenShow: false,
          children: [
            {
              id: "repPro1",
              pageName: "Products Summary By Project Report",
              sidemenuName: "Products Summary By Project Report",
              name: "products_summary_by_project",
              path: "/reporting/project/products-summary",
              component: <ProductsSummaryByProjectScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "repPro2",
              pageName: "Product Transactions by Project Report",
              sidemenuName: "Product Transactions by Project Report",
              name: "products_summary_by_project",
              path: "/reporting/project/product-transactions",
              component: <ProductTransactionsByProjectScreen />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            
            
          ],
          
        },
        
      ],
    },
    
  ];
};
