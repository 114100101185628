import axios from "axios";
import { authMiddleware } from "./authMiddleware";
import { SYSTEM_SETTINGS_DETAILS_FAIL, SYSTEM_SETTINGS_DETAILS_REQUEST, SYSTEM_SETTINGS_DETAILS_SUCCESS, UPDATE_SYSTEM_SETTINGS_FAIL, UPDATE_SYSTEM_SETTINGS_REQUEST, UPDATE_SYSTEM_SETTINGS_SUCCESS } from "../constants/systemSettingsConstants";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const systemSettingsDetailsRequest = (category) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();
  
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      
      dispatch({ type: SYSTEM_SETTINGS_DETAILS_REQUEST });
      axios
        .get(SERVER_URL + `/settings/system-settings/details?category=${category}`, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({
              type: SYSTEM_SETTINGS_DETAILS_SUCCESS,
              payload: response.data.data,
            });
          } else {
            throw response.data.message
          }
        })
        .catch((err) => {
          dispatch({
            type: SYSTEM_SETTINGS_DETAILS_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: SYSTEM_SETTINGS_DETAILS_FAIL,
        payload: "Connection error, please try again later.",
      });
    }
  };

  export const updateSystemSettingsRequest = (category, values) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();
  
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      
      dispatch({ type: UPDATE_SYSTEM_SETTINGS_REQUEST });
      axios
        .post(SERVER_URL + `/settings/system-settings/details/update?category=${category}`, values, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({
              type: UPDATE_SYSTEM_SETTINGS_SUCCESS
            });
          } else {
            throw response.data.message
          }
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_SYSTEM_SETTINGS_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: UPDATE_SYSTEM_SETTINGS_FAIL,
        payload: "Connection error, please try again later.",
      });
    }
  };