import React from "react";
import ReactPaginate from 'react-paginate';
import commonStyle from "../style/commonStyle.module.css";
import {MdKeyboardArrowLeft, 
    MdKeyboardArrowRight} from "react-icons/md";

export default function Pagination({
  totalPageNum,
  onPageChange,
  forcePage
}) {
  return (
    <div>
      <ReactPaginate
        breakLabel="..."
        nextLabel={<MdKeyboardArrowRight />}
        onPageChange={onPageChange}
        pageRangeDisplayed={5}
        pageCount={totalPageNum}
        forcePage={forcePage}
        previousLabel={<MdKeyboardArrowLeft />}
        renderOnZeroPageCount={null}
        containerClassName={commonStyle.paginationCon}
        pageLinkClassName={commonStyle.pageItem}
        breakLinkClassName={commonStyle.pageItem}
        previousLinkClassName={commonStyle.pageItem}
        nextLinkClassName={commonStyle.pageItem}
        activeLinkClassName={commonStyle.pageItemActive}
      />
       
    </div>
  );
}
