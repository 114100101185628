import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import dashboardStyle from "./dashboardStyle.module.css";
import { SimpleBlueButton, EditButton } from "../../components/editComponents";
import { ContentModal, ErrorMessage, Loader, Modal } from "../../components/viewComponents";
import {
  MdManageSearch,
  MdOutlineShopTwo,
  MdPointOfSale,
  MdOutlineInventory2,
  MdDone,
  MdPersonAddAlt,
  MdOutlinePersonPin,
  MdAttachMoney,
  MdAdd,
} from "react-icons/md";
import { SiXero } from "react-icons/si";
import QuickStock from "./QuickStock";
import { updateFavGroupRequest } from "../../actions/dashboardActions";

import {
  AiOutlineUsergroupAdd,
  AiOutlineUserAdd,
  AiFillStar,
} from "react-icons/ai";

export default function FavouriteGroup({ initialData }) {
  const dispatch = useDispatch();
  // access check for the actions
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const [selectedActions, setSelectActions] = useState(initialData);
  const [editFav, setEditedFav] = useState(false);
  // quick stock Modal
  const [quickStockModal, setQuickStockModal] = useState(false);

  const { loading, error } = useSelector((state) => state.updateFavGroup);

  

  const updateSelectedActions = (actionName, actionType) => {
    let itemsCopy = [...selectedActions];

    if (itemsCopy.includes(actionName)) {
      let index = itemsCopy.indexOf(actionName);
      if (index !== -1) {
        itemsCopy.splice(index, 1);
      }
    } else {
      itemsCopy.push(actionName);
    }
    setSelectActions(itemsCopy);
  };

  const saveSelectedActions = () => {
    setEditedFav(false);
    dispatch(updateFavGroupRequest(selectedActions));
  };

  return (
    <div className={dashboardStyle.section_wrapper}>
      <ContentModal
        title="Quick Stock"
        isModalOpen={quickStockModal}
        closeModal={() => setQuickStockModal(false)}
        content={<QuickStock />}
      />
      <div className={dashboardStyle.section_titleCon}>
      <div className="flex items-center ">
          <AiFillStar className={dashboardStyle.section_icon} />
          <p className={dashboardStyle.section_title}>Favourite</p>
        </div>

        {editFav ? <></> : <EditButton action={() => setEditedFav(true)} />}
      </div>
      {loading ? (
        <Loader mess="Processing request..." />
      ) : error ? (
        <ErrorMessage mess={error} />
      ) : (
        <></>
      )}

      {userDetailsSuccess ? (
        <ul className={dashboardStyle.favoriteCon}>
          {/* 1. check if has access 2. check if in edit status 3. check if selected */}
          {userDetails.data.permissions.includes("product_list") ? (
            editFav ? (
              <li
                className={`${dashboardStyle.fav_itemCon} ${dashboardStyle.qsItem}`}
                onClick={() => updateSelectedActions("quickStock")}
              >
                <MdManageSearch className={dashboardStyle.icon} />
                <span className={dashboardStyle.actionName}>Quick Stock</span>
                {selectedActions.includes("quickStock") ? (
                  <div
                    className={`${dashboardStyle.checkMark} ${dashboardStyle.checked}`}
                  >
                    <MdDone />
                  </div>
                ) : (
                  <div
                    className={`${dashboardStyle.checkMark} ${dashboardStyle.unchecked}`}
                  >
                    <MdDone />
                  </div>
                )}
                <span className={dashboardStyle.clickHint}>
                  Click to select/remove
                </span>
              </li>
            ) : selectedActions.includes("quickStock") ? (
              <li
                className={`${dashboardStyle.fav_itemCon} ${dashboardStyle.qsItem}`}
                onClick={() => setQuickStockModal(true)}
              >
                <MdManageSearch className={dashboardStyle.icon} />
                <span className={dashboardStyle.actionName}>Quick Stock</span>
              </li>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          {userDetails.data.permissions.includes("new_purchase_order") ? (
            editFav ? (
              <li
                className={`${dashboardStyle.fav_itemCon} ${dashboardStyle.qsItem}`}
                onClick={() => updateSelectedActions("newPO")}
              >
                <MdOutlineShopTwo className={dashboardStyle.icon} />
                <span className={dashboardStyle.actionName}>
                  {" "}
                  New Purchase Order
                </span>
                {selectedActions.includes("newPO") ? (
                  <div
                    className={`${dashboardStyle.checkMark} ${dashboardStyle.checked}`}
                  >
                    <MdDone />
                  </div>
                ) : (
                  <div
                    className={`${dashboardStyle.checkMark} ${dashboardStyle.unchecked}`}
                  >
                    <MdDone />
                  </div>
                )}
                <span className={dashboardStyle.clickHint}>
                  Click to select/remove
                </span>
              </li>
            ) : selectedActions.includes("newPO") ? (
              <li>
                <NavLink
                  to={`/purchase/order/new`}
                  className={`${dashboardStyle.fav_itemCon} ${dashboardStyle.qsItem}`}
                >
                  <MdOutlineShopTwo className={dashboardStyle.icon} />
                  <span className={dashboardStyle.actionName}>
                    {" "}
                    New Purchase Order
                  </span>
                </NavLink>
              </li>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          {userDetails.data.permissions.includes("new_sale_order") ? (
            editFav ? (
              <li
                className={`${dashboardStyle.fav_itemCon} ${dashboardStyle.qsItem}`}
                onClick={() => updateSelectedActions("newSO")}
              >
                <MdPointOfSale className={dashboardStyle.icon} />
                <span className={dashboardStyle.actionName}>
                  {" "}
                  New Sale Order
                </span>
                {selectedActions.includes("newSO") ? (
                  <div
                    className={`${dashboardStyle.checkMark} ${dashboardStyle.checked}`}
                  >
                    <MdDone />
                  </div>
                ) : (
                  <div
                    className={`${dashboardStyle.checkMark} ${dashboardStyle.unchecked}`}
                  >
                    <MdDone />
                  </div>
                )}
                <span className={dashboardStyle.clickHint}>
                  Click to select/remove
                </span>
              </li>
            ) : selectedActions.includes("newSO") ? (
              <li>
                <NavLink
                  to={`/sale/order/new`}
                  className={`${dashboardStyle.fav_itemCon} ${dashboardStyle.qsItem}`}
                >
                  <MdPointOfSale className={dashboardStyle.icon} />
                  <span className={dashboardStyle.actionName}>
                    {" "}
                    New Sale Order
                  </span>
                </NavLink>
              </li>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          {userDetails.data.orgInfo.xeroConnected ? (
            <>
              {" "}
              {userDetails.data.permissions.includes(
                "manage_integration"
              ) ? (
                editFav ? (
                  <li
                    className={`${dashboardStyle.fav_itemCon} ${dashboardStyle.qsItem}`}
                    onClick={() => updateSelectedActions("xeroIntegration")}
                  >
                    <SiXero className={dashboardStyle.icon} />
                    <span className={dashboardStyle.actionName}>
                      Xero Integration
                    </span>
                    {selectedActions.includes("xeroIntegration") ? (
                      <div
                        className={`${dashboardStyle.checkMark} ${dashboardStyle.checked}`}
                      >
                        <MdDone />
                      </div>
                    ) : (
                      <div
                        className={`${dashboardStyle.checkMark} ${dashboardStyle.unchecked}`}
                      >
                        <MdDone />
                      </div>
                    )}
                    <span className={dashboardStyle.clickHint}>
                      Click to select/remove
                    </span>
                  </li>
                ) : selectedActions.includes("xeroIntegration") ? (
                  <li>
                    <NavLink
                      to={`/integration/xero`}
                      className={`${dashboardStyle.fav_itemCon} ${dashboardStyle.qsItem}`}
                    >
                      <SiXero className={dashboardStyle.icon} />
                      <span className={dashboardStyle.actionName}>
                        Xero Integration
                      </span>
                    </NavLink>
                  </li>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          {userDetails.data.permissions.includes("new_product") ? (
            editFav ? (
              <li
                className={`${dashboardStyle.fav_itemCon} ${dashboardStyle.qsItem}`}
                onClick={() => updateSelectedActions("newProduct")}
              >
                <MdOutlineInventory2 className={dashboardStyle.icon} />
                <span className={dashboardStyle.actionName}> New Product</span>
                {selectedActions.includes("newProduct") ? (
                  <div
                    className={`${dashboardStyle.checkMark} ${dashboardStyle.checked}`}
                  >
                    <MdDone />
                  </div>
                ) : (
                  <div
                    className={`${dashboardStyle.checkMark} ${dashboardStyle.unchecked}`}
                  >
                    <MdDone />
                  </div>
                )}
                <span className={dashboardStyle.clickHint}>
                  Click to select/remove
                </span>
              </li>
            ) : selectedActions.includes("newProduct") ? (
              <li>
                <NavLink
                  to={`/inventory/new`}
                  className={`${dashboardStyle.fav_itemCon} ${dashboardStyle.qsItem}`}
                >
                  <MdOutlineInventory2 className={dashboardStyle.icon} />
                  <span className={dashboardStyle.actionName}>
                    {" "}
                    New Product
                  </span>
                </NavLink>
              </li>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          {userDetails.data.permissions.includes("add_delete_edit_users") ? (
            editFav ? (
              <li
                className={`${dashboardStyle.fav_itemCon} ${dashboardStyle.qsItem}`}
                onClick={() => updateSelectedActions("newUser")}
              >
                <AiOutlineUserAdd className={dashboardStyle.icon} />
                <span className={dashboardStyle.actionName}> New User</span>
                {selectedActions.includes("newUser") ? (
                  <div
                    className={`${dashboardStyle.checkMark} ${dashboardStyle.checked}`}
                  >
                    <MdDone />
                  </div>
                ) : (
                  <div
                    className={`${dashboardStyle.checkMark} ${dashboardStyle.unchecked}`}
                  >
                    <MdDone />
                  </div>
                )}
                <span className={dashboardStyle.clickHint}>
                  Click to select/remove
                </span>
              </li>
            ) : selectedActions.includes("newUser") ? (
              <li>
                <NavLink
                  to={`/settings/users/new`}
                  className={`${dashboardStyle.fav_itemCon} ${dashboardStyle.qsItem}`}
                >
                  <AiOutlineUserAdd className={dashboardStyle.icon} />
                  <span className={dashboardStyle.actionName}> New User</span>
                </NavLink>
              </li>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          {userDetails.data.permissions.includes("add_delete_edit_roles") ? (
            editFav ? (
              <li
                className={`${dashboardStyle.fav_itemCon} ${dashboardStyle.qsItem}`}
                onClick={() => updateSelectedActions("newRole")}
              >
                <AiOutlineUsergroupAdd className={dashboardStyle.icon} />
                <span className={dashboardStyle.actionName}> New Role</span>
                {selectedActions.includes("newRole") ? (
                  <div
                    className={`${dashboardStyle.checkMark} ${dashboardStyle.checked}`}
                  >
                    <MdDone />
                  </div>
                ) : (
                  <div
                    className={`${dashboardStyle.checkMark} ${dashboardStyle.unchecked}`}
                  >
                    <MdDone />
                  </div>
                )}
                <span className={dashboardStyle.clickHint}>
                  Click to select/remove
                </span>
              </li>
            ) : selectedActions.includes("newRole") ? (
              <li>
                <NavLink
                  to={`/settings/roles/new`}
                  className={`${dashboardStyle.fav_itemCon} ${dashboardStyle.qsItem}`}
                >
                  <AiOutlineUsergroupAdd className={dashboardStyle.icon} />
                  <span className={dashboardStyle.actionName}> New Role</span>
                </NavLink>
              </li>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          {userDetails.data.permissions.includes(
            "add_delete_edit_price_tier"
          ) ? (
            editFav ? (
              <li
                className={`${dashboardStyle.fav_itemCon} ${dashboardStyle.qsItem}`}
                onClick={() => updateSelectedActions("newPriceTier")}
              >
                <MdAttachMoney className={dashboardStyle.icon} />
                <span className={dashboardStyle.actionName}>
                  {" "}
                  New Price Tier
                </span>
                {selectedActions.includes("newPriceTier") ? (
                  <div
                    className={`${dashboardStyle.checkMark} ${dashboardStyle.checked}`}
                  >
                    <MdDone />
                  </div>
                ) : (
                  <div
                    className={`${dashboardStyle.checkMark} ${dashboardStyle.unchecked}`}
                  >
                    <MdDone />
                  </div>
                )}
                <span className={dashboardStyle.clickHint}>
                  Click to select/remove
                </span>
              </li>
            ) : selectedActions.includes("newPriceTier") ? (
              <li>
                <NavLink
                  to={`/settings/price-tier`}
                  className={`${dashboardStyle.fav_itemCon} ${dashboardStyle.qsItem}`}
                >
                  <MdAttachMoney className={dashboardStyle.icon} />
                  <span className={dashboardStyle.actionName}>
                    {" "}
                    New Price Tier
                  </span>
                </NavLink>
              </li>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          {userDetails.data.permissions.includes(
            "add_delete_edit_customers"
          ) ? (
            editFav ? (
              <li
                className={`${dashboardStyle.fav_itemCon} ${dashboardStyle.qsItem}`}
                onClick={() => updateSelectedActions("newCustomer")}
              >
                <MdPersonAddAlt className={dashboardStyle.icon} />
                <span className={dashboardStyle.actionName}> New Customer</span>
                {selectedActions.includes("newCustomer") ? (
                  <div
                    className={`${dashboardStyle.checkMark} ${dashboardStyle.checked}`}
                  >
                    <MdDone />
                  </div>
                ) : (
                  <div
                    className={`${dashboardStyle.checkMark} ${dashboardStyle.unchecked}`}
                  >
                    <MdDone />
                  </div>
                )}
                <span className={dashboardStyle.clickHint}>
                  Click to select/remove
                </span>
              </li>
            ) : selectedActions.includes("newCustomer") ? (
              <li>
                <NavLink
                  to={`/customer/new`}
                  className={`${dashboardStyle.fav_itemCon} ${dashboardStyle.qsItem}`}
                >
                  <MdPersonAddAlt className={dashboardStyle.icon} />
                  <span className={dashboardStyle.actionName}>
                    {" "}
                    New Customer
                  </span>
                </NavLink>
              </li>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          {userDetails.data.permissions.includes(
            "add_delete_edit_suppliers"
          ) ? (
            editFav ? (
              <li
                className={`${dashboardStyle.fav_itemCon} ${dashboardStyle.qsItem}`}
                onClick={() => updateSelectedActions("newSupplier")}
              >
                <MdOutlinePersonPin className={dashboardStyle.icon} />
                <span className={dashboardStyle.actionName}> New Supplier</span>
                {selectedActions.includes("newSupplier") ? (
                  <div
                    className={`${dashboardStyle.checkMark} ${dashboardStyle.checked}`}
                  >
                    <MdDone />
                  </div>
                ) : (
                  <div
                    className={`${dashboardStyle.checkMark} ${dashboardStyle.unchecked}`}
                  >
                    <MdDone />
                  </div>
                )}
                <span className={dashboardStyle.clickHint}>
                  Click to select/remove
                </span>
              </li>
            ) : selectedActions.includes("newSupplier") ? (
              <li>
                <NavLink
                  to={`/supplier/new`}
                  className={`${dashboardStyle.fav_itemCon} ${dashboardStyle.qsItem}`}
                >
                  <MdOutlinePersonPin className={dashboardStyle.icon} />
                  <span className={dashboardStyle.actionName}>
                    {" "}
                    New Supplier
                  </span>
                </NavLink>
              </li>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          {editFav ? (
            <></>
          ) : (
            <li
              className={`${dashboardStyle.fav_itemCon} ${dashboardStyle.addItem}`}
              onClick={() => setEditedFav(true)}
            >
              <MdAdd className={dashboardStyle.icon} />
              <span className={dashboardStyle.actionName}>
                {" "}
                Add new feature
              </span>
            </li>
          )}
        </ul>
      ) : (
        <ErrorMessage mess="Requesting user details failed, please refresh the page and try again" />
      )}

      {editFav ? (
      <div className="w-full mt-4">
          <SimpleBlueButton action={saveSelectedActions} name="Update" />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
