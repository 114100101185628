import React, { useState } from "react";
import { useSelector } from "react-redux";

import { SimpleBlueButton } from "../../../components/editComponents";
import { StyledInput, StyledSelect } from "../../../components/inputFields";
import { K_CURRENCIES } from "../../../constantsData/currencies";
import { validatePrice } from "../../../functions/validate";

export default function CurrencyForm({ btnName, values, onSubmit, type }) {
  const { userDetails } = useSelector((state) => state.userDetail);

  const [newValues, setNewValues] = useState(values);
  const [formErrors, setFormErrors] = useState({});

  const checkValidation = () => {
    const hasErrors = validate(newValues);

    if (Object.keys(hasErrors).length === 0) {
      onSubmit(newValues);
    } else {
      setFormErrors(hasErrors);
    }
  };
  // handle change when input
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (e.type === "number") {
      //only allow two decimal number
      const validated = validatePrice(value);
      if (validated) {
        setNewValues({ ...newValues, [name]: value });
      }
    } else {
      setNewValues({ ...newValues, [name]: value });
    }
  };

  // check input validation
  const validate = (values) => {
    const errors = {};
    if (!values.code) {
      errors.code = "please select a currency code.";
    }
    if (!values.rate) {
      errors.rate = "please enter an exchange rate";
    }

    return errors;
  };

  return (
    <form>
      <StyledInput
        label="Base Currency"
        type="text"
        name="baseCurrency"
        value={userDetails.data.orgInfo.base_currency}
        isReadOnly
      />
      {type === "edit" ? (
        <StyledInput
          label="Currency Code*"
          type="text"
          name="baseCurrency"
          value={newValues.code.value}
          isReadOnly
        />
      ) : (
        <StyledSelect
          label="Currency Code*"
          autofocus="autofocus"
          placeHolder="Select currency"
          selectOptions={K_CURRENCIES.filter(
            (x) => x.value !== userDetails.data.orgInfo.base_currency
          )}
          value={newValues.code}
          onChange={(code) =>
            handleChange({
              target: { value: code, name: "code" },
            })
          }
          error={formErrors.code}
        />
      )}

      <StyledInput
        label="Description"
        type="text"
        name="name"
        value={newValues.description}
        onChange={(description) =>
          handleChange({
            target: { value: description, name: "description" },
          })
        }
        error={formErrors.description}
      />
      <StyledInput
        label="Exchange rate"
        type="text"
        name="rate"
        placeHolder="Exchange rate against base currency"
        value={newValues.rate}
        onChange={(rate) =>
          handleChange({
            type: "number",
            target: { value: rate, name: "rate" },
          })
        }
        error={formErrors.rate}
      />
      <div className="w-full mt-6">
        <SimpleBlueButton name={btnName} action={checkValidation} />
      </div>
    </form>
  );
}
