import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  stockAdjustListRequest, stockTransfertListRequest,
} from "../../../actions/productActions";
import {
  Loader,
  ErrorMessage,
  ViewDateText,
  Tag,
} from "../../../components/viewComponents";
import {
  ScreenTitle,
  AddLinkButton,
  ViewButtonLink,
} from "../../../components/editComponents";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";
import Pagination from "../../../components/Pagination";

export default function StockBulkTransferScreen() {
  useDocumentTitle("Stock Transfer");
  const dispatch = useDispatch();

  // access check for the actions
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const { loading, transferList, error, success } = useSelector(
    (state) => state.stockTransferList
  );

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  useEffect(() => {
    dispatch(stockTransfertListRequest(pageNum, pageSize));
  }, [dispatch, pageNum]);

  const PreviewLink = ({ itemId }) => {
    return userDetailsSuccess ? (
      userDetails.data.permissions.includes("stock_transfer") ? (
        <ViewButtonLink path={`/inventory/bulk-stock-transfer/preview/${itemId}`} />
      ) : (
        <></>
      )
    ) : (
      <></>
    );
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Stock Transfer" />
      <ScreenTitle title="Stock Transfer" />

      <div className={commonStyle.pageContentContainer}>
        {loading ? (
          <Loader mess="Requesting data, please wait a moment..." />
        ) : success ? (
          <div className={commonStyle.longDataTable}>
            <AddLinkButton
              name="New Stock Transfer"
              path="/inventory/bulk-stock-transfer/new"
            />

            {/* hold the product list table */}
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Create Date</th>
                  <th>Transfer Out Location</th>
                  <th>Transfer In Location</th>
                  <th>Creator</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {transferList.transferOrders.length > 0 ? (
                  transferList.transferOrders.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <ViewDateText value={item.atime} />
                      </td>

                      <td>
                        {" "}
                        <Tag name={item.outLocation} color="gray" />
                      </td>
                      <td>
                      <Tag name={item.inLocation} color="gray" />
                      </td>

                      <td>
                        {item.creatorFirstName} {item.creatorLastName}
                      </td>
                      <td>
                        {" "}
                        {item.status === "Approved" ? (
                          <Tag name={item.status} color="green" />
                        ) : item.status === "Draft" ? (
                          <Tag name={item.status} color="yellow" />
                        ) : item.status === "Completed" ? (
                          <Tag name={item.status} color="blue" />
                        ) : (
                          <></>
                        )}{" "}
                      </td>

                      {/* according to different status show different actions */}
                      <td style={{ width: "20%" }}>
                        <PreviewLink itemId={item.id} />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      <p>
                        There are no stock transfer order available, create a new
                        stock transfer order now.
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {transferList.transferOrders.length > 0 ? (
              <Pagination
                totalPageNum={transferList.totalPages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
    </div>
  );
}
