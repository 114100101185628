import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { importXeroContactsRequest } from "../../../api/importXeroDataServices";
import {
  LoadingButton,
  SimpleBlueButton,
  SingleRatioButton,
} from "../../../components/editComponents";
import {
  Loader,
  TextErrorMessage,
  ViewDateText,
  ViewLabel,
} from "../../../components/viewComponents";
import integrationStyle from "../integrationStyle.module.css";
import commonStyle from "../../../style/commonStyle.module.css";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function ImportXeroSuppliersForm({ importSuccess }) {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [importStatus, setImportStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [selectAll, setSelectAll] = useState(true);

  const [xeroContacts, setXeroContacts] = useState([]);
  const [lastImportTime, setLastImportTime] = useState("");

  const [successList, setSuccessList] = useState([]);
  const [failedList, setFailedList] = useState([]);

  const [showList, setShowList] = useState(true);
  const [showResult, setShowResult] = useState(false);

  const handleChange = (e) => {
    const { value, index } = e.target;

    let contactsCopy = [...xeroContacts];
    contactsCopy[index]["is_imported"] = value;
    setXeroContacts(contactsCopy);
  };

  const handleAllChange = (value) => {
    if (value) {
      let copy = [...xeroContacts];
      copy.forEach((x) => {
        x.is_imported = true;
      });
      setXeroContacts(copy);
      setSelectAll(true);
    } else {
      let copy = [...xeroContacts];
      copy.forEach((x) => {
        x.is_imported = false;
      });
      setXeroContacts(copy);
      setSelectAll(false);
    }
  };

  const importXeroSuppliers = async () => {
    try {
      setRequestStatus((preState) => ({ ...preState, loading: true }));
      const responseData = await importXeroContactsRequest("supplier", config);

      if (responseData.success) {
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
        setXeroContacts(responseData.data);
        setLastImportTime(responseData.latstImportTime);
      } else {
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          success: false,
          error: "Import suppliers form Xero failed.",
        }));
      }
    } catch (error) {
      setRequestStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  useEffect(() => {
    importXeroSuppliers();
  }, [dispatch]);

  //////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////
  const saveCustomersRequest = () => {
    if (!xeroContacts.length) {
      return;
    }

    try {
      setImportStatus((preState) => ({ ...preState, loading: true }));
      axios
        .post(
          SERVER_URL + `/integration/xero/import_xero_suppliers`,
          xeroContacts,
          config
        )
        .then(async (response) => {
          if (response.data.success) {
            setShowList(false);
            setShowResult(true);
            setSuccessList(response.data.data.successData);
            setFailedList(response.data.data.failedData);
            setImportStatus((preState) => ({
              ...preState,
              loading: false,
              success: true,
            }));
          } else {
            setImportStatus((preState) => ({
              ...preState,
              loading: false,
              success: false,
              error: "Import Xero suppliers to database failed.",
            }));
          }
        })
        .catch((err) => {
          setImportStatus((preState) => ({
            ...preState,
            loading: false,
            error: "Connection error, please try again later.",
          }));
        });
    } catch (error) {
      setImportStatus((preState) => ({
        ...preState,
        loading: false,
        success: false,
        error: "Connection error, please try again later.",
      }));
    }
  };

  return (
    <div style={{ height: "100%", paddingTop: "20px" }}>
      {importStatus.error ? (
        <TextErrorMessage mess={importStatus.error} />
      ) : (
        <></>
      )}
      {showList ? (
        <div className={integrationStyle.resultWrapper}>
          {requestStatus.loading ? (
            <Loader mess="Requesting contact from Xero..." />
          ) : requestStatus.success ? (
            <div
              style={{ height: "calc(100vh - 240px)", position: "relative" }}
            >
              <p className={integrationStyle.xeroContactsCount}>
                Find
                <span className={integrationStyle.xeroContactsCountNum}>
                  {xeroContacts.length}
                </span>
                suppliers from your Xero system
                {lastImportTime && (
                  <div style={{ display: "flex" }}>
                    <span style={{ marginRight: "2px" }}>
                      , since you last import on
                    </span>
                    <ViewDateText value={lastImportTime} />
                  </div>
                )}
              </p>
              {xeroContacts.length > 0 ? (
                <div className={integrationStyle.xeroProductsCon}>
                  <table className={commonStyle.pageTable}>
                    <thead>
                      <tr>
                        <th></th>
                        <th>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginBottom: "22px",
                            }}
                          >
                            {/* <SingleRatioButton
                              label={""}
                              name="select_all"
                              isOn={selectAll}
                              onChange={(is_imported) =>
                                handleAllChange(is_imported)
                              }
                            /> */}
                          </div>
                        </th>
                        <th>Supplier Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {xeroContacts.map((elem, index) => (
                        <tr key={elem.id}>
                          <td>{index + 1}</td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginBottom: "22px",
                              }}
                            >
                              {/* <SingleRatioButton
                                key={index}
                                label={""}
                                name="is_imported"
                                isOn={elem.is_imported}
                                onChange={(is_imported) =>
                                  handleChange({
                                    target: {
                                      value: is_imported,
                                      index: index,
                                    },
                                  })
                                }
                              /> */}
                            </div>
                          </td>
                          <td>{elem.xero_contact_name} </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <></>
              )}
              <div style={{ position: "absolute", bottom: 0 }}>
                {importStatus.loading ? (
                  <LoadingButton name="Importing suppliers to database..." />
                ) : (
                  <SimpleBlueButton
                    name="Import Suppliers from Xero"
                    action={saveCustomersRequest}
                  />
                )}
              </div>
            </div>
          ) : requestStatus.error ? (
            <TextErrorMessage mess={requestStatus.error} />
          ) : (
            <></>
          )}
        </div>
      ) : showResult ? (
        <div style={{ height: "100%" }}>
          <p className={integrationStyle.xeroContactsCount}>
            <span className={integrationStyle.xeroContactsCountNum}>
              {successList.length}
            </span>{" "}
            suppliers imported successfully
          </p>
          <p className={integrationStyle.xeroContactsCount}>
            <span className={integrationStyle.xeroContactsCountNumError}>
              {failedList.length}
            </span>
            failed
          </p>
          {failedList.length > 0 ? (
            failedList.map((elem, index) => (
              <div className={integrationStyle.xeroContactsCon}>
                <ViewLabel title={elem} key={index} />{" "}
              </div>
            ))
          ) : (
            <></>
          )}

          <div className={integrationStyle.btnCon}>
            <SimpleBlueButton name="Confirm" action={importSuccess} />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
