import React, { useState } from "react";
import axios from "axios";
import loginStyle from "./loginStyle.module.css";
import {
  LoadingButton,
  SimpleBlueButton,
  XeroButton,
  SingleRatioButton,
} from "../../components/editComponents";
import {
  StyledSelect,
  StyledInput,
  StyledPasswordInput,
  StyledEmailInput,
} from "../../components/inputFields";
import { AiOutlineCheckCircle } from "react-icons/ai";
import RegisterSteps from "./RegisterSteps";
import { TextErrorMessage, Loader } from "../../components/viewComponents";
import { K_CURRENCIES } from "../../constantsData/currencies";
import { K_COUNTRIES } from "../../constantsData/countries";
import { K_INDUSTRYS } from "../../constantsData/industries";
import { K_DATEFORMAT } from "../../constantsData/dateFormats";
import { K_TIMEZONES } from "../../constantsData/timeZones";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function RegisterForm({
  initialValues,
  onSubmit,
  registerLoading,
  registerError,
}) {
  const headers = {
    "Content-type": "application/json",
  };

  const [registerValues, setRegisterValues] = useState(initialValues);

  //define the error message
  const [formErrors, setFormErrors] = useState({});
  // manage steps
  const [step, setStep] = useState("step1");
  const [emailCheckLoading, setEmailCheckLoading] = useState(false);

  const [term, setTerm] = useState(true);
  const [termError, setTermError] = useState("");

  // handle input field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setRegisterValues({ ...registerValues, [name]: value });
  };
  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setRegisterValues({ ...registerValues, [name]: value });
  };

  // handle email input field change
  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    const errors = {};
    let re = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (re.test(value)) {
      // this is a valid email address
      setFormErrors({});
      setRegisterValues({ ...registerValues, [name]: value });
    } else {
      // invalid email, maybe show an error to the user.
      setRegisterValues({ ...registerValues, [name]: value });
      errors.userEmail = "Please enter a valid email address!";
      setFormErrors(errors);
    }
  };

  // when click next, check email and password
  const nextRequest = () => {
    if (!term) {
      setTermError("Please accept the term of use and pricy policy");
      return;
    }
    const hasErrors = validateLoginDetail(registerValues);

    if (Object.keys(hasErrors).length === 0) {
      setEmailCheckLoading(true);
      try {
        axios
          .post(
            SERVER_URL + `/user/exists`,
            { userEmail: registerValues.userEmail },
            headers
          )
          .then(async (response) => {
            if (response.data.success) {
              setEmailCheckLoading(false);
              if (response.data.data.exists) {
                setFormErrors({
                  emailCheck:
                    "This email address is already being registered, please login.",
                });
              } else {
                setFormErrors({});
                setStep("step2");
              }
            } else {
              setEmailCheckLoading(false);
              setFormErrors({
                emailCheck: "Email check failed, please try again later.",
              });
            }
          })
          .catch((err) => {
            setEmailCheckLoading(false);
            setFormErrors({
              emailCheck: "Email check failed, please try again later.",
            });
          });
      } catch (error) {
        setEmailCheckLoading(false);
        setFormErrors({
          emailCheck: "Email check failed, please try again later.",
        });
      }
    } else {
      setEmailCheckLoading(false);
      setFormErrors(hasErrors);
    }
  };

  const changeStepRequest = (x) => {
    setStep(x);
  };

  // check input validation
  const validateLoginDetail = (values) => {
    const errors = {};
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const regexPassword =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~^@#$!%*?&])[a-zA-Z0-9~^@#$!%*?&]{8,}$/;

    if (!values.userEmail) {
      errors.userEmail = "Email is required!";
    } else if (!regexEmail.test(values.userEmail)) {
      errors.userEmail = "Email is invalid!";
    }

    if (!values.password) {
      errors.password = "Password is required!";
    } else if (!regexPassword.test(values.password)) {
      errors.password = "Password is invalid!";
    }

    return errors;
  };

  // form submit
  const checkValidation = () => {
    const hasErrors = validateCompanyDetail(registerValues);

    if (Object.keys(hasErrors).length === 0) {
      onSubmit(registerValues);
      return true;
    } else {
      setFormErrors(hasErrors);
      return false;
    }
  };

  // check input validation
  const validateCompanyDetail = (values) => {
    const errors = {};

    if (!values.organizationName) {
      errors.organizationName = "Organization name is required!";
    }

    if (!values.industry) {
      errors.industry = "Industry is required!";
    }

    if (!values.country) {
      errors.country = "Country is required!";
    }
    if (!values.baseCurrency) {
      errors.baseCurrency = "Base currency is required!";
    }

    if (!values.dateFormat) {
      errors.dateFormat = "Date format is required!";
    }
    if (!values.timeZone) {
      errors.timeZone = "Time zone is required!";
    }
    return errors;
  };

  /////////////////////////////////////////////////////////////

  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const signupWithXeroRequest = () => {
    if (!term) {
      setTermError("Please accept the term of use and pricy policy");
      return;
    }
    try {
      setRequestStatus({ ...requestStatus, loading: true });
      axios
        .get(SERVER_URL + `/register/xero`)
        .then(async (response) => {
          if (response.data.success) {
            setRequestStatus({
              ...requestStatus,
              loading: false,
              success: true,
            });
            window.location.href = response.data.data.xero_url;
          } else {
            setRequestStatus({
              ...requestStatus,
              loading: false,
              error: "Request Xero OAuth2.0 failed, please try again later.",
            });
          }
        })
        .catch((err) => {
          setRequestStatus({
            ...requestStatus,
            loading: false,
            error: "Request Xero OAuth2.0 failed, please try again later.",
          });
        });
    } catch (error) {
      setRequestStatus({
        ...requestStatus,
        loading: false,
        error: "Connection error, please try again later.",
      });
    }
  };

  return (
    <>
      <RegisterSteps currentStep={step} action={changeStepRequest} />
      {formErrors.emailCheck ? (
        <TextErrorMessage mess={formErrors.emailCheck} />
      ) : (
        <></>
      )}
      {registerError ? <TextErrorMessage mess={registerError} /> : <></>}
      {termError ? <TextErrorMessage mess={termError} /> : <></>}

      {emailCheckLoading ? (
        <Loader mess="Checking email address, please wait a moment..." />
      ) : (
        <></>
      )}

      {step === "step1" ? (
        <form>
          <StyledEmailInput
            label="Email*"
            type="email"
            name="userEmail"
            autofocus="autofocus"
            value={registerValues.userEmail}
            onChange={(userEmail) =>
              handleEmailChange({
                target: { value: userEmail, name: "userEmail" },
              })
            }
            error={formErrors.userEmail}
          />
          <StyledPasswordInput
            label="Password*"
            name="password"
            value={registerValues.password}
            showHints={true}
            onChange={(password) =>
              handlePasswordChange({
                target: { value: password, name: "password" },
              })
            }
            error={formErrors.password}
          />
          <div className="w-full mt-4">
            <SimpleBlueButton name="Next" action={nextRequest} />
          </div>

          {requestStatus.loading ? (
            <LoadingButton name="Redirecting to Xero..." />
          ) : (
            <XeroButton
              name="Sign up with Xero"
              action={signupWithXeroRequest}
            />
          )}

          <div className={loginStyle.termCon}>
            <SingleRatioButton
              label="I have read and accept the"
              name="hasAttrs"
              isOn={term}
              onChange={(term) => setTerm(term)}
            />

            <a
              href={`https://stockunify.com/legal/terms-of-use`}
              className={loginStyle.termLink}
              target="_blank"
              rel="noreferrer"
            >
              Term of use
            </a>
            <span className={loginStyle.termLinkText}> and </span>
            <a
              href={`https://stockunify.com/legal/privacy-policy`}
              className={loginStyle.termLink}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </div>
        </form>
      ) : (
        <>
          <StyledInput
            label="Organization Name*"
            type="text"
            name="organizationName"
            value={registerValues.organizationName}
            onChange={(organizationName) =>
              handleChange({
                target: {
                  value: organizationName,
                  name: "organizationName",
                },
              })
            }
            error={formErrors.organizationName}
          />
          <StyledSelect
            value={registerValues.industry}
            placeHolder="Select industry"
            label="Industry*"
            selectOptions={K_INDUSTRYS}
            onChange={(industry) =>
              handleChange({
                target: { value: industry, name: "industry" },
              })
            }
            error={formErrors.industry}
          />
          <StyledSelect
            value={registerValues.country}
            placeHolder="Select country"
            label="Country*"
            selectOptions={K_COUNTRIES}
            onChange={(country) =>
              handleChange({
                target: { value: country, name: "country" },
              })
            }
            error={formErrors.country}
          />

          <StyledSelect
            value={registerValues.baseCurrency}
            placeHolder="Select base currency"
            label="Base Currecy*"
            selectOptions={K_CURRENCIES}
            onChange={(baseCurrency) =>
              handleChange({
                target: { value: baseCurrency, name: "baseCurrency" },
              })
            }
            error={formErrors.baseCurrency}
          />

          <StyledSelect
            value={registerValues.dateFormat}
            label="Date Format*"
            placeHolder="Select date format"
            selectOptions={K_DATEFORMAT}
            onChange={(dateFormat) =>
              handleChange({
                target: { value: dateFormat, name: "dateFormat" },
              })
            }
            error={formErrors.dateFormat}
          />
          <StyledSelect
            label="Time Zone*"
            placeHolder="Type to search time zone"
            value={registerValues.timeZone}
            selectOptions={K_TIMEZONES}
            onChange={(timeZone) =>
              handleChange({
                target: { value: timeZone, name: "timeZone" },
              })
            }
            error={formErrors.timeZone}
          />
          <div className="w-full mt-4">
            {registerLoading ? (
              <LoadingButton name="Creating account...." />
            ) : (
              <SimpleBlueButton
                name="Start Free Trial Now"
                action={checkValidation}
              />
            )}
          </div>
        </>
      )}
    </>
  );
}
