import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { importXeroProductsRequest } from "../../../api/importXeroDataServices";
import {
  LoadingButton,
  SimpleBlueButton,
  SingleRatioButton,
} from "../../../components/editComponents";
import {
  Loader,
  TextErrorMessage,
  VariantSkuTag,
  ViewDateText,
  ViewLabel,
} from "../../../components/viewComponents";
import integrationStyle from "../integrationStyle.module.css";
import commonStyle from "../../../style/commonStyle.module.css";
import { productOptionsRequest } from "../../../actions/productActions";
import { NoBorderSelect, StyledSelect } from "../../../components/inputFields";
import { convtPrice } from "../../../functions/functions";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function ImportXeroProductsForm({ importSuccess }) {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  const { loading, options, error, success } = useSelector(
    (state) => state.productOptions
  );

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [importStatus, setImportStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [locationErrors, setLocationErrors] = useState({});

  const [selectAll, setSelectAll] = useState(true);

  const [xeroProducts, setXeroProducts] = useState([]);
  const [lastImportTime, setLastImportTime] = useState("");

  const [successList, setSuccessList] = useState([]);
  const [failedList, setFailedList] = useState([]);

  const [showList, setShowList] = useState(true);
  const [showResult, setShowResult] = useState(false);

  const handleSelectedChange = (e) => {
    const { value, index } = e.target;

    let copy = [...xeroProducts];
    copy[index]["is_imported"] = value;
    setXeroProducts(copy);
  };

  const handleChange = (e) => {
    const { name, value, index } = e.target;
    let copy = [...xeroProducts];
    copy[index][name] = value;
    setXeroProducts(copy);
  };


  const handleAllChange = (value) => {
    if (value) {
      let copy = [...xeroProducts];
      copy.forEach((x) => {
        x.is_imported = true;
      });
      setXeroProducts(copy);
      setSelectAll(true);
    } else {
      let copy = [...xeroProducts];
      copy.forEach((x) => {
        x.is_imported = false;
      });
      setXeroProducts(copy);
      setSelectAll(false);
    }
  };

  const importXeroProducts = async () => {
    try {
      setRequestStatus((preState) => ({ ...preState, loading: true }));
      const responseData = await importXeroProductsRequest(config);

      if (responseData.success) {
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
        setXeroProducts(responseData.data);
        setLastImportTime(responseData.latstImportTime);
      } else {
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          success: false,
          error: "Import products form Xero failed.",
        }));
      }
    } catch (error) {
      setRequestStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  useEffect(() => {
    importXeroProducts();
    dispatch(productOptionsRequest());
  }, [dispatch]);

  useEffect(() => {
    if(success && requestStatus.success){
      let hasDefaultLocation = options.location.filter((x) => x.isDefault).length ? options.location.filter((x) => x.isDefault)[0] : "";

      let defaulLocation = hasDefaultLocation ? hasDefaultLocation :  options.location[0];
      let copy = [...xeroProducts];
      copy.forEach((x)=>{
        x.location = defaulLocation;
      })
      setXeroProducts(copy);
    }
  }, [success, requestStatus.success]);

  //////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////
  const checkValidation = () => {

    const hasErrors = validate(xeroProducts);
    if (hasErrors && Object.keys(hasErrors).length === 0) {
      setLocationErrors({});
      saveProductsRequest();
    } else {
      setLocationErrors(hasErrors);
    }

  }

  const validate = (values) => {
    const errors = {};


    values.forEach((x, index)=>{
      if(x.tracked && x.quantityOnHand > 0 && !x.location){
        errors[index] = "Please select a location"
      }
    })

    return errors;
  };

  const saveProductsRequest = () => {
    if (!xeroProducts.length) {
      return;
    }
    try {
      setImportStatus((preState) => ({ ...preState, loading: true }));
      axios
        .post(
          SERVER_URL + `/integration/xero/import_xero_products`,
          xeroProducts,
          config
        )
        .then(async (response) => {
          if (response.data.success) {
            setShowList(false);
            setShowResult(true);
            setSuccessList(response.data.data.successData);
            setFailedList(response.data.data.failedData);
            setImportStatus((preState) => ({
              ...preState,
              loading: false,
              success: true,
            }));
          } else {
            setImportStatus((preState) => ({
              ...preState,
              loading: false,
              success: false,
              error: "Import Xero products to database failed.",
            }));
          }
        })
        .catch((err) => {
          setImportStatus((preState) => ({
            ...preState,
            loading: false,
            error: "Connection error, please try again later.",
          }));
        });
    } catch (error) {
      setImportStatus((preState) => ({
        ...preState,
        loading: false,
        success: false,
        error: "Connection error, please try again later.",
      }));
    }
  };

  return (
    <div style={{ height: "100%", paddingTop: "20px" }}>
        {importStatus.error ? (
                  <TextErrorMessage mess={importStatus.error} />
                ) : (
                  <></>
                )}

      {showList ? (
        <div className={integrationStyle.resultWrapper}>
          {requestStatus.loading ? (
            <Loader mess="Requesting products from Xero, it may take a while if you have a large quantity items in your Xero system." />
          ) : requestStatus.success ? (
            <div
              style={{ height: "calc(100vh - 180px)", position: "relative" }}
            >
              <>
              <p className={integrationStyle.xeroContactsCount}>
                Find
                <span className={integrationStyle.xeroContactsCountNum}>
                  {xeroProducts.length}
                </span>
                products from your Xero system
                {lastImportTime && (
                  <div style={{ display: "flex" }}>
                    <span style={{ marginRight: "2px" }}>
                      , since you last import on
                    </span>
                    <ViewDateText value={lastImportTime} />
                  </div>
                )}
              </p>
              </>
              {xeroProducts.length > 0 ? (
                <div className={integrationStyle.xeroProductsCon}>
                                <p>The tracked item, which has a stock quantity greater than 0, has been assigned a default location. However, you have the option to change this location. Additionally, you can assign a unit or category to the product.</p>

                  <table className={commonStyle.pageTable}>
                    <thead>
                      <tr>
                        <th></th>
                        <th>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginBottom: "22px",
                            }}
                          >
                            {/* <SingleRatioButton
                              label={""}
                              name="select_all"
                              isOn={selectAll}
                              onChange={(is_imported) =>
                                handleAllChange(is_imported)
                              }
                            /> */}
                          </div>
                        </th>
                        <th>Code</th>
                        <th>Product Name</th>
                        <th>Sale Price</th>
                        <th>Purchase Cost</th>
                        <th>Tracked</th>
                        <th>Stock on Hand</th>
                        <th>Total Cost</th>
                        <th>Average Cost</th>
                        <th>location(Required)</th>
                        <th>Unit(Optional)</th>
                        <th>Category(Optional)</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {xeroProducts.map((item, index) => (
                        <tr key={item.id}>
                          <td>{index + 1}</td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginBottom: "22px",
                              }}
                            >
                              {/* <SingleRatioButton
                                key={index}
                                label={""}
                                name="is_imported"
                                isOn={item.is_imported}
                                onChange={(is_imported) =>
                                  handleSelectedChange({
                                    target: {
                                      value: is_imported,
                                      index: index,
                                    },
                                  })
                                }
                              /> */}
                            </div>
                          </td>
                          <td><VariantSkuTag name={item.code} /></td>
                          <td>{item.name} </td>
                          <td>{item.salePrice ? convtPrice(item.salePrice) : ""} </td>
                          <td>{item.purchaseCost ? convtPrice(item.purchaseCost) : ""} </td>
                          <td>{item.tracked ? "Yes" : "No"} </td>
                          <td>{item.tracked ? item.quantityOnHand : ""} </td>

                          <td>{item.tracked ? convtPrice(item.totalCostPool) : ""} </td>
                          <td>{item.tracked ? convtPrice(item.averageCost) : ""} </td>
                          <td className={integrationStyle.xeroProductsEditCol}>
                            {" "}
                            {item.quantityOnHand > 0 ? (
                              <NoBorderSelect
                                placeHolder="Select Location"
                                value={item.location}
                                selectOptions={success ? options.location : []}
                                onChange={(location) =>
                                  handleChange({
                                    target: {
                                      value: location,
                                      name: "location",
                                      index: index,
                                    },
                                  })
                                }
                                error={locationErrors[index]}
                              />
                            ) : (
                              <></>
                            )}
                          </td>
                          <td className={integrationStyle.xeroProductsEditCol}>
                            <NoBorderSelect
                              placeHolder="Select Unit"
                              value={item.unit}
                              selectOptions={success ? options.units : []}
                              onChange={(unit) =>
                                handleChange({
                                  target: {
                                    value: unit,
                                    name: "unit",
                                    index: index,
                                  },
                                })
                              }
                            />
                          </td>
                          <td className={integrationStyle.xeroProductsEditCol}>
                            <NoBorderSelect
                              placeHolder="Select Category"
                              value={item.category}
                              selectOptions={success ? options.category : []}
                              onChange={(category) =>
                                handleChange({
                                  target: {
                                    value: category,
                                    name: "category",
                                    index: index,
                                  },
                                })
                              }
                            />
                          </td>

                          <td>
                            <div className={commonStyle.actionsGrop}></div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <></>
              )}
              <div style={{ position: "absolute", bottom: 0 }}>
           

                {importStatus.loading ? (
                  <LoadingButton name="Importing products to database..." />
                ) : (
                  <SimpleBlueButton
                    name="Import Products from Xero"
                    action={checkValidation}
                  />
                )}
              </div>
            </div>
          ) : requestStatus.error ? (
            <TextErrorMessage mess={requestStatus.error} />
          ) : (
            <></>
          )}
        </div>
      ) : showResult ? (
        <div style={{ height: "100%" }}>
          <p className={integrationStyle.xeroContactsCount}>
            <span className={integrationStyle.xeroContactsCountNum}>
              {successList.length}
            </span>{" "}
            products imported successfully
          </p>
          <p className={integrationStyle.xeroContactsCount}>
            <span className={integrationStyle.xeroContactsCountNumError}>
              {failedList.length}
            </span>
            failed
          </p>
          {failedList.length > 0 ? (
            failedList.map((elem, index) => (
                <p className="text-xs text-gray mt-1" key={index}>{elem.name} : {elem.reason}</p>
            ))
          ) : (
            <></>
          )}

          <div className="w-full mt-4">
            <SimpleBlueButton name="Confirm" action={importSuccess} />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
