import React, { useState } from "react";

import {
  SimpleBlueButton,
  SingleRatioButton,
} from "../../components/editComponents";
import {
  StyledPercentInput,
  StyledInput,
} from "../../components/inputFields";
import { validatePrice } from "../../functions/validate";

export default function TaxForm({ btnName, values, onSubmit }) {
  
  const [rateValues, setRateValues] = useState(values);
  const [formErrors, setFormErrors] = useState({});

  const checkValidation = () => {
    const hasErrors = validate(rateValues);

    if (Object.keys(hasErrors).length === 0) {
      onSubmit(rateValues);
    } else {
      setFormErrors(hasErrors);
    }
  };
  // handle change when input
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (e.type === "number") {
      //only allow two decimal number
      const validated = validatePrice(value);
      if (validated) {
        setRateValues({ ...rateValues, [name]: value });
      }
    } else {
      setRateValues({ ...rateValues, [name]: value });
    }
    
  };

  // check input validation
  const validate = (values) => {

    const errors = {};
    if (!values.taxCode) {
      errors.taxCode = "Tax code is required!";
    }
    if (!values.taxName) {
      errors.taxName = "Tax name is required!";
    }
  
      if(Number(values.taxRate) < 0 ){
        errors.taxRate = "Tax rate must be greater than zero";
      }

      if(values.taxRate === ''){
        errors.taxRate = "Tax rate is required!";
      }
    


    return errors;
  };

  return (
    <form>
      <StyledInput
        label="Tax Code"
        type="text"
        name="taxCode"
        autofocus="autofocus"
        value={rateValues.taxCode}
        onChange={(taxCode) =>
          handleChange({
            target: { value: taxCode, name: "taxCode" },
          })
        }
        error={formErrors.taxCode}
      />
      <StyledInput
        label="Tax Name"
        type="text"
        name="taxName"
        value={rateValues.taxName}
        onChange={(taxName) =>
          handleChange({
            target: { value: taxName, name: "taxName" },
          })
        }
        error={formErrors.taxName}
      />
      <StyledPercentInput
        label="Tax Rate"
        type="text"
        name="taxRate"
        value={rateValues.taxRate}
        onChange={(taxRate) =>
          handleChange({
            type:"number",
            target: { value: taxRate, name: "taxRate" },
          })
        }
        error={formErrors.taxRate}
      />
   
      <SingleRatioButton
        label="Set as default tax rate"
        name="isDefault"
        isOn={rateValues.isDefault}
        onChange={(isDefault) =>
          handleChange({
            target: { value: isDefault, name: "isDefault" },
          })
        }
      />
       <div className="w-full mt-6">
      <SimpleBlueButton name={btnName} action={checkValidation} />
      </div>
    </form>
  );
}
