import React, { useState, useEffect } from "react";
import axios from "axios";
import { pdf } from "@react-pdf/renderer";
import { useDispatch, useSelector } from "react-redux";
import { emailTemplatesDetailsRequest } from "../../../actions/orgSettingsActions";
import {
  LoadingButton,
  SimpleBlueButton,
  SingleRatioButton,
} from "../../../components/editComponents";
import {
  StyledEmailInput,
  StyledTextarea,
  StyledInput,
} from "../../../components/inputFields";
import {
  SuccessDialog,
  ErrorMessage,
  Loader,
  ViewText,
  ErrorText,
} from "../../../components/viewComponents";
import PurchaseOrderPDF from "./PurchaseOrderPDF";
import { generatePurchaseOrderRequest } from "../../../api/generatePDFServices";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function PoEmailForm({ supplierEmail, orderInfo, closeModal }) {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const pdf_config = {
    responseType: "arraybuffer",
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [initialStatus, setInitialStatus] = useState(true);
  const [emailContentValues, setEmailContentValues] = useState();
  const [formErrors, setFormErrors] = useState({});

  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [dialog, setDialog] = useState(false);

  const { loading, templateDetails, error, success } = useSelector(
    (state) => state.emailTemplateDetails
  );
  const { userDetails, success: detailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  useEffect(() => {
    dispatch(emailTemplatesDetailsRequest("po"));
  }, [dispatch]);

  const getInitialValues = () => {
    let values = {
      recipient: supplierEmail,
      senderName:
        userDetails.data.first_name + " " + userDetails.data.last_name,
      replyto: userDetails.data.email,
      template: "Purchase Order",
      subject: templateDetails ? templateDetails.subject : "",
      content: templateDetails ? templateDetails.content : "",
      isAttachedFile: true,
      file: "",
    };
    return values;
  };

  const closeSuccessDialog = () => {
    setDialog(false);
    closeModal();
  };

  //////////////////////////////////////////////////////////////////////////////
  const sendEmailRequest = async (values) => {
    try {
      setRequestStatus({ ...requestStatus, loading: true });

      const responseData = await generatePurchaseOrderRequest(
        orderInfo.basicInfo.id,
        pdf_config
      );
      if (responseData.status === 200) {
        // Create a Blob from the response data
        const blob = new Blob([responseData.data]);

        const fileName =
          "Purchase_order_" + orderInfo.basicInfo.orderNumber + ".pdf";
        const formData = new FormData();

        formData.append("pdf", blob, fileName);
        formData.append("recipientName", orderInfo.basicInfo.supplier);
        formData.append("recipientAddress", values.recipient);
        formData.append("isAttachedFile", values.isAttachedFile);
        formData.append("replyto", values.replyto);
        formData.append("senderName", values.senderName);
        formData.append("replytoName", values.senderName);
        formData.append("subject", values.subject);
        formData.append("content", values.content);

        setEmailContentValues(values);
        setInitialStatus(false);

        axios
          .post(SERVER_URL + `/purchase_order/send_email`, formData, config)
          .then(async (response) => {
            if (response.data.success) {
              setRequestStatus({
                ...requestStatus,
                loading: false,
                success: true,
              });
              setDialog(true);
            } else {
              throw "Email sent failed.";
            }
          })
          .catch((err) => {
            setRequestStatus({
              ...requestStatus,
              loading: false,
              error: err,
            });
          });
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setRequestStatus({
        ...requestStatus,
        loading: false,
        error: error,
      });
    }
  };

  const EailForm = ({ values, onSubmit }) => {
    const [emailValues, setEmailValues] = useState(values);
    // handle change when input
    const handleChange = (e) => {
      const { name, value } = e.target;
      setEmailValues({ ...emailValues, [name]: value });
    };

    // check input validation
    const checkValidation = () => {
      const hasErrors = validate(emailValues);

      if (Object.keys(hasErrors).length === 0) {
        setFormErrors({});
        onSubmit(emailValues);
      } else {
        setFormErrors(hasErrors);
      }
    };

    const validate = (values) => {
      let re = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

      const errors = {};
      if (!values.recipient) {
        errors.recipient = "Recipient is required!";
      }
      if (!values.subject) {
        errors.subject = "Subject is required!";
      }
      if (!values.content) {
        errors.content = "Content is required!";
      }
      if (!re.test(values.recipient)) {
        // this is a valid email address
        errors.recipient = "Please enter a valid email address";
      }

      return errors;
    };

    return (
      <div>
        {requestStatus.success ? (
          <SuccessDialog
            title="Send Email"
            successMessage="Email sent successfully!"
            isDialogOpen={dialog}
            closeDialog={closeSuccessDialog}
            btnValue="Confirm"
            confirmAction={closeSuccessDialog}
          />
        ) : (
          <form>
            <StyledEmailInput
              label="To"
              type="email"
              name="recipient"
              value={emailValues.recipient}
              onChange={(recipient) =>
                handleChange({
                  target: { value: recipient, name: "recipient" },
                })
              }
              error={formErrors.recipient}
            />
            <ViewText title="From" value={emailValues.senderName} />
            <ViewText title="Reply to" value={emailValues.replyto} />
            <StyledInput
              label="Subject"
              type="text"
              name="subject"
              value={emailValues.subject}
              onChange={(subject) =>
                handleChange({
                  target: { value: subject, name: "subject" },
                })
              }
              error={formErrors.subject}
            />

            <StyledTextarea
              label="Email Message"
              type="text"
              name="notes"
              presetRows="8"
              value={emailValues.content}
              onChange={(content) =>
                handleChange({
                  target: { value: content, name: "content" },
                })
              }
              error={formErrors.content}
            />
            <SingleRatioButton
              label="Include purchase order as attachment"
              name="att"
              isOn={emailValues.isAttachedFile}
              onChange={(isAttachedFile) =>
                handleChange({
                  target: { value: isAttachedFile, name: "isAttachedFile" },
                })
              }
            />
            {requestStatus.error ? (
              <ErrorText mess={requestStatus.error} />
            ) : (
              <></>
            )}
            <div className="w-full mt-4">
              {requestStatus.loading ? (
                <LoadingButton name="Proceeding..." />
              ) : (
                <SimpleBlueButton name="Send" action={checkValidation} />
              )}
            </div>
          </form>
        )}
      </div>
    );
  };

  return (
    <>
      {loading ? (
        <Loader mess="Requesting email template content..." />
      ) : success ? (
        detailsSuccess ? (
          <EailForm
            values={initialStatus ? getInitialValues() : emailContentValues}
            onSubmit={sendEmailRequest}
          />
        ) : error ? (
          <ErrorMessage mess={error} />
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </>
  );
}
