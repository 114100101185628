
class CsvWriter
{
    constructor(headLines = [])
    {
        this.content = headLines.join(",") + "\r\n";
        this.columns = headLines.length;
    }

    addLine(data = [])
    {
        if (!data.length) return;
        if (this.columns !== data.length) {
            //this.set_error("Column size is not equal");
            return;
        }
        
        for (let i = 0; i < data.length; ++i) {
            let v = String(data[i]);
            if (v.includes('"')) {
                this.content += '"' + v.replaceAll('"', '""') + '"';
            } else if (v.includes(',')) {
                this.content += '"' + v + '"';
            } else {
                this.content += v;
            }
            if (i !== data.length - 1) this.content += ',';
        }

        this.content += "\r\n";
    };
    getContent()
    {
        return this.content;
    }
};

export {
    CsvWriter,
}