const K_PRICES = {
  nz: {
    startUp: { annually: 899, monthly: 89 },
    basic: { annually: 2399, monthly: 229 },
    business: { annually: 3199, monthly: 299 },
    enterprise: { annually: 6399, monthly: 599 },
  },
  au: {
    startUp: { annually: 899, monthly: 89 },
    basic: { annually: 2399, monthly: 229 },
    business: { annually: 3199, monthly: 299 },
    enterprise: { annually: 6399, monthly: 599 },
  },
  uk: {
    startUp: { annually: 629, monthly: 59 },
    basic: { annually: 1299, monthly: 129 },
    business: { annually: 1799, monthly: 169 },
    enterprise: { annually: 3499, monthly: 329 },
  },
  us: {
    startUp: { annually: 739, monthly: 69 },
    basic: { annually: 1799, monthly: 169 },
    business: { annually: 1999, monthly: 189 },
    enterprise: { annually: 3699, monthly: 359 },
  },
};

export { K_PRICES };
