export const CREATE_SUPPLIER_OPTIONS_REQUEST = "CREATE_SUPPLIER_OPTIONS_REQUEST";
export const CREATE_SUPPLIER_OPTIONS_SUCCESS = "CREATE_SUPPLIER_OPTIONS_SUCCESS";
export const CREATE_SUPPLIER_OPTIONS_FAIL = "CREATE_SUPPLIER_OPTIONS_FAIL";

export const ADD_SUPPLIER_REQUEST = "ADD_SUPPLIER_REQUEST";
export const ADD_SUPPLIER_SUCCESS = "ADD_SUPPLIER_SUCCESS";
export const ADD_SUPPLIER_FAIL = "ADD_SUPPLIER_FAIL";

export const EDIT_SUPPLIER_REQUEST = "EDIT_SUPPLIER_REQUEST";
export const EDIT_SUPPLIER_SUCCESS = "EDIT_SUPPLIER_SUCCESS";
export const EDIT_SUPPLIER_FAIL = "EDIT_SUPPLIER_FAIL";

export const DELETE_SUPPLIER_REQUEST = "DELETE_SUPPLIER_REQUEST";
export const DELETE_SUPPLIER_SUCCESS = "DELETE_SUPPLIER_SUCCESS";
export const DELETE_SUPPLIER_FAIL = "DELETE_SUPPLIER_FAIL";

export const SUPPLIER_DETAILS_REQUEST = "SUPPLIER_DETAILS_REQUEST";
export const SUPPLIER_DETAILS_SUCCESS = "SUPPLIER_DETAILS_SUCCESS";
export const SUPPLIER_DETAILS_FAIL = "SUPPLIER_DETAILS_FAIL";

export const SUPPLIER_LIST_REQUEST = "SUPPLIER_LIST_REQUEST";
export const SUPPLIER_LIST_SUCCESS = "SUPPLIER_LIST_SUCCESS";
export const SUPPLIER_LIST_FAIL = "SUPPLIER_LIST_FAIL";