import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import commonStyle from "../../../style/commonStyle.module.css";
import {
  SimpleBlueButton,
  XDeleteButton,
} from "../../../components/editComponents";
import {
  Loader,
  ErrorText,
  PriceText,
  VariantTag,
  VariantSkuTag,
} from "../../../components/viewComponents";
import successSound from "../../../sounds/positive_sound.mp3";
import failedSound from "../../../sounds/failed_sound.mp3";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function SaleOrderScanForm({
  locationId,
  priceTierId,
  completeScan,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  //////////////////////////////////////////////////////////////////////////////
  const [focused, setFocused] = useState(true);
  const [products, setProducts] = useState([]);
  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const deleteProductItem = (index) => {
    let productsCopy = [...products];
    productsCopy.splice(index, 1);
    setProducts(productsCopy);
  };

  ///////////////////////////////////////////////////////////////
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };

    // create a new Audio object
    const successAudio = new Audio(successSound);
    const failedAudio = new Audio(failedSound);

    let temporaryBarcode = "";
    let lastTypedTime = Date.now();

    function handleKeyDown(event) {
      if (event.key === "Enter") {
        // 'Enter' key detected, process the barcode
        checkBarcode();
      } else {
        // Accumulate the barcode characters
        temporaryBarcode += event.key;
      }
      lastTypedTime = Date.now();
    }

    function checkBarcode() {
      let currentTime = Date.now();
      let timeSinceLastTyped = currentTime - lastTypedTime;
      if (timeSinceLastTyped < 50) {
        // keep listen to input
        return;
      }
      if (temporaryBarcode.length) {
        setFocused(true);
        try {
          setRequestStatus({ ...requestStatus, loading: true });
          axios
            .get(
              SERVER_URL +
                `/product/search/barcode/${temporaryBarcode}?locationId=${locationId}&priceTierId=${priceTierId}`,
              config
            )
            .then(async (response) => {
              if (response.data.success) {
                setRequestStatus({
                  ...requestStatus,
                  loading: false,
                  success: true,
                  error: "",
                });
                // add products to product array
                let newProduct = response.data.productDetails;
                let unitProductPrice = 0;
                if (newProduct.tierPrice) {
                  unitProductPrice = newProduct.tierPrice;
                } else {
                  unitProductPrice = newProduct.salePrice;
                }

                let productsCopy = [...products];
                // check if product exist
                let exists = productsCopy.some(
                  (item) =>
                    item.barcode === newProduct.barcode &&
                    item.variantCode === newProduct.variant_code
                );
                if (exists) {
                  let index = productsCopy.findIndex(
                    (item) =>
                      item.barcode === newProduct.barcode &&
                      item.variantCode === newProduct.variant_code
                  );
                  productsCopy[index].quantity += 1;
                  productsCopy[index].totalPrice =
                    productsCopy[index].quantity *
                    productsCopy[index].unitPrice;
                } else {
                  productsCopy.push({
                    familyId: newProduct.familyId,
                    productType: newProduct.productType,
                    productName: newProduct.name,
                    sku: newProduct.sku,
                    barcode: newProduct.barcode,
                    variantCode: newProduct.variant_code,
                    variantSku: newProduct.variantSku,
                    variant: newProduct.variant,
                    serialized: newProduct.serialized,
                    batchTracked: newProduct.batchTracked,
                    stockOnHand: newProduct.stockLevel,
                    quantity: 1,
                    unitName: newProduct.unit,
                    unitPrice: unitProductPrice,
                    totalPrice: unitProductPrice * 1,
                  });
                }
                setProducts(productsCopy);
                successAudio.play();
              } else if (response.data.error === 10) {
                setRequestStatus({
                  ...requestStatus,
                  loading: false,
                  error: `Barcode not found`,
                });
                failedAudio.play();
              } else {
                setRequestStatus({
                  ...requestStatus,
                  loading: false,
                  error: "Search barcode error",
                });
                failedAudio.play();
              }
            })
            .catch((err) => {
              setRequestStatus({
                ...requestStatus,
                loading: false,
                error: "Connection error, please try again later",
              });
              failedAudio.play();
            });
        } catch (error) {
          setRequestStatus({
            ...requestStatus,
            loading: false,
            error: "Connection error, please try again later",
          });
          failedAudio.play();
        }

        temporaryBarcode = "";
      }
    }

    const intervalId = setInterval(() => {
      checkBarcode();
    }, 50);

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [locationId, priceTierId, products, requestStatus, userInfo.data.token]);

  // check if user focus on this page
  useEffect(() => {
    const handleBlur = () => {
      setFocused(false);
    };

    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("blur", handleBlur);
    };
  }, []);

  const completeScanAction = () => {
    completeScan(products);
  };

  return (
    <div className={commonStyle.scanWrapper}>
      {requestStatus.loading ? (
        <Loader mess="Request product details..." />
      ) : focused ? (
        <div className={commonStyle.readyScanCon}>Ready to Scan</div>
      ) : (
        <div
          onClick={() => setFocused(true)}
          className={commonStyle.lostFocusScanCon}
        >
          Click to start scan
        </div>
      )}

      {requestStatus.error ? <ErrorText mess={requestStatus.error} /> : <></>}
      {products.length > 0 ? (
        <div className={commonStyle.longDataTable}>
          <table className={commonStyle.pageTable}>
            <thead>
              <tr>
                <th>Code</th>
                <th>Product Name</th>
                <th>Barcode</th>
                <th>Unit</th>
                <th>Unit Price</th>
                <th>Quantity</th>
                <th>Total Price</th>
                <th
                  style={{
                    margin: "auto",
                    borderLeft: "1px solid var(--border-color)",
                  }}
                ></th>
              </tr>
            </thead>
            <tbody>
              {products.map((pro, index) => (
                <tr key={index}>
                  <td>
                    {pro.variantSku && <VariantSkuTag name={pro.variantSku} />}
                  </td>
                  <td>
                    <div>
                      <p>
                        {pro.productName}{" "}
                        {pro.variant ? (
                          <VariantTag name={pro.variant} />
                        ) : (
                          <></>
                        )}
                      </p>
                      <div style={{ marginTop: "4px" }}>
                        {Number(pro.stockOnHand) > 0 ? (
                          <span className={commonStyle.stockTag}>
                            {pro.stockOnHand} stock on Hand
                          </span>
                        ) : (
                          <span className={commonStyle.noStockTag}>
                            No Stock Available
                          </span>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>{pro.barcode}</td>
                  <td>{pro.unitName}</td>
                  <td>
                    {" "}
                    <PriceText num={pro.unitPrice} />
                  </td>
                  <td>{pro.quantity}</td>
                  <td>
                    <PriceText num={pro.totalPrice} />
                  </td>

                  <td
                    style={{
                      borderLeft: "1px solid var(--border-color)",
                      width: "4%",
                    }}
                  >
                    <XDeleteButton action={() => deleteProductItem(index)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <></>
      )}

      {products.length > 0 ? (
        <div className="w-full mt-6">
          <SimpleBlueButton name="Save" action={completeScanAction} />{" "}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
