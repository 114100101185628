import {
  ACTIVATE_ACCOUNT_FAIL,
  ACTIVATE_ACCOUNT_REQUEST,
  ACTIVATE_ACCOUNT_SUCCESS,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  USER_DETAIL_FAIL,
  USER_DETAIL_REQUEST,
  USER_DETAIL_SUCCESS,
} from "../constants/authConstants";


export const activateAccountReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case ACTIVATE_ACCOUNT_REQUEST:
      return { loading: true, success: false };
    case ACTIVATE_ACCOUNT_SUCCESS:
      return {loading: false, success: true};
    case ACTIVATE_ACCOUNT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userLoginReducer = (state = { userInfo: [] }, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { loading: true, success: false };
    case LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload, success: true };
    case LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case LOGOUT_REQUEST:
      return {};
    default:
      return state;
  }
};

export const resetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return { loading: true, success: false };
    case RESET_PASSWORD_SUCCESS:
      return { loading: false, success: true };
    case RESET_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};



export const userDetailReducer = (state = { userDetails: {} }, action) => {
  switch (action.type) {
    case USER_DETAIL_REQUEST:
      return { loading: true, success: false };
    case USER_DETAIL_SUCCESS:
      return { loading: false, userDetails: action.payload, success: true };
    case USER_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
