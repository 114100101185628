import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ScreenTitle,
  SimpleBlueButtonLink,
} from "../../components/editComponents";
import {
  ErrorMessage,
  Loader,
  ViewDateText,
  ViewLabel,
} from "../../components/viewComponents";
import navStyle from "./navStyle.module.css";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import Breadcrumbs from "../../components/Breadcrumbs";
import { subscriptionDetailsRequest } from "../../actions/billingActions";
//import ManageSubscriptionTab from "./ManageSubscriptionTab";

export default function ManageSubscriptionScreen() {
  useDocumentTitle("Manage Subscription");

  const { loading, subscription, error, success } = useSelector(
    (state) => state.subscriptionDetails
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(subscriptionDetailsRequest());
  }, [dispatch]);

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Manage Subscription" />
      <ScreenTitle title="Manage Subscription" />

      <div className={commonStyle.tabsContainer}>
        {/* <ManageSubscriptionTab tabName="plan" /> */}

        <div className={commonStyle.settingsContent}>
          {loading ? (
            <Loader mess="Requesting subscription details, please wait a momont..." />
          ) : success ? (
            <div className={navStyle.subscripitionCon}>
              <ViewLabel title="Plan Name" />
              <h2 className={navStyle.planTitle}>
                {subscription.plan_name === "trial"
                  ? "Trial"
                  : subscription.plan_name}
              </h2>
              {subscription.plan_name === "trial" ? (
                <></>
              ) : (
                <>
                  <ViewLabel title="Users" />
                  <p>
                    <span className={navStyle.userNum}>
                    {subscription.plan_name === "start-up"
                      ? "1 user included"
                      : subscription.plan_name === "start-up"
                      ? "3 users included"
                      : subscription.plan_name === "start-up"
                      ? "5 users included"
                      : subscription.plan_name === "start-up"
                      ? "12 users included"
                      : ""}
                    </span>
                 
                  </p>

                  {subscription.extra_users > 0 ? (
                    <>
                      <ViewLabel title="Extra Users" />
                      <h2 className={navStyle.planTitle}>
                        {subscription.extra_users}
                      </h2>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}

              {subscription.plan_name === "trial" ? (
                <>
                  <ViewLabel title="Trial plan expire date" />
                  <ViewDateText
                    value={subscription.end_time}
                  />
                </>
              ) : (
                <>
                  <ViewLabel title="Next Renewal Date" />
                  <ViewDateText
                    value={subscription.end_time}
                  />
                </>
              )}

              {subscription.plan_name === "trial" ? (
                <div style={{ width: "fit-content" }}>
                  <SimpleBlueButtonLink name="Subscribe Now" path="/billing" />
                </div>
              ) : (
                <div style={{ width: "fit-content" }}>
                  {/* <SimpleBlueButtonLink
                    name="Manage Subscription"
                    path="/billing"
                  /> */}
                </div>
              )}
            </div>
          ) : error ? (
            <ErrorMessage mess={error} />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
