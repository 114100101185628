import { PRICE_TIER_LIST_REQUEST, PRICE_TIER_LIST_FAIL, PRICE_TIER_LIST_SUCCESS, NEW_PRICE_TIER_REQUEST, NEW_PRICE_TIER_SUCCESS, NEW_PRICE_TIER_FAIL, UPDATE_PRICE_TIER_REQUEST, UPDATE_PRICE_TIER_SUCCESS, UPDATE_PRICE_TIER_FAIL, DELETE_PRICE_TIER_REQUEST, DELETE_PRICE_TIER_SUCCESS, DELETE_PRICE_TIER_FAIL, PRODUCT_VARIANT_DETAIL_REQUEST,
  PRODUCT_VARIANT_DETAIL_SUCCESS,
  PRODUCT_VARIANT_DETAIL_FAIL, 
  UPDATE_PRODUCT_CUSTOM_PRICE_REQUEST,
  UPDATE_PRODUCT_CUSTOM_PRICE_SUCCESS,
  UPDATE_PRODUCT_CUSTOM_PRICE_FAIL} from "../constants/customPriceConstants";

export const priceTierListReducer = (state = { priceTierList: [] }, action) => {
    switch (action.type) {
      case PRICE_TIER_LIST_REQUEST:
        return { loading: true, success:false };
      case PRICE_TIER_LIST_SUCCESS:
        return { loading: false, priceTierList: action.payload, success:true };
      case PRICE_TIER_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
};


export const newPriceTierReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_PRICE_TIER_REQUEST:
      return { loading: true , success:false};
    case NEW_PRICE_TIER_SUCCESS:
      return { loading: false, success:true };
    case NEW_PRICE_TIER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updatePriceTierReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PRICE_TIER_REQUEST:
      return { loading: true , success:false};
    case UPDATE_PRICE_TIER_SUCCESS:
      return { loading: false, success:true };
    case UPDATE_PRICE_TIER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deletePriceTierReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PRICE_TIER_REQUEST:
      return { loading: true , success:false};
    case DELETE_PRICE_TIER_SUCCESS:
      return { loading: false, success:true };
    case DELETE_PRICE_TIER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const productVariantDetailsReducer = (state = { productVariantPrice: [] }, action) => {
  switch (action.type) {
    case PRODUCT_VARIANT_DETAIL_REQUEST:
      return { loading: true , success:false};
    case PRODUCT_VARIANT_DETAIL_SUCCESS:
      return { loading: false, productVariantPrice: action.payload, success:true };
    case PRODUCT_VARIANT_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateProductCustomPriceReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PRODUCT_CUSTOM_PRICE_REQUEST:
      return { loading: true , success:false};
    case UPDATE_PRODUCT_CUSTOM_PRICE_SUCCESS:
      return { loading: false, success:true };
    case UPDATE_PRODUCT_CUSTOM_PRICE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
