import axios from "axios";
import {
  BULK_STOCK_TRANSFER_FAIL,
  BULK_STOCK_TRANSFER_REQUEST,
  BULK_STOCK_TRANSFER_SUCCESS,
  CREATE_NEW_PRODUCT_FAIL,
  CREATE_NEW_PRODUCT_REQUEST,
  CREATE_NEW_PRODUCT_SUCCESS,
  DELETE_CATEGORY_FAIL,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  DELETE_PRODUCT_FAIL,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_UNIT_FAIL,
  DELETE_UNIT_REQUEST,
  DELETE_UNIT_SUCCESS,
  DIRECTLY_RECEIVE_STOCK_FAIL,
  DIRECTLY_RECEIVE_STOCK_REQUEST,
  DIRECTLY_RECEIVE_STOCK_SUCCESS,
  EDIT_PRODUCT_FAIL,
  EDIT_PRODUCT_REQUEST,
  EDIT_PRODUCT_SUCCESS,
  NEW_CATEGORY_FAIL,
  NEW_CATEGORY_REQUEST,
  NEW_CATEGORY_SUCCESS,
  NEW_EDIT_PRODUCT_PACKAGE_FAIL,
  NEW_EDIT_PRODUCT_PACKAGE_REQUEST,
  NEW_EDIT_PRODUCT_PACKAGE_SUCCESS,
  NEW_UNIT_FAIL,
  NEW_UNIT_REQUEST,
  NEW_UNIT_SUCCESS,
  PRODUCT_CATEGORY_LIST_FAIL,
  PRODUCT_CATEGORY_LIST_REQUEST,
  PRODUCT_CATEGORY_LIST_SUCCESS,
  PRODUCT_DETAIL_FAIL,
  PRODUCT_DETAIL_REQUEST,
  PRODUCT_DETAIL_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_OPTIONS_FAIL,
  PRODUCT_OPTIONS_REQUEST,
  PRODUCT_OPTIONS_SUCCESS,
  PRODUCT_PACKAGE_DETAILS_FAIL,
  PRODUCT_PACKAGE_DETAILS_REQUEST,
  PRODUCT_PACKAGE_DETAILS_SUCCESS,
  PRODUCT_PACKAGE_LIST_FAIL,
  PRODUCT_PACKAGE_LIST_REQUEST,
  PRODUCT_PACKAGE_LIST_SUCCESS,
  PRODUCT_UNIT_LIST_FAIL,
  PRODUCT_UNIT_LIST_REQUEST,
  PRODUCT_UNIT_LIST_SUCCESS,
  SERIAL_BATCH_LIST_FAIL,
  SERIAL_BATCH_LIST_REQUEST,
  SERIAL_BATCH_LIST_SUCCESS,
  STOCKTAKE_LIST_FAIL,
  STOCKTAKE_LIST_REQUEST,
  STOCKTAKE_LIST_SUCCESS,
  STOCK_ADJUST_DETAILS_FAIL,
  STOCK_ADJUST_DETAILS_REQUEST,
  STOCK_ADJUST_DETAILS_SUCCESS,
  STOCK_ADJUST_LIST_FAIL,
  STOCK_ADJUST_LIST_REQUEST,
  STOCK_ADJUST_LIST_SUCCESS,
  STOCK_TRANSFER_DETAIL_FAIL,
  STOCK_TRANSFER_DETAIL_REQUEST,
  STOCK_TRANSFER_DETAIL_SUCCESS,
  STOCK_TRANSFER_LIST_FAIL,
  STOCK_TRANSFER_LIST_REQUEST,
  STOCK_TRANSFER_LIST_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_UNIT_FAIL,
  UPDATE_UNIT_REQUEST,
  UPDATE_UNIT_SUCCESS
} from "../constants/productConstants";
import { authMiddleware } from "./authMiddleware";


const SERVER_URL = process.env.REACT_APP_SERVER_URL;

// add a new product
export const createNewProductRequest =
  (values) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      dispatch({ type: CREATE_NEW_PRODUCT_REQUEST });
      axios
        .post(SERVER_URL + `/product/create`, values, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: CREATE_NEW_PRODUCT_SUCCESS });
            dispatch(productListRequest());
          } else {
            throw Object.assign(
              new Error(response.data.message),
              { code: 102 }
           );
          }
        })
        .catch((err) => {
          dispatch({
            type: CREATE_NEW_PRODUCT_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: CREATE_NEW_PRODUCT_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

export const editProductRequest =
  (values, id) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
     
      dispatch({ type: EDIT_PRODUCT_REQUEST });
      axios
        .put(SERVER_URL + `/product/family/${id}`, values, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: EDIT_PRODUCT_SUCCESS });
          } else {
            throw Object.assign(
              new Error(response.data.message),
              { code: 102 }
           );
          }
        })
        .catch((err) => {
          dispatch({
            type: EDIT_PRODUCT_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: EDIT_PRODUCT_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

export const productOptionsRequest = () => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
   

    dispatch({ type: PRODUCT_OPTIONS_REQUEST });
    axios
      .get(SERVER_URL + `/product/options`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({
            type: PRODUCT_OPTIONS_SUCCESS,
            payload: response.data.data,
          });
        } else {
          throw Object.assign(
            new Error("Request product category list failed, please try again later."),
            { code: 104 }
         );
        }
      })
      .catch((err) => {
        dispatch({
          type: PRODUCT_OPTIONS_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: PRODUCT_OPTIONS_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

// get product list
export const productListRequest =
  (pageNum = 0, pageSize = 5, keywords = '', category = '', location = '', supplier = '', status = '') => async (dispatch, getState) => {

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      

      dispatch({ type: PRODUCT_LIST_REQUEST });

      axios
        .get(
          SERVER_URL +
            `/product/list?page_num=${pageNum}&page_size=${pageSize}&keywords=${keywords}&category=${category}&location=${location}&supplier=${supplier}&status=${status}`,
          config
        )
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            let total_pages = response.data.data.total_pages;
            let productData = [];

            response.data.data.products.forEach((x) => {
              let product = {
                id: x.famid,
                type: x.type,
                sku: x.sku,
                name: x.name,
                category: x.cate,
                locations: x.locations.length ? x.locations.split("||") : [],
                locationStockLevel: x.locationStockLevel ? x.locationStockLevel : {},
                unitName: x.unitName,
                price: x.price,
                latestCost: x.latestCost,
                variantSku: x.variantSku,
                image: x.image,
                stock: x.stock,
                status: x.status,
              };

              productData.push(product);
            });

            dispatch({
              type: PRODUCT_LIST_SUCCESS,
              payload: { products: productData, totalPages: total_pages },
            });
          } else {
            throw Object.assign(
              new Error("Request product list failed, please try again late."),
              { code: 105 }
           );
          }
        })
        .catch((err) => {
          dispatch({
            type: PRODUCT_LIST_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: PRODUCT_LIST_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

// get product details
export const productDetailsRequest =
  (id, requestType) => async (dispatch, getState) => {

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      

      dispatch({ type: PRODUCT_DETAIL_REQUEST });

      axios
        .get(SERVER_URL + `/product/family/${id}`, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            let attributeNames = []; // e.g. [size, color, ...]
            let detail = response.data.data; // abbr
            if (detail.basicInfo.attributes.length > 0) {
              for (let i of detail.basicInfo.attributes) {
                attributeNames.push(i.name);
              }
            }

            let attr = []; // stores the converted format for the FE 
            if (attributeNames.length > 0) { // has attributes
              // find which options has stock (not allow delete)
              let optionStocks = {};  // attribute.option=>has stock, e.g.: {size.large: true, color.red: false, ...}
              for (let stockItem of detail.variations) {
            
                // don't care if it has no stock.
                  if (! ('stockLevel' in stockItem)) continue;
                  if (Number(stockItem.stockLevel) === 0) continue; 
              

                for (let attribute of attributeNames) {
                  if (attribute in stockItem) {
                    optionStocks[`${attribute}.${stockItem[attribute]}`] = true; // marked as having stock
                  }
                }
              }

              // formatting
              for (let item of detail.basicInfo.attributes) {
                let attribute = item.name;
                let res = [];
                for (let option of item.opts) {
                  res.push({
                    optionName: option,
                    optionStock: optionStocks[`${attribute}.${option}`] === true, //  whether has stock
                  });
                }
                attr.push({
                  attrName: attribute,
                  attrOptions: res
                });
              }
            }

            let locationValues = [];
            if ( response.data.data.basicInfo.status === "Active" && requestType === "edit") {
              response.data.data.basicInfo.locations.forEach((x) => {
                x.isFixed = true;
                locationValues.push(x);
              });
            } else {
              locationValues = response.data.data.basicInfo.locations;
            }
          
            let productBasicInfo = {
              productName: response.data.data.basicInfo.name,
              type: response.data.data.basicInfo.type,
              sku: response.data.data.basicInfo.sku,
              unitId: response.data.data.basicInfo.unitId,
              unitName: response.data.data.basicInfo.unitName,
              categoryId: response.data.data.basicInfo.categoryId,
              categoryName: response.data.data.basicInfo.categoryName,
              reference: response.data.data.basicInfo.reference,
              description: response.data.data.basicInfo.description,
              location: locationValues,
              saleTaxId: response.data.data.basicInfo.saleTaxId,
              saleTax: response.data.data.basicInfo.taxrateName,
              supplierId: response.data.data.basicInfo.supplierId,
              supplierName: response.data.data.basicInfo.supplierName,
              image: response.data.data.basicInfo.image,
              hasStockOnHand: response.data.data.basicInfo.hasStockOnHand,
              hasBatches: response.data.data.basicInfo.hasBatches,
              serialized: Number(response.data.data.basicInfo.serialized) === 1 ? true : false,
              batchTracked:  Number(response.data.data.basicInfo.batchTracked) === 1 ? true : false,
              hasAttrs: attributeNames.length > 0 ? true : false,
              attrs: attr,
              attributeNames: attributeNames,  //display the table head when preview product
              managed: Number(response.data.data.basicInfo.managed) === 1 ? true : false,
              syncStatus: response.data.data.basicInfo.sync_stock_status,
              syncStockId: response.data.data.basicInfo.sync_stock_id,
             
            };
  
            // Note: in the new product form it doesn't need a variant in each location,
            //       hence group by the variant here.
            let variations = response.data.data.variations;

            // convert number

            for (const item of variations){
              item.latestCost = Number(item.latestCost);
              item.reorderLevel = Number(item.reorderLevel)
              item.salePrice = Number(item.salePrice)
              item.stockLevel = Number(item.stockLevel)

            }

            let uniq_var_codes = new Set();
            let only_variants = (variations).filter((elem, idx, _) => {
              if (uniq_var_codes.has(elem._variantCode)) {
                return false;
              }
              uniq_var_codes.add(elem._variantCode);
              return true;
            });

            dispatch({
              type: PRODUCT_DETAIL_SUCCESS,
              payload: {
                basicInfo: productBasicInfo,
                attrsDetails: requestType === "preview" ? variations : only_variants,
              },
            });
          } else {
            throw Object.assign(
              new Error("Request product details failed, please try again later."),
              { code: 106 }
           );
          }
        })
        .catch((err) => {
          dispatch({
            type: PRODUCT_DETAIL_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: PRODUCT_DETAIL_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };



//////////////////////////// product categories///////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const productCategoriesRequest = (requestType) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
   

    dispatch({ type: PRODUCT_CATEGORY_LIST_REQUEST });
    axios
      .get(SERVER_URL + `/product/category/list`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          let list = response.data.data;
          let returnData = []
          if(requestType === "selectOptions"){
            list.forEach((elem) => {
              returnData.push({
                id: elem.id,
                label: elem.name,
                value: elem.name
              })
            })
          }else {
            returnData = list
          }
          dispatch({
            type: PRODUCT_CATEGORY_LIST_SUCCESS,
            payload: returnData,
          });
        } else {
          throw Object.assign(
            new Error("Request product category list failed, please try again later."),
            { code: 107 }
         );
        }
      })
      .catch((err) => {
        dispatch({
          type: PRODUCT_CATEGORY_LIST_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: PRODUCT_CATEGORY_LIST_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

// add a new category
export const newCategoryRequest = (values) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    

    dispatch({ type: NEW_CATEGORY_REQUEST });
    axios
      .post(SERVER_URL + `/product/category/create`, values, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: NEW_CATEGORY_SUCCESS });
          dispatch(productCategoriesRequest());
        } else {
          if (response.data.error === 11) {
            throw Object.assign(
              new Error("Category name exists, please use a new name"),
              { code: 108 }
           );
          } else {
            throw Object.assign(
              new Error("Create new category failed, please try again later"),
              { code: 109 }
           );
          }
        }
      })
      .catch((err) => {
        dispatch({
          type: NEW_CATEGORY_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: NEW_CATEGORY_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const updateCategoryRequest =
  (values, id) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      

      dispatch({ type: UPDATE_CATEGORY_REQUEST });
      axios
        .put(SERVER_URL + `/product/category/${id}`, values, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: UPDATE_CATEGORY_SUCCESS });
            dispatch(productCategoriesRequest());
          } else {
            if (response.data.error === 11) {
              throw Object.assign(
                new Error("Category name exists, please use a new name"),
                { code: 108 }
             );
            } else {
              throw Object.assign(
                new Error("Update category failed, please use a new name"),
                { code: 110 }
             );
            }
          }
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_CATEGORY_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: UPDATE_CATEGORY_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

export const deleteCategoryRequest = (id) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    

    dispatch({ type: DELETE_CATEGORY_REQUEST });
    axios
      .delete(SERVER_URL + `/product/category/${id}`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: DELETE_CATEGORY_SUCCESS });
          dispatch(productCategoriesRequest());
        } else {
          throw Object.assign(
            new Error("Delete category failed, please use a new name"),
            { code: 111 }
         );
        }
      })
      .catch((err) => {
        dispatch({
          type: DELETE_CATEGORY_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: DELETE_CATEGORY_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};


//// stock adjust

  export const bulkStockAdjustRequest = (values) => async (dispatch, getState) => {

    const {
      userLogin: { userInfo },
    } = getState();
  
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      
      dispatch({ type: DIRECTLY_RECEIVE_STOCK_REQUEST });
      axios
        .post(SERVER_URL + `/product/bulk_stock_adjustment`, values, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: DIRECTLY_RECEIVE_STOCK_SUCCESS });
           
          } else if (response.data.error === 30) {
            throw Object.assign(
              new Error("There is no enough stock to deduct"),
              { code: 110232 }
           );
          }else {
            throw Object.assign(
              new Error("Create stock adjustment failed, please try again later"),
              { code: 107 }
           );
          }
        })
        .catch((err) => {
          //console.log(err);
          dispatch({
            type: DIRECTLY_RECEIVE_STOCK_FAIL,
            payload: err,
          });
        });

    } catch (error) {
      dispatch({
        type: DIRECTLY_RECEIVE_STOCK_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

  export const bulkStockTransferRequest = (values) => async (dispatch, getState) => {

    const {
      userLogin: { userInfo },
    } = getState();
  
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      
      dispatch({ type: BULK_STOCK_TRANSFER_REQUEST });
      axios
        .post(SERVER_URL + `/product/bulk_stock_transfer`, values, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: BULK_STOCK_TRANSFER_SUCCESS });
          }else {
            throw Object.assign(
              new Error(response.data.message),
              { code: 107 }
           );
          }
        })
        .catch((err) => {
          //console.log(err);
          dispatch({
            type: BULK_STOCK_TRANSFER_FAIL,
            payload: err,
          });
        });

    } catch (error) {
      dispatch({
        type: BULK_STOCK_TRANSFER_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

  export const stockTransfertListRequest =
  (pageNum = 0, pageSize = 5,) => async (dispatch, getState) => {

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      

      dispatch({ type: STOCK_TRANSFER_LIST_REQUEST });

      axios
        .get(
          SERVER_URL +
            `/product/stock_transfer_list?page_num=${pageNum}&page_size=${pageSize}`,
          config
        )
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({
              type: STOCK_TRANSFER_LIST_SUCCESS,
              payload: response.data.data,
            });
          } else {
            throw Object.assign(
              new Error(response.data.message),
              { code: 105 }
           );
          }
        })
        .catch((err) => {
          dispatch({
            type: STOCK_TRANSFER_LIST_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: STOCK_TRANSFER_LIST_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

  export const stockTransferDetailsRequest =
  (adjust_id) => async (dispatch, getState) => {

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      

      dispatch({ type: STOCK_TRANSFER_DETAIL_REQUEST });

      axios
        .get(
          SERVER_URL +
            `/product/stock_transfer/details/${adjust_id}`,
          config
        )
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {

            let details = response.data.data;
            let orderItems = details.orderItems;
            for(const item of orderItems){
              item.unitCost = Number(item.unitCost);
              item.quantity = Number(item.quantity);
              item.totalCost = Number(item.totalCost);
            }
            dispatch({
              type: STOCK_TRANSFER_DETAIL_SUCCESS,
              payload: response.data.data,
            });
          } else {
            throw Object.assign(
              new Error(response.data.message),
              { code: 105 }
           );
          }
        })
        .catch((err) => {
          dispatch({
            type: STOCK_TRANSFER_DETAIL_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: STOCK_TRANSFER_DETAIL_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

  export const stockAdjustListRequest =
  (pageNum = 0, pageSize = 5,) => async (dispatch, getState) => {

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
     

      dispatch({ type: STOCK_ADJUST_LIST_REQUEST });

      axios
        .get(
          SERVER_URL +
            `/product/stock_adjustment_list?page_num=${pageNum}&page_size=${pageSize}`,
          config
        )
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({
              type: STOCK_ADJUST_LIST_SUCCESS,
              payload: response.data.data,
            });
          } else {
            throw Object.assign(
              new Error("Request stock adjustment list failed, please try again later."),
              { code: 105 }
           );
          }
        })
        .catch((err) => {
          dispatch({
            type: STOCK_ADJUST_LIST_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: STOCK_ADJUST_LIST_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

  export const stockAdjustDetailsRequest =
  (adjust_id) => async (dispatch, getState) => {

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      

      dispatch({ type: STOCK_ADJUST_DETAILS_REQUEST });

      axios
        .get(
          SERVER_URL +
            `/product/stock_adjustment/details/${adjust_id}`,
          config
        )
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {

            let details = response.data.data;
            let orderItems = details.orderItems;
            for(const item of orderItems){
              item.unitCost = Number(item.unitCost);
              item.quantity = Number(item.quantity);
              item.totalCost = Number(item.totalCost);
            }
            dispatch({
              type: STOCK_ADJUST_DETAILS_SUCCESS,
              payload: response.data.data,
            });
          } else {
            throw Object.assign(
              new Error("Request stock adjustment details failed, please try again later."),
              { code: 105 }
           );
          }
        })
        .catch((err) => {
          dispatch({
            type: STOCK_ADJUST_DETAILS_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: STOCK_ADJUST_DETAILS_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };




// get stocktake list
export const stocktakeListRequest =
  (pageNum = 0, pageSize = 5, category = '', location = '') => async (dispatch, getState) => {

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
     

      dispatch({ type: STOCKTAKE_LIST_REQUEST });

      axios
        .get(
          SERVER_URL +
            `/reporting/stocktake-list?page_num=${pageNum}&page_size=${pageSize}&category=${category}&location=${location}`,
          config
        )
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({
              type: STOCKTAKE_LIST_SUCCESS,
              payload: response.data.data,
            });
          } else {
            throw Object.assign(
              new Error("Request stocktake list failed, please try again later."),
              { code: 105 }
           );
          }
        })
        .catch((err) => {
          dispatch({
            type: STOCKTAKE_LIST_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: STOCKTAKE_LIST_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

  // add a new category
export const updateProductPackageRequest = (values) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    

    dispatch({ type: NEW_EDIT_PRODUCT_PACKAGE_REQUEST });
    axios
      .post(SERVER_URL + `/product/product-package/update`, values, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: NEW_EDIT_PRODUCT_PACKAGE_SUCCESS });
          //dispatch(productCategoriesRequest());
        } else {
          if (response.data.error === 100) {
            throw Object.assign(
              new Error("Package name exists, please use a new name"),
              { code: 2108 }
           );
          } else {
            throw Object.assign(
              new Error("Unable to save product package information at the moment. Please try again later."),
              { code: 2109 }
           );
          }
        }
      })
      .catch((err) => {
        dispatch({
          type: NEW_EDIT_PRODUCT_PACKAGE_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: NEW_EDIT_PRODUCT_PACKAGE_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const productPackageListRequest =
(pageNum = 0, pageSize = 5,) => async (dispatch, getState) => {

  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    

    dispatch({ type: PRODUCT_PACKAGE_LIST_REQUEST });

    axios
      .get(
        SERVER_URL +
          `/product/product-package/list?page_num=${pageNum}&page_size=${pageSize}`,
        config
      )
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({
            type: PRODUCT_PACKAGE_LIST_SUCCESS,
            payload: response.data.data,
          });
        } else {
          throw Object.assign(
            new Error("Request product package list failed, please try again later."),
            { code: 105 }
         );
        }
      })
      .catch((err) => {
        dispatch({
          type: PRODUCT_PACKAGE_LIST_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: PRODUCT_PACKAGE_LIST_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const productPackageDetailsRequest =
(package_id) => async (dispatch, getState) => {

  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    

    dispatch({ type: PRODUCT_PACKAGE_DETAILS_REQUEST });

    axios
      .get(
        SERVER_URL +
          `/product/product-package/details/${package_id}`,
        config
      )
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          let packageInfo = response.data.data;
          let packageProducts = packageInfo.packageProducts;
          for (const item of packageProducts) {
            item.quantity = Number(item.quantity);
          }
          dispatch({
            type: PRODUCT_PACKAGE_DETAILS_SUCCESS,
            payload: packageInfo,
          });
        } else {
          throw Object.assign(
            new Error("Request product package details failed, please try again later."),
            { code: 105 }
         );
        }
      })
      .catch((err) => {
        dispatch({
          type: PRODUCT_PACKAGE_DETAILS_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: PRODUCT_PACKAGE_DETAILS_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const serialBatchNumbersRequest = (famid) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    

    dispatch({ type: SERIAL_BATCH_LIST_REQUEST });
    axios
      .get(SERVER_URL + `/serial_batch/all_location_list/${famid}`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {

          dispatch({
            type: SERIAL_BATCH_LIST_SUCCESS,
            payload: response.data.data,
          });
        } else {
          throw Object.assign(
            new Error("Request product serial/batch numbers failed, please try again later."),
            { code: 112 }
         );
        }
      })
      .catch((err) => {
        dispatch({
          type: SERIAL_BATCH_LIST_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: SERIAL_BATCH_LIST_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

//////////////////////////// product units///////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const productUnitsRequest = () => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
   

    dispatch({ type: PRODUCT_UNIT_LIST_REQUEST });
    axios
      .get(SERVER_URL + `/product/unit/list`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({
            type: PRODUCT_UNIT_LIST_SUCCESS,
            payload: response.data.data
          });
        } else {
          throw Object.assign(
            new Error(response.data.message),
            { code: 107 }
         );
        }
      })
      .catch((err) => {
        dispatch({
          type: PRODUCT_UNIT_LIST_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: PRODUCT_UNIT_LIST_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

// add a new category
export const newUnitRequest = (values) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
   

    dispatch({ type: NEW_UNIT_REQUEST });
    axios
      .post(SERVER_URL + `/product/unit/create`, values, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: NEW_UNIT_SUCCESS });
          dispatch(productUnitsRequest());
        } else {
          throw Object.assign(
            new Error(response.data.message),
            { code: 107 }
         );
        }
      })
      .catch((err) => {
        dispatch({
          type: NEW_UNIT_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: NEW_UNIT_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const updateUnitRequest =
  (values, id) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      

      dispatch({ type: UPDATE_UNIT_REQUEST });
      axios
        .put(SERVER_URL + `/product/unit/${id}`, values, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: UPDATE_UNIT_SUCCESS });
            dispatch(productUnitsRequest());
          } else {
            throw Object.assign(
              new Error(response.data.message),
              { code: 107 }
           );
          }
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_UNIT_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: UPDATE_UNIT_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

export const deleteUnitRequest = (id) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    

    dispatch({ type: DELETE_UNIT_REQUEST });
    axios
      .delete(SERVER_URL + `/product/unit/${id}`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: DELETE_UNIT_SUCCESS });
          dispatch(productUnitsRequest());
        } else {
          throw Object.assign(
            new Error(response.data.message),
            { code: 107 }
         );
        }
      })
      .catch((err) => {
        dispatch({
          type: DELETE_UNIT_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: DELETE_UNIT_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const deleteProductRequest = (id) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    

    dispatch({ type: DELETE_PRODUCT_REQUEST });
    axios
      .post(SERVER_URL + `/product/delete/${id}`, "", config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: DELETE_PRODUCT_SUCCESS });
        } else {
          throw Object.assign(
            new Error(response.data.message),
            { code: 107 }
         );
        }
      })
      .catch((err) => {
        dispatch({
          type: DELETE_PRODUCT_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: DELETE_PRODUCT_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};
