import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Breadcrumbs from "../../components/Breadcrumbs";
import {
  ScreenTitle,
  DownloadButton,
  RefreshButton,
} from "../../components/editComponents";
import IOTab from "./IOTab";
import commonStyle from "../../style/commonStyle.module.css";
import ioStyle from "./ioStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import {
  ErrorMessage,
  Loader,
  Tag,
  ViewDateWithTime,
  SimpleDialog,
} from "../../components/viewComponents";
import { FaFileCsv } from "react-icons/fa";
import {
  importExportLogsRequest,
  exportCsvRequest,
} from "../../actions/IOActions";

export default function ExportScreen({ type }) {
  useDocumentTitle("Export");
  const dispatch = useDispatch();
  ////////////////////////////////////////////////////////////////////////////////////////////

  const [exportDialog, setExportDialog] = useState(false);

  const {
    loading: logsLoading,
    logsList,
    error: logsError,
    success: logsSuccess,
  } = useSelector((state) => state.IOLogs);

  const { loading, success, error } = useSelector((state) => state.exportCsv);

  ////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (type) {
      dispatch(importExportLogsRequest(type, "EXPORT"));
    }
  }, [dispatch, type, success]);
  ////////////////////////////////////////////////////////////////////////////////////////////
  const createExportCsvTask = () => {
    setExportDialog(true);
    dispatch(exportCsvRequest(type));
  };

  const refreshData = () => {
    dispatch(importExportLogsRequest(type, "EXPORT"));
  };

  return (
    <div className={commonStyle.pageContainer}>
      {type === "product" ? (
        <Breadcrumbs screenName={"Export Products"} />
      ) : type === "supplier" ? (
        <Breadcrumbs screenName={"Export Suppliers"} />
      ) : type === "customer" ? (
        <Breadcrumbs screenName={"Export Customers"} />
      ) : (
        <></>
      )}
      {type === "product" ? (
        <ScreenTitle title={"Export Products"} />
      ) : type === "supplier" ? (
        <ScreenTitle title={"Export Suppliers"} />
      ) : type === "customer" ? (
        <ScreenTitle title={"Export Customers"} />
      ) : (
        <></>
      )}

      <SimpleDialog
        title="Export"
        successMessage="Export task added successfully."
        isDialogOpen={exportDialog}
        closeDialog={() => setExportDialog(false)}
        loading={loading}
        loadingMessage="Processing request..."
        success={success}
        error={error}
        confirmAction={() => setExportDialog(false)}
      />

      <div className={commonStyle.tabsContainer}>
        <IOTab tabName="export" type={type} />

        <div className={commonStyle.settingsContent}>
          <div className={ioStyle.contentWrapper}>
            <h2>Export</h2>
            <div className={ioStyle.importTips}>
              <p>
                Click below button to create a new export{" "}
                <span>
                  {type === "product"
                    ? "all products"
                    : type === "supplier"
                    ? "all suppliers"
                    : type === "customer"
                    ? "all customers"
                    : ""}
                </span>{" "}
                task, once the task completed, you can download csv file in
                below table. .
              </p>
            </div>
            <DownloadButton
              name="Create New Export Task"
              action={createExportCsvTask}
            />
            <div style={{ marginTop: "40px",display: "flex", justifyContent: "space-between" }}>
              <h2>Export Tasks</h2>
              <RefreshButton name="Refresh Task Status" action={refreshData} />
            </div>

            {logsLoading ? (
              <Loader mess="Requesting data, please wait a moment..." />
            ) : logsSuccess ? (
              <table className={commonStyle.pageTable}>
                <thead>
                  <tr>
                    {/* <th></th> */}
                    <th>Date</th>
                    <th>Operator</th>
                    <th>Status</th>
                    <th>Result</th>
                  </tr>
                </thead>
                <tbody>
                  {logsList.length > 0 ? (
                    logsList.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <ViewDateWithTime value={item.atime} />
                        </td>

                        <td>
                          {item.operatorFirstName} {item.operatorLastName}{" "}
                        </td>
                        <td>
                          {item.status === "QUEUED" ? (
                            <Tag name={item.status} color="yellow" />
                          ) : item.status === "PROCESSING" ? (
                            <Tag name={item.status} color="green" />
                          ) : item.status === "SUCCESS" ? (
                            <Tag name={item.status} color="blue" />
                          ) : item.status === "FAILED" ? (
                            <Tag name={item.status} color="red" />
                          ) : (
                            <></>
                          )}
                        </td>
                        <td>
                          {item.status === "SUCCESS" ? (
                            <a
                              href={item.outputFileUrl}
                              download={item.outputFileUrl}
                              rel="noreferrer"
                              className={ioStyle.downloadBtnCon}
                            >
                              <FaFileCsv className={ioStyle.downloadBtnLogo} />
                              <span>Download Csv</span>
                            </a>
                          ) : item.status === "FAILED" ? (
                            item.errors
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>No export records.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            ) : logsError ? (
              <ErrorMessage mess={logsError} />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
