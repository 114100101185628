import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import commonStyle from "../../../style/commonStyle.module.css";
import CompanySettingsTab from "./CompanySettingsTab";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  organizationDetailsRequest,
  organizationUpdateDetailsRequest,
} from "../../../actions/orgSettingsActions";
import OrganizationForm from "./OrganizationForm";
import {
  ErrorMessage,
  Loader,
  SimpleDialog,
} from "../../../components/viewComponents";
import { K_COUNTRIES } from "../../../constantsData/countries";
import { K_INDUSTRYS } from "../../../constantsData/industries";
import { K_DATEFORMAT } from "../../../constantsData/dateFormats";
import { K_TIMEZONES } from "../../../constantsData/timeZones";
import useDocumentTitle from "../../../useDocumentTitle";

export default function CompanySettings() {
  useDocumentTitle("Company Setting");
  const dispatch = useDispatch();

  const [updateDialog, setUpdateDialog] = useState(false);

  const { loading, orgInfo, error, success } = useSelector(
    (state) => state.orgDetails
  );

  const {
    loading: upadteLoading, 
    error: updateError,
    success: updateSuccess,
  } = useSelector((state) => state.orgUpdate);

  const formatCompanyInfo = (values) => {
    let companyData = {
      id: values.id,
      name: values.name ? values.name : "",
      country: values.country
        ? K_COUNTRIES.filter((x) => x.value === values.country)[0]
        : "",
      baseCurrency: values.baseCurrency ? values.baseCurrency : "",
      timeZone: values.timeZone
        ? K_TIMEZONES.filter((x) => x.value === values.timeZone)[0]
        : "",
      dateFormat: values.dateFormat
        ? K_DATEFORMAT.filter((x) => x.value === values.dateFormat)[0]
        : "",
      industry: values.industry
        ? K_INDUSTRYS.filter((x) => x.value === values.industry)[0]
        : "",
      logo: values.logo ? values.logo : "",
    };

    return companyData;
  };

  useEffect(() => {
    dispatch(organizationDetailsRequest());
  }, [dispatch]);

  const saveComoanyInfo = (values) => {
    const str = values.timeZone.label;
    const offset = str.match(/\((UTC)(.*?)\)/)[2];

    let submittedValues = {
      id: values.id,
      name: values.name,
      baseCurrency: values.baseCurrency,
      country: values.country.value,
      timeZone: values.timeZone.value,
      dateFormat: values.dateFormat.value,
      industry: values.industry.value,
      offset: offset,
    };

    dispatch(organizationUpdateDetailsRequest(submittedValues));
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Update Company Details"
        successMessage="Company details updated successfully."
        isDialogOpen={updateDialog}
        closeDialog={() => setUpdateDialog(false)}
        loading={upadteLoading}
        loadingMessage="Processing request..."
        success={updateSuccess}
        error={updateError}
        btnValue="OK"
        confirmAction={() => setUpdateDialog(false)}
      />

      <Breadcrumbs screenName="Company Settings" />
      <div className={commonStyle.tabsContainer}>
        <CompanySettingsTab tabName="general" />

        <div className={commonStyle.settingsContent}>
          {loading ? (
            <Loader mess="Requesting company details, please wait a moment..." />
          ) : success ? (
            <OrganizationForm
              orgInfo={() => formatCompanyInfo(orgInfo)}
              onSubmit={saveComoanyInfo}
            />
          ) : error ? (
            <ErrorMessage mess={error} />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
