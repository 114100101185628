import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { LoadingButton } from "../../components/editComponents";
import { TextErrorMessage } from "../../components/viewComponents";
import integrationStyle from "./integrationStyle.module.css";
import { userDetailsRequest } from "../../actions/authAction";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function DisconnectXero({ textBtn = false }) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  //////////////////////////////////////
  const [disconnectReqStatus, setDisconnectReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  //////////////////////////////////////
  const disconnectXeroSuccess = () => {
    navigate("/integration/xero/connect", { replace: true, from: location });
  };

  const disconnectXeroAction = () => {
    try {
      setDisconnectReqStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      axios
        .get(SERVER_URL + `/integration/xero/disconnect`, config)
        .then(async (response) => {
          if (response.data.success) {
            setDisconnectReqStatus((preState) => ({
              ...preState,
              loading: false,
              success: true,
              error: "",
            }));
            disconnectXeroSuccess();
            dispatch(userDetailsRequest());
          } else if (response.data.error === 10) {
            setDisconnectReqStatus((preState) => ({
              ...preState,
              loading: false,
            }));
            disconnectXeroSuccess();
          } else {
            setDisconnectReqStatus((preState) => ({
              ...preState,
              loading: false,
              error: "Disconnect Xero failed, please try again later.",
            }));
          }
        })
        .catch((err) => {
          setDisconnectReqStatus((preState) => ({
            ...preState,
            loading: false,
            error: "Disconnect Xero failed, please try again later.",
          }));
        });
    } catch (error) {
      setDisconnectReqStatus((preState) => ({
        ...preState,
        loading: false,
        error: "Connection Error, please try again later.",
      }));
    }
  };

  return textBtn ? (
    <div
      className={integrationStyle.disconnectTextBtn}
      onClick={disconnectXeroAction}
    >
      Disconnect
    </div>
  ) : (
    <div className={integrationStyle.configCard}>
      {disconnectReqStatus.error ? (
        <TextErrorMessage mess={disconnectReqStatus.error} />
      ) : (
        <></>
      )}
      {disconnectReqStatus.loading ? (
        <LoadingButton name="Disconnecting..." />
      ) : (
        <div
          className={integrationStyle.disconnectBtn}
          onClick={disconnectXeroAction}
        >
          Disconnect
        </div>
      )}
    </div>
  );
}
