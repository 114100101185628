
function validateInteger(num){
    const validated = num.match(/^(?:\d+)?$/g);
    return validated;
}

function validatePrice(num){
    // only allow two decimals
    const validated = num.match(/^(\d*\.{0,1}\d{0,2}$)/);
    return validated;
    
}

function validateQuantity(num){
    // only allow three decimals
    const validated = num.match(/^(\d*\.{0,1}\d{0,3}$)/);
    return validated;
}

function validateEmail(email){
    // only allow three decimals
    let re = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const validated = re.test(email);
    return validated;
}
  
export { validatePrice, validateQuantity, validateInteger, validateEmail };