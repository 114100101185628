import React, { useEffect, useState } from "react";
import { userlogout } from "../../actions/authAction.js";
import { useDispatch, useSelector } from "react-redux";
import {
  serialBatchNumbersReportFilterOptionsRequest,
  serialBatchNumbersReportRequest,
} from "../../api/reportingServices";
import Breadcrumbs from "../../components/Breadcrumbs";
import Pagination from "../../components/Pagination";
import {
  LoadingButton,
  ScreenTitle,
  SimpleBlueButton,
  SimpleOutlineButton,
} from "../../components/editComponents";
import { StyledMultiSelect } from "../../components/inputFields";
import {
  ErrorMessage,
  Loader,
  Tag,
  VariantSkuTag,
  VariantTag,
  ViewDateText,
} from "../../components/viewComponents";
import { formatDate } from "../../functions/functions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import { CsvWriter } from "./CsvWriter.js";

export default function SerialBatchNumberReportScreen() {
  useDocumentTitle("Serial & Batch Numbers Report");
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  // pagination

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);

  const [reportData, setReportData] = useState([]);
  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [downloadStatus, setDownloadStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [options, setOptions] = useState("");

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  // filter and search  function
  const [filterLocation, setFilterLocation] = useState([]);
  const [filterCategory, setFilterCategory] = useState([]);
  const [filterProduct, setFilterProduct] = useState([]);

  const applyFilter = () => {
    getProducts();
  };

  /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////
  const downloadFile = async () => {
    // get full stocktake list
    let stockListdata = [];

    let selectedFilterCategory = [];
    let selectedFilterLocation = [];
    let selectedFilterProduct = [];

    if (filterCategory.length > 0) {
      filterCategory.forEach((cate) => {
        selectedFilterCategory.push(cate.id);
      });
    }

    if (filterLocation.length > 0) {
      filterLocation.forEach((loc) => {
        selectedFilterLocation.push(loc.id);
      });
    }
    if (filterProduct.length > 0) {
      filterProduct.forEach((pro) => {
        selectedFilterProduct.push(pro.id);
      });
    }

    try {
      setDownloadStatus((preState) => ({
        ...preState,
        loading: true,
      }));

      const responseData = await serialBatchNumbersReportRequest(
        0,
        99999999999,
        selectedFilterCategory.join(),
        selectedFilterLocation.join(),
        selectedFilterProduct.join(),
        config
      );

      if (responseData.success) {
        stockListdata = responseData.data.products;

        setDownloadStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      }else if(responseData.error === -3){
        dispatch(userlogout());
      }
    } catch (error) {
      setDownloadStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }

    //get csv file
    let csvWriter = new CsvWriter([
      "Type",
      "Product Code",
      "ProductName",
      "Category",
      "Variant",
      "Location",
      "Code",
      "Quantity",
      "ExpireDate",
    ]);
    for (let line of stockListdata) {

      csvWriter.addLine([
        line.type,
        line.variantSku,
        line.name,
        line.category ? line.category : "",
        line.variant,
        line.location,
        line.code,
        line.quantity_left,
        line.expire_date ? formatDate(
          new Date(line.expire_date * 1000),
          userDetails.data.orgInfo.date_format,
          userDetails.data.orgInfo.time_zone
        ) : ""
      ]);
    }
    const csvString = csvWriter.content;

    // create a download link
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "batch-serial-numbers-report.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  /////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getProducts();
  }, [pageNum]);

  useEffect(() => {
    const getOptions = async () => {
      try {
        const responseData = await serialBatchNumbersReportFilterOptionsRequest(
          config
        );

        if (responseData.success) {
          setOptions(responseData.data);
        }
      } catch (error) {}
    };

    if (!options) {
      getOptions();
    }
  }, [options]);

  const getProducts = async () => {
    let selectedFilterCategory = [];
    let selectedFilterLocation = [];
    let selectedFilterProduct = [];

    if (filterCategory.length > 0) {
      filterCategory.forEach((cate) => {
        selectedFilterCategory.push(cate.id);
      });
    }

    if (filterLocation.length > 0) {
      filterLocation.forEach((loc) => {
        selectedFilterLocation.push(loc.id);
      });
    }
    if (filterProduct.length > 0) {
      filterProduct.forEach((pro) => {
        selectedFilterProduct.push(pro.id);
      });
    }


    try {
      setRequestStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await serialBatchNumbersReportRequest(
        pageNum,
        pageSize,
        selectedFilterCategory.join(),
        selectedFilterLocation.join(),
        selectedFilterProduct.join(),
        config
      );

      if (responseData.success) {
        setReportData(responseData.data);
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      }else if(responseData.error === -3){
        dispatch(userlogout());
      }
    } catch (error) {
      setRequestStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const DownloadBtn = () => {
    return (
      <div style={{ width: "auto" }}>
        {downloadStatus.loading ? (
          <LoadingButton name="Downloading.." />
        ) : (
          <SimpleBlueButton name="Download" action={downloadFile} />
        )}
      </div>
    );
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Serial & Batch Numbers Report" />

      <ScreenTitle
        title="Serial & Batch Numbers Report"
        buttonComponent={<DownloadBtn />}
      />
      <div className={commonStyle.pageContentContainer}>
        <div style={{ width: "100%", display: "flex", alignItems: "flex-end" }}>
          <div
            style={{
              width: "80%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "30%" }}>
              <StyledMultiSelect
                label="Location"
                selectOptions={
                  options ? options.locations : []
                }
                value={filterLocation}
                onChange={(location) => setFilterLocation(location)}
              />
            </div>
            <div style={{ width: "30%" }}>
              <StyledMultiSelect
                label="Product"
                selectOptions={
                  options ? options.productNames : []
                }
                value={filterProduct}
                onChange={(product) => setFilterProduct(product)}
              />
            </div>
            <div style={{ width: "30%" }}>
              <StyledMultiSelect
                label="Category"
                selectOptions={
                  options ? options.categories : []
                }
                value={filterCategory}
                onChange={(category) => setFilterCategory(category)}
              />
            </div>
          </div>
          <div
            style={{
              width: "20%",
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "-20px",
            }}
          >
            <SimpleOutlineButton name="Apply Filter" action={applyFilter} />
          </div>
        </div>

        {requestStatus.loading ? (
          <Loader mess="Requesting report, please wait a moment..." />
        ) : requestStatus.success ? (
          <div className={commonStyle.longDataTable}>
            {/* hold the product list table */}
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Product Code</th>
                  <th>Product Name</th>
                  <th>Category</th>
                  <th>Variant</th>
                  <th>Location</th>
                  <th>Code</th>
                  <th>Quantity</th>
                  <th>Expire Date</th>
                </tr>
              </thead>
              <tbody>
                {reportData.products.length > 0 ? (
                  reportData.products.map((item) => (
                    <React.Fragment key={item.id}>
                      <tr>
                        <td>{item.type}</td>
                        <td><VariantSkuTag name={item.variantSku} /></td>
                        <td>{item.name}</td>
                        <td>{item.category}</td>
                        <td>
                          {item.variant ? (
                            <VariantTag name={item.variant} />
                          ) : (
                            <></>
                          )}
                        </td>
                        <td>
                          <Tag name={item.location} color="gray" />
                        </td>
                        <td>{item.code}</td>
                        <td>{item.quantity_left}</td>
                        <td>
                          {item.expire_date > 0 ? (
                            <ViewDateText value={item.expire_date} />
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td>
                      <p>There are no data in selected period.</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {reportData.products.length > 0 ? (
              <Pagination
                totalPageNum={reportData.total_pages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : requestStatus.error ? (
          <ErrorMessage mess="Requesting report data failed, please try again later." />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
