import axios from "axios";


const SERVER_URL = process.env.REACT_APP_SERVER_URL;
// get package list
export const batchSerialNumbersRequest = async (type, famid, variantCode, locationId,rentalStartTime, rentalEndTime, config) => {
   try {
     const response = await axios.get(SERVER_URL + `/serial_batch/list?type=${type}&famid=${famid}&variantCode=${variantCode}&locationId=${locationId}&rentalStartTime=${rentalStartTime}&rentalEndTime=${rentalEndTime}`, config);
     return response.data;
   } catch (error) {
     throw new Error(error.message);
   }
 
 };
