export const SALES_TAX_RATE_REQUEST = "SALES_TAX_RATE_REQUEST";
export const SALES_TAX_RATE_SUCCESS = "SALES_TAX_RATE_SUCCESS";
export const SALES_TAX_RATE_FAIL = "SALES_TAX_RATE_FAIL";

//users and roles

export const ALL_PERMISSIONS_REQUEST = "ALL_PERMISSIONS_REQUEST";
export const ALL_PERMISSIONS_SUCCESS = "ALL_PERMISSIONS_SUCCESS";
export const ALL_PERMISSIONS_FAIL = "ALL_PERMISSIONS_FAIL";

export const ROLE_NAME_LIST_REQUEST = "ROLE_NAME_LIST_REQUEST";
export const ROLE_NAME_LIST_SUCCESS = "ROLE_NAME_LIST_SUCCESS";
export const ROLE_NAME_LIST_FAIL = "ROLE_NAME_LIST_FAIL";

export const ROLE_DETAIL_REQUEST = "ROLE_DETAIL_REQUEST";
export const ROLE_DETAIL_SUCCESS = "ROLE_DETAIL_SUCCESS";
export const ROLE_DETAIL_FAIL = "ROLE_DETAIL_FAIL";

export const NEW_ROLE_REQUEST = "NEW_ROLE_REQUEST";
export const NEW_ROLE_SUCCESS = "NEW_ROLE_SUCCESS";
export const NEW_ROLE_FAIL = "NEW_ROLE_FAIL";

export const UPDATE_ROLE_REQUEST = "UPDATE_ROLE_REQUEST";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_FAIL = "UPDATE_ROLE_FAIL";

export const DELETE_ROLE_REQUEST = "DELETE_ROLE_REQUEST";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAIL = "DELETE_ROLE_FAIL";

export const USERS_LIST_REQUEST = "USERS_LIST_REQUEST";
export const USERS_LIST_SUCCESS = "USERS_LIST_SUCCESS";
export const USERS_LIST_FAIL = "USERS_LIST_FAIL";

export const CREATE_USER_OPTIONS_REQUEST = "CREATE_USER_OPTIONS_REQUEST";
export const CREATE_USER_OPTIONS_SUCCESS = "CREATE_USER_OPTIONS_SUCCESS";
export const CREATE_USER_OPTIONS_FAIL = "CREATE_USER_OPTIONS_FAIL";

export const NEW_USER_REQUEST = "NEW_USER_REQUEST";
export const NEW_USER_SUCCESS = "NEW_USER_SUCCESS";
export const NEW_USER_FAIL = "NEW_USER_FAIL";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";




