import {
  CREATED_PO_DATA_FAIL,
  CREATED_PO_DATA_REQUEST,
  CREATED_PO_DATA_SUCCESS,
  FAV_GROUP_FAIL,
  FAV_GROUP_REQUEST,
  FAV_GROUP_SUCCESS,
  FAV_GROUP_UPDATE_FAIL,
  FAV_GROUP_UPDATE_REQUEST,
  FAV_GROUP_UPDATE_SUCCESS,
  INITIAL_SETTINGS_FAIL,
  INITIAL_SETTINGS_REQUEST,
  INITIAL_SETTINGS_SUCCESS,
  INV_BOARD_DATA_FAIL,
  INV_BOARD_DATA_REQUEST,
  INV_BOARD_DATA_SUCCESS,
  SALE_BOARD_DATA_FAIL,
  SALE_BOARD_DATA_REQUEST,
  SALE_BOARD_DATA_SUCCESS,
  SALE_TRENDS_DATA_FAIL,
  SALE_TRENDS_DATA_REQUEST,
  SALE_TRENDS_DATA_SUCCESS,
  STOCK_ALERT_PRODUCT_FAIL,
  STOCK_ALERT_PRODUCT_REQUEST,
  STOCK_ALERT_PRODUCT_SUCCESS,
  TOP_SALE_PRODUCT_FAIL,
  TOP_SALE_PRODUCT_REQUEST,
  TOP_SALE_PRODUCT_SUCCESS,
  TRAIL_TASK_FAIL,
  TRAIL_TASK_REQUEST,
  TRAIL_TASK_SUCCESS,
} from "../constants/dashboardConstants";

export const initialSettingsReducer = (state = { initialSettings: {} }, action) => {
  switch (action.type) {
    case INITIAL_SETTINGS_REQUEST:
      return { loading: true, success: false };
    case INITIAL_SETTINGS_SUCCESS:
      return { loading: false, initialSettings: action.payload, success: true };
    case INITIAL_SETTINGS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const trailTaskReducer = (state = { tasks: {} }, action) => {
  switch (action.type) {
    case TRAIL_TASK_REQUEST:
      return { loading: true, success: false };
    case TRAIL_TASK_SUCCESS:
      return { loading: false, tasks: action.payload, success: true };
    case TRAIL_TASK_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const favGroupReducer = (state = { favList: [] }, action) => {
  switch (action.type) {
    case FAV_GROUP_REQUEST:
      return { loading: true, success: false };
    case FAV_GROUP_SUCCESS:
      return { loading: false, favList: action.payload, success: true };
    case FAV_GROUP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateFavGroupReducer = (state = {}, action) => {
  switch (action.type) {
    case FAV_GROUP_UPDATE_REQUEST:
      return { loading: true, success: false };
    case FAV_GROUP_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case FAV_GROUP_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const invBoardDataReducer = (state = { invData: {} }, action) => {
  switch (action.type) {
    case INV_BOARD_DATA_REQUEST:
      return { loading: true, success: false };
    case INV_BOARD_DATA_SUCCESS:
      return { loading: false, invData: action.payload, success: true };
    case INV_BOARD_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const saleBoardDataReducer = (state = { salesData: [] }, action) => {
  switch (action.type) {
    case SALE_BOARD_DATA_REQUEST:
      return { loading: true, success: false };
    case SALE_BOARD_DATA_SUCCESS:
      return { loading: false, salesData: action.payload, success: true };
    case SALE_BOARD_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
  
export const saleTrendsdDataReducer = (state = { saleTrends: [] }, action) => {
  switch (action.type) {
    case SALE_TRENDS_DATA_REQUEST:
      return { loading: true, success: false };
    case SALE_TRENDS_DATA_SUCCESS:
      return { loading: false, saleTrends: action.payload, success: true };
    case SALE_TRENDS_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createdPurchaseOrderDataReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case CREATED_PO_DATA_REQUEST:
      return { loading: true, success: false };
    case CREATED_PO_DATA_SUCCESS:
      return { loading: false, orders: action.payload, success: true };
    case CREATED_PO_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const topSaleProductReducer = (state = { topSales: [] }, action) => {
  switch (action.type) {
    case TOP_SALE_PRODUCT_REQUEST:
      return { loading: true, success: false };
    case TOP_SALE_PRODUCT_SUCCESS:
      return { loading: false, topSales: action.payload, success: true };
    case TOP_SALE_PRODUCT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const stockAlertProductReducer = (state = { alterProducts: [] }, action) => {
  switch (action.type) {
    case STOCK_ALERT_PRODUCT_REQUEST:
      return { loading: true, success: false };
    case STOCK_ALERT_PRODUCT_SUCCESS:
      return { loading: false, alterProducts: action.payload, success: true };
    case STOCK_ALERT_PRODUCT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
