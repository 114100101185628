import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import dashboardStyle from "./dashboardStyle.module.css";
import { HiCube } from "react-icons/hi";
import { topSaleProductsRequest } from "../../actions/dashboardActions";
import {
  ErrorMessage,
  Loader,
  MoreLink,
  VariantTag,
} from "../../components/viewComponents";
import { getTimeStamps } from "../../functions/functions";
import NodataChart from "./charts/NodataChart";
import { convtPrice } from "../../functions/functions";

export default function LeaderBoard() {
  const dispatch = useDispatch();
  // access check for the actions

  const { userDetails } = useSelector((state) => state.userDetail);

  const { loading, topSales, success, error } = useSelector(
    (state) => state.topSaleBoard
  );

  useEffect(() => {
    let timeStamp = getTimeStamps("7days", userDetails.data.orgInfo.time_zone);
    dispatch(topSaleProductsRequest(timeStamp[0], timeStamp[1], "soldRevenue"));
  }, [dispatch, userDetails.data.orgInfo.time_zone]);

  return (
    <div className={dashboardStyle.section_wrapper_width}>
      {loading ? (
        <Loader mess="Requesting data..." />
      ) : success ? (
        <div>
          <div className={dashboardStyle.section_titleCon}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
                  <div className="flex items-center ">
                <HiCube className={dashboardStyle.section_icon} />
                <p className={dashboardStyle.section_title}>
                  Last 7 days Top Sale Products
                </p>
              </div>

              <MoreLink href={`/reporting/sale/summary-by-product`} />
            </div>
          </div>

          {topSales.length > 0 ? (
            <div className={dashboardStyle.LeaderBoardWrapepr}>
              {/* <LeaderBoard title="Today's Top Seller" data={topSales} /> */}

              <table className={dashboardStyle.leadBoradTable}>
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Revenue</th>
                  </tr>
                </thead>
                <tbody>
                  {topSales.map((item, index) => (
                    <tr key={index}>
                      <td
                        className={
                          index === 0
                            ? dashboardStyle.leadBoradItemNum1
                            : index === 1
                            ? dashboardStyle.leadBoradItemNum2
                            : index === 2
                            ? dashboardStyle.leadBoradItemNum3
                            : dashboardStyle.leadBoradItemNum
                        }
                      >
                        {index + 1}
                      </td>
                      <td className={dashboardStyle.leadBoradItemCon}>
                        <div>
                          <p>{item.name}</p>{" "}
                          {item.variant ? (
                            <VariantTag name={item.variant} />
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>
                      <td>{convtPrice(item.incomeTotal)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <NodataChart text="There are no records of sales from the last 7 days" />
          )}
        </div>
      ) : error ? (
        <ErrorMessage mess={error} />
      ) : (
        <></>
      )}
    </div>
  );
}
