import axios from "axios";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;



export const deletePurchaseOrderRequest = async (poid, config) => {
   try {
     const response = await axios.post(SERVER_URL + `/purchase_order/delete/${poid}`, "", config);
     return response.data;
   } catch (error) {
     throw new Error(error.message);
   }
 
};


