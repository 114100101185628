import {
  EXPORT_CSV_FILE_FAIL,
  EXPORT_CSV_FILE_REQUEST,
  EXPORT_CSV_FILE_SUCCESS,
  IMPORT_CSV_FILE_FAIL,
  IMPORT_CSV_FILE_REQUEST,
  IMPORT_CSV_FILE_SUCCESS,
  IO_LOGS_FAIL,
  IO_LOGS_REQUEST,
  IO_LOGS_SUCCESS,
} from "../constants/IOConstants";

export const importCsvReducer = (state = {}, action) => {
  switch (action.type) {
    case IMPORT_CSV_FILE_REQUEST:
      return { loading: true, success: false };
    case IMPORT_CSV_FILE_SUCCESS:
      return { loading: false, success: true };
    case IMPORT_CSV_FILE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const exportCsvReducer = (state = {}, action) => {
  switch (action.type) {
    case EXPORT_CSV_FILE_REQUEST:
      return { loading: true, success: false };
    case EXPORT_CSV_FILE_SUCCESS:
      return { loading: false, success: true };
    case EXPORT_CSV_FILE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const importExportLogsReducer = (state = { logsList: [] }, action) => {
  switch (action.type) {
    case IO_LOGS_REQUEST:
      return { loading: true, success: false, showBtn: false };
    case IO_LOGS_SUCCESS:
      return {
        loading: false,
        logsList: action.payload,
        success: true,
        showBtn: true,
      };
    case IO_LOGS_FAIL:
      return { loading: false, error: action.payload, showBtn: true };
    default:
      return state;
  }
};
