import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  ScreenTitle,
  EditButton,
  SimpleBlueButtonLink,
} from "../../components/editComponents";
import Breadcrumbs from "../../components/Breadcrumbs";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import {
  Loader,
  TextErrorMessage,
  ViewLabel,
} from "../../components/viewComponents";
import { useLocation, useNavigate } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import integrationStyle from "./integrationStyle.module.css";
import XeroIntegrationTab from "./XeroIntegrationTab";
import { NavLink } from "react-router-dom";
import DisconnectXero from "./DisconnectXero";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function XeroIntegrationScreen() {
  useDocumentTitle("Xero Integration");

  const location = useLocation();
  const navigate = useNavigate();
  // access check for the actions

  const { userInfo } = useSelector((state) => state.userLogin);

  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  const [tenantName, setTenantName] = useState("");
  const [configured, setConfigured] = useState(false);
  // xero connection status in Rui database
  const [connected, setConnected] = useState(false);
  // check xero connection statu by call Xero API, if user disconnect from Xero side, the two may different
  const [xeroConnected, setXeroConnected] = useState(false);

  const [xeroConfigData, setXeroConfigData] = useState({});
  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  ////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    // get xero connection status
    try {
      setRequestStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      axios
        .get(SERVER_URL + `/integration/xero/connection_status`, config)
        .then(async (response) => {
          if (response.data.success) {
            setRequestStatus((preState) => ({
              ...preState,
              loading: false,
              success: true,
              error: "",
            }));
            setTenantName(response.data.data.tenantName);
            if (
              response.data.data.connectionStatus === "connected" &&
              response.data.data.xeroConenctionStatus === "connected" &&
              response.data.data.configStatus !== "configured"
            ) {
              navigate("/integration/xero/edit", {
                replace: true,
                from: location,
                state: { tenantName: response.data.data.tenantName },
              });
            }
            if (response.data.data.connectionStatus !== "connected") {
              navigate("/integration/xero/conenction", {
                replace: true,
                from: location,
              });
            }
            if (response.data.data.connectionStatus === "connected") {
              setConnected(true);
            }
            if (response.data.data.xeroConenctionStatus === "connected") {
              setXeroConnected(true);
            }
            if (response.data.data.configStatus === "configured") {
              setConfigured(true);
              setXeroConfigData(response.data.data.configData);
            }
          } else {
            setRequestStatus((preState) => ({
              ...preState,
              loading: false,
              error:
                "Request Xero connection status falied, please try again later.",
            }));
          }
        })
        .catch((err) => {
          setRequestStatus((preState) => ({
            ...preState,
            loading: false,
            error:
              "Request Xero connection status falied, please try again later.",
          }));
        });
    } catch (error) {
      setRequestStatus((preState) => ({
        ...preState,
        loading: false,
        error: "Connection Error, please try again later.",
      }));
    }
  }, [SERVER_URL, userInfo.data.token, location, navigate]);

  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  const RightArrow = () => {
    return <BsArrowRight className={integrationStyle.arrowIcon} />;
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Xero Integration" />
      <ScreenTitle title="Xero Integration" />

      {requestStatus.loading ? (
        <Loader mess="Requesting Xero connection status, please wait a moment..." />
      ) : requestStatus.success ? (
        <>
          {connected && configured ? (
            <div className={commonStyle.tabsContainer}>
              <XeroIntegrationTab tabName="config" />

              <div className={commonStyle.settingsContent}>
                <div className={integrationStyle.configCard}>
                  <span>Connected Organization</span>
                  <span className={integrationStyle.companyName}>
                    {tenantName}
                  </span>

                  {!xeroConnected ? (
                    <div style={{ marginTop: "20px" }}>
                      <TextErrorMessage mess="Lost connection with your Xero account, please reconnect to Xero" />
                      <div style={{ width: "fit-content" }}>
                        <SimpleBlueButtonLink
                          path="/integration/xero/connect"
                          name="Reconnect to Xero"
                          data={{ reconnect: true }}
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                {xeroConnected ? (
                  <div className={integrationStyle.configCard}>
                    <ViewLabel
                      title="We will seamlessly synchronize your sales and purchasing data with
                  Xero, ensuring that each component (.i.e subtotal, discount, tax,
                  and shipping) of every sale and purchase is automatically mapped to
                  your connected Xero account, allowing you to effortlessly track your
                  revenue, expenses, and tax liabilities. Map below line items to your
                  Xero account to get started."
                    />

                    <div>
                      <div className={integrationStyle.editBtnWrapper}>
                        <NavLink
                          className={integrationStyle.editBtnCon}
                          to="/integration/xero/edit"
                          exact="true"
                          state={{
                            data: xeroConfigData,
                            tenantName: tenantName,
                          }}
                        >
                          <EditButton /> <span>Edit Xero Configuration</span>
                        </NavLink>
                      </div>

                      <div style={{ display: "flex" }}>
                        <div style={{ width: "100%" }}>
                          <p className={integrationStyle.configSubTitle}>
                            Accounts Mapping
                          </p>
                          <div className={integrationStyle.lineItemCon}>
                            <div
                              className={integrationStyle.lineNameEmpty}
                            ></div>
                            <div className={integrationStyle.arrowIcon}></div>
                            <div className={integrationStyle.accountTypeName}>
                              Xero Asset Account
                            </div>
                            <div className={integrationStyle.accountTypeName}>
                              Xero Revenue Account
                            </div>
                            <div className={integrationStyle.accountTypeName}>
                              Xero Expense Account
                            </div>
                          </div>

                          <div className={integrationStyle.lineItemCon}>
                            <div className={integrationStyle.lineName}>
                              Stock on Hand
                            </div>
                            <div>
                              <RightArrow />
                            </div>
                            <div className={integrationStyle.xeroAccountName}>
                              <div className={integrationStyle.lineValueName}>
                                {xeroConfigData.accounts.stockOnHand.code}-
                                {xeroConfigData.accounts.stockOnHand.name}
                              </div>
                            </div>
                            <div
                              className={integrationStyle.xeroAccountName}
                            ></div>
                            <div
                              className={integrationStyle.xeroAccountName}
                            ></div>
                          </div>

                          <div className={integrationStyle.lineItemCon}>
                            <div className={integrationStyle.lineName}>
                              Sales-Products
                            </div>
                            <div>
                              <RightArrow />
                            </div>
                            <div
                              className={integrationStyle.xeroAccountName}
                            ></div>
                            <div className={integrationStyle.xeroAccountName}>
                              <div className={integrationStyle.lineValueName}>
                                {xeroConfigData.accounts.salesProducts.code} -
                                {xeroConfigData.accounts.salesProducts.name}
                              </div>
                            </div>
                            <div
                              className={integrationStyle.xeroAccountName}
                            ></div>
                          </div>

                          <div className={integrationStyle.lineItemCon}>
                            <div className={integrationStyle.lineName}>
                              Sales-Other Charge
                            </div>
                            <div>
                              <RightArrow />
                            </div>
                            <div
                              className={integrationStyle.xeroAccountName}
                            ></div>
                            <div className={integrationStyle.xeroAccountName}>
                              <div className={integrationStyle.lineValueName}>
                                {xeroConfigData.accounts.salesFreight.code}-
                                {xeroConfigData.accounts.salesFreight.name}
                              </div>
                            </div>
                            <div
                              className={integrationStyle.xeroAccountName}
                            ></div>
                          </div>
                          <div className={integrationStyle.lineItemCon}>
                            <div className={integrationStyle.lineName}>
                              Purchase-Products
                            </div>
                            <div>
                              <RightArrow />
                            </div>
                            <div
                              className={integrationStyle.xeroAccountName}
                            ></div>
                            <div
                              className={integrationStyle.xeroAccountName}
                            ></div>
                            <div className={integrationStyle.xeroAccountName}>
                              <div className={integrationStyle.lineValueName}>
                                {xeroConfigData.accounts.purchaseProducts
                                  ? xeroConfigData.accounts.purchaseProducts
                                      .code + " - " +
                                    xeroConfigData.accounts.purchaseProducts
                                      .name
                                  : ""}
                              </div>
                            </div>
                          </div>

                          <div className={integrationStyle.lineItemCon}>
                            <div className={integrationStyle.lineName}>
                              Purchase-Other Cost
                            </div>
                            <div>
                              <RightArrow />
                            </div>
                            <div
                              className={integrationStyle.xeroAccountName}
                            ></div>
                            <div
                              className={integrationStyle.xeroAccountName}
                            ></div>
                            <div className={integrationStyle.xeroAccountName}>
                              <div className={integrationStyle.lineValueName}>
                                {xeroConfigData.accounts.purchaseFreight
                                  ? xeroConfigData.accounts.purchaseFreight
                                      .code + " - " +
                                    xeroConfigData.accounts.purchaseFreight.name
                                  : ""}
                              </div>
                            </div>
                          </div>

                          <div className={integrationStyle.lineItemCon}>
                            <div className={integrationStyle.lineName}>
                              Stock Adjustment
                            </div>
                            <div>
                              <RightArrow />
                            </div>
                            <div
                              className={integrationStyle.xeroAccountName}
                            ></div>
                            <div
                              className={integrationStyle.xeroAccountName}
                            ></div>
                            <div className={integrationStyle.xeroAccountName}>
                              <div className={integrationStyle.lineValueName}>
                                {xeroConfigData.accounts.stockAdjustment.code}-
                                {xeroConfigData.accounts.stockAdjustment.name}
                              </div>
                            </div>
                          </div>

                          <div className={integrationStyle.lineItemCon}>
                            <div className={integrationStyle.lineName}>
                              Cost of Goods Sold
                            </div>
                            <div>
                              <RightArrow />
                            </div>
                            <div
                              className={integrationStyle.xeroAccountName}
                            ></div>
                            <div
                              className={integrationStyle.xeroAccountName}
                            ></div>
                            <div className={integrationStyle.xeroAccountName}>
                              <div className={integrationStyle.lineValueName}>
                                {xeroConfigData.accounts.cogs.code}-
                                {xeroConfigData.accounts.cogs.name}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={integrationStyle.divider}></div>

                      <div style={{ display: "flex" }}>
                        <div style={{ width: "50%" }}>
                          <p className={integrationStyle.configSubTitle}>
                            Invoice/Bill Status
                          </p>
                          <div className={integrationStyle.statusLineItemCon}>
                            <div className={integrationStyle.statusLineName}>
                              Sale order invoice
                            </div>
                            <div style={{ flex: 1 }}>
                              <div className={integrationStyle.lineValueName}>
                                {xeroConfigData.status.saleInvoice}
                              </div>
                            </div>
                          </div>
                          <div className={integrationStyle.statusLineItemCon}>
                            <div className={integrationStyle.statusLineName}>
                              Purchase order (payable bill)
                            </div>
                            <div style={{ flex: 1 }}>
                              <div className={integrationStyle.lineValueName}>
                                {xeroConfigData.status.purchaseOrderBill}
                              </div>
                            </div>
                          </div>
                          <div className={integrationStyle.statusLineItemCon}>
                            <div className={integrationStyle.statusLineName}>
                              Stock adjustment journal
                            </div>
                            <div style={{ flex: 1 }}>
                              <div className={integrationStyle.lineValueName}>
                                {xeroConfigData.status.stockAdjustJournal}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ marginLeft: "32px", flex: 1 }}>
                          <p className={integrationStyle.configSubTitle}>
                            Invoice Branding Theme
                          </p>
                          <div style={{ display: "flex" }}>
                            <div className={integrationStyle.statusLineName}>
                              Invoice Branding Theme
                            </div>
                            <div style={{ flex: 1 }}>
                              <div className={integrationStyle.lineValueName}>
                                {xeroConfigData.brandingTheme.brandingThemeName}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={integrationStyle.divider}></div>
                      <p className={integrationStyle.configSubTitle}>
                        Tax Mapping
                      </p>

                      <div>
                        <div className={integrationStyle.lineItemCon}>
                          <div className={integrationStyle.lineNameEmpty}></div>
                          <div className={integrationStyle.arrowIcon}></div>
                          <div className={integrationStyle.rateTypeName}>
                            Revenue Tax Rate
                          </div>
                          <div className={integrationStyle.rateTypeName}>
                            Expense Tax Rate
                          </div>
                        </div>
                      </div>

                      {xeroConfigData.taxs.length > 0 ? (
                        xeroConfigData.taxs
                          .filter((x) => x.type === "revenue")
                          .map((item, index) => (
                            <div
                              className={integrationStyle.lineItemCon}
                              key={index}
                            >
                              <div className={integrationStyle.lineName}>
                                {item.name}
                              </div>
                              <div>
                                <RightArrow />
                              </div>
                              <div className={integrationStyle.xeroRateName}>
                                <div className={integrationStyle.lineValueName}>
                                  {
                                    xeroConfigData.taxs.filter(
                                      (x) =>
                                        x.taxrate_id === item.taxrate_id &&
                                        x.type === "revenue"
                                    )[0].xero_tax_type
                                  }{" "}
                                  -{" "}
                                  {
                                    xeroConfigData.taxs.filter(
                                      (x) =>
                                        x.taxrate_id === item.taxrate_id &&
                                        x.type === "revenue"
                                    )[0].xero_tax_name
                                  }
                                </div>
                              </div>

                              <div className={integrationStyle.xeroRateName}>
                                <div className={integrationStyle.lineValueName}>
                                  {
                                    xeroConfigData.taxs.filter(
                                      (x) =>
                                        x.taxrate_id === item.taxrate_id &&
                                        x.type === "expense"
                                    )[0].xero_tax_type
                                  }{" "}
                                  -{" "}
                                  {
                                    xeroConfigData.taxs.filter(
                                      (x) =>
                                        x.taxrate_id === item.taxrate_id &&
                                        x.type === "expense"
                                    )[0].xero_tax_name
                                  }
                                </div>
                              </div>
                            </div>
                          ))
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <DisconnectXero />
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : requestStatus.error ? (
        <TextErrorMessage mess={requestStatus.error} />
      ) : (
        <></>
      )}
    </div>
  );
}
