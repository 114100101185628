import axios from "axios";
import { authMiddleware } from "./authMiddleware";
import { SALE_ORDER_REPORT_OPTIONS_FAIL, SALE_ORDER_REPORT_OPTIONS_REQUEST, SALE_ORDER_REPORT_OPTIONS_SUCCESS } from "../constants/reportingConstants";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const saleOrderReportOptionsRequest = () => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {


    dispatch({ type: SALE_ORDER_REPORT_OPTIONS_REQUEST });
    axios
      .get(SERVER_URL + `/reporting/sale/filter-options`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({
            type: SALE_ORDER_REPORT_OPTIONS_SUCCESS,
            payload: response.data.data,
          });
        } else {
          throw Object.assign(
            new Error("Request report options failed, please try again later."),
            { code: 301 }
          );
        }
      })
      .catch((err) => {
        dispatch({
          type: SALE_ORDER_REPORT_OPTIONS_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: SALE_ORDER_REPORT_OPTIONS_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};
