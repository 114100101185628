import React from "react";
import commonStyle from "../style/commonStyle.module.css";
import { MdArrowRightAlt } from "react-icons/md";

export default function VerticalTabs({ tabsData, changeTab, activeTab }) {

  return (
    <div className={commonStyle.variantTabcon}>
      {tabsData.map((item, index) => {
        return activeTab && item.value === activeTab ? (
          <div key={index} className={commonStyle.variantItemActive}>
            <span style={{marginRight:"4px"}}>{item.tabName}</span>
            <MdArrowRightAlt />
          </div>
        ) : (
          <div
            key={index}
            className={commonStyle.variantItem}
            onClick={() => changeTab(item.value)}
          >
            <span>{item.tabName}</span>
          </div>
        );
      })}
    </div>
  );
}
