import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../components/Breadcrumbs";
import {
  newCategoryRequest,
  updateCategoryRequest,
  productCategoriesRequest,
  deleteCategoryRequest,
} from "../../actions/productActions";
import {
  ScreenTitle,
  AddButton,
  EditButton,
  DeleteButton,
} from "../../components/editComponents";
import {
  Modal,
  ErrorMessage,
  Loader,
  SimpleDialog,
  DeleteDialog,
} from "../../components/viewComponents";
import commonStyle from "../../style/commonStyle.module.css";
import CatogoryForm from "./CategoryForm";
import useDocumentTitle from "../../useDocumentTitle";

export default function ProductCategoriesScreen() {
  useDocumentTitle("Product category")
  // access check for the actions

  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  //define the add new category input values
  const initialValues = {
    name: "",
  };

  // get data from store by using useSelector method
  const { loading, categoryList, error, success } = useSelector(
    (state) => state.productCategories
  );

  const {
    loading: upadteLoading,
    error: updateError,
    success: updateSuccess,
  } = useSelector((state) => state.updateCategory);

  const {
    loading: newLoading,
    error: newError,
    success: newSuccess,
  } = useSelector((state) => state.newCategory);

  const [editedCategory, setEditCategory] = useState("");

  // new and edit Modal
  const [newModal, setNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  // mange dialog content, new and edit
  const [newDialog, setNewDialog] = useState(false);
  const [updateDialog, setUpdateDialog] = useState(false);

  const openEditModal = (item) => {
    setEditCategory(item);
    setEditModal(true);
  };

  const addNewCategory = (values) => {
    let newValues = {
      cateName: values.name,
    };

    dispatch(newCategoryRequest(newValues));
    setNewModal(false);
    setNewDialog(true);
  };

  const editCategory = (values) => {
    let newValues = {
      cateName: values.name,
    };

    dispatch(updateCategoryRequest(newValues, values.id));
    setEditModal(false);
    setUpdateDialog(true);
  };

  // delete
  const [deleteId, setDeleteId] = useState();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const {
    loading: deleteLoading,
    error: deleteError,
    success: deleteSuccess,
  } = useSelector((state) => state.deleteCategory);

  const deleteAction = (id) => {
    setDeleteId(id);
    setDeleteDialog(true);
  };

  const sendDeleteRequest = () => {
    dispatch(deleteCategoryRequest(deleteId));
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(productCategoriesRequest());
  }, [dispatch]);

  return (
    <div className={commonStyle.pageContainer}>
      <Modal
        title="New Product Category"
        isModalOpen={newModal}
        closeModal={() => setNewModal(false)}
        content={
          <CatogoryForm
            values={initialValues}
            onSubmit={addNewCategory}
            btnName="Save"
          />
        }
      />
      <Modal
        title="Edit Product Category"
        isModalOpen={editModal}
        closeModal={() => setEditModal(false)}
        content={
          <CatogoryForm
            values={editedCategory}
            onSubmit={editCategory}
            btnName="Update"
          />
        }
      />

      <SimpleDialog
        title="Add New Product Category"
        successMessage="New product category added successfully."
        isDialogOpen={newDialog}
        closeDialog={() => setNewDialog(false)}
        loading={newLoading}
        loadingMessage="Processing request..."
        success={newSuccess}
        error={newError}
        btnValue="Confirm"
        confirmAction={() => setNewDialog(false)}
      />

      <SimpleDialog
        title="Edit Product Category"
        successMessage="Product category updated successfully."
        isDialogOpen={updateDialog}
        closeDialog={() => setUpdateDialog(false)}
        loading={upadteLoading}
        loadingMessage="Processing request..."
        success={updateSuccess}
        error={updateError}
        btnValue="Confirm"
        confirmAction={() => setUpdateDialog(false)}
      />

      <DeleteDialog
        title="Delete Product Category"
        preDeleteMess="Deleting this category can not be undone. All products associated with this category will have their category field changed to empty. Are you sure you want to proceed with deleting this category?"
        successMessage="Product category deleted sucessfully."
        isDialogOpen={deleteDialog}
        closeDialog={() => setDeleteDialog(false)}
        loading={deleteLoading}
        success={deleteSuccess}
        error={deleteError}
        loadingMessage="Processing request..."
        deleteAction={sendDeleteRequest}
      />

      <Breadcrumbs screenName="Product Categories" />
      <ScreenTitle title="Product Categories" />

      <div className={commonStyle.pageContentContainer}>
        {loading ? (
          <Loader mess="Requesting category list, please wait a moment..." />
        ) : success ? (
          <div className={commonStyle.shortDataTable}>
            {userDetailsSuccess ? (
              userDetails.data.permissions.includes(
                "add_delete_edit_product_categories"
              ) ? (
                <AddButton
                name="New Product Category"
                action={() => setNewModal(true)}
              />
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
           

            {/* hold the table */}
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  {/* <th></th> */}
                  <th>Category Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {categoryList.length > 0 ? (
                  categoryList.map((item) => (
                    <tr key={item.id}>
                      <td>{item.name} </td>
                      <td>
                        {/* check if has access for "new_product" */}
                        {userDetailsSuccess ? (
                          userDetails.data.permissions.includes(
                            "add_delete_edit_product_categories"
                          ) ? (
                            <div className={commonStyle.actionsGrop}>
                              <EditButton action={() => openEditModal(item)} />
                              <DeleteButton
                                action={() => deleteAction(item.id)}
                              />
                            </div>
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td> There are no product category available. Please add a new product category now.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
    </div>
  );
}
