import { SALE_ORDER_REPORT_OPTIONS_FAIL, SALE_ORDER_REPORT_OPTIONS_REQUEST, SALE_ORDER_REPORT_OPTIONS_SUCCESS } from "../constants/reportingConstants";




export const saleOrderReportOptionReducer = (state = {options:""}, action) => {
  switch (action.type) {
    case SALE_ORDER_REPORT_OPTIONS_REQUEST:
      return { loading: true };
    case SALE_ORDER_REPORT_OPTIONS_SUCCESS:
      return { loading: false, options: action.payload, success: true };
    case SALE_ORDER_REPORT_OPTIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }

};


