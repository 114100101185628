import axios from "axios";
import { Buffer } from "buffer";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  DownloadInvoiceButton,
  InvoiceButton,
} from "../../../components/editComponents";
import styledComponentsStyle from "../../../style/styledEditComponentsStyle.module.css";
import { Loader } from "../../../components/viewComponents";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function SaleOrderInvoice({ name, invoiceId, detailspage }) {
  const { userInfo } = useSelector((state) => state.userLogin);

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  /////////////////////////////////////////////////////////////////
  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const downloadInvoice = () => {
    try {
      setRequestStatus({ ...requestStatus, loading: true });
      axios
        .get(
          SERVER_URL + `/integration/xero/transaction/invoice/${invoiceId}`,
          config
        )
        .then(async (response) => {
          if (response.data.success) {
            setRequestStatus({
              ...requestStatus,
              loading: false,
              success: true,
            });
            // Create a Blob from the buffer

            var buf = Buffer.from(response.data.data.invoice, "base64");
            const blob = new Blob([buf], { type: "application/pdf" });

            const url = URL.createObjectURL(blob);
            const fileName = name;
            // Create a link to trigger the download
            const link = document.createElement("a");
            link.href = url;
            link.download = fileName;
            document.body.appendChild(link);

            // Click the link to trigger the download
            link.click();

            // Clean up the link element and URL object
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
          } else {
            setRequestStatus({
              ...requestStatus,
              loading: false,
              error: "Request invoice failed, please try again later",
            });
          }
        })
        .catch((err) => {
          setRequestStatus({
            ...requestStatus,
            loading: false,
            error: "Request invoice failed, please try again later",
          });
        });
    } catch (error) {
      setRequestStatus({
        ...requestStatus,
        loading: false,
        error: "Connection error, please try again later",
      });
    }
  };

  return (
    <>
      {requestStatus.loading ? (
        <Loader mess="Downloading..." />
      ) : (
        <>
          {detailspage ? (
            <div
              className={styledComponentsStyle.downloadBtnCon}
              onClick={downloadInvoice}
            >
              <div className={styledComponentsStyle.xeroLogo}></div>
              <span>{"Download Invoice From Xero"}</span>
            </div>
          ) : (
            <InvoiceButton name={name} action={downloadInvoice} />
          )}
        </>
      )}
    </>
  );
}

export { SaleOrderInvoice };
