import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  GreenButton,
  XDeleteButton,
  SimpleBlueButton,
  AddItem,
  SingleRatioButton,
  AddLinkOutlineButton,
  BarcodeScanButton,
  EditButton,
} from "../../../components/editComponents";
import {
  StyledSelect,
  StyledInput,
  NoBorderPercentInput,
  NoBorderInput,
  StyledTextarea,
  DateInput,
  StyledPureInput,
} from "../../../components/inputFields";
import {
  Modal,
  ContentModal,
  Loader,
  SubTitle,
  ViewText,
  Tag,
  PriceText,
  VariantTag,
  TipsText,
  VariantSkuTag,
  XeroAccountMappingTip,
} from "../../../components/viewComponents";
import purchaseStyle from "../../purchase/purchaseStyle.module.css";
import commonStyle from "../../../style/commonStyle.module.css";
import salesStyle from "../salesStyle.module.css";
import SaleOrderMargin from "./SaleOrderMargin";
import SaleOrderScanForm from "./SaleOrderScanForm";
import { convtPrice, convtQuantity } from "../../../functions/functions";
import ProductPicker from "../../../components/ProductPicker";
import { ProductTypeIcon } from "../../../components/icons";
import ChangePackageProductForm from "./ChangePackageProductForm";
import { validatePrice, validateQuantity } from "../../../functions/validate";
import {
  SerialNumberSelect,
  BatchNumberSelect,
} from "../../../components/trackingNumbersComponents";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

/* 
must select location and customer before add product to order
when change location, refrsh the orderitems to check id has sufficient stock last
*/

export default function SaleOrderForm({
  initialOrderInfo,
  initialOrderItems,
  initialOrderSummary,
  initialCosts,
  submit,
  type,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  // access check for the actions
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
      "Content-type": "application/json",
    },
  };

  // product values

  const { options } = useSelector((state) => state.saleOrderOptions);

  /////////////////////////////initial states//////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  const [orderInfo, setOrderInfo] = useState(initialOrderInfo);
  const [orderItems, setOrderItems] = useState(initialOrderItems);

  const [addtionalCosts, setAddtionalCosts] = useState(initialCosts);
  // store the order items total cost and extra cost total. the tax amount and order amount calculate when display
  const [orderSummary, setOrderSummary] = useState(initialOrderSummary);

  const [updateStockLoadingOpen, setUpdateStockLoadingOpen] = useState(false);

  //define the error message
  const [formErrors, setFormErrors] = useState({});
  // scan dialog
  const [scanModal, setScanModal] = useState(false);

  /////////////////////////////Handle change functions////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrderInfo({ ...orderInfo, [name]: value });
  };
  const handleCustomerChange = (e) => {
    const { value } = e.target;

    if (value) {
      try {
        axios
          .get(SERVER_URL + `/customers/${value.id}`, config)
          .then(async (response) => {
            if (response.data.success) {
              let customerDetails = response.data.data;

              setOrderInfo({
                ...orderInfo,
                customer: value,
                customerName: customerDetails.name,
                customerBillingName: customerDetails.billingName,
                firstName: customerDetails.firstName,
                lastName: customerDetails.lastName,
                email: customerDetails.email,
                phone: customerDetails.phone,
                postalAddress: customerDetails.postalAddress,
                billingAddress: customerDetails.billingAddress,
                taxRate: options.taxrateOptions.filter(
                  (x) => Number(x.id) === Number(customerDetails.taxRateId)
                )[0],
                currency: customerDetails.currency
                  ? customerDetails.currency
                  : userDetails.data.orgInfo.base_currency,
                paymentTerm: customerDetails.paymentTerm,
                priceTier: options.priceTierOptions.filter(
                  (x) => Number(x.id) === Number(customerDetails.priceTierId)
                )[0],
              });
            } else {
              setFormErrors({ customer: "Get customer details failed!" });
            }
          })
          .catch((err) => {
            setFormErrors({ customer: "Get customer details failed!" });
          });
      } catch (error) {
        setFormErrors({ customer: "Get customer details failed!" });
      }
    } else {
      setOrderInfo({
        ...orderInfo,
        customer: "",
      });
    }
  };

  ////////////////////  //////////handle location change//////////////  //////////////
  ////////////////////  ///////refresh stock level//////////////  //////////////

  const handleLocationChange = (e) => {
    const { name, value } = e.target;
    setOrderInfo({ ...orderInfo, [name]: value });
    if (value) {
      if (orderItems.filter((x) => x.productName).length > 0) {
        updateProductsStock(value.id);
      }
    }
  };

  const updateProductsStock = (locationId) => {
    if (!locationId) {
      setFormErrors({
        orderItemsError: "Please select a location firstly.",
      });
      return;
    }

    const variantsCopy = [...orderItems];
    if (variantsCopy.filter((x) => x.productName || x.packageName).length > 0) {
      setUpdateStockLoadingOpen(true);
      let variantsParams = [];

      variantsCopy.forEach((elem) => {
        if (elem.productName) {
          variantsParams.push({
            famid: elem.famid,
            variantCode: elem.variantCode,
          });
        }
      });

      let variantOptions = encodeURI(JSON.stringify(variantsParams));

      axios
        .get(
          SERVER_URL +
            `/sale_order/variant/details?variantOptions=${variantOptions}&locationId=${locationId}`,
          config
        )
        .then(async (response) => {
          if (response.data.success) {
            setFormErrors({});
            setUpdateStockLoadingOpen(false);
            let varaiantStock = response.data.data;

            variantsCopy.forEach((elem) => {
              if (elem.productName) {
                elem.stockOnHand = varaiantStock.filter(
                  (x) =>
                    x.famid === elem.famid && x.variantCode === elem.variantCode
                )[0].stockOnHand;
              }
            });

            setOrderItems(variantsCopy);
          } else {
            setUpdateStockLoadingOpen(false);
            setFormErrors({
              orderItemsError:
                "Update product stock failed, please try again later.",
            });
          }
        })
        .catch((err) => {
          setUpdateStockLoadingOpen(false);
          setFormErrors({
            orderItemsError: "Connection error, please try again later",
          });
        });
    } else {
      setFormErrors({
        orderItemsError:
          "Please add a product firstly before check stock level.",
      });
    }
  };

  const sumProductsTotal = (items) => {
    let orderSummaryCopy = { ...orderSummary };
    let newProductTotal = items.reduce(
      (preTotal, item) => Number(preTotal) + Number(item.totalPrice),
      0
    );

    orderSummaryCopy.productsTotal = newProductTotal;

    setOrderSummary(orderSummaryCopy);
  };

  const handleOrderItemChange = (e) => {
    const { key, value, index } = e.target;
    const type = e.type;

    // only allow int inout
    if (type === "quantity") {
      const validated = validateQuantity(value);
      if (!validated) return;
    }

    if (type === "price") {
      const validated = validatePrice(value);
      if (!validated) return;
    }

    let newItems = [...orderItems];

    newItems[index][key] = value;

    if (newItems[index].unitPrice && newItems[index].quantity) {
      if (newItems[index].discount) {
        let discountedUnitPrice = convtPrice(
          newItems[index].unitPrice * (1 - newItems[index].discount / 100)
        );
        // has discount value
        newItems[index]["totalPrice"] =
          discountedUnitPrice * Number(newItems[index].quantity);
      } else {
        // no discount value
        newItems[index]["totalPrice"] = convtPrice(
          Number(newItems[index].unitPrice) * Number(newItems[index].quantity)
        );
      }
    } else {
      newItems[index]["totalPrice"] = "";
    }

    // caculate order total price before tax
    sumProductsTotal(newItems);
    // update order items
    setOrderItems(newItems);
  };

  const handleCostChange = (e) => {
    const { key, value, index } = e.target;
    const type = e.type;

    if (type === "price") {
      const validated = validatePrice(value);
      if (!validated) return;
    }

    let costsCopy = [...addtionalCosts];
    costsCopy[index][key] = value;
    setAddtionalCosts(costsCopy);

    // calculate cost total
    let orderSummaryCopy = { ...orderSummary };

    let costTotal = costsCopy.reduce(
      (preTotal, item) => convtPrice(Number(preTotal) + Number(item.cost)),
      0
    );

    orderSummaryCopy.additionalCostTotal = costTotal;
    setOrderSummary(orderSummaryCopy);
  };

  const handlePackageQuantityChange = (e) => {
    const { key, value, index, packageId } = e.target;
    const type = e.type;

    // only allow int inout
    if (type === "quantity") {
      const validated = validateQuantity(value);
      if (!validated) return;
    }
    //
    let newItems = [...orderItems];

    newItems[index][key] = value;

    if (newItems[index].unitPrice && newItems[index].quantity) {
      if (newItems[index].discount) {
        let discountedUnitPrice = convtPrice(
          newItems[index].unitPrice * (1 - newItems[index].discount / 100)
        );
        // has discount value
        newItems[index]["totalPrice"] =
          discountedUnitPrice * Number(newItems[index].quantity);
      } else {
        // no discount value
        newItems[index]["totalPrice"] = convtPrice(
          Number(newItems[index].unitPrice) * Number(newItems[index].quantity)
        );
      }
    } else {
      newItems[index]["totalPrice"] = "";
    }

    for (const item of newItems) {
      if (
        item.type === "packageProduct" &&
        item.packageId === packageId &&
        Number(value) !== 0
      ) {
        item.quantity = Number(item.packageQuantity) * Number(value);
      }
      if (
        item.type === "packageProduct" &&
        item.packageId === packageId &&
        Number(value) === 0
      ) {
        item.quantity = Number(item.packageQuantity);
      }
    }

    // caculate order total price before tax
    sumProductsTotal(newItems);
    // update order items
    setOrderItems(newItems);
  };

  // when open order margin modal
  const calculateExclTaxCost = () => {
    let costTotalExclTax = 0;
    if (!orderInfo.taxRate) {
      setFormErrors({
        orderItemsError:
          "Please select a tax rate before calculate order margin.",
      });
      return;
    }
    if (orderInfo.taxIncluded) {
      costTotalExclTax = convtPrice(
        orderSummary.additionalCostTotal /
          (1 + Number(orderInfo.taxRate.rate) / 100)
      );
    } else {
      costTotalExclTax = orderSummary.additionalCostTotal;
    }

    return costTotalExclTax;
  };

  ///////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////

  const addAdditionalCost = () => {
    let costCopy = [...addtionalCosts];
    costCopy.push({
      costName: "",
      cost: "",
    });

    setAddtionalCosts(costCopy);
  };

  const deleteOrderItem = (index, itemType, package_id) => {
    let orderItemsCopy = [...orderItems];
    if (itemType === "package") {
      orderItemsCopy = orderItemsCopy.filter(
        (x) => Number(x.packageId) !== Number(package_id)
      );
    } else {
      orderItemsCopy.splice(index, 1);
    }

    // caculate order total price before tax
    sumProductsTotal(orderItemsCopy);
    setOrderItems(orderItemsCopy);
  };

  const deleteCostItem = (index) => {
    let costCopy = [...addtionalCosts];
    costCopy.splice(index, 1);

    // re calculate cost total
    let orderSummaryCopy = { ...orderSummary };
    let costTotal = costCopy.reduce(
      (preTotal, item) => Number(preTotal) + Number(item.cost),
      0
    );
    orderSummaryCopy.additionalCostTotal = costTotal;

    setAddtionalCosts(costCopy);
    setOrderSummary(orderSummaryCopy);
  };

  ////////////////////  ////////////////////  ////////////////////  ////////////////////
  ////////////////////  ////////////////////  ////////////////////  ////////////////////
  ////////////////////  ////////////////////  ////////////////////  ////////////////////
  const getProductFromPicker = (result) => {
    if (result.productType === "package") {
      addNewProductPackage(result.data);
    } else {
      addNewOrderItem(result.data);
    }
  };

  const addNewOrderItem = (proDetails) => {
    let orderItemsCopy = [...orderItems];

    // remove empty row in order items
    orderItemsCopy = orderItemsCopy.filter(
      (x) => x.productName || x.packageName
    );
    // check exist products, error
    let exists = orderItemsCopy.some(
      (item) =>
        item.famid === proDetails.familyId &&
        item.variantCode === proDetails.variantCode &&
        item.type === proDetails.type
    );

    if (exists) {
      let index = orderItemsCopy.findIndex(
        (item) =>
          item.famid === proDetails.familyId &&
          item.variantCode === proDetails.variantCode
      );
      orderItemsCopy[index].quantity =
        Number(orderItemsCopy[index].quantity) + 1;

      orderItemsCopy[index].totalPrice =
        Number(orderItemsCopy[index].totalPrice) +
        1 * Number(orderItemsCopy[index].unitPrice);
    } else {
      orderItemsCopy.push({
        type: "product",
        productType: proDetails.productType,
        famid: proDetails.familyId,
        sku: proDetails.sku,
        productName: proDetails.name,
        variant: proDetails.variant ? proDetails.variant : "",
        variantCode: proDetails.variantCode,
        variantSku: proDetails.variantSku,
        serialized: proDetails.serialized,
        batchTracked: proDetails.batchTracked,
        consumeSerialNumbers: [],
        consumeBatchNumbers: [],
        unitName: proDetails.unit,
        unitPrice: proDetails.tierPrice
          ? convtPrice(proDetails.tierPrice)
          : convtPrice(proDetails.salePrice),
        stockOnHand: proDetails.stockLevel,
        discount: "",
        quantity: "",
        totalPrice: "",
      });
    }
    sumProductsTotal(orderItemsCopy);
    setOrderItems(orderItemsCopy);
  };

  // get orderitems from scan
  const openScanModal = () => {
    // check if has location selected
    if (!orderInfo.location) {
      setFormErrors({
        orderItemsError: "Please select a location before scan product.",
      });
      return;
    }

    setFormErrors({ orderItemsError: "" });
    setScanModal(true);
  };
  const getScanedProducts = (products) => {
    let orderItemsCopy = [...orderItems];
    // remove empty row in order items
    orderItemsCopy = orderItemsCopy.filter(
      (x) => x.productName || x.packageName
    );

    for (let i = 0; i < products.length; i++) {
      let pro = products[i];

      let exists = orderItemsCopy.some(
        (item) =>
          item.famid === pro.familyId && item.variantCode === pro.variantCode
      );
      if (exists) {
        let index = orderItemsCopy.findIndex(
          (item) =>
            item.famid === pro.familyId && item.variantCode === pro.variantCode
        );
        orderItemsCopy[index].quantity =
          Number(orderItemsCopy[index].quantity) + Number(pro.quantity);
        orderItemsCopy[index].totalPrice =
          Number(orderItemsCopy[index].totalPrice) +
          Number(pro.quantity) * Number(orderItemsCopy[index].unitPrice);
      } else {
        orderItemsCopy.push({
          type: "product",
          productType: pro.productType,
          famid: pro.familyId,
          productName: pro.productName,
          variant: pro.variant,
          variantCode: pro.variantCode,
          variantSku: pro.variantSku,
          serialized: pro.serialized,
          batchTracked: pro.batchTracked,
          consumeSerialNumbers: [],
          consumeBatchNumbers: [],
          sku: pro.sku,
          stockOnHand: pro.stockOnHand,
          discount: "",
          unitName: pro.unitName,
          unitPrice: pro.unitPrice,
          quantity: pro.quantity,
          totalPrice: pro.totalPrice,
        });
      }
    }

    // re calculate order total
    sumProductsTotal(orderItemsCopy);
    setOrderItems(orderItemsCopy);
    setScanModal(false);
  };

  ////////////////////  ////////////////////  ////////////////////  ////////////////////
  ////////////////////  ////////////////////  ////////////////////  ////////////////////
  ////////////////////  ////////////////////  ////////////////////  ////////////////////

  const addNewProductPackage = (packageDetails) => {
    let packageProducts = packageDetails.packageProducts;
    let packageInfo = packageDetails.basicInfo;

    let orderItemsCopy = [...orderItems];

    let exists = orderItemsCopy.some(
      (item) => item.packageId === packageInfo.id
    );

    if (exists) {
      return;
    }

    orderItemsCopy.push({
      productType: "",
      type: "package",
      packageId: packageInfo.id,
      famid: "",
      productName: "",
      packageName: packageInfo.packageName,
      variant: "",
      variantCode: "",
      variantSku: "",
      quantity: 1,
      unitName: "",
      unitPrice: convtPrice(packageInfo.price),
      discount: "",
      totalPrice: 1 * convtPrice(packageInfo.price),
    });

    packageProducts.forEach((elem) => {
      orderItemsCopy.push({
        productType: elem.productType,
        type: "packageProduct",
        packageId: packageInfo.id,
        packageName: packageInfo.packageName,
        famid: elem.famid,
        productName: elem.productName,
        variant: elem.variant,
        variantCode: elem.variantCode,
        variantSku: elem.variantSku,
        serialized: elem.serialized,
        batchTracked: elem.batchTracked,
        consumeSerialNumbers: [],
        consumeBatchNumbers: [],
        stockOnHand: elem.stockLevel,
        packageQuantity: Number(elem.quantity),
        quantity: Number(elem.quantity),
        unitName: elem.unitName,
        unitPrice: 0,
        discount: 0,
        totalPrice: 0,
        editable: elem.editable === 1 ? true : false,
      });
    });

    sumProductsTotal(orderItemsCopy);
    setOrderItems(orderItemsCopy);
  };

  ////////////////////////////change package product/////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////

  const [changPackageProductModal, setChangPackageProductModal] =
    useState(false);
  const [currentPackageProduct, setCurrentPackageProduct] = useState("");

  const editPackageProduct = (pro, index) => {
    if (!orderInfo.location) {
      setFormErrors({
        orderItemsError:
          "Please select a location before change package product.",
      });
      return;
    }

    setChangPackageProductModal(true);
    setCurrentPackageProduct({
      product: pro,
      index: index,
      locationId: orderInfo.location.id,
    });
  };

  const saveNewPackageProduct = (newProductDetails) => {
    let index = newProductDetails.index;
    let newProd = newProductDetails.product;

    let orderItemsCopy = [...orderItems];
    orderItemsCopy[index].productType = newProd.productType;
    orderItemsCopy[index].famid = newProd.familyId;
    orderItemsCopy[index].productName = newProd.name;
    orderItemsCopy[index].variant = newProd.variant;
    orderItemsCopy[index].variantCode = newProd.variantCode;
    orderItemsCopy[index].variantSku = newProd.variantSku;
    orderItemsCopy[index].serialized = newProd.serialized;
    orderItemsCopy[index].batchTracked = newProd.batchTracked;
    orderItemsCopy[index].consumeSerialNumbers = [];
    orderItemsCopy[index].consumeBatchNumbers = [];
    orderItemsCopy[index].unitName = newProd.unit;
    orderItemsCopy[index].stockOnHand = newProd.stockLevel;

    setOrderItems(orderItemsCopy);
    setChangPackageProductModal(false);
  };

  /////////////////////////////////Batch and Serial tracking//////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////

  const getSerialNumbersStockReduce = (serialNumbers, index) => {
    let orderProductsCopy = [...orderItems];
    orderProductsCopy[index].consumeSerialNumbers = serialNumbers;
    setOrderItems(orderProductsCopy);
  };

  const getBatchNumbersStockReduce = (batchNumbers, index) => {
    let orderProductsCopy = [...orderItems];
    orderProductsCopy[index].consumeBatchNumbers = batchNumbers;
    setOrderItems(orderProductsCopy);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////Submit and check values/////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////

  const checkValidation = (status) => {
    let sumitValues = {
      orderInfo: orderInfo,
      orderItems: orderItems,
      costs: addtionalCosts.filter((x) => x.costName && x.cost),
      orderSummary: orderSummary,
    };

    const hasErrors = validate(sumitValues, status);

    if (Object.keys(hasErrors).length === 0) {
      submit(sumitValues, status);
      setFormErrors({});
    } else {
      setFormErrors(hasErrors);
    }
  };

  // check input validation
  const validate = (values, status) => {
    const errors = {};

    if (!values.orderInfo.orderNumber) {
      errors.orderNumber = "order number is required!";
    }
    if (!values.orderInfo.customer) {
      errors.customer = "Customer is required!";
    }
    if (!values.orderInfo.taxRate) {
      errors.taxRate = "Tax rate is required!";
    }
    if (!values.orderInfo.currency) {
      errors.currency = "Currency is required!";
    }

    if (values.orderInfo.isNeedShipping) {
      if (!values.orderInfo.postalAddress) {
        errors.postalAddress = "Shipping address is required!";
      }
    }

    if (!values.orderInfo.location) {
      errors.location = "Sale location is required!";
    }

    if (values.orderInfo.paymentTerm === "") {
      errors.paymentTerm = "Payment term is required!";
    }

    // check if the order items is filled
    if (values.orderItems.length <= 0) {
      errors.orderItemsError =
        "Please add at least one product to the sale order.";
    } else {
      values.orderItems.forEach((item) => {
        if (item.type !== "packageProduct" && !item.unitPrice) {
          errors.orderItemsError = "Product price is required.";
        }

        if (!item.quantity) {
          errors.orderItemsError = "Product quantity is required.";
        }
        if (!Number(item.quantity) > 0) {
          errors.orderItemsError = "Product quantity must be greater than 0.";
        }
      });
    }

    // check serial and batch number validations
    if (
      status === "Approved" &&
      values.orderItems.some(
        (item) =>
          item.serialized &&
          Number(item.consumeSerialNumbers.length) !== Number(item.quantity)
      )
    ) {
      errors.orderItemsError =
        "The quantity of selected serial numbers is not equal to the order quantity.";
    }

    if (
      status === "Approved" &&
      values.orderItems.some(
        (item) =>
          item.batchTracked &&
          Number(
            item.consumeBatchNumbers.reduce((accumulator, currentValue) => {
              return accumulator + Number(currentValue.consumeQuantity);
            }, 0)
          ) !== Number(item.quantity)
      )
    ) {
      errors.orderItemsError =
        "The quantity of selected batch numbers is not equal to the order quantity.";
    }

    return errors;
  };

  //////////////////////////////////////calculate margin//////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const [marginModal, setMarginModal] = useState(false);
  const [calMarginLoadingOpen, setCalMarginLoadingOpen] = useState(false);

  const calculateOrderMargin = () => {
    if (!orderInfo.location) {
      setFormErrors({
        orderItemsError:
          "Please select a location before calculate order margin.",
      });
      return;
    }

    if (!orderInfo.taxRate) {
      setFormErrors({
        orderItemsError:
          "Please select a tax rate before calculate order margin.",
      });
      return;
    }

    const orderItemsCopy = [...orderItems];
    if (orderItemsCopy.filter((x) => x.productName).length < 1) {
      setFormErrors({
        orderItemsError:
          "Please add at least one product before calculate order margin.",
      });
      return;
    }
    let variantsParams = [];

    for (let i = 0; i < orderItemsCopy.length; i++) {
      if (
        orderItemsCopy[i].type !== "package" &&
        orderItemsCopy[i].productName
      ) {
        if (!Number(orderItemsCopy[i].quantity) > 0) {
          setFormErrors({
            orderItemsError:
              "Please enter a quantity before calculate order margin.",
          });
          return;
        }

        variantsParams.push({
          famid: orderItemsCopy[i].famid,
          variantSku: orderItemsCopy[i].variantSku,
          variantCode: orderItemsCopy[i].variantCode,
          salePrice: orderItemsCopy[i].unitPrice,
          quantity: orderItemsCopy[i].quantity,
          discount: orderItemsCopy[i].discount,
          locationId: orderInfo.location.id,
        });
      }
    }

    let variants = encodeURI(JSON.stringify(variantsParams));
    setCalMarginLoadingOpen(true);
    axios
      .get(
        SERVER_URL + `/sale_order/variant/margin?variants=${variants}`,
        config
      )
      .then(async (response) => {
        if (response.data.success) {
          setFormErrors({});
          setCalMarginLoadingOpen(false);

          setMarginModal(true);

          let varaiantsMargin = response.data.data;

          let taxrate = Number(orderInfo.taxRate.rate);

          orderItemsCopy.forEach((elem) => {
            if (elem.type === "packageProduct" && elem.productName) {
              let unitCost = varaiantsMargin.filter(
                (x) =>
                  x.famid === elem.famid && x.variantCode === elem.variantCode
              )[0].unitCost;

              elem["priceExclTax"] = 0;
              elem["productMargin"] = (0 - unitCost) * Number(elem.quantity);
              elem["unitCost"] = unitCost * Number(elem.quantity);
            } else {
              let unitPriceExclTax = 0;
              let unitCost = 0;

              if (orderInfo.taxIncluded) {
                unitPriceExclTax =
                  convtPrice(
                    Number(elem.unitPrice) * (1 - Number(elem.discount) / 100)
                  ) /
                  (1 + taxrate / 100);
              } else {
                unitPriceExclTax = convtPrice(
                  Number(elem.unitPrice) * (1 - Number(elem.discount) / 100)
                );
              }

              if (elem.type === "package") {
                unitCost = 0;
              } else {
                unitCost = varaiantsMargin.filter(
                  (x) =>
                    x.famid === elem.famid && x.variantCode === elem.variantCode
                )[0].unitCost;
              }

              elem["priceExclTax"] = unitPriceExclTax * Number(elem.quantity);
              elem["productMargin"] =
                (unitPriceExclTax - unitCost) * Number(elem.quantity);
              elem["unitCost"] = unitCost * Number(elem.quantity);
            }
          });

          setOrderItems(orderItemsCopy);
        } else {
          setCalMarginLoadingOpen(false);
          setFormErrors({
            orderItemsError:
              "Calculate sale order margin failed, please try again later.",
          });
        }
      })
      .catch((err) => {
        setCalMarginLoadingOpen(false);
        setFormErrors({
          orderItemsError: "Connection error, please try again later",
        });
      });
  };

  return (
    <div>
      <ContentModal
        title="Sale Order Margin"
        isModalOpen={marginModal}
        closeOnOverlayClick={true}
        closeModal={() => setMarginModal(false)}
        content={
          <SaleOrderMargin
            values={orderItems}
            taxIncluded={orderInfo.taxIncluded}
            additionalCharge={orderSummary.additionalCostTotal}
            additionalChargeExcltax={calculateExclTaxCost}
          />
        }
      />

      <ContentModal
        title="Scan Barcode"
        isModalOpen={scanModal}
        closeOnOverlayClick={false}
        closeModal={() => setScanModal(false)}
        content={
          <SaleOrderScanForm
            locationId={orderInfo.location ? orderInfo.location.id : ""}
            priceTierId={orderInfo.priceTier ? orderInfo.priceTier.id : ""}
            completeScan={getScanedProducts}
          />
        }
      />
      <Modal
        title="Change Package Product"
        isModalOpen={changPackageProductModal}
        closeModal={() => setChangPackageProductModal(false)}
        content={
          <ChangePackageProductForm
            currentProduct={currentPackageProduct}
            onSubmit={saveNewPackageProduct}
          />
        }
      />

      <div className={salesStyle.sectionCard}>
        <XeroAccountMappingTip pageName="saleOrder" />
        <div className={salesStyle.locationCon}>
          {userDetailsSuccess ? (
            userDetails.data.permissions.includes(
              "cross_location_sale_order"
            ) ? (
              <StyledSelect
               label="Sale Location*"
                placeHolder="Select sale location"
                selectOptions={options.locationOptions}
                value={orderInfo.location}
                clearable={false}
                onChange={(location) =>
                  handleLocationChange({
                    target: { value: location, name: "location" },
                  })
                }
                error={formErrors.location}
              />
            ) : (
              <StyledPureInput
                type="text"
                name="orderLocation"
                value={orderInfo.location.label}
                readOnly
              />
            )
          ) : (
            <></>
          )}
           <StyledInput
              label="Order Number"
              type="text"
              name="orderNumber"
              value={orderInfo.orderNumber}
              onChange={(orderNumber) =>
                handleChange({
                  target: { value: orderNumber, name: "orderNumber"},
                })
              }
              error={formErrors.orderNumber}
            />
        </div>
      </div>

      <div className={salesStyle.sectionCard}>
        <SubTitle name="Customer Information" />
        <div className={purchaseStyle.orderInfoCon}>
          <div className={purchaseStyle.supplierCon}>
            <div style={{ display: "flex", alignItems: "flex-end" }}>
              <StyledSelect
                label="Customer*"
                placeHolder="Select customer"
                value={orderInfo.customer}
                selectOptions={options.customerOptions}
                onChange={(customer) =>
                  handleCustomerChange({
                    target: { value: customer },
                  })
                }
                error={formErrors.customer}
              />
              <AddLinkOutlineButton name="New Customer" path="/customer/new" />
            </div>

            {orderInfo.customer ? (
              <div className={salesStyle.customerInfoCard}>
                <p>Contact Infomation</p>
                <TipsText
                  text={orderInfo.firstName + " " + orderInfo.lastName}
                />
                <TipsText text={orderInfo.email} />
                <TipsText text={orderInfo.phone} />
                <div style={{ display: "flex" }}>
                  <div style={{ width: "50%", marginRight: "20px" }}>
                    <p>Postal Address</p>
                    <TipsText text={orderInfo.customerName} />
                    <TipsText
                      text={
                        orderInfo.postalAddress.country
                          ? orderInfo.postalAddress.country.value
                          : ""
                      }
                    />
                    <TipsText text={orderInfo.postalAddress.region} />
                    <TipsText text={orderInfo.postalAddress.city} />
                    <TipsText text={orderInfo.postalAddress.addressLine} />
                    <TipsText text={orderInfo.postalAddress.postalCode} />
                  </div>
                  <div style={{ width: "50%", marginRight: "20px" }}>
                    <p>Billing Address</p>
                    <TipsText text={orderInfo.customerBillingName} />
                    <TipsText
                      text={
                        orderInfo.billingAddress.country
                          ? orderInfo.billingAddress.country.value
                          : ""
                      }
                    />
                    <TipsText text={orderInfo.billingAddress.region} />
                    <TipsText text={orderInfo.billingAddress.city} />
                    <TipsText text={orderInfo.billingAddress.addressLine} />
                    <TipsText text={orderInfo.billingAddress.postalCode} />
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            <SingleRatioButton
              label="This order need shipping"
              name="isNeedShipping"
              isOn={orderInfo.isNeedShipping}
              onChange={(isNeedShipping) =>
                handleChange({
                  target: { value: isNeedShipping, name: "isNeedShipping" },
                })
              }
            />
          </div>

          <div className={purchaseStyle.shippingCon}>
            <StyledSelect
              label="Price Tier"
              placeHolder="Select price tier"
              value={orderInfo.priceTier}
              selectOptions={options.priceTierOptions}
              onChange={(priceTier) =>
                handleChange({
                  target: { value: priceTier, name: "priceTier" },
                })
              }
              error={formErrors.priceTier}
            />
            <StyledSelect
              label="Tax Rate*"
              placeHolder="Select tax rate"
              clearable={false}
              value={orderInfo.taxRate}
              selectOptions={options.taxrateOptions}
              onChange={(taxRate) =>
                handleChange({
                  target: { value: taxRate, name: "taxRate" },
                })
              }
              error={formErrors.taxRate}
            />
            <StyledInput
              label="Currency*"
              value={orderInfo.currency}
              type="text"
              name="currency"
              isReadOnly
            />

            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: "6px" }}>
                <StyledInput
                  label="Payment Term*"
                  type="text"
                  name="paymentTerm"
                  value={orderInfo.paymentTerm}
                  onChange={(paymentTerm) =>
                    handleChange({
                      target: { value: paymentTerm, name: "paymentTerm" },
                    })
                  }
                  error={formErrors.paymentTerm}
                />
              </div>
              <span style={{ marginTop: "26px" }}>
                {" "}
                Days after invoice date
              </span>
            </div>
            {options.projectsOptions.length > 0 ? (
              <StyledSelect
                label="Project"
                placeHolder="Select project"
                value={orderInfo.project}
                selectOptions={options.projectsOptions}
                onChange={(project) =>
                  handleChange({
                    target: { value: project, name: "project" },
                  })
                }
              />
            ) : (
              <></>
            )}

            <StyledTextarea
              label="Notes"
              type="text"
              name="notes"
              value={orderInfo.notes}
              onChange={(notes) =>
                handleChange({
                  target: { value: notes, name: "notes" },
                })
              }
              error={formErrors.notes}
            />
          </div>
        </div>
      </div>

      {/************ order items ***********************/}
      <div className={salesStyle.sectionCard}>
        <div className={purchaseStyle.orderItemsCon}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <SubTitle name="Order Products" />
            <SingleRatioButton
              label="Amounts are tax inclusive"
              name="taxIncluded"
              isOn={orderInfo.taxIncluded}
              onChange={(taxIncluded) =>
                handleChange({
                  target: { value: taxIncluded, name: "taxIncluded" },
                })
              }
            />
          </div>

          {formErrors.orderItemsError ? (
            <p className={commonStyle.tableError}>
              {formErrors.orderItemsError}
            </p>
          ) : (
            <></>
          )}

          {updateStockLoadingOpen ? (
            <Loader mess="Updating stock level, please wait a moment..." />
          ) : calMarginLoadingOpen ? (
            <Loader mess="Calculating sale order margin, please wait a moment..." />
          ) : (
            <></>
          )}

          <div className={salesStyle.orderActionsCon}>
            <div style={{ width: "60%" }}>
              <ProductPicker
                locationId={orderInfo.location ? orderInfo.location.id : ""}
                priceTierId={orderInfo.priceTier ? orderInfo.priceTier.id : ""}
                onSelect={getProductFromPicker}
                requiredType={"saleProduct"}
              />
            </div>

            <div className={salesStyle.orderItemsActionCon}>
              <BarcodeScanButton name="Scan Barcode" action={openScanModal} />

              {userDetailsSuccess ? (
                userDetails.data.permissions.includes(
                  "view_sale_order_margin"
                ) ? (
                  <div
                    onClick={calculateOrderMargin}
                    className={salesStyle.calMarginBtn}
                  >
                    Calculate Margin
                  </div>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}

              <div
                onClick={() =>
                  updateProductsStock(
                    orderInfo.location ? orderInfo.location.id : ""
                  )
                }
                className={salesStyle.updateStockBtn}
              >
                Check Stock Level
              </div>
            </div>
          </div>

          <div className={commonStyle.longDataTable}>
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Product Name</th>
                  <th>Unit</th>
                  <th>Unit Price</th>
                  <th>Discount</th>
                  <th>Net Price</th>
                  <th>Quantity</th>
                  <th></th>
                  <th>Total Price</th>
                  <th
                    style={{
                      margin: "auto",
                      borderLeft: "1px solid var(--border-color)",
                    }}
                  ></th>
                </tr>
              </thead>
              <tbody>
                {orderItems.map((item, index1) => {
                  return (
                    <tr key={index1}>
                      <td>
                        {item.variantSku && (
                          <VariantSkuTag name={item.variantSku} />
                        )}
                      </td>

                      {item.type !== "package" ? (
                        <td className={purchaseStyle.productNameCol}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {item.type === "packageProduct" ? (
                              <div className={salesStyle.packageProductTag}>
                                Package
                              </div>
                            ) : (
                              <></>
                            )}
                            <div>
                              <div>
                                {item.productName}
                                {item.variant ? (
                                  <VariantTag name={item.variant} />
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div style={{ marginTop: "4px" }}>
                                {Number(item.stockOnHand) > 0 ? (
                                  <span className={salesStyle.stockTag}>
                                    {convtQuantity(item.stockOnHand)} stock on
                                    hand
                                  </span>
                                ) : (
                                  <span className={salesStyle.noStockTag}>
                                    No Stock Available
                                  </span>
                                )}
                              </div>
                            </div>
                            {item.editable ? (
                              <div style={{ marginLeft: "20px" }}>
                                <EditButton
                                  action={() =>
                                    editPackageProduct(item, index1)
                                  }
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </td>
                      ) : (
                        <td className={salesStyle.productPackageNameCol}>
                          <div className={salesStyle.productPackageName}>
                            {item.packageName}
                          </div>
                        </td>
                      )}
                      <td>{item.unitName}</td>

                      <td
                        style={{
                          width: "10%",
                        }}
                      >
                        {item.type !== "packageProduct" ? (
                          <div>
                            {userDetailsSuccess ? (
                              userDetails.data.permissions.includes(
                                "change_sale_price"
                              ) ? (
                                <NoBorderInput
                                  type="text"
                                  name="unitPrice"
                                  value={item.unitPrice}
                                  onChange={(unitPrice) =>
                                    handleOrderItemChange({
                                      type: "price",
                                      target: {
                                        value: unitPrice,
                                        key: "unitPrice",
                                        index: index1,
                                      },
                                    })
                                  }
                                />
                              ) : (
                                <PriceText num={item.unitPrice} />
                              )
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td
                        style={{
                          width: "10%",
                        }}
                      >
                        {item.type !== "packageProduct" ? (
                          <NoBorderPercentInput
                            type="text"
                            name="discount"
                            value={item.discount}
                            onChange={(discount) =>
                              handleOrderItemChange({
                                type: "price",
                                target: {
                                  value: discount,
                                  key: "discount",
                                  index: index1,
                                },
                              })
                            }
                          />
                        ) : (
                          <></>
                        )}
                      </td>
                      <td>
                        {item.type !== "packageProduct" ? (
                          <div>
                            {item.discount ? (
                              <PriceText
                                num={convtPrice(
                                  (1 - Number(item.discount) / 100) *
                                    Number(item.unitPrice)
                                )}
                              />
                            ) : (
                              <PriceText num={item.unitPrice} />
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td
                        style={{
                          width: "10%",
                        }}
                      >
                        {item.type !== "package" ? (
                          <NoBorderInput
                            type="text"
                            name="quantity"
                            value={item.quantity}
                            onChange={(quantity) =>
                              handleOrderItemChange({
                                type: "quantity",
                                target: {
                                  value: quantity,
                                  key: "quantity",
                                  index: index1,
                                },
                              })
                            }
                          />
                        ) : (
                          <NoBorderInput
                            type="text"
                            name="quantity"
                            value={item.quantity}
                            onChange={(quantity) =>
                              handlePackageQuantityChange({
                                type: "quantity",
                                target: {
                                  value: quantity,
                                  key: "quantity",
                                  index: index1,
                                  packageId: item.packageId,
                                },
                              })
                            }
                          />
                        )}
                      </td>
                      <td>
                        {item.serialized === 1 ? (
                          <SerialNumberSelect
                            btnName="Select Serial Number"
                            famid={item.famid}
                            variantCode={item.variantCode}
                            locationId={orderInfo.location.id}
                            prodName={item.productName}
                            rentalStartTime={""}
                            rentalEndTime={""}
                            requiredQty={item.quantity}
                            index={index1}
                            onSave={getSerialNumbersStockReduce}
                            initialNumbers={
                              item.consumeSerialNumbers
                                ? item.consumeSerialNumbers
                                : []
                            }
                          />
                        ) : (
                          <></>
                        )}
                        {item.batchTracked === 1 ? (
                          <BatchNumberSelect
                            btnName="Select Batch Number"
                            prodName={item.productName}
                            famid={item.famid}
                            variantCode={item.variantCode}
                            locationId={orderInfo.location.id}
                            rentalStartTime={""}
                            rentalEndTime={""}
                            requiredQty={item.quantity}
                            index={index1}
                            onSave={getBatchNumbersStockReduce}
                            initialNumbers={
                              item.consumeBatchNumbers
                                ? item.consumeBatchNumbers
                                : []
                            }
                          />
                        ) : (
                          <></>
                        )}
                      </td>

                      <td
                        style={{
                          width: "10%",
                          maxWidth: "15%",
                        }}
                      >
                        {item.type !== "packageProduct" ? (
                          <PriceText num={item.totalPrice} />
                        ) : (
                          <></>
                        )}
                      </td>

                      <td
                        style={{
                          borderLeft: "1px solid var(--border-color)",
                          width: "4%",
                        }}
                      >
                        {item.type !== "packageProduct" ? (
                          <XDeleteButton
                            action={() =>
                              deleteOrderItem(index1, item.type, item.packageId)
                            }
                          />
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="7" className={commonStyle.tableFootName}>
                    Total
                  </td>

                  <td>
                    <PriceText num={orderSummary.productsTotal} />
                  </td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
            <div className={purchaseStyle.summaryWrapper}>
              <div className={purchaseStyle.costCon}>
                {/* additional cost */}
                <SubTitle name="Additional Charge" />
                <table className={commonStyle.pageTable}>
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>Price</th>
                      <th
                        style={{
                          borderLeft: "1px solid var(--border-color)",
                        }}
                      ></th>
                    </tr>
                  </thead>

                  <tbody>
                    {addtionalCosts.map((c, index2) => {
                      return (
                        <tr key={index2}>
                          <td
                            className={purchaseStyle.productNameCol}
                            style={{
                              width: "60%",
                            }}
                          >
                            <NoBorderInput
                              type="text"
                              name="costName"
                              value={c.costName}
                              placeHolder="Eg.shipping cost / tax "
                              onChange={(costName) =>
                                handleCostChange({
                                  target: {
                                    value: costName,
                                    key: "costName",
                                    index: index2,
                                  },
                                })
                              }
                            />
                          </td>

                          <td
                            style={{
                              width: "36%",
                            }}
                          >
                            <NoBorderInput
                              type="text"
                              name="cost"
                              value={c.cost}
                              onChange={(cost) =>
                                handleCostChange({
                                  type: "price",
                                  target: {
                                    value: cost,
                                    key: "cost",
                                    index: index2,
                                  },
                                })
                              }
                            />
                          </td>

                          <td
                            style={{
                              borderLeft: "1px solid var(--border-color)",
                              width: "4%",
                            }}
                          >
                            <XDeleteButton
                              action={() => deleteCostItem(index2)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className={commonStyle.tableFootName}>Total</td>

                      <td>
                        <PriceText num={orderSummary.additionalCostTotal} />
                      </td>
                      <td></td>
                    </tr>
                  </tfoot>
                </table>
                <AddItem
                  name="Add Additional Charge"
                  action={addAdditionalCost}
                />
              </div>

              <div className={purchaseStyle.summaryCon}>
                {/* summary total price */}
                <SubTitle name="Order Summary" />
                <table
                  className={commonStyle.summaryTable}
                  style={{ marginTop: "12px" }}
                >
                  {orderInfo.taxIncluded ? (
                    <tbody>
                      <tr>
                        <td className={commonStyle.tableFootName}>
                          Products Total
                        </td>

                        <td style={{ width: "30%" }}>
                          <PriceText num={orderSummary.productsTotal} />
                        </td>
                      </tr>
                      <tr>
                        <td className={commonStyle.tableFootName}>
                          Additional Charge Total
                        </td>
                        <td style={{ width: "30%" }}>
                          <PriceText num={orderSummary.additionalCostTotal} />
                        </td>
                      </tr>

                      <tr className={commonStyle.summaryTableTotalRow}>
                        <td className={commonStyle.summaryTableTotalName}>
                          Order Total{" "}
                          <span className={commonStyle.taxText}>Tax Incl.</span>
                        </td>

                        <td className={commonStyle.summaryTableTotalValue}>
                          {orderSummary.productsTotal ? (
                            <PriceText
                              num={
                                Number(orderSummary.productsTotal) +
                                Number(orderSummary.additionalCostTotal)
                              }
                            />
                          ) : (
                            "0.00"
                          )}{" "}
                          {orderInfo.currency}
                        </td>
                      </tr>

                      <tr className={commonStyle.summaryTableTotalRow}>
                        <td className={commonStyle.tableFootName}>
                          Total{" "}
                          <span className={commonStyle.taxText}>Tax Excl.</span>
                        </td>

                        <td style={{ width: "30%" }}>
                          {orderInfo.taxRate ? (
                            <PriceText
                              num={convtPrice(
                                (Number(orderSummary.productsTotal) +
                                  Number(orderSummary.additionalCostTotal)) /
                                  (1 + Number(orderInfo.taxRate.rate) / 100)
                              )}
                            />
                          ) : (
                            "0.00"
                          )}
                        </td>
                      </tr>

                      <tr className={commonStyle.summaryTableTotalRow}>
                        <td className={commonStyle.tableFootName}>
                          Tax
                          <span className={commonStyle.taxText}>
                            {orderInfo.taxRate
                              ? "-" + orderInfo.taxRate.value
                              : ""}
                          </span>
                        </td>

                        <td style={{ width: "30%" }}>
                          {orderInfo.taxRate ? (
                            <PriceText
                              num={convtPrice(
                                Number(orderSummary.productsTotal) +
                                  Number(orderSummary.additionalCostTotal) -
                                  (Number(orderSummary.productsTotal) +
                                    Number(orderSummary.additionalCostTotal)) /
                                    (1 + Number(orderInfo.taxRate.rate) / 100)
                              )}
                            />
                          ) : (
                            "0.00"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className={commonStyle.tableFootName}>
                          Products Total
                        </td>

                        <td style={{ width: "30%" }}>
                          <PriceText num={orderSummary.productsTotal} />
                        </td>
                      </tr>
                      <tr>
                        <td className={commonStyle.tableFootName}>
                          Additional Charge Total
                        </td>
                        <td style={{ width: "30%" }}>
                          <PriceText num={orderSummary.additionalCostTotal} />
                        </td>
                      </tr>

                      <tr className={commonStyle.summaryTableTotalRow}>
                        <td className={commonStyle.tableFootName}>
                          Total{" "}
                          <span className={commonStyle.taxText}>Tax Excl.</span>
                        </td>

                        <td style={{ width: "30%" }}>
                          {orderInfo.taxRate ? (
                            <PriceText
                              num={
                                Number(orderSummary.productsTotal) +
                                Number(orderSummary.additionalCostTotal)
                              }
                            />
                          ) : (
                            "0.00"
                          )}
                        </td>
                      </tr>

                      <tr className={commonStyle.summaryTableTotalRow}>
                        <td className={commonStyle.tableFootName}>
                          Tax
                          <span className={commonStyle.taxText}>
                            {orderInfo.taxRate
                              ? "-" + orderInfo.taxRate.value
                              : ""}
                          </span>
                        </td>

                        <td style={{ width: "30%" }}>
                          {orderInfo.taxRate ? (
                            <PriceText
                              num={convtPrice(
                                (Number(orderSummary.productsTotal) +
                                  Number(orderSummary.additionalCostTotal)) *
                                  (Number(orderInfo.taxRate.rate) / 100)
                              )}
                            />
                          ) : (
                            "0.00"
                          )}
                        </td>
                      </tr>

                      <tr className={commonStyle.summaryTableTotalRow}>
                        <td className={commonStyle.summaryTableTotalName}>
                          Order Total{" "}
                          <span className={commonStyle.taxText}>Tax Incl.</span>
                        </td>

                        <td className={commonStyle.summaryTableTotalValue}>
                          {orderSummary.productsTotal && orderInfo.taxRate ? (
                            <PriceText
                              num={convtPrice(
                                Number(orderSummary.productsTotal) +
                                  Number(orderSummary.additionalCostTotal) +
                                  (Number(orderSummary.productsTotal) +
                                    Number(orderSummary.additionalCostTotal)) *
                                    (Number(orderInfo.taxRate.rate) / 100)
                              )}
                            />
                          ) : (
                            "0.00"
                          )}{" "}
                          {orderInfo.currency}
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex items-start justify-between">
        <div className="w-1/2">
          <GreenButton
            name="Save as Draft"
            action={() => checkValidation("Draft")}
            fullWidth={false}
            marginRight={true}
          />
          <GreenButton
            name="Save as Back Order"
            action={() => checkValidation("Back Order")}
            fullWidth={false}
            marginRight={false}
          />
        </div>

        <div className="w-fit flex flex-col items-end">
          {userDetails.data.permissions.includes("approve_sale_order") ? (
            <div className="w-fit">
              <SimpleBlueButton
                name="Save as Approved"
                action={() => checkValidation("Approved")}
              />
            </div>
          ) : (
            <></>
          )}
          <p className={commonStyle.saveHintText}>
            *** Approved status order can not be edited ***
          </p>
        </div>
      </div>
    </div>
  );
}
