import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import commonStyle from "../../style/commonStyle.module.css";
import {  Loader, TextErrorMessage } from "../../components/viewComponents";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function Unsubscribe() {
  const [searchParams] = useSearchParams();
  const useremail = searchParams.get("email");

  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  useEffect(() => {
    const unsubscribe = () => {
      const emailvalues = {
        email: useremail,
      };
      try {
        setReqStatus((prevStatus) => {
          return { ...prevStatus, loading: true };
        });

        axios
          .post(SERVER_URL + `/email_marketing/unsubscribe`, emailvalues)
          .then(async (response) => {
            if (response.data.success) {
              setReqStatus((prevStatus) => {
                return { ...prevStatus, loading: false, success: true };
              });
            } else {
              setReqStatus((prevStatus) => {
                return {
                  ...prevStatus,
                  loading: false,
                  error: "Unsubscribe failed, please try again later.",
                };
              });
            }
          })
          .catch((err) => {
            setReqStatus((prevStatus) => {
              return {
                ...prevStatus,
                loading: false,
                error: "Connection error, please try again later.",
              };
            });
          });
      } catch (error) {
        setReqStatus((prevStatus) => {
          return {
            ...prevStatus,
            loading: false,
            error: "Connection error, please try again later.",
          };
        });
      }
    };
    unsubscribe();
  }, []);

  return (
    <div className={commonStyle.container}>
    
      <div style={{padding:"20px"}}>
        {reqStatus.loading ? (
          <Loader mess="Requesting..." />
        ) : reqStatus.success ? (
          <div>
               <p>{useremail},</p>
               <p>Unsubscribe Successfully!</p>
            </div>
       
        ) : reqStatus.error ? (
          <TextErrorMessage mess={reqStatus.error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
