import axios from "axios";
import { authMiddleware } from "./authMiddleware";
import { convtPrice } from "../functions/functions";
import { CREATED_PO_DATA_FAIL, CREATED_PO_DATA_REQUEST, CREATED_PO_DATA_SUCCESS, FAV_GROUP_FAIL, FAV_GROUP_REQUEST, FAV_GROUP_SUCCESS, FAV_GROUP_UPDATE_FAIL, FAV_GROUP_UPDATE_REQUEST, FAV_GROUP_UPDATE_SUCCESS, INITIAL_SETTINGS_FAIL, INITIAL_SETTINGS_REQUEST, INITIAL_SETTINGS_SUCCESS, INV_BOARD_DATA_FAIL, INV_BOARD_DATA_REQUEST, INV_BOARD_DATA_SUCCESS, SALE_BOARD_DATA_FAIL, SALE_BOARD_DATA_REQUEST, SALE_BOARD_DATA_SUCCESS, SALE_TRENDS_DATA_FAIL, SALE_TRENDS_DATA_REQUEST, SALE_TRENDS_DATA_SUCCESS, STOCK_ALERT_PRODUCT_FAIL, STOCK_ALERT_PRODUCT_REQUEST, STOCK_ALERT_PRODUCT_SUCCESS, TOP_SALE_PRODUCT_FAIL, TOP_SALE_PRODUCT_REQUEST, TOP_SALE_PRODUCT_SUCCESS, TRAIL_TASK_FAIL, TRAIL_TASK_REQUEST, TRAIL_TASK_SUCCESS } from "../constants/dashboardConstants";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const initialSettingsRequest = () => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    dispatch({ type: INITIAL_SETTINGS_REQUEST });
    axios
      .get(SERVER_URL + `/dashboard/initial_settings`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: INITIAL_SETTINGS_SUCCESS, payload: response.data.data });
        } else {
          throw Object.assign(
            new Error("Get tinitial settings failed, please try again later."),
            { code: 701 }
         );
          
        }
      })
      .catch((err) => {
        dispatch({
          type: INITIAL_SETTINGS_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: INITIAL_SETTINGS_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const trialTaskRequest = () => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    dispatch({ type: TRAIL_TASK_REQUEST });
    axios
      .get(SERVER_URL + `/dashboard/trial_tasks`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: TRAIL_TASK_SUCCESS, payload: response.data.data });
        } else {
          throw Object.assign(
            new Error("Get trial task status failed, please try again later."),
            { code: 701 }
         );
          
        }
      })
      .catch((err) => {
        dispatch({
          type: TRAIL_TASK_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: TRAIL_TASK_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const updateFavGroupRequest = (values) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();
  
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      dispatch({ type: FAV_GROUP_UPDATE_REQUEST });
      axios
        .post(SERVER_URL + `/dashboard/fav_group/update`, values, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: FAV_GROUP_UPDATE_SUCCESS });
            dispatch(favGroupRequest());
          } else {
            throw Object.assign(
              new Error("Update favourite features failed, please try again later."),
              { code: 701 }
           );
            
          }
        })
        .catch((err) => {
          dispatch({
            type: FAV_GROUP_UPDATE_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: FAV_GROUP_UPDATE_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

  export const favGroupRequest = () => async (dispatch, getState) => {
    
    const {
      userLogin: { userInfo },
    } = getState();
  
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
  
      dispatch({ type: FAV_GROUP_REQUEST });
      axios
        .get(SERVER_URL + `/dashboard/fav_group`, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {

            dispatch({
              type: FAV_GROUP_SUCCESS,
              payload: response.data.data,
            });
          } else {
            throw Object.assign(
              new Error("Request favourite features failed, please try again later."),
              { code: 702 }
           );
          }
        })
        .catch((err) => {
          dispatch({
            type: FAV_GROUP_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: FAV_GROUP_FAIL,
        payload: "Connection error, please try again later",
      });
    }
};

export const invBoardDataRequest = () => async (dispatch, getState) => {
    
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    dispatch({ type: INV_BOARD_DATA_REQUEST });
    axios
      .get(SERVER_URL + `/dashboard/inventory_summary`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({
            type: INV_BOARD_DATA_SUCCESS,
            payload: response.data.data,
          });
        } else {
          throw Object.assign(
            new Error("Request inventory overview data failed, please try again later."),
            { code: 702 }
         );
        }
      })
      .catch((err) => {
        dispatch({
          type: INV_BOARD_DATA_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: INV_BOARD_DATA_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const saleBoardDataRequest = (todayTimeStamp) => async (dispatch, getState) => {
    
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    dispatch({ type: SALE_BOARD_DATA_REQUEST });
    axios
      .get(SERVER_URL + `/dashboard/sale_real_time?date=${todayTimeStamp}`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({
            type: SALE_BOARD_DATA_SUCCESS,
            payload: response.data.data,
          });
        } else {
          throw Object.assign(
            new Error("Request sales data failed, please try again later."),
            { code: 702 }
         );
        }
      })
      .catch((err) => {
        dispatch({
          type: SALE_BOARD_DATA_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: SALE_BOARD_DATA_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const saleTrendsDataRequest = (startTime, endTime) => async (dispatch, getState) => {
  
  const {
    userLogin: { userInfo },
    userDetail: { userDetails}
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {

    dispatch({ type: SALE_TRENDS_DATA_REQUEST });
    axios
      .get(SERVER_URL + `/dashboard/sale_trend?startTime=${startTime}&endTime=${endTime}&timeZone=${userDetails.data.orgInfo.time_zone}&dateFormat=${userDetails.data.orgInfo.date_format}`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          let revenues = response.data.data;
          revenues.forEach(element => {
            element.revenue = convtPrice(element.revenue);
            element.cogs = convtPrice(element.cogs);
            element.profit = convtPrice(element.profit);
          });

          dispatch({
            type: SALE_TRENDS_DATA_SUCCESS,
            payload: revenues,
          });
        } else {
          throw Object.assign(
            new Error("Request sales trends data failed, please try again later."),
            { code: 702 }
         );
        }
      })
      .catch((err) => {
        dispatch({
          type: SALE_TRENDS_DATA_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: SALE_TRENDS_DATA_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const createdPurchaseOrderRequest = () => async (dispatch, getState) => {
    
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    dispatch({ type: CREATED_PO_DATA_REQUEST });
    axios
      .get(SERVER_URL + `/dashboard/created_purchase_order`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({
            type: CREATED_PO_DATA_SUCCESS,
            payload: response.data.data,
          });
        } else {
          throw Object.assign(
            new Error("Request created PO data failed, please try again later."),
            { code: 702 }
         );
        }
      })
      .catch((err) => {
        dispatch({
          type: CREATED_PO_DATA_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: CREATED_PO_DATA_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const topSaleProductsRequest = (startTime, endTime, rangeType) => async (dispatch, getState) => {
    
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {

    dispatch({ type: TOP_SALE_PRODUCT_REQUEST });
    axios
      .get(SERVER_URL + `/dashboard/top_sale_products?startTime=${startTime}&endTime=${endTime}`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          let products = response.data.data;
          // range products
          if(rangeType === "soldQty"){
            products.sort((a, b) => {
              return b.stockChangeTotal - a.stockChangeTotal;
            })
          }else if(rangeType === "soldRevenue") {
            products.sort((a, b) => {
              return b.incomeTotal - a.incomeTotal;
            })
          } else {
            products.sort((a, b) => {
              return b.stockChangeTotal - a.stockChangeTotal;
            })
          }

          dispatch({
            type: TOP_SALE_PRODUCT_SUCCESS,
            payload: products,
          });
        } else {
          throw Object.assign(
            new Error("Request top sale products failed, please try again later."),
            { code: 702 }
         );
        }
      })
      .catch((err) => {
        dispatch({
          type: TOP_SALE_PRODUCT_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: TOP_SALE_PRODUCT_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const stockAlertProductsRequest = () => async (dispatch, getState) => {
    
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {

    dispatch({ type: STOCK_ALERT_PRODUCT_REQUEST });
    axios
      .get(SERVER_URL + `/dashboard/stock_alert_products`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          let products = response.data.data;
             // range products
              products.sort((a, b) => {
                return a.stockLevel - b.stockLevel;
              })
           

          dispatch({
            type: STOCK_ALERT_PRODUCT_SUCCESS,
            payload: products,
          });
        } else {
          throw Object.assign(
            new Error("Request stock alert products failed, please try again later."),
            { code: 702 }
         );
        }
      })
      .catch((err) => {
        dispatch({
          type: STOCK_ALERT_PRODUCT_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: STOCK_ALERT_PRODUCT_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

