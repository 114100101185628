import axios from "axios";
import React, { useState } from "react";
import { FaRegCheckSquare } from "react-icons/fa";
import { MdOutlineDoneAll } from "react-icons/md";
import { useSelector } from "react-redux";
import {
  AddLinkOutlineButton,
  GreenButton,
  SimpleBlueButton,
  SingleRatioButton
} from "../../../components/editComponents";
import {
  NoBorderInput,
  StyledInput,
  StyledSelect,
  StyledTextarea,
} from "../../../components/inputFields";
import {
  ContentModal,
  Loader,
  ProductImage,
  QuestionMark,
  SubTitle,
  VariantTag,
  XeroAccountMappingTip,
  XeroSmallLogo
} from "../../../components/viewComponents";
import { productTypeOptions } from "../../../constantsData/productType";
import { validatePrice, validateQuantity } from "../../../functions/validate";
import commonStyle from "../../../style/commonStyle.module.css";
import inventoryStyle from "../inventoryStyle.module.css";
import VariantForm from "./VariantForm";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function ProductDetailsForm({
  initialBasicInfo,
  initialVariants,
  onSave,
  btnName,
  type,
}) {
  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  const { userInfo } = useSelector((state) => state.userLogin);
  const { loading, options, success } = useSelector(
    (state) => state.productOptions
  );
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  const [basicInfo, setBasicInfo] = useState(initialBasicInfo);
  const [variants, setVariants] = useState(initialVariants);
  const [formErrors, setFormErrors] = useState({});
  const [attrModal, setAttrModel] = useState(false);

  const [preImage, setPreImage] = useState();
  const [uploadLoading, setUploadLoading] = useState(false);

  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////
  const handleChange = (e) => {
    const { name, value } = e.target;
    setBasicInfo({ ...basicInfo, [name]: value });
  };

  const handleSerializedChange = (e) => {
    const { name, value } = e.target;
    if (value) {
      if (name === "serialized") {
        setBasicInfo({ ...basicInfo, [name]: value, batchTracked: !value });
      }

      if (name === "batchTracked") {
        setBasicInfo({ ...basicInfo, [name]: value, serialized: !value });
      }
    } else {
      setBasicInfo({ ...basicInfo, [name]: value });
    }
  };

  const closeAttrModel = () => {
    setAttrModel(false);
  };

  const saveAttrValues = (in_attrs) => {
    setAttrModel(false);
    setBasicInfo({ ...basicInfo, attrs: in_attrs });

    // e.g. ['size', 'color', ...]
    let attributes = in_attrs.map((elem) => elem.attrName);
    attributes.sort();

    // prepare for doing full combination
    let fullCombItems = {}; // e.g. {'size': ['s', 'l'], 'color': ['red']}
    attributes.forEach((attr) => {
      fullCombItems[attr] = [];
    });
    in_attrs.forEach((attr) => {
      for (let opt of attr.attrOptions) {
        fullCombItems[attr.attrName].push(opt.optionName);
      }
    });

    let resVariants = []; // the finial output
    resVariants.push({
      variantSku: "",
      barcode: "",
      salePrice: 0,
      latestCost: 0,
      reorderLevel: 0,
      stockLevel: 0,
    });
    for (let attr of attributes) {
      // use each of the old item as a template,
      // copy each template N times (N = option lengh)
      // ... to create N new items with the new attribute
      let resVariantsNew = [];
      for (let opt of fullCombItems[attr]) {
        for (let variantItem of resVariants) {
          resVariantsNew.push({ [attr]: opt, ...variantItem });
        }
      }
      resVariants = resVariantsNew;
    }

    // build cache from prev data
    let cache = {};
    const getCacheKey = (variantItem) => {
      let key = "";
      for (let attrName of attributes) {
        key += "[" + variantItem[attrName] + "]";
      }
      return key;
    };
    variants.forEach((elem) => {
      cache[getCacheKey(elem)] = elem;
    });
    //apply cache to the new data
    for (let i = 0; i < resVariants.length; ++i) {
      let key = getCacheKey(resVariants[i]);
      if (key in cache) {
        resVariants[i] = cache[key];
      }
    }
    setVariants(resVariants);
  };

  ///////////////////////////////////////////////////////
  // handle change when upload image
  const handleVariantsChange = (e) => {
    const { type, key, value, index } = e.target;

    if (type === "quantity") {
      const validated = validateQuantity(value);
      if (!validated) return;
    }

    if (type === "price") {
      const validated = validatePrice(value);
      if (!validated) return;
    }

    let newState = [...variants];

    newState[index][key] = value;
    setVariants(newState);
  };

  ///////////////////////////////////////////////////////
  // handle change when upload image
  const handleImageChange = (e) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("image", file);

      // if is edit product, append product id in the file
      formData.append("productId", "0");

      try {
        setUploadLoading(true);
        axios
          .post(SERVER_URL + `/product/image/update`, formData, config)
          .then(async (response) => {
            setUploadLoading(false);
            if (response.data.success) {
              setPreImage(response.data.data.url);
              setBasicInfo({ ...basicInfo, image: response.data.data.url });
            } else {
              setFormErrors({
                image: "Update product image failed, please try again later",
              });
            }
          })
          .catch((err) => {
            setUploadLoading(false);
            setFormErrors({
              image: "Update product image failed, please try again later",
            });
          });
      } catch (error) {
        setUploadLoading(false);
        setFormErrors({ image: "Connection error, please try again later" });
      }
    }
  };
  /////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////

  const bulkApplyToAll = () => {
    let variantsCopy = [...variants];
    let indexVariant = variantsCopy[0];

    variantsCopy.forEach((x) => {
      x.reorderLevel = indexVariant.reorderLevel;
      x.salePrice = indexVariant.salePrice;
      x.latestCost = indexVariant.latestCost;
    });

    setVariants(variantsCopy);
  };
  /////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////
  const checkValidation = (isDraft) => {
    const hasErrors = validate(basicInfo);

    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});

      onSave(
        {
          basicInfo: basicInfo,
          variants: variants,
        },
        isDraft
      );
    } else {
      setFormErrors(hasErrors);
    }
  };

  // check input validation
  const validate = (values) => {
    const errors = {};

    if (!values.type) {
      errors.type = "Product type is required!";
    }

    if (!values.productName) {
      errors.productName = "Product name is required!";
    }

    if (!values.unit) {
      errors.unit = "Unit is required!";
    }

    return errors;
  };

  return (
    <div>
      <ContentModal
        title="Edit Product Variations"
        isModalOpen={attrModal}
        closeModal={closeAttrModel}
        content={
          <VariantForm
            values={basicInfo.attrs}
            onSubmit={saveAttrValues}
            editType={type}
          />
        }
      />
      {loading ? (
        <Loader mess="Requesting data..." />
      ) : success ? (
        <div>
          {/* basic info con */}
          <div className={inventoryStyle.basicInfo}>
            <XeroAccountMappingTip pageName="productDetails" />
            <SubTitle name="Product Basic Details" />
            <div className={inventoryStyle.basicInfoCol}>
              <div className={inventoryStyle.col1}>
                {type === "editActive" ? (
                  <StyledInput
                    label="Product Type"
                    type="text"
                    name="type"
                    value={basicInfo.type.label}
                    isReadOnly
                  />
                ) : (
                  <StyledSelect
                    label="Product Type*"
                    placeHolder="Select type"
                    selectOptions={productTypeOptions}
                    value={basicInfo.type}
                    onChange={(type) =>
                      handleChange({
                        target: { value: type, name: "type" },
                      })
                    }
                    error={formErrors.type}
                  />
                )}

                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <StyledSelect
                    label="Category"
                    placeHolder="Select category"
                    selectOptions={options.category}
                    value={basicInfo.category}
                    onChange={(category) =>
                      handleChange({
                        target: { value: category, name: "category" },
                      })
                    }
                    error={formErrors.category}
                  />
                  <AddLinkOutlineButton path="/inventory/product-categories" />
                </div>
                <StyledInput
                  label="Product Name*"
                  type="text"
                  name="productName"
                  autofocus="autofocus"
                  value={basicInfo.productName}
                  onChange={(productName) =>
                    handleChange({
                      target: { value: productName, name: "productName" },
                    })
                  }
                  error={formErrors.productName}
                />
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <StyledSelect
                    label="Unit*"
                    placeHolder="Select unit"
                    value={basicInfo.unit}
                    selectOptions={options.units}
                    onChange={(unit) =>
                      handleChange({
                        target: { value: unit, name: "unit" },
                      })
                    }
                    error={formErrors.unit}
                  />
                  <AddLinkOutlineButton path="/inventory/product-units" />
                </div>

                <StyledSelect
                  label="Default Supplier"
                  placeHolder="Select supplier"
                  value={basicInfo.supplier}
                  selectOptions={options.suppliers}
                  onChange={(supplier) =>
                    handleChange({
                      target: { value: supplier, name: "supplier" },
                    })
                  }
                />
                <StyledTextarea
                  label="Description"
                  type="text"
                  name="description"
                  value={basicInfo.description}
                  onChange={(description) =>
                    handleChange({
                      target: { value: description, name: "description" },
                    })
                  }
                  error={formErrors.description}
                />
                {basicInfo.hasBatches ? (
                  <div>
                  <div className="mt-2 flex items-center">
                    <FaRegCheckSquare className="text-gray-500 w-4 h-4 mr-1" />
                    <span>
                      {basicInfo.serialized
                        ? "Product is serialized"
                        : basicInfo.batchTracked
                        ? "Product is bath-tracked"
                        : "Unknow"}
                    </span>
                  </div>
                  <p className="mt-2 text-gray">The product has existing batch or serial records and cannot be changed to a non-tracked product.</p>
                  </div>
                ) : (
                  <>
                    <SingleRatioButton
                      label="Serialized"
                      name="hasAttrs"
                      isOn={basicInfo.serialized}
                      onChange={(serialized) =>
                        handleSerializedChange({
                          target: { value: serialized, name: "serialized" },
                        })
                      }
                    />

                    <SingleRatioButton
                      label="Batch Tracked"
                      name="hasAttrs"
                      isOn={basicInfo.batchTracked}
                      onChange={(batchTracked) =>
                        handleSerializedChange({
                          target: { value: batchTracked, name: "batchTracked" },
                        })
                      }
                    />
                  </>
                )}
              </div>

              <div className={inventoryStyle.col3}>
                <div className={inventoryStyle.imageWrapper}>
                  <ProductImage
                    alt="product preview"
                    src={
                      preImage
                        ? preImage
                        : basicInfo.image
                        ? basicInfo.image
                        : ""
                    }
                  />

                  {uploadLoading ? (
                    <Loader mess="Uploading..." />
                  ) : (
                    <div className={inventoryStyle.uploadBtnCon}>
                      <label className={inventoryStyle.uploadBtn}>
                        Upload Product Image
                        <input
                          type="file"
                          name="logo"
                          onChange={handleImageChange}
                        />
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* basic info con end*/}
          {userDetailsSuccess &&
          userDetails.data.orgInfo.xeroConnected &&
          userDetails.data.orgInfo.xeroConfigured ? (
            <div className={inventoryStyle.basicInfo}>
              <SubTitle name="Manage Inventory in Xero" />
              {type === "editActive" && basicInfo.hasStockOnHand ? (
                <>
                  {basicInfo.originalManaged ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "12px",
                        }}
                      >
                        <XeroSmallLogo />
                        <span style={{ width: "auto", fontWeight: "700" }}>
                          Xero Tracked Inventory Item
                        </span>
                      </div>
                      <p style={{ marginTop: "16px" }}>
                        This product will be synchronized with your Xero system
                        as a "Tracked Inventory Item." Additionally, any changes
                        to this product's stock levels will be updated in your
                        Xero account and displayed in your "Inventory" account.
                      </p>
                      <p style={{ marginTop: "16px" }}>
                        ! This product can not be untracked as it appears has
                        stock on hand.
                      </p>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "12px",
                        }}
                      >
                        <span style={{ width: "auto", fontWeight: "700" }}>
                          Xero Non-Tracked Inventory Item
                        </span>
                      </div>

                      <p style={{ marginTop: "16px" }}>
                        ! This product can not change to tracked as it appears
                        has stock on hand.
                      </p>
                    </>
                  )}
                </>
              ) : (
                <>
                  <SingleRatioButton
                    label="Xero Tracked Inventory Item"
                    name="hasAttrs"
                    isOn={basicInfo.managed}
                    onChange={(managed) =>
                      handleChange({
                        target: { value: managed, name: "managed" },
                      })
                    }
                  />
                  <p style={{ marginTop: "16px" }}>
                    By checking this box, the product will be synchronized with
                    your Xero system as a "Tracked Inventory Item."
                    Additionally, any changes to this product's stock levels
                    will be updated in your Xero account and displayed in your
                    "Inventory" account.
                  </p>
                </>
              )}
            </div>
          ) : (
            <></>
          )}

          {/************ Attrs ***********************/}

          <div className={inventoryStyle.basicInfo}>
            <SubTitle name="Product Transaction Details" />

            {basicInfo.hasAttrs ? (
              <>
                {/************ Variants table ***********************/}
                <div>
                  <table className={inventoryStyle.dynTable}>
                    <thead>
                      <tr>
                        {basicInfo.attrs.length > 0 ? (
                          basicInfo.attrs.map((item, index1) => {
                            return <th key={index1}>{item.attrName}</th>;
                          })
                        ) : (
                          <></>
                        )}
                        <th>Code</th>
                        <th>Barcode</th>
                        <th>Re-order Level</th>
                        <th>Sale Price</th>
                        <th>
                          {" "}
                          <QuestionMark
                            title="Latest Cost"
                            text="The latest tax exclusive cost of this product, it is not a fixed value, will be automatically updated when new stock added into system."
                          />
                        </th>
                        <th>
                          <QuestionMark
                            title="Action"
                            text="Click [Apply to all] will apply re-order level, sale price, latest cost to all the variantions row."
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {variants.map((item, index2) => (
                        <tr key={index2}>
                          {basicInfo.attrs.length > 0 ? (
                            basicInfo.attrs.map((key, index3) => {
                              return (
                                <td key={index3}>
                                  <VariantTag name={item[key.attrName]} />
                                </td>
                              );
                            })
                          ) : (
                            <></>
                          )}
                          <td style={{ width: "15%" }}>
                            <NoBorderInput
                              type="text"
                              placeHolder={"-Auto-"}
                              name="code"
                              value={item.variantSku}
                              isReadOnly={type === "editActive" ? true : false}
                              onChange={(variantSku) =>
                                handleVariantsChange({
                                  target: {
                                    type: "text",
                                    value: variantSku,
                                    key: "variantSku",
                                    index: index2,
                                  },
                                })
                              }
                            />
                          </td>

                          <td style={{ width: "20%" }}>
                            <NoBorderInput
                              type="text"
                              name="barcode"
                              value={item.barcode}
                              onChange={(barcode) =>
                                handleVariantsChange({
                                  target: {
                                    type: "text",
                                    value: barcode,
                                    key: "barcode",
                                    index: index2,
                                  },
                                })
                              }
                            />
                          </td>
                          <td style={{ width: "15%" }}>
                            <NoBorderInput
                              type="text"
                              name="salePrice"
                              value={item.reorderLevel}
                              onChange={(reorderLevel) =>
                                handleVariantsChange({
                                  target: {
                                    type: "quantity",
                                    value: reorderLevel,
                                    key: "reorderLevel",
                                    index: index2,
                                  },
                                })
                              }
                            />
                          </td>
                          <td style={{ width: "15%" }}>
                            <NoBorderInput
                              type="text"
                              name="salePrice"
                              value={item.salePrice}
                              onChange={(salePrice) =>
                                handleVariantsChange({
                                  target: {
                                    type: "price",
                                    value: salePrice,
                                    key: "salePrice",
                                    index: index2,
                                  },
                                })
                              }
                            />
                          </td>

                          <td style={{ width: "15%" }}>
                            <NoBorderInput
                              type="text"
                              name="latestCost"
                              value={item.latestCost}
                              onChange={(latestCost) =>
                                handleVariantsChange({
                                  target: {
                                    type: "price",
                                    value: latestCost,
                                    key: "latestCost",
                                    index: index2,
                                  },
                                })
                              }
                            />
                          </td>
                          <td style={{ width: "20%" }}>
                            {index2 === 0 ? (
                              <div
                                onClick={bulkApplyToAll}
                                className={inventoryStyle.bulkFillBtn}
                              >
                                <MdOutlineDoneAll />
                                <span>Apply to All</span>
                              </div>
                            ) : (
                              <></>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {/************ Variants table end ***********************/}
              </>
            ) : (
              <div>
                <table className={inventoryStyle.dynTable}>
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Barcode</th>
                      <th>Re-order Level</th>
                      <th>Sale Price</th>
                      <th>
                        <QuestionMark
                          title="Latest Cost"
                          text="The latest tax exclusive cost of this product, it is not a fixed value, will be automatically updated when new stock added into system."
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {variants.map((item, index2) => (
                      <tr key={index2}>
                        <td style={{ width: "15%" }}>
                          <NoBorderInput
                            type="text"
                            name="code"
                            placeHolder={"-Auto-"}
                            value={item.variantSku}
                            isReadOnly={type === "editActive" ? true : false}
                            onChange={(variantSku) =>
                              handleVariantsChange({
                                target: {
                                  type: "text",
                                  value: variantSku,
                                  key: "variantSku",
                                  index: index2,
                                },
                              })
                            }
                          />
                        </td>
                        <td style={{ width: "20%" }}>
                          <NoBorderInput
                            type="text"
                            name="barcode"
                            value={item.barcode}
                            onChange={(barcode) =>
                              handleVariantsChange({
                                target: {
                                  type: "text",
                                  value: barcode,
                                  key: "barcode",
                                  index: index2,
                                },
                              })
                            }
                          />
                        </td>
                        <td style={{ width: "15%" }}>
                          <NoBorderInput
                            type="text"
                            name="salePrice"
                            value={item.reorderLevel}
                            onChange={(reorderLevel) =>
                              handleVariantsChange({
                                target: {
                                  type: "quantity",
                                  value: reorderLevel,
                                  key: "reorderLevel",
                                  index: index2,
                                },
                              })
                            }
                          />
                        </td>
                        <td style={{ width: "15%" }}>
                          <NoBorderInput
                            type="text"
                            name="salePrice"
                            value={item.salePrice}
                            onChange={(salePrice) =>
                              handleVariantsChange({
                                target: {
                                  type: "price",
                                  value: salePrice,
                                  key: "salePrice",
                                  index: index2,
                                },
                              })
                            }
                          />
                        </td>

                        <td style={{ width: "15%" }}>
                          <NoBorderInput
                            type="text"
                            name="latestCost"
                            value={item.latestCost}
                            onChange={(latestCost) =>
                              handleVariantsChange({
                                target: {
                                  type: "price",
                                  value: latestCost,
                                  key: "latestCost",
                                  index: index2,
                                },
                              })
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className={commonStyle.btnWrapper}>
        {/************  ***********************/}

        {type === "editActive" ? (
          <div className="w-1/2 my-4">
            <SimpleBlueButton
              name="Update Product"
              action={() => checkValidation(false)}
            />
          </div>
        ) : (
          <>
            <div className="w-full grid grid-cols-2 gap-4">
              <GreenButton
                name="Save as Draft"
                action={() => checkValidation(true)}
              />

              <SimpleBlueButton
                name="Save as Approved"
                action={() => checkValidation(false)}
              />
            </div>
            <p className={commonStyle.saveHintText}>
              *** Draft status products can not be sold or purchased.***
            </p>
          </>
        )}
      </div>
    </div>
  );
}
