import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  customerDetailsRequest,
  editCustomerRequest,
  createCustomerOptionsRequest,
} from "../../actions/customerActions";
import Breadcrumbs from "../../components/Breadcrumbs";
import commonStyle from "../../style/commonStyle.module.css";
import CustomerDetailForm from "./CustomerDetailForm";
import {
  ErrorMessage,
  Loader,
  SimpleDialog,
} from "../../components/viewComponents";
import { ScreenTitle } from "../../components/editComponents";
import { K_COUNTRIES } from "../../constantsData/countries";
import useDocumentTitle from "../../useDocumentTitle";

export default function EditCustomerScreen() {
  useDocumentTitle("Edit Customer");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { id: customerId } = useParams();

  const { loading, options, error, success } = useSelector(
    (state) => state.createCustomerOptions
  );


  const {
    loading: updateLoading,
    success: updateSuccess,
    error: updateError,
  } = useSelector((state) => state.editCustomer);
  
  useEffect(() => {
    dispatch(createCustomerOptionsRequest());
  }, [dispatch]);

  const [dialog, setDialog] = useState(false);

  const closeDialogWithSuccess = () => {
    setDialog(false);
    navigate("/customers", { replace: true, from: location });
  };

  const updateCustomerRequest = (values, differentBillingName) => {
    let customerValues = {
      name: values.name,
      billingName: differentBillingName ? values.billingName : values.name,
      country: typeof values.country === "object" ? values.country.value : "",
      currency:
        typeof values.currency === "object" ? values.currency.value : "",
      taxRateId: values.taxRateId,
      locationId: values.locationId,
      priceTierId: values.priceTierId,
      paymentTerm: values.paymentTerm,
      postalAddress: values.postalAddress,
      billingAddress: values.billingAddress,

      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      notes: values.notes,
    };

    setDialog(true);

    dispatch(editCustomerRequest(customerValues, customerId));
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Edit Customer"
        successMessage="Customer updated successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={updateLoading}
        loadingMessage="Processing request..."
        success={updateSuccess}
        error={updateError}
        confirmAction={closeDialogWithSuccess}
      />

      <Breadcrumbs screenName="Edit Customer" />
      <ScreenTitle title="Edit Customer" />
      <div className={commonStyle.pageContentContainer}>
        {loading ? (
          <Loader mess="Requesting customer details, please wait a moment..." />
        ) : success ? (
          <CustomerDetailForm
            type="edit"
            customerId={customerId}
            onSubmit={updateCustomerRequest}
            btnName="Update Customer"
            options={options}
          />
        ) : error ? (
          <ErrorMessage mess={error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
