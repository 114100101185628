import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

// check if user has logged in and has access to visit url

function RequireAuth({
  children,
  pageName,
  permisson,
}: {
  children: JSX.Element,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success } = useSelector((state) => state.userDetail);

  let location = useLocation();

  // check user login status
  if (!userInfo) {
    // Redirect them to the /login page
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if (permisson) {
    if (success) {
      let roles = userDetails.data.permissions;
      let hasAccess = roles.includes(pageName);

      if (!hasAccess) {
        return (
          <Navigate to="/no_permission" state={{ from: location }} replace />
        );
      }
    }
  } else {
    return children;
  }

  return children;
}

export { RequireAuth };
