import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import menuStyle from "../../style/menuStyle.module.css";
import { SideMenuIcon } from "../icons";

function CollapsedMenu({ item }) {
  const location = useLocation();

  let showSidebar = false;
  let showChildren = false;

  if(item.children && item.children.length > 0 && item.isChildrenShow && item.isSidebarShow){
    showSidebar = true;
    showChildren = true;
  }
  if(item.children && item.children.length > 0 && !item.isChildrenShow && item.isSidebarShow){
    showSidebar = true;
  }
  if(!item.children && item.isSidebarShow){
    showSidebar = true;
  }

  return showSidebar && showChildren ? (
    // sidemen expanded, and item has childrens
    <div className={menuStyle.sideMenuItemColla}>
      <div className={menuStyle.collaIcon}>  <SideMenuIcon name={item.iconName} /></div>

      <div className={menuStyle.subMenuContainer}>
        {item.children.map((item, index) => (
          <div className={menuStyle.collaSubitemContainer} key={index}>
            {item.isSidebarShow ? (
              <NavLink
                to={item.path}
                exact="true"
                className={item.path === location.pathname ? `${menuStyle.collaSubitem} ${menuStyle.pathSelected}` : menuStyle.collaSubitem}
              >
                {item.sidemenuName}
              </NavLink>
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
    </div>
  ) : showSidebar && !showChildren ? (
    // sidemen expanded, and item no children
    <div>
      <NavLink key={item.id} to={item.path} exact="true" className={menuStyle.collaIconPlain}>
      <SideMenuIcon name={item.iconName} />
        <div className={menuStyle.menuNameTag}>{item.sidemenuName}</div>
      </NavLink>
    </div>
  ) : (
    <></>
  );
}

export default CollapsedMenu;
