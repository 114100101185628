import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userlogout } from "../../actions/authAction.js";
import {
  productOptionsRequest,
  stocktakeListRequest,
} from "../../actions/productActions";
import { stocktakeReportRequest } from "../../api/reportingServices";
import Breadcrumbs from "../../components/Breadcrumbs";
import Pagination from "../../components/Pagination";
import {
  LoadingButton,
  ScreenTitle,
  SimpleBlueButton,
  SimpleOutlineButton,
} from "../../components/editComponents";
import { StyledMultiSelect } from "../../components/inputFields";
import {
  ErrorMessage,
  Loader,
  Tag,
  VariantSkuTag,
  VariantTag,
} from "../../components/viewComponents";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import { CsvWriter } from "./CsvWriter.js";

export default function StocktakeReportScreen() {
  useDocumentTitle("Stocktake Report");
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  // get data from store by using useSelector method
  const { loading, stocktakeList, error, success } = useSelector(
    (state) => state.stocktake
  );

  const {
    loading: optionsLoading,
    options,
    error: optionsError,
    success: optionsSuccess,
  } = useSelector((state) => state.productOptions);

  // pagination

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);
  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  // filter and search  function
  const [filterLocation, setFilterLocation] = useState([]);
  const [filterCategory, setFilterCategory] = useState([]);

  const applyFilter = () => {
    let selectedFilterCategory = [];
    let selectedFilterLocation = [];

    if (filterCategory.length > 0) {
      filterCategory.forEach((cate) => {
        selectedFilterCategory.push(cate.id);
      });
    }

    if (filterLocation.length > 0) {
      filterLocation.forEach((loc) => {
        selectedFilterLocation.push(loc.id);
      });
    }

    dispatch(
      stocktakeListRequest(
        0,
        pageSize,
        selectedFilterCategory.join(),
        selectedFilterLocation.join()
      )
    );
  };

  /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////
  const downloadFile = async () => {
    // get full stocktake list
    let stockListdata = [];

    let selectedFilterCategory = [];
    let selectedFilterLocation = [];

    if (filterCategory.length > 0) {
      filterCategory.forEach((cate) => {
        selectedFilterCategory.push(cate.id);
      });
    }

    if (filterLocation.length > 0) {
      filterLocation.forEach((loc) => {
        selectedFilterLocation.push(loc.id);
      });
    }

    try {
      setRequestStatus((preState) => ({
        ...preState,
        loading: true,
      }));

      const responseData = await stocktakeReportRequest(
        selectedFilterCategory.join(),
        selectedFilterLocation.join(),
        config
      );

      if (responseData.success) {
        stockListdata = responseData.data.products;

        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      }else if(responseData.error === -3){
        dispatch(userlogout());
      }
    } catch (error) {
      setRequestStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }

    //get csv file
    let csvWriter = new CsvWriter([
      "Code",
      "ProductName",
      "Variant",
      "Category",
      "Barcode",
      "Location",
      "StockonHand",
    ]);
    for (let line of stockListdata) {
      csvWriter.addLine([
        line.variantSku,
        line.name,
        line.variant,
        line.category ? line.category : "",
        line.barcode,
        line.location,
        line.stock_level,
      ]);
    }
    const csvString = csvWriter.content;

    // create a download link
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "stocktake-report.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////
  useEffect(() => {
    let selectedFilterCategory = [];
    let selectedFilterLocation = [];

    if (filterCategory.length > 0) {
      filterCategory.forEach((cate) => {
        selectedFilterCategory.push(cate.id);
      });
    }

    if (filterLocation.length > 0) {
      filterLocation.forEach((loc) => {
        selectedFilterLocation.push(loc.id);
      });
    }

    dispatch(
      stocktakeListRequest(
        pageNum,
        pageSize,
        selectedFilterCategory.join(),
        selectedFilterLocation.join()
      )
    );
    dispatch(productOptionsRequest());
  }, [dispatch, pageSize, pageNum]);

  const DownloadBtn = () => {
    return (
      <div style={{ width: "auto" }}>
        {requestStatus.loading ? (
          <LoadingButton name="Downloading.." />
        ) : (
          <SimpleBlueButton name="Download" action={downloadFile} />
        )}
      </div>
    );
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Stocktake Report" />

      <ScreenTitle title="Stocktake Report" buttonComponent={<DownloadBtn />} />
      <div className={commonStyle.pageContentContainer}>
        <div style={{ width: "100%", display: "flex", alignItems: "flex-end" }}>
          <div
            style={{
              width: "80%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "45%" }}>
              <StyledMultiSelect
                label="Location"
                selectOptions={optionsSuccess ? options.location : []}
                value={filterLocation}
                onChange={(location) => setFilterLocation(location)}
              />
            </div>
            <div style={{ width: "45%" }}>
              <StyledMultiSelect
                label="Category"
                selectOptions={optionsSuccess ? options.category : []}
                value={filterCategory}
                onChange={(category) => setFilterCategory(category)}
              />
            </div>
          </div>
          <div
            style={{
              width: "20%",
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "-20px",
            }}
          >
            <SimpleOutlineButton name="Apply Filter" action={applyFilter} />
          </div>
        </div>

        {loading ? (
          <Loader mess="Requesting report, please wait a moment..." />
        ) : success ? (
          <div className={commonStyle.longDataTable}>
            {/* hold the product list table */}
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Product Name</th>
                  <th>Variant</th>
                  <th>Category</th>
                  <th>Barcode</th>
                  <th>Location</th>
                  <th>Stock on Hand</th>
                </tr>
              </thead>
              <tbody>
                {success && stocktakeList.products.length > 0 ? (
                  stocktakeList.products.map((item) => (
                    <React.Fragment key={item.id}>
                      <tr>
                        <td>
                          {item.variantSku && (
                            <VariantSkuTag name={item.variantSku} />
                          )}
                        </td>
                        <td>{item.name}</td>
                        <td>
                          {item.variant ? (
                            <VariantTag name={item.variant} />
                          ) : (
                            <></>
                          )}
                        </td>
                        <td>{item.category}</td>
                        <td>{item.barcode}</td>
                        <td>
                          <Tag name={item.location} color="gray" />
                        </td>
                        <td>{item.stock_level}</td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td>
                      <p>No products have stock on hand.</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {stocktakeList.products.length > 0 ? (
              <Pagination
                totalPageNum={stocktakeList.total_pages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : error ? (
          <ErrorMessage mess="Requesting report failed, please try again later" />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
