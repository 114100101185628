import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import commonStyle from "../../../style/commonStyle.module.css";
import {
  ScreenTitle,
  CloneButtonLink,
  EditButtonLink,
  ViewButtonLink,
  ShippingButton,
  RentalReturnButton,
  DeleteButton,
} from "../../../components/editComponents";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Pagination from "../../../components/Pagination";
import {
  ErrorMessage,
  Loader,
  Tag,
  ViewDateText,
  DollarTag,
  Modal,
  SimpleDialog,
  PriceText,
  DeleteDialog,
} from "../../../components/viewComponents";
import {
  saleOrderListRequest,
  shipSaleOrderRequest,
} from "../../../actions/saleOrderActions";
import SaleOrderShippingForm from "./SaleOrderShippingForm";
import useDocumentTitle from "../../../useDocumentTitle";
import { Search } from "../../../components/inputFields";
import { SaleOrderInvoice } from "./invoiceComponents";
import { deleteSaleOrderRequest } from "../../../api/saleOrderServices";

export default function SaleOrderListScreen() {
  useDocumentTitle("Manage Sale Orders");

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.userLogin);

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [shippingModal, setShippingModal] = useState(false);
  const [shippingDialog, setShippingDialog] = useState(false);
  const [shipOrderId, setShipOrderId] = useState("");
  const [deleteOrderId, setDeleteOrderId] = useState("");
  const [deleteDialog, setDeleteDialog] = useState(false);

  const [deleteStatus, setDeleteStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  // access check for the actions
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const { loading, saleOrders, error, success } = useSelector(
    (state) => state.saleOrderList
  );

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);
  // search
  const [keyWords, setKeywords] = useState("");

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  const initialShippingValues = {
    shippingCompany: "",
    trackingNo: "",
  };
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  const openShippingModal = (orderId) => {
    setShippingModal(true);
    setShipOrderId(orderId);
  };

  const addShippingInformation = (shippingValues) => {
    setShippingModal(false);
    setShippingDialog(true);
    dispatch(shipSaleOrderRequest(shippingValues, shipOrderId));
  };
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  const closeDialogWithSuccess = () => {
    setShippingDialog(false);
    navigate("/sale/sale-order-list", { replace: true, from: location });
  };

  const {
    loading: shippingLoading,
    error: shippingError,
    success: shippingSuccess,
  } = useSelector((state) => state.shipSaleOrder);
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (
      userDetailsSuccess &&
      userDetails.data.permissions.includes("sale_order_list")
    ) {
      dispatch(saleOrderListRequest(keyWords, pageNum, pageSize, "all"));
    } else {
      dispatch(saleOrderListRequest(keyWords, pageNum, pageSize, "creator"));
    }
  }, [
    dispatch,
    shippingSuccess,
    pageNum,
    keyWords,
    userDetails,
    deleteStatus.success,
  ]);

  const handleSearchChange = (value) => {
    setKeywords(value);
    setPageNum(0);
  };

  ////////////////////////////////////////////////////////////////////////////////////
  const openDeleteDialog = (orderId) => {
    setDeleteOrderId(orderId);
    setDeleteDialog(true);
  };
  const sendDeleteRequest = async () => {
    try {
      setDeleteStatus({
        ...deleteStatus,
        loading: true,
        success: false,
        error: "",
      });
      const responseData = await deleteSaleOrderRequest(deleteOrderId, config);

      if (responseData.success) {
        setDeleteStatus({
          ...deleteStatus,
          loading: false,
          success: true,
        });
      } else {
        throw "Delete sale order failed.";
      }
    } catch (error) {
      setDeleteStatus({
        ...deleteStatus,
        loading: false,
        error: error,
      });
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////
  const EditLink = ({ itemId }) => {
    return userDetailsSuccess ? (
      userDetails.data.permissions.includes("new_sale_order") ? (
        <EditButtonLink path={`/sale/order/edit/${itemId}`} />
      ) : (
        <></>
      )
    ) : (
      <></>
    );
  };
  const DeleteOrderButton = ({ itemId }) => {
    return userDetailsSuccess ? (
      userDetails.data.permissions.includes("new_sale_order") ? (
        <DeleteButton action={() => openDeleteDialog(itemId)} />
      ) : (
        <></>
      )
    ) : (
      <></>
    );
  };

  const CloneLink = ({ itemId }) => {
    return userDetailsSuccess ? (
      userDetails.data.permissions.includes("new_sale_order") ? (
        <CloneButtonLink path={`/sale/order/new`} data={itemId} />
      ) : (
        <></>
      )
    ) : (
      <></>
    );
  };

  const PreviewLink = ({ itemId }) => {
    return userDetailsSuccess ? (
      userDetails.data.permissions.includes("new_sale_order") ? (
        <ViewButtonLink path={`/sale/order/preview/${itemId}`} />
      ) : (
        <></>
      )
    ) : (
      <></>
    );
  };

  const ShippingLink = ({ itemId }) => {
    return userDetailsSuccess ? (
      userDetails.data.permissions.includes("new_sale_order") ? (
        <ShippingButton action={() => openShippingModal(itemId)} />
      ) : (
        <></>
      )
    ) : (
      <></>
    );
  };

  const DownloadInvoice = ({ invoiceNumber, invoiceId }) => {
    return userDetailsSuccess ? (
      userDetails.data.permissions.includes("new_sale_order") ? (
        <SaleOrderInvoice name={invoiceNumber} invoiceId={invoiceId} />
      ) : (
        <></>
      )
    ) : (
      <></>
    );
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Modal
        title="Shipping"
        isModalOpen={shippingModal}
        closeModal={() => setShippingModal(false)}
        content={
          <SaleOrderShippingForm
            values={initialShippingValues}
            onSubmit={addShippingInformation}
            btnName="Ship Order"
          />
        }
      />
      <SimpleDialog
        title="Shipping"
        successMessage="Shipping information updated successfully."
        isDialogOpen={shippingDialog}
        closeDialog={() => setShippingDialog(false)}
        loading={shippingLoading}
        loadingMessage="Processing request..."
        success={shippingSuccess}
        error={shippingError}
        confirmAction={closeDialogWithSuccess}
      />
      <DeleteDialog
        title="Delete Sale Order"
        preDeleteMess={`Please note that the delete action can not be undone. Are you sure you want to proceed?`}
        successMessage="Sale order deleted sucessfully."
        isDialogOpen={deleteDialog}
        closeDialog={() => setDeleteDialog(false)}
        loading={deleteStatus.loading}
        success={deleteStatus.success}
        error={deleteStatus.error}
        loadingMessage="Processing request..."
        deleteAction={sendDeleteRequest}
      />

      <Breadcrumbs screenName={"All Sale Orders"} />
      <ScreenTitle title={"All Sale Orders"} />
      <div className={commonStyle.pageContentContainer}>
        <div style={{ width: "60%" }}>
          <Search
            placeholdertext="Type order number / customer name to search..."
            initialQuery={keyWords}
            onChange={handleSearchChange}
          />
        </div>
        {loading ? (
          <Loader mess="Requesting sale order list, please wait a moment..." />
        ) : success ? (
          <div className={commonStyle.longDataTable}>
            {/* hold the product list table */}
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  {/* <th>Order Type</th> */}
                  <th>Order Number</th>
                  <th>Create Date</th>
                  <th>Customer</th>
                  <th>Location</th>
                  <th>Total Price</th>
                  <th>Sales Person</th>
                  <th>Order Status</th>
                  <th>Invoice Status</th>
                  <th>Shipping Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {saleOrders.orders.length > 0 ? (
                  saleOrders.orders.map((item) => (
                    <tr key={item.id}>
                      <td>{item.orderNumber}</td>
                      <td>
                        <ViewDateText value={item.createDate} />
                      </td>
                      <td style={{ width: "10%" }}>
                        {item.noCustomer ? (
                          <Tag name="No Customer" color="gray" />
                        ) : (
                          item.customerName
                        )}
                      </td>
                      <td style={{ width: "10%" }}>
                        <Tag name={item.locationName} color="gray" />
                      </td>
                      <td>
                        <DollarTag />
                        <PriceText num={item.totalPriceInclTax} />
                      </td>
                      <td>
                        {item.creatorFirstName} {item.creatorLastName ? item.creatorLastName : ""}
                      </td>
                      <td>
                        {item.status === "Approved" ? (
                          <Tag name={item.status} color="green" />
                        ) : item.status === "Draft" ? (
                          <Tag name={item.status} color="yellow" />
                        ) : item.status === "Back Order" ? (
                          <Tag name={item.status} color="yellow" />
                        ) : item.status === "Completed" ? (
                          <Tag name={item.status} color="blue" />
                        ) : item.status === "Deleted" ? (
                          <Tag name={item.status} color="gray" />
                        ) : (
                          <></>
                        )}
                      </td>
                      <td>
                        {item.invoiceStatus === "ISSUED" ? (
                          <DownloadInvoice
                            invoiceNumber={item.invoiceNumber}
                            invoiceId={item.invoiceId}
                          />
                        ) : (
                          <></>
                        )}
                      </td>
                      <td>
                        {item.status !== "Draft" ? (
                          item.shippingStatus === "Waiting" ? (
                            <Tag name="Waiting for shipping" color="yellow" />
                          ) : item.shippingStatus === "Not required" ? (
                            <Tag name="Not Required" color="gray" />
                          ) : item.shippingStatus === "Shipped" ? (
                            <Tag name="Shipped" color="blue" />
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                      </td>

                      {/* according to different status show different actions */}
                      <td>
                        {item.status === "Draft" ? (
                          <div className={commonStyle.actionsGrop}>
                            <PreviewLink itemId={item.id} />
                            <EditLink itemId={item.id} />
                            <DeleteOrderButton itemId={item.id} />
                            <CloneLink itemId={item.id} />
                          </div>
                        ) : item.status === "Back Order" ? (
                          <div className={commonStyle.actionsGrop}>
                            <PreviewLink itemId={item.id} />

                            <EditLink itemId={item.id} />
                            <DeleteOrderButton itemId={item.id} />
                            <CloneLink itemId={item.id} />
                          </div>
                        ) : item.status === "Approved" ? (
                          <div className={commonStyle.actionsGrop}>
                            {item.shippingStatus === "Waiting" ? (
                              <ShippingLink itemId={item.id} />
                            ) : (
                              <></>
                            )}

                            <PreviewLink itemId={item.id} />
                            <CloneLink itemId={item.id} />
                          </div>
                        ) : item.status === "Completed" ? (
                          <div className={commonStyle.actionsGrop}>
                            <PreviewLink itemId={item.id} />
                            <CloneLink itemId={item.id} />
                          </div>
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      There are no order available, create a new order now.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {saleOrders.orders.length > 0 ? (
              <Pagination
                totalPageNum={saleOrders.totalPages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
    </div>
  );
}
