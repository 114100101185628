import React, { useEffect, useState } from "react";
import { AiOutlineBarcode } from "react-icons/ai";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { productListRequest } from "../../actions/productActions";
import Breadcrumbs from "../../components/Breadcrumbs";
import Pagination from "../../components/Pagination";
import {
  CircleDeleteButton,
  ScreenTitle,
} from "../../components/editComponents";
import { ProductListFilter } from "../../components/filters";
import { Search } from "../../components/inputFields";
import {
  ContentModal,
  DollarTag,
  ErrorMessage,
  Loader,
  SmallProductImage,
  Tag,
  VariantSkuTag,
  VariantTag,
} from "../../components/viewComponents";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import QuickStockScanForm from "../dashboard/QuickStockScanForm";
import ProductActions from "./ProductActions";
import inventoryStyle from "./inventoryStyle.module.css";

export default function ProductListScreen() {
  useDocumentTitle("Manage Products");
  const dispatch = useDispatch();

  // get data from store by using useSelector method
  const { userInfo } = useSelector((state) => state.userLogin);

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const { loading, productList, error, success } = useSelector(
    (state) => state.productList
  );
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  //initial state
  const [actionsOpen, setActionsOpen] = useState({});
  // pagination
  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);

  // manage scan barcode state
  const [scanModal, setScanModal] = useState(false);
  // manage xero task status'
  // const [xeroTaskFamilyId, setXeroTaskFamilyId] = useState("");
  // const [xeroTaskStatus, setXeroTaskStatus] = useState({});
  // const [xeroTaskDialog, setXeroTaskDialog] = useState(false);
  // handle page change

  const [deleteCount, setDeleteCount] = useState(0);
  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  const expandActionCon = (productId) => {
    let actionsOpenCopy = { ...actionsOpen };

    if (productId in actionsOpenCopy) {
      setActionsOpen({
        ...actionsOpen,
        [productId]: !actionsOpenCopy[productId],
      });
    } else {
      setActionsOpen({ ...actionsOpen, [productId]: true });
    }
  };

  // filter and search  function

  let initialFilterData = {
    category: [],
    location: [],
    status: [],
    supplier: [],
  };

  const [selectedFilterData, setSelectedFilterData] =
    useState(initialFilterData);

  const [keyWords, setKeywords] = useState("");

  // search

  const getProductName = (proName) => {
    setScanModal(false);
    setKeywords(proName);
  };


  const applyFilter = (values) => {
    setSelectedFilterData(values);
  };

  const handleSearchChange = (value) => {
    setKeywords(value);
    setPageNum(0);
  };

  const deleteFilterOption = (type, index) => {
    let copy = {...selectedFilterData};
    let dataCopy = copy[type];
    dataCopy.splice(index, 1);
    setSelectedFilterData(copy);
    setDeleteCount(deleteCount + 1);
  };

  useEffect(() => {
    let selectedFilterCategory = [];
    let selectedFilterLocation = [];
    let selectedFilterSupplier = [];
    let selectedFilterStatus = [];
    
    if (selectedFilterData.category.length > 0) {
      selectedFilterData.category.forEach((cate) => {
        selectedFilterCategory.push(cate.id);
      });
    }

    if (selectedFilterData.location.length > 0) {
      selectedFilterData.location.forEach((loc) => {
        selectedFilterLocation.push(loc.id);
      });
    }

    if (selectedFilterData.supplier.length > 0) {
      selectedFilterData.supplier.forEach((sup) => {
        selectedFilterSupplier.push(sup.id);
      });
    }

    if (selectedFilterData.status.length > 0) {
      selectedFilterData.status.forEach((s) => {
        selectedFilterStatus.push(s.value);
      });
    }
    dispatch(
      productListRequest(
        pageNum,
        pageSize,
        keyWords,
        selectedFilterCategory.join(),
        selectedFilterLocation.join(),
        selectedFilterSupplier.join(),
        selectedFilterStatus.join()
      )
    );
  }, [dispatch, pageSize, pageNum, keyWords, selectedFilterData]);

  const reloadProductList = () => {
    let selectedFilterCategory = [];
    let selectedFilterLocation = [];
    let selectedFilterSupplier = [];
    let selectedFilterStatus = [];

    if (selectedFilterData.category.length > 0) {
      selectedFilterData.category.forEach((cate) => {
        selectedFilterCategory.push(cate.id);
      });
    }

    if (selectedFilterData.location.length > 0) {
      selectedFilterData.location.forEach((loc) => {
        selectedFilterLocation.push(loc.id);
      });
    }

    if (selectedFilterData.supplier.length > 0) {
      selectedFilterData.supplier.forEach((sup) => {
        selectedFilterSupplier.push(sup.id);
      });
    }

    if (selectedFilterData.status.length > 0) {
      selectedFilterData.status.forEach((s) => {
        selectedFilterStatus.push(s.value);
      });
    }
    dispatch(
      productListRequest(
        pageNum,
        pageSize,
        keyWords,
        selectedFilterCategory.join(),
        selectedFilterLocation.join(),
        selectedFilterSupplier.join(),
        selectedFilterStatus.join()
      )
    );
  };

  return (
    <div className={commonStyle.pageContainer}>
      <ContentModal
        title="Scan Barcode"
        isModalOpen={scanModal}
        closeOnOverlayClick={false}
        closeModal={() => setScanModal(false)}
        content={
          <QuickStockScanForm type="manage_stock" proDetails={getProductName} />
        }
      />

      <Breadcrumbs screenName="Product List" />
      <ScreenTitle title="Product List" />
      <div className={commonStyle.pageContentContainer}>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%" }}>
            <Search
              placeholdertext="Type product name/code to search..."
              initialQuery={keyWords}
              onChange={handleSearchChange}
            />
          </div>
          <div
            className={inventoryStyle.barcodeScanCon}
            onClick={() => setScanModal(true)}
          >
            <AiOutlineBarcode />
            <span>Scan Barcode</span>
          </div>

          <ProductListFilter
            onSelect={applyFilter}
            initialFilter={selectedFilterData}
            filterOptions={["category", "location", "status", "supplier"]}
            onDelete={deleteCount}
          />
        </div>

        {loading ? (
          <Loader mess="Requesting product list, please wait a moment..." />
        ) : success ? (
          <div className={commonStyle.longDataTable}>
            {Object.values(selectedFilterData).some((x) => x.length > 0) ? (
              <div className={inventoryStyle.filterSelectCon}>
                {selectedFilterData.category.length > 0 ? (
                  <div className={inventoryStyle.filterTypeCon}>
                    <span>Category:</span>
                    {selectedFilterData.category.map((x, index1) => (
                      <div
                        className={inventoryStyle.filterOptionCon}
                        key={index1}
                      >
                        <span>{x.value}</span>
                        <CircleDeleteButton
                          action={() => deleteFilterOption("category", index1)}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <></>
                )}
                {selectedFilterData.location.length > 0 ? (
                  <div className={inventoryStyle.filterTypeCon}>
                    <span>Location:</span>
                    {selectedFilterData.location.map((x, index2) => (
                      <div
                        className={inventoryStyle.filterOptionCon}
                        key={index2}
                      >
                        <span>{x.value}</span>
                        <CircleDeleteButton
                          action={() => deleteFilterOption("location", index2)}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <></>
                )}
                 {selectedFilterData.supplier.length > 0 ? (
                  <div className={inventoryStyle.filterTypeCon}>
                    <span>Supplier:</span>
                    {selectedFilterData.supplier.map((x, index2) => (
                      <div
                        className={inventoryStyle.filterOptionCon}
                        key={index2}
                      >
                        <span>{x.value}</span>
                        <CircleDeleteButton
                          action={() => deleteFilterOption("supplier", index2)}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <></>
                )}
                {selectedFilterData.status.length > 0 ? (
                  <div className={inventoryStyle.filterTypeCon}>
                    <span>Status:</span>
                    {selectedFilterData.status.map((x, index3) => (
                      <div
                        className={inventoryStyle.filterOptionCon}
                        key={index3}
                      >
                        <span>{x.value}</span>
                        <CircleDeleteButton
                          action={() => deleteFilterOption("status", index3)}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}

            {/* hold the product list table */}
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th></th>
                  <th>Code</th>
                  <th>Product Name</th>
                  <th>Category</th>
                  <th>Unit</th>
                  <th>Location</th>
                  <th>Total Stock</th>
                  <th>Sale Price</th>
                  <th>Latest Cost</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {success && productList.products.length > 0 ? (
                  productList.products.map((item) => (
                    <React.Fragment key={item.id}>
                      <tr
                        className={
                          actionsOpen[item.id]
                            ? `${inventoryStyle.productRow} ${inventoryStyle.open}`
                            : inventoryStyle.productRow
                        }
                      >
                        <td>
                          <div
                            className={inventoryStyle.toggleBtn}
                            onClick={() => expandActionCon(item.id)}
                          >
                            <MdKeyboardArrowDown />
                          </div>
                        </td>
                        <td onClick={() => expandActionCon(item.id)} className="hover:cursor-pointer">
                          <VariantSkuTag name = {item.variantSku } />
                        </td>
                        <td
                          className={commonStyle.name}
                          onClick={() => expandActionCon(item.id)}
                        >
                          {
                            item.image ? <div className="flex flex-row py-2 items-center">
                            <div className="w-[60px] h-[60px] mr-2">
                              <SmallProductImage
                                alt={"product image /" + item.image }
                                src={item.image}
                              />
                            </div>

                            <p>{item.name}</p>
                          </div> :  <p>{item.name}</p> 
                          }
                          
                        </td>
                      
                        <td>{item.category}</td>
                        <td>{item.unitName}</td>
                        <td>
                          {item.locations.length > 0 ? (
                            item.locations.map((loc, index) => (
                              <Tag 
                              name={
                                item.locations.length > 1 
                                  ? `${loc} | ${item.locationStockLevel[loc] !== undefined ? item.locationStockLevel[loc] : ""}` 
                                  : loc 
                              }                        
                              key={index} 
                              color="gray" />
                            ))
                          ) : (
                            <></>
                          )}
                        </td>
                        <td>{item.stock}</td>
                        <td>
                          <DollarTag />
                          {item.price}
                        </td>
                        <td>
                          <DollarTag />
                          {item.latestCost}
                        </td>
                        <td>
                          {item.status === "Active" ? (
                            <Tag name={item.status} color="green" />
                          ) : item.status === "Draft" ? (
                            <Tag name={item.status} color="yellow" />
                          ) : (
                            <></>
                          )}{" "}
                        </td>
                      </tr>
                      <tr
                        className={
                          actionsOpen[item.id]
                            ? inventoryStyle.actionsConOpen
                            : inventoryStyle.actionsCon
                        }
                      >
                        <td colSpan="9" style={{ padding: "16px" }}>
                          <ProductActions
                            product={item}
                            showPreview={true}
                            reloadList={reloadProductList}
                          />
                        </td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td>
                      <p>There are no product available.</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {success && productList.products.length > 0 ? (
              <Pagination
                totalPageNum={productList.totalPages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : error ? (
          <ErrorMessage mess="Requesting product list failed, please try again later..." />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
