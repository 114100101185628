import React from "react";
import billingStyle from "./billingStyle.module.css";
import BillingSteps from "./BillingSteps";
import {
  SimpleBlueButton
} from "../../components/editComponents";
import { useLocation, useNavigate } from "react-router-dom";

export default function BillingFailed({
  paidFailed,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const gotoDashboard = () => {
    navigate("/", { replace: true, from: location });
  }

  const retryRequest = () => {
    navigate("/manage-subscription", { replace: true, from: location });
  }


  return (
    <div>
      <BillingSteps step1={true} step2={true} paidFailed />
      <div className={billingStyle.billingSummaryWrapper}>
        <div className={billingStyle.payStatusCon}>
        <div className={billingStyle.payFailedIcon}></div>
        <p className={billingStyle.payStatusText}>Payment Cancelled!</p>
        <div className={billingStyle.actionsCon}>
          <div style={{marginRight: "20px"}}>
            <SimpleBlueButton name="Dashboard" action={gotoDashboard} /> 
          </div>
      
          <SimpleBlueButton name="Retry" action={retryRequest} />
        </div>
      
        </div>
        
      </div>
    </div>
  );
}
