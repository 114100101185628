import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { supplierDetailsRequest, editSupplierRequest, createSupplierOptionsRequest } from "../../actions/supplierActions";
import Breadcrumbs from "../../components/Breadcrumbs";
import commonStyle from "../../style/commonStyle.module.css";
import SupplierDetailForm from "./SupplierDetailForm";
import {
  ErrorMessage,
  Loader,
  SimpleDialog,
} from "../../components/viewComponents";
import { ScreenTitle } from "../../components/editComponents";
import { K_COUNTRIES } from "../../constantsData/countries";
import useDocumentTitle from "../../useDocumentTitle";


export default function EditSupplier() {
  useDocumentTitle("Edit Supplier")
  const { id } = useParams();

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

 const {
    loading: optionsLoading,
    options,
    error: optionsError,
    success: optionsSuccess,
  } = useSelector((state) => state.createSupplierOptions);

  const { loading, supplierInfo, success, error } = useSelector(
    (state) => state.supplierDetails
  );

  const { loading:updateLoading, success:updateSuccess, error:updateError } = useSelector(
    (state) => state.editSupplier
  );

  useEffect(() => {
    dispatch(supplierDetailsRequest(id));
    dispatch(createSupplierOptionsRequest());
  }, [dispatch, id]);

  const formatSupplierInfo = () => {
    let supplierCopy = {...supplierInfo};

    let has_country_value = K_COUNTRIES.find(
      (x) => x.value === supplierCopy.country
    );

    if (has_country_value) {
      supplierCopy.country = has_country_value;
    } else {
      supplierCopy.country = "";
    }

    let has_currency_value = options.currencies.find(
      (x) => x.value === supplierCopy.currency
    );
    if (has_currency_value) {
      supplierCopy.currency = has_currency_value;
    } else {
      supplierCopy.currency = "";
    }

    if (typeof supplierCopy.postalAddress.country !== "object") {
      let find_postal_address_country = K_COUNTRIES.find(
        (x) => x.value === supplierCopy.postalAddress.country
      );
      if (find_postal_address_country) {
        supplierCopy.postalAddress.country = find_postal_address_country;
      } else {
        supplierCopy.postalAddress.country = "";
      }
    }
    if (typeof supplierCopy.billingAddress.country !== "object") {
      let find_billing_address_country = K_COUNTRIES.find(
        (x) => x.value === supplierCopy.billingAddress.country
      );
      if (find_billing_address_country) {
        supplierCopy.billingAddress.country = find_billing_address_country;
      } else {
        supplierCopy.billingAddress.country = "";
      }
    }

    return supplierCopy;
  };


  const [dialog, setDialog] = useState(false);

  const closeDialogWithSuccess = () => {
    setDialog(false);
    navigate("/suppliers", { replace: true, from: location });
  };

  const updateSupplierRequest = (values) => {
    setDialog(true);
    
    let supplierValues = {
      name: values.name,
      country: typeof values.country === 'object' ? values.country.value : "",
      currency: typeof values.currency === 'object' ? values.currency.value : "",
      taxRateId: values.taxRateId,
      postalAddress: JSON.stringify(values.postalAddress),
      billingAddress: JSON.stringify(values.billingAddress),
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      notes: values.notes,
    };
    dispatch(editSupplierRequest(supplierValues, id));
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Update Supplier"
        successMessage="Supplier updated successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={updateLoading}
        loadingMessage="Processing request..."
        success={updateSuccess}
        error={updateError}
        confirmAction={closeDialogWithSuccess}
      />

      <Breadcrumbs screenName="Edit Supplier" />
      <ScreenTitle title="Edit Supplier" />
      <div className={commonStyle.pageContentContainer}>
      {loading ? (
          <Loader mess="Requesting supplier details, please wait a moment..." />
        ) : optionsLoading ? (
          <Loader mess="Requesting data, please wait a moment" />
        ) : success ? (
          optionsSuccess ? (
            <SupplierDetailForm
            values={() => formatSupplierInfo()}
            onSubmit={updateSupplierRequest}
            btnName="Update Supplier"
            options={options}
            title="Update Supplier"
          />
          ) : error ? (
            <ErrorMessage mess={error} />
          ) : optionsError ? (
            <ErrorMessage mess={optionsError} />
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
        
      </div>
    </div>
  );
}
