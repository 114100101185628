import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Breadcrumbs from "../../components/Breadcrumbs";
import {
  AddButton,
  DeleteButton,
  EditButton,
  ScreenTitle,
} from "../../components/editComponents";
import {
  AdjustTypeTag,
  DeleteDialog,
  ErrorMessage,
  Loader,
  Modal,
  SimpleDialog,
} from "../../components/viewComponents";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import Pagination from "../../components/Pagination";
import AdjustReasonForm from "./AdjustReasonForm";
import { adjustTypeOptions } from "../../constantsData/adjustType";
import { deleteAdjustReasonRequest, getAdjustReasonListRequest } from "../../api/adjustReasonsServices";

export default function AdjustReasonsScreen() {
  useDocumentTitle("Adjust Reasons");
  // set the Modal

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
      "Content-type": "application/json",
    },
  };

  // access check for the actions
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );
  //////////////////////////////////////////////////////////////////////////////

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);

  const [reasons, setReasons] = useState([]);
  const [updateListCount, setUpdateListCount] = useState(0);

  const [newModal, setNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const [newDialog, setNewDialog] = useState(false);
  const [updateDialog, setUpdateDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const [editedReason, setEditReason] = useState();
  const [deletedReason, setDeletedReason] = useState();

  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [deleteStatus, setDeleteStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  //////////////////////////////////////////////////////////////////////////////

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  useEffect(() => {
    const getReasonList = async () => {
      try {
        setRequestStatus((preState) => ({
          ...preState,
          loading: true,
        }));
        const responseData = await getAdjustReasonListRequest(pageNum, pageSize, config);

        if (responseData.data.success) {
          setReasons(responseData.data.data);
          setRequestStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
            error: "",
          }));
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };
    getReasonList();
  }, [updateListCount, pageNum]);

  const editReasonModal = (item) => {
    setEditReason(item);
    setEditModal(true);
  };

  const openDeleteReasonDialog = (reasonId) => {
    setDeletedReason(reasonId);
    setDeleteDialog(true);
  };

  const addReason = async () => {
    setNewModal(false);
    setNewDialog(true);
    setUpdateListCount((preState) => preState + 1);

  };

  const editReason = async () => {
    setEditModal(false);
    setUpdateDialog(true);
    setEditReason();
    setUpdateListCount((preState) => preState + 1);
  };

  const deleteReason = async () => {
    try {
      setDeleteStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await deleteAdjustReasonRequest(deletedReason, config);

      if (responseData.data.success) {
        setDeletedReason();
        setUpdateListCount((preState) => preState + 1);
        setDeleteStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
          error: "",
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setDeleteStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Modal
        title="New Adjust Reason"
        isModalOpen={newModal}
        closeModal={() => setNewModal(false)}
        content={
          <AdjustReasonForm
            reasonId=""
            type="new"
            btnName="Add New Adjust Reason"
            values={{ name: "", adjustType: adjustTypeOptions[0]}}
            onSuccess={addReason}
          />
        }
      />
      <Modal
        title="Update Adjust Reason"
        isModalOpen={editModal}
        closeModal={() => setEditModal(false)}
        content={
          <AdjustReasonForm
            reasonId={editedReason ? editedReason.id : ""}
            type="edit"
            btnName="Update Adjust Reason"
            values={{ name: editedReason ? editedReason.name : "" , adjustType: editedReason ? adjustTypeOptions.find((x)=> x.value === editedReason.type) : ""}}
            onSuccess={editReason}
          />
        }
      />

      <SimpleDialog
        title="New Adjust Reason"
        successMessage="New adjust reason added successfully."
        isDialogOpen={newDialog}
        closeDialog={() => setNewDialog(false)}
        loading={false}
        loadingMessage="Processing request..."
        success={true}
        error={""}
        btnValue="Confirm"
        confirmAction={() => setNewDialog(false)}
      />

      <SimpleDialog
        title="Update Adjust Reason"
        successMessage="Adjust reason updated successfully"
        isDialogOpen={updateDialog}
        closeDialog={() => setUpdateDialog(false)}
        loading={false}
        loadingMessage="Processing request..."
        success={true}
        error={""}
        btnValue="Confirm"
        confirmAction={() => setUpdateDialog(false)}
      />

      <DeleteDialog
        title="Delete Adjust Reason"
        preDeleteMess="Deleting adjust reason will not affect reports or historical transactions."
        successMessage="Adjust reason deleted successfully."
        isDialogOpen={deleteDialog}
        closeDialog={() => setDeleteDialog(false)}
        loading={deleteStatus.loading}
        success={deleteStatus.success}
        error={deleteStatus.error}
        loadingMessage="Processing request..."
        deleteAction={deleteReason}
      />

      <Breadcrumbs screenName="Adjust Reasons" />
      <ScreenTitle title="Adjust Reasons" />

      <div className={commonStyle.pageContentContainer}>
        {requestStatus.loading ? (
          <Loader mess="Requesting reason list, please wait a moment.." />
        ) : requestStatus.success ? (
          <div className={commonStyle.shortDataTable}>
            {userDetailsSuccess ? (
              userDetails.data.permissions.includes(
                "add_delete_edit_adjust_reasons"
              ) ? (
                <AddButton
                  name="New Adjust Reason"
                  action={() => setNewModal(true)}
                />
              ) : (
                <></>
              )
            ) : (
              <></>
            )}

            {/* hold the tax rates table */}
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Reason Name</th>
                  <th>Adjust Type</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {reasons.reasons.length > 0 ? (
                  reasons.reasons.map((item, index) => (
                    <tr key={index}>
                       <td><AdjustTypeTag name={item.type} /></td>

                      <td>{item.name}</td>

                      <td>
                        <div className={commonStyle.actionsGrop}>
                          <EditButton action={() => editReasonModal(item)} />
                          <DeleteButton
                            action={() => openDeleteReasonDialog(item.id)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      There are no adjust reason available, add a new adjust reason now.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {reasons.reasons.length > 0 ? (
              <Pagination
                totalPageNum={reasons.totalPages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : requestStatus.error ? (
          <ErrorMessage mess={requestStatus.error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
