import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { updateUserRequest } from "../../../actions/userRoleActions";
import Breadcrumbs from "../../../components/Breadcrumbs";
import commonStyle from "../../../style/commonStyle.module.css";
import UserDetailForm from "./UserDetailForm";
import { SimpleDialog } from "../../../components/viewComponents";
import useDocumentTitle from "../../../useDocumentTitle";
import { ScreenTitle } from "../../../components/editComponents";

export default function EditUser() {
  useDocumentTitle("Update User");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {}, [dispatch]);

  // get state from url
  const data = location.state;

  const { loading, success, error } = useSelector(
    (state) => state.updateUser
  );

  const [editUserDialog, setEditUserDialog] = useState(false);
  const editUserRequest = (values) => {
    setEditUserDialog(true);
    // get selected permissions id

    let selectedRolesId = [];

    for (let i = 0; i < values.roles.length; i++) {
      selectedRolesId.push(values.roles[i].id);
    }

    let userValues = {
      email: values.userEmail,
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.userPhone,
      position: values.position,
      departmentId: values.department.id,
      locationId: values.location.id,
      roleIds: selectedRolesId,
    };

    dispatch(updateUserRequest(userValues, values.id));
  };

  const closeDialogWithSuccess = () => {
    navigate("/settings/users", { replace: true, from: location });
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Update User"
        successMessage="User updated successfully!"
        isDialogOpen={editUserDialog}
        closeDialog={() => setEditUserDialog(false)}
        loading={loading}
        loadingMessage="Processing request..."
        success={success}
        error={error}
        btnValue="Confirm"
        confirmAction={closeDialogWithSuccess}
      />

      <Breadcrumbs screenName="Update User" />
      <ScreenTitle title="Update User" />
      <div>
      <div className={commonStyle.pageContentContainer}>
            <UserDetailForm
              values={data}
              onSubmit={editUserRequest}
              btnName="Update User"
              type="edit"
            />
          </div>
        </div>
    </div>
  );
}
