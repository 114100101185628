import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import commonStyle from "../../style/commonStyle.module.css";
import {
  Loader,
  ErrorText,
} from "../../components/viewComponents";
import successSound from "../../sounds/positive_sound.mp3";
import failedSound from "../../sounds/failed_sound.mp3";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function QuickStockScanForm({
  type,
  proDetails
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  //////////////////////////////////////////////////////////////////////////////
  const [focused, setFocused] = useState(true);
  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  ///////////////////////////////////////////////////////////////
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };

    // create a new Audio object
    const successAudio = new Audio(successSound);
    const failedAudio = new Audio(failedSound);

    let temporaryBarcode = "";
    let lastTypedTime = Date.now();

    function handleKeyDown(event) {
      if (event.key === 'Enter') {
        // 'Enter' key detected, process the barcode
        checkBarcode();
      } else {
        // Accumulate the barcode characters
        temporaryBarcode += event.key;
      }
      lastTypedTime = Date.now();
    }

    function checkBarcode() {
      let currentTime = Date.now();
      let timeSinceLastTyped = currentTime - lastTypedTime;
      if (timeSinceLastTyped < 50) {
        // keep listen to input
        return;
      }
      if (temporaryBarcode.length) {
        setFocused(true);
        try {
          setRequestStatus({ ...requestStatus, loading: true });
          axios
            .get(
              SERVER_URL +
                `/product/search/barcode/${temporaryBarcode}`,
              config
            )
            .then(async (response) => {
              if (response.data.success) {
                setRequestStatus({
                  ...requestStatus,
                  loading: false,
                  success: true,
                  error: "",
                });
                // add products to product array
                let newProduct = response.data.productDetails;
                if(type === "quick_stock"){
                  proDetails(newProduct.familyId, newProduct.name);
                }
                if(type === "manage_stock"){
                  proDetails(newProduct.name);
                }
                
                
                successAudio.play();
              } else if (response.data.error === 10) {
                setRequestStatus({
                  ...requestStatus,
                  loading: false,
                  error: `Barcode not found`,
                });
                failedAudio.play();
              } else {
                setRequestStatus({
                  ...requestStatus,
                  loading: false,
                  error: "Search barcode error",
                });
                failedAudio.play();
              }
            })
            .catch((err) => {
              setRequestStatus({
                ...requestStatus,
                loading: false,
                error: "Connection error, please try again later",
              });
              failedAudio.play();
            });
        } catch (error) {
          setRequestStatus({
            ...requestStatus,
            loading: false,
            error: "Connection error, please try again later",
          });
          failedAudio.play();
        }

        temporaryBarcode = "";
      }
    }

    const intervalId = setInterval(() => {
      checkBarcode();
    }, 50);

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [type, proDetails, requestStatus, userInfo.data.token]);

  // check if user focus on this page
  useEffect(() => {
    const handleBlur = () => {
      setFocused(false);
    };
    window.addEventListener("blur", handleBlur);
    return () => {
      window.removeEventListener("blur", handleBlur);
    };
  }, []);

  return (
    <div className={commonStyle.scanWrapper}>
      {requestStatus.loading ? (
        <Loader mess="Request product details..." />
      ) : focused ? (
        <div className={commonStyle.readyScanCon}>Ready to Scan</div>
      ) : (
        <div
          onClick={() => setFocused(true)}
          className={commonStyle.lostFocusScanCon}
        >
          Click to start scan
        </div>
      )}

      {requestStatus.error ? <ErrorText mess={requestStatus.error} /> : <></>}
    </div>
  );
}
