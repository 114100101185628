import React from "react";
import {AiOutlineQuestionCircle} from "react-icons/ai"
import dashboardStyle from "../dashboardStyle.module.css";


export default function NodataChart({text}) {
  
  return (

    <div className={dashboardStyle.nodataCon}>
      
        <AiOutlineQuestionCircle className={dashboardStyle.nodataIcon}/>
        <p className={dashboardStyle.nodataText}>{text}</p>
       

    </div>
  );
}
