import axios from "axios";



const SERVER_URL = process.env.REACT_APP_SERVER_URL;
// trial tasks actions
export const skipTrialStepRequest = async (type, userToken) => {

  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  };

   try {
     const response = await axios.post(SERVER_URL + `/dashboard/trial_task/skip`,type, config);
     return response.data;
   } catch (error) {
     throw new Error(error.message);
   }
 
 };

 export const deleteTrialDataRequest = async (dataType,userToken) => {

  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  };

   try {
     const response = await axios.post(SERVER_URL + `/dashboard/trial_account_data/delete`, dataType, config);
     return response.data;
   } catch (error) {
     throw new Error(error.message);
   }
 
 };
